import React from 'react';
import FormComponent from '../../common/FormComponent';
import { getDeceasedBroughtById } from '../../services/deceasedBrought/DeceasedBroughtService';
import strings from "../../localization";
import DeceasedBroughtForm from '../../components/forms/deceasedBrought/DeceasedBroughtForm';
import { bindActionCreators } from "redux";
import { withSnackbar } from 'notistack';
import connect from "react-redux/es/connect/connect";
import * as Actions from "../../actions/Actions";
import { withRouter } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import { Paper } from "@material-ui/core";

class ViewDeceasedBrought extends FormComponent {
  constructor(props) {
    super(props);

    this.state = {
      data: props.data ? props.data : {},
      errors: {}
    };

    this.props.changeFullScreen(false);
  }

  componentDidMount() {
    getDeceasedBroughtById(this.props.id).then(response => {
      this.setState({
        data: response.data
      });
    });
  }

  render() {
    return (
      <Grid id="page" item md={12}>
        <div className="header">
          <h1>{strings.deceasedBrought.viewPageTitle}</h1>
        </div>

        <Paper className="paper">
          <DeceasedBroughtForm
            data={this.state.data}
            isDisabled={this.props.isDisabled}
          />
        </Paper>
      </Grid>
    );
  }

}

function mapDispatchToProps(dispatch) {

  return bindActionCreators(
    {
      changeFullScreen: Actions.changeFullScreen
    },
    dispatch
  );
}

function mapStateToProps({ menuReducers, siteDataReducers }) {

  return { menu: menuReducers, siteData: siteDataReducers };
}

export default withSnackbar(
  withRouter(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(ViewDeceasedBrought)
  )
);
