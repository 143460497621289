import React from 'react';
import strings from "../../localization";
import { bindActionCreators } from "redux";
import { withSnackbar } from "notistack";
import { withRouter } from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import * as Actions from "../../actions/Actions";
import Page from '../../common/Page';
import { getRenewalTypes } from '../../util/ReviewsUtil';
import update from 'immutability-helper';
import { getAllGraveyards } from '../../services/graveyard/GraveyardService';
import { generateRenewalProcessingPdf, 
    getEditedRenewalsForProcessing, 
    loadProcessingReport, 
    getAllProcessingReports, 
    createProcessingReport, 
    generateRenewalProcessingPdfWithoutDiscount} from '../../services/renewalEdited/RenewalEditedService';

import IconButton from "@material-ui/core/IconButton";
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import SaveIcon from '@material-ui/icons/Save';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';

import RenewalProcessingHeader from './RenewalProcessingHeader';
import RenewalProcessingReportHeader from './RenewalProcessingReportHeader';
import { dateToString3, dateToStringDatePickerFormat3, dateTimeToString } from '../../util/DateUtil';
import RenewalProcessingResult from './RenewalProcessingResult';

class RenewalProcessingList extends Page {
    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            selectRenewalType: [],
            selectRenewalTypeKind: [],
            editedResult: false,
            data: {
                selectedRenewalType: { id: -1 },
                selectedRenewalTypeKind: { id: -1, value: -1 },
                selectedYear: { value: -1 },
                selectedGraveyard: { id: -1 },
                selectedGraveSite: { id: -1 },
                selectedReport: { id: -1 },
                field: null,
                plot: null,
                graveSiteNumber: null,
            },

        }

        this.state.sortBy = "renewalNumber,ASC";
        this.changeData = this.changeData.bind(this);
        this.find = this.find.bind(this);
        this.hideTableShowSearch = this.hideTableShowSearch.bind(this);
        this.loadReport = this.loadReport.bind(this);
    }

    componentDidMount() {
        this.setState({
            companyId: this.props.auth.user.company.id,
            selectRenewalType: renewalSearch(),
            selectRenewalTypeKind: getRenewalTypes(),


        });

        getAllGraveyards().then(response => {
            this.setState({
                ...this.state,
                graveyards: response.data.entities
            })
        });
        
        getAllProcessingReports().then(response => {
            const modifiedData = response.data.entities
                .map(item => {
                    const formattedDate = dateTimeToString(item.dateCreated);

                    return {
                        ...item,
                        dateCreated: formattedDate
                    };
                });

            this.setState({
                ...this.state,
                //reports: response.data.entities
                reports: modifiedData
            })
        });
    }

    componentWillReceiveProps(nextProps) {
        strings.setLanguage(nextProps.auth.user.language);
        this.setState({
            selectRenewalType: renewalSearch(),
            selectRenewalTypeKind: getRenewalTypes(),
        });
    }

    changeData(event, data = 'data') {
        this.setState({
            [data]: update(this.state[data], { [event.target.name]: { $set: event.target.value } })
        }, () => {
            if (event.target && event.target.name == 'renewalProcessingFromDate') {
                const renewalProcessingToDate = new Date(event.target.value);

                const is1stOfApril = (renewalProcessingToDate.getMonth() === 3 && renewalProcessingToDate.getDate() === 1);
                const is31stOfDecember = (renewalProcessingToDate.getMonth() === 11 && renewalProcessingToDate.getDate() === 31);

                if (is1stOfApril || is31stOfDecember) {
                    renewalProcessingToDate.setDate(renewalProcessingToDate.getDate());
                } else {
                    const date1stOfApril = new Date(renewalProcessingToDate.getFullYear(), 3, 1);
                    const date31stOfDecember = new Date(renewalProcessingToDate.getFullYear(), 11, 31);

                    renewalProcessingToDate.setDate(renewalProcessingToDate.getDate() + 4);

                    const newDate = new Date(renewalProcessingToDate);
                    const fromDate = new Date(event.target.value);
                    
                    const is1stAprilOrBeyond = fromDate < date1stOfApril && newDate >= date1stOfApril;
                    const is31stDecemberOrBeyond = fromDate < date31stOfDecember && (newDate.getFullYear() != date31stOfDecember.getFullYear() || newDate.getDate() === 31);

                    if (is1stAprilOrBeyond) {
                        renewalProcessingToDate.setDate(date1stOfApril.getDate() - 1);
                    }
                    if (is31stDecemberOrBeyond) {
                        renewalProcessingToDate.setDate(30);
                        renewalProcessingToDate.setMonth(11);
                        renewalProcessingToDate.setFullYear(fromDate.getFullYear());
                    }
                }
                
                this.setState({
                    data: {
                        ...this.state.data,
                        renewalProcessingToDate: event.target.value ? renewalProcessingToDate : null,
                    }
                });
            }
        });
        this.writeLineObject(event);

        this.restartSelects(event.target.name);
    }

    restartSelects(eventName) {
        if (eventName == "selectedRenewalType") {
            this.setState({
                data: {
                    ...this.state.data,
                    selectedRenewalTypeKind: { id: -1, value: -1 },
                    selectedYear: { value: new Date().getFullYear() },
                    selectedGraveyard: { id: -1 },
                    selectedGraveSite: { id: -1 },
                }
            }, () => {
                this.setState({
                    obj: {
                        ...this.state.obj,
                        renewalTypeKind: null,
                        year: null,
                        selectedGraveSite: null,
                        selectedGraveyard: null,
                        deceasedNameSurname: null,
                        nameSurnameSubmitter: null,
                    }
                })
            });

        }
    }

    getPageHeader() {
        return <h1>{strings.renewalProcessing.headerTitle}</h1>;
    }



    writeLineObject(e) {
        if (e.target.name == "selectedRenewalType") {
            this.setState({
                obj: {
                    ...this.state.obj,
                    renewalType: e.target.value
                },
                data: {
                    ...this.state.data,
                    selectedRenewalType: e.target.value
                }
            })


        }

        if (e.target.name == "selectedRenewalTypeKind") {
            this.setState({
                obj: {
                    ...this.state.obj,
                    renewalTypeKind: e.target.value
                }
            })
        }

        if (e.target.name == "graveyard") {
            this.setState({
                data: {
                    ...this.state.data,
                    selectedGraveyard: e.target.value
                },
                obj: {
                    ...this.state.obj,
                    selectedGraveyard: e.target.value
                }
            })
        }

        if (e.target.name == "selectedYear") {
            this.setState({
                obj: {
                    ...this.state.obj,
                    year: e.target.value
                }
            })
        }

        if (e.target.name == "graveSite") {
            this.setState({
                obj: {
                    ...this.state.obj,
                    selectedGraveSite: e.target.value
                }
            })

        }

        if (e.target.name == "selectedReport") {
            this.setState({
                data: {
                    ...this.state.data,
                    selectedReport: e.target.value
                },
                obj: {
                    ...this.state.obj,
                    selectedReport: e.target.value
                }
            })
        }
    }

    onAutocompleteChange = (event, values) => {
        let autocompleteName = event.target.id.split("-")[0];
        this.setState({
            data: {
                ...this.state.data, [autocompleteName]: values
            },
            obj: {
                ...this.state.obj,
                selectedGraveSite: values
            }
        });
    }

    handleSaveReport() {
        let data = {
            renewals: this.state.responseData,
        }

        createProcessingReport(data).then(response => {
            if (response.ok) {
                this.props.enqueueSnackbar(strings.renewalList.reportSaved, {
                    variant: "success",
                  });
                this.hideTableShowSearch();
            }

            getAllProcessingReports().then(response => {
                this.setState({
                    ...this.state,
                    reports: response.data.entities
                })
            });
        });
    }

    loadReport() {
        loadProcessingReport(this.state.data.selectedReport.id).then(response => {
            this.setState({
                showTable: true,
                responseData: response.data.renewals,
            }, () => {
                this.setState({
                    tableData: this.state.responseData,
                    total: response.data.renewals.length,
                });
            });
        })
    }

    find() {
        let data = {
            page: 0,
            size: 5000,
            graveyardId: this.state.obj.selectedGraveyard != null ? this.state.obj.selectedGraveyard.id : -1,
            field: this.state.data.field != null ? this.state.data.field : "",
            plot: this.state.data.plot !=null ? this.state.data.plot : "",
            graveSiteNumber: this.state.data.graveSiteNumber != null ? this.state.data.graveSiteNumber : "",
            fromDate: dateToStringDatePickerFormat3(this.state.data.renewalProcessingFromDate),
            toDate: dateToStringDatePickerFormat3(this.state.data.renewalProcessingToDate),
            companyId: this.state.companyId,
            userId: this.props.auth.user.id,
            selectedRenewalTypeKind: this.state.data.selectedRenewalTypeKind.id,
        };

        {
            (!this.state.editedResult) && 
                getEditedRenewalsForProcessing(data).then(response => {
                    this.setState({
                        showTable: true,
                        responseData: response.data.entities,
                    }, () => {
                        this.setState({
                            tableData: this.state.responseData,
                            total: response.data.total,
                        });
                    });
                });
        }
        // {
        //     (this.state.editedResult) &&
        //         getEditedRenewalsForProcessing(data).then(response => {
        //             const modifiedData = this.state.responseData
        //                 .map(current => {
        //                     const match = response.data.entities.find(item => item.id === current.id);
        
        //                     if(match) {
        //                         return match;
        //                     }
        
        //                     return current;
        //                 });
        
        //             this.setState({
        //                 showTable: true,
        //                 responseData: modifiedData,
        //                 tableData: modifiedData,
        //                 editedResult: false,
        //             });
        //         });
        // }
    }

    hideTableShowSearch() {
        this.setState({
            showTable: false
        })
    }

    // handleEditCompleted = () => {
    //     this.setState({
    //         editedResult: true
    //     }, () => {
    //         this.find();
    //     })
    // }

    handleEditCompleted = (modifiedItem) => {
        const modifiedData = this.state.responseData
            .map(current => {
                return (current.id === modifiedItem.id)
                    ? modifiedItem
                    : current
        });

        this.setState({
            responseData: modifiedData,
            tableData: modifiedData,
        });
    }

    handleRemoveItemFromProcessingList = (item) => {
        this.setState(prevState => {
            const modifiedData = prevState.responseData
                .map(current => (current.id === item.id) ? null : current)
                .filter(item => item !== null);

            return {
                responseData: modifiedData,
                tableData: modifiedData,
                total: this.state.total - 1,
            }
        });
    }

    handleGeneratePdf = (discount) => {
        this.setState({
            loading: true,
        });

        let data = {};

        if (discount) {
            data = {
            renewals: this.state.responseData.map(item => ({
                ...item, 
                discount: true
                }))
            }
        } else {
            data = {
                renewals: this.state.responseData,
            }
        }

        generateRenewalProcessingPdf(data).then(response => {
            const blob = new Blob([response.data], { type: 'application/pdf' });

            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            
            const period = `${dateToString3(this.state.data.renewalProcessingFromDate)}-${dateToString3(this.state.data.renewalProcessingToDate)}`;

            link.download = `Obrade-${period}.pdf`;

            document.body.appendChild(link);

            link.click();

            document.body.removeChild(link);

            this.setState({
                loading: false,
            });
        });
    }

    render() {
        return (
            <div className='header'>
                {this.getPageHeader()}
                {!this.state.showTable &&
                    <div>
                        <RenewalProcessingHeader
                            selectRenewalType={this.state.selectRenewalType}
                            selectRenewalTypeKind={this.state.selectRenewalTypeKind}
                            onChange={this.changeData}
                            selectedYear={this.state.data.selectedYear}
                            selectedRenewalType={this.state.obj != undefined ? this.state.obj.renewalType : ""}
                            selectedRenewalTypeKind={this.state.obj != undefined ? this.state.obj.renewalTypeKind : ""}
                            selectedGraveyard={this.state.data.selectedGraveyard}
                            selectedGraveSite={this.state.data.selectedGraveSite}
                            years={this.state.years}
                            graveyards={this.state.graveyards}
                            graveSites={this.state.graveSites}
                            autocompleteChange={this.onAutocompleteChange}
                            handleTextInputChange={this.handleTextInputChange}
                            onKeyDown={this.keyPressBackspace}
                            renewalType={this.state.obj != undefined ? this.state.obj.renewalType : ""}
                            find={this.find}
                            data={this.state.data}
                            obj={this.state.obj}
                        />
                        <RenewalProcessingReportHeader
                            onChange={this.changeData}
                            reports={this.state.reports}
                            selectedReport={this.state.data.selectedReport}
                            autocompleteChange={this.onAutocompleteChange}
                            handleTextInputChange={this.handleTextInputChange}
                            onKeyDown={this.keyPressBackspace}
                            load={this.loadReport}
                            data={this.state.data}
                            obj={this.state.obj}
                        />
                    </div>
                }

                {this.state.showTable &&
                    <div>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <h4>Укупно резултата: { this.state.total }</h4>
                            <IconButton onClick={() => this.handleSaveReport()}>
                                    <SaveIcon />
                                    <Typography variant="button">{strings.review.buttons.saveProcessingReport}</Typography>
                            </IconButton>
                            <IconButton onClick={() => this.hideTableShowSearch()}>
                                    <ArrowBackIosIcon />
                                    <Typography variant="button">{strings.review.buttons.redefineSearch}</Typography>
                            </IconButton>
                        </div>
                        <RenewalProcessingResult
                            data={this.state.responseData}
                            selectedRenewalTypeKind={this.state.data.selectedRenewalTypeKind}
                            hideTableShowSearch={this.state.showTable}
                            onEditCompleted={this.handleEditCompleted}
                            handleRemoveItemFromProcessingList={this.handleRemoveItemFromProcessingList}
                        />
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <div>
                                <IconButton onClick={() => this.hideTableShowSearch()}>
                                    <ArrowBackIosIcon />
                                    <Typography variant="button">{strings.review.buttons.back}</Typography>
                                </IconButton>
                            </div>

                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <IconButton onClick={() => this.handleGeneratePdf(true)}>
                                    { this.state.loading 
                                        ? (<CircularProgress size={24} color="inherit" />) 
                                        : (<InsertDriveFileIcon />)
                                    }
                                    <Typography variant="button">{strings.review.buttons.generatePDF}</Typography>
                                </IconButton>

                                <IconButton onClick={() => this.handleGeneratePdf(false)}>
                                    { this.state.loading 
                                        ? (<CircularProgress size={24} color="inherit" />) 
                                        : (<InsertDriveFileIcon />)
                                    }
                                    <Typography variant="button">{strings.review.buttons.generatePDFDiscount}</Typography>
                                </IconButton>
                            </div>
                        </div>
                    </div>
                }
            </div>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        changeFullScreen: Actions.changeFullScreen
    }, dispatch);
}

function mapStateToProps({ menuReducers, authReducers, siteDataReducers }) {
    return { menu: menuReducers, auth: authReducers, siteData: siteDataReducers };
}

function renewalSearch() {
    return [
        { id: 0, value: null, name: strings.renewalTypes.all },
        { id: 1, value: strings.renewalTypes.submitterNameSurname, name: strings.renewalTypes.submitterNameSurname },
        { id: 2, value: strings.renewalTypes.deceasedNameSurname, name: strings.renewalTypes.deceasedNameSurname },
        { id: 3, value: strings.renewalTypes.graveSite, name: strings.renewalTypes.graveSite },
    ];
}

export default withSnackbar(withRouter(connect(mapStateToProps, mapDispatchToProps)(RenewalProcessingList)));
