import React from 'react';
import strings from "../../../localization";
import { getError, hasError } from "../../../functions/Validation";
import { Button, TextField, FormLabel, FormControlLabel, Radio, FormControl, RadioGroup } from "@material-ui/core";
import DatePickerControl from '../../controls/DatePickerControl';
import Autocomplete from '@material-ui/lab/Autocomplete';

const DeathReportFuneralSchedulingForm = ({
    onSubmit,
    onCancel,
    onChange,
    errors,
    data,
    isDisabled,
    handleChange,
    onAutocompleteChange,
    handleTextInputChange,
    submitters,
    submitter,
    flagAddNewSubmitter,
    renderAddCitizen,
    flagMarginValid,
    flagAddNewCost,
    flagMarginValid2,
    costs,
    cost,
    identificationSubmitterEqualsIdentificationCosts,
    identificationCostsEqualsIdentificationUser,
    userGraveSite,
    users,
    costSameAsSubmitter,
    userSameAsCost,
    userSameAsSubmitter,
    identificationSubmitterEqualsIdentificationUser,
    costInfo,
    userInfo,
    firmInfo,
    flag,
    firms,
    firmCost,
    disabled,
    flagToClose,
    openCloseScheduledFuneralDrawer
}) => {
    let renderAddCitizenButton = {
        marginLeft: "289px",
        marginTop: "-114px",
        paddingBottom: "-300px"
    }

    console.log("data", data)

    return (
        <form id='user-form'>
            <label className="header-label"> {strings.funeralScheduling.form.submitter} </label>
            {((data.municipality && flag == "edit") || (flag == "add") || (data.transporter && flag == "view")) &&
                <Autocomplete
                    id={"submitter"}
                    name={"submitter"}
                    options={submitters}
                    getOptionLabel={option => option.personalNumber}
                    onChange={onAutocompleteChange}
                    renderInput={params => (
                        <TextField
                            {...params}
                            variant="standard"
                            label={strings.funeralScheduling.form.identificationNumber}
                            margin="normal"
                            style={{ width: 275 }}
                            fullWidth
                            name={"submitter"}
                            onChange={handleTextInputChange}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    )}
                    disabled={isDisabled}
                    value={data.submitter}
                />
            }
            {((!data.municipality && flag == "edit") || (!data.transporter && flag == "view")) &&
                <Autocomplete
                    id={"submitter"}
                    name={"submitter"}
                    options={submitters}
                    getOptionLabel={option => option.personalNumber}
                    onChange={onAutocompleteChange}
                    renderInput={params => (
                        <TextField
                            {...params}
                            variant="standard"
                            label={strings.funeralScheduling.form.identificationNumber}
                            margin="normal"
                            style={{ width: 275 }}
                            fullWidth
                            name={"submitter"}
                            onChange={handleTextInputChange}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    )}
                    disabled={isDisabled}
                    value={data.submitter}
                />
            }

            {flagAddNewSubmitter == true && flagMarginValid == true &&
                <div className='submit-container'>
                    <Button variant="contained" color="secondary" onClick={renderAddCitizen} style={renderAddCitizenButton}>
                        {strings.funeralScheduling.form.addNewSubmitter}
                    </Button>
                </div>
            }

            {flagAddNewSubmitter == true && flagMarginValid == false &&
                <div className='submit-container'>
                    <Button variant="contained" color="secondary" onClick={renderAddCitizen} style={renderAddCitizenButton}>
                        {strings.funeralScheduling.form.addNewSubmitter}
                    </Button>
                    <div class="renderAddCitizenButtonNotValidPersonalNumber">
                        {strings.citizen.messages.CITIZEN_PERSONAL_NUMBER_NOT_VALID}
                    </div>
                </div>
            }

            <TextField
                label={strings.funeralScheduling.form.nameSurnameSubmitter}
                error={hasError(errors, 'nameSurnameSubmitter')}
                helperText={getError(errors, 'nameSurnameSubmitter')}
                fullWidth
                name='nameSurnameSubmitter'
                onChange={onChange}
                margin="normal"
                defaultValue={submitter.nameSurnameSubmitter}
                value={submitter.nameSurnameSubmitter}
                InputLabelProps={{
                    shrink: true,
                }}
                disabled={true}
            />

            <TextField
                label={strings.funeralScheduling.form.submitterNameOfOneParent}
                error={hasError(errors, 'submitterNameOfOneParent')}
                helperText={getError(errors, 'submitterNameOfOneParent')}
                fullWidth
                name='submitterNameOfOneParent'
                onChange={onChange}
                margin="normal"
                defaultValue={data.submitterNameOfOneParent}
                value={data.submitterNameOfOneParent}
                InputLabelProps={{
                    shrink: true,
                }}
                disabled={isDisabled}
            />

            <TextField
                label={strings.funeralScheduling.form.addressSubmitter}
                error={hasError(errors, 'addressSubmitter')}
                helperText={getError(errors, 'addressSubmitter')}
                fullWidth
                name='addressSubmitter'
                onChange={onChange}
                margin="normal"
                defaultValue={submitter.addressSubmitter}
                value={submitter.addressSubmitter}
                InputLabelProps={{
                    shrink: true,
                }}
                disabled={true}
            />

            <TextField
                label={strings.funeralScheduling.form.municipalitySubmitter}
                error={hasError(errors, 'municipalitySubmitter')}
                helperText={getError(errors, 'municipalitySubmitter')}
                fullWidth
                name='municipalitySubmitter'
                onChange={onChange}
                margin="normal"
                defaultValue={submitter.municipalitySubmitter}
                value={submitter.municipalitySubmitter}
                InputLabelProps={{
                    shrink: true,
                }}
                disabled={true}
            />

            <TextField
                label={strings.funeralScheduling.form.telephoneSubmitter}
                error={hasError(errors, 'telephoneSubmitter')}
                helperText={getError(errors, 'telephoneSubmitter')}
                fullWidth
                name='telephoneSubmitter'
                onChange={onChange}
                margin="normal"
                defaultValue={submitter.telephoneSubmitter}
                value={submitter.telephoneSubmitter}
                InputLabelProps={{
                    shrink: true,
                }}
                disabled={true}
            />

            <TextField
                label={strings.funeralScheduling.form.IDCardOrPassportNumber}
                error={hasError(errors, 'submitterIDCardOrPassportNumber')}
                helperText={getError(errors, 'submitterIDCardOrPassportNumber')}
                fullWidth
                name='submitterIDCardOrPassportNumber'
                onChange={onChange}
                margin="normal"
                defaultValue={data.submitterIDCardOrPassportNumber}
                value={data.submitterIDCardOrPassportNumber}
                InputLabelProps={{
                    shrink: true,
                }}
                disabled={isDisabled}
            />

            <TextField
                label={strings.funeralScheduling.form.IDCardOrPassportIssuer}
                error={hasError(errors, 'submitterIDCardOrPassportIssuer')}
                helperText={getError(errors, 'submitterIDCardOrPassportIssuer')}
                fullWidth
                name='submitterIDCardOrPassportIssuer'
                onChange={onChange}
                margin="normal"
                defaultValue={data.submitterIDCardOrPassportIssuer}
                value={data.submitterIDCardOrPassportIssuer}
                InputLabelProps={{
                    shrink: true,
                }}
                disabled={isDisabled}
            />

            <DatePickerControl
                label={strings.funeralScheduling.form.IDCardOrPassportIssueDate}
                error={getError(errors, 'submitterIDCardOrPassportIssueDate')}
                hasError={hasError(errors, 'submitterIDCardOrPassportIssueDate')}
                helperText={getError(errors, 'submitterIDCardOrPassportIssueDate')}
                placeholder={strings.funeralScheduling.form.IDCardOrPassportIssueDate}
                name='submitterIDCardOrPassportIssueDate'
                onChange={onChange}
                date={data.submitterIDCardOrPassportIssueDate ? new Date(data.submitterIDCardOrPassportIssueDate) : data.submitterIDCardOrPassportIssueDate}
                isClearable={true}
                dateFormat='dd/MM/yyyy'
                disabled={isDisabled}
            />

            <TextField
                label={strings.funeralScheduling.form.relationToDeceased}
                error={hasError(errors, 'submitterRelationToDeceased')}
                helperText={getError(errors, 'submitterRelationToDeceased')}
                fullWidth
                name='submitterRelationToDeceased'
                onChange={onChange}
                margin="normal"
                defaultValue={data.submitterRelationToDeceased}
                value={data.submitterRelationToDeceased}
                InputLabelProps={{
                    shrink: true,
                }}
                disabled={isDisabled}
            />

            <label className="header-label"> {strings.mainBookOfBuried.form.cost} </label>
            {flag != "view" &&
                <Button
                    variant="contained"
                    color={costSameAsSubmitter ? "default" : "primary"}
                    className="formButton"
                    onClick={identificationSubmitterEqualsIdentificationCosts}
                    disabled={submitter.nameSurnameSubmitter == null ? true : false}
                >
                    {strings.mainBookOfBuried.form.submitter}
                </Button>
            }

            <FormControl component="fieldset">
                <FormLabel className="checkboxLabel"> {strings.funeralScheduling.form.firmPays} </FormLabel>
                <RadioGroup name="firmPays" value={(data.firmPays == true || data.firmPays == "true") ? "true" : "false"} onChange={handleChange} className="radioGroup">
                    <FormControlLabel value={"true"} disabled={isDisabled} control={<Radio />} label={<span className="formControlLabel">{strings.funeralScheduling.form.yes}</span>} />
                    <FormControlLabel value={"false"} disabled={isDisabled} control={<Radio />} label={<span className="formControlLabel">{strings.funeralScheduling.form.no}</span>} />
                </RadioGroup>
            </FormControl>

            {(data.firmPays == "true" || data.firmPays == true) &&
                <Autocomplete
                    id={"firmCost"}
                    name={"firmCost"}
                    options={firms}
                    getOptionLabel={option => option.name}
                    onChange={onAutocompleteChange}
                    renderInput={params => (
                        <TextField
                            {...params}
                            variant="standard"
                            label={strings.funeralScheduling.form.firmCost}
                            margin="normal"
                            style={{ width: 275 }}
                            fullWidth
                            name={"firmCost"}
                            onChange={handleTextInputChange}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    )}
                    disabled={isDisabled}
                    value={data.firmCost ? data.firmCost : firmCost}
                />
            }

            {(data.firmPays == 'false' || !data.firmPays) &&
                <Autocomplete
                    id={"cost"}
                    name={"cost"}
                    options={costs}
                    getOptionLabel={option => option.personalNumber}
                    onChange={onAutocompleteChange}
                    renderInput={params => (
                        <TextField
                            {...params}
                            variant="standard"
                            label={strings.funeralScheduling.form.identificationNumber}
                            margin="normal"
                            style={{ width: 275 }}
                            fullWidth
                            name={"cost"}
                            onChange={handleTextInputChange}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    )}
                    disabled={isDisabled}
                    value={data.cost ? data.cost : cost}
                />
            }

            {flagAddNewCost == true && flagMarginValid2 == true &&
                <div className='submit-container'>
                    <Button variant="contained" color="secondary" onClick={renderAddCitizen} style={renderAddCitizenButton}>
                        {strings.funeralScheduling.form.addNewCost}
                    </Button>
                </div>
            }

            {flagAddNewCost == true && flagMarginValid2 == false &&
                <div className='submit-container'>
                    <Button variant="contained" color="secondary" onClick={renderAddCitizen} style={renderAddCitizenButton}>
                        {strings.funeralScheduling.form.addNewCost}
                    </Button>
                    <div class="renderAddCitizenButtonNotValidPersonalNumber">
                        {strings.citizen.messages.CITIZEN_PERSONAL_NUMBER_NOT_VALID}
                    </div>
                </div>
            }

            {(data.firmPays == "true" || data.firmPays == true) &&
                <React.Fragment>
                    <TextField
                        label={strings.funeralScheduling.form.taxNumberCosts}
                        error={hasError(errors, 'taxNumberCost')}
                        helperText={getError(errors, 'taxNumberCost')}
                        fullWidth
                        name='taxNumberCost'
                        onChange={onChange}
                        margin="normal"
                        defaultValue={firmInfo.taxNumberCost}
                        value={firmInfo.taxNumberCost}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        disabled={true}
                    />
                    <TextField
                        label={strings.funeralScheduling.form.headquarters}
                        error={hasError(errors, 'headquarters')}
                        helperText={getError(errors, 'headquarters')}
                        fullWidth
                        name='headquarters'
                        onChange={onChange}
                        margin="normal"
                        defaultValue={firmInfo.headquarters}
                        value={firmInfo.headquarters}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        disabled={true}
                    />
                    <TextField
                        label={strings.funeralScheduling.form.telephoneCosts}
                        error={hasError(errors, 'telephoneFirm')}
                        helperText={getError(errors, 'telephoneFirm')}
                        fullWidth
                        name='telephoneFirm'
                        onChange={onChange}
                        margin="normal"
                        defaultValue={firmInfo.telephoneFirm}
                        value={firmInfo.telephoneFirm}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        disabled={true}
                    />
                    <TextField
                        label={strings.funeralScheduling.form.contactPerson}
                        error={hasError(errors, 'contactPerson')}
                        helperText={getError(errors, 'contactPerson')}
                        fullWidth
                        name='contactPerson'
                        onChange={onChange}
                        margin="normal"
                        defaultValue={firmInfo.contactPerson}
                        value={firmInfo.contactPerson}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        disabled={true}
                    />
                </React.Fragment> 
            }

            {(data.firmPays == 'false' || !data.firmPays) &&
                <React.Fragment>
                    <TextField
                        label={strings.funeralScheduling.form.nameSurnameCosts}
                        error={hasError(errors, 'nameSurnameCosts')}
                        helperText={getError(errors, 'nameSurnameCosts')}
                        fullWidth
                        name='nameSurnameCosts'
                        onChange={onChange}
                        margin="normal"
                        defaultValue={costInfo.nameSurnameCosts}
                        value={costInfo.nameSurnameCosts}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        disabled={true}
                    />

                    <TextField
                        label={strings.funeralScheduling.form.costNameOfOneParent}
                        error={hasError(errors, 'costNameOfOneParent')}
                        helperText={getError(errors, 'costNameOfOneParent')}
                        fullWidth
                        name='costNameOfOneParent'
                        onChange={onChange}
                        margin="normal"
                        defaultValue={data.costNameOfOneParent}
                        value={data.costNameOfOneParent}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        disabled={isDisabled}
                    />

                    <TextField
                        label={strings.funeralScheduling.form.addressCosts}
                        error={hasError(errors, 'addressCosts')}
                        helperText={getError(errors, 'addressCosts')}
                        fullWidth
                        name='addressCosts'
                        onChange={onChange}
                        margin="normal"
                        defaultValue={costInfo.addressCosts}
                        value={costInfo.addressCosts}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        disabled={true}
                    />

                    <TextField
                        label={strings.funeralScheduling.form.municipalityCosts}
                        error={hasError(errors, 'municipalityCosts')}
                        helperText={getError(errors, 'municipalityCosts')}
                        fullWidth
                        name='municipalityCosts'
                        onChange={onChange}
                        margin="normal"
                        defaultValue={costInfo.municipalityCosts}
                        value={costInfo.municipalityCosts}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        disabled={true}
                    />

                    <TextField
                        label={strings.funeralScheduling.form.telephoneCosts}
                        error={hasError(errors, 'telephoneCosts')}
                        helperText={getError(errors, 'telephoneCosts')}
                        fullWidth
                        name='telephoneCosts'
                        onChange={onChange}
                        margin="normal"
                        defaultValue={costInfo.telephoneCosts}
                        value={costInfo.telephoneCosts}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        disabled={true}
                    />

                    <TextField
                        label={strings.funeralScheduling.form.IDCardOrPassportNumber}
                        error={hasError(errors, 'costIDCardOrPassportNumber')}
                        helperText={getError(errors, 'costIDCardOrPassportNumber')}
                        fullWidth
                        name='costIDCardOrPassportNumber'
                        onChange={onChange}
                        margin="normal"
                        defaultValue={data.costIDCardOrPassportNumber}
                        value={data.costIDCardOrPassportNumber}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        disabled={isDisabled}
                    />

                    <TextField
                        label={strings.funeralScheduling.form.IDCardOrPassportIssuer}
                        error={hasError(errors, 'costIDCardOrPassportIssuer')}
                        helperText={getError(errors, 'costIDCardOrPassportIssuer')}
                        fullWidth
                        name='costIDCardOrPassportIssuer'
                        onChange={onChange}
                        margin="normal"
                        defaultValue={data.costIDCardOrPassportIssuer}
                        value={data.costIDCardOrPassportIssuer}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        disabled={isDisabled}
                    />

                    <DatePickerControl
                        label={strings.funeralScheduling.form.IDCardOrPassportIssueDate}
                        error={getError(errors, 'costIDCardOrPassportIssueDate')}
                        hasError={hasError(errors, 'costIDCardOrPassportIssueDate')}
                        helperText={getError(errors, 'costIDCardOrPassportIssueDate')}
                        placeholder={strings.funeralScheduling.form.IDCardOrPassportIssueDate}
                        name='costIDCardOrPassportIssueDate'
                        onChange={onChange}
                        date={data.costIDCardOrPassportIssueDate ? new Date(data.costIDCardOrPassportIssueDate) : data.costIDCardOrPassportIssueDate}
                        isClearable={true}
                        dateFormat='dd/MM/yyyy'
                        disabled={isDisabled}
                    />

                    <TextField
                        label={strings.funeralScheduling.form.relationToDeceased}
                        error={hasError(errors, 'costRelationToDeceased')}
                        helperText={getError(errors, 'costRelationToDeceased')}
                        fullWidth
                        name='costRelationToDeceased'
                        onChange={onChange}
                        margin="normal"
                        defaultValue={data.costRelationToDeceased}
                        value={data.costRelationToDeceased}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        disabled={isDisabled}
                    />
                </React.Fragment>
            }

            <label className="header-label"> {strings.funeralScheduling.form.graveSiteUser} </label>
            {flag != "view" &&
                <Button
                    variant="contained"
                    color={userSameAsSubmitter ? "default" : "primary"}
                    className="formButton"
                    onClick={identificationSubmitterEqualsIdentificationUser}
                    disabled={submitter.nameSurnameSubmitter == null ? true : false || userSameAsCost == true ? true : false}
                >
                    {strings.funeralScheduling.form.shorthenedSubmitter}
                </Button>
            }
            {flag != "view" &&
                <Button
                    variant="contained"
                    color={userSameAsCost ? "default" : "primary"}
                    className="formButton"
                    onClick={identificationCostsEqualsIdentificationUser}
                    disabled={costInfo.nameSurnameCosts == null ? true : false || userSameAsSubmitter == true ? true : false || costInfo.nameSurnameCosts == "" ? true : false}
                >
                    {strings.funeralScheduling.form.shorthenedCosts}
                </Button>
            }

            <Autocomplete
                id={"userGraveSite"}
                name={"userGraveSite"}
                options={users}
                getOptionLabel={option => option.personalNumber}
                onChange={onAutocompleteChange}
                renderInput={params => (
                    <TextField
                        {...params}
                        variant="standard"
                        label={strings.funeralScheduling.form.identificationNumber}
                        margin="normal"
                        fullWidth
                        name={"userGraveSite"}
                        onChange={handleTextInputChange}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                )}
                disabled={isDisabled}
                value={data.userGraveSite ? data.userGraveSite : userGraveSite}
            />

            <TextField
                label={strings.funeralScheduling.form.nameSurnameUser}
                error={hasError(errors, 'nameSurnameUser')}
                helperText={getError(errors, 'nameSurnameUser')}
                fullWidth
                name='nameSurnameUser'
                onChange={onChange}
                margin="normal"
                defaultValue={userInfo.nameSurnameUser}
                value={userInfo.nameSurnameUser}
                InputLabelProps={{
                    shrink: true,
                }}
                disabled={true}
            />

            <TextField
                label={strings.funeralScheduling.form.userNameOfOneParent}
                error={hasError(errors, 'userNameOfOneParent')}
                helperText={getError(errors, 'userNameOfOneParent')}
                fullWidth
                name='userNameOfOneParent'
                onChange={onChange}
                margin="normal"
                defaultValue={data.userNameOfOneParent}
                value={data.userNameOfOneParent}
                InputLabelProps={{
                    shrink: true,
                }}
                disabled={isDisabled}
            />

            <TextField
                label={strings.funeralScheduling.form.addressUser}
                error={hasError(errors, 'addressUser')}
                helperText={getError(errors, 'addressUser')}
                fullWidth
                name='addressUser'
                onChange={onChange}
                margin="normal"
                defaultValue={userInfo.addressUser}
                value={userInfo.addressUser}
                InputLabelProps={{
                    shrink: true,
                }}
                disabled={true}
            />

            <TextField
                label={strings.funeralScheduling.form.municipalityUser}
                error={hasError(errors, 'municipalityUser')}
                helperText={getError(errors, 'municipalityUser')}
                fullWidth
                name='municipalityUser'
                onChange={onChange}
                margin="normal"
                defaultValue={userInfo.municipalityUser}
                value={userInfo.municipalityUser}
                InputLabelProps={{
                    shrink: true,
                }}
                disabled={true}
            />

            <TextField
                label={strings.funeralScheduling.form.telephoneUser}
                error={hasError(errors, 'telephoneUser')}
                helperText={getError(errors, 'telephoneUser')}
                fullWidth
                name='telephoneUser'
                onChange={onChange}
                margin="normal"
                defaultValue={userInfo.telephoneUser}
                value={userInfo.telephoneUser}
                InputLabelProps={{
                    shrink: true,
                }}
                disabled={true}
            />

            <TextField
                label={strings.funeralScheduling.form.IDCardOrPassportNumber}
                error={hasError(errors, 'userIDCardOrPassportNumber')}
                helperText={getError(errors, 'userIDCardOrPassportNumber')}
                fullWidth
                name='userIDCardOrPassportNumber'
                onChange={onChange}
                margin="normal"
                defaultValue={data.userIDCardOrPassportNumber}
                value={data.userIDCardOrPassportNumber}
                InputLabelProps={{
                    shrink: true,
                }}
                disabled={isDisabled}
            />

            <TextField
                label={strings.funeralScheduling.form.IDCardOrPassportIssuer}
                error={hasError(errors, 'userIDCardOrPassportIssuer')}
                helperText={getError(errors, 'userIDCardOrPassportIssuer')}
                fullWidth
                name='userIDCardOrPassportIssuer'
                onChange={onChange}
                margin="normal"
                defaultValue={data.userIDCardOrPassportIssuer}
                value={data.userIDCardOrPassportIssuer}
                InputLabelProps={{
                    shrink: true,
                }}
                disabled={isDisabled}
            />

            <DatePickerControl
                label={strings.funeralScheduling.form.IDCardOrPassportIssueDate}
                error={getError(errors, 'userIDCardOrPassportIssueDate')}
                hasError={hasError(errors, 'userIDCardOrPassportIssueDate')}
                helperText={getError(errors, 'userIDCardOrPassportIssueDate')}
                placeholder={strings.funeralScheduling.form.IDCardOrPassportIssueDate}
                name='userIDCardOrPassportIssueDate'
                onChange={onChange}
                date={data.userIDCardOrPassportIssueDate ? new Date(data.userIDCardOrPassportIssueDate) : data.userIDCardOrPassportIssueDate}
                isClearable={true}
                dateFormat='dd/MM/yyyy'
                disabled={isDisabled}
            />

            <TextField
                label={strings.funeralScheduling.form.relationToDeceased}
                error={hasError(errors, 'userRelationToDeceased')}
                helperText={getError(errors, 'userRelationToDeceased')}
                fullWidth
                name='userRelationToDeceased'
                onChange={onChange}
                margin="normal"
                defaultValue={data.userRelationToDeceased}
                value={data.userRelationToDeceased}
                InputLabelProps={{
                    shrink: true,
                }}
                disabled={isDisabled}
            />
            {!isDisabled &&
            <div className='submit-container' style={{ padding: '5px' }}>
            <Button variant="contained" color="primary" disabled={isDisabled} onClick={onSubmit}>
                {strings.funeralScheduling.form.ok}
            </Button>
            <Button variant="contained" color="secondary" onClick={flagToClose === "appointments" ? openCloseScheduledFuneralDrawer : onCancel}>
                {strings.funeralScheduling.form.cancel}
            </Button>
            </div>
            }
        </form>
    )
};

export default DeathReportFuneralSchedulingForm;