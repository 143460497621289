import React from 'react'
import { bindActionCreators } from "redux";
import * as Actions from "../../actions/Actions";
import { withRouter } from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import Grid from '@material-ui/core/Grid';
import strings from "../../localization";
import Validators from "../../constants/ValidatorTypes";
import FormComponent from "../../common/FormComponent";
import { withSnackbar } from "notistack";
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';

import DeceasedDetailsEquipmentOrderForm from '../../components/forms/equipmentOrder/DeceasedDetailsEquipmentOrderForm';
import EquipmentOrderForm from '../../components/forms/equipmentOrder/EquipmentOrderForm';

import { getDeceasedPreparations, getLetterTypes, getLetters, getTombstones, getTransportOptions } from '../../util/ReviewsUtil';
import { addEquipmentOrder } from '../../services/equipmentOrder/EquipmentOrderService';
import { dateToString2 } from '../../util/DateUtil';
import { getTypefaces } from '../../services/typeface/TypefaceService';
import { calculateAmount, calculateTotalAmount } from '../../util/CalculationsUtil';
import { validatePersonalNumber } from '../../functions/Validation';

function TabContainer(props) {
  return (
    <Typography className="tabContainer" component="div">
      {props.children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

class AddEquipmentOrder extends FormComponent {

  validationList = {
    deceasedNameSurname: [{ type: Validators.REQUIRED }],
    height: [{ type: Validators.IS_NEGATIVE_NUMBER }],
    weight: [{ type: Validators.IS_NEGATIVE_NUMBER }],
  };

  constructor(props) {
    super(props);

    this.state = {
      data: props.data ? props.data : {},
      errors: {},
      value: 0,
      disabled: false,
      dateOfBirthWhenValidIDNumber: [],
      letterType: [],
      letterTypes: [],
      tombstone: [],
      tombstones: {},
      letter: [],
      letters: {},
      deceasedPreparation: [],
      deceasedPreparations: {},
      transportOption: [],
      transportOptions: {},
    };

    this.state.sortBy = "name,ASC";

    this.props.changeFullScreen(false);

    this.submit = this.submit.bind(this);
  }

  componentDidMount() {
    this.setState({
      data: {
        ...this.state.data,
        height: 0,
        weight: 0,
        orderNumber: new Date().getFullYear(),
      },
      letterTypes: getLetterTypes(),
      tombstones: getTombstones(),
      letters: getLetters(),
      deceasedPreparations: getDeceasedPreparations(),
      transportOptions: getTransportOptions(),
    });
  }

  submit() {
    this.setState({ disabled: true })

    if (!this.validate()) {
      this.props.enqueueSnackbar(strings.error.requiredFields, { variant: 'error' });
      this.setState({ disabled: false })
      return;
    }

    let validPersonalNumber = validatePersonalNumber(this.state.data.identificationNumber)

    if (!validPersonalNumber) {
      this.setError("identificationNumber", strings.mainBookOfBuried.messages.MAIN_BOOK_OF_BURIED_IDENTIFICATION_NUMBER_NOT_VALID);
      this.props.enqueueSnackbar(strings.citizen.messages.CITIZEN_PERSONAL_NUMBER_NOT_VALID, { variant: 'error' });
      this.setState({ disabled: false });
    }

    this.showDrawerLoader();

    addEquipmentOrder(this.transformRequest(this.state.data), this.props.cid).then(response => {
      if (!response.ok) {
        let messageKey = response.response.data.message;

        this.props.enqueueSnackbar(strings.equipmentOrder.messages[messageKey], {
          variant: "error"
        });

        this.handleError(messageKey);

        return;
      }

      this.setState({
        disabled: false
      })
      this.props.enqueueSnackbar(strings.addCompany.equipmentOrderAdded, { variant: 'success' });
      this.props.onFinish(response.data.equipmentOrder);
      this.hideDrawerLoader();
    });
  }

  transformRequest(data) {
    return {
      ...data,
      userEmail: this.props.uemail,
      responsiblePersonId: this.props.responsiblePersonId,
      typefaceId: this.state.data?.typeface?.id ? this.state.data?.typeface?.id : null,
      
      letterType: this.state.letterType ? this.state.letterType.value : null,
      tombstone: this.state.tombstone ? this.state.tombstone.value : null,
      letters: this.state.letter ? this.state.letter.value : null,
      washDeceased: this.isSelectedDeceasedPreparation(strings.equipmentOrder.washDeceased),
      shaveDeceased: this.isSelectedDeceasedPreparation(strings.equipmentOrder.shaveDeceased),
      dressDeceased: this.isSelectedDeceasedPreparation(strings.equipmentOrder.dressDeceased),
      transportOption: this.state.transportOption ? this.state.transportOption.value : null,
      dateTimeOfFuneralString: this.state.data.dateTimeOfFuneral ? dateToString2(this.state.data.dateTimeOfFuneral) : "",
    }
  }  

  isSelectedDeceasedPreparation = (option) => {
    return this.state.deceasedPreparation.some(item => item.value === option);
  } 

  /* Handlers */

  handleError(message) {
    switch (message) {
      case "EQUIPMENT_ORDER_WITH_NAME_SURNAME_CANT_BE_NULL":
        this.setError("deceasedNameSurname", strings.equipmentOrder.messages.EQUIPMENT_ORDER_WITH_NAME_SURNAME_CANT_BE_NULL);
        break;
      // case "MAIN_BOOK_OF_BURIED_WITH_IDENTIFICATION_NUMBER_ALREADY_EXIST":
      //   this.setError("identificationNumber", strings.mainBookOfBuried.messages.MAIN_BOOK_OF_BURIED_WITH_IDENTIFICATION_NUMBER_ALREADY_EXIST);
      //   break;
    }
  }

  handleChangeTab = (event, value) => {
    this.setState({
      value: value
    });
  };

  handleChange = (event) => {
    const { name, value } = event.target;
  
    this.setState(prevState => {
      let newData = { ...prevState.data, [name]: value };
  
      if (name === 'cover' && prevState.data.cover !== value) {
        newData.coverType = null;
        newData.coverAmount = null;
      }
      if (name === 'obituaryNotice' && prevState.data.obituaryNotice !== value) {
        newData.obituaryAmount = null;
        newData.numberOfNotices = null;
      }
      if (name === 'tilesAndBadges' && prevState.data.tilesAndBadges !== value) {
        newData.tilesAndBadgesAmount = null;
        newData.numberOfTilesAndBadges = null;
      }
      if (name === 'floralTribute' && prevState.data.floralTribute !== value) {
        newData.floralTributeAmount = null;
        newData.numberOfFloralTributes = null;
      }
      if (name === 'scarves' && prevState.data.scarves !== value) {
        newData.scarvesAmount = null;
        newData.numberOfScarves = null;
      }
      if (name === 'towels' && prevState.data.towels !== value) {
        newData.towelsAmount = null;
        newData.numberOfTowels = null;
      }
      if (name === 'urn' && prevState.data.urn !== value) {
        newData.urnAmount = null;
        newData.urnType = null;
      }
      if (name === 'furnishCoffin' && prevState.data.furnishCoffin !== value) {
        newData.furnishCoffinAmount = null;
      }
      if (name === 'transportDeceased' && prevState.data.transportDeceased !== value) {
        newData.transportDeceasedAmount = null;
        newData.transportOption = null;
        newData.numberOfKilometers = null;
      }
      if (name === 'funeralCosts' && prevState.data.funeralCosts !== value) {
        newData.funeralCostsAmount = null;
        newData.typeOfGraveSite = null;
      }
      if (name === 'cremationCosts' && prevState.data.cremationCosts !== value) {
        newData.cremationCostsAmount = null;
        newData.typeOfCremation = null;
      }
      if (name === 'pvcInsert' && prevState.data.pvcInsert !== value) {
        newData.pvcInsertAmount = null;
      }
      if (name === 'pvcBagForDeceasedTakeOut' && prevState.data.pvcBagForDeceasedTakeOut !== value) {
        newData.pvcBagForDeceasedTakeOutAmount = null;
      }
      if (name === 'tinInsert' && prevState.data.tinInsert !== value) {
        newData.tinInsertAmount = null;
      }
      if (name === 'deceasedTakeOutFromApartment' && prevState.data.deceasedTakeOutFromApartment !== value) {
        newData.deceasedTakeOutFromApartmentAmount = null;
      }
  
      const newAmount = calculateAmount(newData);
      const newRefundAmount = newData.refundAmount;
      const newTotalAmount = calculateTotalAmount(newAmount, newRefundAmount);
  
      newData.amount = newAmount;
      newData.refundAmount = newRefundAmount;
      newData.totalAmount = newTotalAmount;
  
      return {
        data: newData,
        errors: { ...prevState.errors, [name]: null }
      };
    });
  };

// handleDeceasedPreparationsCheckboxChange = (type, id) => (event) => {
//     const checked = event.target.checked;
//     const exists = this.state.deceasedPreparation.some(item => item.id === id);

//     if (checked && !exists) {
//         this.setState(prevState => ({
//             deceasedPreparation: [...prevState.deceasedPreparation, { id, value: type, name: type }]
//         }));
//     } else {
//         this.setState(prevState => ({
//             deceasedPreparation: prevState.deceasedPreparation.filter(item => item.id !== id)
//         }));
//     }
// }

  handleDeceasedPreparationsCheckboxChange = (type, id) => (event) => {
    const checked = event.target.checked;
    const exists = this.state.deceasedPreparation.some(item => item.id === id);

    this.setState(prevState => {
      let updatedPreparation;
      if (checked && !exists) {
        updatedPreparation = [...prevState.deceasedPreparation, { id, value: type, name: type }];
      } else {
        updatedPreparation = prevState.deceasedPreparation.filter(item => item.id !== id);
      }

      let newData = {
        ...prevState.data,
        deceasedPreparationsAmount: updatedPreparation.length === 0 ? null : prevState.data.deceasedPreparationsAmount
      };

      const newAmount = calculateAmount(newData);
      const newRefundAmount = newData.refundAmount;
      const newTotalAmount = calculateTotalAmount(newAmount, newRefundAmount);
  
      newData.amount = newAmount;
      newData.refundAmount = newRefundAmount;
      newData.totalAmount = newTotalAmount;

      return {
        deceasedPreparation: updatedPreparation,
        data: newData,
        errors: { ...prevState.errors }
      };
    });
  };

  handleLetterTypeCheckboxChange = (type, id, value) => (event) => {
    const checked = event.target.checked;
    if (checked) {
        this.setState({
            letterType: { id, value: value, name: type}
        });
    }
  };

  handleTransportOptionsCheckboxChange = (type, id, value) => (event) => {
    const checked = event.target.checked;
    if (checked) {
      this.setState({
          transportOption: { id, value: value, name: type}
      });
    }
  };
      
      
  // handleTombstonesCheckboxChange = (type, id, value) => (event) => {
  //   const checked = event.target.checked;
  //   if (checked) {
  //     this.setState({
  //         tombstone: { id, value: value, name: type}
  //     });
  //   }
  // };

  // handleTombstonesCheckboxChange = (type, id, value) => (event) => {
  //     const checked = event.target.checked;
  //     if (checked) {
  //         this.setState(prevState => ({
  //             tombstone: { id, value: value, name: type},
  //             data: {
  //               ...prevState.data,
  //               tombstoneType: null,
  //             }
  //         }));
  //     }
  // };

  handleTombstonesCheckboxChange = (type, id, value) => (event) => {
    const checked = event.target.checked;
    if (checked) {
      this.setState((prevState) => {
          const isDifferent = prevState.tombstone.id !== id || prevState.tombstone.value !== value;

          const newData = {
            ...prevState.data,
            crossAmount: isDifferent ? null : prevState.data.crossAmount,
            pyramidAmount: isDifferent ? null : prevState.data.pyramidAmount,
            reformationBoardAmount: isDifferent ? null : prevState.data.reformationBoardAmount,
          };

          const newAmount = calculateAmount(newData);
          const newRefundAmount = newData.refundAmount;
          const newTotalAmount = calculateTotalAmount(newAmount, newRefundAmount);
      
          newData.amount = newAmount;
          newData.refundAmount = newRefundAmount;
          newData.totalAmount = newTotalAmount;

          return {
            tombstone: { id, value, name: type },
            data: newData,
            errors: { ...prevState.errors }
          };
      });
    }
  };

  // handleLettersCheckboxChange = (type, id, value) => (event) => {
  //   const checked = event.target.checked;
  //   if (checked) {
  //     this.setState(prevState => ({
  //         letter: { id, value: value, name: type },
  //         data: {
  //           ...prevState.data,
  //           typeface: {}
  //         },
  //         typefaces: []
  //     }));
  //   }
  // };

  handleLettersCheckboxChange = (type, id, value) => (event) => {
    const checked = event.target.checked;
    if (checked) {
        this.setState((prevState) => {
          const isDifferent = prevState.letter.id !== id || prevState.letter.value !== value;

          const newData = {
            ...prevState.data,
            typeface: {},
            metalLettersAmount: isDifferent ? null : prevState.data.metalLettersAmount,
            plasticLettersAmount: isDifferent ? null : prevState.data.plasticLettersAmount,
          };

          const newAmount = calculateAmount(newData);
          const newRefundAmount = newData.refundAmount;
          const newTotalAmount = calculateTotalAmount(newAmount, newRefundAmount);
      
          newData.amount = newAmount;
          newData.refundAmount = newRefundAmount;
          newData.totalAmount = newTotalAmount;

          return {
              letter: { id, value, name: type },
              data: newData,
              typefaces: [],
              errors: { ...prevState.errors }
          };
        });
    }
};


  /** 
   * Handles autocomplete change event
   * Triggers when user selects something from dropdown menu
   * to fill info (disabled) fields 
   */
  onAutocompleteChange = (event, values) => {
    let autocompleteName = event.target.id.split("-")[0];
    this.setState({
      data: {
        ...this.state.data, [autocompleteName]: values
      }
    });

    // if (autocompleteName === "typeface") {
    //   this.setState({ 
    //     typeface: values 
    //   });
    // }
  }

  /** 
   * Handles autocomplete type input change event
   * When user typed in 2+ characters it searches for results to fill dropdown 
   */
  handleTextInputChange = (e) => {
    if (e.target.name === "typeface" && e.target.value.trim().length >= 2) {
      let data = {
        searchParam: e.target.value,
        companyId: this.props.cid,
        sort: this.state.sortBy,
        userId: 0
      };

      {
        this.props.cid != undefined &&
        getTypefaces(data).then(response => {
            if (!response.ok) {
              return;
            }
            this.setState({
              typefaces: response.data.entities,
            });
          });
      }
    }
  }

  changeData(event, data = 'data') {
    const name = event.target && event.target.name;
    const value = event.target ? event.target.value : null;

    if (!name) {
        return;
    }

    this.setState(prevState => {
        let newData = {
            ...prevState[data],
            [name]: value
        };

        if (name === 'casket' && prevState.data.casket !== value) {
          newData.casketAmount = null;
        }
        if (name === 'additionalFieldOne' && prevState.data.additionalFieldOne !== value) {
          newData.additionalFieldOneAmount = null;
        }
        if (name === 'additionalFieldTwo' && prevState.data.additionalFieldTwo !== value) {
          newData.additionalFieldTwoAmount = null;
        }
        if (name === 'additionalFieldThree' && prevState.data.additionalFieldThree !== value) {
          newData.additionalFieldThreeAmount = null;
        }

        const newAmount = calculateAmount(newData);
        const newRefundAmount = newData.refundAmount;
        const newTotalAmount = calculateTotalAmount(newAmount, newRefundAmount);
    
        newData.amount = newAmount;
        newData.refundAmount = newRefundAmount;
        newData.totalAmount = newTotalAmount;

        return {
            [data]: newData,
            errors: {
                ...prevState.errors,
                [name]: null,
            }
        };
    });
  }

  render() {
    return (
      <Grid id='page' item md={12}>

        <div className='header'>
          <h1>{strings.equipmentOrder.addPageTitle}</h1>
        </div>
        <div className="tabsDiv">
          <AppBar position="static">
            <Tabs value={this.state.value} className="tabs" onChange={this.handleChangeTab}>
              <Tab label={strings.equipmentOrder.deceased} className="twoTabs" />
              <Tab label={strings.equipmentOrder.equipmentDetails} className="twoTabs" />
            </Tabs>
          </AppBar>
          {this.state.value === 0 && <TabContainer>

            <DeceasedDetailsEquipmentOrderForm
              onChange={this.changeData}
              onSubmit={this.submit}
              data={this.state.data}
              errors={this.state.errors}
              onCancel={this.props.onCancel}
              handleChange={this.handleChange}
              flag={this.props.flag}
              date={(new Date().getFullYear())}
              disabled={this.state.disabled}
              letterType={this.state.letterType}
              letterTypes={this.state.letterTypes}
              handleLetterTypeCheckboxChange={this.handleLetterTypeCheckboxChange}
            />

          </TabContainer>}
          {this.state.value === 1 && <TabContainer>

            <EquipmentOrderForm
              onChange={this.changeData}
              onSubmit={this.submit}
              data={this.state.data}
              flag={this.props.flag}
              errors={this.state.errors}
              onCancel={this.props.onCancel}
              handleChange={this.handleChange}
              onAutocompleteChange={this.onAutocompleteChange}
              handleTextInputChange={this.handleTextInputChange}
              disabled={this.state.disabled}
              tombstone={this.state.tombstone}
              tombstones={this.state.tombstones}
              handleTombstonesCheckboxChange={this.handleTombstonesCheckboxChange}
              letter={this.state.letter}
              letters={this.state.letters}
              handleLettersCheckboxChange={this.handleLettersCheckboxChange}
              deceasedPreparation={this.state.deceasedPreparation}
              deceasedPreparations={this.state.deceasedPreparations}
              handleDeceasedPreparationsCheckboxChange={this.handleDeceasedPreparationsCheckboxChange}
              transportOption={this.state.transportOption}
              transportOptions={this.state.transportOptions}
              handleTransportOptionsCheckboxChange={this.handleTransportOptionsCheckboxChange}
              typefaces={this.state.typefaces == undefined ? [] : this.state.typefaces}
            />

          </TabContainer>}
        </div>

      </Grid>

    );
  }
}


function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    changeFullScreen: Actions.changeFullScreen
  }, dispatch);
}

function mapStateToProps({ menuReducers, siteDataReducers }) {
  return { menu: menuReducers, siteData: siteDataReducers };
}

export default (withSnackbar(withRouter(connect(mapStateToProps, mapDispatchToProps)(AddEquipmentOrder))));