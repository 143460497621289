import React from 'react';
import strings from "../../../localization";
import { getError, hasError } from "../../../functions/Validation";
import { Button, TextField } from "@material-ui/core";

const CompanyForm = ({
    onSubmit,
    onCancel,
    onChange,
    errors,
    data,
    isDisabled
}) => (

    <form id='user-form'>
        <TextField
            label={strings.company.form.name + "*"}
            error={hasError(errors, 'name')}
            helperText={getError(errors, 'name')}
            fullWidth
            autoFocus
            name='name'
            onChange={onChange}
            margin="normal"
            disabled={isDisabled}
            defaultValue={data.name}
            value={data.name}
            InputLabelProps={{
                shrink: true,
            }}
        />
        <TextField
            label={strings.company.form.email + "*"}
            error={hasError(errors, 'email')}
            helperText={getError(errors, 'email')}
            fullWidth
            name='email'
            onChange={onChange}
            margin="normal"
            disabled={isDisabled}
            defaultValue={data.email}
            value={data.email}
            InputLabelProps={{
                shrink: true,
            }}
        />
        <TextField
            label={strings.company.form.city}
            error={hasError(errors, 'city')}
            helperText={getError(errors, 'city')}
            fullWidth
            name='city'
            onChange={onChange}
            margin="normal"
            disabled={isDisabled}
            defaultValue={data.city}
            value={data.city}
            InputLabelProps={{
                shrink: true,
            }}
        />
        <TextField
            label={strings.company.form.state}
            error={hasError(errors, 'state')}
            helperText={getError(errors, 'state')}
            fullWidth
            name='state'
            onChange={onChange}
            margin="normal"
            disabled={isDisabled}
            defaultValue={data.state}
            value={data.state}
            InputLabelProps={{
                shrink: true,
            }}
        />
        <TextField
            label={strings.company.form.zipCode}
            error={hasError(errors, 'zipCode')}
            helperText={getError(errors, 'zipCode')}
            fullWidth
            name='zipCode'
            onChange={onChange}
            margin="normal"
            disabled={isDisabled}
            defaultValue={data.zipCode}
            value={data.zipCode}
            InputLabelProps={{
                shrink: true,
            }}
        />
        <TextField
            label={strings.company.form.registrationNumber + "*"}
            error={hasError(errors, 'registrationNumber')}
            helperText={getError(errors, 'registrationNumber')}
            fullWidth
            name='registrationNumber'
            onChange={onChange}
            margin="normal"
            disabled={isDisabled}
            defaultValue={data.registrationNumber}
            value={data.registrationNumber}
            InputLabelProps={{
                shrink: true,
            }}
        />
        <TextField
            label={strings.company.form.taxNumber}
            error={hasError(errors, 'taxNumber')}
            helperText={getError(errors, 'taxNumber')}
            fullWidth
            name='taxNumber'
            onChange={onChange}
            margin="normal"
            disabled={isDisabled}
            defaultValue={data.taxNumber}
            value={data.taxNumber}
            InputLabelProps={{
                shrink: true,
            }}
        />
        <TextField
            label={strings.company.form.codeNumber}
            error={hasError(errors, 'codeNumber')}
            helperText={getError(errors, 'codeNumber')}
            fullWidth
            name='codeNumber'
            onChange={onChange}
            margin="normal"
            disabled={isDisabled}
            defaultValue={data.codeNumber}
            value={data.codeNumber}
            InputLabelProps={{
                shrink: true,
            }}
        />
        <TextField
            label={strings.company.form.phone}
            error={hasError(errors, 'phone')}
            helperText={getError(errors, 'phone')}
            fullWidth
            name='phone'
            onChange={onChange}
            margin="normal"
            disabled={isDisabled}
            defaultValue={data.phone}
            value={data.phone}
            InputLabelProps={{
                shrink: true,
            }}
        />
        <TextField
            label={strings.company.form.website}
            error={hasError(errors, 'website')}
            helperText={getError(errors, 'website')}
            fullWidth
            name='website'
            onChange={onChange}
            margin="normal"
            disabled={isDisabled}
            defaultValue={data.website}
            value={data.website}
            InputLabelProps={{
                shrink: true,
            }}
        />
        <TextField
            label={strings.company.form.fax}
            error={hasError(errors, 'fax')}
            helperText={getError(errors, 'fax')}
            fullWidth
            name='fax'
            onChange={onChange}
            margin="normal"
            disabled={isDisabled}
            defaultValue={data.fax}
            value={data.fax}
            InputLabelProps={{
                shrink: true,
            }}
        />
        <TextField
            label={strings.company.form.address}
            error={hasError(errors, 'address')}
            helperText={getError(errors, 'address')}
            fullWidth
            name='address'
            onChange={onChange}
            margin="normal"
            disabled={isDisabled}
            defaultValue={data.address}
            value={data.address}
            InputLabelProps={{
                shrink: true,
            }}
        />
        {
            !isDisabled &&
            <div className='submit-container'>
                <Button variant="contained" color="primary" onClick={onSubmit}>
                    {strings.company.form.ok}
                </Button>
                <Button variant="contained" color="secondary" onClick={onCancel}>
                    {strings.company.form.cancel}
                </Button>
            </div>
        }
    </form>
);

export default CompanyForm;