import {request} from "../../base/HTTP";
import HttpMethod from "../../constants/HttpMethod";

export async function getPermissions(data) {
    return await request('/api/permission/all', data);
}

export async function addPermission(data, cid) {
    return await request('/api/permission/' + cid, data, HttpMethod.POST);
}

export async function getPermissionByName(data){
    return await request('/api/permission/search', data);
}

export async function deletePermission(id, uid) {
    return await request('/api/permission/' + id + '/' + uid, {}, HttpMethod.DELETE);
}