import React from "react";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import * as Actions from "../../actions/Actions";
import connect from "react-redux/es/connect/connect";
import Grid from "@material-ui/core/Grid";
import strings from "../../localization";
import Validators from "../../constants/ValidatorTypes";
import { withSnackbar } from "notistack";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import { TextField, Button } from "@material-ui/core";
import { getError, hasError } from "../../functions/Validation";
import { dateToString3 } from "../../util/DateUtil";
import { addAppointment, bookAppointmentById, cancelAppointmentById, deleteAppointment } from "../../services/appointment/AppointmentService";
import TablePage from "../../common/TablePage";

function TabContainer(props) {
  return (
    <Typography className="tabContainer" component="div">
      {props.children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

class AddAppointment extends TablePage {
  validationList = {
    appointmentHours: [{ type: Validators.REQUIRED }],
    appointmentMinutes: [{ type: Validators.REQUIRED }],
    // height: [{ type: Validators.IS_NEGATIVE_NUMBER }],
    // weight: [{ type: Validators.IS_NEGATIVE_NUMBER }],
  };

  constructor(props) {
    super(props);

    this.state = {
      data: props.data ? props.data : {},
      errors: {},
      value: 0,
      disabled: false,
      showLoader: false,
    };

    this.state.sortBy = "name,ASC";

    this.props.changeFullScreen(false);

    this.submit = this.submit.bind(this);

    this.handleChange = this.handleChange.bind(this);

    this.bookAppointment = this.bookAppointment.bind(this);

    this.cancelAppointment = this.cancelAppointment.bind(this);
  }

  componentDidMount() {
    this.setState({
      data: {
        ...this.state.data,
      },
    });
  }

  submit() {
    this.setState({
      disabled: true,
      showLoader: true,
    });

    if (!this.validate()) {
      this.props.enqueueSnackbar(strings.error.requiredFields, { variant: "error" });

      this.setState({
        data: {
          ...this.state.data
        },
        disabled: false,
        showLoader: false
      })

      return;
    }

    addAppointment(this.transformRequest(this.state.data), this.props.cid).then((response) => {
        if (!response.ok) {
          let messageKey = response.response.data.message;

          this.props.enqueueSnackbar(strings.appointment.messages[messageKey], {
            variant: "error",
          });

          this.handleError(messageKey);

          this.setState({
            data: {
              ...this.state.data
            },
            disabled: false,
            showLoader: false
          })

          return;
        }

        this.props.enqueueSnackbar(strings.appointments.appointmentAdded, {
          variant: "success",
        });

        this.setState({
          disabled: false,
          showLoader: false,
          data: {
            ...this.state.data,
            appointmentHours: "",
            appointmentMinutes: "",
            note: "",
          },
        });

        this.props.onFinish(response.data.appointment);
        this.props.onFetchData();
      }
    );
  }

  transformRequest(data) {
    return {
      ...data,
      responsiblePersonId: this.props.responsiblePersonId,
      responsiblePersonEmail: this.props.uemail,
      appointmentTime: this.state?.data?.appointmentHours && this.state?.data?.appointmentMinutes ? this.state?.data?.appointmentHours + ":" + this.state?.data?.appointmentMinutes : null,
      appointmentDate: this.props.filterByAppointmentDate ? this.props.filterByAppointmentDate : null,
      appointmentNumber: new Date().getFullYear(),
      note: this.state.data.note ? this.state.data.note : null,
    };
  }

  bookAppointment(item) {
    bookAppointmentById(item, this.props.responsiblePersonId).then((response) => {
        if (response && !response.ok) {
          this.onFinish(null);
          return;
        }

        this.props.enqueueSnackbar(strings.appointments.appointmentReserved, {
          variant: "success",
        });

        this.onFinish(item);
        this.props.onFetchData();
      }
    );
  }

  cancelAppointment(item) {
    if (item.responsiblePersonId !== this.props.responsiblePersonId) {
      this.props.enqueueSnackbar(strings.appointments.messages.FORBIDDEN_CANCEL_APPOINTMENT_MESSAGE, {
        variant: "error",
      });

      return;
    }

    if (item.appointmentScheduled) {
      this.props.enqueueSnackbar(strings.appointments.appointmentScheduledMessage, {
        variant: "error",
      });

      return;
    }

    cancelAppointmentById(item, this.props.responsiblePersonId).then((response) => {
        if (response && !response.ok) {
          this.onFinish(null);
          return;
        }

        this.props.enqueueSnackbar(strings.appointments.appointmentCanceled, {
          variant: "success",
        });

        this.onFinish(item);
        this.props.onFetchData();
      }
    );
  }

  delete(item) {
    deleteAppointment(item.id, this.props.responsiblePersonId).then((response) => {
        if (response && !response.ok) {
          let messageKey = response.response.data.message;

          this.handleError(messageKey);

          this.cancelDelete();

          this.props.enqueueSnackbar(strings.appointments.messages[messageKey], { variant: "error" });

          this.onFinish(null);

          return;
        }

        this.props.enqueueSnackbar(strings.appointments.appointmentDeleted, { variant: "success" });

        this.onFinish(item);
        this.cancelDelete();
        this.props.onFetchData();
      }
    );
  }

  handleChangeTab = (event, value) => {
    this.setState({
      value: value,
    });
  };

  handleChange = (event) => {
    this.setState({
      data: {
        ...this.state.data,
        [event.target.name]: event.target.value,
      },
    });
  };

  handleError(message) {
    switch (message) {
      case "APPOINTMENT_TAKEN":
        this.setError("appointmentTaken", strings.appointments.messages.APPOINTMENT_TAKEN);
        break;
    }
  }

  render() {
    const { onCancel } = this.props;

    return (
      <Grid id="page" item md={12}>
        {this.renderDialog(
          strings.table.confirmDelete,
          strings.delete.confirmation,
          this.cancelDelete,
          this.delete
        )}
        <div className="header">
          <h1>
            {strings.appointments.appointmentsList}{" "}
            {dateToString3(this.props.data.appointmentDate)}
          </h1>
        </div>
        <div className="tabsDiv">
          <AppBar position="static">
            <Tabs
              value={this.state.value}
              className="tabs"
              // onChange={this.handleChangeTab}
            >
              {/* <Tab
                label={strings.appointments.appointmentsList}
                className="twoTabs"
              /> */}
              <Tab
                label={strings.appointments.addAppointment}
                className="twoTabs"
              />
            </Tabs>
          </AppBar>
          {/* {this.state.value === 0 && (
            <TabContainer>
              <div className="appointment-wrapper">
                <>
                  {loading ? (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        margin: "auto",
                      }}
                    >
                      <CircularProgress color="secondary" />
                    </div>
                  ) : data?.appointments?.length === 0 ? (
                    <h1>
                      {strings.appointments.appointmentsNotFoundMessage}{" "}
                      {dateToString3(this.props.data.appointmentDate)}
                    </h1>
                  ) : (
                    <>
                      {data?.appointments?.map((appointment) => (
                        <>
                          <div
                            key={appointment.id}
                            className="appointment-item"
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                alignItems: "center",
                                gap: "1rem",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "flex-start",
                                  gap: ".2rem",
                                }}
                              >
                                <label htmlFor="appointmentHours" style={{ fontSize: "16px", color: "lightgray" }}>
                                  {strings.appointments.appointmentHours}
                                </label>
                                <TextField
                                  InputLabelProps={{ shrink: true }}
                                  margin={"normal"}
                                  name="appointmentHours"
                                  defaultValue={appointment?.appointmentTime?.substring(0,2)}
                                  value={this.state.data.appointmentTime}
                                  onChange={this.handleChange}
                                  inputProps={{ maxLength: 2 }}
                                  disabled
                                />
                              </div>

                              <p>:</p>

                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "flex-start",
                                  gap: ".2rem",
                                }}
                              >
                                <label htmlFor="appointmentMinutes" style={{ fontSize: "16px", color: "lightgray",}}>
                                  {strings.appointments.appointmentMinutes}
                                </label>
                                <TextField
                                  InputLabelProps={{ shrink: true }}
                                  margin={"normal"}
                                  name="appointmentMinutes"
                                  defaultValue={appointment?.appointmentTime?.substring(3,5)}
                                  value={this.state.data.appointmentTime}
                                  onChange={this.handleChange}
                                  inputProps={{ maxLength: 2 }}
                                  disabled
                                />
                              </div>

                              <div className="appointments-buttons-group">
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    gap: ".5rem",
                                  }}
                                >
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => this.bookAppointment(appointment)}
                                    disabled={appointment.appointmentTaken}
                                  >
                                  {strings.appointments.reservationButton}
                                  </Button>
                                </div>

                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    gap: ".5rem",
                                  }}
                                >
                                  <Button
                                    variant="contained"
                                    color="default"
                                    onClick={() => this.cancelAppointment(appointment)}
                                    disabled={!appointment.appointmentTaken}
                                  >
                                  {strings.appointments.cancelButton}
                                  </Button>
                                  <Button
                                    variant="outlined"
                                    color="secondary"
                                    onClick={() => this.selectItem(appointment)}
                                    disabled={appointment.appointmentTaken}
                                  >
                                    {strings.appointments.deleteButton}
                                  </Button>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div style={{ display: 'flex', flexDirection: 'column', gap: '.5rem'}}>
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '2rem' }}>
                              {appointment?.note ? <p>{strings.mainBookOfBuried.form.note}: {appointment?.note}</p> : null}
                            </div>
                          </div>
                          <hr style={{ width: "100%", border: ".1rem solid #ddd", borderRadius: "5px" }} />
                        </>
                      ))}
                    </>
                  )}
                </>
              </div>
            </TabContainer>
          )} */}
          {this.state.value === 0 && (
            <TabContainer>
              <form id="user-form">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    gap: "1rem",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                      gap: "1rem",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: ".2rem",
                      }}
                    >
                      <label htmlFor="appointmentHours" style={{ fontSize: "16px", color: "gray" }}>
                        {strings.appointments.appointmentHours}
                      </label>
                      <TextField
                        style={{ width: "75px" }}
                        InputLabelProps={{ shrink: true }}
                        error={hasError(this.state.errors, "appointmentHours")}
                        helperText={getError(this.state.errors, "appointmentHours")}
                        margin={"normal"}
                        name="appointmentHours"
                        defaultValue={""}
                        value={this.state.data.appointmentHours}
                        onChange={this.handleChange}
                        inputProps={{ maxLength: 2 }}
                        placeholder="00"
                      />
                    </div>
                    <p>:</p>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-start",
                        gap: ".2rem",
                      }}
                    >
                      <label htmlFor="appointmentMinutes" style={{ fontSize: "16px", color: "gray" }}>
                        {strings.appointments.appointmentMinutes}
                      </label>
                      <TextField
                        style={{ width: "75px" }}
                        InputLabelProps={{ shrink: true }}
                        error={hasError(this.state.errors, "appointmentMinutes")}
                        helperText={getError(this.state.errors, "appointmentMinutes")}
                        margin={"normal"}
                        name="appointmentMinutes"
                        defaultValue={""}
                        value={this.state.data.appointmentMinutes}
                        onChange={this.handleChange}
                        inputProps={{ maxLength: 2 }}
                        placeholder="00"
                      />
                    </div>
                  </div>

                {/* <div>
                  <TextField
                    style={{ width: '100%' }}
                    InputLabelProps={{ shrink: true }}
                    margin={"normal"}
                    name="note"
                    defaultValue={""}
                    value={this.state.data.note}
                    onChange={this.handleChange}
                    placeholder={strings.appointments.note}
                  />
                </div> */}
              </div>

                <div className="submit-container">
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={this.state.showLoader}
                    onClick={this.submit}
                  >
                    {strings.equipmentOrder.form.ok}
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    disabled={this.state.showLoader}
                    onClick={onCancel}
                  >
                    {strings.equipmentOrder.form.cancel}
                  </Button>
                </div>
              </form>
            </TabContainer>
          )}
        </div>
      </Grid>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      changeFullScreen: Actions.changeFullScreen,
    },
    dispatch
  );
}

function mapStateToProps({ menuReducers, siteDataReducers }) {
  return { menu: menuReducers, siteData: siteDataReducers };
}

export default withSnackbar(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(AddAppointment))
);