import React from 'react';
import strings from "../../../localization";
import {getError, hasError} from "../../../functions/Validation";
import {Button, TextField} from "@material-ui/core";
import DatePickerControl from '../../controls/DatePickerControl';
import { withRouter, Link } from "react-router-dom";

const ProfileForm = ({
                      onSubmit,
                      onCancel,
                      onChange,
                      errors,
                      data
                  }) => (

    <form id='user-form'>

        <TextField
            label={ strings.user.form.name }
            error={ hasError(errors, 'name') }
            helperText={ getError(errors, 'name') }
            fullWidth
            autoFocus
            name='name'
            onChange={ onChange }
            margin="normal"
            value={ data.name }
            InputLabelProps={{
                shrink: true,
            }}
        />
        <TextField
            label={ strings.user.form.surname }
            error={ hasError(errors, 'surname') }
            helperText={ getError(errors, 'surname') }
            fullWidth
            name='surname'
            onChange={ onChange }
            margin="normal"
            value={ data.surname }
            InputLabelProps={{
                shrink: true,
            }}
        />
        <TextField
            label={ strings.user.form.email }
            error={ hasError(errors, 'email') }
            helperText={ getError(errors, 'email') }
            fullWidth
            name='email'
            onChange={ onChange }
            margin="normal"
            value={ data.email }
            InputLabelProps={{
                shrink: true,
            }}
        />
        <TextField
            label={ strings.user.form.phoneNumber }
            error={ hasError(errors, 'phoneNumber') }
            helperText={ getError(errors, 'phoneNumber') }
            fullWidth
            name='phoneNumber'
            onChange={ onChange }
            margin="normal"
            value={ data.phoneNumber }
            InputLabelProps={{
                shrink: true,
            }}
        />
        <TextField
            label={ strings.user.form.address }
            error={ hasError(errors, 'address') }
            helperText={ getError(errors, 'address') }
            fullWidth
            name='address'
            onChange={ onChange }
            margin="normal"
            value={ data.address }
            InputLabelProps={{
                shrink: true,
            }}
        />
        <DatePickerControl
            label={ strings.user.form.birthDate }
            placeholder={ strings.user.form.birthDate }
            name='birthDate'
            onChange={ onChange }
            date={ data.birthDate ? new Date(data.birthDate) : data.birthDate }
            isClearable={ true }
            dateFormat='dd/MM/yyyy'
        />
        <Link to="/changePassword">
            <Button>{strings.profile.changePassword}</Button>
        </Link>
        <div className='submit-container'>
            <Button variant="contained" color="primary" onClick={ onSubmit }>
                { strings.user.form.ok }
            </Button>
        </div>
    </form>
);

export default ProfileForm;