import React from 'react';
import strings from "../../../localization";
import { getError, hasError } from "../../../functions/Validation";
import { Button, TextField, FormLabel, FormGroup, FormControlLabel, Checkbox, Radio, FormControl, RadioGroup } from "@material-ui/core";
import DatePickerControl from '../../controls/DatePickerControl';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { stringToDate } from '../../../util/DateUtil';
import moment from 'moment';

const ExhumationForm = ({
  onSubmit,
  onCancel,
  onChange,
  errors,
  data,
  isDisabled,
  onAutocompleteChange,
  handleTextInputChange,
  graveyards,
  graveSites,
  costs,
  mainBooksOfBuried,
  flag,
  date,
  typedInGraveSite,
  keyPress,
  keyPressBackspace,
}) => {
  
  return (

    <form id='user-form'>
      {((data.mainBookOfBuried && flag == "edit") || (flag == "add") || (data.mainBookOfBuried && flag == "view")) &&
        <Autocomplete
          id={"mainBookOfBuried"}
          name={"mainBookOfBuried"}
          options={mainBooksOfBuried}
          getOptionLabel={option => option.deceasedNameSurname + " - " + strings.exhumation.form.mainBookOfBuried + ": " + option.mainBookOfBuries}
          onChange={onAutocompleteChange}
          renderInput={params => (
            <TextField
              {...params}
              variant="standard"
              label={strings.exhumation.form.deceasedNameSurname + "*"}
              error={hasError(errors, 'mainBookOfBuried')}
              helperText={getError(errors, 'mainBookOfBuried')}
              margin="normal"
              fullWidth
              name={"mainBookOfBuried"}
              onChange={handleTextInputChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
          disabled={isDisabled}
          value={data.mainBookOfBuried}
        />
      }
      {((!data.mainBookOfBuried && flag == "edit") || (!data.mainBookOfBuried && flag == "view")) &&
        <Autocomplete
          id={"mainBookOfBuried"}
          name={"mainBookOfBuried"}
          options={mainBooksOfBuried}
          getOptionLabel={option => option.deceasedNameSurname + " - " + strings.exhumation.form.mainBookOfBuried + ": " + option.mainBookOfBuries}
          onChange={onAutocompleteChange}
          renderInput={params => (
            <TextField
              {...params}
              variant="standard"
              label={strings.exhumation.form.deceasedNameSurname + "*"}
              error={hasError(errors, 'mainBookOfBuried')}
              helperText={getError(errors, 'mainBookOfBuried')}
              margin="normal"
              fullWidth
              name={"mainBookOfBuried"}
              onChange={handleTextInputChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
          disabled={isDisabled}
          value={data.mainBookOfBuried}
        />
      }
      <TextField
        label={strings.exhumation.form.exhumation + "*"}
        error={hasError(errors, 'exhumation')}
        helperText={getError(errors, 'exhumation')}
        fullWidth
        name='exhumation'
        onChange={onChange}
        margin="normal"
        defaultValue={date}
        value={data.exhumation}
        InputLabelProps={{
          shrink: true,
        }}
        disabled={isDisabled}
      />
      {(data.expiryDateString != undefined && data.expiryDateString != null && data.expiryDateString != "") &&
        <TextField
          label={strings.exhumation.form.expiryDateString}
          fullWidth
          name='expiryDateString'
          onChange={handleTextInputChange}
          margin="normal"
          value={data.expiryDateString}
          InputLabelProps={{
            shrink: true,
          }}
          disabled={true}
        />
      }
      
      {flag == "add" &&
        <DatePickerControl
          label={strings.exhumation.form.expiryDate}
          error={getError(errors, 'expiryDate')}
          hasError={hasError(errors, 'expiryDate')}
          helperText={getError(errors, 'expiryDate')}
          placeholder={strings.exhumation.form.expiryDate}
          name='expiryDate'
          onChange={onChange}
          date={data.mainBookOfBuried != undefined ? (data.mainBookOfBuried.expiryDate ? new Date(data.mainBookOfBuried.expiryDate) : data.expiryDate) : data.expiryDate}
          isClearable={true}
          dateFormat='dd/MM/yyyy'
          disabled={isDisabled}
        />
      }

      {(flag == "edit" || flag == "view") &&
        <DatePickerControl
          label={strings.exhumation.form.expiryDate}
          error={getError(errors, 'expiryDate')}
          hasError={hasError(errors, 'expiryDate')}
          helperText={getError(errors, 'expiryDate')}
          placeholder={strings.exhumation.form.expiryDate}
          name='expiryDate'
          onChange={onChange}
          date={data.expiryDate  ? new Date(data.expiryDate): data.expiryDate}
          isClearable={true}
          dateFormat='dd/MM/yyyy'
          disabled={isDisabled}
        />
      }

      {flag == "add" &&
        <DatePickerControl
          label={strings.exhumation.form.dateOfExhumation}
          error={getError(errors, 'dateOfExhumation')}
          hasError={hasError(errors, 'dateOfExhumation')}
          helperText={getError(errors, 'dateOfExhumation')}
          placeholder={strings.exhumation.form.dateOfExhumation}
          name='dateOfExhumation'
          onChange={onChange}
          date={data.dateOfExhumation ? new Date(data.dateOfExhumation) : data.dateOfExhumation}
          isClearable={true}
          dateFormat='dd/MM/yyyy'
          disabled={isDisabled}
        />
      }

      {(flag == "edit" || flag == "view") &&
        <DatePickerControl
          label={strings.exhumation.form.dateOfExhumation}
          error={getError(errors, 'dateOfExhumation')}
          hasError={hasError(errors, 'dateOfExhumation')}
          helperText={getError(errors, 'dateOfExhumation')}
          placeholder={strings.exhumation.form.dateOfExhumation}
          name='dateOfExhumation'
          onChange={onChange}
          date={data.dateOfExhumation ? new Date(data.dateOfExhumation) : data.dateOfExhumation}
          isClearable={true}
          dateFormat='dd/MM/yyyy'
          disabled={isDisabled}
        />
      }

      {((data.graveyard && flag == "edit") || (flag == "add") || (data.graveyard && flag == "view")) &&
        <Autocomplete
          id={"graveyard"}
          name={"graveyard"}
          options={graveyards}
          getOptionLabel={option => option.name}
          onChange={onAutocompleteChange}
          renderInput={params => (
            <TextField
              {...params}
              variant="standard"
              label={strings.exhumation.form.graveyard + "*"}
              error={hasError(errors, 'graveyard')}
              helperText={getError(errors, 'graveyard')}
              margin="normal"
              fullWidth
              name={"graveyard"}
              onChange={handleTextInputChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
          disabled={isDisabled}
          value={data.graveyard}
        />
      }
      {((!data.graveyard && flag == "edit") || (!data.graveyard && flag == "view")) &&
        <Autocomplete
          id={"graveyard"}
          name={"graveyard"}
          options={graveyards}
          getOptionLabel={option => option.name}
          onChange={onAutocompleteChange}
          renderInput={params => (
            <TextField
              {...params}
              variant="standard"
              label={strings.exhumation.form.graveyard + "*"}
              error={hasError(errors, 'graveyard')}
              helperText={getError(errors, 'graveyard')}
              margin="normal"
              fullWidth
              name={"graveyard"}
              onChange={handleTextInputChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
          disabled={isDisabled}
          value={data.graveyard}
        />

      }
      {((data.graveSite && flag == "edit") || (flag == "add") || (data.graveSite && flag == "view")) && ((data.graveyard != undefined && data.graveyard != null)) &&
        <Autocomplete
          noOptionsText={(typedInGraveSite != undefined ? strings.mainBookOfBuried.form.create + typedInGraveSite : "")}
          onKeyPress={keyPress}
          id={"graveSite"}
          name={"graveSite"}
          options={graveSites}
          getOptionLabel={option => option.code}
          onChange={onAutocompleteChange}
          renderInput={params => (
            <TextField
              {...params}
              variant="standard"
              label={strings.exhumation.form.graveSite}
              error={hasError(errors, 'graveSite')}
              helperText={getError(errors, 'graveSite')}
              margin="normal"
              fullWidth
              name={"graveSite"}
              onChange={handleTextInputChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
          disabled={isDisabled}
          value={data.graveSite}
          disableClearable={true}
          onKeyDown={keyPressBackspace}
        />
      }
      {((!data.graveSite && flag == "edit") || (!data.graveSite && flag == "view") && (data.graveyard != undefined && data.graveyard != null)) &&
        <Autocomplete
          noOptionsText={(typedInGraveSite != undefined ? strings.mainBookOfBuried.form.create + typedInGraveSite : "")}
          onKeyPress={keyPress}
          id={"graveSite"}
          name={"graveSite"}
          options={graveSites}
          getOptionLabel={option => option.code}
          onChange={onAutocompleteChange}
          renderInput={params => (
            <TextField
              {...params}
              variant="standard"
              label={strings.exhumation.form.graveSite}
              error={hasError(errors, 'graveSite')}
              helperText={getError(errors, 'graveSite')}
              margin="normal"
              fullWidth
              name={"graveSite"}
              onChange={handleTextInputChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
          disabled={isDisabled}
          value={data.graveSite}
          disableClearable={true}
          onKeyDown={keyPressBackspace}
        />
      }

      {(data.graveyard == undefined || data.graveyard == null) &&
        <Autocomplete
          id={"graveSite"}
          name={"graveSite"}
          options={graveSites}
          getOptionLabel={option => option.code}
          onChange={onAutocompleteChange}
          renderInput={params => (
            <TextField
              {...params}
              variant="standard"
              label={strings.exhumation.form.graveSite}
              error={hasError(errors, 'graveSite')}
              helperText={getError(errors, 'graveSite')}
              margin="normal"
              fullWidth
              name={"graveSite"}
              onChange={handleTextInputChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
          disabled={true}
          value={null}
          disableClearable={true}
        />
      }
      {(flag == "edit") || (flag == "view") &&
        <TextField
        label={strings.exhumation.form.oldGraveyard}
        error={hasError(errors, 'graveyard')}
        helperText={getError(errors, 'graveyard')}
        fullWidth
        name='oldGraveyard'
        onChange={onChange}
        margin="normal"
        defaultValue={data.oldGraveyardName}
        value={data.oldGraveyardName}
        InputLabelProps={{
          shrink: true,
        }}
        disabled={isDisabled}
        />
      }
      {(flag == "edit") || (flag == "view") &&
        <TextField
        label={strings.exhumation.form.oldGraveSiteCode}
        error={hasError(errors, 'graveSite')}
        helperText={getError(errors, 'graveSite')}
        fullWidth
        name='oldGraveSiteCode'
        onChange={onChange}
        margin="normal"
        defaultValue={data.oldGraveSiteCode}
        value={data.oldGraveSiteCode}
        InputLabelProps={{
          shrink: true,
        }}
        disabled={isDisabled}
        />
      }
      
      <TextField
        label={strings.exhumation.form.tombstoneCode}
        error={hasError(errors, 'socialCase')}
        helperText={getError(errors, 'socialCase')}
        fullWidth
        name='socialCase'
        onChange={onChange}
        margin="normal"
        defaultValue={data.socialCase}
        value={data.socialCase}
        InputLabelProps={{
          shrink: true,
        }}
        disabled={isDisabled}
      />

      {((data.cost && flag == "edit") ||
        flag == "add" ||
        (data.cost && flag == "view")) && (
        <Autocomplete
          id={"cost"}
          name={"cost"}
          options={costs}
          getOptionLabel={(option) => option.personalNumber}
          onChange={onAutocompleteChange}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              label={strings.exhumation.form.costPersonalNumber}
              margin="normal"
              fullWidth
              name={"cost"}
              onChange={handleTextInputChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
          disabled={isDisabled}
          value={data.cost}
        />
      )}

      {((!data.cost && flag == "edit") || (!data.cost && flag == "view")) && (
        <Autocomplete
          id={"cost"}
          name={"cost"}
          options={costs}
          getOptionLabel={(option) => option.personalNumber}
          onChange={onAutocompleteChange}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              label={strings.exhumation.form.costPersonalNumber}
              margin="normal"
              fullWidth
              name={"cost"}
              onChange={handleTextInputChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
          disabled={isDisabled}
          value={data.cost}
        />
      )}

      {((data.cost && flag == "edit") ||
        flag == "add" ||
        (data.cost && flag == "view")) && (
        <TextField
          label={strings.exhumation.form.costNameSurname}
          error={hasError(errors, "nameSurnameCost")}
          helperText={getError(errors, "nameSurnameCost")}
          fullWidth
          name="nameSurnameCost"
          onChange={onChange}
          margin="normal"
          defaultValue={data.cost ? data.cost.nameSurnameCost : ""}
          InputLabelProps={{
            shrink: true,
          }}
          disabled={true}
          value={data.cost ? data.cost.nameSurnameCost : ""}
        />
      )}

      {((!data.cost && flag == "edit") ||
        (!data.cost && flag == "view")) && (
        <TextField
          label={strings.exhumation.form.costNameSurname}
          error={hasError(errors, "nameSurnameCost")}
          helperText={getError(errors, "nameSurnameCost")}
          fullWidth
          name="nameSurnameCost"
          onChange={onChange}
          margin="normal"
          defaultValue={data.cost ? data.cost.nameSurnameCost : ""}
          InputLabelProps={{
            shrink: true,
          }}
          disabled={true}
          value={data.cost ? data.cost.nameSurnameCost : ""}
        />
      )}

      <TextField
        label={strings.exhumation.form.costAddress}
        error={hasError(errors, "addressCost")}
        helperText={getError(errors, "addressCost")}
        fullWidth
        name="addressCost"
        onChange={onChange}
        margin="normal"
        defaultValue={data.cost ? data.cost.addressCost : ""}
        value={data.cost ? data.cost.addressCost : ""}
        InputLabelProps={{
          shrink: true,
        }}
        disabled={true}
      />
      <TextField
        label={strings.exhumation.form.costCity}
        error={hasError(errors, "municipalityCost")}
        helperText={getError(errors, "municipalityCost")}
        fullWidth
        name="municipalityCost"
        onChange={onChange}
        margin="normal"
        defaultValue={data.cost ? data.cost.municipalityCost : ""}
        value={data.cost ? data.cost.municipalityCost : ""}
        InputLabelProps={{
          shrink: true,
        }}
        disabled={true}
      />
      <TextField
        label={strings.exhumation.form.costTelephone}
        error={hasError(errors, "telephoneCost")}
        helperText={getError(errors, "telephoneCost")}
        fullWidth
        name="telephoneCost"
        onChange={onChange}
        margin="normal"
        defaultValue={data.cost ? data.cost.telephoneCost : ""}
        value={data.cost ? data.cost.telephoneCost : ""}
        InputLabelProps={{
          shrink: true,
        }}
        disabled={true}
      />

      <TextField
        label={strings.exhumation.form.paymentNumber}
        error={hasError(errors, 'paymentNumber')}
        helperText={getError(errors, 'paymentNumber')}
        fullWidth
        name='paymentNumber'
        onChange={onChange}
        margin="normal"
        defaultValue={data.paymentNumber}
        value={data.paymentNumber}
        InputLabelProps={{
          shrink: true,
        }}
        disabled={isDisabled}
      />
      <TextField
        multiline={true}
        rowsMax={5}
        label={strings.exhumation.form.note}
        error={hasError(errors, 'note')}
        helperText={getError(errors, 'note')}
        fullWidth
        name='note'
        onChange={onChange}
        margin="normal"
        defaultValue={data.note}
        value={data.note}
        InputLabelProps={{
          shrink: true,
        }}
        disabled={isDisabled}
      />
      {
        !isDisabled &&
        <div className='submit-container'>
          <Button variant="contained" color="primary" onClick={onSubmit}>
            {strings.mainBookOfBuried.form.ok}
          </Button>
          <Button variant="contained" color="secondary" onClick={onCancel}>
            {strings.mainBookOfBuried.form.cancel}
          </Button>
        </div>
      }
    </form>
  )
};

export default ExhumationForm;