import { Button, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React from 'react';
import { getError, hasError } from '../../../functions/Validation';
import strings from '../../../localization';
const PostalAddressCodeImprovedForm = ({
    onSubmit,
    onCancel,
    onChange,
    onAutocompleteChange,
    handleTextInputChange,
    data,
    errors,
    isDisabled,
    flag,
    states,
    citiesAndZips
}) => {
    return (
        <form id='user-form'>
            {((data.state && flag == "edit") || (flag == "add") || (data.state && flag == "view")) &&
                <Autocomplete
                    id={"state"}
                    name={"state"}
                    options={states}
                    getOptionLabel={option => option.name}
                    onChange={onAutocompleteChange}
                    renderInput={params => (
                        <TextField
                            {...params}
                            variant="standard"
                            label={strings.postalCodeImproved.form.state + "*"}
                            error={hasError(errors, 'state')}
                            helperText={getError(errors, 'state')}
                            margin="normal"
                            fullWidth
                            name={"state"}
                            onChange={handleTextInputChange}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    )}
                    disabled={isDisabled}
                    value={data.state}
                />
            }
            {((!data.state && flag == "edit") || (!data.state && flag == "view")) &&
                <Autocomplete
                    id={"state"}
                    name={"state"}
                    options={states}
                    getOptionLabel={option => option.name}
                    onChange={onAutocompleteChange}
                    renderInput={params => (
                        <TextField
                            {...params}
                            variant="standard"
                            label={strings.postalCodeImproved.form.state + "*"}
                            error={hasError(errors, 'state')}
                            helperText={getError(errors, 'state')}
                            margin="normal"
                            fullWidth
                            name={"state"}
                            onChange={handleTextInputChange}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    )}
                    disabled={isDisabled}
                    value={data.state}
                />
            }

            {(data.state != undefined && data.state != null) &&
                <Autocomplete
                    id={"postalCodeImprovedCityZip"}
                    name={"postalCodeImprovedCityZip"}
                    options={citiesAndZips}
                    getOptionLabel={option => option.city + " - " + option.zip}
                    onChange={onAutocompleteChange}
                    renderInput={params => (
                        <TextField
                            {...params}
                            variant="standard"
                            label={strings.postalAddressCodeImprovedList.cityAndZip + '*'}
                            error={hasError(errors, 'postalCodeImprovedCityZip')}
                            helperText={getError(errors, 'postalCodeImprovedCityZip')}
                            margin="normal"
                            fullWidth
                            name={"postalCodeImprovedCityZip"}
                            onChange={handleTextInputChange}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    )}
                    disabled={isDisabled}
                    value={data.postalCodeImprovedCityZip}

                />
            }


            {(data.state == undefined || data.state == null) &&
                <Autocomplete
                    id={"postalCodeImprovedCityZip"}
                    name={"postalCodeImprovedCityZip"}
                    options={citiesAndZips}
                    getOptionLabel={option => option.city + " - " + option.zip}
                    onChange={onAutocompleteChange}
                    renderInput={params => (
                        <TextField
                            {...params}
                            variant="standard"
                            label={strings.postalAddressCodeImprovedList.cityAndZip + '*'}
                            error={hasError(errors, 'postalCodeImprovedCityZip')}
                            helperText={getError(errors, 'postalCodeImprovedCityZip')}
                            margin="normal"
                            fullWidth
                            name={"postalCodeImprovedCityZip"}
                            onChange={handleTextInputChange}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    )}
                    disabled={true}
                    value={null}

                />
            }
            <TextField
                label={strings.postalAddressCodeImprovedList.street + '*'}
                error={hasError(errors, 'street')}
                helperText={getError(errors, 'street')}
                fullWidth
                name='street'
                onChange={onChange}
                margin="normal"
                defaultValue={data.street}
                value={data.street}
                InputLabelProps={{
                    shrink: true,
                }}
                disabled={isDisabled}
            />
            <TextField
                label={strings.postalAddressCodeImprovedList.number + '*'}
                error={hasError(errors, 'number')}
                helperText={getError(errors, 'number')}
                fullWidth
                name='number'
                onChange={onChange}
                margin="normal"
                defaultValue={data.number}
                value={data.number}
                InputLabelProps={{
                    shrink: true,
                }}
                disabled={isDisabled}
            />
            <TextField
                label={strings.postalAddressCodeImprovedList.pac + '*'}
                error={hasError(errors, 'pac')}
                helperText={getError(errors, 'pac')}
                fullWidth
                name='pac'
                onChange={onChange}
                margin="normal"
                defaultValue={data.pac}
                value={data.pac}
                InputLabelProps={{
                    shrink: true,
                }}
                disabled={isDisabled}
            />
            {
                !isDisabled &&
                <div className='submit-container'>
                    <Button variant='contained' color='primary' onClick={onSubmit}>
                        {strings.postalAddressCode.form.ok}
                    </Button>
                    <Button variant='contained' color='secondary' onClick={onCancel}>
                        {strings.postalAddressCode.form.cancel}
                    </Button>
                </div>
            }
        </form>
    )
}
export default PostalAddressCodeImprovedForm;