import React from 'react'
import BaseControl from './BaseControl';
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

class DatePickerControl extends BaseControl {

  constructor(props) {
    super(props);

    this.state = {
      date: props.date,
      name: props.name ? props.name : '',
      hasError: props.hasError,
      error: props.error ? props.error : '',
      placeholder: props.placeholder ? props.placeholder : '',
      dateFormat: props.dateFormat ? props.dateFormat : 'MM/dd/yyyy',
      showTimeSelect: props.showTimeSelect ? props.showTimeSelect : false,
      showYearDropdown: props.showYearDropdown ? props.showYearDropdown : false,
      disabled: props.disabled ? props.disabled : false
    }
  }

  componentWillReceiveProps(props) {
    this.setState({
      date: props.date,
      hasError: props.hasError,
      error: props.error ? props.error : '',
      placeholder: props.placeholder ? props.placeholder : '',
      disabled: props.disabled ? props.disabled : false
    });
  }

  handleChange(date) {

    this.onChange({
      target: {
        name: this.state.name,
        value: date
      }
    });
  }

  render() {
    return (
      <div className={'datepicker-control-container' + (this.state.hasError ? ' error' : '')}>
        <label className="dateLabel">{this.props.label}</label>
        <DatePicker
          placeholderText={this.state.placeholder}
          selected={this.state.date}
          onChange={(date) => this.handleChange(date)}
          dateFormat={this.state.dateFormat}
          showTimeSelect={this.state.showTimeSelect}
          showYearDropdown={this.state.showYearDropdown}
          isClearable={!this.state.disabled}
          className={this.state.hasError ? 'error' : ''}
          disabled={this.state.disabled}
          timeFormat={"HH:mm"}
        />

        {
          this.state.hasError &&
          <p className='error'>{this.state.error}</p>
        }
      </div>
    )
  }
}

export default DatePickerControl;