const DOT_REGEX = /\./g;
const THOUSAND_DOT_SEPARATOR = /\B(?=(\d{3})+(?!\d))/g;

function parseNumber(value) {
  if (typeof value === "string") {
    return parseFloat(value.replace(DOT_REGEX, "").replace(/,/g, ".")) || 0;
  }
  return parseFloat(value) || 0;
}

function calculateAmount(data) {
  const casketAmount = parseNumber(data.casketAmount);
  const coverAmount = parseNumber(data.coverAmount);
  const crossAmount = parseNumber(data.crossAmount);
  const pyramidAmount = parseNumber(data.pyramidAmount);
  const reformationBoardAmount = parseNumber(data.reformationBoardAmount);
  const metalLettersAmount = parseNumber(data.metalLettersAmount);
  const plasticLettersAmount = parseNumber(data.plasticLettersAmount);
  const obituaryAmount = parseNumber(data.obituaryAmount);
  const tilesAndBadgesAmount = parseNumber(data.tilesAndBadgesAmount);
  const floralTributeAmount = parseNumber(data.floralTributeAmount);
  const scarvesAmount = parseNumber(data.scarvesAmount);
  const towelsAmount = parseNumber(data.towelsAmount);
  const urnAmount = parseNumber(data.urnAmount);
  const deceasedPreparationsAmount = parseNumber(data.deceasedPreparationsAmount);
  const furnishCoffinAmount = parseNumber(data.furnishCoffinAmount);
  const transportDeceasedAmount = parseNumber(data.transportDeceasedAmount);
  const funeralCostsAmount = parseNumber(data.funeralCostsAmount);
  const cremationCostsAmount = parseNumber(data.cremationCostsAmount);
  const pvcInsertAmount = parseNumber(data.pvcInsertAmount);
  const pvcBagForDeceasedTakeOutAmount = parseNumber(data.pvcBagForDeceasedTakeOutAmount);
  const tinInsertAmount = parseNumber(data.tinInsertAmount);
  const deceasedTakeOutFromApartmentAmount = parseNumber(data.deceasedTakeOutFromApartmentAmount);
  const additionalFieldOneAmount = parseNumber(data.additionalFieldOneAmount);
  const additionalFieldTwoAmount = parseNumber(data.additionalFieldTwoAmount);
  const additionalFieldThreeAmount = parseNumber(data.additionalFieldThreeAmount);

  const amount =
    casketAmount +
    coverAmount +
    crossAmount +
    pyramidAmount +
    reformationBoardAmount +
    metalLettersAmount +
    plasticLettersAmount +
    obituaryAmount +
    tilesAndBadgesAmount +
    floralTributeAmount +
    scarvesAmount +
    towelsAmount +
    urnAmount +
    deceasedPreparationsAmount +
    furnishCoffinAmount +
    transportDeceasedAmount +
    funeralCostsAmount +
    cremationCostsAmount +
    pvcInsertAmount +
    pvcBagForDeceasedTakeOutAmount +
    tinInsertAmount +
    deceasedTakeOutFromApartmentAmount +
    additionalFieldOneAmount +
    additionalFieldTwoAmount +
    additionalFieldThreeAmount;

    return amount.toFixed(2).replace(".", ",").replace(THOUSAND_DOT_SEPARATOR, ".") || "0";
}

function calculateTotalAmount(amount, refundAmount) {
  const parsedAmount = parseNumber(amount);
  const parsedRefundAmount = parseNumber(refundAmount);

  const totalAmount = parsedAmount - parsedRefundAmount;

  return totalAmount.toFixed(2).replace(".", ",").replace(THOUSAND_DOT_SEPARATOR, ".") || "0";
}

export {
  calculateAmount,
  calculateTotalAmount,
};
