import React from 'react'

import {bindActionCreators} from "redux";
import * as Actions from "../../actions/Actions";
import {withRouter} from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import strings from "../../localization";
import Page from "../../common/Page";
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Validators from "../../constants/ValidatorTypes";
import { changePassword, getCurrentUser } from '../../services/user/UserService';
import ChangePasswordForm from '../../components/forms/user/ChangePasswordForm';
import {withSnackbar} from "notistack";


class ChangePassword extends Page {

    validationList = {
        password: [ {type: Validators.REQUIRED } ],
        passwordRepeat: [ {type: Validators.REQUIRED } ],
    };

    constructor(props) {
        super(props);

        this.state = {
            data: {},
            errors: {},
            email: null,
        };

        this.props.changeFullScreen(true);

        this.keyPress = this.keyPress.bind(this);
    }

    componentDidMount() {

        getCurrentUser().then(response => {
            this.setState({
                email: response.data.email
            });
        });  
    }

    keyPress(event) {

        if(event.key == 'Enter') {
            this.resetPassword()
        }
    }

    passwordMatch() {

        return this.state.data.password === this.state.data.passwordRepeat;
    }

    changePassword(){

        let data = this.state.data;

        if(!this.validate()) {
            this.setError("password", strings.validation.AllRequiredErrorMessage);
            return;
        }

        if(!this.passwordMatch()) {
            this.setError('password', strings.validation.PasswordsNotEqual);
            return;
        }
        if(this.state.email != null) {
            changePassword(this.state.email, data.password).then(response => {
                if(!response.ok) {
                    this.setError("password", strings.validation.PasswordErrorMessage);
                    return;
                }

                this.props.history.push('/profile');
                this.props.enqueueSnackbar(strings.user.changePassword, { variant: 'success' });
            });
        }
        
    }

    render() {

        return (

            <div id='login'>
                <Grid item md={6}>
                    <Paper className='paper'>
                        <h1>{ strings.changePassword.changePassword }</h1>
                        <ChangePasswordForm 
                            onSubmit={ () => this.changePassword() } 
                            onChange={ this.changeData }                       
                            keyPress={ this.keyPress }                       
                            data={ this.state.data } errors={ this.state.errors }
                        />
                    </Paper>
                </Grid>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch)
{
    return bindActionCreators({
        changeFullScreen: Actions.changeFullScreen,
        login: Actions.login
    }, dispatch);
}

function mapStateToProps({ menuReducers, authReducers })
{
    return { menu: menuReducers, auth: authReducers };
}

export default withSnackbar(withRouter(connect(mapStateToProps, mapDispatchToProps)(ChangePassword)));