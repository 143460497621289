import React from 'react'
import TablePage from "../../common/TablePage";
import { bindActionCreators } from "redux";
import * as Actions from "../../actions/Actions";
import { withRouter } from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import strings from "../../localization";
import { withSnackbar } from "notistack";
import { TableCell } from "@material-ui/core";
import { getAuditTrails } from '../../services/auditTrail/AuditTrailService';
import {
  Drawer,
  TableHead, TableBody,
  TableRow, TextField
} from "@material-ui/core";
import PageState from '../../constants/PageState';
import SelectControl from '../../components/controls/SelectControl';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import DrawerWrapper from '../../common/DrawerWrapper';
import update from 'immutability-helper';
import { getAllCompanies } from '../../services/company/CompanyService';
import { dateTimeToString } from '../../util/DateUtil';
import SortDirection from '../../constants/SortDirection';


class AuditTrailList extends TablePage {

  tableDescription = [
    { key: 'actionName', label: strings.auditTrailList.actionName, sort: 'actionName' },
    { key: 'objectId', label: strings.auditTrailList.objectId, sort: 'objectId' },
    { key: 'date', label: strings.auditTrailList.date, transform: 'renderColumnDateTime', sort: 'date' },
    { key: 'email', label: strings.auditTrailList.email, sort: 'email' },
  ];


  constructor(props) {
    super(props);


    this.state = {
      ...this.state,
      showFilter: false,
      data: {
        selectedCompany: { id: -1 },
      },

    };
    this.state.sortBy = "date,DESC";

  }

  renderColumnDateTime(value) {
    return dateTimeToString(value);
  }

  fetchData() {
    let data = {
      searchParam: this.state.searchData.search,
      page: this.state.searchData.page - 1,
      size: this.state.searchData.perPage,
      companyId: this.state.companyId,
      selectedCompanyId: this.state.data.selectedCompany.id,
      sort: this.state.sortBy
    };

    {
      this.state.companyId != undefined &&
        getAuditTrails(data).then(response => {
          if (!response.ok) {
            return;
          }

          response.data.entities.forEach(element => {
            element.actionName = this.translateAction(element.actionName);
          });

          this.setState({
            tableData: response.data.entities,
            total: response.data.total,
          });
        });
    }
  }

  translateAction(action) {
    let str;
    switch (action) {
      case "VIEW":
        str = strings.auditTrailList.actions.VIEW;
        break;
      case "REPORT":
        str = strings.auditTrailList.actions.REPORT;
        break;
      case "ADD_USER":
        str = strings.auditTrailList.actions.ADD_USER;
        break;
      case "EDIT_USER":
        str = strings.auditTrailList.actions.EDIT_USER;
        break;
      case "DELETE_USER":
        str = strings.auditTrailList.actions.DELETE_USER;
        break;
      case "ADD_COMPANY":
        str = strings.auditTrailList.actions.ADD_COMPANY;
        break;
      case "EDIT_COMPANY":
        str = strings.auditTrailList.actions.EDIT_COMPANY;
        break;
      case "DELETE_COMPANY":
        str = strings.auditTrailList.actions.DELETE_COMPANY;
        break;
      case "ADD_OCCUPATION":
        str = strings.auditTrailList.actions.ADD_OCCUPATION;
        break;
      case "EDIT_OCCUPATION":
        str = strings.auditTrailList.actions.EDIT_OCCUPATION;
        break;
      case "DELETE_OCCUPATION":
        str = strings.auditTrailList.actions.DELETE_OCCUPATION;
        break;
      case "ADD_STATE":
        str = strings.auditTrailList.actions.ADD_STATE;
        break;
      case "EDIT_STATE":
        str = strings.auditTrailList.actions.EDIT_STATE;
        break;
      case "DELETE_STATE":
        str = strings.auditTrailList.actions.DELETE_STATE;
        break;
      case "ADD_CAUSE_OF_DEATH":
        str = strings.auditTrailList.actions.ADD_CAUSE_OF_DEATH;
        break;
      case "EDIT_CAUSE_OF_DEATH":
        str = strings.auditTrailList.actions.EDIT_CAUSE_OF_DEATH;
        break;
      case "DELETE_CAUSE_OF_DEATH":
        str = strings.auditTrailList.actions.DELETE_CAUSE_OF_DEATH;
        break;
      case "ADD_DOCTOR":
        str = strings.auditTrailList.actions.ADD_DOCTOR;
        break;
      case "EDIT_DOCTOR":
        str = strings.auditTrailList.actions.EDIT_DOCTOR;
        break;
      case "DELETE_DOCTOR":
        str = strings.auditTrailList.actions.DELETE_DOCTOR;
        break;
      case "ADD_TRANSPORTER":
        str = strings.auditTrailList.actions.ADD_TRANSPORTER;
        break;
      case "EDIT_TRANSPORTER":
        str = strings.auditTrailList.actions.EDIT_TRANSPORTER;
        break;
      case "DELETE_TRANSPORTER":
        str = strings.auditTrailList.actions.DELETE_TRANSPORTER;
        break;
      case "ADD_PRICELIST":
        str = strings.auditTrailList.actions.ADD_PRICELIST;
        break;
      case "EDIT_PRICELIST":
        str = strings.auditTrailList.actions.EDIT_PRICELIST;
        break;
      case "DELETE_PRICELIST":
        str = strings.auditTrailList.actions.DELETE_PRICELIST;
        break;
      case "ADD_URN":
        str = strings.auditTrailList.actions.ADD_URN;
        break;
      case "EDIT_URN":
        str = strings.auditTrailList.actions.EDIT_URN;
        break;
      case "DELETE_URN":
        str = strings.auditTrailList.actions.DELETE_URN;
        break;
      case "ADD_FAMOUS_CITIZEN":
        str = strings.auditTrailList.actions.ADD_FAMOUS_CITIZEN;
        break;
      case "EDIT_FAMOUS_CITIZEN":
        str = strings.auditTrailList.actions.EDIT_FAMOUS_CITIZEN;
        break;
      case "DELETE_FAMOUS_CITIZEN":
        str = strings.auditTrailList.actions.DELETE_FAMOUS_CITIZEN;
        break;
      case "ADD_SETTLEMENT":
        str = strings.auditTrailList.actions.ADD_SETTLEMENT;
        break;
      case "EDIT_SETTLEMENT":
        str = strings.auditTrailList.actions.EDIT_SETTLEMENT;
        break;
      case "DELETE_SETTLEMENT":
        str = strings.auditTrailList.actions.DELETE_SETTLEMENT;
        break;
      case "ADD_POSTAL_CODE":
        str = strings.auditTrailList.actions.ADD_POSTAL_CODE;
        break;
      case "EDIT_POSTAL_CODE":
        str = strings.auditTrailList.actions.EDIT_POSTAL_CODE;
        break;
      case "DELETE_POSTAL_CODE":
        str = strings.auditTrailList.actions.DELETE_POSTAL_CODE;
        break;
      case "ADD_POSTAL_ADDRESS_CODE":
        str = strings.auditTrailList.actions.ADD_POSTAL_ADDRESS_CODE;
        break;
      case "EDIT_POSTAL_ADDRESS_CODE":
        str = strings.auditTrailList.actions.EDIT_POSTAL_ADDRESS_CODE;
        break;
      case "DELETE_POSTAL_ADDRESS_CODE":
        str = strings.auditTrailList.actions.DELETE_POSTAL_ADDRESS_CODE;
        break;
      case "ADD_STONECUTTER":
        str = strings.auditTrailList.actions.ADD_STONECUTTER;
        break;
      case "EDIT_STONECUTTER":
        str = strings.auditTrailList.actions.EDIT_STONECUTTER;
        break;
      case "DELETE_STONECUTTER":
        str = strings.auditTrailList.actions.DELETE_STONECUTTER;
        break;
      case "ADD_GRAVEYARD":
        str = strings.auditTrailList.actions.ADD_GRAVEYARD;
        break;
      case "EDIT_GRAVEYARD":
        str = strings.auditTrailList.actions.EDIT_GRAVEYARD;
        break;
      case "DELETE_GRAVEYARD":
        str = strings.auditTrailList.actions.DELETE_GRAVEYARD;
        break;
      case "ADD_FIRM":
        str = strings.auditTrailList.actions.ADD_FIRM;
        break;
      case "EDIT_FIRM":
        str = strings.auditTrailList.actions.EDIT_FIRM;
        break;
      case "DELETE_FIRM":
        str = strings.auditTrailList.actions.DELETE_FIRM;
        break;
      case "ADD_CITIZEN":
        str = strings.auditTrailList.actions.ADD_CITIZEN;
        break;
      case "EDIT_CITIZEN":
        str = strings.auditTrailList.actions.EDIT_CITIZEN;
        break;
      case "DELETE_CITIZEN":
        str = strings.auditTrailList.actions.DELETE_CITIZEN;
        break;
      case "ADD_DECEASED_RECEPTION":
        str = strings.auditTrailList.actions.ADD_DECEASED_RECEPTION;
        break;
      case "EDIT_DECEASED_RECEPTION":
        str = strings.auditTrailList.actions.EDIT_DECEASED_RECEPTION;
        break;
      case "DELETE_DECEASED_RECEPTION":
        str = strings.auditTrailList.actions.DELETE_DECEASED_RECEPTION;
        break;
      case "ADD_MAIN_BOOK_BURIED":
        str = strings.auditTrailList.actions.ADD_MAIN_BOOK_BURIED;
        break;
      case "EDIT_MAIN_BOOK_BURIED":
        str = strings.auditTrailList.actions.EDIT_MAIN_BOOK_BURIED;
        break;
      case "DELETE_MAIN_BOOK_BURIED":
        str = strings.auditTrailList.actions.DELETE_MAIN_BOOK_BURIED;
        break;
      case "ADD_GRAVE_SITE":
        str = strings.auditTrailList.actions.ADD_GRAVE_SITE;
        break;
      case "EDIT_GRAVE_SITE":
        str = strings.auditTrailList.actions.EDIT_GRAVE_SITE;
        break;
      case "DELETE_GRAVE_SITE":
        str = strings.auditTrailList.actions.DELETE_GRAVE_SITE;
        break;
      case "ADD_MAIN_BOOK_CREMATED":
        str = strings.auditTrailList.actions.ADD_MAIN_BOOK_CREMATED;
        break;
      case "EDIT_MAIN_BOOK_OF_CREMATED":
        str = strings.auditTrailList.actions.EDIT_MAIN_BOOK_OF_CREMATED;
        break;
      case "DELETE_MAIN_BOOK_OF_CREMATED":
        str = strings.auditTrailList.actions.DELETE_MAIN_BOOK_OF_CREMATED;
        break;
      case "ADD_EXHUMATION":
        str = strings.auditTrailList.actions.ADD_EXHUMATION;
        break;
      case "EDIT_EXHUMATION":
        str = strings.auditTrailList.actions.EDIT_EXHUMATION;
        break;
      case "DELETE_EXHUMATION":
        str = strings.auditTrailList.actions.DELETE_EXHUMATION;
        break;
      case "ADD_AID":
        str = strings.auditTrailList.actions.ADD_AID;
        break;
      case "EDIT_AID":
        str = strings.auditTrailList.actions.EDIT_AID;
        break;
      case "DELETE_AID":
        str = strings.auditTrailList.actions.DELETE_AID;
        break;
      case "ADD_PRINCIPAL":
        str = strings.auditTrailList.actions.ADD_PRINCIPAL;
        break;
      case "EDIT_PRINCIPAL":
        str = strings.auditTrailList.actions.EDIT_PRINCIPAL;
        break;
      case "DELETE_PRINCIPAL":
        str = strings.auditTrailList.actions.DELETE_PRINCIPAL;
        break;
      case "ADD_REASON_BRINGING_DECEASED":
        str = strings.auditTrailList.actions.ADD_REASON_BRINGING_DECEASED;
        break;
      case "EDIT_REASON_BRINGING_DECEASED":
        str = strings.auditTrailList.actions.EDIT_REASON_BRINGING_DECEASED;
        break;
      case "DELETE_REASON_BRINGING_DECEASED":
        str = strings.auditTrailList.actions.DELETE_REASON_BRINGING_DECEASED;
        break;
      case "ADD_STORAGE_PLACE":
        str = strings.auditTrailList.actions.ADD_STORAGE_PLACE;
        break;
      case "EDIT_STORAGE_PLACE":
        str = strings.auditTrailList.actions.EDIT_STORAGE_PLACE;
        break;
      case "DELETE_STORAGE_PLACE":
        str = strings.auditTrailList.actions.DELETE_STORAGE_PLACE;
        break;
      case "ADD_DECEASED_BROUGHT":
        str = strings.auditTrailList.actions.ADD_DECEASED_BROUGHT;
        break;
      case "EDIT_DECEASED_BROUGHT":
        str = strings.auditTrailList.actions.EDIT_DECEASED_BROUGHT;
        break;
      case "DELETE_DECEASED_BROUGHT":
        str = strings.auditTrailList.actions.DELETE_DECEASED_BROUGHT;
        break;
      case "ADD_CRATE":
        str = strings.auditTrailList.actions.ADD_CRATE;
        break;
      case "EDIT_CRATE":
        str = strings.auditTrailList.actions.EDIT_CRATE;
        break;
      case "DELETE_CRATE":
        str = strings.auditTrailList.actions.DELETE_CRATE;
        break;
      case "ADD_TOMBSTONE":
        str = strings.auditTrailList.actions.ADD_TOMBSTONE;
        break;
      case "EDIT_TOMBSTONE":
        str = strings.auditTrailList.actions.EDIT_TOMBSTONE;
        break;
      case "DELETE_TOMBSTONE":
        str = strings.auditTrailList.actions.DELETE_TOMBSTONE;
        break;
      case "ADD_LETTER":
        str = strings.auditTrailList.actions.ADD_LETTER;
        break;
      case "EDIT_LETTER":
        str = strings.auditTrailList.actions.EDIT_LETTER;
        break;
      case "DELETE_LETTER":
        str = strings.auditTrailList.actions.DELETE_LETTER;
        break;
      case "ADD_LANGUAGE":
        str = strings.auditTrailList.actions.ADD_LANGUAGE;
        break;
      case "EDIT_LANGUAGE":
        str = strings.auditTrailList.actions.EDIT_LANGUAGE;
        break;
      case "DELETE_LANGUAGE":
        str = strings.auditTrailList.actions.DELETE_LANGUAGE;
        break;
      case "ADD_TYPEFACE":
        str = strings.auditTrailList.actions.ADD_TYPEFACE;
        break;
      case "EDIT_TYPEFACE":
        str = strings.auditTrailList.actions.EDIT_TYPEFACE;
        break;
      case "DELETE_TYPEFACE":
        str = strings.auditTrailList.actions.DELETE_TYPEFACE;
        break;
      case "ADD_STONE_CUTTING_WORK":
        str = strings.auditTrailList.actions.ADD_STONE_CUTTING_WORK;
        break;
      case "EDIT_STONE_CUTTING_WORK":
        str = strings.auditTrailList.actions.EDIT_STONE_CUTTING_WORK;
        break;
      case "DELETE_STONE_CUTTING_WORK":
        str = strings.auditTrailList.actions.DELETE_STONE_CUTTING_WORK;
        break;
      case "ADD_RESERVATION":
        str = strings.auditTrailList.actions.ADD_RESERVATION;
        break;
      case "EDIT_RESERVATION":
        str = strings.auditTrailList.actions.EDIT_RESERVATION;
        break;
      case "DELETE_RESERVATION":
        str = strings.auditTrailList.actions.DELETE_RESERVATION;
        break;
      case "CANCEL_RESERVATION":
        str = strings.auditTrailList.actions.CANCEL_RESERVATION;
        break;
      case "UNDO_CANCEL_RESERVATION":
        str = strings.auditTrailList.actions.UNDO_CANCEL_RESERVATION;
        break;
      case "ADD_TOMB_RENT":
        str = strings.auditTrailList.actions.ADD_TOMB_RENT;
        break;
      case "EDIT_TOMB_RENT":
        str = strings.auditTrailList.actions.EDIT_TOMB_RENT;
        break;
      case "DELETE_TOMB_RENT":
        str = strings.auditTrailList.actions.DELETE_TOMB_RENT;
        break;
      case "CANCEL_TOMB_RENT":
        str = strings.auditTrailList.actions.CANCEL_TOMB_RENT;
        break;
      case "UNDO_CANCEL_TOMB_RENT":
        str = strings.auditTrailList.actions.UNDO_CANCEL_TOMB_RENT;
        break;
      case "ADD_CASSETTE_RENT":
        str = strings.auditTrailList.actions.ADD_CASSETTE_RENT;
        break;
      case "EDIT_CASSETTE_RENT":
        str = strings.auditTrailList.actions.EDIT_CASSETTE_RENT;
        break;
      case "DELETE_CASSETTE_RENT":
        str = strings.auditTrailList.actions.DELETE_CASSETTE_RENT;
        break;
      case "CANCEL_CASSETTE_RENT":
        str = strings.auditTrailList.actions.CANCEL_CASSETTE_RENT;
        break;
      case "UNDO_CANCEL_CASSETTE_RENT":
        str = strings.auditTrailList.actions.UNDO_CANCEL_CASSETTE_RENT;
        break;
      case "EDIT_RENEWAL":
        str = strings.auditTrailList.actions.EDIT_RENEWAL;
        break;
      case "DELETE_RENEWAL":
        str = strings.auditTrailList.actions.DELETE_RENEWAL;
        break;
      case "CANCEL_RENEWAL":
        str = strings.auditTrailList.actions.CANCEL_RENEWAL;
        break;
      case "UNDO_CANCEL_RENEWAL":
        str = strings.auditTrailList.actions.UNDO_CANCEL_RENEWAL;
        break;
      case "ADD_GRASS":
        str = strings.auditTrailList.actions.ADD_GRASS;
        break;
      case "EDIT_GRASS":
        str = strings.auditTrailList.actions.EDIT_GRASS;
        break;
      case "DELETE_GRASS":
        str = strings.auditTrailList.actions.DELETE_GRASS;
        break;
      case "CANCEL_GRASS":
        str = strings.auditTrailList.actions.CANCEL_GRASS;
        break;
      case "UNDO_CANCEL_GRASS":
        str = strings.auditTrailList.actions.UNDO_CANCEL_GRASS;
        break;
      default:
        str = action;
    }
    return str;
  }

  componentDidMount() {
    getAllCompanies().then(response => {
      this.setState({
        companies: response.data.entities
      }, () => {
        this.state.companies.unshift({ id: -1, name: strings.company.all })
      });
    });

    if (this.props.auth.user.role === "SUPERADMIN") {
      this.setState({
        showFilter: true
      });
    }

    this.setState({
      companyId: this.props.auth.user.company == null ? 0 : this.props.auth.user.company.id
    }, () => { this.fetchData(); })
  }

  componentWillReceiveProps(nextProps) {
    strings.setLanguage(nextProps.auth.user.language);
    this.tableDescription = [
      { key: 'actionName', label: strings.auditTrailList.actionName, sort: 'actionName' },
      { key: 'objectId', label: strings.auditTrailList.objectId, sort: 'objectId' },
      { key: 'date', label: strings.auditTrailList.date, transform: 'renderColumnDateTime', sort: 'date' },
      { key: 'email', label: strings.auditTrailList.email, sort: 'email' },
    ];

    getAllCompanies().then(response => {
      this.setState({
        companies: response.data.entities
      }, () => {
        this.state.companies.unshift({ id: -1, name: strings.company.all })
      });
    });

    if (this.props.auth.user.role === "SUPERADMIN") {
      this.setState({
        showFilter: true
      });
    }

    this.setState({
      companyId: this.props.auth.user.company == null ? 0 : this.props.auth.user.company.id
    }, () => { this.fetchData(); })
  }

  changeData(event, data = 'data') {
    this.setState({
      [data]: update(this.state[data], { [event.target.name]: { $set: event.target.value } })
    }, () => { this.fetchData(); });
  }

  getPageHeader() {
    return <h1>{strings.auditTrailList.pageTitle}</h1>;
  }

  /** RENDER TABLE  **/

  renderTableRowData(item) {

    let result = [];

    for (let description of this.tableDescription) {

      result.push(
        <TableCell key={'table-data-' + description.key + '-' + result.length}>
          {
            description.transform !== undefined &&
            this[description.transform](item[description.key])
          }
          {
            description.transform === undefined &&
            item[description.key]
          }
        </TableCell>
      );
    }

    return result;
  }

  renderTableRow(data = []) {

    let result = [];

    for (let item of data) {

      let className = 'table-row-' + result.length % 2;

      result.push(
        <TableRow key={'table-row-' + result.length} className={className}>
          {this.renderTableRowData(item)}
        </TableRow>
      )
    }

    return (
      <TableBody>
        {result}
      </TableBody>
    );
  }

  renderTableHeader() {

    let result = [];

    for (let item of this.tableDescription) {

      result.push(
        <TableCell key={'table-header-' + result.length}>
          <div class='container-horizontal'>
            <div class='container-horizontal-label'>
              {item.label}
            </div>
            <div class='container-horizontal-icons'>
              {this.state.sortPropertie !== item.sort ? (
                <div class='container-vertical'>
                  <img src={'images/button.png'} id='table-page-image' onClick={() => this.handleSortBy(item.label, SortDirection.ASC)} />
                  <img src={'images/multimedia-option.png'} id='table-page-image2' onClick={() => this.handleSortBy(item.label, SortDirection.DESC)} />
                </div>
              ) : (
                <div>
                  {this.state.sortDirection === SortDirection.ASC && this.state.sortPropertie === item.sort ? (
                    <img src={'images/multimedia-option.png'} id='table-page-image2' onClick={() => this.handleSortBy(item.label, SortDirection.DESC)} />
                  ) : (
                    <img src={'images/button.png'} id='table-page-image' onClick={() => this.handleSortBy(item.label, SortDirection.ASC)} />
                  )}
                </div>
              )}
            </div>
          </div>
        </TableCell>
      )
    }

    return (
      <TableHead className='table-header'>
        <TableRow>
          {result}
        </TableRow>
      </TableHead>
    );
  }

  renderFilters() {
    return (
      <div style={{ display: 'flex', marginRight: '20px' }}>
        <SelectControl
          options={this.state.companies}
          selected={this.state.data.selectedCompany}
          onChange={this.changeData}
          label={strings.auditTrailList.companies}
          name={"selectedCompany"}
          nameKey={"name"}
          valueKey={"id"}
        />
      </div>
    );
  }

  render() {

    return (
      <Grid id='table-page'>
        {this.renderDialog(strings.table.confirmDelete, strings.delete.confirmation,
          this.cancelDelete, this.delete)}
        <div className='header'>
          {this.getPageHeader()}

          <div className='filter-controls'>
            {this.state.showFilter &&
              this.renderFilters()

            }
            {
              <TextField
                label={strings.table.search}
                type="search"
                name='search'
                value={this.state.searchData.search}
                onChange={this.searchChanged}
              />
            }
          </div>
        </div>
        <Paper md={12}>
          {this.renderTable(this.state.tableData)}
        </Paper>

        <Drawer id='drawer' anchor='right' open={this.showDrawer()} onClose={() => this.setPageState(PageState.View)} ModalProps={{disableBackdropClick: true,}} >
          <DrawerWrapper onBack={() => this.setPageState(PageState.View)}>
            {this.renderDrawerContent()}
          </DrawerWrapper>
        </Drawer>
      </Grid>
    );
  }

}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    changeFullScreen: Actions.changeFullScreen
  }, dispatch);
}

function mapStateToProps({ menuReducers, authReducers, siteDataReducers }) {
  return { menu: menuReducers, auth: authReducers, siteData: siteDataReducers };
}

export default withSnackbar(withRouter(connect(mapStateToProps, mapDispatchToProps)(AuditTrailList)));