import FormComponent from "../../common/FormComponent";
import React from "react";
import { bindActionCreators } from "redux";
import * as Actions from "../../actions/Actions";
import { withRouter } from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import Grid from "@material-ui/core/Grid";
import { Paper } from "@material-ui/core";
import strings from "../../localization";
import { withSnackbar } from "notistack";
import PostalAddressCodeForm from "../../components/forms/postalAddressCode/PostalAddressCodeForm";
import { getPostalAddressCodeById } from "../../services/postalAddressCode/PostalAddressCodeService";
import { getPostalCodeById } from "../../services/postalCode/PostalCodeService";
import { getSettlementById } from "../../services/settlement/SettlementService";
import { getStateById } from "../../services/state/StateService";

class ViewPostalAddressCode extends FormComponent {

  constructor(props) {
    super(props);

    this.state = {
      data: props.data ? props.data : {},
      errors: {},
    };

    this.props.changeFullScreen(false);
  }

  componentDidMount() {

    getPostalAddressCodeById(this.props.id).then(response => {
      this.setState({
        data: response.data
      }, () => {
        getPostalCodeById(this.state.data.postalNumberId).then(response => {
          this.setState({
            data: {
              ...this.state.data,
              postalNumber: response.data
            },
          });
        });
        getSettlementById(this.state.data.settlementId).then(response => {
          this.setState({
            data: {
              ...this.state.data,
              settlement: response.data
            },
          });
        });
        getStateById(this.state.data.stateId).then(response => {
          this.setState({
            data: {
              ...this.state.data,
              state: response.data
            },
          });
        });
      });
    });
  }

  render() {

    return (
      <Grid id="page" item md={12}>
        <div className="header">
          <h1>{strings.reservation.viewPageTitle}</h1>
        </div>

        <Paper className='paper'>
          <PostalAddressCodeForm
            onChange={this.changeData}
            onSubmit={this.submit}
            data={this.state.data}
            errors={this.state.errors}
            onCancel={this.props.onCancel}
            isDisabled={this.props.isDisabled}
            handleChange={this.handleChange}
            onAutocompleteChange={this.onAutocompleteChange}
            handleTextInputChange={this.handleTextInputChange}
            postalNumbers={[]}
            settlementCodes={[]}
            stateCodes={[]}
            flag={this.props.flag} />
        </Paper>

      </Grid>
    );
  }
}

function mapDispatchToProps(dispatch) {

  return bindActionCreators(
    {
      changeFullScreen: Actions.changeFullScreen
    },
    dispatch
  );
}

function mapStateToProps({ menuReducers, siteDataReducers }) {

  return { menu: menuReducers, siteData: siteDataReducers };
}

export default withSnackbar(
  withRouter(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(ViewPostalAddressCode
    )
  )
);
