import moment from "moment";
import strings from '../localization';

export function getYears(plusYears = 0) {

  let result = [];
  let currentYear = (new Date()).getFullYear();

  currentYear += plusYears;

  for (let i = currentYear; i >= currentYear - 99; i--) {

    result.push({
      name: i,
      value: i
    });
  }

  return result;
}

export function getMonths() {

  return [
    { id: 1, value: strings.months.jan, name: strings.months.jan },
    { id: 2, value: strings.months.feb, name: strings.months.feb },
    { id: 3, value: strings.months.mar, name: strings.months.mar },
    { id: 4, value: strings.months.apr, name: strings.months.apr },
    { id: 5, value: strings.months.may, name: strings.months.may },
    { id: 6, value: strings.months.jun, name: strings.months.jun },
    { id: 7, value: strings.months.jul, name: strings.months.jul },
    { id: 8, value: strings.months.aug, name: strings.months.aug },
    { id: 9, value: strings.months.sep, name: strings.months.sep },
    { id: 10, value: strings.months.oct, name: strings.months.oct },
    { id: 11, value: strings.months.nov, name: strings.months.nov },
    { id: 12, value: strings.months.dec, name: strings.months.dec },
  ];
}

export function getCurrentMonth() {

  const months = getMonths();
  return months[new Date().getMonth()];
}

export function getNextMonth(month = getCurrentMonth(), direction = "forward") {

  let id;
  if (direction === "back") {
    id = (month.id - 1 !== 0) ? month.id - 1 : 12;

  } else {
    id = (month.id + 1 !== 13) ? month.id + 1 : 1;
  }

  let next;
  getMonths().forEach(m => {
    if (m.id === id) {
      next = m;
    }
  });

  return next;
}

export function leapYear(year) {
  return ((year % 4 === 0) && (year % 100 !== 0)) || (year % 400 === 0);
}

export function stringToDate(date, format = 'DD-MM-YYYY') {
  return moment(date, format);
}

export function stringToDate2(date, format = 'DD.MM.YYYY/HH.mm') {
  return moment(date, format, true);
}

// export function stringToDate3(date, format = 'DD.MM.YYYY/ .') {
//   return moment(date, format, true);
// }

// export function stringToDate4(date, format = 'DD.MM.YYYY/') {
//   return moment(date, format, true);
// }


// export function stringToDate5(date, format = '  .  .YYYY/') {
//   return moment(date, format, true);
// }

// export function stringToDate6(date, format = '  .  .YYYY') {
//   return moment(date, format, true);
// }

// export function stringToDate7(date, format = '  .  .  ') {
//   return moment(date, format, true);
// }


export function dateToString(date, format = 'DD-MM-YYYY') {
  if (date == null) {
    return "";
  }
  return moment(date).format(format);
}

export function dateToString2(date, format = 'YYYY-MM-DD') {
  if (date == null) {
    return "";
  }
  return moment(date).format(format);
}

export function dateToString3(date, format = 'DD.MM.YYYY') {
  if (date == null) {
    return "";
  }
  return moment(date).format(format);
}

export function dateToStringDatePickerFormat(date, format = 'MM/DD/YYYY') {
  return moment(date).format(format);
}

export function dateToStringDatePickerFormat2(date, format = 'DD/MM/YYYY HH:mm') {
  return moment(date).format(format);
}

export function dateToStringDatePickerFormat3(date) {
  return date.toISOString();
}

export function dateTimeToString(date, format = 'DD-MM-YYYY HH:mm') {
  return date != null ? moment(date).format(format) : "";
}

export function isYear(year) {
  if (year > 999 && year < 10000) {
    return true;
  }
  else {
    return false;
  }
}

export function getYearFromDate(date, format = 'YYYY.') {
  return date != null ? moment(date).format(format) : null;
}