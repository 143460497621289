import {request} from "../../base/HTTP";
import HttpMethod from "../../constants/HttpMethod";

export async function getMainBooksOfBuried(data) {
    return await request('/api/mainBookOfBuried/search', data);
}

export async function addMainBookOfBuried(data, cid) {
    return await request('/api/mainBookOfBuried/' + cid, data, HttpMethod.POST);
}

export function getMainBookOfBuriedById(id) {
    return  request("api/mainBookOfBuried/" + id , {}, HttpMethod.GET);
}

export async function editMainBookOfBuried(data) {
    return await request("api/mainBookOfBuried/" + data.id, data, HttpMethod.PUT);
}

export async function deleteMainBookOfBuried(id, uid) {
    return await request('/api/mainBookOfBuried/' + id + '/' + uid, {} , HttpMethod.DELETE);
}

export function getAutocompleteObjectsById(data) {
    return  request("api/mainBookOfBuried/objects/" + data.id , data, HttpMethod.PUT);
}

export async function getDeletedMainBooksOfBuried(data){
    return await request('api/mainBookOfBuried/deleted',data)
}

export async function restoreMainBookOfBuried(data) {
    return await request("api/mainBookOfBuried/restore/" + data.id, data, HttpMethod.PUT);
}