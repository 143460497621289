import React from 'react'
import TablePage from "../../common/TablePage";
import { bindActionCreators } from "redux";
import * as Actions from "../../actions/Actions";
import { withRouter } from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import strings from "../../localization";
import { withSnackbar } from "notistack";
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { Drawer, TextField, TableRow, TableBody, FormControlLabel, Switch } from "@material-ui/core";
import PageState from '../../constants/PageState';
import DrawerWrapper from '../../common/DrawerWrapper';
import update from 'immutability-helper';
import { getMainBookOfBuriedSelect } from '../../util/ReviewsUtil';
import { getError, hasError, validatePersonalNumber } from '../../functions/Validation';
import { dateToString2, dateToString3 } from '../../util/DateUtil';
import DatePickerControl from '../../components/controls/DatePickerControl';
import { deleteExhumationRequest, generateExhumationRequestPdf, getDeletedExhumationRequests, getExhumationRequests, restoreExhumationRequest } from '../../services/exhumationRequest/ExhumationRequestService';

import AddExhumationRequest from './AddExhumationRequest';
import EditExhumationRequest from './EditExhumationRequest';
import ViewExhumationRequest from './ViewExhumationRequest';

import IconButton from "@material-ui/core/IconButton";
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';

class ExhumationRequestList extends TablePage {

  tableDescription = [
    { key: 'exhumationRequestNumber', label: strings.exhumationRequest.exhumationRequestNumber, sort: 'exhumationRequestNumber' },
    { key: 'deceasedNameSurname', label: strings.exhumationRequest.deceasedNameSurname, sort: 'deceasedNameSurname' },
    { key: 'fromGraveyard', label: strings.exhumationRequest.fromGraveyard, sort: 'fromGraveyard' },
    { key: 'fromGraveSite', label: strings.exhumationRequest.fromGraveSite, sort: 'fromGraveSite' },
    { key: 'toGraveyard', label: strings.exhumationRequest.toGraveyard, sort: 'toGraveyard' },
    { key: 'toGraveSite', label: strings.exhumationRequest.toGraveSite, sort: 'toGraveSite' },
    { key: 'dateTimeOfExhumation', label: strings.exhumationRequest.dateTimeOfExhumation, transform: 'renderColumnDateTime', sort: 'dateTimeOfExhumation' },
    { key: 'dateCreated', label: strings.exhumationRequest.dateCreated, transform: 'renderColumnDate', sort: 'dateCreated' },
  ];


  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
      selectMainBookOfBuried: [],
      showCitizen: false,
      checked: false,
      data: {
        selectedMainBookOfBuriedReview: { id: -1 },
        year: { value: -1 },
        month: { id: -1 },
        graveyard: { id: -1 },
      },
      errors: {},
      companyId: this.props.auth.user.company.id,
    };

    this.handleGeneratePdf = this.handleGeneratePdf.bind(this);

    this.state.sortBy = "dateCreated,DESC";

    this.changeData = this.changeData.bind(this);

    this.state.showDeleted = false;
  }


  fetchData() {
    let data = {
      searchParam: this.state.searchData.search,
      page: this.state.searchData.page - 1,
      size: this.state.searchData.perPage,
      companyId: this.props.auth.user.company.id,
      filterDate: this.state.data.filterDate ? dateToString2(this.state.data.filterDate) : "",
      sort: this.state.sortBy,
      userId: this.props.auth.user.id,
    };

    {
      !this.state.showDeleted && 
      getExhumationRequests(data).then(response => {
        if (!response.ok) {
          return;
        }
        this.setState({
          tableData: response.data.entities,
          total: response.data.total,
        });
      }); 
    }

    {
      this.state.showDeleted && 
      getDeletedExhumationRequests(data).then(response => {
        if (!response.ok) {
          return;
        }
        this.setState({
          tableData: response.data.entities,
          total: response.data.total,
        });
      }); 
    }
  }

  handleDeletedChange(){
    this.setState({
      showDeleted : !this.state.showDeleted
    },() =>{ this.fetchData(); })
  }

  usePermissions(){
    let data = {
      userId: this.props.auth.user.id,
      category: "Главна књига сахрањених"
    }
    this.getPermissionsS(data);
    this.removedItemsShow(this.props.removedItemsShow);

  }

  componentDidMount() {
    this.usePermissions()
    this.setState({
      companyId: this.props.auth.user.company.id,
      selectMainBookOfBuried: getMainBookOfBuriedSelect()
    }, () => {
      this.fetchData();
      this.state.selectMainBookOfBuried.unshift({ id: -1, name: strings.reviewMainBookOfBuried.all });
    });
  }

  componentWillReceiveProps(nextProps) {
    strings.setLanguage(nextProps.auth.user.language);
    this.tableDescription = [
      { key: 'exhumationRequestNumber', label: strings.exhumationRequest.exhumationRequestNumber, sort: 'exhumationRequestNumber' },
      { key: 'deceasedNameSurname', label: strings.exhumationRequest.deceasedNameSurname, sort: 'deceasedNameSurname' },
      { key: 'fromGraveyard', label: strings.exhumationRequest.fromGraveyard, sort: 'fromGraveyard' },
      { key: 'fromGraveSite', label: strings.exhumationRequest.fromGraveSite, sort: 'fromGraveSite' },
      { key: 'toGraveyard', label: strings.exhumationRequest.toGraveyard, sort: 'toGraveyard' },
      { key: 'toGraveSite', label: strings.exhumationRequest.toGraveSite, sort: 'toGraveSite' },
      { key: 'dateTimeOfExhumation', label: strings.exhumationRequest.dateTimeOfExhumation, transform: 'renderColumnDateTime', sort: 'dateTimeOfExhumation' },
      { key: 'dateCreated', label: strings.exhumationRequest.dateCreated, transform: 'renderColumnDate', sort: 'dateCreated' },
    ];
  }

  getPageHeader() {
    return <h1>{strings.exhumationRequestList.pageTitle}</h1>;
  }

  renderAddContent() {
    return <AddExhumationRequest
      cid={this.state.companyId}
      uemail={this.props.auth.user.email}
      onCancel={this.onCancel}
      onFinish={this.onFinish}
      flag={"add"}
      responsiblePersonId={this.props.auth.user.id}
    />
  }

  renderEditContent() {
    return <EditExhumationRequest
      id={this.state.selectedItem.id}
      uemail={this.props.auth.user.email}
      cid={this.state.companyId}
      onCancel={this.onCancel}
      onFinish={this.onFinish}
      isDisabled={false}
      flag={"edit"}
      responsiblePersonId={this.props.auth.user.id}
    />
  }

  renderViewContent() {
    return <ViewExhumationRequest
      id={this.state.selectedItem.id}
      isDisabled={true}
      onCancel={this.onCancel}
      onFinish={this.onFinish}
      flag={"view"}
    />
  }

  changeData(event, data = 'data') {
    this.setState({
      [data]: update(this.state[data], { [event.target.name]: { $set: event.target.value } })
    }, () => {
      this.fetchData();
    });
  }

  handleChange = event => {
    this.setState({ checked: event.target.checked }, () => {
      let validPersonalNumber = validatePersonalNumber(this.state.data.personalNumber)

      if (!validPersonalNumber) {
        this.setError("personalNumber", strings.citizen.messages.CITIZEN_PERSONAL_NUMBER_NOT_VALID);
      }
      else {
        this.unsetError("personalNumber");
      }
    })
  }

  delete(item) {

    this.setState({
      lockTable: true
    });


    deleteExhumationRequest(item.id, this.props.auth.user.id).then(response => {

      if (response && !response.ok) {
        this.onFinish(null);
        return;
      }

      this.props.enqueueSnackbar(strings.companyList.exhumationRequestDeleted, { variant: 'success' });

      this.onFinish(item);
      this.cancelDelete();

      this.setState({
        lockTable: false
      });
    });
  }

  restore(item){
    
    restoreExhumationRequest(this.transformRequest(item)).then(response => {
      if (response && !response.ok) {
        this.onFinish(null);
        return;
      }
      this.setState({
        showDeleted : !this.state.showDeleted,
      }, () => { this.fetchData(); })
    })
  }

  transformRequest(data) {
    return {
      ...data,
      userEmail: this.props.auth.user.email,
      companyId: this.state.companyId
    }
  }

  renderFilters() {
    return (
      <div className="renderFilters">
        <DatePickerControl
          label={strings.cremationScheduling.filterDate}
          error={getError(this.state.errors, 'filterDate')}
          hasError={hasError(this.state.errors, 'filterDate')}
          helperText={getError(this.state.errors, 'filterDate')}
          placeholder={strings.cremationScheduling.filterDate}
          name='filterDate'
          onChange={this.changeData}
          date={this.state.data.filterDate ? new Date(this.state.data.filterDate) : this.state.data.filterDate}
          isClearable={true}
          dateFormat='dd/MM/yyyy'
          disabled={false}
        />
      </div>
    );
  }

  handleGeneratePdf() {
    this.setState({
        loading: true,
    });

    let data = {
        exhumationRequests: this.state.tableData,
    }

    generateExhumationRequestPdf(data).then(response => {
        const blob = new Blob([response.data], { type: 'application/pdf' });

        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        
        link.download = `Zahtevi-za-ekshumaciju.pdf`;

        document.body.appendChild(link);

        link.click();

        document.body.removeChild(link);

        this.setState({
            loading: false,
        });
    });
  }

  renderTableRow(data = []) {

    let result = [];
    let expiryDate;
    let currentDate = new Date();
    let expiryDateIncremented;

    for (let item of data) {

      expiryDate = item.expiryDate != null ? new Date(item.expiryDate) : "";
      expiryDateIncremented = expiryDate;

      let className = 'table-row-' + result.length % 2;

      if (this.isRowSelected(item)) {
        className += ' selected';
      }

      if (item.dateTimeFuneral != null) {
        const now = new Date();
        const dateTimeFuneralCompleted = new Date(item.dateTimeFuneral);
        dateTimeFuneralCompleted.setHours(dateTimeFuneralCompleted.getHours() + 1);

        if (dateTimeFuneralCompleted <= now) {
          className = 'backgroundColorFuneralCompleted';
        }
      }

      result.push(
        <TableRow key={'table-row-' + result.length} className={className} onClick={() => this.selectRow(item)}>
          {this.renderRowMenu(result.length, item)}
          {this.renderTableRowData(item)}
        </TableRow>
      )
    }

    return (
      <TableBody>
        {result}
      </TableBody>
    );

  }

  render() {

    return (
      <Grid id='table-page'>
        {this.renderDialog(strings.table.confirmDelete, strings.delete.confirmation,
          this.cancelDelete, this.delete)}
        <div className='header'>
        
          {this.getPageHeader()}

          <div>
            {this.state.removedItemsShow &&
              <FormControlLabel
                control = {<Switch
                checked={this.state.showDeleted}
                onChange={this.handleDeletedChange}
                inputProps={{ 'aria-label': 'controlled' }}
                />}
                labelPlacement="start"
                label={!this.state.showDeleted ? "Прикажи обрисане" : "Прикажи необрисане"}
              />
            }
          </div> 

          <div className='filter-controls'>
            {
              this.renderFilters()
            }
            {
              this.state.showSearch &&
              <TextField
                label={strings.table.search}
                type="search"
                name='search'
                value={this.state.searchData.search}
                onChange={this.searchChanged}
              />
            }

            {
              this.state.showAdd &&
              this.renderTableControls()
            }
          </div>
        </div>
        <Paper md={12}>
          {this.renderTable(this.state.tableData)}
        </Paper>

        <Drawer id='drawer' anchor='right' open={this.showDrawer()} onClose={() => this.setPageState(PageState.View)} ModalProps={{disableBackdropClick: true,}} >
          <DrawerWrapper onBack={() => this.setPageState(PageState.View)}>
            {this.renderDrawerContent()}
          </DrawerWrapper>
        </Drawer>

        <div style={{ display: 'flex', justifyContent: 'end' }}>
          <IconButton disabled={this.state.tableData.length <= 0} onClick={() => this.handleGeneratePdf()}>
            {this.state.loading 
                ? (<CircularProgress size={24} color="inherit" />) 
                : (<InsertDriveFileIcon />)
            }
            <Typography variant="button">{strings.review.buttons.generatePDF}</Typography>
          </IconButton>
        </div>
        
      </Grid>
    );
  }

}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    changeFullScreen: Actions.changeFullScreen
  }, dispatch);
}

function mapStateToProps({ menuReducers, authReducers, siteDataReducers }) {
  return { menu: menuReducers, auth: authReducers, siteData: siteDataReducers };
}

export default withSnackbar(withRouter(connect(mapStateToProps, mapDispatchToProps)(ExhumationRequestList)));