import FormComponent from "../../../common/FormComponent";
import React from "react";
import { bindActionCreators } from "redux";
import * as Actions from "../../../actions/Actions";
import { withRouter } from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import Grid from "@material-ui/core/Grid";
import { Paper } from "@material-ui/core";
import strings from "../../../localization";
import Validators from "../../../constants/ValidatorTypes";
import { withSnackbar } from "notistack";
import { getDeceasedReceptionById, editDeceasedReception, getAutocompleteObjectsById } from "../../../services/deceasedReception/DeceasedReceptionService";
import DeceasedTakeawayRecordForm from "../../../components/forms/deceasedReception/deceasedTakeawayRecord/DeceasedTakeawayRecordForm";
import { getAllTransporters } from "../../../services/transporter/TransporterService";

class EditDeceasedTakeawayRecord extends FormComponent {

    validationList = {
        deceasedNameSurname: [{ type: Validators.REQUIRED }],
    };

    constructor(props) {
        super(props);

        this.state = {
            data: props.data ? props.data : {},
            errors: {},
        };

        this.state.sortBy = "name,ASC";
        this.props.changeFullScreen(false);
        this.submit = this.submit.bind(this);
    }

    componentDidMount() {
        getDeceasedReceptionById(this.props.id).then(response => {
            this.setState({
                data: response.data,
            }, () => {
                getAutocompleteObjectsById(this.state.data).then(response => {
                    this.setState({
                        data: {
                            ...this.state.data,
                            transporterT: response.data.transporterT,
                        }
                    });
                });
            });
        });

        getAllTransporters().then(response => {
            this.setState({
                transporterTs: response.data.entities,
            });
        })

    }

    submit() {

        if (!this.validate()) {
            this.props.enqueueSnackbar(strings.error.requiredFields, { variant: 'error' });
            return;
        }

        this.showDrawerLoader();

        editDeceasedReception(this.transformRequest(this.state.data)).then(response => {
            if (!response.ok) {
                let messageKey = response.response.data.message;

                this.props.enqueueSnackbar(strings.deceasedReception.messages[messageKey], {
                    variant: "error"
                });

                this.handleError(messageKey);

                return;
            }

            this.props.enqueueSnackbar(strings.addCompany.deceaseTakeawayRecordEdited, { variant: 'success' });
            this.props.onFinish(response.data.deceasedTakeawayRecord);

            this.hideDrawerLoader();
        });
    }

    transformRequest(data) {
        return {
            ...data,
            id: this.props.id,
            userEmail: this.props.uemail,
            companyId: this.props.cid,
            deceasedReceptionType: 3,
            transporterTId: this.state.data.transporterT ? this.state.data.transporterT.id : null,
        }
    }

    handleError(message) {
        switch (message) {
            case "DECEASED_RECEPTION_WITH_NAME_SURNAME_ALREADY_EXIST":
                this.setError("deceasedNameSurname", strings.deceasedReception.messages.DECEASED_RECEPTION_WITH_NAME_SURNAME_ALREADY_EXIST);
                break;
            case "DECEASED_RECEPTION_WITH_NAME_SURNAME_CANT_BE_NULL":
                this.setError("deceasedNameSurname", strings.deceasedReception.messages.DECEASED_RECEPTION_WITH_NAME_SURNAME_CANT_BE_NULL);
                break;
        }
    }

    handleChangeTab = (event, value) => {
        this.setState({
            value: value
        });
    };

    handleChange = (event) => {
        this.setState({
            data: {
                ...this.state.data, [event.target.name]: event.target.value
            }
        })
    };

    onAutocompleteChange = (event, values) => {
        let autocompleteName = event.target.id.split("-")[0];
        this.setState({
            data: {
                ...this.state.data, [autocompleteName]: values
            }
        });
    }

    render() {
        return (
            <Grid id="page" item md={12}>
                <div className="header">
                    <h1>{strings.deceasedTakeawayRecord.editPageTitle}</h1>
                </div>

                <Paper className="paper">
                    <DeceasedTakeawayRecordForm
                        onChange={this.changeData}
                        onSubmit={this.submit}
                        data={this.state.data}
                        errors={this.state.errors}
                        onCancel={this.props.onCancel}
                        handleChange={this.handleChange}
                        onAutocompleteChange={this.onAutocompleteChange}
                        handleTextInputChange={this.handleTextInputChange}
                        transporterTs={this.state.transporterTs}
                        flag={this.props.flag}
                    />
                </Paper>
            </Grid>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            changeFullScreen: Actions.changeFullScreen
        },
        dispatch
    );
}

function mapStateToProps({ menuReducers, siteDataReducers }) {
    return { menu: menuReducers, siteData: siteDataReducers };
}

export default withSnackbar(
    withRouter(
        connect(
            mapStateToProps,
            mapDispatchToProps
        )(EditDeceasedTakeawayRecord)
    )
);
