import {request} from "../../base/HTTP";
import HttpMethod from "../../constants/HttpMethod";

export async function getOccupations(data) {
    return await request('/api/occupation/search', data);
}

export async function addOccupation(data, cid) {
    return await request('/api/occupation/' + cid, data, HttpMethod.POST);
}

export function getOccupationById(id) {
    return  request("api/occupation/" + id , {}, HttpMethod.GET);
}

export async function editOccupation(data) {
    return await request("api/occupation/" + data.id, data, HttpMethod.PUT);
}

export async function deleteOccupation(id, uid) {
    return await request('/api/occupation/' + id + '/' + uid, {} , HttpMethod.DELETE);
}

export async function getDeletedOccupations(data){
    return await request('/api/occupation/deleted', data);
}

export async function restoreOccupation(data) {
    return await request("api/occupation/restore/" + data.id , data , HttpMethod.PUT)
}