import React from 'react';
import strings from "../../../localization";
import { getError, hasError } from "../../../functions/Validation";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Button, TextField, FormLabel, FormGroup, FormControlLabel, Checkbox, Radio, FormControl, RadioGroup } from "@material-ui/core";
import DatePickerControl from '../../controls/DatePickerControl';
import { stringToDate } from '../../../util/DateUtil';
import moment from 'moment';

const GraveSiteForm = ({
  onSubmit,
  onCancel,
  onChange,
  errors,
  data,
  isDisabled,
  belongingGraveyards,
  handleChange,
  onAutocompleteChange,
  handleTextInputChange,
  flag,
  typedInGraveSite,
  keyPressBackspace,
}) => {
  console.log('dejta', data)
  return (
    <form id='user-form'>
      {((data.belongingGraveyard && flag == "edit") || (flag == "add") || (data.belongingGraveyard && flag == "view")) &&
        <Autocomplete
          id={"belongingGraveyard"}
          name={"belongingGraveyard"}
          options={belongingGraveyards}
          getOptionLabel={option => option.name}
          onChange={onAutocompleteChange}
          renderInput={params => (
            <TextField
              {...params}
              variant="standard"
              label={strings.graveSite.form.belongingGraveyard + "*"}
              error={hasError(errors, 'belongingGraveyard')}
              helperText={getError(errors, 'belongingGraveyard')}
              margin="normal"
              fullWidth
              name={"belongingGraveyard"}
              onChange={handleTextInputChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
          disabled={isDisabled}
          value={data.belongingGraveyard}
        />
      }
      {((!data.belongingGraveyard && flag == "edit") || (!data.belongingGraveyard && flag == "view")) &&
        <Autocomplete
          id={"belongingGraveyard"}
          name={"belongingGraveyard"}
          options={belongingGraveyards}
          getOptionLabel={option => option.name}
          onChange={onAutocompleteChange}
          renderInput={params => (
            <TextField
              {...params}
              variant="standard"
              label={strings.graveSite.form.belongingGraveyard + "*"}
              error={hasError(errors, 'belongingGraveyard')}
              helperText={getError(errors, 'belongingGraveyard')}
              margin="normal"
              fullWidth
              name={"belongingGraveyard"}
              onChange={handleTextInputChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
          disabled={isDisabled}
          value={data.belongingGraveyard}
        />
      }
      {(flag == "edit" || flag == "view") &&
        < TextField
          noOptionsText={(typedInGraveSite != undefined ? strings.mainBookOfBuried.form.create + typedInGraveSite : "")}
          label={strings.graveSite.form.code}
          error={hasError(errors, 'code')}
          helperText={getError(errors, 'code')}
          fullWidth
          name='code'
          onChange={handleChange}
          margin="normal"
          defaultValue={data.code}
          value={data.code}
          InputLabelProps={{
            shrink: true,
          }}
          disabled={isDisabled}
          disableClearable={true}
          onKeyDown={keyPressBackspace}
        />
      }
      {flag == "add" &&
        <TextField
          label={strings.graveSite.form.graveField}
          error={hasError(errors, 'graveField')}
          helperText={getError(errors, 'graveField')}
          fullWidth
          name='graveField'
          onChange={onChange}
          margin="normal"
          defaultValue={data.graveField}
          value={data.graveField}
          InputLabelProps={{
            shrink: true,
          }}
          disabled={isDisabled}
        />
      }
      {flag == "add" &&
        <TextField
          label={strings.graveSite.form.gravePlot}
          error={hasError(errors, 'gravePlot')}
          helperText={getError(errors, 'gravePlot')}
          fullWidth
          name='gravePlot'
          onChange={onChange}
          margin="normal"
          defaultValue={data.gravePlot}
          value={data.gravePlot}
          InputLabelProps={{
            shrink: true,
          }}
          disabled={isDisabled}
        />
      }
      {flag == "add" &&
        <FormControl component="fieldset">
          <FormLabel className="checkboxLabel"> {strings.graveSite.form.addMoreThanOne} </FormLabel>
          <RadioGroup name="addMoreThanOne" value={(data.addMoreThanOne == true || data.addMoreThanOne == "true") ? "true" : "false"} onChange={handleChange} className="radioGroup">
            <FormControlLabel value={"true"} disabled={isDisabled} control={<Radio />} label={<span className="formControlLabel">{strings.deceasedReception.form.yes}</span>} />
            <FormControlLabel value={"false"} disabled={isDisabled} control={<Radio />} label={<span className="formControlLabel">{strings.deceasedReception.form.no}</span>} />
          </RadioGroup>
        </FormControl>
      }
      {flag == "add" &&
        <TextField
          label={strings.graveSite.form.placeNumberFrom + "*"}
          error={hasError(errors, 'placeNumberFrom')}
          helperText={getError(errors, 'placeNumberFrom')}
          fullWidth
          name='placeNumberFrom'
          onChange={onChange}
          margin="normal"
          defaultValue={data.placeNumberFrom}
          value={data.placeNumberFrom}
          InputLabelProps={{
            shrink: true,
          }}
          disabled={isDisabled}
        />
      }
      {(data.addMoreThanOne == "true" && flag == "add") &&
        <TextField
          label={strings.graveSite.form.placeNumberTo}
          error={hasError(errors, 'placeNumberTo')}
          helperText={getError(errors, 'placeNumberTo')}
          fullWidth
          name='placeNumberTo'
          onChange={onChange}
          margin="normal"
          defaultValue={data.placeNumberTo}
          value={data.placeNumberTo}
          InputLabelProps={{
            shrink: true,
          }}
          disabled={isDisabled}
        />
      }
      {flag == "add" &&
        <TextField
          label={strings.graveSite.form.labelGraveSite}
          error={hasError(errors, 'labelGraveSite')}
          helperText={getError(errors, 'labelGraveSite')}
          fullWidth
          name='labelGraveSite'
          onChange={onChange}
          margin="normal"
          defaultValue={data.labelGraveSite}
          value={data.labelGraveSite}
          InputLabelProps={{
            shrink: true,
          }}
          disabled={isDisabled}
        />
      }
      {flag == "add" &&
        <TextField
          label={strings.graveSite.form.typeGraveSite}
          error={hasError(errors, 'typeGraveSite')}
          helperText={getError(errors, 'typeGraveSite')}
          fullWidth
          name='typeGraveSite'
          onChange={onChange}
          margin="normal"
          defaultValue={data.typeGraveSite}
          value={data.typeGraveSite}
          InputLabelProps={{
            shrink: true,
          }}
          disabled={isDisabled}
        />
      }
      <label className="header-label"> {strings.graveSite.form.dimensions} </label>
      <TextField
        label={strings.graveSite.form.length}
        error={hasError(errors, 'length')}
        helperText={getError(errors, 'length')}
        fullWidth
        name='length'
        onChange={onChange}
        margin="normal"
        defaultValue={data.length}
        value={data.length}
        InputLabelProps={{
          shrink: true,
        }}
        disabled={isDisabled}
      />
      <TextField
        label={strings.graveSite.form.width}
        error={hasError(errors, 'width')}
        helperText={getError(errors, 'width')}
        fullWidth
        name='width'
        onChange={onChange}
        margin="normal"
        defaultValue={data.width}
        value={data.width}
        InputLabelProps={{
          shrink: true,
        }}
        disabled={isDisabled}
      />
      <TextField
        label={strings.graveSite.form.depth}
        error={hasError(errors, 'depth')}
        helperText={getError(errors, 'depth')}
        fullWidth
        name='depth'
        onChange={onChange}
        margin="normal"
        defaultValue={data.depth}
        value={data.depth}
        InputLabelProps={{
          shrink: true,
        }}
        disabled={isDisabled}
      />
      <TextField
        label={strings.graveSite.form.size}
        error={hasError(errors, 'size')}
        helperText={getError(errors, 'size')}
        fullWidth
        name='size'
        onChange={onChange}
        margin="normal"
        defaultValue={data.size}
        value={data.size}
        InputLabelProps={{
          shrink: true,
        }}
        disabled={isDisabled}
      />
      <div>
        <FormControl component="fieldset">
          <FormLabel className="checkboxLabel"> {strings.graveSite.form.arrangedPlot} </FormLabel>
          <RadioGroup name="arrangedPlot" value={(data.arrangedPlot == true || data.arrangedPlot == "true") ? "true" : "false"} onChange={handleChange} className="radioGroup">
            <FormControlLabel value={"true"} disabled={isDisabled} control={<Radio />} label={<span className="formControlLabel">{strings.deceasedReception.form.yes}</span>} />
            <FormControlLabel value={"false"} disabled={isDisabled} control={<Radio />} label={<span className="formControlLabel">{strings.deceasedReception.form.no}</span>} />
          </RadioGroup>
        </FormControl>
      </div>
      <div>
        <FormControl component="fieldset">
          <FormLabel className="checkboxLabel"> {strings.graveSite.form.os} </FormLabel>
          <RadioGroup name="os" value={(data.os == true || data.os == "true") ? "true" : "false"} onChange={handleChange} className="radioGroup">
            <FormControlLabel value={"true"} disabled={isDisabled} control={<Radio />} label={<span className="formControlLabel">{strings.deceasedReception.form.yes}</span>} />
            <FormControlLabel value={"false"} disabled={isDisabled} control={<Radio />} label={<span className="formControlLabel">{strings.deceasedReception.form.no}</span>} />
          </RadioGroup>
        </FormControl>
      </div>
      <div>
        <FormControl component="fieldset">
          <FormLabel className="checkboxLabel"> {strings.graveSite.form.childPlot} </FormLabel>
          <RadioGroup name="childPlot" value={(data.childPlot == true || data.childPlot == "true") ? "true" : "false"} onChange={handleChange} className="radioGroup">
            <FormControlLabel value={"true"} disabled={isDisabled} control={<Radio />} label={<span className="formControlLabel">{strings.deceasedReception.form.yes}</span>} />
            <FormControlLabel value={"false"} disabled={isDisabled} control={<Radio />} label={<span className="formControlLabel">{strings.deceasedReception.form.no}</span>} />
          </RadioGroup>
        </FormControl>
      </div>
      <div>
        <FormControl component="fieldset">
          <FormLabel className="checkboxLabel"> {"Zabrana"} </FormLabel>
          <RadioGroup name="z" value={(data.z == true || data.z == "1") ? "1" : "0"} onChange={handleChange} className="radioGroup">
            <FormControlLabel value={"1"} disabled={isDisabled} control={<Radio />} label={<span className="formControlLabel">{strings.deceasedReception.form.yes}</span>} />
            <FormControlLabel value={"0"} disabled={isDisabled} control={<Radio />} label={<span className="formControlLabel">{strings.deceasedReception.form.no}</span>} />
          </RadioGroup>
        </FormControl>
      </div>
      {(data.paymentDateString != undefined && data.paymentDateString != null && data.paymentDateString != "") &&
        <TextField
          label={strings.graveSite.form.paymentDateString}
          fullWidth
          name='paymentDateString'
          onChange={handleTextInputChange}
          margin="normal"
          value={data.paymentDateString}
          InputLabelProps={{
            shrink: true,
          }}
          disabled={true}
        />
      }
      <DatePickerControl
        label={strings.graveSite.form.paymentDate}
        error={getError(errors, 'paymentDate')}
        hasError={hasError(errors, 'paymentDate')}
        helperText={getError(errors, 'paymentDate')}
        placeholder={strings.graveSite.form.paymentDate}
        name='paymentDate'
        onChange={onChange}
        //date={data.paymentDate ? new Date(data.paymentDate) : data.paymentDate}
        date={data.paymentDate ? stringToDate(data.paymentDateString) > moment(data.paymentDate, 'YYYY-MM-DD') ? stringToDate(data.paymentDateString).toDate() : new Date(data.paymentDate) : data.paymentDate}
        isClearable={true}
        dateFormat='dd/MM/yyyy'
        disabled={isDisabled}
      />
      {/* <FormControl component="fieldset">
        <FormLabel className="checkboxLabel"> {strings.graveSite.form.renewal} </FormLabel>
        <RadioGroup name="renewal" value={(data.renewal == true || data.renewal == "true") ? "true" : "false"} onChange={handleChange} className="radioGroup">
          <FormControlLabel value={"true"} disabled={isDisabled} control={<Radio />} label={<span className="formControlLabel">{strings.deceasedReception.form.yes}</span>} />
          <FormControlLabel value={"false"} disabled={isDisabled} control={<Radio />} label={<span className="formControlLabel">{strings.deceasedReception.form.no}</span>} />
        </RadioGroup>
      </FormControl> */}
      {(data.expirationDateString != undefined && data.expirationDateString != null && data.expirationDateString != "" && data.renewal == "true") &&
        <TextField
          label={strings.graveSite.form.expirationDateString}
          fullWidth
          name='expirationDateString'
          onChange={handleTextInputChange}
          margin="normal"
          value={data.expirationDateString}
          InputLabelProps={{
            shrink: true,
          }}
          disabled={true}
        />
      }
      {data.renewal == "true" &&
        <DatePickerControl
          label={strings.graveSite.form.expirationDate}
          error={getError(errors, 'expirationDate')}
          hasError={hasError(errors, 'expirationDate')}
          helperText={getError(errors, 'expirationDate')}
          placeholder={strings.graveSite.form.expirationDate}
          name='expirationDate'
          onChange={onChange}
          //date={data.expirationDate ? new Date(data.expirationDate) : data.expirationDate}
          date={data.expirationDate ? stringToDate(data.expirationDateString) > moment(data.expirationDate, 'YYYY-MM-DD') ? stringToDate(data.expirationDateString).toDate() : new Date(data.expirationDate) : data.expirationDate}
          isClearable={true}
          dateFormat='dd/MM/yyyy'
          disabled={isDisabled}
        />
      }
      {
        !isDisabled &&
        <div className='submit-container'>
          <Button variant="contained" color="primary" onClick={onSubmit}>
            {strings.settlement.form.ok}
          </Button>
          <Button variant="contained" color="secondary" onClick={onCancel}>
            {strings.settlement.form.cancel}
          </Button>
        </div>
      }
    </form>
  )
};

export default GraveSiteForm;