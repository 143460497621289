import React from 'react';
import strings from "../../../localization";
import { getError, hasError } from "../../../functions/Validation";
import { Button, TextField, FormControlLabel, Checkbox } from "@material-ui/core";
import DatePickerControl from '../../controls/DatePickerControl';

const DeceasedDetailsEquipmentOrderForm = ({
  onSubmit,
  onCancel,
  onChange,
  errors,
  data,
  date,
  isDisabled,
  onChangePersonalNumberField,
  disabled,
  letterType,
  letterTypes,
  handleLetterTypeCheckboxChange,
}) => {
  
  return (

    <form id='user-form'>
        <TextField
            label={strings.equipmentOrder.form.orderNumber + " (генерисано аутоматски)"}
            error={hasError(errors, 'orderNumber')}
            helperText={getError(errors, 'orderNumber')}
            fullWidth
            autoFocus
            name='orderNumber'
            onChange={onChange}
            margin="normal"
            defaultValue={date}
            value={data.orderNumber}
            InputLabelProps={{
                shrink: true,
            }}
            disabled={true}
        />
      <TextField
        label={strings.equipmentOrder.form.identificationNumber}
        error={hasError(errors, 'identificationNumber')}
        helperText={getError(errors, 'identificationNumber')}
        fullWidth
        name='identificationNumber'
        onChange={onChange}
        margin="normal"
        defaultValue={data.identificationNumber}
        value={data.identificationNumber}
        InputLabelProps={{
          shrink: true,
        }}
        disabled={isDisabled}
      />

      <TextField
        label={strings.equipmentOrder.form.deceasedNameSurname}
        error={hasError(errors, 'deceasedNameSurname')}
        helperText={getError(errors, 'deceasedNameSurname')}
        fullWidth
        name='deceasedNameSurname'
        onChange={onChange}
        margin="normal"
        defaultValue={data.deceasedNameSurname}
        value={data.deceasedNameSurname}
        InputLabelProps={{
          shrink: true,
        }}
        disabled={isDisabled}
      />

        <DatePickerControl
            label={strings.equipmentOrder.form.dateOfBirth}
            error={getError(errors, 'dateOfBirth')}
            hasError={hasError(errors, 'dateOfBirth')}
            helperText={getError(errors, 'dateOfBirth')}
            placeholder={strings.equipmentOrder.form.dateOfBirth}
            name='dateOfBirth'
            onChange={onChange}
            date={data.dateOfBirth ? new Date(data.dateOfBirth) : data.dateOfBirth}
            isClearable={true}
            dateFormat='dd/MM/yyyy'
            disabled={isDisabled}
        />

        <DatePickerControl
            label={strings.equipmentOrder.form.dateTimeOfDeath}
            error={getError(errors, 'dateTimeOfDeath')}
            hasError={hasError(errors, 'dateTimeOfDeath')}
            helperText={getError(errors, 'dateTimeOfDeath')}
            placeholder={strings.equipmentOrder.form.dateTimeOfDeath}
            name='dateTimeOfDeath'
            onChange={onChange}
            date={data.dateTimeOfDeath ? new Date(data.dateTimeOfDeath) : data.dateTimeOfDeath}
            isClearable={true}
            dateFormat='dd/MM/yyyy HH:mm'
            showTimeSelect={true}
            disabled={isDisabled}
        />

        <TextField
            label={strings.equipmentOrder.form.yearsOfLife}
            error={hasError(errors, 'yearsOfLife')}
            helperText={getError(errors, 'yearsOfLife')}
            fullWidth
            name='yearsOfLife'
            onChange={onChange}
            margin="normal"
            defaultValue={data.yearsOfLife}
            value={data.yearsOfLife}
            InputLabelProps={{
            shrink: true,
            }}
            disabled={isDisabled}
        />

        <TextField
            label={strings.equipmentOrder.form.weight}
            error={hasError(errors, 'weight')}
            helperText={getError(errors, 'weight')}
            fullWidth
            name='weight'
            onChange={onChange}
            margin="normal"
            defaultValue={data.weight}
            value={data.weight}
            InputLabelProps={{
            shrink: true,
            }}
            disabled={isDisabled}
        />

        <TextField
            label={strings.equipmentOrder.form.height}
            error={hasError(errors, 'height')}
            helperText={getError(errors, 'height')}
            fullWidth
            name='height'
            onChange={onChange}
            margin="normal"
            defaultValue={data.height}
            value={data.height}
            InputLabelProps={{
            shrink: true,
            }}
            disabled={isDisabled}
        />

        <label className="checkboxLabel">{strings.equipmentOrder.form.letterType}</label>
        <div>
            {letterTypes.map(type => (
                <FormControlLabel
                    key={type.id}
                    control={
                    <Checkbox
                        checked={letterType && letterType.id === type.id}
                        onChange={handleLetterTypeCheckboxChange(type.name, type.id, type.value)}
                        disabled={isDisabled}
                    />
                    }
                    label={<span className="formControlLabel">{type.name}</span>}
                    style={{fontSize: '10px'}}
                />
            ))}
        </div>

        <TextField
            label={strings.equipmentOrder.form.currentLocationOfDeceased}
            error={hasError(errors, 'currentLocationOfDeceased')}
            helperText={getError(errors, 'currentLocationOfDeceased')}
            fullWidth
            name='currentLocationOfDeceased'
            onChange={onChange}
            margin="normal"
            defaultValue={data.currentLocationOfDeceased}
            value={data.currentLocationOfDeceased}
            InputLabelProps={{
            shrink: true,
            }}
            disabled={isDisabled}
        />

        <DatePickerControl
            label={strings.equipmentOrder.form.dateTimeOfTakeOut}
            error={getError(errors, 'dateTimeOfTakeOut')}
            hasError={hasError(errors, 'dateTimeOfTakeOut')}
            helperText={getError(errors, 'dateTimeOfTakeOut')}
            placeholder={strings.equipmentOrder.form.dateTimeOfTakeOut}
            name='dateTimeOfTakeOut'
            onChange={onChange}
            date={data.dateTimeOfTakeOut ? new Date(data.dateTimeOfTakeOut) : data.dateTimeOfTakeOut}
            isClearable={true}
            dateFormat='dd/MM/yyyy HH:mm'
            showTimeSelect={true}
            disabled={isDisabled}
        />

        <TextField
            label={strings.equipmentOrder.form.placeOfTakeOut}
            error={hasError(errors, 'placeOfTakeOut')}
            helperText={getError(errors, 'placeOfTakeOut')}
            fullWidth
            name='placeOfTakeOut'
            onChange={onChange}
            margin="normal"
            defaultValue={data.placeOfTakeOut}
            value={data.placeOfTakeOut}
            InputLabelProps={{
            shrink: true,
            }}
            disabled={isDisabled}
        />

        <TextField
            label={strings.equipmentOrder.form.deceasedIdNumber}
            error={hasError(errors, 'deceasedIdNumber')}
            helperText={getError(errors, 'deceasedIdNumber')}
            fullWidth
            name='deceasedIdNumber'
            onChange={onChange}
            margin="normal"
            defaultValue={data.deceasedIdNumber}
            value={data.deceasedIdNumber}
            InputLabelProps={{
            shrink: true,
            }}
            disabled={isDisabled}
        />

        <DatePickerControl
            label={strings.equipmentOrder.form.dateTimeOfFuneral}
            error={getError(errors, 'dateTimeOfFuneral')}
            hasError={hasError(errors, 'dateTimeOfFuneral')}
            helperText={getError(errors, 'dateTimeOfFuneral')}
            placeholder={strings.equipmentOrder.form.dateTimeOfFuneral}
            name='dateTimeOfFuneral'
            onChange={onChange}
            date={data.dateTimeOfFuneral ? new Date(data.dateTimeOfFuneral) : data.dateTimeOfFuneral}
            isClearable={true}
            dateFormat='dd/MM/yyyy HH:mm'
            showTimeSelect={true}
            disabled={isDisabled}
        />

        <TextField
            label={strings.equipmentOrder.form.graveyardFuneral}
            error={hasError(errors, 'graveyardFuneral')}
            helperText={getError(errors, 'graveyardFuneral')}
            fullWidth
            name='graveyardFuneral'
            onChange={onChange}
            margin="normal"
            defaultValue={data.graveyardFuneral}
            value={data.graveyardFuneral}
            InputLabelProps={{
            shrink: true,
            }}
            disabled={isDisabled}
        />

        {
            !isDisabled &&
            <div className='submit-container'>
                <Button variant="contained" color="primary" disabled={disabled} onClick={onSubmit}>
                    {strings.equipmentOrder.form.ok}
                </Button>
                <Button variant="contained" color="secondary" onClick={onCancel}>
                    {strings.equipmentOrder.form.cancel}
                </Button>
            </div>
        }
    </form>
  )
};

export default DeceasedDetailsEquipmentOrderForm;