import FormComponent from "../../common/FormComponent";
import React from "react";
import { bindActionCreators } from "redux";
import * as Actions from "../../actions/Actions";
import { withRouter } from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import Grid from "@material-ui/core/Grid";
import { Paper } from "@material-ui/core";
import strings from "../../localization";
import { withSnackbar } from "notistack";
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import { getAllTransporters } from "../../services/transporter/TransporterService";
import { getMainBookOfCrematedById, getAutocompleteObjectsById } from "../../services/mainBookOfCremated/MainBookOfCrematedService";
import DeceasedMainBookOfCrematedForm from '../../components/forms/mainBookOfCremated/DeceasedMainBookOfCrematedForm';
import DeathReportMainBookOfCrematedForm from '../../components/forms/mainBookOfCremated/DeathReportMainBookOfCrematedForm';
import CremationMainBookOfCremationForm from '../../components/forms/mainBookOfCremated/CremationMainBookOfCremationForm';
import { validatePersonalNumber, getDateFromIdentificationNumber } from "../../functions/Validation";
import { stringToDate, stringToDate2 } from "../../util/DateUtil";
import moment from "moment"

function TabContainer(props) {
  return (
    <Typography className="tabContainer" component="div">
      {props.children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

class ViewMainBookOfCremated extends FormComponent {

  constructor(props) {
    super(props);

    this.state = {
      data: props.data ? props.data : {},
      errors: {},
      value: 0,
      dateFlag: "",
      dateOfBirthWhenValidIDNumber: [],
    };

    this.props.changeFullScreen(false);
  }

  componentDidMount() {

    getMainBookOfCrematedById(this.props.id).then(response => {
      this.setState({
        data: response.data,
      }, () => {
        this.setState({
          data: {
            ...this.state.data,
            dateOfBirthString: response.data.dateOfBirthString,
            dateOfBirth: (response.data.dateOfBirthString && response.data.dateOfBirth == null) ? (stringToDate2(response.data.dateOfBirthString)._d == 'Invalid Date' ? (stringToDate(response.data.dateOfBirthString)._d == 'Invalid Date' ? null : stringToDate(response.data.dateOfBirthString)._d) : stringToDate2(response.data.dateOfBirthString)._d) : response.data.dateOfBirth,

            dateTimeOfDeathString: response.data.dateTimeOfDeathString,
            dateTimeOfDeath: (response.data.dateTimeOfDeathString && response.data.dateTimeOfDeath == null) ? (stringToDate2(response.data.dateTimeOfDeathString)._d == 'Invalid Date' ? (stringToDate(response.data.dateTimeOfDeathString)._d == 'Invalid Date' ? null : stringToDate(response.data.dateTimeOfDeathString)._d) : stringToDate2(response.data.dateTimeOfDeathString)._d) : response.data.dateTimeOfDeath,

            dateTimeOfTakeInString: response.data.dateTimeOfTakeInString,
            dateTimeOfTakeIn: (response.data.dateTimeOfTakeInString && response.data.dateTimeOfTakeIn == null) ? (stringToDate2(response.data.dateTimeOfTakeInString)._d == 'Invalid Date' ? (stringToDate(response.data.dateTimeOfTakeInString)._d == 'Invalid Date' ? null : stringToDate(response.data.dateTimeOfTakeInString)._d) : stringToDate2(response.data.dateTimeOfTakeInString)._d) : response.data.dateTimeOfTakeIn,

            dateTimeSayGoodbyeString: response.data.dateTimeSayGoodbyeString,
            dateTimeSayGoodbye: (response.data.dateTimeSayGoodbyeString && response.data.dateTimeSayGoodbye == null) ? (stringToDate2(response.data.dateTimeSayGoodbyeString)._d == 'Invalid Date' ? (stringToDate(response.data.dateTimeSayGoodbyeString)._d == 'Invalid Date' ? null : stringToDate(response.data.dateTimeSayGoodbyeString)._d) : stringToDate2(response.data.dateTimeSayGoodbyeString)._d) : response.data.dateTimeSayGoodbye,

            dateTimeOfCremationString: response.data.dateTimeOfCremationString,
            dateTimeOfCremation: (response.data.dateTimeOfCremationString && response.data.dateTimeOfCremation == null) ? (stringToDate2(response.data.dateTimeOfCremationString)._d == 'Invalid Date' ? (stringToDate(response.data.dateTimeOfCremationString)._d == 'Invalid Date' ? null : stringToDate(response.data.dateTimeOfCremationString)._d) : stringToDate2(response.data.dateTimeOfCremationString)._d) : response.data.dateTimeOfCremation,

            dateTimeUrnTakeoverString: response.data.dateTimeUrnTakeoverString,
            dateTimeUrnTakeover: (response.data.dateTimeUrnTakeoverString && response.data.dateTimeUrnTakeover == null) ? (stringToDate2(response.data.dateTimeUrnTakeoverString)._d == 'Invalid Date' ? (stringToDate(response.data.dateTimeUrnTakeoverString)._d == 'Invalid Date' ? null : stringToDate(response.data.dateTimeUrnTakeoverString)._d) : stringToDate2(response.data.dateTimeUrnTakeoverString)._d) : response.data.dateTimeUrnTakeover,

            //expiryDate: (response.data.expiryDateString && response.data.expiryDate == null) ? (stringToDate2(response.data.expiryDateString)._d == 'Invalid Date' ? (stringToDate(response.data.expiryDateString)._d == 'Invalid Date' ? null : stringToDate(response.data.expiryDateString)._d) : stringToDate2(response.data.expiryDateString)._d) : response.data.expiryDate,
            expiryDate: (stringToDate(response.data.expiryDateString) > moment(response.data.expiryDate, 'YYYY-MM-DD')) ? stringToDate(response.data.expiryDateString) : response.data.expiryDate,

          }
        });

        this.checkIfValidatePersonalNumber(this.state.data.identificationNumber);
        getAutocompleteObjectsById(this.state.data).then(response => {
          
          this.setState({
            data: {
              ...this.state.data,
              transporter: response.data.transporter,
              causeOfDeath: response.data.causeOfDeath,
              submitter: response.data.submitter,
              cost: response.data.cost,
              userGraveSite: response.data.userGraveSite,
              firmCost: response.data.firmCost,
              firmPays: this.state.data.firmPays.toString(),
              urn: response.data.urn,
              municipality: response.data.municipality,
              state: response.data.state,
              occupation: response.data.occupation,
              municipalityOfBirth: response.data.municipalityOfBirth,
              doctor: response.data.doctor,
              famousCitizen: response.data.famousCitizen,
              graveyard: response.data.graveyard,
              graveSite: response.data.graveSite,
            },
            submitter: {
              nameSurnameSubmitter: response.data.submitter ? response.data.submitter.name + " " + response.data.submitter.surname : null,
              addressSubmitter: response.data.submitter ? response.data.submitter.street + " " + response.data.submitter.streetNumber : "",
              municipalitySubmitter: response.data.submitter ? response.data.submitter.postalCodeImprovedZip : "",
              telephoneSubmitter: response.data.submitter ? response.data.submitter.telephone : "",
            },
            costInfo: {
              nameSurnameCosts: response.data.cost ? response.data.cost.name + " " + response.data.cost.surname : "",
              addressCosts: response.data.cost ? response.data.cost.street + " " + response.data.cost.streetNumber : "",
              municipalityCosts: response.data.cost ? response.data.cost.postalCodeImprovedZip : "",
              telephoneCosts: response.data.cost ? response.data.cost.telephone : "",
            },
            userInfo: {
              nameSurnameUser: response.data.userGraveSite ? response.data.userGraveSite.name + " " + response.data.userGraveSite.surname : "",
              addressUser: response.data.userGraveSite ? response.data.userGraveSite.street + " " + response.data.userGraveSite.streetNumber : "",
              municipalityUser: response.data.userGraveSite ? response.data.userGraveSite.postalCodeImprovedZip : "",
              telephoneUser: response.data.userGraveSite ? response.data.userGraveSite.telephone : "",
            },
            firmInfo: {
              taxNumberCost: response.data.firmCost ? response.data.firmCost.taxNumber : "",
            },
          })
        });
      });
    });

    getAllTransporters().then(response => {
      this.setState({
        transporters: response.data.entities,
      });
    });
  }

  checkIfValidatePersonalNumber(identificationNumber) {
    let dateOfBirth = "";
    let dateOfBirthArray = [];

    if (validatePersonalNumber(identificationNumber)) {
      dateOfBirth = getDateFromIdentificationNumber(identificationNumber);
      dateOfBirthArray = dateOfBirth.split('/');
      this.setState({
        dateFlag: "validIDNumber",
        dateOfBirthWhenValidIDNumber: dateOfBirthArray,
      });
    }
    else {
      this.setState({
        dateFlag: "invalidIDNumber"
      });
    }
  }

  handleChangeTab = (event, value) => {
    this.setState({
      value: value
    });
  };

  render() {

    return (
      <Grid id="page" item md={12}>
        <div className="header">
          <h1>{strings.mainBookOfCremated.viewPageTitle}</h1>
        </div>

        <div className="tabsDiv">
          <AppBar position="static">
            <Tabs value={this.state.value} className="tabs" onChange={this.handleChangeTab}>
              <Tab label={strings.mainBookOfCremated.deceased} className="threeTabs" />
              <Tab label={strings.mainBookOfCremated.deathReport} className="threeTabs" />
              <Tab label={strings.mainBookOfCremated.cremation} className="threeTabs" />
            </Tabs>
          </AppBar>
          {this.state.value === 0 && <TabContainer>

            <DeceasedMainBookOfCrematedForm
              onChange={this.changeData}
              onSubmit={this.submit}
              data={this.state.data}
              errors={this.state.errors}
              onCancel={this.props.onCancel}
              handleChange={this.handleChange}
              isDisabled={this.props.isDisabled}
              onAutocompleteChange={this.onAutocompleteChange}
              handleTextInputChange={this.handleTextInputChange}
              transporters={this.state.transporters}
              municipalities={this.state.settlements == undefined ? [] : this.state.settlements}
              states={this.state.states == undefined ? [] : this.state.states}
              occupations={this.state.occupations == undefined ? [] : this.state.occupations}
              causesOfDeath={this.state.causesOfDeath == undefined ? [] : this.state.causesOfDeath}
              doctors={this.state.doctors == undefined ? [] : this.state.doctors}
              famousCitizens={this.state.famousCitizens == undefined ? [] : this.state.famousCitizens}
              flag={this.props.flag}
              dateFlag={this.state.dateFlag}
              dateOfBirthWhenValidIDNumber={this.state.dateOfBirthWhenValidIDNumber}
            />

          </TabContainer>}
          {this.state.value === 1 && <TabContainer>

            <DeathReportMainBookOfCrematedForm
              onChange={this.changeData}
              onSubmit={this.submit}
              data={this.state.data}
              errors={this.state.errors}
              onCancel={this.props.onCancel}
              handleChange={this.handleChange}
              isDisabled={this.props.isDisabled}
              onAutocompleteChange={this.onAutocompleteChange}
              handleTextInputChange={this.handleTextInputChange}
              submitters={this.state.submitters == undefined ? [] : this.state.submitters}
              submitter={this.state.submitter}
              identificationSubmitterEqualsIdentificationCosts={this.identificationSubmitterEqualsIdentificationCosts}
              costs={this.state.costs == undefined ? [] : this.state.costs}
              cost={this.state.cost}
              identificationCostsEqualsIdentificationUser={this.identificationCostsEqualsIdentificationUser}
              users={this.state.users == undefined ? [] : this.state.users}
              userGraveSite={this.state.userGraveSite}
              identificationSubmitterEqualsIdentificationUser={this.identificationSubmitterEqualsIdentificationUser}
              costSameAsSubmitter={this.state.costSameAsSubmitter}
              userSameAsCost={this.state.userSameAsCost}
              userSameAsSubmitter={this.state.userSameAsSubmitter}
              costInfo={this.state.costInfo}
              userInfo={this.state.userInfo}
              firmInfo={this.state.firmInfo}
              flag={this.props.flag}
              firms={this.state.firms == undefined ? [] : this.state.firms}
              firmCost={this.state.firmCost}
            />

          </TabContainer>}
          {this.state.value === 2 && <TabContainer>

            <CremationMainBookOfCremationForm
              onChange={this.changeData}
              onSubmit={this.submit}
              data={this.state.data}
              errors={this.state.errors}
              onCancel={this.props.onCancel}
              handleChange={this.handleChange}
              isDisabled={this.props.isDisabled}
              onAutocompleteChange={this.onAutocompleteChange}
              handleTextInputChange={this.handleTextInputChange}
              graveyards={this.state.graveyards == undefined ? [] : this.state.graveyards}
              graveSites={this.state.graveSites == undefined ? [] : this.state.graveSites}
              urns={this.state.urns == undefined ? [] : this.state.urns}
              flag={this.props.flag}
            />

          </TabContainer>}
        </div>

      </Grid>
    );
  }
}

function mapDispatchToProps(dispatch) {

  return bindActionCreators(
    {
      changeFullScreen: Actions.changeFullScreen
    },
    dispatch
  );
}

function mapStateToProps({ menuReducers, siteDataReducers }) {

  return { menu: menuReducers, siteData: siteDataReducers };
}

export default withSnackbar(
  withRouter(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(ViewMainBookOfCremated)
  )
);
