import React from 'react';
import strings from "../../../localization";
import { getError, hasError } from "../../../functions/Validation";
import { Button, TextField, FormLabel, FormControlLabel, Checkbox, Radio, FormControl, RadioGroup, Box } from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';
import NumberFormat from 'react-number-format';

const EquipmentOrderForm = ({
  onSubmit,
  onCancel,
  onChange,
  errors,
  data,
  flag,
  isDisabled,
  disabled,
  handleChange,
  onAutocompleteChange,
  handleTextInputChange,
  tombstone,
  tombstones,
  handleTombstonesCheckboxChange,
  letter,
  letters,
  handleLettersCheckboxChange,
  deceasedPreparation,
  deceasedPreparations,
  handleDeceasedPreparationsCheckboxChange,
  transportOption,
  transportOptions,
  handleTransportOptionsCheckboxChange,
  typefaces,
}) => {
    console.log(data)
  return (
    <form id='user-form'>
        <Box className='flex-wrapper'>
            <Box flexGrow={1} width="100%">
                <TextField
                    label={strings.equipmentOrder.form.casket}
                    error={hasError(errors, 'casket')}
                    helperText={getError(errors, 'casket')}
                    fullWidth
                    name='casket'
                    onChange={onChange}
                    margin="normal"
                    defaultValue={data?.casket}
                    value={data?.casket}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    disabled={isDisabled}
                />
            </Box>

            <Box>
                {(data.casket != undefined && data.casket != null && data.casket != "") &&
                <NumberFormat
                    thousandSeparator={"."}
                    decimalSeparator={","}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    inputmode="numeric" 
                    displayType="numeric"
                    customInput={TextField}
                    type="text"
                    label={strings.equipmentOrder.form.casketAmount}
                    error={hasError(errors, 'casketAmount')}
                    helperText={getError(errors, 'casketAmount')}
                    fullWidth
                    name='casketAmount'
                    onChange={onChange}
                    margin="normal"
                    defaultValue={data?.casketAmount}
                    value={data?.casketAmount}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    disabled={isDisabled}
                    placeholder="0.00"
                />
                }
            </Box>
        </Box>
    
        <div>
            <FormControl component="fieldset">
                <FormLabel className="checkboxLabel"> {strings.equipmentOrder.form.cover} </FormLabel>
                <RadioGroup name="cover" value={(data.cover == true || data.cover == "true") ? "true" : "false"} onChange={handleChange} className="radioGroup">
                    <FormControlLabel value={"true"} disabled={isDisabled} control={<Radio />} label={<span className="formControlLabel">{strings.equipmentOrder.form.yes}</span>} />
                    <FormControlLabel value={"false"} disabled={isDisabled} control={<Radio />} label={<span className="formControlLabel">{strings.equipmentOrder.form.no}</span>} />
                </RadioGroup>
            </FormControl>
        </div>

        {(data.cover == "true" || data.cover == true) &&
        <React.Fragment>
            <Box className="flex-wrapper">
                <Box flexGrow={1}>
                    <TextField
                        label={strings.equipmentOrder.form.coverType}
                        error={hasError(errors, 'coverType')}
                        helperText={getError(errors, 'coverType')}
                        fullWidth
                        name='coverType'
                        onChange={onChange}
                        margin="normal"
                        defaultValue={data?.coverType}
                        value={data?.coverType}
                        InputLabelProps={{
                        shrink: true,
                        }}
                        disabled={isDisabled}
                    />
                </Box>

                <Box>
                    <NumberFormat
                        thousandSeparator={"."}
                        decimalSeparator={","}
                        decimalScale={2}
                        fixedDecimalScale={true}   
                        customInput={TextField}
                        type="text"
                        label={strings.equipmentOrder.form.coverAmount}
                        error={hasError(errors, 'coverAmount')}
                        helperText={getError(errors, 'coverAmount')}
                        fullWidth
                        name='coverAmount'
                        onChange={onChange}
                        margin="normal"
                        defaultValue={data?.coverAmount}
                        value={data?.coverAmount}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        disabled={isDisabled}
                        placeholder="0.00"
                    />
                </Box>
            </Box>
        </React.Fragment>
        }

        <label className="checkboxLabel">{strings.equipmentOrder.form.tombstones}</label>
        <div>
            {tombstones.map(type => (
                <FormControlLabel
                    key={type.id}
                    control={
                        <Checkbox
                            checked={tombstone && tombstone.id === type.id}
                            onChange={handleTombstonesCheckboxChange(type.name, type.id, type.value)}
                            disabled={isDisabled}
                        />
                    }
                    label={<span className="formControlLabel">{type.name}</span>}
                    style={{fontSize: '10px'}}
                />
            ))}
        </div>

        {tombstones?.some(item => item?.id === tombstone?.id) && tombstone?.name === strings.equipmentOrder.cross && (
            <React.Fragment>
                <Box className="flex-wrapper">
                    <Box flexGrow={1}>
                        <TextField
                            label={strings.equipmentOrder.form.tombstoneType}
                            error={hasError(errors, 'tombstoneType')}
                            helperText={getError(errors, 'tombstoneType')}
                            fullWidth
                            name='tombstoneType'
                            onChange={onChange}
                            margin="normal"
                            defaultValue={data?.tombstoneType}
                            value={data?.tombstoneType}
                            InputLabelProps={{
                            shrink: true,
                            }}
                            disabled={isDisabled}
                        />
                    </Box>

                    <Box>
                        <NumberFormat
                            thousandSeparator={"."}
                            decimalSeparator={","}
                            decimalScale={2}
                            fixedDecimalScale={true} 
                            inputmode="numeric" 
                            displayType="numeric"
                            customInput={TextField}
                            type="text"
                            label={strings.equipmentOrder.form.crossAmount}
                            error={hasError(errors, 'crossAmount')}
                            helperText={getError(errors, 'crossAmount')}
                            fullWidth
                            name='crossAmount'
                            onChange={onChange}
                            margin="normal"
                            defaultValue={data?.crossAmount}
                            value={data?.crossAmount}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            disabled={isDisabled}
                            placeholder="0.00"
                        />
                    </Box>
                </Box>
            </React.Fragment>                        
        )}

        {tombstones?.some(item => item?.id === tombstone?.id) && tombstone?.name === strings.equipmentOrder.pyramid && (
        <React.Fragment>
            <Box className="flex-wrapper">
                <Box flexGrow={1}>
                    <TextField
                        label={strings.equipmentOrder.form.tombstoneType}
                        error={hasError(errors, 'tombstoneType')}
                        helperText={getError(errors, 'tombstoneType')}
                        fullWidth
                        name='tombstoneType'
                        onChange={onChange}
                        margin="normal"
                        defaultValue={data?.tombstoneType}
                        value={data?.tombstoneType}
                        InputLabelProps={{
                        shrink: true,
                        }}
                        disabled={isDisabled}
                    />
                </Box>

                <Box>
                    <NumberFormat
                        thousandSeparator={"."}
                        decimalSeparator={","}
                        decimalScale={2}
                        fixedDecimalScale={true} 
                        inputmode="numeric" displayType="numeric"
                        customInput={TextField}
                        type="text"
                        label={strings.equipmentOrder.form.pyramidAmount}
                        error={hasError(errors, 'pyramidAmount')}
                        helperText={getError(errors, 'pyramidAmount')}
                        fullWidth
                        name='pyramidAmount'
                        onChange={onChange}
                        margin="normal"
                        defaultValue={data?.pyramidAmount}
                        value={data?.pyramidAmount}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        disabled={isDisabled}
                        placeholder="0.00"
                    />
                </Box>
            </Box>
        </React.Fragment>                        
        )}

        {tombstones?.some(item => item?.id === tombstone?.id) && tombstone?.name === strings.equipmentOrder.reformationBoard && (
        <React.Fragment>
            <Box className="flex-wrapper">
                <Box flexGrow={1}>
                    <TextField
                        label={strings.equipmentOrder.form.tombstoneType}
                        error={hasError(errors, 'tombstoneType')}
                        helperText={getError(errors, 'tombstoneType')}
                        fullWidth
                        name='tombstoneType'
                        onChange={onChange}
                        margin="normal"
                        defaultValue={data?.tombstoneType}
                        value={data?.tombstoneType}
                        InputLabelProps={{
                        shrink: true,
                        }}
                        disabled={isDisabled}
                    />
                </Box>

                <Box>
                    <NumberFormat
                        thousandSeparator={"."}
                        decimalSeparator={","}
                        decimalScale={2}
                        fixedDecimalScale={true} 
                        inputmode="numeric" 
                        displayType="numeric"
                        customInput={TextField}
                        type="text"
                        label={strings.equipmentOrder.form.reformationBoardAmount}
                        error={hasError(errors, 'reformationBoardAmount')}
                        helperText={getError(errors, 'reformationBoardAmount')}
                        fullWidth
                        name='reformationBoardAmount'
                        onChange={onChange}
                        margin="normal"
                        defaultValue={data.reformationBoardAmount}
                        value={data.reformationBoardAmount}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        disabled={isDisabled}
                        placeholder="0.00"
                    />
                </Box>
            </Box>
        </React.Fragment>                        
        )}

        <label className="checkboxLabel">{strings.equipmentOrder.form.letters}</label>
        <div>
            {letters?.map(item => (
                <FormControlLabel
                    key={item.id}
                    control={
                        <Checkbox
                            checked={letter && letter?.id === item?.id}
                            onChange={handleLettersCheckboxChange(item.name, item.id, item.value)}
                            disabled={isDisabled}
                        />
                    }
                    label={<span className="formControlLabel">{item.name}</span>}
                    style={{fontSize: '10px'}}
                />
            ))}

            {letters?.some(item => item?.id === letter?.id) && letter?.name === strings.equipmentOrder.metalLetters && (
                <div>
                    <React.Fragment>
                        <Autocomplete
                            id={"typeface"}
                            name={"typeface"}
                            options={typefaces}
                            getOptionLabel={option => option.name}
                            onChange={onAutocompleteChange}
                            renderInput={params => (
                            <TextField
                                {...params}
                                variant="standard"
                                label={strings.equipmentOrder.metalLetters}
                                error={hasError(errors, 'typeface')}
                                helperText={getError(errors, 'typeface')}
                                margin="normal"
                                fullWidth
                                name={"typeface"}
                                onChange={handleTextInputChange}
                                InputLabelProps={{
                                shrink: true,
                                }}
                            />
                            )}
                            disabled={isDisabled}
                            value={data?.typeface}
                        />
                        <Box className="flex-wrapper">
                            <Box flexGrow={1}>
                                <TextField
                                    label={strings.equipmentOrder.form.numberOfLetters}
                                    error={hasError(errors, 'numberOfLetters')}
                                    helperText={getError(errors, 'numberOfLetters')}
                                    fullWidth
                                    name='numberOfLetters'
                                    onChange={onChange}
                                    margin="normal"
                                    defaultValue={data.numberOfLetters}
                                    value={data.numberOfLetters}
                                    InputLabelProps={{
                                    shrink: true,
                                    }}
                                    disabled={isDisabled}
                                />
                            </Box>

                            <Box>
                                <NumberFormat
                                    thousandSeparator={"."}
                                    decimalSeparator={","}
                                    decimalScale={2}
                                    fixedDecimalScale={true} 
                                    inputmode="numeric" 
                                    displayType="numeric"
                                    customInput={TextField}
                                    type="text"
                                    label={strings.equipmentOrder.form.metalLettersAmount}
                                    error={hasError(errors, 'metalLettersAmount')}
                                    helperText={getError(errors, 'metalLettersAmount')}
                                    fullWidth
                                    name='metalLettersAmount'
                                    onChange={onChange}
                                    margin="normal"
                                    defaultValue={data.metalLettersAmount}
                                    value={data.metalLettersAmount}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    disabled={isDisabled}
                                    placeholder="0.00"
                                />
                            </Box>
                        </Box>
                </React.Fragment>                        
            </div>
            )}

            {letters.some(item => item?.id === letter?.id) && letter?.name === strings.equipmentOrder.plasticLetters && (
            <React.Fragment>
                <Autocomplete
                    id={"typeface"}
                    name={"typeface"}
                    options={typefaces}
                    getOptionLabel={option => option.name}
                    onChange={onAutocompleteChange}
                    renderInput={params => (
                    <TextField
                        {...params}
                        variant="standard"
                        label={strings.equipmentOrder.plasticLetters}
                        error={hasError(errors, 'typeface')}
                        helperText={getError(errors, 'typeface')}
                        margin="normal"
                        fullWidth
                        name={"typeface"}
                        onChange={handleTextInputChange}
                        InputLabelProps={{
                        shrink: true,
                        }}
                    />
                    )}
                    disabled={isDisabled}
                    value={data?.typeface}
                />

                <Box className="flex-wrapper">
                    <Box flexGrow={1}>
                        <TextField
                            label={strings.equipmentOrder.form.numberOfLetters}
                            error={hasError(errors, 'numberOfLetters')}
                            helperText={getError(errors, 'numberOfLetters')}
                            fullWidth
                            name='numberOfLetters'
                            onChange={onChange}
                            margin="normal"
                            defaultValue={data.numberOfLetters}
                            value={data.numberOfLetters}
                            InputLabelProps={{
                            shrink: true,
                            }}
                            disabled={isDisabled}
                        />
                    </Box>

                    <Box>
                        <NumberFormat
                            thousandSeparator={"."}
                            decimalSeparator={","}
                            decimalScale={2}
                            fixedDecimalScale={true}
                            inputmode="numeric" displayType="numeric"
                            customInput={TextField}
                            type="text"
                            label={strings.equipmentOrder.form.plasticLettersAmount}
                            error={hasError(errors, 'plasticLettersAmount')}
                            helperText={getError(errors, 'plasticLettersAmount')}
                            fullWidth
                            name='plasticLettersAmount'
                            onChange={onChange}
                            margin="normal"
                            defaultValue={data.plasticLettersAmount}
                            value={data.plasticLettersAmount}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            disabled={isDisabled}
                            placeholder="0.00"
                        />
                    </Box>
                </Box>
            </React.Fragment>                        
        )}
        </div>

        <div>
            <FormControl component="fieldset">
                <FormLabel className="checkboxLabel"> {strings.equipmentOrder.form.obituaryNotice} </FormLabel>
                <RadioGroup name="obituaryNotice" value={(data.obituaryNotice == true || data.obituaryNotice == "true") ? "true" : "false"} onChange={handleChange} className="radioGroup">
                    <FormControlLabel value={"true"} disabled={isDisabled} control={<Radio />} label={<span className="formControlLabel">{strings.equipmentOrder.form.yes}</span>} />
                    <FormControlLabel value={"false"} disabled={isDisabled} control={<Radio />} label={<span className="formControlLabel">{strings.equipmentOrder.form.no}</span>} />
                </RadioGroup>
            </FormControl>
        </div>

        {(data.obituaryNotice == "true" || data.obituaryNotice == true) &&
        <React.Fragment>
            <Box className="flex-wrapper">
                <Box flexGrow={1}>
                    <TextField
                        label={strings.equipmentOrder.form.numberOfNotices}
                        error={hasError(errors, 'numberOfNotices')}
                        helperText={getError(errors, 'numberOfNotices')}
                        fullWidth
                        name='numberOfNotices'
                        onChange={onChange}
                        margin="normal"
                        defaultValue={data.numberOfNotices}
                        value={data.numberOfNotices}
                        InputLabelProps={{
                        shrink: true,
                        }}
                        disabled={isDisabled}
                    />
                </Box>

                <Box>
                    <NumberFormat
                        thousandSeparator={"."}
                        decimalSeparator={","}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        inputmode="numeric" 
                        displayType="numeric"
                        customInput={TextField}
                        type="text"
                        label={strings.equipmentOrder.form.obituaryAmount}
                        error={hasError(errors, 'obituaryAmount')}
                        helperText={getError(errors, 'obituaryAmount')}
                        fullWidth
                        name='obituaryAmount'
                        onChange={onChange}
                        margin="normal"
                        defaultValue={data.obituaryAmount}
                        value={data.obituaryAmount}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        disabled={isDisabled}
                        placeholder="0.00"
                    />
                </Box>
            </Box>
        </React.Fragment>
        }

        <div>
            <FormControl component="fieldset">
                <FormLabel className="checkboxLabel"> {strings.equipmentOrder.form.tilesAndBadges} </FormLabel>
                <RadioGroup name="tilesAndBadges" value={(data.tilesAndBadges == true || data.tilesAndBadges == "true") ? "true" : "false"} onChange={handleChange} className="radioGroup">
                    <FormControlLabel value={"true"} disabled={isDisabled} control={<Radio />} label={<span className="formControlLabel">{strings.equipmentOrder.form.yes}</span>} />
                    <FormControlLabel value={"false"} disabled={isDisabled} control={<Radio />} label={<span className="formControlLabel">{strings.equipmentOrder.form.no}</span>} />
                </RadioGroup>
            </FormControl>
        </div>

        {(data.tilesAndBadges == "true" || data.tilesAndBadges == true) &&
        <React.Fragment>
            <Box className="flex-wrapper">
                <Box flexGrow={1}>
                    <TextField
                        label={strings.equipmentOrder.form.numberOfTilesAndBadges}
                        error={hasError(errors, 'numberOfTilesAndBadges')}
                        helperText={getError(errors, 'numberOfTilesAndBadges')}
                        fullWidth
                        name='numberOfTilesAndBadges'
                        onChange={onChange}
                        margin="normal"
                        defaultValue={data.numberOfTilesAndBadges}
                        value={data.numberOfNonumberOfTilesAndBadgestices}
                        InputLabelProps={{
                        shrink: true,
                        }}
                        disabled={isDisabled}
                    />
                </Box>

                <Box>
                    <NumberFormat
                        thousandSeparator={"."}
                        decimalSeparator={","}
                        decimalScale={2}
                        fixedDecimalScale={true} 
                        inputmode="numeric" 
                        displayType="numeric"
                        customInput={TextField}
                        type="text"
                        label={strings.equipmentOrder.form.tilesAndBadgesAmount}
                        error={hasError(errors, 'tilesAndBadgesAmount')}
                        helperText={getError(errors, 'tilesAndBadgesAmount')}
                        fullWidth
                        name='tilesAndBadgesAmount'
                        onChange={onChange}
                        margin="normal"
                        defaultValue={data.tilesAndBadgesAmount}
                        value={data.tilesAndBadgesAmount}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        disabled={isDisabled}
                        placeholder="0.00"
                    />
                </Box>
            </Box>
        </React.Fragment>
        }

        <div>
            <FormControl component="fieldset">
                <FormLabel className="checkboxLabel"> {strings.equipmentOrder.form.floralTribute} </FormLabel>
                <RadioGroup name="floralTribute" value={(data.floralTribute == true || data.floralTribute == "true") ? "true" : "false"} onChange={handleChange} className="radioGroup">
                    <FormControlLabel value={"true"} disabled={isDisabled} control={<Radio />} label={<span className="formControlLabel">{strings.equipmentOrder.form.yes}</span>} />
                    <FormControlLabel value={"false"} disabled={isDisabled} control={<Radio />} label={<span className="formControlLabel">{strings.equipmentOrder.form.no}</span>} />
                </RadioGroup>
            </FormControl>
        </div>

        {(data.floralTribute == "true" || data.floralTribute == true) &&
        <React.Fragment>
            <Box className="flex-wrapper">
                <Box flexGrow={1}>
                <TextField
                    label={strings.equipmentOrder.form.numberOfFloralTributes}
                    error={hasError(errors, 'numberOfFloralTributes')}
                    helperText={getError(errors, 'numberOfFloralTributes')}
                    fullWidth
                    name='numberOfFloralTributes'
                    onChange={onChange}
                    margin="normal"
                    defaultValue={data.numberOfFloralTributes}
                    value={data.numberOfFloralTributes}
                    InputLabelProps={{
                    shrink: true,
                    }}
                    disabled={isDisabled}
                />
                </Box>

                <Box>
                    <NumberFormat
                        thousandSeparator={"."}
                        decimalSeparator={","}
                        decimalScale={2}
                        fixedDecimalScale={true} 
                        inputmode="numeric"
                        displayType="numeric"
                        customInput={TextField}
                        type="text"
                        label={strings.equipmentOrder.form.floralTributeAmount}
                        error={hasError(errors, 'floralTributeAmount')}
                        helperText={getError(errors, 'floralTributeAmount')}
                        fullWidth
                        name='floralTributeAmount'
                        onChange={onChange}
                        margin="normal"
                        defaultValue={data.floralTributeAmount}
                        value={data.floralTributeAmount}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        disabled={isDisabled}
                        placeholder="0.00"
                    />
                </Box>
            </Box>
        </React.Fragment>
        }

        <div>
            <FormControl component="fieldset">
                <FormLabel className="checkboxLabel"> {strings.equipmentOrder.form.scarves} </FormLabel>
                <RadioGroup name="scarves" value={(data.scarves == true || data.scarves == "true") ? "true" : "false"} onChange={handleChange} className="radioGroup">
                    <FormControlLabel value={"true"} disabled={isDisabled} control={<Radio />} label={<span className="formControlLabel">{strings.equipmentOrder.form.yes}</span>} />
                    <FormControlLabel value={"false"} disabled={isDisabled} control={<Radio />} label={<span className="formControlLabel">{strings.equipmentOrder.form.no}</span>} />
                </RadioGroup>
            </FormControl>
        </div>

        {(data.scarves == "true" || data.scarves == true) &&
        <React.Fragment>
            <Box className="flex-wrapper">
                <Box flexGrow={1}>
                    <TextField
                        label={strings.equipmentOrder.form.numberOfScarves}
                        error={hasError(errors, 'numberOfScarves')}
                        helperText={getError(errors, 'numberOfScarves')}
                        fullWidth
                        name='numberOfScarves'
                        onChange={onChange}
                        margin="normal"
                        defaultValue={data.numberOfScarves}
                        value={data.numberOfScarves}
                        InputLabelProps={{
                        shrink: true,
                        }}
                        disabled={isDisabled}
                    />
                </Box>

                <Box>
                    <NumberFormat
                        thousandSeparator={"."}
                        decimalSeparator={","}
                        decimalScale={2}
                        fixedDecimalScale={true} 
                        inputmode="numeric" 
                        displayType="numeric"
                        customInput={TextField}
                        type="text"
                        label={strings.equipmentOrder.form.scarvesAmount}
                        error={hasError(errors, 'scarvesAmount')}
                        helperText={getError(errors, 'scarvesAmount')}
                        fullWidth
                        name='scarvesAmount'
                        onChange={onChange}
                        margin="normal"
                        defaultValue={data.scarvesAmount}
                        value={data.scarvesAmount}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        disabled={isDisabled}
                        placeholder="0.00"
                    />
                </Box>
            </Box>
        </React.Fragment>
        }

        <div>
            <FormControl component="fieldset">
                <FormLabel className="checkboxLabel"> {strings.equipmentOrder.form.towels} </FormLabel>
                <RadioGroup name="towels" value={(data.towels == true || data.towels == "true") ? "true" : "false"} onChange={handleChange} className="radioGroup">
                    <FormControlLabel value={"true"} disabled={isDisabled} control={<Radio />} label={<span className="formControlLabel">{strings.equipmentOrder.form.yes}</span>} />
                    <FormControlLabel value={"false"} disabled={isDisabled} control={<Radio />} label={<span className="formControlLabel">{strings.equipmentOrder.form.no}</span>} />
                </RadioGroup>
            </FormControl>
        </div>

        {(data.towels == "true" || data.towels == true) &&
        <React.Fragment>
            <Box className="flex-wrapper">
                <Box flexGrow={1}>
                <TextField
                    label={strings.equipmentOrder.form.numberOfTowels}
                    error={hasError(errors, 'numberOfTowels')}
                    helperText={getError(errors, 'numberOfTowels')}
                    fullWidth
                    name='numberOfTowels'
                    onChange={onChange}
                    margin="normal"
                    defaultValue={data.numberOfTowels}
                    value={data.numberOfTowels}
                    InputLabelProps={{
                    shrink: true,
                    }}
                    disabled={isDisabled}
                />
                </Box>

                <Box>
                    <NumberFormat
                        thousandSeparator={"."}
                        decimalSeparator={","}
                        decimalScale={2}
                        fixedDecimalScale={true} 
                        inputmode="numeric" 
                        displayType="numeric"
                        customInput={TextField}
                        type="text"
                        label={strings.equipmentOrder.form.towelsAmount}
                        error={hasError(errors, 'towelsAmount')}
                        helperText={getError(errors, 'towelsAmount')}
                        fullWidth
                        name='towelsAmount'
                        onChange={onChange}
                        margin="normal"
                        defaultValue={data.towelsAmount}
                        value={data.towelsAmount}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        disabled={isDisabled}
                        placeholder="0.00"
                    />
                </Box>
            </Box>
        </React.Fragment>
        }

        <div>
            <FormControl component="fieldset">
                <FormLabel className="checkboxLabel"> {strings.equipmentOrder.form.urn} </FormLabel>
                <RadioGroup name="urn" value={(data.urn == true || data.urn == "true") ? "true" : "false"} onChange={handleChange} className="radioGroup">
                    <FormControlLabel value={"true"} disabled={isDisabled} control={<Radio />} label={<span className="formControlLabel">{strings.equipmentOrder.form.yes}</span>} />
                    <FormControlLabel value={"false"} disabled={isDisabled} control={<Radio />} label={<span className="formControlLabel">{strings.equipmentOrder.form.no}</span>} />
                </RadioGroup>
            </FormControl>
        </div>

        {(data.urn == "true" || data.urn == true) &&
        <React.Fragment>
            <Box className="flex-wrapper">
                <Box flexGrow={1}>
                    <TextField
                        label={strings.equipmentOrder.form.urnType}
                        error={hasError(errors, 'urnType')}
                        helperText={getError(errors, 'urnType')}
                        fullWidth
                        name='urnType'
                        onChange={onChange}
                        margin="normal"
                        defaultValue={data.urnType}
                        value={data.urnType}
                        InputLabelProps={{
                        shrink: true,
                        }}
                        disabled={isDisabled}
                    />
                </Box>

                <Box>
                    <NumberFormat
                        thousandSeparator={"."}
                        decimalSeparator={","}
                        decimalScale={2}
                        fixedDecimalScale={true} 
                        inputmode="numeric"
                        displayType="numeric"
                        customInput={TextField}
                        type="text"
                        label={strings.equipmentOrder.form.urnAmount}
                        error={hasError(errors, 'urnAmount')}
                        helperText={getError(errors, 'urnAmount')}
                        fullWidth
                        name='urnAmount'
                        onChange={onChange}
                        margin="normal"
                        defaultValue={data.urnAmount}
                        value={data.urnAmount}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        disabled={isDisabled}
                        placeholder="0.00"
                    />
                </Box>
            </Box>
        </React.Fragment>
        }

        <label className="checkboxLabel">{strings.equipmentOrder.form.deceasedPreparations}</label>
        <div>
            {deceasedPreparations.map(item => (
                <FormControlLabel
                    key={item.id}
                    control={
                        <Checkbox
                            checked={deceasedPreparation.some(itemFromList => itemFromList.id === item.id)}
                            onChange={handleDeceasedPreparationsCheckboxChange(item.name, item.id)}
                            disabled={isDisabled}
                        />
                    }
                    label={<span className="formControlLabel">{item.name}</span>}
                    style={{fontSize: '10px'}}
                />
            ))}

            {deceasedPreparation.length >= 1 && (
                <NumberFormat
                    thousandSeparator={"."}
                    decimalSeparator={","}
                    decimalScale={2}
                    fixedDecimalScale={true} 
                    className=""
                    inputMode="numeric"
                    displayType="numeric"
                    customInput={TextField}
                    type="text"
                    label={strings.equipmentOrder.form.deceasedPreparationsAmount}
                    error={hasError(errors, 'deceasedPreparationsAmount')}
                    helperText={getError(errors, 'deceasedPreparationsAmount')}
                    fullWidth
                    name='deceasedPreparationsAmount'
                    onChange={onChange}
                    margin="normal"
                    defaultValue={data.deceasedPreparationsAmount}
                    value={data.deceasedPreparationsAmount}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    disabled={isDisabled}
                    placeholder="0.00"
                />
            )}
        </div>

        <div>
            <Box className="flex-wrapper">
                <Box flexGrow={1}>
                    <FormControl component="fieldset">
                        <FormLabel className="checkboxLabel"> {strings.equipmentOrder.form.furnishCoffin} </FormLabel>
                        <RadioGroup name="furnishCoffin" value={(data.furnishCoffin == true || data.furnishCoffin == "true") ? "true" : "false"} onChange={handleChange} className="radioGroup">
                            <FormControlLabel value={"true"} disabled={isDisabled} control={<Radio />} label={<span className="formControlLabel">{strings.equipmentOrder.form.yes}</span>} />
                            <FormControlLabel value={"false"} disabled={isDisabled} control={<Radio />} label={<span className="formControlLabel">{strings.equipmentOrder.form.no}</span>} />
                        </RadioGroup>
                    </FormControl>
                </Box>


                <Box>
                    {(data.furnishCoffin == "true" || data.furnishCoffin == true) &&
                    <NumberFormat
                        thousandSeparator={"."}
                        decimalSeparator={","}
                        decimalScale={2}
                        fixedDecimalScale={true} 
                        inputmode="numeric" 
                        displayType="numeric"
                        customInput={TextField}
                        type="text"
                        label={strings.equipmentOrder.form.furnishCoffinAmount}
                        error={hasError(errors, 'furnishCoffinAmount')}
                        helperText={getError(errors, 'furnishCoffinAmount')}
                        fullWidth
                        name='furnishCoffinAmount'
                        onChange={onChange}
                        margin="normal"
                        defaultValue={data.furnishCoffinAmount}
                        value={data.furnishCoffinAmount}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        disabled={isDisabled}
                        placeholder="0.00"
                    />
                    }
                </Box>
            </Box>
        </div>

        <div>
            <FormControl component="fieldset">
                <FormLabel className="checkboxLabel"> {strings.equipmentOrder.form.transportDeceased} </FormLabel>
                <RadioGroup name="transportDeceased" value={(data.transportDeceased == true || data.transportDeceased == "true") ? "true" : "false"} onChange={handleChange} className="radioGroup">
                    <FormControlLabel value={"true"} disabled={isDisabled} control={<Radio />} label={<span className="formControlLabel">{strings.equipmentOrder.form.yes}</span>} />
                    <FormControlLabel value={"false"} disabled={isDisabled} control={<Radio />} label={<span className="formControlLabel">{strings.equipmentOrder.form.no}</span>} />
                </RadioGroup>
            </FormControl>
        </div>

        {(data.transportDeceased == "true" || data.transportDeceased == true) &&
        <React.Fragment>
            <label className="checkboxLabel">{strings.equipmentOrder.form.transportOptions}</label>
            <div>
                {transportOptions.map(option => (
                    <div>
                        <FormControlLabel
                            key={option.id}
                            control={
                                <Checkbox
                                    checked={transportOption && transportOption.id === option.id}
                                    onChange={handleTransportOptionsCheckboxChange(option.name, option.id, option.value)}
                                    disabled={isDisabled}
                                />
                            }
                            label={<span className="formControlLabel">{option.name}</span>}
                            style={{fontSize: '10px'}}
                        />
                        {(transportOption && transportOption.id === option.id) && !(option.name === strings.equipmentOrder.withinCityLimits) &&
                            <TextField
                                label={strings.equipmentOrder.form.numberOfKilometers}
                                error={hasError(errors, 'numberOfKilometers')}
                                helperText={getError(errors, 'numberOfKilometers')}
                                fullWidth
                                name='numberOfKilometers'
                                onChange={onChange}
                                margin="normal"
                                defaultValue={data.numberOfKilometers}
                                value={data.numberOfKilometers}
                                InputLabelProps={{
                                shrink: true,
                                }}
                                disabled={isDisabled}
                            />
                        }
                    </div>
                ))}
                <NumberFormat
                    thousandSeparator={"."}
                    decimalSeparator={","}
                    decimalScale={2}
                    fixedDecimalScale={true} 
                    inputmode="numeric" 
                    displayType="numeric"
                    customInput={TextField}
                    type="text"
                    label={strings.equipmentOrder.form.transportDeceasedAmount}
                    error={hasError(errors, 'transportDeceasedAmount')}
                    helperText={getError(errors, 'transportDeceasedAmount')}
                    fullWidth
                    name='transportDeceasedAmount'
                    onChange={onChange}
                    margin="normal"
                    defaultValue={data.transportDeceasedAmount}
                    value={data.transportDeceasedAmount}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    disabled={isDisabled}
                    placeholder="0.00"
                />
            </div>
        </React.Fragment>
        }

        <div>
            <FormControl component="fieldset">
                <FormLabel className="checkboxLabel"> {strings.equipmentOrder.form.funeralCosts} </FormLabel>
                <RadioGroup name="funeralCosts" value={(data.funeralCosts == true || data.funeralCosts == "true") ? "true" : "false"} onChange={handleChange} className="radioGroup">
                    <FormControlLabel value={"true"} disabled={isDisabled} control={<Radio />} label={<span className="formControlLabel">{strings.equipmentOrder.form.yes}</span>} />
                    <FormControlLabel value={"false"} disabled={isDisabled} control={<Radio />} label={<span className="formControlLabel">{strings.equipmentOrder.form.no}</span>} />
                </RadioGroup>
            </FormControl>
        </div>

        {(data.funeralCosts == "true" || data.funeralCosts == true) &&
        <React.Fragment>
            <Box className="flex-wrapper">
                <Box flexGrow={1}>
                    <TextField
                        label={strings.equipmentOrder.form.typeOfGraveSite}
                        error={hasError(errors, 'typeOfGraveSite')}
                        helperText={getError(errors, 'typeOfGraveSite')}
                        fullWidth
                        name='typeOfGraveSite'
                        onChange={onChange}
                        margin="normal"
                        defaultValue={data.typeOfGraveSite}
                        value={data.typeOfGraveSite}
                        InputLabelProps={{
                        shrink: true,
                        }}
                        disabled={isDisabled}
                    />
                </Box>

                <Box>
                    <NumberFormat
                        thousandSeparator={"."}
                        decimalSeparator={","}
                        decimalScale={2}
                        fixedDecimalScale={true} 
                        inputmode="numeric" displayType="numeric"
                        customInput={TextField}
                        type="text"
                        label={strings.equipmentOrder.form.funeralCostsAmount}
                        error={hasError(errors, 'funeralCostsAmount')}
                        helperText={getError(errors, 'funeralCostsAmount')}
                        fullWidth
                        name='funeralCostsAmount'
                        onChange={onChange}
                        margin="normal"
                        defaultValue={data.funeralCostsAmount}
                        value={data.funeralCostsAmount}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        disabled={isDisabled}
                        placeholder="0.00"
                    />
                </Box>
            </Box>
        </React.Fragment>
        }

        <div>
            <FormControl component="fieldset">
                <FormLabel className="checkboxLabel"> {strings.equipmentOrder.form.cremationCosts} </FormLabel>
                <RadioGroup name="cremationCosts" value={(data.cremationCosts == true || data.cremationCosts == "true") ? "true" : "false"} onChange={handleChange} className="radioGroup">
                    <FormControlLabel value={"true"} disabled={isDisabled} control={<Radio />} label={<span className="formControlLabel">{strings.equipmentOrder.form.yes}</span>} />
                    <FormControlLabel value={"false"} disabled={isDisabled} control={<Radio />} label={<span className="formControlLabel">{strings.equipmentOrder.form.no}</span>} />
                </RadioGroup>
            </FormControl>
        </div>

        {(data.cremationCosts == "true" || data.cremationCosts == true) &&
        <React.Fragment>
            <Box className="flex-wrapper">
                <Box flexGrow={1}>
                    <TextField
                        label={strings.equipmentOrder.form.typeOfCremation}
                        error={hasError(errors, 'typeOfCremation')}
                        helperText={getError(errors, 'typeOfCremation')}
                        fullWidth
                        name='typeOfCremation'
                        onChange={onChange}
                        margin="normal"
                        defaultValue={data.typeOfCremation}
                        value={data.typeOfCremation}
                        InputLabelProps={{
                        shrink: true,
                        }}
                        disabled={isDisabled}
                    />
                </Box>

                <Box>
                    <NumberFormat
                        thousandSeparator={"."}
                        decimalSeparator={","}
                        decimalScale={2}
                        fixedDecimalScale={true} 
                        inputmode="numeric" 
                        displayType="numeric"
                        customInput={TextField}
                        type="text"
                        label={strings.equipmentOrder.form.cremationCostsAmount}
                        error={hasError(errors, 'cremationCostsAmount')}
                        helperText={getError(errors, 'cremationCostsAmount')}
                        fullWidth
                        name='cremationCostsAmount'
                        onChange={onChange}
                        margin="normal"
                        defaultValue={data.cremationCostsAmount}
                        value={data.cremationCostsAmount}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        disabled={isDisabled}
                        placeholder="0.00"
                    />
                </Box>
            </Box>
        </React.Fragment>
        }

        <div>
            <Box className="flex-wrapper">
                <Box flexGrow={1}>
                    <FormControl component="fieldset">
                    <FormLabel className="checkboxLabel"> {strings.equipmentOrder.form.pvcInsert} </FormLabel>
                        <RadioGroup name="pvcInsert" value={(data.pvcInsert == true || data.pvcInsert == "true") ? "true" : "false"} onChange={handleChange} className="radioGroup">
                            <FormControlLabel value={"true"} disabled={isDisabled} control={<Radio />} label={<span className="formControlLabel">{strings.equipmentOrder.form.yes}</span>} />
                            <FormControlLabel value={"false"} disabled={isDisabled} control={<Radio />} label={<span className="formControlLabel">{strings.equipmentOrder.form.no}</span>} />
                        </RadioGroup>
                    </FormControl>
                </Box>

                <Box>
                    {(data.pvcInsert == "true" || data.pvcInsert == true) && 
                    <NumberFormat
                        thousandSeparator={"."}
                        decimalSeparator={","}
                        decimalScale={2}
                        fixedDecimalScale={true}                     
                        inputmode="numeric" 
                        displayType="numeric"
                        customInput={TextField}
                        type="text"
                        label={strings.equipmentOrder.form.pvcInsertAmount}
                        error={hasError(errors, 'pvcInsertAmount')}
                        helperText={getError(errors, 'pvcInsertAmount')}
                        fullWidth
                        name='pvcInsertAmount'
                        onChange={onChange}
                        margin="normal"
                        defaultValue={data.pvcInsertAmount}
                        value={data.pvcInsertAmount}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        disabled={isDisabled}
                        placeholder="0.00"
                    />
                    }
                </Box>
            </Box>
        </div>

        <div>
            <Box className="flex-wrapper">
                <Box flexGrow={1}>
                    <FormControl component="fieldset">
                        <FormLabel className="checkboxLabel"> {strings.equipmentOrder.form.pvcBagForDeceasedTakeOut} </FormLabel>
                        <RadioGroup name="pvcBagForDeceasedTakeOut" value={(data.pvcBagForDeceasedTakeOut == true || data.pvcBagForDeceasedTakeOut == "true") ? "true" : "false"} onChange={handleChange} className="radioGroup">
                            <FormControlLabel value={"true"} disabled={isDisabled} control={<Radio />} label={<span className="formControlLabel">{strings.equipmentOrder.form.yes}</span>} />
                            <FormControlLabel value={"false"} disabled={isDisabled} control={<Radio />} label={<span className="formControlLabel">{strings.equipmentOrder.form.no}</span>} />
                        </RadioGroup>
                    </FormControl>
                </Box>


                <Box>
                    {(data.pvcBagForDeceasedTakeOut == "true" || data.pvcBagForDeceasedTakeOut == true) && 
                    <NumberFormat
                        thousandSeparator={"."}
                        decimalSeparator={","}
                        decimalScale={2}
                        fixedDecimalScale={true} 
                        inputmode="numeric" 
                        displayType="numeric"
                        customInput={TextField}
                        type="text"
                        label={strings.equipmentOrder.form.pvcBagForDeceasedTakeOutAmount}
                        error={hasError(errors, 'pvcBagForDeceasedTakeOutAmount')}
                        helperText={getError(errors, 'pvcBagForDeceasedTakeOutAmount')}
                        fullWidth
                        name='pvcBagForDeceasedTakeOutAmount'
                        onChange={onChange}
                        margin="normal"
                        defaultValue={data.pvcBagForDeceasedTakeOutAmount}
                        value={data.pvcBagForDeceasedTakeOutAmount}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        disabled={isDisabled}
                        placeholder="0.00"
                    />
                    }
                </Box>
            </Box>
        </div>

        <div>
            <Box className="flex-wrapper">
                <Box flexGrow={1}>
                    <FormControl component="fieldset">
                        <FormLabel className="checkboxLabel"> {strings.equipmentOrder.form.tinInsert} </FormLabel>
                        <RadioGroup name="tinInsert" value={(data.tinInsert == true || data.tinInsert == "true") ? "true" : "false"} onChange={handleChange} className="radioGroup">
                            <FormControlLabel value={"true"} disabled={isDisabled} control={<Radio />} label={<span className="formControlLabel">{strings.equipmentOrder.form.yes}</span>} />
                            <FormControlLabel value={"false"} disabled={isDisabled} control={<Radio />} label={<span className="formControlLabel">{strings.equipmentOrder.form.no}</span>} />
                        </RadioGroup>
                    </FormControl>
                </Box>

                <Box>
                    {(data.tinInsert == "true" || data.tinInsert == true) && 
                    <NumberFormat
                        thousandSeparator={"."}
                        decimalSeparator={","}
                        decimalScale={2}
                        fixedDecimalScale={true} 
                        inputmode="numeric" 
                        displayType="numeric"
                        customInput={TextField}
                        type="text"
                        label={strings.equipmentOrder.form.tinInsertAmount}
                        error={hasError(errors, 'tinInsertAmount')}
                        helperText={getError(errors, 'tinInsertAmount')}
                        fullWidth
                        name='tinInsertAmount'
                        onChange={onChange}
                        margin="normal"
                        defaultValue={data.tinInsertAmount}
                        value={data.tinInsertAmount}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        disabled={isDisabled}
                        placeholder="0.00"
                    />
                    }
                </Box>
            </Box>
        </div>

        <div>
            <Box className="flex-wrapper">
                <Box flexGrow={1}>
                    <FormControl component="fieldset">
                        <FormLabel className="checkboxLabel"> {strings.equipmentOrder.form.deceasedTakeOutFromApartment} </FormLabel>
                        <RadioGroup name="deceasedTakeOutFromApartment" value={(data.deceasedTakeOutFromApartment == true || data.deceasedTakeOutFromApartment == "true") ? "true" : "false"} onChange={handleChange} className="radioGroup">
                            <FormControlLabel value={"true"} disabled={isDisabled} control={<Radio />} label={<span className="formControlLabel">{strings.equipmentOrder.form.yes}</span>} />
                            <FormControlLabel value={"false"} disabled={isDisabled} control={<Radio />} label={<span className="formControlLabel">{strings.equipmentOrder.form.no}</span>} />
                        </RadioGroup>
                    </FormControl>
                </Box>

                <Box>
                    {(data.deceasedTakeOutFromApartment == "true" || data.deceasedTakeOutFromApartment == true) && 
                    <NumberFormat
                        thousandSeparator={"."}
                        decimalSeparator={","}
                        decimalScale={2}
                        fixedDecimalScale={true} 
                        inputmode="numeric" 
                        displayType="numeric"
                        customInput={TextField}
                        type="text"
                        label={strings.equipmentOrder.form.deceasedTakeOutFromApartmentAmount}
                        error={hasError(errors, 'deceasedTakeOutFromApartmentAmount')}
                        helperText={getError(errors, 'deceasedTakeOutFromApartmentAmount')}
                        fullWidth
                        name='deceasedTakeOutFromApartmentAmount'
                        onChange={onChange}
                        margin="normal"
                        defaultValue={data.deceasedTakeOutFromApartmentAmount}
                        value={data.deceasedTakeOutFromApartmentAmount}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        disabled={isDisabled}
                        placeholder="0.00"
                    />
                    }
                </Box>
            </Box>
        </div>


        <Box className="flex-wrapper">
            <Box flexGrow={1} width="100%">
                <TextField
                    label={strings.equipmentOrder.form.additionalFieldOne}
                    error={hasError(errors, 'additionalFieldOne')}
                    helperText={getError(errors, 'additionalFieldOne')}
                    fullWidth
                    name='additionalFieldOne'
                    onChange={onChange}
                    margin="normal"
                    defaultValue={data?.additionalFieldOne}
                    value={data?.additionalFieldOne}
                    InputLabelProps={{
                    shrink: true,
                    }}
                    disabled={isDisabled}
                />
            </Box>

            <Box>
            {(data.additionalFieldOne != undefined && data.additionalFieldOne != null && data.additionalFieldOne != "") && (
                    <NumberFormat
                        thousandSeparator={"."}
                        decimalSeparator={","}
                        decimalScale={2}
                        fixedDecimalScale={true} 
                        inputmode="numeric" 
                        displayType="numeric"
                        customInput={TextField}
                        type="text"
                        label={strings.equipmentOrder.form.additionalFieldOneAmount}
                        error={hasError(errors, 'additionalFieldOneAmount')}
                        helperText={getError(errors, 'additionalFieldOneAmount')}
                        fullWidth
                        name='additionalFieldOneAmount'
                        onChange={onChange}
                        margin="normal"
                        defaultValue={data?.additionalFieldOneAmount}
                        value={data?.additionalFieldOneAmount}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        disabled={isDisabled}
                        placeholder="0.00"
                    />
                )}
            </Box>
        </Box>

        <Box className="flex-wrapper">
            <Box flexGrow={1} width="100%">
                <TextField
                    label={strings.equipmentOrder.form.additionalFieldTwo}
                    error={hasError(errors, 'additionalFieldTwo')}
                    helperText={getError(errors, 'additionalFieldTwo')}
                    fullWidth
                    name='additionalFieldTwo'
                    onChange={onChange}
                    margin="normal"
                    defaultValue={data?.additionalFieldTwo}
                    value={data?.additionalFieldTwo}
                    InputLabelProps={{
                    shrink: true,
                    }}
                    disabled={isDisabled}
                />
            </Box>

            <Box>
            {(data.additionalFieldTwo != undefined && data.additionalFieldTwo != null && data.additionalFieldTwo != "") && (
                    <NumberFormat
                        thousandSeparator={"."}
                        decimalSeparator={","}
                        decimalScale={2}
                        fixedDecimalScale={true} 
                        inputmode="numeric" 
                        displayType="numeric"
                        customInput={TextField}
                        type="text"
                        label={strings.equipmentOrder.form.additionalFieldTwoAmount}
                        error={hasError(errors, 'additionalFieldTwoAmount')}
                        helperText={getError(errors, 'additionalFieldTwoAmount')}
                        fullWidth
                        name='additionalFieldTwoAmount'
                        onChange={onChange}
                        margin="normal"
                        defaultValue={data?.additionalFieldTwoAmount}
                        value={data?.additionalFieldTwoAmount}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        disabled={isDisabled}
                        placeholder="0.00"
                    />
                )}
            </Box>
        </Box>

        <Box className="flex-wrapper">
            <Box flexGrow={1} width="100%">
                <TextField
                    label={strings.equipmentOrder.form.additionalFieldThree}
                    error={hasError(errors, 'additionalFieldThree')}
                    helperText={getError(errors, 'additionalFieldThree')}
                    fullWidth
                    name='additionalFieldThree'
                    onChange={onChange}
                    margin="normal"
                    defaultValue={data?.additionalFieldThree}
                    value={data?.additionalFieldThree}
                    InputLabelProps={{
                    shrink: true,
                    }}
                    disabled={isDisabled}
                />
            </Box>

            <Box>
                {(data.additionalFieldThree != undefined && data.additionalFieldThree != null && data.additionalFieldThree != "") && (
                    <NumberFormat
                        thousandSeparator={"."}
                        decimalSeparator={","}
                        decimalScale={2}
                        fixedDecimalScale={true} 
                        inputmode="numeric" 
                        displayType="numeric"
                        customInput={TextField}
                        type="text"
                        label={strings.equipmentOrder.form.additionalFieldThreeAmount}
                        error={hasError(errors, 'additionalFieldThreeAmount')}
                        helperText={getError(errors, 'additionalFieldThreeAmount')}
                        fullWidth
                        name='additionalFieldThreeAmount'
                        onChange={onChange}
                        margin="normal"
                        defaultValue={data?.additionalFieldThreeAmount}
                        value={data?.additionalFieldThreeAmount}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        disabled={isDisabled}
                        placeholder="0.00"
                    />
                )}
            </Box>
        </Box>

        {(flag == "add" || flag == "edit" || flag == "view") &&
            <NumberFormat
                thousandSeparator={"."}
                decimalSeparator={","}
                decimalScale={2}
                fixedDecimalScale={true} 
                inputmode="numeric" 
                displayType="numeric"
                customInput={TextField}
                type="text"
                label={strings.equipmentOrder.form.amount}
                error={hasError(errors, 'amount')}
                helperText={getError(errors, 'amount')}
                fullWidth
                name='amount'
                onChange={onChange}
                margin="normal"
                defaultValue={data.amount}
                value={data.amount}
                InputLabelProps={{
                    shrink: true,
                }}
                disabled={true}
                placeholder="0.00"
            />
        }

        {(flag == "add" || flag == "edit" || flag == "view") &&
            <NumberFormat
                thousandSeparator={"."}
                decimalSeparator={","}
                decimalScale={2}
                fixedDecimalScale={true} 
                inputmode="numeric" 
                displayType="numeric"
                customInput={TextField}
                type="text"
                label={strings.equipmentOrder.form.refundAmount}
                error={hasError(errors, 'refundAmount')}
                helperText={getError(errors, 'refundAmount')}
                fullWidth
                name='refundAmount'
                onChange={onChange}
                margin="normal"
                defaultValue={data.refundAmount}
                value={data.refundAmount}
                InputLabelProps={{
                    shrink: true,
                }}
                disabled={isDisabled}
                placeholder="0.00"
            />
        }

        {data.amount == null && data.refundAmount == null &&
            <TextField
                label={strings.equipmentOrder.form.totalAmount}
                error={hasError(errors, 'totalAmount')}
                helperText={getError(errors, 'totalAmount')}
                fullWidth
                name='totalAmount'
                onChange={onChange}
                margin="normal"
                value={'0.00'}
                InputLabelProps={{
                    shrink: true,
                }}
                disabled={true}
                placeholder="0.00"
            />
        }

        {data.amount == null && data.refundAmount != null &&
            <TextField
                label={strings.equipmentOrder.form.totalAmount}
                error={hasError(errors, 'totalAmount')}
                helperText={getError(errors, 'totalAmount')}
                fullWidth
                name='totalAmount'
                onChange={onChange}
                margin="normal"
                value={-data.refundAmount}
                InputLabelProps={{
                    shrink: true,
                }}
                disabled={true}
                placeholder="0.00"
            />
        }

        {data.amount != null && data.refundAmount == null &&
            <TextField
                label={strings.equipmentOrder.form.totalAmount}
                error={hasError(errors, 'totalAmount')}
                helperText={getError(errors, 'totalAmount')}
                fullWidth
                name='totalAmount'
                onChange={onChange}
                margin="normal"
                value={data.totalAmount}
                InputLabelProps={{
                    shrink: true,
                }}
                disabled={true}
                placeholder="0.00"
            />
        }

        {data.amount != null && data.refundAmount != null &&
            <TextField
                label={strings.equipmentOrder.form.totalAmount}
                error={hasError(errors, 'totalAmount')}
                helperText={getError(errors, 'totalAmount')}
                fullWidth
                name='totalAmount'
                onChange={onChange}
                margin="normal"
                value={data.totalAmount}
                InputLabelProps={{
                    shrink: true,
                }}
                disabled={true}
                placeholder="0.00"
            />
        }

        {
            !isDisabled &&
            <div className='submit-container'>
                <Button variant="contained" color="primary" disabled={disabled} onClick={onSubmit}>
                    {strings.equipmentOrder.form.ok}
                </Button>
                <Button variant="contained" color="secondary" onClick={onCancel}>
                    {strings.equipmentOrder.form.cancel}
                </Button>
            </div>
        }
    </form>
  )
};

export default EquipmentOrderForm;