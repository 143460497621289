import React from 'react';
import strings from "../../../localization";
import { getError, hasError } from "../../../functions/Validation";
import { Button, TextField } from "@material-ui/core";

const TypefaceForm = ({
  onSubmit,
  onCancel,
  onChange,
  errors,
  data,
  isDisabled,
}) => (
    <form id='typeface-form'>
      <TextField
        label={strings.typeface.form.name + "*"}
        error={hasError(errors, 'name')}
        helperText={getError(errors, 'name')}
        fullWidth
        autoFocus
        name='name'
        onChange={onChange}
        margin="normal"
        disabled={isDisabled}
        defaultValue={data.name}
        value={data.name}
        inputProps={{ maxLength: 128 }}
        InputLabelProps={{
          shrink: true,
        }}
      />

      {
        !isDisabled &&
        <div className='submit-container'>
          <Button variant="contained" color="primary" onClick={onSubmit} >
            {strings.typeface.form.ok}
          </Button>
          <Button variant="contained" color="secondary" onClick={onCancel}>
            {strings.typeface.form.cancel}
          </Button>
        </div>
      }
    </form>
  );

export default TypefaceForm;