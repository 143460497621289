import { request } from "../../base/HTTP";
import HttpMethod from "../../constants/HttpMethod";

export async function getAllLetters() {
  return await request("api/letter/all");
}

export async function getLetters(data) {
  return await request('api/letter/search', data);
}

export function getLetterById(id) {
  return request("api/letter/" + id, {}, HttpMethod.GET);
}

export async function addLetter(data, cid) {
  return await request('/api/letter/' + cid, data, HttpMethod.POST);
}

export async function editLetter(data) {
  return await request("api/letter/" + data.id, data, HttpMethod.PUT);
}

export async function deleteLetter(id, uid) {
  return await request('/api/letter/' + id + '/' + uid, {}, HttpMethod.DELETE);
}

export async function getDeletedLetters(data){
  return await request('/api/letter/deleted',data)
}

export async function restoreLetter(data) {
  return await request("api/letter/restore/" + data.id, data, HttpMethod.PUT);
}