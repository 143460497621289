import React from "react";
import { bindActionCreators } from "redux";
import * as Actions from "../../actions/Actions";
import { withRouter } from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import strings from "../../localization";
import FormComponent from "../../common/FormComponent";
import { withSnackbar } from "notistack";
import ReservationForm from "../../components/forms/reservation/ReservationForm";
import {
  getCitizenByPersonalNumber,
  getCitizens,
} from "../../services/citizen/CitizenService";
import { addReservation } from "../../services/reservation/ReservationService";
import { getGraveyards } from "../../services/graveyard/GraveyardService";
import {
  addGraveSite,
  getGraveSitesWithSelectedGraveyard,
  editGraveSite
} from "../../services/graveSite/GraveSiteService";
import { getStonecutters } from "../../services/stonecutter/StonecutterService";
import Validators from "../../constants/ValidatorTypes";
import { validatePersonalNumber } from "../../functions/Validation";
import { validateTypedInGraveSite } from "../../functions/Validation";

class AddReservation extends FormComponent {
  validationList = {
    mainBookOfBuriedString: [{ type: Validators.REQUIRED }],
    dateOfRent: [{ type: Validators.REQUIRED }],
  };

  constructor(props) {
    super(props);

    this.state = {
      data: props.data ? props.data : {},
      errors: {},
      submitter: {},
      submitter2: {},
    };

    this.props.changeFullScreen(false);

    this.submit = this.submit.bind(this);
    this.keyPress = this.keyPress.bind(this);
  }

  componentDidMount() {
    this.setState({
      data: {
        ...this.state.data,
        mainBookOfBuriedString: new Date().getFullYear(),
      },
    });

    const close = document.getElementsByClassName(
      "MuiAutocomplete-clearIndicator"
    )[0];

    // Add a Click Event Listener to the button
    close.addEventListener("click", () => {
      this.setState({
        submitter: {
          personalNumber: "",
          nameSurnameSubmitter: "",
          addressSubmitter: "",
          municipalitySubmitter: "",
          telephoneSubmitter: "",
        },
      });
    });

    const close1 = document.getElementsByClassName(
      "MuiAutocomplete-clearIndicator"
    )[1];

    // Add a Click Event Listener to the button
    close1.addEventListener("click", () => {
      this.setState({
        submitter2: {
          personalNumber2: "",
          nameSurnameSubmitter2: "",
          addressSubmitter2: "",
          municipalitySubmitter2: "",
          telephoneSubmitter2: "",
        },
      });
    });
  }

  keyPress(event) {
    if (event.key == "Enter") {
      event.preventDefault();

      if (!validateTypedInGraveSite(this.state.typedInGraveSite)) {
        this.props.enqueueSnackbar(
          strings.graveSite.messages.GRAVE_SITE_INPUT_NOT_VALID,
          {
            variant: "error",
          }
        );
        return;
      }

      addGraveSite(this.transformRequestGraveSite(), this.props.cid).then(
        (response) => {
          if (!response.ok) {
            let messageKey = response.response.data.message;

            this.props.enqueueSnackbar(strings.graveSite.messages[messageKey], {
              variant: "error",
            });

            this.handleError(messageKey);

            return;
          }

          this.setState({
            data: {
              ...this.state.data,
              graveSite: response.data,
              graveSiteCode: response.data.code,
              graveSiteId: response.data.id,
            },
          });

          this.props.enqueueSnackbar(strings.addCompany.graveSiteAdded, {
            variant: "success",
          });
        }
      );
    }
  }

  keyPressBackspace(e) {
    if (e.target.value.length == 2 && e.keyCode !== 8) {
      e.target.value = e.target.value + "/";
    }

    if (e.target.value.length == 5 && e.keyCode !== 8) {
      e.target.value = e.target.value + "-";
    }

    if (e.keyCode !== 8) {
      return;
    }

    if (
      e.target.value.charAt(e.target.value.length - 2) == "-" ||
      e.target.value.charAt(e.target.value.length - 2) == "/"
    ) {
      e.target.value = e.target.value.substring(0, e.target.value.length - 1);
    } else {
      e.target.value = e.target.value.substring(0, e.target.value.length);
    }
  }

  transformRequestGraveSite() {
    return {
      userEmail: this.props.uemail,
      code: this.state.typedInGraveSite,
      belongingGraveyardId: this.state.data.graveyard.id,
    };
  }

  transformRequestEditGraveSite(graveSite) {
    return {
      ...graveSite,
      userEmail: this.props.uemail,
      companyId: this.props.cid,
      belongingGraveyardId: this.state.data.graveyard.id,
      belongingGraveyardName: this.state.data.graveyard.name,
      identificationNumber: this.state.submitter.personalNumber,
    }
  }

  submit() {
    if (!this.validate()) {
      this.props.enqueueSnackbar(strings.reservation.validationЕrror, {
        variant: "error",
      });
      return;
    }

    if (this.state.data.mainBookOfBuriedString.length != 6) {
      this.setError(
        "mainBookOfBuriedString",
        strings.mainBookOfBuried.errorLength
      );
      this.props.enqueueSnackbar(strings.error.requiredFields, {
        variant: "error",
      });
      return;
    }

    this.showDrawerLoader();

    editGraveSite(this.transformRequestEditGraveSite(this.state.data.graveSite)).then(() => {
      addReservation(this.transformRequest(this.state.data), this.props.cid).then(
        (response) => {
          if (!response.ok) {
            let messageKey = response.response.data.message;
  
            this.props.enqueueSnackbar(strings.reservation.messages[messageKey], {
              variant: "error",
            });
  
            this.handleError(messageKey);
  
            return;
          }
  
          this.props.enqueueSnackbar(strings.addCompany.reservationAdded, {
            variant: "success",
          });
          this.props.onFinish(response.data.reservation);
  
          this.hideDrawerLoader();
        }
      );
    });
  }

  transformRequest(data) {
    return {
      ...data,
      userEmail: this.props.uemail,
      submitterId: this.state.data.submitter
        ? this.state.data.submitter.id
        : null,
      submitter2Id: this.state.data.submitter2
        ? this.state.data.submitter2.id
        : null,
      graveyardId: this.state.data.graveyard
        ? this.state.data.graveyard.id
        : null,
      graveSiteId: this.state.data.graveSite
        ? this.state.data.graveSite.id
        : null,
      stonecutterId: this.state.data.stonecutter
        ? this.state.data.stonecutter.id
        : null,
    };
  }

  handleError(message) {
    switch (message) {
      case "RESERVATION_WITH_MAIN_BOOK_OF_BURIED_ALREADY_EXIST":
        this.setError(
          "mainBookOfBuriedString",
          strings.reservation.messages
            .RESERVATION_WITH_MAIN_BOOK_OF_BURIED_ALREADY_EXIST
        );
        break;
      case "RESERVATION_WITH_MAIN_BOOK_OF_BURIED_CANT_BE_NULL":
        this.setError(
          "mainBookOfBuriedString",
          strings.reservation.messages
            .RESERVATION_WITH_MAIN_BOOK_OF_BURIED_CANT_BE_NULL
        );
        break;
    }
  }

  handleChange = (event) => {
    this.setState({
      data: {
        ...this.state.data,
        [event.target.name]: event.target.value,
      },
    });
  };

  /**
   * Handles autocomplete change event
   * Triggers when user selects something from dropdown menu
   * to fill info (disabled) fields
   */
  onAutocompleteChange = (event, values) => {
    let autocompleteName = event.target.id.split("-")[0];
    this.setState({
      data: {
        ...this.state.data,
        [autocompleteName]: values,
      },
    });

    if (autocompleteName === "submitter" && values != null) {
      getCitizenByPersonalNumber(values.personalNumber).then((response) => {
        this.setState({
          submitter: {
            personalNumber: response.data.personalNumber,
            nameSurnameSubmitter: response.data.nameSurname,
            addressSubmitter: response.data.address,
            municipalitySubmitter: response.data.municipality,
            telephoneSubmitter: response.data.telephone,
          },
        });
      });
    } else if (autocompleteName === "submitter2" && values != null) {
      getCitizenByPersonalNumber(values.personalNumber).then((response) => {
        this.setState({
          submitter2: {
            personalNumber2: response.data.personalNumber,
            nameSurnameSubmitter2: response.data.nameSurname,
            addressSubmitter2: response.data.address,
            municipalitySubmitter2: response.data.municipality,
            telephoneSubmitter2: response.data.telephone,
          },
        });
      });
    } else if (autocompleteName === "graveSite" && values != null) {
      this.setState({
        data: {
          ...this.state.data,
          graveSite: values,
          expiryDateString: values.expirationDateString,
          expiryDate: values.expirationDate,
        },
      });
    }
  };

  /**
   * Handles autocomplete type input change event
   * When user typed in 2+ characters it searches for results to fill dropdown
   */
  handleTextInputChange = (e) => {
    if (e.target.name === "submitter" && e.target.value.trim().length >= 2) {
      let data = {
        searchParam: e.target.value,
        companyId: this.props.cid,
        sort: "personalNumber,ASC",
        userId: 0,
      };

      let validPersonalNumber = validatePersonalNumber(e.target.value);

      if (!validPersonalNumber) {
        this.setError("submitter", "");
        this.setState({
          flagMarginValid: false,
        });
      } else {
        this.unsetError("submitter");
        this.setState({
          flagMarginValid: true,
        });
      }

      this.setState({
        typedInPersonalNumber: e.target.value,
      });

      this.props.handler(e.target.value);

      {
        this.props.cid != undefined &&
          getCitizens(data).then((response) => {
            if (!response.ok) {
              return;
            }
            this.setState(
              {
                submitters: response.data.entities,
              },
              () => {
                let flagDifferent = true;
                for (let i = 0; i < this.state.submitters.length; i++) {
                  if (
                    this.state.typedInPersonalNumber ==
                    this.state.submitters[i].personalNumber
                  ) {
                    flagDifferent = false;
                  }
                }

                if (
                  this.state.submitters.length == 0 ||
                  flagDifferent == true
                ) {
                  this.setState({
                    flagAddNewSubmitter: true,
                  });
                } else {
                  this.setState({
                    flagAddNewSubmitter: false,
                  });
                }
              }
            );
          });
      }
    } else if (
      e.target.name === "submitter2" &&
      e.target.value.trim().length >= 2
    ) {
      let data = {
        searchParam: e.target.value,
        companyId: this.props.cid,
        sort: "personalNumber,ASC",
        userId: 0,
      };
      let validPersonalNumber = validatePersonalNumber(e.target.value);

      if (!validPersonalNumber) {
        this.setError("submitter", "");
        this.setState({
          flagMarginValid2: false,
        });
      } else {
        this.unsetError("submitter");
        this.setState({
          flagMarginValid2: true,
        });
      }
      this.props.handler(e.target.value);

      {
        this.props.cid != undefined &&
          getCitizens(data).then((response) => {
            if (!response.ok) {
              return;
            }
            this.setState(
              {
                submitters2: response.data.entities,
              },
              () => {
                let flagDifferent = true;
                for (let i = 0; i < this.state.submitters2.length; i++) {
                  if (
                    this.state.typedInPersonalNumber ==
                    this.state.submitters2[i].personalNumber
                  ) {
                    flagDifferent = false;
                  }
                }

                if (
                  this.state.submitters2.length == 0 ||
                  flagDifferent == true
                ) {
                  this.setState({
                    flagAddNewSubmitter2: true,
                  });
                } else {
                  this.setState({
                    flagAddNewSubmitter2: false,
                  });
                }
              }
            );
          });
      }
    } else if (
      e.target.name === "graveyard" &&
      e.target.value.trim().length >= 2
    ) {
      let data = {
        searchParam: e.target.value,
        companyId: this.props.cid,
        sort: "name,ASC",
        userId: 0,
      };

      {
        this.props.cid != undefined &&
          getGraveyards(data).then((response) => {
            if (!response.ok) {
              return;
            }
            this.setState({
              graveyards: response.data.entities,
            });
          });
      }

      this.setState({
        graveSites: null,
        data: {
          ...this.state.data,
          graveSite: null,
        },
      });
    } else if (
      e.target.name === "graveSite" &&
      e.target.value.trim().length >= 2
    ) {
      let data = {
        searchParam: e.target.value,
        companyId: this.props.cid,
        sort: "code,ASC",
        userId: 0,
        graveyardId: this.state.data.graveyard.id,
      };

      this.setState({
        typedInGraveSite: e.target.value,
      });

      {
        this.props.cid != undefined &&
          getGraveSitesWithSelectedGraveyard(data).then((response) => {
            if (!response.ok) {
              return;
            }
            this.setState({
              graveSites: response.data.entities,
            });
          });
      }
    } else if (
      e.target.name === "stonecutter" &&
      e.target.value.trim().length >= 2
    ) {
      let data = {
        searchParam: e.target.value,
        companyId: this.props.cid,
        sort: "name,ASC",
        userId: 0,
      };

      {
        this.props.cid != undefined &&
          getStonecutters(data).then((response) => {
            if (!response.ok) {
              return;
            }
            this.setState({
              stonecutters: response.data.entities,
            });
          });
      }
    }
  };

  render() {
    return (
      <Grid id="page" item md={12}>
        <div className="header">
          <h1>{strings.reservation.pageTitle}</h1>
        </div>

        <Paper className="paper">
          <ReservationForm
            onChange={this.changeData}
            onSubmit={this.submit}
            data={this.state.data}
            errors={this.state.errors}
            onCancel={this.props.onCancel}
            isDisabled={this.props.isDisabled}
            handleChange={this.handleChange}
            onAutocompleteChange={this.onAutocompleteChange}
            handleTextInputChange={this.handleTextInputChange}
            submitters={
              this.state.submitters == undefined ? [] : this.state.submitters
            }
            submitters2={
              this.state.submitters2 == undefined ? [] : this.state.submitters2
            }
            submitter={this.state.submitter}
            submitter2={this.state.submitter2}
            graveyards={
              this.state.graveyards == undefined ? [] : this.state.graveyards
            }
            graveSites={
              this.state.graveSites == undefined ? [] : this.state.graveSites
            }
            stonecutters={
              this.state.stonecutters == undefined
                ? []
                : this.state.stonecutters
            }
            flag={this.props.flag}
            date={new Date().getFullYear()}
            flagAddNewSubmitter={this.state.flagAddNewSubmitter}
            flagAddNewSubmitter2={this.state.flagAddNewSubmitter2}
            renderAddCitizen={this.props.flagAddCitizen}
            flagMarginValid={this.state.flagMarginValid}
            flagMarginValid2={this.state.flagMarginValid2}
            typedInGraveSite={this.state.typedInGraveSite}
            keyPress={this.keyPress}
            keyPressBackspace={this.keyPressBackspace}
          />
        </Paper>
      </Grid>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      changeFullScreen: Actions.changeFullScreen,
    },
    dispatch
  );
}

function mapStateToProps({ menuReducers, siteDataReducers }) {
  return { menu: menuReducers, siteData: siteDataReducers };
}

export default withSnackbar(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(AddReservation))
);
