import {request} from "../../base/HTTP";
import HttpMethod from "../../constants/HttpMethod";

export async function getPostalCodes(data) {
    return await request('/api/postalcode/search', data);
}

export async function addPostalCode(data, cid) {
    return await request('/api/postalcode/' + cid, data, HttpMethod.POST);
}

export function getPostalCodeById(id) {
    return  request("api/postalcode/" + id , {}, HttpMethod.GET);
}

export async function editPostalCode(data) {
    return await request("api/postalcode/" + data.id, data, HttpMethod.PUT);
}

export async function deletePostalCode(id, uid) {
    return await request('/api/postalcode/' + id + '/' + uid, {} , HttpMethod.DELETE);
}

