import { request } from "../../base/HTTP";
import HttpMethod from "../../constants/HttpMethod";

export async function getAllLanguages() {
  return await request("api/language/all");
}

export async function getLanguages(data) {
  return await request('api/language/search', data);
}

export function getLanguageById(id) {
  return request("api/language/" + id, {}, HttpMethod.GET);
}

export async function addLanguage(data, cid) {
  return await request('/api/language/' + cid, data, HttpMethod.POST);
}

export async function editLanguage(data) {
  return await request("api/language/" + data.id, data, HttpMethod.PUT);
}

export async function deleteLanguage(id, uid) {
  return await request('/api/language/' + id + '/' + uid, {}, HttpMethod.DELETE);
}

export async function getDeletedLanguages(data){
  return await request('/api/language/deleted',data)
}

export async function restoreLanguage(data) {
  return await request("api/language/restore/" + data.id, data, HttpMethod.PUT);
}
