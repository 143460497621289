import React from 'react'
import TablePage from "../../common/TablePage";
import { bindActionCreators } from "redux";
import * as Actions from "../../actions/Actions";
import { withRouter } from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import strings from "../../localization";
import { withSnackbar } from "notistack";
import { getFirms, getDeletedFirms, deleteFirm, restoreFirm } from '../../services/firm/FirmService';
import AddFirm from './AddFirm';
import EditFirm from './EditFirm';
import ViewFirm from './ViewFirm';


class FirmList extends TablePage {

  tableDescription = [
    { key: 'taxNumber', label: strings.firmList.taxNumber, sort: 'taxNumber' },
    { key: 'name', label: strings.firmList.name, sort: 'name' },
    { key: 'headquarters', label: strings.firmList.headquarters, sort: 'headquarters' },
    { key: 'dateCreated', label: strings.firmList.dateCreated, transform: 'renderColumnDate', sort: 'dateCreated' },
  ];


  constructor(props) {
    super(props);

    this.state.sortBy = "name,ASC";
    this.state.showDeleted = false;

  }

  fetchData() {
    let data = {
      searchParam: this.state.searchData.search,
      page: this.state.searchData.page - 1,
      size: this.state.searchData.perPage,
      companyId: this.state.companyId,
      sort: this.state.sortBy,
      userId: this.props.auth.user.id
    };

    {
      (this.state.companyId != undefined && !this.state.showDeleted) &&
      getFirms(data).then(response => {
        if (!response.ok) {
          return;
        }
        this.setState({
          tableData: response.data.entities,
          total: response.data.total,
        });
      });
    }

    {
      (this.state.companyId != undefined && this.state.showDeleted) &&
        getDeletedFirms(data).then(response => {
          if (!response.ok) {
            return;
          }
          this.setState({
            tableData: response.data.entities,
            total: response.data.total,
          });
        });
    }
  }

  handleDeletedChange(){
    this.setState({
      showDeleted : !this.state.showDeleted
    },() =>{ this.fetchData(); })
    
  }

  usePermissions(){
    let data = {
      userId: this.props.auth.user.id,
      category: "Фирме"
    }
    this.getPermissionsS(data);
    this.showAdd(this.props.showAdd);
    this.showRemove(this.props.remove);
    this.showEdit(this.props.showEdit);
    this.showView(this.props.showView);
    this.removedItemsShow(this.props.removedItemsShow);

  }


  componentDidMount() {
    this.usePermissions()
    this.setState({
      companyId: this.props.auth.user.company.id
    }, () => { this.fetchData(); })
  }

  componentWillReceiveProps(nextProps) {
    strings.setLanguage(nextProps.auth.user.language);
    this.tableDescription = [
      { key: 'taxNumber', label: strings.firmList.taxNumber, sort: 'taxNumber' },
      { key: 'name', label: strings.firmList.name, sort: 'name' },
      { key: 'headquarters', label: strings.firmList.headquarters, sort: 'headquarters' },
      { key: 'dateCreated', label: strings.firmList.dateCreated, transform: 'renderColumnDate', sort: 'dateCreated' },
    ];
  }

  getPageHeader() {
    return <h1>{strings.firmList.pageTitle}</h1>;
  }

  renderAddContent() {
    return <AddFirm cid={this.state.companyId} uemail={this.props.auth.user.email} onCancel={this.onCancel} onFinish={this.onFinish} />
  }

  renderEditContent() {
    return <EditFirm
      id={this.state.selectedItem.id}
      uemail={this.props.auth.user.email}
      cid={this.state.companyId}
      onCancel={this.onCancel}
      onFinish={this.onFinish}
      isDisabled={false}
    />
  }

  renderViewContent() {

    return <ViewFirm id={this.state.selectedItem.id} isDisabled={true} onCancel={this.onCancel} onFinish={this.onFinish} />
  }

  delete(item) {

    this.setState({
      lockTable: true
    });


    deleteFirm(item.id, this.props.auth.user.id).then(response => {

      if (response && !response.ok) {
        this.onFinish(null);
        return;
      }

      this.props.enqueueSnackbar(strings.companyList.firmDeleted, { variant: 'success' });

      this.onFinish(item);
      this.cancelDelete();

      this.setState({
        lockTable: false
      });
    });
  }

  transformRequest(data) {
    return {
        ...data,
        userEmail: this.props.auth.user.email,
        companyId: this.state.companyId
    }
}
  restore(item){
    
    restoreFirm(this.transformRequest(item)).then(response => {
      if (response && !response.ok) {
        this.onFinish(null);
        return;
      }
      this.setState({
        showDeleted : !this.state.showDeleted,
      },() =>{ this.fetchData(); })
    })
  }

}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    changeFullScreen: Actions.changeFullScreen
  }, dispatch);
}

function mapStateToProps({ menuReducers, authReducers, siteDataReducers }) {
  return { menu: menuReducers, auth: authReducers, siteData: siteDataReducers };
}

export default withSnackbar(withRouter(connect(mapStateToProps, mapDispatchToProps)(FirmList)));