import React from 'react';
import { Button, TextField } from "@material-ui/core";
import DatePickerControl from '../../../components/controls/DatePickerControl';
import strings from '../../../localization';
import IconButton from "@material-ui/core/IconButton";


const CardGraveSiteHeader = ({
  graveyardName,
  graveSiteCode,
  famousCitizenCode,
  graveSiteSize,
  graveSiteLength,
  graveSiteWidth,
  graveSiteDepth,
  contractExpiryDate
}) => (


  <div className="card-grave-site-display-flex">
    <div className="card-grave-site-header-width">
      <TextField
        label={strings.mainBookOfBuriedList.graveyard}
        fullWidth
        name='graveyardName'
        margin="normal"
        disabled={true}
        value={graveyardName}
        InputLabelProps={{
          shrink: true,
        }}
      />
      <div className="card-grave-site-display-flex">
        <TextField
          label={strings.mainBookOfBuriedList.graveSite}
          fullWidth
          name='graveSiteCode'
          margin="normal"
          disabled={true}
          value={graveSiteCode}
          InputLabelProps={{
            shrink: true,
          }}
        />
              </div>

      <DatePickerControl
        label={strings.cardGraveSiteList.expiryDateContract}
        placeholder={""}
        name='contractExpiryDate'
        date={contractExpiryDate ? new Date(contractExpiryDate) : contractExpiryDate}
        isClearable={true}
        dateFormat='dd/MM/yyyy'
        disabled={true}
      />
    </div>
  </div>
);

export default CardGraveSiteHeader;