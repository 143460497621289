import FormComponent from "../../common/FormComponent";
import React from "react";
import { bindActionCreators } from "redux";
import * as Actions from "../../actions/Actions";
import { withRouter } from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import Grid from "@material-ui/core/Grid";
import { Paper } from "@material-ui/core";
import strings from "../../localization";
import { withSnackbar } from "notistack";
import { getCitizenById } from "../../services/citizen/CitizenService";
import CitizenForm from "../../components/forms/citizen/CitizenForm";
import { getPostalCodeImprovedById } from "../../services/postalCodeImproved/PostalCodeImprovedService";
import { getStateById } from "../../services/state/StateService";

class ViewCitizen extends FormComponent {

  constructor(props) {
    super(props);

    this.state = {
      data: props.data ? props.data : {},
      errors: {}
    };

    this.props.changeFullScreen(false);
  }

  componentDidMount() {


    getCitizenById(this.props.id).then(response => {
      this.setState({
        data: response.data,
        checked: response.data.deceased
      }, () => {
        getStateById(this.state.data.stateCode.trim()).then((response) => {
          this.setState({
            data: {
              ...this.state.data,
              state: response.data,
            },
          });
          getPostalCodeImprovedById(this.state.data.postalCodeImprovedId).then((response) => {
            this.setState({
              data: {
                ...this.state.data,
                postalCodeImproved: response.data,
              },
            });
          });
        });
        // getPostalCodeImprovedById(this.state.data.postalCodeImprovedId).then(response =>{
        //   this.setState({
        //     data:{
        //     ...this.state.data,
        //     postalCodeImproved: response.data 
        //     }
        //   })
        // })
      });
    });
  }

  handleChange = event => {
    this.setState({ checked: event.target.checked })
  }

  render() {

    return (
      <Grid id="page" item md={12}>
        <div className="header">
          <h1>{strings.citizen.viewPageTitle}</h1>
        </div>

        <Paper className="paper">
          {this.state.checked != undefined &&
            <CitizenForm
              onChange={this.changeData}
              data={this.state.data}
              errors={this.state.errors}
              onCancel={this.props.onCancel}
              isDisabled={this.props.isDisabled}
              checked={this.state.checked}
              disabledCity={true}
              disabledNumber={true}
              disabledStreet={true}
              flag={"view"}
            />
          }
        </Paper>

      </Grid>
    );
  }
}

function mapDispatchToProps(dispatch) {

  return bindActionCreators(
    {
      changeFullScreen: Actions.changeFullScreen
    },
    dispatch
  );
}

function mapStateToProps({ menuReducers, siteDataReducers }) {

  return { menu: menuReducers, siteData: siteDataReducers };
}

export default withSnackbar(
  withRouter(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(ViewCitizen)
  )
);
