import React from 'react';
import strings from '../../../localization';
import { Button, TextField } from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { getError, hasError } from '../../../functions/Validation';

const PostalCodeImprovedForm = ({
    onSubmit,
    onCancel,
    onChange,
    errors,
    data,
    isDisabled,
    flag,
    states,
    onAutocompleteChange,
    handleTextInputChange,


}) => {


    return (
        <form id='user-form'>

            {((data.state && flag == "edit") || (flag == "add") || (data.state && flag == "view")) &&
                <Autocomplete
                    id={"state"}
                    name={"state"}
                    options={states}
                    getOptionLabel={option => option.name}
                    onChange={onAutocompleteChange}
                    renderInput={params => (
                        <TextField
                            {...params}
                            variant="standard"
                            label={strings.postalCodeImproved.form.state + "*"}
                            error={hasError(errors, 'state')}
                            helperText={getError(errors, 'state')}
                            margin="normal"
                            fullWidth
                            name={"state"}
                            onChange={handleTextInputChange}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    )}
                    disabled={isDisabled}
                    value={data.state}
                />
            }
            {((!data.state && flag == "edit") || (!data.state && flag == "view")) &&
                <Autocomplete
                    id={"state"}
                    name={"state"}
                    options={states}
                    getOptionLabel={option => option.name}
                    onChange={onAutocompleteChange}
                    renderInput={params => (
                        <TextField
                            {...params}
                            variant="standard"
                            label={strings.postalCodeImproved.form.state + "*"}
                            error={hasError(errors, 'state')}
                            helperText={getError(errors, 'state')}
                            margin="normal"
                            fullWidth
                            name={"state"}
                            onChange={handleTextInputChange}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    )}
                    disabled={isDisabled}
                    value={data.state}
                />
            }



            <TextField
                label={strings.postalCodeImproved.form.city + "*"}
                error={hasError(errors, 'city')}
                helperText={getError(errors, 'city')}
                fullWidth
                autoFocus
                name='city'
                onChange={onChange}
                margin='normal'
                value={data.city}
                disabled={isDisabled}
                InputLabelProps={{
                    shrink: true,
                }}

            />

            <TextField
                label={strings.postalCodeImproved.form.zip + "*"}
                error={hasError(errors, 'zip')}
                helperText={getError(errors, 'zip')}
                fullWidth
                autoFocus
                name='zip'
                onChange={onChange}
                margin='normal'
                value={data.zip}
                disabled={isDisabled}
                InputLabelProps={{
                    shrink: true,
                }}

            />

            {
                !isDisabled &&
                <div className='submit-container'>
                    <Button variant="contained" color="primary" onClick={onSubmit}>
                        {strings.postalCode.form.ok}
                    </Button>
                    <Button variant="contained" color="secondary" onClick={onCancel}>
                        {strings.postalCode.form.cancel}
                    </Button>
                </div>
            }
        </form>
    )
};
export default PostalCodeImprovedForm;