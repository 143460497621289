import { TextField, Button } from "@material-ui/core";
import strings from "../../../localization";
import { hasError, getError } from "../../../functions/Validation";
import React from 'react';

const DeceasedBroughtForm = ({
  onSubmit,
  onCancel,
  onChange,
  errors,
  data,
  isDisabled,
}) => (
    <form id="deceasedBrought-form">
      <TextField
        label={strings.deceasedBrought.form.name + "*"}
        error={hasError(errors, 'name')}
        helperText={getError(errors, 'name')}
        fullWidth
        autoFocus
        name='name'
        onChange={onChange}
        margin="normal"
        disabled={isDisabled}
        defaultValue={data.name}
        value={data.name}
        inputProps={{ maxLength: 128 }}
        InputLabelProps={{
          shrink: true
        }}
      />

      {
        !isDisabled &&
        <div className='submit-container'>
          <Button variant="contained" color="primary" onClick={onSubmit}>
            {strings.deceasedBrought.form.ok}
          </Button>
          <Button variant="contained" color="secondary" onClick={onCancel}>
            {strings.deceasedBrought.form.cancel}
          </Button>
        </div>
      }
    </form>
  );

export default DeceasedBroughtForm;