import { request } from "../../base/HTTP";
import HttpMethod from "../../constants/HttpMethod";

export async function resetPasswordRequest(data) {
  return await request("/api/user/password/reset", data, HttpMethod.POST);
}

export async function resetPassword(resetToken, password) {
  return await request(
    "/api/user/resetPassword/" + resetToken, { password }, HttpMethod.PUT);
}

export async function recoverPassword(recoveryPasswordToken, password) {
  return await request(
    "/api/user/recoveryPassword/" + recoveryPasswordToken,
    { password },
    HttpMethod.PUT
  );
}

export async function createPassword(registrationToken, password) {
  return await request("/api/user/createPassword/" + registrationToken, { password }, HttpMethod.PUT);
}

export async function editUser(data) {
    return await request("api/user/" + data.id, data, HttpMethod.PUT);
}

export function getCurrentUser() {
    return  request("api/user/current", {}, HttpMethod.GET);
}

export async function changePassword(email, password) {
  return await request(
    "/api/user/changePassword/", { email , password }, HttpMethod.POST);
}

export async function changeLanguage(email, language) {
  return await request("/api/user/changeLanguage/", { email , language }, HttpMethod.POST);
}

export async function getUserBySearchParam(data){
  return await request('/api/user/search', data)
}
