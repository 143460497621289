import {request} from "../../base/HTTP";
import HttpMethod from "../../constants/HttpMethod";

export async function getStates(data) {
    return await request('/api/state/search', data);
}

export async function addState(data, cid) {
    return await request('/api/state/' + cid, data, HttpMethod.POST);
}

export function getStateById(id) {
    return  request("api/state/" + id , {}, HttpMethod.GET);
}

export async function editState(data) {
    return await request("api/state/" + data.id, data, HttpMethod.PUT);
}

export async function deleteState(id, uid) {
    return await request('/api/state/' + id + '/' + uid, {} , HttpMethod.DELETE);
}

export async function getDeletedStates(data){
    return await request('api/state/deleted', data)
}

export async function restoreStates(data){
    return await request("api/state/restore/" + data.id, data, HttpMethod.PUT)
}