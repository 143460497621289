import {request} from "../../base/HTTP";
import HttpMethod from "../../constants/HttpMethod";

export async function getRoles(data) {
    return await request('/api/role/search', data);
}

export async function addRole(data, cid) {
    return await request('/api/role/' + cid, data, HttpMethod.POST);
}

export async function getRoleByName(data){
    return await request('/api/role/name', data)
}

export async function deleteRole(id, uid){
    return await request('api/role/' + id + '/' + uid, {}, HttpMethod.DELETE);
}