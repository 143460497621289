import FormComponent from "../../common/FormComponent";
import React from "react";
import { bindActionCreators } from "redux";
import * as Actions from "../../actions/Actions";
import { withRouter } from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import Grid from "@material-ui/core/Grid";
import { Paper } from "@material-ui/core";
import strings from "../../localization";
import Validators from "../../constants/ValidatorTypes";
import { withSnackbar } from "notistack";
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import { getAllTransporters } from '../../services/transporter/TransporterService';
import { getPostalCodesImproved } from '../../services/postalCodeImproved/PostalCodeImprovedService';
import { getStates } from '../../services/state/StateService';
import { getOccupations } from '../../services/occupation/OccupationService';
import { getCauseOfDeaths } from '../../services/causeOfDeath/CauseOfDeathService';
import { getDoctors } from '../../services/doctor/DoctorService';
import { getFamousCitizens } from '../../services/famousCitizen/FamousCitizenService';
import { getCitizenByPersonalNumber, getCitizens } from '../../services/citizen/CitizenService';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import DeceasedMainBookOfCrematedForm from '../../components/forms/mainBookOfCremated/DeceasedMainBookOfCrematedForm';
import DeathReportMainBookOfCrematedForm from '../../components/forms/mainBookOfCremated/DeathReportMainBookOfCrematedForm';
import CremationMainBookOfCremationForm from '../../components/forms/mainBookOfCremated/CremationMainBookOfCremationForm';
import { getGraveyards } from "../../services/graveyard/GraveyardService";
import { addGraveSite, getGraveNotTakenSites, getGraveSitesWithSelectedGraveyard } from "../../services/graveSite/GraveSiteService";
import { getFirms, getFirmById } from "../../services/firm/FirmService";
import { getMainBookOfCrematedById, editMainBookOfCremated, getAutocompleteObjectsById } from "../../services/mainBookOfCremated/MainBookOfCrematedService";
import { getUrns } from "../../services/urn/UrnService";
import { validatePersonalNumber, getDateFromIdentificationNumber, validate, validateTypedInGraveSite } from "../../functions/Validation";
import { stringToDate, stringToDate2 } from "../../util/DateUtil";
import moment from "moment";


function TabContainer(props) {
  return (
    <Typography className="tabContainer" component="div">
      {props.children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

class EditMainBookOfCremated extends FormComponent {

  validationList = {
    deceasedNameSurname: [{ type: Validators.REQUIRED }],
    mainBookOfCremated: [{ type: Validators.REQUIRED }, { type: Validators.IS_NUMBER }],
    //identificationNumber: [{ type: Validators.REQUIRED }],
    height: [{ type: Validators.IS_NUMBER }],
    weight: [{ type: Validators.IS_NUMBER }],
  };

  constructor(props) {
    super(props);

    this.state = {
      data: props.data ? props.data : {},
      errors: {},
      value: 0,
      costSameAsSubmitter: false,
      userSameAsCost: false,
      userSameAsSubmitter: false,
      submitter: {},
      cost: {},
      firmCost: {},
      userGraveSite: {},
      costInfo: {},
      userInfo: {},
      firmInfo: {},
      dateFlag: "invalidIDNumber",
      dateOfBirthWhenValidIDNumber: [],
    };

    this.state.sortBy = "name,ASC";
    this.props.changeFullScreen(false);
    this.submit = this.submit.bind(this);
    this.identificationSubmitterEqualsIdentificationCosts = this.identificationSubmitterEqualsIdentificationCosts.bind(this);
    this.identificationCostsEqualsIdentificationUser = this.identificationCostsEqualsIdentificationUser.bind(this);
    this.identificationSubmitterEqualsIdentificationUser = this.identificationSubmitterEqualsIdentificationUser.bind(this);

    this.keyPress = this.keyPress.bind(this);
  }

  componentDidMount() {

    getMainBookOfCrematedById(this.props.id).then(response => {
      this.setState({
        data: response.data,
      }, () => {
        this.setState({
          data: {
            ...this.state.data,
            dateOfBirthString: response.data.dateOfBirthString,
            dateOfBirth: (response.data.dateOfBirthString && response.data.dateOfBirth == null) ? (stringToDate2(response.data.dateOfBirthString)._d == 'Invalid Date' ? (stringToDate(response.data.dateOfBirthString)._d == 'Invalid Date' ? null : stringToDate(response.data.dateOfBirthString)._d) : stringToDate2(response.data.dateOfBirthString)._d) : response.data.dateOfBirth,

            dateTimeOfDeathString: response.data.dateTimeOfDeathString,
            dateTimeOfDeath: (response.data.dateTimeOfDeathString && response.data.dateTimeOfDeath == null) ? (stringToDate2(response.data.dateTimeOfDeathString)._d == 'Invalid Date' ? (stringToDate(response.data.dateTimeOfDeathString)._d == 'Invalid Date' ? null : stringToDate(response.data.dateTimeOfDeathString)._d) : stringToDate2(response.data.dateTimeOfDeathString)._d) : response.data.dateTimeOfDeath,

            dateTimeOfTakeInString: response.data.dateTimeOfTakeInString,
            dateTimeOfTakeIn: (response.data.dateTimeOfTakeInString && response.data.dateTimeOfTakeIn == null) ? (stringToDate2(response.data.dateTimeOfTakeInString)._d == 'Invalid Date' ? (stringToDate(response.data.dateTimeOfTakeInString)._d == 'Invalid Date' ? null : stringToDate(response.data.dateTimeOfTakeInString)._d) : stringToDate2(response.data.dateTimeOfTakeInString)._d) : response.data.dateTimeOfTakeIn,

            dateTimeSayGoodbyeString: response.data.dateTimeSayGoodbyeString,
            dateTimeSayGoodbye: (response.data.dateTimeSayGoodbyeString && response.data.dateTimeSayGoodbye == null) ? (stringToDate2(response.data.dateTimeSayGoodbyeString)._d == 'Invalid Date' ? (stringToDate(response.data.dateTimeSayGoodbyeString)._d == 'Invalid Date' ? null : stringToDate(response.data.dateTimeSayGoodbyeString)._d) : stringToDate2(response.data.dateTimeSayGoodbyeString)._d) : response.data.dateTimeSayGoodbye,

            dateTimeOfCremationString: response.data.dateTimeOfCremationString,
            dateTimeOfCremation: (response.data.dateTimeOfCremationString && response.data.dateTimeOfCremation == null) ? (stringToDate2(response.data.dateTimeOfCremationString)._d == 'Invalid Date' ? (stringToDate(response.data.dateTimeOfCremationString)._d == 'Invalid Date' ? null : stringToDate(response.data.dateTimeOfCremationString)._d) : stringToDate2(response.data.dateTimeOfCremationString)._d) : response.data.dateTimeOfCremation,

            dateTimeUrnTakeoverString: response.data.dateTimeUrnTakeoverString,
            dateTimeUrnTakeover: (response.data.dateTimeUrnTakeoverString && response.data.dateTimeUrnTakeover == null) ? (stringToDate2(response.data.dateTimeUrnTakeoverString)._d == 'Invalid Date' ? (stringToDate(response.data.dateTimeUrnTakeoverString)._d == 'Invalid Date' ? null : stringToDate(response.data.dateTimeUrnTakeoverString)._d) : stringToDate2(response.data.dateTimeUrnTakeoverString)._d) : response.data.dateTimeUrnTakeover,

            //expiryDateString: response.data.expiryDateString,
            //expiryDate: (response.data.expiryDateString && response.data.expiryDate == null) ? (stringToDate2(response.data.expiryDateString)._d == 'Invalid Date' ? (stringToDate(response.data.expiryDateString)._d == 'Invalid Date' ? null : stringToDate(response.data.expiryDateString)._d) : stringToDate2(response.data.expiryDateString)._d) : response.data.expiryDate,
            expiryDate: (stringToDate(response.data.expiryDateString) > moment(response.data.expiryDate, 'YYYY-MM-DD')) ? stringToDate(response.data.expiryDateString) : response.data.expiryDate,

          }
        });
        this.checkIfValidatePersonalNumber(this.state.data.identificationNumber);
        getAutocompleteObjectsById(this.state.data).then(response => {
          this.setState({
            data: {
              ...this.state.data,
              transporter: response.data.transporter,
              causeOfDeath: response.data.causeOfDeath,
              submitter: response.data.submitter,
              cost: response.data.cost,
              userGraveSite: response.data.userGraveSite,
              firmCost: response.data.firmCost,
              firmPays: this.state.data.firmPays.toString(),
              urn: response.data.urn,
              municipality: response.data.municipality,
              state: response.data.state,
              occupation: response.data.occupation,
              municipalityOfBirth: response.data.municipalityOfBirth,
              doctor: response.data.doctor,
              famousCitizen: response.data.famousCitizen,
              graveyard: response.data.graveyard,
              graveSite: response.data.graveSite,
            },
            submitter: {
              nameSurnameSubmitter: response.data.submitter ? response.data.submitter.name + " " + response.data.submitter.surname : null,
              addressSubmitter: response.data.submitter ? response.data.submitter.street + " " + response.data.submitter.streetNumber : "",
              municipalitySubmitter: response.data.submitter ? response.data.submitter.postalCodeImprovedZip : "",
              telephoneSubmitter: response.data.submitter ? response.data.submitter.telephone : "",
            },
            costInfo: {
              nameSurnameCosts: response.data.cost ? response.data.cost.name + " " + response.data.cost.surname : "",
              addressCosts: response.data.cost ? response.data.cost.street + " " + response.data.cost.streetNumber : "",
              municipalityCosts: response.data.cost ? response.data.cost.postalCodeImprovedZip : "",
              telephoneCosts: response.data.cost ? response.data.cost.telephone : "",
            },
            userInfo: {
              nameSurnameUser: response.data.userGraveSite ? response.data.userGraveSite.name + " " + response.data.userGraveSite.surname : "",
              addressUser: response.data.userGraveSite ? response.data.userGraveSite.street + " " + response.data.userGraveSite.streetNumber : "",
              municipalityUser: response.data.userGraveSite ? response.data.userGraveSite.postalCodeImprovedZip : "",
              telephoneUser: response.data.userGraveSite ? response.data.userGraveSite.telephone : "",
            },
            firmInfo: {
              taxNumberCost: response.data.firmCost ? response.data.firmCost.taxNumber : "",
            },
          })
        });
      });
    });

    getAllTransporters().then(response => {
      this.setState({
        transporters: response.data.entities,
      });
    });
  }

  keyPress(event) {
    if (event.key == "Enter") {
      event.preventDefault();

      if (!validateTypedInGraveSite(this.state.typedInGraveSite)) {
        this.props.enqueueSnackbar(strings.graveSite.messages.GRAVE_SITE_INPUT_NOT_VALID, {
          variant: "error"
        });
        return;
      }
      addGraveSite(this.transformRequestGraveSite(), this.props.cid).then(response => {

        if (!response.ok) {

          let messageKey = response.response.data.message;

          this.props.enqueueSnackbar(strings.graveSite.messages[messageKey], {
            variant: "error"
          });

          this.handleError(messageKey);

          return;
        }

        this.setState({
          data: {
            ...this.state.data,
            graveSite: response.data,
            graveSiteCode: response.data.code,
            graveSiteId: response.data.id
          }
        });

        this.props.enqueueSnackbar(strings.addCompany.graveSiteAdded, { variant: 'success' });

      });
    }
  }

  keyPressBackspace(e) {
    if (e.target.value.length == 2 && e.keyCode !== 8) {
      e.target.value = e.target.value + '/';
    }

    if (e.target.value.length == 5 && e.keyCode !== 8) {
      e.target.value = e.target.value + '-';
    }

    if (e.keyCode !== 8) {
      return;
    }

    if (e.target.value.charAt(e.target.value.length - 2) == "-" || e.target.value.charAt(e.target.value.length - 2) == "/") {
      e.target.value = e.target.value.substring(0, e.target.value.length - 1);
    }
    else {
      e.target.value = e.target.value.substring(0, e.target.value.length);
    }
  }

  transformRequestGraveSite() {
    return {
      userEmail: this.props.uemail,
      code: this.state.typedInGraveSite,
      belongingGraveyardId: this.state.data.graveyard.id
    }
  }

  
  checkIfValidatePersonalNumber(identificationNumber) {
    let dateOfBirth = "";
    let dateOfBirthArray = [];

    if (validatePersonalNumber(identificationNumber)) {
      dateOfBirth = getDateFromIdentificationNumber(identificationNumber);
      dateOfBirthArray = dateOfBirth.split('/');
      this.setState({
        dateFlag: "validIDNumber",
        dateOfBirthWhenValidIDNumber: dateOfBirthArray,
      });
    }
    else {
      this.setState({
        dateFlag: "invalidIDNumber"
      });
    }
  }

  onChangePersonalNumberField = (event) => {
    let validPersonalNumber = validatePersonalNumber(event.target.value)
    let dateOfBirth = "";
    let dateOfBirthArray = [];

    if (!validPersonalNumber) {
      this.setError("identificationNumber", strings.mainBookOfCremated.messages.MAIN_BOOK_OF_CREMATED_IDENTIFICATION_NUMBER_NOT_VALID);
      this.setState({
        dateFlag: "invalidIDNumber"
      });
    }
    else {
      this.unsetError("identificationNumber");
      dateOfBirth = getDateFromIdentificationNumber(event.target.value);
      dateOfBirthArray = dateOfBirth.split('/');
      this.setState({
        dateOfBirthWhenValidIDNumber: dateOfBirthArray,
        dateFlag: "validIDNumber"
      });
    }
    this.changeData(event);
  }

  submit() {

    if (!this.validate()) {
      this.props.enqueueSnackbar(strings.error.requiredFields, { variant: 'error' });
      return;
    }

    if (this.state.data.mainBookOfCremated.length != 9) {
      this.setError("mainBookOfCremated", strings.mainBookOfCremated.errorLengthForEdit);
      this.props.enqueueSnackbar(strings.error.requiredFields, { variant: 'error' });
      return;
    }

    this.showDrawerLoader();

    editMainBookOfCremated(this.transformRequest(this.state.data)).then(response => {
      if (!response.ok) {
        let messageKey = response.response.data.message;

        this.props.enqueueSnackbar(strings.mainBookOfCremated.messages[messageKey], {
          variant: "error"
        });

        this.handleError(messageKey);

        return;
      }

      this.props.enqueueSnackbar(strings.addCompany.mainBookOfCrematedEdited, { variant: 'success' });
      this.props.onFinish(response.data.mainBookOfCremated);

      this.hideDrawerLoader();
    });
  }

  transformRequest(data) {
    delete data.submitterName;
    delete data.submitterSurname;
    delete data.userGraveSiteName;
    delete data.userGraveSiteSurname;

    return {
      ...data,
      identificationNumber: this.state.data.identificationNumber ? this.state.data.identificationNumber : '',
      userEmail: this.props.uemail,
      companyId: this.props.cid,
      transporterId: this.state.data.transporter ? this.state.data.transporter.id : null,
      causeOfDeathId: this.state.data.causeOfDeath ? this.state.data.causeOfDeath.id : null,
      submitterId: this.state.data.submitter ? this.state.data.submitter.id : null,
      costId: this.state.data.cost ? this.state.data.cost.id : null,
      userGraveSiteId: this.state.data.userGraveSite ? this.state.data.userGraveSite.id : null,
      firmCostId: this.state.data.firmCost ? this.state.data.firmCost.id : null,
      municipalityId: this.state.data.municipality ? this.state.data.municipality.id : null,
      stateId: this.state.data.state ? this.state.data.state.id : null,
      occupationId: this.state.data.occupation ? this.state.data.occupation.id : null,
      municipalityOfBirthId: this.state.data.municipalityOfBirth ? this.state.data.municipalityOfBirth.id : null,
      doctorId: this.state.data.doctor ? this.state.data.doctor.id : null,
      famousCitizenId: this.state.data.famousCitizen ? this.state.data.famousCitizen.id : null,
      graveyardId: this.state.data.graveyard ? this.state.data.graveyard.id : null,
      graveSiteId: this.state.data.graveSite ? this.state.data.graveSite.id : null,
      urnId: this.state.data.urn ? this.state.data.urn.id : null
    }
  }

  identificationSubmitterEqualsIdentificationCosts() {
    if (!this.state.costSameAsSubmitter) {
      getCitizenByPersonalNumber(this.state.data.submitter.personalNumber).then(response => {
        this.setState({
          costInfo: {
            nameSurnameCosts: response.data.nameSurname,
            addressCosts: response.data.address,
            municipalityCosts: response.data.municipality,
            telephoneCosts: response.data.telephone ? response.data.telephone : ""
          },
          data: {
            ...this.state.data,
            cost: response.data
          },
          costSameAsSubmitter: !this.state.costSameAsSubmitter
        });
      });
    } else {
      this.setState({
        cost: null,
        data: {
          ...this.state.data,
          cost: null,
        },
        costInfo: {
          nameSurnameCosts: "",
          addressCosts: "",
          municipalityCosts: "",
          telephoneCosts: ""
        },
        costSameAsSubmitter: !this.state.costSameAsSubmitter
      });
    }

  }

  identificationSubmitterEqualsIdentificationUser() {
    if (!this.state.userSameAsSubmitter) {
      getCitizenByPersonalNumber(this.state.data.submitter.personalNumber).then(response => {
        this.setState({
          userInfo: {
            nameSurnameUser: response.data.nameSurname,
            addressUser: response.data.address,
            municipalityUser: response.data.municipality,
            telephoneUser: response.data.telephone ? response.data.telephone : ""
          },
          data: {
            ...this.state.data,
            userGraveSite: response.data,
          },
          userSameAsSubmitter: !this.state.userSameAsSubmitter
        });
      });
    } else {
      this.setState({
        userGraveSite: null,
        userInfo: {
          nameSurnameUser: "",
          addressUser: "",
          municipalityUser: "",
          telephoneUser: "",
        },
        data: {
          ...this.state.data,
          userGraveSite: null,
        },
        userSameAsSubmitter: !this.state.userSameAsSubmitter
      });
    }
  }

  identificationCostsEqualsIdentificationUser() {
    if (!this.state.userSameAsCost) {
      getCitizenByPersonalNumber(this.state.data.cost.personalNumber).then(response => {
        this.setState({
          userInfo: {
            nameSurnameUser: response.data.nameSurname,
            addressUser: response.data.address,
            municipalityUser: response.data.municipality,
            telephoneUser: response.data.telephone ? response.data.telephone : "",
          },
          data: {
            ...this.state.data,
            userGraveSite: response.data,
          },
          userSameAsCost: !this.state.userSameAsCost
        });
      });
    } else {
      this.setState({
        userGraveSite: null,
        userInfo: {
          nameSurnameUser: "",
          addressUser: "",
          municipalityUser: "",
          telephoneUser: "",
        },
        data: {
          ...this.state.data,
          userGraveSite: null,
        },
        userSameAsCost: !this.state.userSameAsCost
      });
    }
  }

  /* Handlers */

  handleError(message) {
    switch (message) {
      case "MAIN_BOOK_OF_CREMATED_WITH_IDENTIFICATION_NUMBER_ALREADY_EXIST":
        this.setError("identificationNumber", strings.mainBookOfCremated.messages.MAIN_BOOK_OF_CREMATED_WITH_IDENTIFICATION_NUMBER_ALREADY_EXIST);
        break;
      case "MAIN_BOOK_OF_CREMATED_WITH_NAME_SURNAME_CANT_BE_NULL":
        this.setError("deceasedNameSurname", strings.mainBookOfCremated.messages.MAIN_BOOK_OF_CREMATED_WITH_NAME_SURNAME_CANT_BE_NULL);
        break;
      case "MAIN_BOOK_OF_CREMATED_EXIST":
        this.setError("mainBookOfCremated", strings.mainBookOfCremated.messages.MAIN_BOOK_OF_CREMATED_EXIST);
        break;
      default:

    }
  }

  handleChangeTab = (event, value) => {
    this.setState({
      value: value
    });
  };

  handleChange = (event) => {
    this.setState({
      data: {
        ...this.state.data, [event.target.name]: event.target.value
      }
    })
  };

  /** 
   * Handles autocomplete change event
   * Triggers when user selects something from dropdown menu
   * to fill info (disabled) fields 
   */
  onAutocompleteChange = (event, values) => {
    let autocompleteName = event.target.id.split("-")[0];
    this.setState({
      data: {
        ...this.state.data, [autocompleteName]: values
      }
    });

    if (autocompleteName === "submitter" && values != null) {
      getCitizenByPersonalNumber(values.personalNumber).then(response => {
        this.setState({
          submitter: {
            personalNumber: response.data.personalNumber,
            nameSurnameSubmitter: response.data.nameSurname,
            addressSubmitter: response.data.address,
            municipalitySubmitter: response.data.municipality,
            telephoneSubmitter: response.data.telephone
          }
        });
      });
    } else if (autocompleteName === "cost" && values != null) {
      getCitizenByPersonalNumber(values.personalNumber).then(response => {
        this.setState({
          costInfo: {
            personalNumber: response.data.personalNumber,
            nameSurnameCosts: response.data.nameSurname,
            addressCosts: response.data.address,
            municipalityCosts: response.data.municipality,
            telephoneCosts: response.data.telephone
          }
        });
      });
    } else if (autocompleteName === "userGraveSite" && values != null) {
      getCitizenByPersonalNumber(values.personalNumber).then(response => {
        this.setState({
          userInfo: {
            personalNumber: response.data.personalNumber,
            nameSurnameUser: response.data.nameSurname,
            addressUser: response.data.address,
            municipalityUser: response.data.municipality,
            telephoneUser: response.data.telephone
          }
        });
      });
    } else if (autocompleteName === "firmCost" && values != null) {
      getFirmById(values.id).then(response => {
        this.setState({
          firmInfo: {
            taxNumberCost: response.data.taxNumber
          }
        });
      });
    }
  }

  /** 
   * Handles autocomplete type input change event
   * When user typed in 2+ characters it searches for results to fill dropdown 
   */
  handleTextInputChange = (e) => {
    if (e.target.name === "municipality" && e.target.value.trim().length >= 2) {
      let data = {
        searchParam: e.target.value,
        companyId: this.props.cid,
        sort: 'city',
        userId: 0
      };

      {
        this.props.cid != undefined &&
        getPostalCodesImproved(data).then(response => {
            if (!response.ok) {
              return;
            }
            this.setState({
              settlements: response.data.entities,
            });
          });
      }
    } else if (e.target.name === "state" && e.target.value.trim().length >= 2) {
      let data = {
        searchParam: e.target.value,
        companyId: this.props.cid,
        sort: this.state.sortBy,
        userId: 0
      };

      {
        this.props.cid != undefined &&
          getStates(data).then(response => {
            if (!response.ok) {
              return;
            }
            this.setState({
              states: response.data.entities,
            });
          });
      }
    } else if (e.target.name === "occupation" && e.target.value.trim().length >= 2) {
      let data = {
        searchParam: e.target.value,
        companyId: this.props.cid,
        sort: this.state.sortBy,
        userId: 0
      };

      {
        this.props.cid != undefined &&
          getOccupations(data).then(response => {
            if (!response.ok) {
              return;
            }
            this.setState({
              occupations: response.data.entities,
            });
          });
      }
    } else if (e.target.name === "causeOfDeath" && e.target.value.trim().length >= 2) {
      let data = {
        searchParam: e.target.value,
        companyId: this.props.cid,
        sort: this.state.sortBy,
        userId: 0
      };

      {
        this.props.cid != undefined &&
          getCauseOfDeaths(data).then(response => {
            if (!response.ok) {
              return;
            }
            this.setState({
              causesOfDeath: response.data.entities,
            });
          });
      }
    } else if (e.target.name === "doctor" && e.target.value.trim().length >= 2) {
      let data = {
        searchParam: e.target.value,
        companyId: this.props.cid,
        sort: this.state.sortBy,
        userId: 0
      };

      {
        this.props.cid != undefined &&
          getDoctors(data).then(response => {
            if (!response.ok) {
              return;
            }
            this.setState({
              doctors: response.data.entities,
            });
          });
      }
    } else if (e.target.name === "famousCitizen" && e.target.value.trim().length >= 2) {
      let data = {
        searchParam: e.target.value,
        companyId: this.props.cid,
        sort: "description,ASC",
        userId: 0
      };

      {
        this.props.cid != undefined &&
          getFamousCitizens(data).then(response => {
            if (!response.ok) {
              return;
            }
            this.setState({
              famousCitizens: response.data.entities,
            });
          });
      }
    } else if (e.target.name === "submitter" && e.target.value.trim().length >= 2) {
      let data = {
        searchParam: e.target.value,
        companyId: this.props.cid,
        sort: "surname,ASC",
        userId: 0
      };

      let validPersonalNumber = validatePersonalNumber(e.target.value);

      if (!validPersonalNumber) {
        this.setError("submitter", "");
        this.setState({
          flagMarginValid: false,
        });
      } else {
        this.unsetError("submitter");
        this.setState({
          flagMarginValid: true,
        });
      }

      this.setState({
        typedInPersonalNumber: e.target.value,
      });

      this.props.handler(e.target.value);

      {
        this.props.cid != undefined &&
          getCitizens(data).then(response => {
            if (!response.ok) {
              return;
            }
            this.setState({
              submitters: response.data.entities,
            }, () => {
              let flagDifferent = true;
              for (let i = 0; i < this.state.submitters.length; i++) {
                if (
                  this.state.typedInPersonalNumber ==
                  this.state.submitters[i].personalNumber
                ) {
                  flagDifferent = false;
                }
              }

              if (
                this.state.submitters.length == 0 ||
                flagDifferent == true
              ) {
                this.setState({
                  flagAddNewSubmitter: true,
                });
              } else {
                this.setState({
                  flagAddNewSubmitter: false,
                });
              }
            });
          });
      }
    } else if (e.target.name === "cost" && e.target.value.trim().length >= 2) {
      let data = {
        searchParam: e.target.value,
        companyId: this.props.cid,
        sort: "surname,ASC",
        userId: 0
      };

      let validPersonalNumber = validatePersonalNumber(e.target.value);

      if (!validPersonalNumber) {
        this.setError("cost", "");
        this.setState({
          flagMarginValid2: false,
        });
      } else {
        this.unsetError("cost");
        this.setState({
          flagMarginValid2: true,
        });
      }

      this.setState({
        typedInPersonalNumber: e.target.value,
      });

      this.props.handler(e.target.value);

      {
        this.props.cid != undefined &&
          getCitizens(data).then(response => {
            if (!response.ok) {
              return;
            }
            this.setState({
              costs: response.data.entities,
            }, () => {
              let flagDifferent = true;
              for (let i = 0; i < this.state.costs.length; i++) {
                if (
                  this.state.typedInPersonalNumber ==
                  this.state.costs[i].personalNumber
                ) {
                  flagDifferent = false;
                }
              }

              if (
                this.state.costs.length == 0 ||
                flagDifferent == true
              ) {
                this.setState({
                  flagAddNewCost: true,
                });
              } else {
                this.setState({
                  flagAddNewCost: false,
                });
              }
            });
          });
      }
    } else if (e.target.name === "userGraveSite" && e.target.value.trim().length >= 2) {
      let data = {
        searchParam: e.target.value,
        companyId: this.props.cid,
        sort: "surname,ASC",
        userId: 0
      };

      {
        this.props.cid != undefined &&
          getCitizens(data).then(response => {
            if (!response.ok) {
              return;
            }
            this.setState({
              users: response.data.entities,
            });
          });
      }
    } else if (e.target.name === "municipalityOfBirth" && e.target.value.trim().length >= 2) {
      let data = {
        searchParam: e.target.value,
        companyId: this.props.cid,
        sort: 'city',
        userId: 0
      };

      {
        this.props.cid != undefined &&
        getPostalCodesImproved(data).then(response => {
            if (!response.ok) {
              return;
            }
            this.setState({
              settlements: response.data.entities,
            });
          });
      }
    } else if (e.target.name === "graveyard" && e.target.value.trim().length >= 2) {
      let data = {
        searchParam: e.target.value,
        companyId: this.props.cid,
        sort: this.state.sortBy,
        userId: 0
      };

      {
        this.props.cid != undefined &&
          getGraveyards(data).then(response => {
            if (!response.ok) {
              return;
            }
            this.setState({
              graveyards: response.data.entities,
            });
          });
      }

      this.setState({
        graveSites: null,
        data: {
          ...this.state.data,
          graveSite: null
        }
      });

    } else if (e.target.name === "graveSite" && e.target.value.trim().length >= 2) {
      let data = {
        searchParam: e.target.value,
        companyId: this.props.cid,
        sort: "code,ASC",
        userId: 0,
        graveyardId: this.state.data.graveyard.id
      };

      this.setState({
        typedInGraveSite: e.target.value
      });

      {
        this.props.cid != undefined &&
          getGraveSitesWithSelectedGraveyard(data).then(response => {
            if (!response.ok) {
              return;
            }
            this.setState({
              graveSites: response.data.entities,
            });
          });
      }
    } else if (e.target.name === "urn" && e.target.value.trim().length >= 2) {
      let data = {
        searchParam: e.target.value,
        companyId: this.props.cid,
        sort: this.state.sortBy,
        userId: 0
      };

      {
        this.props.cid != undefined &&
          getUrns(data).then(response => {
            if (!response.ok) {
              return;
            }
            this.setState({
              urns: response.data.entities,
            });
          });
      }
    } else if (e.target.name === "firmCost" && e.target.value.trim().length >= 2) {
      let data = {
        searchParam: e.target.value,
        companyId: this.props.cid,
        sort: this.state.sortBy,
        userId: 0
      };

      {
        this.props.cid != undefined &&
          getFirms(data).then(response => {
            if (!response.ok) {
              return;
            }
            this.setState({
              firms: response.data.entities,
            });
          });
      }
    }
  }

  render() {

    return (
      <Grid id="page" item md={12}>
        <div className="header">
          <h1>{strings.mainBookOfCremated.editPageTitle}</h1>
        </div>

        <div className="tabsDiv">
          <AppBar position="static">
            <Tabs value={this.state.value} className="tabs" onChange={this.handleChangeTab}>
              <Tab label={strings.mainBookOfCremated.deceased} className="threeTabs" />
              <Tab label={strings.mainBookOfCremated.deathReport} className="threeTabs" />
              <Tab label={strings.mainBookOfCremated.cremation} className="threeTabs" />
            </Tabs>
          </AppBar>
          {this.state.value === 0 && <TabContainer>

            <DeceasedMainBookOfCrematedForm
              onChange={this.changeData}
              onSubmit={this.submit}
              data={this.state.data}
              errors={this.state.errors}
              onCancel={this.props.onCancel}
              handleChange={this.handleChange}
              onAutocompleteChange={this.onAutocompleteChange}
              handleTextInputChange={this.handleTextInputChange}
              transporters={this.state.transporters}
              municipalities={this.state.settlements == undefined ? [] : this.state.settlements}
              states={this.state.states == undefined ? [] : this.state.states}
              occupations={this.state.occupations == undefined ? [] : this.state.occupations}
              causesOfDeath={this.state.causesOfDeath == undefined ? [] : this.state.causesOfDeath}
              doctors={this.state.doctors == undefined ? [] : this.state.doctors}
              famousCitizens={this.state.famousCitizens == undefined ? [] : this.state.famousCitizens}
              flag={this.props.flag}
              dateFlag={this.state.dateFlag}
              dateOfBirthWhenValidIDNumber={this.state.dateOfBirthWhenValidIDNumber}
              onChangePersonalNumberField={this.onChangePersonalNumberField}
            />

          </TabContainer>}
          {this.state.value === 1 && <TabContainer>

            <DeathReportMainBookOfCrematedForm
              onChange={this.changeData}
              onSubmit={this.submit}
              data={this.state.data}
              errors={this.state.errors}
              onCancel={this.props.onCancel}
              handleChange={this.handleChange}
              onAutocompleteChange={this.onAutocompleteChange}
              handleTextInputChange={this.handleTextInputChange}
              submitters={this.state.submitters == undefined ? [] : this.state.submitters}
              submitter={this.state.submitter}
              flagAddNewSubmitter={this.state.flagAddNewSubmitter}
              renderAddCitizen={this.props.flagAddCitizen}
              flagMarginValid={this.state.flagMarginValid}
              flagAddNewCost={this.state.flagAddNewCost}
              flagMarginValid2={this.state.flagMarginValid2}
              identificationSubmitterEqualsIdentificationCosts={this.identificationSubmitterEqualsIdentificationCosts}
              costs={this.state.costs == undefined ? [] : this.state.costs}
              cost={this.state.cost}
              identificationCostsEqualsIdentificationUser={this.identificationCostsEqualsIdentificationUser}
              users={this.state.users == undefined ? [] : this.state.users}
              userGraveSite={this.state.userGraveSite}
              identificationSubmitterEqualsIdentificationUser={this.identificationSubmitterEqualsIdentificationUser}
              costSameAsSubmitter={this.state.costSameAsSubmitter}
              userSameAsCost={this.state.userSameAsCost}
              userSameAsSubmitter={this.state.userSameAsSubmitter}
              costInfo={this.state.costInfo}
              userInfo={this.state.userInfo}
              firmInfo={this.state.firmInfo}
              flag={this.props.flag}
              firms={this.state.firms == undefined ? [] : this.state.firms}
              firmCost={this.state.firmCost}
            />

          </TabContainer>}
          {this.state.value === 2 && <TabContainer>

            <CremationMainBookOfCremationForm
              onChange={this.changeData}
              onSubmit={this.submit}
              data={this.state.data}
              errors={this.state.errors}
              onCancel={this.props.onCancel}
              handleChange={this.handleChange}
              onAutocompleteChange={this.onAutocompleteChange}
              handleTextInputChange={this.handleTextInputChange}
              graveyards={this.state.graveyards == undefined ? [] : this.state.graveyards}
              graveSites={this.state.graveSites == undefined ? [] : this.state.graveSites}
              urns={this.state.urns == undefined ? [] : this.state.urns}
              flag={this.props.flag}
              typedInGraveSite={this.state.typedInGraveSite}
              keyPress={this.keyPress}
              keyPressBackspace={this.keyPressBackspace}
            />

          </TabContainer>}
        </div>

      </Grid>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      changeFullScreen: Actions.changeFullScreen
    },
    dispatch
  );
}

function mapStateToProps({ menuReducers, siteDataReducers }) {
  return { menu: menuReducers, siteData: siteDataReducers };
}

export default withSnackbar(
  withRouter(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(EditMainBookOfCremated)
  )
);
