import {request} from "../../base/HTTP";
import HttpMethod from "../../constants/HttpMethod";

export async function getUrns(data) {
    return await request('/api/urn/search', data);
}

export async function addUrn(data, cid) {
    return await request('/api/urn/' + cid, data, HttpMethod.POST);
}

export function getUrnById(id) {
    return  request("api/urn/" + id , {}, HttpMethod.GET);
}

export async function editUrn(data) {
    return await request("api/urn/" + data.id, data, HttpMethod.PUT);
}

export async function deleteUrn(id, uid) {
    return await request('/api/urn/' + id + '/' + uid, {} , HttpMethod.DELETE);
}

export async function getDeletedUrns(data) {
    return await request('/api/urn/deleted', data)
}

export async function restoreUrn(data) {
    return await request("api/urn/restore/" + data.id, data, HttpMethod.PUT);
}