import React from 'react';
import strings from "../../localization";
import { bindActionCreators } from "redux";
import { withSnackbar } from "notistack";
import { withRouter } from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import * as Actions from "../../actions/Actions";
import Page from '../../common/Page';
import { generateFuneralsForAllGraveyards } from '../../services/report/ReportService';
import { downloadReport } from '../../util/ReviewsUtil';
import IconButton from "@material-ui/core/IconButton";
import Loader from '../../components/Loader';

class FuneralAllGraveyardsList extends Page {

  constructor(props) {
    super(props);

    this.state = {
      showLoader: false
    }

  }

  fetchData() {
    let data = {
      searchParam: this.state.searchData.search,
      page: this.state.searchData.page - 1,
      size: this.state.searchData.perPage,
      companyId: this.state.companyId,
      sort: this.state.sortBy,
    };
  }

  usePermissions(){
    let data = {
      userId: this.props.auth.user.id,
      category: "Funeral"
    }
    this.getPermissionsS(data);
    this.showAdd(this.props.showAdd);
    this.showRemove(this.props.remove);
    this.showEdit(this.props.showEdit);
    this.showView(this.props.showView);
    this.removedItemsShow(this.props.removedItemsShow);

  }

  componentDidMount() {
    this.usePermissions()
    this.setState({
      companyId: this.props.auth.user.company.id,
    }, () => {
      this.fetchData();
    });
  }

  componentWillReceiveProps(nextProps) {
    strings.setLanguage(nextProps.auth.user.language);
  }

  renderText() {
    let styles = {
      marginLeft: '20px',
    };

    return (
      <div style={styles}>
        <h1> {strings.review.tables.funerals.funeralsByTypeOfGraveSitesAndGraveyards} </h1>
      </div>
    );
  }

  renderButton() {
    let data = {
      companyId: this.state.companyId,
      userId: this.props.auth.user.id
    };

    let styles = {
      marginLeft: '20px',
    };

    return (
      <div className="report-button" style={styles}>
        <IconButton onClick={() => this.generateFuneralsForAllGraveyards(data, strings.review.tables.funerals.funeralsListPDFNameForAllGraveyards)}>
          {strings.review.buttons.generateReport}
        </IconButton>
      </div>
    );
  }

  generateFuneralsForAllGraveyards(data, value) {
    this.showDrawerLoader();
    generateFuneralsForAllGraveyards(data).then(response => {
      if (!response.ok) {
        return;
      }


      this.props.enqueueSnackbar(strings.review.tables.funerals.funeralsListPDF, { variant: 'success' });
      downloadReport(response, value);
      this.hideDrawerLoader();
    });
  }


  render() {
    return (
      <div>
        {
          this.renderText()
        }
        {
          this.renderButton()
        }
        {
          this.state.showLoader &&
          <Loader />
        }
      </div>
    );
  }
}


function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    changeFullScreen: Actions.changeFullScreen
  }, dispatch);
}

function mapStateToProps({ menuReducers, authReducers, siteDataReducers }) {
  return { menu: menuReducers, auth: authReducers, siteData: siteDataReducers };
}

export default withSnackbar(withRouter(connect(mapStateToProps, mapDispatchToProps)(FuneralAllGraveyardsList)));

