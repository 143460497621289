import FormComponent from "../../common/FormComponent";
import React from "react";
import { bindActionCreators } from "redux";
import * as Actions from "../../actions/Actions";
import { withRouter } from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import Grid from "@material-ui/core/Grid";
import { Paper } from "@material-ui/core";
import strings from "../../localization";
import { withSnackbar } from "notistack";
import { getExhumationById } from "../../services/exhumation/ExhumationService";
import ExhumationForm from "../../components/forms/exhumation/ExhumationForm";
import { getMainBookOfBuriedById } from "../../services/mainBookOfBuried/MainBookOfBuriedService";
import { getGraveyardById } from "../../services/graveyard/GraveyardService";
import { getGraveSiteById } from "../../services/graveSite/GraveSiteService";
import { getCitizenById } from "../../services/citizen/CitizenService";

class ViewExhumation extends FormComponent {

  constructor(props) {
    super(props);

    this.state = {
      data: props.data ? props.data : {},
      errors: {}
    };

    this.props.changeFullScreen(false);
  }

  componentDidMount() {
    
    getExhumationById(this.props.id).then(response => {
      this.setState({
        data: response.data,

      }, () => {
        getMainBookOfBuriedById(this.state.data.mainBookOfBuriedId).then(response => {
          this.setState({
            data: {
              ...this.state.data,
              mainBookOfBuried: response.data
            },
          });
        });
        getGraveyardById(this.state.data.graveyardId).then(response => {
          this.setState({
            data: {
              ...this.state.data,
              graveyard: response.data
            },
          });
        });
        {
          this.state.data.graveSiteId &&
          getGraveSiteById(this.state.data.graveSiteId).then(response => {
            this.setState({
              data: {
                ...this.state.data,
                graveSite: response.data
              },
            });
          })
        }
        {
          this.state.data.costId &&
          getCitizenById(this.state.data.costId).then(response => {
            this.setState({
              data: {
                ...this.state.data,
                cost: {
                  personalNumber: response.data.personalNumber,
                  nameSurnameCost: response.data.name + " " + response.data.surname,
                  addressCost: response.data.fullAddress,
                  municipalityCost: response.data.postalCodeImprovedZip,
                  telephoneCost: response.data.telephone,
                }
              }
            });
          })
        }
      });
    });
  }

  render() {

    return (
      <Grid id="page" item md={12}>
        <div className="header">
          <h1>{strings.exhumation.viewPageTitle}</h1>
        </div>
        <Paper className='paper'>
          <ExhumationForm
            onChange={this.changeData}
            onSubmit={this.submit}
            data={this.state.data}
            errors={this.state.errors}
            onCancel={this.props.onCancel}
            isDisabled={this.props.isDisabled}
            mainBooksOfBuried={this.state.mainBooksOfBuried == undefined ? [] : this.state.mainBooksOfBuried}
            graveyards={this.state.graveyards == undefined ? [] : this.state.graveyards}
            graveSites={this.state.graveSites == undefined ? [] : this.state.graveSites}
            flag={this.props.flag}
          />
        </Paper>
      </Grid>
    );
  }
}

function mapDispatchToProps(dispatch) {

  return bindActionCreators(
    {
      changeFullScreen: Actions.changeFullScreen
    },
    dispatch
  );
}

function mapStateToProps({ menuReducers, siteDataReducers }) {

  return { menu: menuReducers, siteData: siteDataReducers };
}

export default withSnackbar(
  withRouter(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(ViewExhumation)
  )
);
