import React from 'react'
import TablePage from "../../common/TablePage";
import { bindActionCreators } from "redux";
import * as Actions from "../../actions/Actions";
import { withRouter } from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import strings from "../../localization";
import { withSnackbar } from "notistack";
import { deleteRole, getRoles } from '../../services/roles/RolesService';
import AddRole from './AddRole';


class RolesList extends TablePage {
    
    tableDescription = [
        { key: 'name', label: strings.roles.name, sort: 'name' },
        { key: 'dateCreated', label: strings.stateList.dateCreated, transform: 'renderColumnDate', sort: 'dateCreated' },
      ];

    constructor(props) {
        super(props);
    
        this.state.showDeleted = false;
        this.state.sortBy = "name,ASC";
        
    
      }

      usePermissions(){
        let data = {
          userId: this.props.auth.user.id,
          category: "Роле"
        }
        this.getPermissionsS(data);
        
    
      }
    
      componentDidMount() {
        this.usePermissions()
        this.setState({
          companyId: this.props.auth.user.company.id
        }, () => { this.fetchData(); })
      }

      componentWillReceiveProps(nextProps){
        strings.setLanguage(nextProps.auth.user.language);
        this.tableDescription = [
          { key: 'name', label: strings.roles.name, sort: 'name' },
          { key: 'dateCreated', label: strings.stateList.dateCreated, transform: 'renderColumnDate', sort: 'dateCreated' },
        ];
      }

      fetchData() {
        let data = {
            searchParam: this.state.searchData.search,
            page: this.state.searchData.page - 1,
            size: this.state.searchData.perPage,
            companyId: this.state.companyId,
            sort: this.state.sortBy,
            userId: this.props.auth.user.id
        };

        getRoles(data).then(response => {
            if (!response.ok) {
              return;
            }
            this.setState({
              tableData: response.data.entities,
              total: response.data.total,
            });
          });
    }

    delete(item){
      this.setState({
        lockTable: true
      });

      deleteRole(item.id, this.props.auth.user.id).then(response =>{
        if (response && !response.ok) {
          this.onFinish(null);
          return;
        }
  
        this.props.enqueueSnackbar(strings.roles.deleteRole, { variant: 'success' });
  
        this.onFinish(item);
        this.cancelDelete();
  
        this.setState({
          lockTable: false
        });
      });
    }
    renderAddContent() {
        return <AddRole cid={this.state.companyId} uemail={this.props.auth.user.email} onCancel={this.onCancel} onFinish={this.onFinish} />
      }

      getPageHeader() {
        return <h1>{strings.roles.pageTitle}</h1>;
      }
    
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
      changeFullScreen: Actions.changeFullScreen
    }, dispatch);
  }
  
  function mapStateToProps({ menuReducers, authReducers, siteDataReducers }) {
    return { menu: menuReducers, auth: authReducers, siteData: siteDataReducers };
  }
  
  export default withSnackbar(withRouter(connect(mapStateToProps, mapDispatchToProps)(RolesList)));