import React from 'react';
import strings from "../../../localization";
import { getError, hasError } from "../../../functions/Validation";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Button, TextField } from "@material-ui/core";
import DatePickerControl from '../../controls/DatePickerControl';
import { dateTimeToString, dateToString, dateToStringDatePickerFormat, dateToStringDatePickerFormat2, stringToDate, stringToDate2, changeTimeZone } from '../../../util/DateUtil';
import moment from "moment";

const FuneralMainBookOfBuriedForm = ({
  onSubmit,
  onCancel,
  onChange,
  errors,
  data,
  isDisabled,
  graveyards,
  graveSites,
  stoneCutters,
  handleChange,
  onAutocompleteChange,
  handleTextInputChange,
  flag,
  typedInGraveSite,
  keyPress,
  keyPressBackspace,
  disabled,
  onKeyDown,
  onChangePaymentSlipAndDateField
}) => {


  return (

    <form id='user-form'>
      <label className="header-label"> {strings.mainBookOfBuried.form.currentPlaceOfRest} </label>
      <Autocomplete
        id={"graveyard"}
        name={"graveyard"}
        options={graveyards}
        getOptionLabel={option => option.name}
        onChange={onAutocompleteChange}
        renderInput={params => (
          <TextField
            {...params}
            variant="standard"
            label={strings.mainBookOfBuried.form.graveyard}
            error={hasError(errors, 'graveyard')}
            helperText={getError(errors, 'graveyard')}
            margin="normal"
            fullWidth
            name={"graveyard"}
            onChange={handleTextInputChange}
            InputLabelProps={{
              shrink: true,
            }}
          />
        )}
        disabled={isDisabled}
        value={data.graveyard}
      />

      {(data.dateTimeFuneralString != undefined && data.dateTimeFuneralString != null && data.dateTimeFuneralString != "") &&
        <TextField
          label={strings.mainBookOfBuried.form.dateTimeFuneralString}
          fullWidth
          name='dateTimeFuneralString'
          onChange={handleTextInputChange}
          margin="normal"
          value={data.dateTimeFuneralString}
          InputLabelProps={{
            shrink: true,
          }}
          disabled={true}
        />
      }
      <DatePickerControl
        label={strings.mainBookOfBuried.form.dateTimeFuneral}
        error={getError(errors, 'dateTimeFuneral')}
        hasError={hasError(errors, 'dateTimeFuneral')}
        helperText={getError(errors, 'dateTimeFuneral')}
        placeholder={strings.mainBookOfBuried.form.dateTimeFuneral}
        name='dateTimeFuneral'
        onChange={onChange}
        date={data.dateTimeFuneral ? new Date(data.dateTimeFuneral) : data.dateTimeFuneral}
        isClearable={true}
        dateFormat='dd/MM/yyyy HH:mm'
        showTimeSelect={true}
        disabled={isDisabled}
      />
      {(data.graveyard != undefined && data.graveyard != null) &&
        <Autocomplete
          noOptionsText={(typedInGraveSite != undefined ? strings.mainBookOfBuried.form.create + typedInGraveSite : "")}
          onKeyPress={keyPress}
          id={"graveSite"}
          name={"graveSite"}
          options={graveSites}
          getOptionLabel={option => option.code}
          onChange={onAutocompleteChange}
          renderInput={params => (
            <TextField
              {...params}
              variant="standard"
              label={strings.mainBookOfBuried.form.graveSite}
              error={hasError(errors, 'graveSite')}
              helperText={getError(errors, 'graveSite')}
              margin="normal"
              fullWidth
              name={"graveSite"}
              onChange={handleTextInputChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
          disabled={isDisabled}
          value={data.graveSite}
          disableClearable={true}
          onKeyDown={keyPressBackspace}
        />
      }


      {(data.graveyard == undefined || data.graveyard == null) &&
        <Autocomplete
          id={"graveSite"}
          name={"graveSite"}
          options={graveSites}
          getOptionLabel={option => option.code}
          onChange={onAutocompleteChange}
          renderInput={params => (
            <TextField
              {...params}
              variant="standard"
              label={strings.mainBookOfBuried.form.graveSite}
              error={hasError(errors, 'graveSite')}
              helperText={getError(errors, 'graveSite')}
              margin="normal"
              fullWidth
              name={"graveSite"}
              onChange={handleTextInputChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
          disabled={true}
          value={null}
          disableClearable={true}
        />
      }

      <TextField
        label={strings.mainBookOfBuried.form.paymentNumber}
        error={hasError(errors, 'paymentNumber')}
        helperText={getError(errors, 'paymentNumber')}
        fullWidth
        name='paymentNumber'
        onChange={onChangePaymentSlipAndDateField}
        margin="normal"
        defaultValue={data.paymentNumber}
        value={data.paymentNumber}
        InputLabelProps={{
          shrink: true,
        }}
        disabled={isDisabled}
        onKeyDown={onKeyDown}

      />
{/* 
      {(data.expiryDateString != undefined && data.expiryDateString != null && data.expiryDateString != "") &&
        <TextField
          label={strings.mainBookOfBuried.form.expiryDateString}
          fullWidth
          name='expiryDateString'
          onChange={handleTextInputChange}
          margin="normal"
          value={data.expiryDateString}
          InputLabelProps={{
            shrink: true,
          }}
          disabled={true}
        />
      } */}

      {flag == "add" &&
        <DatePickerControl
          label={strings.mainBookOfBuried.form.expiryDate}
          error={getError(errors, 'expiryDate')}
          hasError={hasError(errors, 'expiryDate')}
          helperText={getError(errors, 'expiryDate')}
          placeholder={strings.mainBookOfBuried.form.expiryDate}
          name='expiryDate'
          onChange={onChange}
          //date={data.graveSite != undefined ? (data.graveSite.expirationDate ? new Date(data.graveSite.expirationDate) : data.expiryDate) : (data.expiryDate)}
          //date={data.dateTimeFuneral ? new Date(data.dateTimeFuneral) : data.dateTimeFuneral}
          //date={data.expiryDate ? new Date(data.expiryDate) : data.expiryDate}
          date={data.expiryDate ? stringToDate(data.expiryDateString) > moment(data.expiryDate, 'YYYY-MM-DD') ? stringToDate(data.expiryDateString).toDate() : new Date(data.expiryDate) : data.expiryDate}
          isClearable={true}
          dateFormat='dd/MM/yyyy'
          disabled={isDisabled}
        />
      }

      {(flag == "edit" || flag == "view") &&
        <DatePickerControl
          label={strings.mainBookOfBuried.form.expiryDate}
          error={getError(errors, 'expiryDate')}
          hasError={hasError(errors, 'expiryDate')}
          helperText={getError(errors, 'expiryDate')}
          placeholder={strings.mainBookOfBuried.form.expiryDate}
          name='expiryDate'
          onChange={onChange}
          //date={data.graveSite != undefined ? (data.graveSite.expirationDate ? new Date(data.graveSite.expirationDate) : data.expiryDate) : (data.expiryDate)}
          //date={data.dateTimeFuneral ? new Date(data.dateTimeFuneral) : data.dateTimeFuneral}
          // date={data.expiryDate ? new Date(data.expiryDate) : data.expiryDate}
          date={data.expiryDate ? stringToDate(data.expiryDateString) > moment(data.expiryDate, 'YYYY-MM-DD') ? stringToDate(data.expiryDateString).toDate() : new Date(data.expiryDate) : data.expiryDate}
          isClearable={true}
          dateFormat='dd/MM/yyyy'
          disabled={isDisabled}
        />
      }



      <Autocomplete
        id={"stoneCutter"}
        name={"stoneCutter"}
        options={stoneCutters}
        getOptionLabel={option => option.name}
        onChange={onAutocompleteChange}
        renderInput={params => (
          <TextField
            {...params}
            variant="standard"
            label={strings.mainBookOfBuried.form.stoneCutter}
            error={hasError(errors, 'stoneCutter')}
            helperText={getError(errors, 'stoneCutter')}
            margin="normal"
            fullWidth
            name={"stoneCutter"}
            onChange={handleTextInputChange}
            InputLabelProps={{
              shrink: true,
            }}
          />
        )}
        disabled={isDisabled}
        value={data.stoneCutter}
      />

      {(flag == "edit" || flag == "view") &&
        <React.Fragment>
          <label className="header-label"> {strings.mainBookOfBuried.form.previousPlaceOfRest} </label>
          <Autocomplete
            id={"graveyard2"}
            name={"graveyard2"}
            options={graveyards}
            getOptionLabel={option => option.name}
            onChange={onAutocompleteChange}
            renderInput={params => (
              <TextField
                {...params}
                variant="standard"
                label={strings.mainBookOfBuried.form.graveyard2}
                error={hasError(errors, 'graveyard2')}
                helperText={getError(errors, 'graveyard2')}
                margin="normal"
                fullWidth
                name={"graveyard2"}
                onChange={handleTextInputChange}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
            disabled={true}
            value={data.graveyard2}
          />
          {(data.dateTimeFuneral2String != undefined && data.dateTimeFuneral2String != null && data.dateTimeFuneral2String != "") &&
            <TextField
              label={strings.mainBookOfBuried.form.dateTimeFuneral2String}
              fullWidth
              name='dateTimeFuneral2String'
              onChange={handleTextInputChange}
              margin="normal"
              value={data.dateTimeFuneral2String}
              InputLabelProps={{
                shrink: true,
              }}
              disabled={true}
            />
          }
          <DatePickerControl
            label={strings.mainBookOfBuried.form.dateTimeFuneral2}
            error={getError(errors, 'dateTimeFuneral2')}
            hasError={hasError(errors, 'dateTimeFuneral2')}
            helperText={getError(errors, 'dateTimeFuneral2')}
            placeholder={strings.mainBookOfBuried.form.dateTimeFuneral2}
            name='dateTimeFuneral2'
            onChange={onChange}
            date={data.dateTimeFuneral2 ? new Date(data.dateTimeFuneral2) : data.dateTimeFuneral2}
            isClearable={true}
            dateFormat='dd/MM/yyyy HH:mm'
            showTimeSelect={true}
            disabled={true}
          />
          <Autocomplete
            id={"graveSite2"}
            name={"graveSite2"}
            options={graveSites}
            getOptionLabel={option => option.code}
            onChange={onAutocompleteChange}
            renderInput={params => (
              <TextField
                {...params}
                variant="standard"
                label={strings.mainBookOfBuried.form.graveSite2}
                error={hasError(errors, 'graveSite2')}
                helperText={getError(errors, 'graveSite2')}
                margin="normal"
                fullWidth
                name={"graveSite2"}
                onChange={handleTextInputChange}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
            disabled={true}
            value={data.graveSite2}
          />
          <TextField
            label={strings.mainBookOfBuried.form.paymentNumber2}
            error={hasError(errors, 'paymentNumber')}
            helperText={getError(errors, 'paymentNumber')}
            fullWidth
            name='paymentNumber2'
            onChange={onChangePaymentSlipAndDateField}
            margin="normal"
            defaultValue={data.paymentNumber2}
            value={data.paymentNumber2}
            InputLabelProps={{
              shrink: true,
            }}
            disabled={true}
            onKeyDown={onKeyDown}
          />
          <Autocomplete
            id={"graveyard3"}
            name={"graveyard3"}
            options={graveyards}
            getOptionLabel={option => option.name}
            onChange={onAutocompleteChange}
            renderInput={params => (
              <TextField
                {...params}
                variant="standard"
                label={strings.mainBookOfBuried.form.graveyard3}
                error={hasError(errors, 'graveyard3')}
                helperText={getError(errors, 'graveyard3')}
                margin="normal"
                fullWidth
                name={"graveyard3"}
                onChange={handleTextInputChange}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
            disabled={true}
            value={data.graveyard3}
          />
          {(data.dateTimeFuneral3String != undefined && data.dateTimeFuneral3String != null && data.dateTimeFuneral3String != "") &&
            <TextField
              label={strings.mainBookOfBuried.form.dateTimeFuneral3String}
              fullWidth
              name='dateTimeFuneral3String'
              onChange={handleTextInputChange}
              margin="normal"
              value={data.dateTimeFuneral3String}
              InputLabelProps={{
                shrink: true,
              }}
              disabled={true}
            />
          }
          <DatePickerControl
            label={strings.mainBookOfBuried.form.dateTimeFuneral3}
            error={getError(errors, 'dateTimeFuneral3')}
            hasError={hasError(errors, 'dateTimeFuneral3')}
            helperText={getError(errors, 'dateTimeFuneral3')}
            placeholder={strings.mainBookOfBuried.form.dateTimeFuneral3}
            name='dateTimeFuneral3'
            onChange={onChange}
            date={data.dateTimeFuneral3 ? new Date(data.dateTimeFuneral3) : data.dateTimeFuneral3}
            isClearable={true}
            dateFormat='dd/MM/yyyy HH:mm'
            showTimeSelect={true}
            disabled={true}
          />
          <Autocomplete
            id={"graveSite3"}
            name={"graveSite3"}
            options={graveSites}
            getOptionLabel={option => option.code}
            onChange={onAutocompleteChange}
            renderInput={params => (
              <TextField
                {...params}
                variant="standard"
                label={strings.mainBookOfBuried.form.graveSite3}
                error={hasError(errors, 'graveSite3')}
                helperText={getError(errors, 'graveSite3')}
                margin="normal"
                fullWidth
                name={"graveSite3"}
                onChange={handleTextInputChange}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
            disabled={true}
            value={data.graveSite3}
          />
          <TextField
            label={strings.mainBookOfBuried.form.paymentNumber3}
            error={hasError(errors, 'paymentNumber')}
            helperText={getError(errors, 'paymentNumber')}
            fullWidth
            name='paymentNumber3'
            onChange={onChangePaymentSlipAndDateField}
            margin="normal"
            defaultValue={data.paymentNumber3}
            value={data.paymentNumber3}
            InputLabelProps={{
              shrink: true,
            }}
            disabled={true}
            onKeyDown={onKeyDown}
          />
          <Autocomplete
            id={"graveyard4"}
            name={"graveyard4"}
            options={graveyards}
            getOptionLabel={option => option.name}
            onChange={onAutocompleteChange}
            renderInput={params => (
              <TextField
                {...params}
                variant="standard"
                label={strings.mainBookOfBuried.form.graveyard4}
                error={hasError(errors, 'graveyard4')}
                helperText={getError(errors, 'graveyard4')}
                margin="normal"
                fullWidth
                name={"graveyard4"}
                onChange={handleTextInputChange}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
            disabled={true}
            value={data.graveyard4}
          />
          {(data.dateTimeFuneral4String != undefined && data.dateTimeFuneral4String != null && data.dateTimeFuneral4String != "") &&
            <TextField
              label={strings.mainBookOfBuried.form.dateTimeFuneral4String}
              fullWidth
              name='dateTimeFuneral4String'
              onChange={handleTextInputChange}
              margin="normal"
              value={data.dateTimeFuneral4String}
              InputLabelProps={{
                shrink: true,
              }}
              disabled={true}
            />
          }
          <DatePickerControl
            label={strings.mainBookOfBuried.form.dateTimeFuneral4}
            error={getError(errors, 'dateTimeFuneral4')}
            hasError={hasError(errors, 'dateTimeFuneral4')}
            helperText={getError(errors, 'dateTimeFuneral4')}
            placeholder={strings.mainBookOfBuried.form.dateTimeFuneral4}
            name='dateTimeFuneral4'
            onChange={onChange}
            date={data.dateTimeFuneral4 ? new Date(data.dateTimeFuneral4) : data.dateTimeFuneral4}
            isClearable={true}
            dateFormat='dd/MM/yyyy HH:mm'
            showTimeSelect={true}
            disabled={true}
          />
          <Autocomplete
            id={"graveSite4"}
            name={"graveSite4"}
            options={graveSites}
            getOptionLabel={option => option.code}
            onChange={onAutocompleteChange}
            renderInput={params => (
              <TextField
                {...params}
                variant="standard"
                label={strings.mainBookOfBuried.form.graveSite4}
                error={hasError(errors, 'graveSite4')}
                helperText={getError(errors, 'graveSite4')}
                margin="normal"
                fullWidth
                name={"graveSite4"}
                onChange={handleTextInputChange}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
            disabled={true}
            value={data.graveSite4}
          />
          <TextField
            label={strings.mainBookOfBuried.form.paymentNumber4}
            error={hasError(errors, 'paymentNumber')}
            helperText={getError(errors, 'paymentNumber')}
            fullWidth
            name='paymentNumber4'
            onChange={onChangePaymentSlipAndDateField}
            margin="normal"
            defaultValue={data.paymentNumber4}
            value={data.paymentNumber4}
            InputLabelProps={{
              shrink: true,
            }}
            disabled={true}
            onKeyDown={onKeyDown}
          />
        </React.Fragment>
      }
      {
        !isDisabled &&
        <div className='submit-container'>
          <Button variant="contained" color="primary" disabled={disabled} onClick={onSubmit}>
            {strings.settlement.form.ok}
          </Button>
          <Button variant="contained" color="secondary" onClick={onCancel}>
            {strings.settlement.form.cancel}
          </Button>
        </div>
      }
    </form>
  )
};

export default FuneralMainBookOfBuriedForm;