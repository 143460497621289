import React from 'react'
import { bindActionCreators } from "redux";
import * as Actions from "../../actions/Actions";
import { withRouter } from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import Grid from '@material-ui/core/Grid';
import { Paper } from "@material-ui/core";
import strings from "../../localization";
import Validators from "../../constants/ValidatorTypes";
import FormComponent from "../../common/FormComponent";
import { withSnackbar } from "notistack";
import UserRoleForm from '../../components/forms/userRole/UserRoleForm';
import { addUserRole } from '../../services/userRoles/UserRoleService';
import { getRoleByName, getRoles } from '../../services/roles/RolesService';
import { getUserBySearchParam } from '../../services/user/UserService';

class AddUserRole extends FormComponent{
    
    constructor(props) {
        super(props);

        this.state = {
            data: props.data ? props.data : {},
            errors: {}
        };

        this.props.changeFullScreen(false);

        this.submit = this.submit.bind(this);

    }


    onAutocompleteChange = (event, values) => {
        let autocompleteName = event.target.id.split("-")[0];
        this.setState({
            data: {
                ...this.state.data, [autocompleteName]: values
            }
        });
    }

    handleTextInputChange = (e) =>{
        if(e.target.name === "role" && e.target.value.trim().length >= 2){
            let data = {
                searchParam: e.target.value,
                companyId: this.props.cid,
                sort: "name,ASC",
                userId: 0,
            };
            {
                this.props.cid != undefined &&
                getRoles(data).then(response => {
                    if (!response.ok) {

                        return;
                    }

                    this.setState({
                        roles: response.data.entities,
                    });
                });
            }
        }
        if(e.target.name === "user" && e.target.value.trim().length >= 2){
            let data = {
                searchParam: e.target.value,
                companyId: this.props.cid,
                sort: "name,ASC",
                userId: 0,
            };
            {
                this.props.cid != undefined &&
                getUserBySearchParam(data).then(response => {
                    if (!response.ok) {

                        return;
                    }

                    this.setState({
                        users: response.data.entities,
                    });
                });
            }
        }
    }

    transformRequest(data) {
        
        return {
            ...data,
            userEmail: this.props.uemail,
            companyId: this.props.cid,
            userId: this.state.data.user ? this.state.data.user.id : null,
            roleId: this.state.data.role ? this.state.data.role.id : null,

        }
    }

    submit(){
        
        if(!this.validate()){
            
            return;
        }

        this.showDrawerLoader();
    

        addUserRole(this.transformRequest(this.state.data), this.props.cid).then(response =>{
            if(!response.ok){
                let messageKey = response.response.data.message;

                this.props.enqueueSnackbar(strings.state.messages[messageKey], {
                    variant: "error"
                });

                // this.handleError(messageKey);

                return;
            }
            this.props.enqueueSnackbar(strings.userRole.userRoleAdded, { variant: 'success' });
            this.props.onFinish(response.data.state);

            this.hideDrawerLoader();
        
        })
    }

    render() {
        return (
            <Grid id='page' item md={12}>

            <div className='header'>
                <h1>{"Роле корисника"}</h1>
            </div>

            <Paper className='paper'>
                <UserRoleForm 
                onChange={this.changeData} 
                cid={this.state.companyId} 
                onSubmit={this.submit} 
                data={this.state.data}
                onCancel={this.props.onCancel}
                isDisabled={this.props.isDIsabled}
                onAutocompleteChange={this.onAutocompleteChange}
                handleTextInputChange={this.handleTextInputChange}
                roles={this.state.roles == undefined ? [] : this.state.roles}
                users={this.state.users == undefined ? [] : this.state.users} 
                flag={"add"}/>
            </Paper>

        </Grid>
        );
    }

}
function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        changeFullScreen: Actions.changeFullScreen
    }, dispatch);
}

function mapStateToProps({ menuReducers, siteDataReducers }) {
    return { menu: menuReducers, siteData: siteDataReducers };
}

export default withSnackbar(withRouter(connect(mapStateToProps, mapDispatchToProps)(AddUserRole)));