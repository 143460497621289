import { request } from "../../base/HTTP";
import HttpMethod from "../../constants/HttpMethod";

export async function getAllTombstones() {
  return await request("api/tombstone/all");
}

export async function getTombstones(data) {
  return await request('api/tombstone/search', data);
}

export function getTombstoneById(id) {
  return request("api/tombstone/" + id, {}, HttpMethod.GET);
}

export async function addTombstone(data, cid) {
  return await request('/api/tombstone/' + cid, data, HttpMethod.POST);
}

export async function editTombstone(data) {
  return await request("api/tombstone/" + data.id, data, HttpMethod.PUT);
}

export async function deleteTombstone(id, uid) {
  return await request('/api/tombstone/' + id + '/' + uid, {}, HttpMethod.DELETE);
}

export async function getDeletedTombstones(data){
  return await request('/api/tombstone/deleted',data)
}

export async function restoreTombstone(data) {
  return await request("api/tombstone/restore/" + data.id, data, HttpMethod.PUT);
}
