import {request} from "../../base/HTTP";
import HttpMethod from "../../constants/HttpMethod";

export async function getAllGraveyards() {
    return await request('/api/graveyard/all');
}

export async function getGraveyards(data) {
    return await request('/api/graveyard/search', data);
}

export async function addGraveyard(data, cid) {
    return await request('/api/graveyard/' + cid, data, HttpMethod.POST);
}

export function getGraveyardById(id) {
    return  request("api/graveyard/" + id , {}, HttpMethod.GET);
}

export async function editGraveyard(data) {
    return await request("api/graveyard/" + data.id, data, HttpMethod.PUT);
}

export async function deleteGraveyard(id, uid) {
    return await request('/api/graveyard/' + id + '/' + uid, {} , HttpMethod.DELETE);
}

export async function getDeletedGraveyards(data) {
    return await request('/api/graveyard/deleted', data)
}

export async function restoreGraveyard(data) {
    return await request("api/graveyard/restore/" + data.id, data, HttpMethod.PUT);
}
