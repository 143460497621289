import React from 'react'
import { bindActionCreators } from "redux";
import * as Actions from "../../actions/Actions";
import { withRouter } from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import Grid from '@material-ui/core/Grid';
import { Paper } from "@material-ui/core";
import strings from "../../localization";
import FormComponent from "../../common/FormComponent";
import { withSnackbar } from "notistack";
import Validators from '../../constants/ValidatorTypes';
import { editPostalAddressCode, getPostalAddressCodeById } from '../../services/postalAddressCode/PostalAddressCodeService';
import { getPostalCodeById, getPostalCodes } from '../../services/postalCode/PostalCodeService';
import { getSettlementById, getSettlements } from '../../services/settlement/SettlementService';
import { getStateById, getStates } from '../../services/state/StateService';
import PostalAddressCodeForm from '../../components/forms/postalAddressCode/PostalAddressCodeForm';

class EditPostalAddressCode extends FormComponent {

  validationList = {
    place: [{ type: Validators.REQUIRED }],
    street: [{ type: Validators.REQUIRED }],
    number: [{ type: Validators.REQUIRED }],
    pac: [{ type: Validators.REQUIRED }],
    postalNumber: [{ type: Validators.REQUIRED }],
    settlement: [{ type: Validators.REQUIRED }],
    state: [{ type: Validators.REQUIRED }],
  };

  constructor(props) {
    super(props);

    this.state = {
      data: props.data ? props.data : {},
      errors: {},
    };

    this.props.changeFullScreen(false);
    this.submit = this.submit.bind(this);
  }

  componentDidMount() {

    getPostalAddressCodeById(this.props.id).then(response => {
      this.setState({
        data: response.data
      }, () => {
        getPostalCodeById(this.state.data.postalNumberId).then(response => {
          this.setState({
            data: {
              ...this.state.data,
              postalNumber: response.data
            },
          });
        });
        getSettlementById(this.state.data.settlementId).then(response => {
          this.setState({
            data: {
              ...this.state.data,
              settlement: response.data
            },
          });
        });
        getStateById(this.state.data.stateId).then(response => {
          this.setState({
            data: {
              ...this.state.data,
              state: response.data
            },
          });
        });
      });
    });
  }

  submit() {
    if (!this.validate()) {
      return;
    }

    this.showDrawerLoader();

    editPostalAddressCode(this.transformRequest(this.state.data), this.props.cid).then(response => {
      if (!response.ok) {

        let messageKey = response.response.data.message;

        this.props.enqueueSnackbar(strings.postalAddressCode.messages[messageKey], {
          variant: "error"
        });

        this.handleError(messageKey);
        return;
      }

      this.props.enqueueSnackbar(strings.addCompany.postalAddressCodeEdited, { variant: 'success' });
      this.props.onFinish(response.data.postalAddressCode);

      this.hideDrawerLoader();
    });
  }

  transformRequest(data) {

    return {
      ...data,
      companyId: this.props.cid,
      userEmail: this.props.uemail,
      settlementId: this.state.data.settlement ? this.state.data.settlement.id : null,
      stateId: this.state.data.state ? this.state.data.state.id : null,
      postalNumberId: this.state.data.postalNumber ? this.state.data.postalNumber.id : null,
    }
  }

  handleError(message) {
    switch (message) {
      case "POSTAL_ADDRESS_CODE_PLACE_CANT_BE_NULL":
        this.setError("place", strings.postalAddressCode.messages.POSTAL_ADDRESS_CODE_PLACE_CANT_BE_NULL);
        break;
      case "POSTAL_ADDRESS_CODE_STREET_CANT_BE_NULL":
        this.setError("street", strings.postalAddressCode.messages.POSTAL_ADDRESS_CODE_STREET_CANT_BE_NULL);
        break;
      case "POSTAL_ADDRESS_CODE_NUMBER_CANT_BE_NULL":
        this.setError("number", strings.postalAddressCode.messages.POSTAL_ADDRESS_CODE_NUMBER_CANT_BE_NULL);
        break;
      case "POSTAL_ADDRESS_CODE_PAC_CANT_BE_NULL":
        this.setError("pac", strings.postalAddressCode.messages.POSTAL_ADDRESS_CODE_PAC_CANT_BE_NULL);
        break;
      case "POSTAL_ADDRESS_CODE_POSTAL_NUMBER_CANT_BE_NULL":
        this.setError("postalNumber", strings.postalAddressCode.messages.POSTAL_ADDRESS_CODE_POSTAL_NUMBER_CANT_BE_NULL);
        break;
      case "POSTAL_ADDRESS_CODE_SETTLEMENT_CODE_CANT_BE_NULL":
        this.setError("settlement", strings.postalAddressCode.messages.POSTAL_ADDRESS_CODE_SETTLEMENT_CODE_CANT_BE_NULL);
        break;
      case "POSTAL_ADDRESS_CODE_STATE_CODE_CANT_BE_NULL":
        this.setError("state", strings.postalAddressCode.messages.POSTAL_ADDRESS_CODE_STATE_CODE_CANT_BE_NULL);
        break;

    }
  }

  /** 
   * Handles autocomplete change event
   * Triggers when user selects something from dropdown menu 
   */
  onAutocompleteChange = (event, values) => {
    let autocompleteName = event.target.id.split("-")[0];
    this.setState({
      data: {
        ...this.state.data, [autocompleteName]: values
      }
    });
  }

  /** 
   * Handles autocomplete type input change event
   * When user typed in 2+ characters it searches for results to fill dropdown 
   */
  handleTextInputChange = (e) => {
    if (e.target.name === "postalNumber" && e.target.value.trim().length >= 2) {
      let data = {
        searchParam: e.target.value,
        companyId: this.props.cid,
        sort: "code,ASC",
        userId: 0
      };

      {
        this.props.cid != undefined &&
          getPostalCodes(data).then(response => {
            if (!response.ok) {
              return;
            }
            this.setState({
              postalNumbers: response.data.entities,
            });
          });
      }
    }
    else if (e.target.name === 'settlement' && e.target.value.trim().length >= 2) {
      let data = {
        searchParam: e.target.value,
        companyId: this.props.cid,
        sort: "code,ASC",
        userId: 0
      };

      {
        this.props.cid != undefined &&
          getSettlements(data).then(response => {
            if (!response.ok) {
              return;
            }
            this.setState({
              settlementCodes: response.data.entities,
            });
          });
      }
    }
    else if (e.target.name === "state" && e.target.value.trim().length >= 2) {
      let data = {
        searchParam: e.target.value,
        companyId: this.props.cid,
        sort: "code,ASC",
        userId: 0
      };

      {
        this.props.cid != undefined &&
          getStates(data).then(response => {
            if (!response.ok) {
              return;
            }
            this.setState({
              stateCodes: response.data.entities,
            });
          });
      }
    }
  }

  render() {
    return (
      <Grid id='page' item md={12}>

        <div className='header'>
          <h1>{strings.postalAddressCode.editPageTitle}</h1>
        </div>

        <Paper className='paper'>
          <PostalAddressCodeForm
            onChange={this.changeData}
            onSubmit={this.submit}
            data={this.state.data}
            errors={this.state.errors}
            onCancel={this.props.onCancel}
            isDisabled={this.props.isDisabled}
            onAutocompleteChange={this.onAutocompleteChange}
            handleChange={this.handleChange}
            handleTextInputChange={this.handleTextInputChange}
            flag={this.props.flag}
            postalNumbers={this.state.postalNumbers == undefined ? [] : this.state.postalNumbers}
            settlementCodes={this.state.settlementCodes == undefined ? [] : this.state.settlementCodes}
            stateCodes={this.state.stateCodes == undefined ? [] : this.state.stateCodes}
          />
        </Paper>

      </Grid>

    );
  }

}


function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    changeFullScreen: Actions.changeFullScreen
  }, dispatch);
}

function mapStateToProps({ menuReducers, siteDataReducers }) {
  return { menu: menuReducers, siteData: siteDataReducers };
}

export default withSnackbar(withRouter(connect(mapStateToProps, mapDispatchToProps)(EditPostalAddressCode)));