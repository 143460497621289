import React from 'react';
import strings from "../../../localization";
import { getError, hasError } from "../../../functions/Validation";
import { Button, TextField, FormLabel, FormGroup, FormControlLabel, Checkbox, Radio, FormControl, RadioGroup } from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';

const DeathReportMainBookOfBuriedForm = ({
    onSubmit,
    onCancel,
    onChange,
    errors,
    data,
    isDisabled,
    handleChange,
    onAutocompleteChange,
    handleTextInputChange,
    submitters,
    submitter,
    flagAddNewSubmitter,
    renderAddCitizen,
    flagMarginValid,
    flagAddNewCost,
    flagMarginValid2,
    costs,
    cost,
    identificationSubmitterEqualsIdentificationCosts,
    identificationCostsEqualsIdentificationUser,
    userGraveSite,
    users,
    costSameAsSubmitter,
    userSameAsCost,
    userSameAsSubmitter,
    identificationSubmitterEqualsIdentificationUser,
    costInfo,
    userInfo,
    firmInfo,
    flag,
    firms,
    firmCost,
    disabled
}) => {
    let renderAddCitizenButton = {
        marginLeft: "289px",
        marginTop: "-114px",
        paddingBottom: "-300px"
    }

    return (
        <form id='user-form'>
            <label className="header-label"> {strings.mainBookOfBuried.form.submitter} </label>
            {((data.municipality && flag == "edit") || (flag == "add") || (data.transporter && flag == "view")) &&
                <Autocomplete
                    id={"submitter"}
                    name={"submitter"}
                    options={submitters}
                    getOptionLabel={option => option.personalNumber}
                    onChange={onAutocompleteChange}
                    renderInput={params => (
                        <TextField
                            {...params}
                            variant="standard"
                            label={strings.mainBookOfBuried.form.identificationNumberSubmitter}
                            margin="normal"
                            style={{ width: 275 }}
                            fullWidth
                            name={"submitter"}
                            onChange={handleTextInputChange}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    )}
                    disabled={isDisabled}
                    value={data.submitter}
                />
            }
            {((!data.municipality && flag == "edit") || (!data.transporter && flag == "view")) &&
                <Autocomplete
                    id={"submitter"}
                    name={"submitter"}
                    options={submitters}
                    getOptionLabel={option => option.personalNumber}
                    onChange={onAutocompleteChange}
                    renderInput={params => (
                        <TextField
                            {...params}
                            variant="standard"
                            label={strings.mainBookOfBuried.form.identificationNumberSubmitter}
                            margin="normal"
                            style={{ width: 275 }}
                            fullWidth
                            name={"submitter"}
                            onChange={handleTextInputChange}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    )}
                    disabled={isDisabled}
                    value={data.submitter}
                />
            }

            {flagAddNewSubmitter == true && flagMarginValid == true &&
                <div className='submit-container'>
                    <Button variant="contained" color="secondary" onClick={renderAddCitizen} style={renderAddCitizenButton}>
                        {strings.mainBookOfBuried.form.addNewSubmitter}
                    </Button>
                </div>
            }

            {flagAddNewSubmitter == true && flagMarginValid == false &&
                <div className='submit-container'>
                    <Button variant="contained" color="secondary" onClick={renderAddCitizen} style={renderAddCitizenButton}>
                        {strings.mainBookOfBuried.form.addNewSubmitter}
                    </Button>
                    <div class="renderAddCitizenButtonNotValidPersonalNumber">
                        {strings.citizen.messages.CITIZEN_PERSONAL_NUMBER_NOT_VALID}
                    </div>
                </div>
            }

            <TextField
                label={strings.mainBookOfBuried.form.nameSurnameSubmitter}
                error={hasError(errors, 'nameSurnameSubmitter')}
                helperText={getError(errors, 'nameSurnameSubmitter')}
                fullWidth
                name='nameSurnameSubmitter'
                onChange={onChange}
                margin="normal"
                defaultValue={submitter.nameSurnameSubmitter}
                value={submitter.nameSurnameSubmitter}
                InputLabelProps={{
                    shrink: true,
                }}
                disabled={true}
            />
            <TextField
                label={strings.mainBookOfBuried.form.addressSubmitter}
                error={hasError(errors, 'addressSubmitter')}
                helperText={getError(errors, 'addressSubmitter')}
                fullWidth
                name='addressSubmitter'
                onChange={onChange}
                margin="normal"
                defaultValue={submitter.addressSubmitter}
                value={submitter.addressSubmitter}
                InputLabelProps={{
                    shrink: true,
                }}
                disabled={true}
            />
            <TextField
                label={strings.mainBookOfBuried.form.municipalitySubmitter}
                error={hasError(errors, 'municipalitySubmitter')}
                helperText={getError(errors, 'municipalitySubmitter')}
                fullWidth
                name='municipalitySubmitter'
                onChange={onChange}
                margin="normal"
                defaultValue={submitter.municipalitySubmitter}
                value={submitter.municipalitySubmitter}
                InputLabelProps={{
                    shrink: true,
                }}
                disabled={true}
            />
            <TextField
                label={strings.mainBookOfBuried.form.telephoneSubmitter}
                error={hasError(errors, 'telephoneSubmitter')}
                helperText={getError(errors, 'telephoneSubmitter')}
                fullWidth
                name='telephoneSubmitter'
                onChange={onChange}
                margin="normal"
                defaultValue={submitter.telephoneSubmitter}
                value={submitter.telephoneSubmitter}
                InputLabelProps={{
                    shrink: true,
                }}
                disabled={true}
            />
            <label className="header-label"> {strings.mainBookOfBuried.form.cost} </label>
            {flag != "view" &&
                <Button
                    variant="contained"
                    color={costSameAsSubmitter ? "default" : "primary"}
                    className="formButton"
                    onClick={identificationSubmitterEqualsIdentificationCosts}
                    disabled={submitter.nameSurnameSubmitter == null ? true : false}
                >
                    {strings.mainBookOfBuried.form.submitter}
                </Button>
            }
            {(data.firmPays == "false" || !data.firmPays) &&
                <Autocomplete
                    id={"cost"}
                    name={"cost"}
                    options={costs}
                    getOptionLabel={option => option.personalNumber}
                    onChange={onAutocompleteChange}
                    renderInput={params => (
                        <TextField
                            {...params}
                            variant="standard"
                            label={strings.mainBookOfBuried.form.identificationNumberCosts}
                            margin="normal"
                            style={{ width: 275 }}
                            fullWidth
                            name={"cost"}
                            onChange={handleTextInputChange}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    )}
                    disabled={isDisabled}
                    value={data.cost ? data.cost : cost}
                />
            }
            {data.firmPays == "true" &&
                <Autocomplete
                    id={"firmCost"}
                    name={"firmCost"}
                    options={firms}
                    getOptionLabel={option => option.name}
                    onChange={onAutocompleteChange}
                    renderInput={params => (
                        <TextField
                            {...params}
                            variant="standard"
                            label={strings.mainBookOfBuried.form.firmCost}
                            margin="normal"
                            style={{ width: 275 }}
                            fullWidth
                            name={"firmCost"}
                            onChange={handleTextInputChange}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    )}
                    disabled={isDisabled}
                    value={data.firmCost ? data.firmCost : firmCost}
                />
            }

            {flagAddNewCost == true && flagMarginValid2 == true &&
                <div className='submit-container'>
                    <Button variant="contained" color="secondary" onClick={renderAddCitizen} style={renderAddCitizenButton}>
                        {strings.mainBookOfBuried.form.addNewCost}
                    </Button>
                </div>
            }

            {flagAddNewCost == true && flagMarginValid2 == false &&
                <div className='submit-container'>
                    <Button variant="contained" color="secondary" onClick={renderAddCitizen} style={renderAddCitizenButton}>
                        {strings.mainBookOfBuried.form.addNewCost}
                    </Button>
                    <div class="renderAddCitizenButtonNotValidPersonalNumber">
                        {strings.citizen.messages.CITIZEN_PERSONAL_NUMBER_NOT_VALID}
                    </div>
                </div>
            }

            <FormControl component="fieldset">
                <FormLabel className="checkboxLabel"> {strings.mainBookOfBuried.form.firmPays} </FormLabel>
                <RadioGroup name="firmPays" value={(data.firmPays == true || data.firmPays == "true") ? "true" : "false"} onChange={handleChange} className="radioGroup">
                    <FormControlLabel value={"true"} disabled={isDisabled} control={<Radio />} label={<span className="formControlLabel">{strings.deceasedReception.form.yes}</span>} />
                    <FormControlLabel value={"false"} disabled={isDisabled} control={<Radio />} label={<span className="formControlLabel">{strings.deceasedReception.form.no}</span>} />
                </RadioGroup>
            </FormControl>
            {data.firmPays == "true" &&
                <TextField
                    label={strings.mainBookOfBuried.form.taxNumberCosts}
                    error={hasError(errors, 'taxNumberCost')}
                    helperText={getError(errors, 'taxNumberCost')}
                    fullWidth
                    name='taxNumberCost'
                    onChange={onChange}
                    margin="normal"
                    defaultValue={firmInfo.taxNumberCost}
                    value={firmInfo.taxNumberCost}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    disabled={true}
                />
            }
            {(data.firmPays == "false" || !data.firmPays) &&
                <React.Fragment>
                    <TextField
                        label={strings.mainBookOfBuried.form.nameSurnameCosts}
                        error={hasError(errors, 'nameSurnameCosts')}
                        helperText={getError(errors, 'nameSurnameCosts')}
                        fullWidth
                        name='nameSurnameCosts'
                        onChange={onChange}
                        margin="normal"
                        defaultValue={costInfo.nameSurnameCosts}
                        value={costInfo.nameSurnameCosts}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        disabled={true}
                    />
                    <TextField
                        label={strings.mainBookOfBuried.form.addressCosts}
                        error={hasError(errors, 'addressCosts')}
                        helperText={getError(errors, 'addressCosts')}
                        fullWidth
                        name='addressCosts'
                        onChange={onChange}
                        margin="normal"
                        defaultValue={costInfo.addressCosts}
                        value={costInfo.addressCosts}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        disabled={true}
                    />
                    <TextField
                        label={strings.mainBookOfBuried.form.municipalityCosts}
                        error={hasError(errors, 'municipalityCosts')}
                        helperText={getError(errors, 'municipalityCosts')}
                        fullWidth
                        name='municipalityCosts'
                        onChange={onChange}
                        margin="normal"
                        defaultValue={costInfo.municipalityCosts}
                        value={costInfo.municipalityCosts}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        disabled={true}
                    />
                    <TextField
                        label={strings.mainBookOfBuried.form.telephoneCosts}
                        error={hasError(errors, 'telephoneCosts')}
                        helperText={getError(errors, 'telephoneCosts')}
                        fullWidth
                        name='telephoneCosts'
                        onChange={onChange}
                        margin="normal"
                        defaultValue={costInfo.telephoneCosts}
                        value={costInfo.telephoneCosts}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        disabled={true}
                    />
                </React.Fragment>
            }
            <label className="header-label"> {strings.mainBookOfBuried.form.grUser} </label>
            {flag != "view" &&
                <Button
                    variant="contained"
                    color={userSameAsSubmitter ? "default" : "primary"}
                    className="formButton"
                    onClick={identificationSubmitterEqualsIdentificationUser}
                    disabled={submitter.nameSurnameSubmitter == null ? true : false || userSameAsCost == true ? true : false}
                >
                    {strings.mainBookOfBuried.form.shorthenedSubmitter}
                </Button>
            }
            {flag != "view" &&
                <Button
                    variant="contained"
                    color={userSameAsCost ? "default" : "primary"}
                    className="formButton"
                    onClick={identificationCostsEqualsIdentificationUser}
                    disabled={costInfo.nameSurnameCosts == null ? true : false || userSameAsSubmitter == true ? true : false || costInfo.nameSurnameCosts == "" ? true : false}
                >
                    {strings.mainBookOfBuried.form.shorthenedCosts}
                </Button>
            }
            <Autocomplete
                id={"userGraveSite"}
                name={"userGraveSite"}
                options={users}
                getOptionLabel={option => option.personalNumber}
                onChange={onAutocompleteChange}
                renderInput={params => (
                    <TextField
                        {...params}
                        variant="standard"
                        label={strings.mainBookOfBuried.form.identificationNumberUser}
                        margin="normal"
                        fullWidth
                        name={"userGraveSite"}
                        onChange={handleTextInputChange}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                )}
                disabled={isDisabled}
                value={data.userGraveSite ? data.userGraveSite : userGraveSite}
            />
            <TextField
                label={strings.mainBookOfBuried.form.nameSurnameUser}
                error={hasError(errors, 'nameSurnameUser')}
                helperText={getError(errors, 'nameSurnameUser')}
                fullWidth
                name='nameSurnameUser'
                onChange={onChange}
                margin="normal"
                defaultValue={userInfo.nameSurnameUser}
                value={userInfo.nameSurnameUser}
                InputLabelProps={{
                    shrink: true,
                }}
                disabled={true}
            />
            <TextField
                label={strings.mainBookOfBuried.form.addressUser}
                error={hasError(errors, 'addressUser')}
                helperText={getError(errors, 'addressUser')}
                fullWidth
                name='addressUser'
                onChange={onChange}
                margin="normal"
                defaultValue={userInfo.addressUser}
                value={userInfo.addressUser}
                InputLabelProps={{
                    shrink: true,
                }}
                disabled={true}
            />
            <TextField
                label={strings.mainBookOfBuried.form.municipalityUser}
                error={hasError(errors, 'municipalityUser')}
                helperText={getError(errors, 'municipalityUser')}
                fullWidth
                name='municipalityUser'
                onChange={onChange}
                margin="normal"
                defaultValue={userInfo.municipalityUser}
                value={userInfo.municipalityUser}
                InputLabelProps={{
                    shrink: true,
                }}
                disabled={true}
            />
            <TextField
                label={strings.mainBookOfBuried.form.telephoneUser}
                error={hasError(errors, 'telephoneUser')}
                helperText={getError(errors, 'telephoneUser')}
                fullWidth
                name='telephoneUser'
                onChange={onChange}
                margin="normal"
                defaultValue={userInfo.telephoneUser}
                value={userInfo.telephoneUser}
                InputLabelProps={{
                    shrink: true,
                }}
                disabled={true}
            />
            {
                !isDisabled &&
                <div className='submit-container'>
                    <Button variant="contained" color="primary" disabled={disabled} onClick={onSubmit}>
                        {strings.mainBookOfBuried.form.ok}
                    </Button>
                    <Button variant="contained" color="secondary" onClick={onCancel}>
                        {strings.mainBookOfBuried.form.cancel}
                    </Button>
                </div>
            }
        </form>
    )
};

export default DeathReportMainBookOfBuriedForm;