import FormComponent from "../../common/FormComponent";
import React from "react";
import { bindActionCreators } from "redux";
import * as Actions from "../../actions/Actions";
import { withRouter } from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import Grid from "@material-ui/core/Grid";
import { Paper } from "@material-ui/core";
import strings from "../../localization";
import Validators from "../../constants/ValidatorTypes";
import { withSnackbar } from "notistack";
import TransporterForm from '../../components/forms/transporter/TransporterForm';
import { getTransporterById, editTransporter } from "../../services/transporter/TransporterService";

class EditTransporter extends FormComponent {

  validationList = {
    // code: [{ type: Validators.REQUIRED }],
    name: [{ type: Validators.REQUIRED }],
    shortenedName: [{ type: Validators.REQUIRED }],
  };

  constructor(props) {
    super(props);

    this.state = {
      data: props.data ? props.data : {},
      errors: {}
    };

    this.props.changeFullScreen(false);
    this.submit = this.submit.bind(this);
  }

  componentDidMount() {

    getTransporterById(this.props.id).then(response => {
      this.setState({
        data: response.data
      });
    });
  }

  submit() {

    if (!this.validate()) {
      return;
    }

    this.showDrawerLoader();

    editTransporter(this.transformRequest(this.state.data)).then(response => {
      if (!response.ok) {
        let messageKey = response.response.data.message;

        this.props.enqueueSnackbar(strings.transporter.messages[messageKey], {
          variant: "error"
        });

        this.handleError(messageKey);

        return;
      }

      this.props.enqueueSnackbar(strings.addCompany.transporterEdited, { variant: 'success' });
      this.props.onFinish(response.data.transporter);

      this.hideDrawerLoader();
    });
  }

  transformRequest(data) {
    return {
      ...data,
      userEmail: this.props.uemail,
      companyId: this.props.cid
    }
  }

  handleError(message) {
    switch (message) {
      // case "TRANSPORTER_WITH_CODE_ALREADY_EXIST":
      //   this.setError("code", strings.transporter.messages.TRANSPORTER_WITH_CODE_ALREADY_EXIST);
      //   break;
      // case "TRANSPORTER_CODE_CANT_BE_NULL":
      //   this.setError("code", strings.transporter.messages.TRANSPORTER_CODE_CANT_BE_NULL);
      //   break;
      case "TRANSPORTER_WITH_NAME_ALREADY_EXIST":
        this.setError("name", strings.transporter.messages.TRANSPORTER_WITH_NAME_ALREADY_EXIST);
        break;

      case "TRANSPORTER_NAME_CANT_BE_NULL":
        this.setError("name", strings.transporter.messages.TRANSPORTER_NAME_CANT_BE_NULL);
        break;
      case "TRANSPORTER_SHORTENED_NAME_CANT_BE_NULL":
        this.setError("shortenedName", strings.transporter.messages.TRANSPORTER_SHORTENED_NAME_CANT_BE_NULL);
        break;
    }
  }

  render() {
    return (
      <Grid id="page" item md={12}>
        <div className="header">
          <h1>{strings.transporter.editPageTitle}</h1>
        </div>

        <Paper className="paper">
          <TransporterForm
            onChange={this.changeData}
            onSubmit={this.submit}
            data={this.state.data}
            errors={this.state.errors}
            onCancel={this.props.onCancel}
          />
        </Paper>

      </Grid>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      changeFullScreen: Actions.changeFullScreen
    },
    dispatch
  );
}

function mapStateToProps({ menuReducers, siteDataReducers }) {
  return { menu: menuReducers, siteData: siteDataReducers };
}

export default withSnackbar(
  withRouter(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(EditTransporter)
  )
);
