import React from 'react';
import strings from "../../../../localization";
import { getError, hasError } from "../../../../functions/Validation";
import { Button, TextField, FormLabel, FormGroup, FormControlLabel, Checkbox, Radio, FormControl, RadioGroup } from "@material-ui/core";
import DatePickerControl from '../../../controls/DatePickerControl';
import Autocomplete from '@material-ui/lab/Autocomplete';

const DeceasedTakeoverRecordForm = ({
  onSubmit,
  onCancel,
  onChange,
  errors,
  data,
  isDisabled,
  handleChange,
  onAutocompleteChange,
  handleTextInputChange,
  deceasedAids,
  transporters,
  principals,
  reasonsOfBrought,
  storages,
  flag
}) => {

  return (

    <form id='user-form'>
      <TextField
        label={strings.deceasedReception.form.identificationNumber}
        error={hasError(errors, 'identificationNumber')}
        helperText={getError(errors, 'identificationNumber')}
        fullWidth
        autoFocus
        name='identificationNumber'
        onChange={onChange}
        margin="normal"
        defaultValue={data.identificationNumber}
        value={data.identificationNumber}
        InputLabelProps={{
          shrink: true,
        }}
        disabled={isDisabled}
      />
      <TextField
        label={strings.deceasedReception.form.equippingOrder}
        error={hasError(errors, 'equippingOrder')}
        helperText={getError(errors, 'equippingOrder')}
        fullWidth
        name='equippingOrder'
        onChange={onChange}
        margin="normal"
        defaultValue={data.equippingOrder}
        value={data.equippingOrder}
        InputLabelProps={{
          shrink: true,
        }}
        disabled={isDisabled}
      />
      <TextField
        label={strings.deceasedReception.form.deceasedNameSurname + "*"}
        error={hasError(errors, 'deceasedNameSurname')}
        helperText={getError(errors, 'deceasedNameSurname')}
        fullWidth
        name='deceasedNameSurname'
        onChange={onChange}
        margin="normal"
        defaultValue={data.deceasedNameSurname}
        value={data.deceasedNameSurname}
        InputLabelProps={{
          shrink: true,
        }}
        disabled={isDisabled}
      />
      <TextField
        label={strings.deceasedReception.form.placeOfDeath}
        error={hasError(errors, 'placeOfDeath')}
        helperText={getError(errors, 'placeOfDeath')}
        fullWidth
        name='placeOfDeath'
        onChange={onChange}
        margin="normal"
        defaultValue={data.placeOfDeath}
        value={data.placeOfDeath}
        InputLabelProps={{
          shrink: true,
        }}
        disabled={isDisabled}
      />
      <DatePickerControl
        label={strings.deceasedReception.form.dateOfDeath}
        error={getError(errors, 'dateOfDeath')}
        hasError={hasError(errors, 'dateOfDeath')}
        helperText={getError(errors, 'dateOfDeath')}
        placeholder={strings.deceasedReception.form.dateOfDeath}
        name='dateOfDeath'
        onChange={onChange}
        date={data.dateOfDeath ? new Date(data.dateOfDeath) : data.dateOfDeath}
        isClearable={true}
        dateFormat='dd/MM/yyyy'
        disabled={isDisabled}
      />
      <FormControl component="fieldset">
        <FormLabel className="checkboxLabel"> {strings.deceasedReception.form.bodyState} </FormLabel>
        <RadioGroup name="bodyState" value={(data.bodyState == true || data.bodyState == "true") ? "true" : "false"} onChange={handleChange} className="radioGroup">
          <FormControlLabel value={"true"} disabled={isDisabled} control={<Radio />} label={<span className="formControlLabel">{strings.deceasedReception.form.bodyStateGood}</span>} />
          <FormControlLabel value={"false"} disabled={isDisabled} control={<Radio />} label={<span className="formControlLabel">{strings.deceasedReception.form.bodyStateBad}</span>} />
        </RadioGroup>
      </FormControl>
      <DatePickerControl
        label={strings.deceasedReception.form.deliveryDateTime}
        error={getError(errors, 'deliveryDateTime')}
        hasError={hasError(errors, 'deliveryDateTime')}
        helperText={getError(errors, 'deliveryDateTime')}
        placeholder={strings.deceasedReception.form.deliveryDateTime}
        name='deliveryDateTime'
        onChange={onChange}
        date={data.deliveryDateTime ? new Date(data.deliveryDateTime) : data.deliveryDateTime}
        isClearable={true}
        dateFormat='dd/MM/yyyy HH:mm'
        showTimeSelect={true}
        disabled={isDisabled}
      />
      <FormControl component="fieldset">
        <FormLabel className="checkboxLabel"> {strings.deceasedReception.form.dressedUp} </FormLabel>
        <RadioGroup name="dressedUp" value={(data.dressedUp == true || data.dressedUp == "true") ? "true" : "false"} onChange={handleChange} className="radioGroup">
          <FormControlLabel value={"true"} disabled={isDisabled} control={<Radio />} label={<span className="formControlLabel">{strings.deceasedReception.form.yes}</span>} />
          <FormControlLabel value={"false"} disabled={isDisabled} control={<Radio />} label={<span className="formControlLabel">{strings.deceasedReception.form.no}</span>} />
        </RadioGroup>
      </FormControl>
      <TextField
        label={strings.deceasedReception.form.preparationPreformedBy}
        error={hasError(errors, 'preparationPreformedBy')}
        helperText={getError(errors, 'preparationPreformedBy')}
        fullWidth
        name='preparationPreformedBy'
        onChange={onChange}
        margin="normal"
        defaultValue={data.preparationPreformedBy}
        value={data.preparationPreformedBy}
        InputLabelProps={{
          shrink: true,
        }}
        disabled={isDisabled}
      />
      <FormControl component="fieldset">
        <FormLabel className="checkboxLabel"> {strings.deceasedReception.form.suitBrought} </FormLabel>
        <RadioGroup name="suitBrought" value={(data.suitBrought == true || data.suitBrought == "true") ? "true" : "false"} onChange={handleChange} className="radioGroup">
          <FormControlLabel value={"true"} disabled={isDisabled} control={<Radio />} label={<span className="formControlLabel">{strings.deceasedReception.form.yes}</span>} />
          <FormControlLabel value={"false"} disabled={isDisabled} control={<Radio />} label={<span className="formControlLabel">{strings.deceasedReception.form.no}</span>} />
        </RadioGroup>
      </FormControl>
      <TextField
        multiline={true}
        rowsMax={5}
        label={strings.deceasedReception.form.noteDeceased}
        error={hasError(errors, 'noteDeceased')}
        helperText={getError(errors, 'noteDeceased')}
        fullWidth
        name='noteDeceased'
        onChange={onChange}
        margin="normal"
        defaultValue={data.noteDeceased}
        value={data.noteDeceased}
        InputLabelProps={{
          shrink: true,
        }}
        disabled={isDisabled}
      />
      <TextField
        multiline={true}
        rowsMax={5}
        label={strings.deceasedReception.form.noteJewelry}
        error={hasError(errors, 'noteJewelry')}
        helperText={getError(errors, 'noteJewelry')}
        fullWidth
        name='noteJewelry'
        onChange={onChange}
        margin="normal"
        defaultValue={data.noteJewelry}
        value={data.noteJewelry}
        InputLabelProps={{
          shrink: true,
        }}
        disabled={isDisabled}
      />

      {(deceasedAids && ((data.deceasedAid && flag == "edit") || (flag == "add") || (data.deceasedAid && flag == "view"))) &&
        <Autocomplete
          id={"deceasedAid"}
          name={"deceasedAid"}
          options={deceasedAids}
          getOptionLabel={option => option.name}
          onChange={onAutocompleteChange}
          renderInput={params => (
            <TextField
              {...params}
              variant="standard"
              label={strings.deceasedReception.form.deceasedAids}
              margin="normal"
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
          disabled={isDisabled}
          value={data.deceasedAid}
        />
      }

      {(deceasedAids && ((!data.deceasedAid && flag == "edit") || (!data.deceasedAid && flag == "view"))) &&
        <Autocomplete
          id={"deceasedAid"}
          name={"deceasedAid"}
          options={deceasedAids}
          getOptionLabel={option => option.name}
          onChange={onAutocompleteChange}
          renderInput={params => (
            <TextField
              {...params}
              variant="standard"
              label={strings.deceasedReception.form.deceasedAids}
              margin="normal"
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
          disabled={isDisabled}
          value={data.deceasedAid}
        />
      }

      {(transporters && ((data.transporter && flag == "edit") || (flag == "add") || (data.transporter && flag == "view"))) &&
        <Autocomplete
          id={"transporter"}
          name={"transporter"}
          options={transporters}
          getOptionLabel={option => option.name}
          onChange={onAutocompleteChange}
          renderInput={params => (
            <TextField
              {...params}
              variant="standard"
              label={strings.deceasedReception.form.transporter}
              margin="normal"
              fullWidth

              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
          disabled={isDisabled}
          value={data.transporter}
        />
      }

      {(transporters && ((!data.transporter && flag == "edit") || (!data.transporter && flag == "view"))) &&
        <Autocomplete
          id={"transporter"}
          name={"transporter"}
          options={transporters}
          getOptionLabel={option => option.name}
          onChange={onAutocompleteChange}
          renderInput={params => (
            <TextField
              {...params}
              variant="standard"
              label={strings.deceasedReception.form.transporter}
              margin="normal"
              fullWidth

              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
          disabled={isDisabled}
          value={data.transporter}
        />
      }

      <TextField
        label={strings.deceasedReception.form.driverNameSurname}
        error={hasError(errors, 'driverNameSurname')}
        helperText={getError(errors, 'driverNameSurname')}
        fullWidth
        name='driverNameSurname'
        onChange={onChange}
        margin="normal"
        defaultValue={data.driverNameSurname}
        value={data.driverNameSurname}
        InputLabelProps={{
          shrink: true,
        }}
        disabled={isDisabled}
      />
      <TextField
        label={strings.deceasedReception.form.registrationPlates}
        error={hasError(errors, 'registrationPlates')}
        helperText={getError(errors, 'registrationPlates')}
        fullWidth
        name='registrationPlates'
        onChange={onChange}
        margin="normal"
        defaultValue={data.registrationPlates}
        value={data.registrationPlates}
        InputLabelProps={{
          shrink: true,
        }}
        disabled={isDisabled}
      />

      {(principals && ((data.principal && flag == "edit") || (flag == "add") || (data.principal && flag == "view"))) &&
        <Autocomplete
          id={"principal"}
          name={"principal"}
          options={principals}
          getOptionLabel={option => option.name}
          onChange={onAutocompleteChange}
          renderInput={params => (
            <TextField
              {...params}
              variant="standard"
              label={strings.deceasedReception.form.principal}
              margin="normal"
              fullWidth


              InputLabelProps={{
                shrink: true
              }}
            />
          )}
          disabled={isDisabled}
          value={data.principal}
        />
      }

      {(principals && ((!data.principal && flag == "edit") || (!data.principal && flag == "view"))) &&
        <Autocomplete
          id={"principal"}
          name={"principal"}
          options={principals}
          getOptionLabel={option => option.name}
          onChange={onAutocompleteChange}
          renderInput={params => (
            <TextField
              {...params}
              variant="standard"
              label={strings.deceasedReception.form.principal}
              margin="normal"
              fullWidth


              InputLabelProps={{
                shrink: true
              }}
            />
          )}
          disabled={isDisabled}
          value={data.principal}
        />
      }

      <TextField
        label={strings.deceasedReception.form.principalAddress}
        error={hasError(errors, 'principalAddress')}
        helperText={getError(errors, 'principalAddress')}
        fullWidth
        name='principalAddress'
        onChange={onChange}
        margin="normal"
        defaultValue={data.principalAddress}
        value={data.principalAddress}
        InputLabelProps={{
          shrink: true,
        }}
        disabled={isDisabled}
      />

      {(reasonsOfBrought && ((data.reasonOfBrought && flag == "edit") || (flag == "add") || (data.reasonOfBrought && flag == "view"))) &&
        <Autocomplete
          id={"reasonOfBrought"}
          name={"reasonOfBrought"}
          options={reasonsOfBrought}
          getOptionLabel={option => option.name}
          onChange={onAutocompleteChange}
          renderInput={params => (
            <TextField
              {...params}
              variant="standard"
              label={strings.deceasedReception.form.reasonOfBrought}
              margin="normal"
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
          disabled={isDisabled}
          value={data.reasonOfBrought}
        />
      }

      {(reasonsOfBrought && ((!data.reasonOfBrought && flag == "edit") || (!data.reasonOfBrought && flag == "view"))) &&
        <Autocomplete
          id={"reasonOfBrought"}
          name={"reasonOfBrought"}
          options={reasonsOfBrought}
          getOptionLabel={option => option.name}
          onChange={onAutocompleteChange}
          renderInput={params => (
            <TextField
              {...params}
              variant="standard"
              label={strings.deceasedReception.form.reasonOfBrought}
              margin="normal"
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
          disabled={isDisabled}
          value={data.reasonOfBrought}
        />
      }

      <FormControl component="fieldset">
        <FormLabel className="checkboxLabel"> {strings.deceasedReception.form.reportedFuneral} </FormLabel>
        <RadioGroup name="reportedFuneral" value={(data.reportedFuneral == true || data.reportedFuneral == "true") ? "true" : "false"} onChange={handleChange} className="radioGroup">
          <FormControlLabel value={"true"} disabled={isDisabled} control={<Radio />} label={<span className="formControlLabel">{strings.deceasedReception.form.reported}</span>} />
          <FormControlLabel value={"false"} disabled={isDisabled} control={<Radio />} label={<span className="formControlLabel">{strings.deceasedReception.form.notReported}</span>} />
        </RadioGroup>
      </FormControl>

      {(storages && ((data.storage && flag == "edit") || (flag == "add") || (data.storage && flag == "view"))) &&
        <Autocomplete
          id={"storage"}
          name={"storage"}
          options={storages}
          getOptionLabel={option => option.name}
          onChange={onAutocompleteChange}
          renderInput={params => (
            <TextField
              {...params}
              variant="standard"
              label={strings.deceasedReception.form.storage}
              margin="normal"
              fullWidth
              InputLabelProps={{
                shrink: true
              }}
            />
          )}
          disabled={isDisabled}
          value={data.storage}
        />
      }

      {(storages && ((!data.storage && flag == "edit") || (!data.storage && flag == "view"))) &&
        <Autocomplete
          id={"storage"}
          name={"storage"}
          options={storages}
          getOptionLabel={option => option.name}
          onChange={onAutocompleteChange}
          renderInput={params => (
            <TextField
              {...params}
              variant="standard"
              label={strings.deceasedReception.form.storage}
              margin="normal"
              fullWidth
              InputLabelProps={{
                shrink: true
              }}
            />
          )}
          disabled={isDisabled}
          value={data.storage}
        />
      }

      {
        !isDisabled &&
        <div className='submit-container'>
          <Button variant="contained" color="primary" onClick={onSubmit}>
            {strings.deceasedReception.form.ok}
          </Button>
          <Button variant="contained" color="secondary" onClick={onCancel}>
            {strings.deceasedReception.form.cancel}
          </Button>
        </div>
      }
    </form>
  )
};

export default DeceasedTakeoverRecordForm;