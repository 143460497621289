import strings from '../localization';
import ValidatorTypes from '../constants/ValidatorTypes';
import { isNumeric, isInt } from "../util/DataValidation";
import { isYear } from '../util/DateUtil';

export function hasError(errors, type) {
  return errors && errors[type] && errors[type].length > 0;
}

export function getError(errors, type) {

  return hasError(errors, type) ? errors[type][0].message : '';
}

export default function getErrorClass(errors, type) {
  return hasError(errors, type) ? 'error' : '';
}

export function validate(formData, validationList) {

  let errors = {};

  for (let propertyKey of Object.keys(validationList)) {

    let fieldErrors = [];

    validationList[propertyKey].forEach((validationItem) => {

      if (validationItem.type === ValidatorTypes.REQUIRED) {
        if (!required(formData[propertyKey])) {

          fieldErrors.push(
            {
              message: strings.validation.RequiredErrorMessage
            });
        }
      }
      else if (validationItem.type === ValidatorTypes.MIN_LENGTH) {
        if (!minLength(formData[propertyKey], validationItem.min)) {
          fieldErrors.push(
            {
              message: `${strings.validation.MinLengthErrorMessage}${validationItem.min}`
            });
        }

      }
      else if (validationItem.type === ValidatorTypes.MAX_LENGTH) {
        if (!maxLength(formData[propertyKey], validationItem.max)) {
          fieldErrors.push(
            {
              message: `${strings.validation.MaxLengthErrorMessage}${validationItem.max}`
            });
        }
      }
      else if (validationItem.type === ValidatorTypes.EMAIL) {
        if (!email(formData[propertyKey])) {
          fieldErrors.push(
            {
              message: strings.validation.EmailErrorMessage
            });
        }
      }
      else if (validationItem.type === ValidatorTypes.PASSWORD) {
        if (!password(formData[propertyKey])) {
          fieldErrors.push(
            {
              message: strings.validation.PasswordErrorMessage
            });
        }
      }
      else if (validationItem.type === ValidatorTypes.NOT_EMPTY_ARRAY) {
        if (!notEmptyArray(formData[propertyKey])) {
          fieldErrors.push(
            {
              message: strings.validation.RequiredErrorMessage
            });
        }
      }
      else if (validationItem.type === ValidatorTypes.SELECTED_OPTION) {

        if (!formData[propertyKey] || formData[propertyKey] == '-1') {
          fieldErrors.push(
            {
              message: strings.validation.RequiredErrorMessage
            });
        }
      }
      else if (validationItem.type === ValidatorTypes.IS_NUMBER) {
        if (formData[propertyKey] == "" || formData[propertyKey] == null) {
          return;
        }
        if (!isNumeric(formData[propertyKey])) {
          fieldErrors.push(
            {
              message: strings.validation.notNumber
            });
        }
      }
      else if (validationItem.type === ValidatorTypes.IS_NEGATIVE_NUMBER) {
        if (!isNumeric(formData[propertyKey])) {
          fieldErrors.push(
            {
              message: strings.validation.notNumber
            });
        }
        if (isNegativeNumber(formData[propertyKey])) {
          fieldErrors.push({
            message: strings.validation.isNegativeNumber
          });
        }
      }

      else if (validationItem.type === ValidatorTypes.IS_VALID_YEAR) {
        if (formData[propertyKey] == "" || formData[propertyKey] == null) {
          return;
        }
        if (!isNumeric(formData[propertyKey])) {
          fieldErrors.push(
            {
              message: strings.validation.notNumber
            });
        }
        if (!isYear(formData[propertyKey])) {
          fieldErrors.push({
            message: strings.validation.yearNotValid
          });
        }
      }



    });

    errors[propertyKey] = fieldErrors;
  }

  return errors;
}

function notEmptyArray(data) {
  return !(!data || data.length === 0);
}

function required(data) {
  return !(!data || data === '');
}

function minLength(data, minLength) {
  return data && data.length >= minLength;
}

function maxLength(data, maxLength) {
  return data && data.length <= maxLength;
}

function email(data) {
  let regexp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regexp.test(data);
}

function password(data) {
  let regexp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{6,}$/;
  return regexp.test(data);
}

export function isFormValid(errors) {
  for (let key in errors) {
    let error = errors[key];
    if (error.length !== 0) {
      return false;
    }
  }
  return true;
}


export function validatePersonalNumber(id) {
  if (id == null) {
    return false;
  }

  let result = true;

  if (id.length != 13) {
    return false;
  }

  if (!isNumeric(id)) {
    return false;
  }

  let day = parseInt(id.substring(0, 2));
  let month = parseInt(id.substring(2, 4));
  let year = parseInt(id.substring(4, 7));
  let regionCode = parseInt(id.substring(7, 9));

  if (!validateMonthInYear(month)) {
    return false;
  }

  if (!validateDayInMonth(year, month, day)) {
    return false;
  }

  if (!(1 <= regionCode && regionCode <= 96)) {
    return false;
  }

  let controlNumber = parseInt(id.substring(12, 13));

  return validateControlNumber(id, controlNumber);
}

function isLeapYear(year) {
  let leapYear = false;

  if (((year % 4 == 0) && (year % 100 != 0)) || (year % 400 == 0)) {
    leapYear = true;
  }

  return leapYear;
}

function validateMonthInYear(month) {
  let isMonthValid = false;

  if (1 <= month && month <= 12) {
    isMonthValid = true;
  }

  return isMonthValid;
}

function validateDayInMonth(year, month, day) {
  let isDayValid = false;

  if (validateMonthInYear(month)) {
    switch (month) {
      case 1:
      case 3:
      case 5:
      case 7:
      case 8:
      case 10:
      case 12:
        if (1 <= day && day <= 31) {
          isDayValid = true;
          break;
        }
        isDayValid = false;
        break;
      case 2:
        if (isLeapYear(year)) {
          if (1 <= day && day <= 29) {
            isDayValid = true;
            break;
          }
        }
        else {
          if (1 <= day && day <= 28) {
            isDayValid = true;
            break;
          }
        }
        isDayValid = false;
        break;

      case 4:
      case 6:
      case 9:
      case 11:
        if (1 <= day && day <= 30) {
          isDayValid = true;
          break;
        }
        isDayValid = false;
        break;
    }
  }

  return isDayValid;
}

export function validateTypedInGraveSite(graveSite) {
  let graveField = graveSite.substring(0, 2);
  if (!isNumeric((graveField))) {
    return false;
  }

  if (graveSite.substring(2, 3) != '/') {
   
    return false;
  }

  let gravePlot = graveSite.substring(3, 5);
  if (!isNumeric((gravePlot))) {
    
    return false;
  }

  if (graveSite.substring(5, 6) != '-') {
  
    return false;
  }

  let placeNumber = graveSite.split('-') != null ? graveSite.split('-')[1] : "";

  if (!isNumeric((placeNumber.substring(0,3)))) {
    
      return false;
    }

  return true;
  }

function validateControlNumber(id, controlNumber) {
  let digit1 = parseInt(id.substring(0, 1));
  let digit2 = parseInt(id.substring(1, 2));
  let digit3 = parseInt(id.substring(2, 3));
  let digit4 = parseInt(id.substring(3, 4));
  let digit5 = parseInt(id.substring(4, 5));
  let digit6 = parseInt(id.substring(5, 6));
  let digit7 = parseInt(id.substring(6, 7));
  let digit8 = parseInt(id.substring(7, 8));
  let digit9 = parseInt(id.substring(8, 9));
  let digit10 = parseInt(id.substring(9, 10));
  let digit11 = parseInt(id.substring(10, 11));
  let digit12 = parseInt(id.substring(11, 12));

  let calculatedControlNumber = 11 - ((7 * (digit1 + digit7) + 6 * (digit2 + digit8) + 5 * (digit3 + digit9) +
    4 * (digit4 + digit10) + 3 * (digit5 + digit11) + 2 * (digit6 + digit12)) % 11);


  if (calculatedControlNumber > 9) {
    calculatedControlNumber = 0;
  }

  return controlNumber == calculatedControlNumber;
}

export function isNegativeNumber(number) {
  if (number < 0) {
    return true;
  }
  return false;
}

export function getDateFromIdentificationNumber(id) {
  let day = id.substr(0, 2);
  let month = id.substr(2, 2);
  let year = "";
  let date = "";

  if (id.substr(4, 3) <= 999 && id.substr(4, 3) >= 200) {
    year = '1' + id.substr(4, 3);
  }
  else {
    year = '2' + id.substr(4, 3);
  }

  date = day + '/' + month + '/' + year;

  return date;
}

export function getDateFromRenewalPaymentSlipAndDate(paymentSlipAndDate) {
  return paymentSlipAndDate.substr(9, 10);
}

export function validateDateFromRenewalPaymentSlipAndDate(paymentSlipAndDate) {
  let date = getDateFromRenewalPaymentSlipAndDate(paymentSlipAndDate);

  let day = parseInt(date.substr(0, 2))
  let month = parseInt(date.substr(3, 2))
  let year = parseInt(date.substr(6, 4))

  if (!validateMonthInYear(month)) {
    return false;
  }

  if (!validateDayInMonth(year, month, day)) {
    return false;
  }

  if (year < 1000 || year > 9999) {

    return false;
  }

  return true;
}

