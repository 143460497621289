import { withSnackbar } from 'notistack';
import React from 'react';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import connect from 'react-redux/es/connect/connect';
import TablePage from '../../common/TablePage';
import * as Actions from "../../actions/Actions";
import strings from '../../localization';
import { TableFooter, TableRow, TableBody, TableCell, TableHead, Menu, ListItemIcon, ListItemText } from "@material-ui/core";
import IconButton from '@material-ui/core/IconButton';
import MoreVert from '@material-ui/icons/MoreVert';
import { MenuItem } from "@material-ui/core";
import AddIcon from '@material-ui/icons/Add';
import { getEditedRenewalFindTableAll } from '../../services/renewalEdited/RenewalEditedService';
import AddRenewalEdited from '../renewalEdited/AddRenewalEdited';
import { getMainBookOfBuriedById } from "../../services/mainBookOfBuried/MainBookOfBuriedService";


class RenewalEditedList extends TablePage {
    tableDescription = [
        { key: 'graveyard', label: strings.renewalList.graveyard, sort: 'graveyard' },
        { key: 'graveSite', label: strings.renewalList.graveSiteCode, sort: 'graveSite' },
        { key: 'deceasedNameSurname', label: strings.renewalList.deceasedNameSurname, sort: 'deceasedNameSurname' },
        { key: 'submitterNameSurname', label: strings.renewalList.submitterNameSurname, sort: 'submitterNameSurname' },
        { key: 'expiryDate', label: strings.renewalList.expiryDate, transform: 'renderColumnDate', sort: 'expiryDate' },
    ];

    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            showSearch: false,
            showAdd: false,
            responseData: -1,
            flagFirstTimeRender: false,
        };

        this.state.sortBy = "dateCreated,DESC";

    }

    fetchData() {
        if (this.state.flagFirstTimeRender) {
            let data = {
                page: 0,
                size: 100,
                graveyardCode: this.props.graveyardCode != null ? this.props.graveyardCode : "",
                graveSiteId: this.props.graveSiteId != null ? this.props.graveSiteId : "",
                companyId: this.state.companyId,
                userId: this.props.auth.user.id,
            };

            getEditedRenewalFindTableAll(data).then(response => {
                this.setState({
                    showTable: true,
                    responseData: response.data.content,
                }, () => {
                    this.setState({
                        tableData: this.state.responseData,
                        total: this.state.responseData.length,
                    });

                });
            });


        }
        else {
            this.setState({
                tableData: this.props.data,
                total: this.props.data.length,
                flagFirstTimeRender: 1,
            });
        }



    }

    usePermissions(){
        let data = {
          userId: this.props.auth.user.id,
          category: "Обнове"
        }
        this.getPermissionsS(data);
        this.showAdd(this.props.showAdd);
        this.showRemove(this.props.remove);
        this.showEdit(this.props.showEdit);
        this.showView(this.props.showView);
        this.removedItemsShow(this.props.removedItemsShow);
    
      }
    
      componentDidMount() {
        this.usePermissions()
        this.setState({
            companyId: this.props.auth.user.company.id,
        });

    }

    componentWillReceiveProps(nextProps) {
        strings.setLanguage(nextProps.auth.user.language);
        this.tableDescription = [
            { key: 'graveyard', label: strings.renewalList.graveyard, sort: 'graveyard' },
            { key: 'graveSite', label: strings.renewalList.graveSiteCode, sort: 'graveSite' },
            { key: 'deceasedNameSurname', label: strings.renewalList.deceasedNameSurname, sort: 'deceasedNameSurname' },
            { key: 'submitterNameSurname', label: strings.renewalList.submitterNameSurname, sort: 'submitterNameSurname' },
            { key: 'expiryDate', label: strings.renewalList.expiryDate, transform: 'renderColumnDate', sort: 'expiryDate' },
        ]
    }

    getPageHeader() {
        return <h2>{strings.renewalList.deceasedAndSubmittersList}</h2>;
    }

    onFinish(data, fetch) {
        this.props.onEditCompleted();
    }

    renderAddContent() {
        return <AddRenewalEdited
            elementIndex={this.props.data.findIndex(x => x.id == this.state.selectedItem.id)}
            id={this.state.selectedItem.id}
            cid={this.state.companyId}
            isDisabled={false}
            uemail={this.props.auth.user.email}
            onCancel={this.onCancel}
            onFinish={this.onFinish}
            flag={"add"}
            searchTypeFlag={this.props.obj.renewalType}
            data={this.props.data}
            selectedRenewalTypeKind={this.props.selectedRenewalTypeKind}
            selectedYear={this.props.selectedYear}
            hideTableShowSearch={this.props.hideTableShowSearch}
        />

    }

    isRowSelected(item) {
        return this.state.selectedItem && item.indicator === this.state.selectedItem.indicator && item.id === this.state.selectedItem.id;
    }

    renderRowMenu(index, item) {

        let ariaOwns = 'action-menu-' + index;

        return (
            <TableCell>
                <IconButton
                    size='small'
                    aria-owns={this.state.anchorEl ? ariaOwns : undefined}
                    aria-haspopup="true"
                    onClick={(event) => this.handleMenuClick(event, ariaOwns)}
                >
                    <MoreVert />
                </IconButton>
                {
                    ariaOwns === this.state.ariaOwns &&
                    <Menu
                        id={ariaOwns}
                        anchorEl={this.state.anchorEl}
                        open={Boolean(this.state.anchorEl)}
                        onClose={() => this.handleMenuClose()}
                    >
                        <MenuItem onClick={() => this.handleMenuAdd(item)}>
                            <ListItemIcon>
                                <AddIcon />
                            </ListItemIcon>
                            <ListItemText primary={strings.renewalList.addNewRenewal} />
                        </MenuItem>
                    </Menu>
                }

            </TableCell>
        );
    }



    renderTableRow(data = []) {

        let result = [];

        for (let item of data) {

            let className = 'table-row-' + result.length % 2;

            if (this.isRowSelected(item)) {
                className += ' selected';
            }

            result.push(
                <TableRow key={'table-row-' + result.length} className={className} onClick={() => this.selectRow(item)}>
                    {this.renderRowMenu(result.length, item)}
                    {this.renderTableRowData(item)}
                </TableRow>
            )
        }

        return (
            <TableBody>
                {result}
            </TableBody>
        );
    }

    renderTableHeader() {

        let result = [];

        for (let item of this.tableDescription) {

            result.push(
                <TableCell key={'table-header-' + result.length}>
                    <div className='container-horizontal'>
                        <div className='container-horizontal-label'>
                            {item.label}
                        </div>
                    </div>
                </TableCell>
            )
        }

        return (
            <TableHead className='table-header'>
                <TableRow>
                    <TableCell>
                        {strings.table.actions}
                    </TableCell>
                    {result}
                </TableRow>
            </TableHead>
        );
    }

    renderTableFooter() {

        return (
            <TableFooter>
                <TableRow>

                </TableRow>
            </TableFooter>
        );
    }


}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        changeFullScreen: Actions.changeFullScreen
    }, dispatch);
}

function mapStateToProps({ menuReducers, authReducers, siteDataReducers }) {
    return { menu: menuReducers, auth: authReducers, siteData: siteDataReducers, };
}

export default withSnackbar(withRouter(connect(mapStateToProps, mapDispatchToProps)(RenewalEditedList)));
