import React from 'react'
import { bindActionCreators } from "redux";
import * as Actions from "../../actions/Actions";
import { withRouter } from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import Grid from '@material-ui/core/Grid';
import { Paper } from "@material-ui/core";
import strings from "../../localization";
import Validators from "../../constants/ValidatorTypes";
import FormComponent from "../../common/FormComponent";
import { withSnackbar } from "notistack";
import { addPricelist } from '../../services/pricelist/PricelistService';
import PricelistForm from '../../components/forms/pricelist/PricelistForm';


class AddState extends FormComponent {

  validationList = {
    // code: [{ type: Validators.REQUIRED }],
    name: [{ type: Validators.REQUIRED }],
    price: [{ type: Validators.REQUIRED, type: Validators.IS_NEGATIVE_NUMBER }],
  };

  constructor(props) {
    super(props);

    this.state = {
      data: props.data ? props.data : {},
      errors: {}
    };

    this.props.changeFullScreen(false);

    this.submit = this.submit.bind(this);

  }

  submit() {

    if (!this.validate()) {
      return;
    }

    this.showDrawerLoader();

    addPricelist(this.transformRequest(this.state.data), this.props.cid).then(response => {
      if (!response.ok) {
        let messageKey = response.response.data.message;

        this.props.enqueueSnackbar(strings.pricelist.messages[messageKey], {
          variant: "error"
        });

        this.handleError(messageKey);

        return;
      }

      this.props.enqueueSnackbar(strings.addCompany.pricelistAdded, { variant: 'success' });
      this.props.onFinish(response.data.pricelist);

      this.hideDrawerLoader();
    });
  }

  transformRequest(data) {
    return {
      ...data,
      userEmail: this.props.uemail,
    }
  }

  handleError(message) {
    switch (message) {
      // case "PRICELIST_WITH_CODE_ALREADY_EXIST":
      //   this.setError("code", strings.pricelist.messages.PRICELIST_WITH_CODE_ALREADY_EXIST);
      //   break;
      // case "PRICELIST_CODE_CANT_BE_NULL":
      //   this.setError("code", strings.pricelist.messages.PRICELIST_CODE_CANT_BE_NULL);
      //   break;

      case "PRICELIST_WITH_NAME_ALREADY_EXIST":
        this.setError("name", strings.pricelist.messages.PRICELIST_WITH_NAME_ALREADY_EXIST);
        break;
      case "PRICELIST_NAME_CANT_BE_NULL":
        this.setError("name", strings.pricelist.messages.PRICELIST_NAME_CANT_BE_NULL);
        break;
      case "PRICELIST_PRICE_CANT_BE_NULL":
        this.setError("price", strings.pricelist.messages.PRICELIST_PRICE_CANT_BE_NULL);
        break;
    }
  }

  render() {
    return (
      <Grid id='page' item md={12}>

        <div className='header'>
          <h1>{strings.pricelist.pageTitle}</h1>
        </div>

        <Paper className='paper'>
          <PricelistForm onChange={this.changeData} onSubmit={this.submit}
            data={this.state.data} errors={this.state.errors} onCancel={this.props.onCancel} />
        </Paper>

      </Grid>

    );
  }
}


function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    changeFullScreen: Actions.changeFullScreen
  }, dispatch);
}

function mapStateToProps({ menuReducers, siteDataReducers }) {
  return { menu: menuReducers, siteData: siteDataReducers };
}

export default withSnackbar(withRouter(connect(mapStateToProps, mapDispatchToProps)(AddState)));