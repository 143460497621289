import {request} from "../../base/HTTP";
import HttpMethod from "../../constants/HttpMethod";

export async function getTombRents(data) {
    return await request('/api/tombrent/search', data);
}

export async function addTombRent(data, cid) {
    return await request('/api/tombrent/' + cid, data, HttpMethod.POST);
}

export function getTombRentById(id) {
    return  request("api/tombrent/" + id , {}, HttpMethod.GET);
}

export async function editTombRent(data) {
    return await request("api/tombrent/" + data.id, data, HttpMethod.PUT);
}

export async function deleteTombRent(id, uid) {
    return await request('/api/tombrent/' + id + '/' + uid, {} , HttpMethod.DELETE);
}

export async function stornoTombRent(id, uid) {
    return await request('/api/tombrent/cancel/' + id + '/' + uid, {} , HttpMethod.PUT);
}

export async function undoStornoTombRent(id, uid) {
    return await request('/api/tombrent/undoCancel/' + id + '/' + uid, {} , HttpMethod.PUT);
}

export async function getDeletedTombRent(data) {
    return await request('/api/tombrent/deleted', data);
}

export async function restoreTombRent(data) {
    return await request("api/tombrent/restore/" + data.id, data, HttpMethod.PUT);
}

export async function getTombRentByGraveSite(id) {
    return await request("api/tombrent/gravesite/" + id, HttpMethod.GET)
}