import {request} from "../../base/HTTP";
import HttpMethod from "../../constants/HttpMethod";
import ResponseTypes from "../../constants/ResponseTypes";

export async function getScheduledFunerals(data) {
    return await request('/api/scheduledFunerals/search', data);
}

export async function addScheduledFuneral(data, cid) {
    return await request('/api/scheduledFunerals/' + cid, data, HttpMethod.POST);
}

export function getScheduledFuneralById(id) {
    return  request("api/scheduledFunerals/" + id , {}, HttpMethod.GET);
}

export async function editScheduledFuneral(data) {
    return await request("api/scheduledFunerals/" + data.id, data, HttpMethod.PUT);
}

export function getAutocompleteObjectsById(data) {
    return  request("api/scheduledFunerals/objects/" + data.id , data, HttpMethod.PUT);
}

export async function deleteScheduledFuneral(id, uid) {
    return await request('/api/scheduledFunerals/' + id + '/' + uid, {} , HttpMethod.DELETE);
}

export async function restoreScheduledFuneral(data) {
    return await request("api/scheduledFunerals/restore/" + data.id, data, HttpMethod.PUT);
}

export function getScheduledFuneralsByAppointmentDateOnCityGraveyard(data) {
    return request('/api/scheduledFunerals/scheduled-funerals-by-appointment-date-city-graveyard', data);
}

export function getScheduledFuneralsByAppointmentDateOnExternalGraveyards(data) {
    return request('/api/scheduledFunerals/scheduled-funerals-by-appointment-date-external-graveyards', data);
}

export async function generateScheduledFuneralsCityGraveyardPdf(data) {
    return await request('/api/scheduledFunerals/scheduled-funerals-city-graveyard/generate-pdf', data, HttpMethod.POST, { responseType: ResponseTypes.BLOB });
}

export async function generateScheduledFuneralsExternalGraveyardsPdf(data) {
    return await request('/api/scheduledFunerals/scheduled-funerals-external-graveyards/generate-pdf', data, HttpMethod.POST, { responseType: ResponseTypes.BLOB });
}

export async function getDeletedScheduledFunerals(data) {
    return await request('/api/scheduledFunerals/search/deleted', data);
}

export async function generateDeathReportPdf(data) {
    return await request('/api/scheduledFunerals/death-report/generate-pdf', data, HttpMethod.POST, { responseType: ResponseTypes.BLOB });
}