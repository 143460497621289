import React from 'react';
import strings from "../../../localization";
import { getError, hasError } from "../../../functions/Validation";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Button, TextField, FormControlLabel, Checkbox, FormControl, FormLabel, RadioGroup, Radio, Select, MenuItem  } from "@material-ui/core";
import DatePickerControl from '../../controls/DatePickerControl';

const CremationRequestForm = ({
  onSubmit,
  onCancel,
  onChange,
  errors,
  data,
  isDisabled,
  graveyards,
  graveSites,
  handleChange,
  onAutocompleteChange,
  handleTextInputChange,
  flag,
  typedInGraveSite,
  urns,
  municipalities,
  causesOfDeath,
  occupations,
  submitters,
  submitter,
  keyPress,
  keyPressBackspace,
  disabled,
  urnStorageType,
  urnStorageTypes,
  handleUrnStorageCheckboxChange,
  attachedDocument,
  attachedDocuments,
  handleAttachedDocumentsCheckboxChange,
  proofOfWish,
  proofOfWishes,
  handleProofOfWishCheckboxChange,
}) => {

  return (

    <form id='user-form'>
        <TextField
            label={strings.cremationScheduling.form.deceasedNameSurname}
            error={hasError(errors, 'deceasedNameSurname')}
            helperText={getError(errors, 'deceasedNameSurname')}
            fullWidth
            name='deceasedNameSurname'
            onChange={onChange}
            margin="normal"
            defaultValue={data.deceasedNameSurname}
            value={data.deceasedNameSurname}
            InputLabelProps={{
            shrink: true,
            }}
            disabled={isDisabled}
        />

        <TextField
            label={strings.cremationScheduling.form.fathersName}
            error={hasError(errors, 'fathersName')}
            helperText={getError(errors, 'fathersName')}
            fullWidth
            name='fathersName'
            onChange={onChange}
            margin="normal"
            defaultValue={data.fathersName}
            value={data.fathersName}
            InputLabelProps={{
            shrink: true,
            }}
            disabled={isDisabled}
        />

        <DatePickerControl
            label={strings.cremationScheduling.form.dateOfBirth}
            error={getError(errors, 'dateOfBirth')}
            hasError={hasError(errors, 'dateOfBirth')}
            helperText={getError(errors, 'dateOfBirth')}
            placeholder={strings.cremationScheduling.form.dateOfBirth}
            name='dateOfBirth'
            onChange={onChange}
            date={data.dateOfBirth ? new Date(data.dateOfBirth) : data.dateOfBirth}
            isClearable={true}
            dateFormat='dd/MM/yyyy'
            disabled={isDisabled}
        />      

        {((data.municipalityOfBirth && flag == "edit") || (flag == "add") || data.municipalityOfBirth && (flag == "view")) &&
            <Autocomplete
                id={"municipalityOfBirth"}
                name={"municipalityOfBirth"}
                options={municipalities}
                getOptionLabel={option => `${option.city} ${option.zip}`}
                onChange={onAutocompleteChange}
                renderInput={params => (
                    <TextField
                        {...params}
                        variant="standard"
                        label={strings.cremationScheduling.form.municipalityOfBirth}
                        margin="normal"
                        fullWidth
                        name={"municipalityOfBirth"}
                        onChange={handleTextInputChange}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                )}
                disabled={isDisabled}
                value={data.municipalityOfBirth}
            />
        }

        {((!data.municipalityOfBirth && flag == "edit") || (!data.municipalityOfBirth && (flag == "view")))&&
            <Autocomplete
                id={"municipalityOfBirth"}
                name={"municipalityOfBirth"}
                options={municipalities}
                getOptionLabel={option => `${option.city} ${option.zip}`}
                onChange={onAutocompleteChange}
                renderInput={params => (
                    <TextField
                        {...params}
                        variant="standard"
                        label={strings.cremationScheduling.form.municipalityOfBirth}
                        margin="normal"
                        fullWidth
                        name={"municipalityOfBirth"}
                        onChange={handleTextInputChange}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                )}
                disabled={isDisabled}
                value={data.municipalityOfBirth}
            />
        }  

        {((data.occupation && flag == "edit") || (flag == "add") || (data.occupation && flag == "view")) &&
            <Autocomplete
                id={"occupation"}
                name={"occupation"}
                options={occupations}
                getOptionLabel={option => option.name}
                onChange={onAutocompleteChange}
                renderInput={params => (
                    <TextField
                        {...params}
                        variant="standard"
                        label={strings.cremationScheduling.form.occupation}
                        margin="normal"
                        fullWidth
                        name={"occupation"}
                        onChange={handleTextInputChange}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                )}
                disabled={isDisabled}
                value={data.occupation}
            />
        }

        {((!data.occupation && flag == "edit") || (!data.occupation && flag == "view")) &&
            <Autocomplete
                id={"occupation"}
                name={"occupation"}
                options={occupations}
                getOptionLabel={option => option.name}
                onChange={onAutocompleteChange}
                renderInput={params => (
                    <TextField
                        {...params}
                        variant="standard"
                        label={strings.cremationScheduling.form.occupation}
                        margin="normal"
                        fullWidth
                        name={"occupation"}
                        onChange={handleTextInputChange}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                )}
                disabled={isDisabled}
                value={data.occupation}
            />
        }

        {((data.municipalityOfResidence && flag == "edit") || (flag == "add") || (data.municipalityOfResidence && flag == "view")) &&
            <Autocomplete
                id={"municipalityOfResidence"}
                name={"municipalityOfResidence"}
                options={municipalities}
                getOptionLabel={option => `${option.city} ${option.zip}`}
                onChange={onAutocompleteChange}
                renderInput={params => (
                    <TextField
                        {...params}
                        variant="standard"
                        label={strings.cremationScheduling.form.municipalityOfResidence}
                        margin="normal"
                        fullWidth
                        name={"municipalityOfResidence"}
                        onChange={handleTextInputChange}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                )}
                disabled={isDisabled}
                value={data.municipalityOfResidence}
            />
        }

        {((!data.municipalityOfResidence && flag == "edit") || (!data.municipalityOfResidence && flag == "view")) &&
            <Autocomplete
                id={"municipalityOfResidence"}
                name={"municipalityOfResidence"}
                options={municipalities}
                getOptionLabel={option => `${option.city} ${option.zip}`}
                onChange={onAutocompleteChange}
                renderInput={params => (
                    <TextField
                        {...params}
                        variant="standard"
                        label={strings.cremationScheduling.form.municipalityOfResidence}
                        margin="normal"
                        fullWidth
                        name={"municipalityOfResidence"}
                        onChange={handleTextInputChange}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                )}
                disabled={isDisabled}
                value={data.municipalityOfResidence}
            />
        }

        <TextField
            label={strings.cremationScheduling.form.lastAddressOfResidence}
            error={hasError(errors, 'lastAddressOfResidence')}
            helperText={getError(errors, 'lastAddressOfResidence')}
            fullWidth
            name='lastAddressOfResidence'
            onChange={onChange}
            margin="normal"
            defaultValue={data.lastAddressOfResidence}
            value={data.lastAddressOfResidence}
            InputLabelProps={{
                shrink: true,
            }}
            disabled={isDisabled}
        />

        {((data.causeOfDeath && flag == "edit") || (flag == "add") || (data.causeOfDeath && flag == "view")) &&
            <Autocomplete
                id={"causeOfDeath"}
                name={"causeOfDeath"}
                options={causesOfDeath}
                getOptionLabel={option => option.name}
                onChange={onAutocompleteChange}
                renderInput={params => (
                    <TextField
                        {...params}
                        variant="standard"
                        label={strings.cremationScheduling.form.causeOfDeath}
                        margin="normal"
                        fullWidth
                        name={"causeOfDeath"}
                        onChange={handleTextInputChange}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                )}
                disabled={isDisabled}
                value={data.causeOfDeath}
            />
        }

        {((!data.causeOfDeath && flag == "edit") || (!data.causeOfDeath && flag == "view")) &&
            <Autocomplete
                id={"causeOfDeath"}
                name={"causeOfDeath"}
                options={causesOfDeath}
                getOptionLabel={option => option.name}
                onChange={onAutocompleteChange}
                renderInput={params => (
                    <TextField
                        {...params}
                        variant="standard"
                        label={strings.cremationScheduling.form.causeOfDeath}
                        margin="normal"
                        fullWidth
                        name={"causeOfDeath"}
                        onChange={handleTextInputChange}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                )}
                disabled={isDisabled}
                value={data.causeOfDeath}
            />
        }

        {((data.municipalityOfDeath && flag == "edit") || (flag == "add") || (data.municipalityOfDeath && flag == "view")) &&
            <Autocomplete
                id={"municipalityOfDeath"}
                name={"municipalityOfDeath"}
                options={municipalities}
                getOptionLabel={option => `${option.city} ${option.zip}`}
                onChange={onAutocompleteChange}
                renderInput={params => (
                    <TextField
                        {...params}
                        variant="standard"
                        label={strings.cremationScheduling.form.municipalityOfDeath}
                        margin="normal"
                        fullWidth
                        name={"municipalityOfDeath"}
                        onChange={handleTextInputChange}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                )}
                disabled={isDisabled}
                value={data.municipalityOfDeath}
            />
        }
        {((!data.municipalityOfDeath && flag == "edit") || (!data.municipalityOfDeath && flag == "view")) &&
            <Autocomplete
                id={"municipalityOfDeath"}
                name={"municipalityOfDeath"}
                options={municipalities}
                getOptionLabel={option => `${option.city} ${option.zip}`}
                onChange={onAutocompleteChange}
                renderInput={params => (
                    <TextField
                        {...params}
                        variant="standard"
                        label={strings.cremationScheduling.form.municipalityOfDeath}
                        margin="normal"
                        fullWidth
                        name={"municipalityOfDeath"}
                        onChange={handleTextInputChange}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                )}
                disabled={isDisabled}
                value={data.municipalityOfDeath}
            />
        } 

        <DatePickerControl
            label={strings.cremationScheduling.form.dateTimeOfDeath}
            error={getError(errors, 'dateTimeOfDeath')}
            hasError={hasError(errors, 'dateTimeOfDeath')}
            helperText={getError(errors, 'dateTimeOfDeath')}
            placeholder={strings.cremationScheduling.form.dateTimeOfDeath}
            name='dateTimeOfDeath'
            onChange={onChange}
            date={data.dateTimeOfDeath ? new Date(data.dateTimeOfDeath) : data.dateTimeOfDeath}
            isClearable={true}
            dateFormat='dd/MM/yyyy HH:mm'
            showTimeSelect={true}
            disabled={isDisabled}
        />

        <Autocomplete
            id={"urn"}
            name={"urn"}
            options={urns}
            getOptionLabel={option => option.name}
            onChange={onAutocompleteChange}
            renderInput={params => (
                <TextField
                    {...params}
                    variant="standard"
                    label={strings.cremationScheduling.form.urn}
                    error={hasError(errors, 'urn')}
                    helperText={getError(errors, 'urn')}
                    margin="normal"
                    fullWidth
                    name={"urn"}
                    onChange={handleTextInputChange}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            )}
            disabled={isDisabled}
            value={data.urn}
        />

        <div>
            <FormControl component="fieldset">
                <FormLabel className="checkboxLabel"> {strings.cremationScheduling.form.hasTombstone} </FormLabel>
                <RadioGroup name="hasTombstone" value={(data.hasTombstone == true || data.hasTombstone == "true") ? "true" : "false"} onChange={handleChange} className="radioGroup">
                    <FormControlLabel value={"true"} disabled={isDisabled} control={<Radio />} label={<span className="formControlLabel">{strings.cremationScheduling.form.hasTombstone}</span>} />
                    <FormControlLabel value={"false"} disabled={isDisabled} control={<Radio />} label={<span className="formControlLabel">{strings.cremationScheduling.form.noTombstone}</span>} />
                </RadioGroup>
            </FormControl>
        </div>

        {(data.hasTombstone == "true" || data.hasTombstone == true) &&
            <div>
                <FormControl component="fieldset">
                    <FormLabel className="checkboxLabel"> {strings.cremationScheduling.form.burnTombstone} </FormLabel>
                    <RadioGroup name="burnTombstone" value={(data.burnTombstone == true || data.burnTombstone == "true") ? "true" : "false"} onChange={handleChange} className="radioGroup">
                        <FormControlLabel value={"true"} disabled={isDisabled} control={<Radio />} label={<span className="formControlLabel">{strings.cremationScheduling.form.yes}</span>} />
                        <FormControlLabel value={"false"} disabled={isDisabled} control={<Radio />} label={<span className="formControlLabel">{strings.cremationScheduling.form.no}</span>} />
                    </RadioGroup>
                </FormControl>
            </div>
        }

        <div>
            <FormControl component="fieldset">
                <FormLabel className="checkboxLabel"> {strings.cremationScheduling.form.expressCremation} </FormLabel>
                <RadioGroup name="expressCremation" value={(data.expressCremation == true || data.expressCremation == "true") ? "true" : "false"} onChange={handleChange} className="radioGroup">
                    <FormControlLabel value={"true"} disabled={isDisabled} control={<Radio />} label={<span className="formControlLabel">{strings.cremationScheduling.form.yes}</span>} />
                    <FormControlLabel value={"false"} disabled={isDisabled} control={<Radio />} label={<span className="formControlLabel">{strings.cremationScheduling.form.no}</span>} />
                </RadioGroup>
            </FormControl>
        </div>

        <label className="checkboxLabel">{strings.cremationScheduling.form.urnStorageType}</label>
        <div>
            {urnStorageTypes.map(type => (
                <FormControlLabel
                    key={type.id}
                    control={
                    <Checkbox
                        checked={urnStorageType && urnStorageType.id === type.id}
                        onChange={handleUrnStorageCheckboxChange(type.name, type.id, type.value)}
                        disabled={isDisabled}
                    />
                    }
                    label={<span className="formControlLabel">{type.name}</span>}
                    style={{fontSize: '10px'}}
                />
            ))}

            {urnStorageTypes.some(item => item?.id === urnStorageType?.id) && urnStorageType?.name === strings.cremationScheduling.other && (
                <div>
                    <React.Fragment>
                    <TextField
                        label={strings.cremationScheduling.other + " " + strings.cremationScheduling.otherComment}
                        error={hasError(errors, 'urnStorageTypeNote')}
                        helperText={getError(errors, 'urnStorageTypeNote')}
                        fullWidth
                        name='urnStorageTypeNote'
                        onChange={onChange}
                        margin="normal"
                        defaultValue={data.urnStorageTypeNote}
                        value={data.urnStorageTypeNote}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        disabled={isDisabled}
                    />
                </React.Fragment>                        
                </div>
            )}
        </div>

        <Autocomplete
            id={"graveyard"}
            name={"graveyard"}
            options={graveyards}
            getOptionLabel={option => option.name}
            onChange={onAutocompleteChange}
            renderInput={params => (
                <TextField
                    {...params}
                    variant="standard"
                    label={strings.cremationScheduling.form.graveyard}
                    error={hasError(errors, 'graveyard')}
                    helperText={getError(errors, 'graveyard')}
                    margin="normal"
                    fullWidth
                    name={"graveyard"}
                    onChange={handleTextInputChange}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            )}
            disabled={isDisabled}
            value={data.graveyard}
        />

        {(data.graveyard != undefined && data.graveyard != null) &&
            <Autocomplete
                noOptionsText={(typedInGraveSite != undefined ? strings.cremationScheduling.form.create + typedInGraveSite : "")}
                onKeyPress={keyPress}
                id={"graveSite"}
                name={"graveSite"}
                options={graveSites}
                getOptionLabel={option => option.code}
                onChange={onAutocompleteChange}
                renderInput={params => (
                    <TextField
                        {...params}
                        variant="standard"
                        label={strings.cremationScheduling.form.graveSite}
                        error={hasError(errors, 'graveSite')}
                        helperText={getError(errors, 'graveSite')}
                        margin="normal"
                        fullWidth
                        name={"graveSite"}
                        onChange={handleTextInputChange}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                )}
                disabled={isDisabled}
                value={data.graveSite}
                disableClearable={true}
                onKeyDown={keyPressBackspace}
            />
        }

        {(data.graveyard == undefined || data.graveyard == null) &&
            <Autocomplete
                id={"graveSite"}
                name={"graveSite"}
                options={graveSites}
                getOptionLabel={option => option.code}
                onChange={onAutocompleteChange}
                renderInput={params => (
                    <TextField
                        {...params}
                        variant="standard"
                        label={strings.cremationScheduling.form.graveSite}
                        error={hasError(errors, 'graveSite')}
                        helperText={getError(errors, 'graveSite')}
                        margin="normal"
                        fullWidth
                        name={"graveSite"}
                        onChange={handleTextInputChange}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                )}
                disabled={true}
                value={null}
                disableClearable={true}
            />
        }

        <TextField
            label={strings.cremationScheduling.form.otherGraveSiteNote}
            error={hasError(errors, 'otherGraveSiteNote')}
            helperText={getError(errors, 'otherGraveSiteNote')}
            fullWidth
            name='otherGraveSiteNote'
            onChange={onChange}
            margin="normal"
            defaultValue={data.otherGraveSiteNote}
            value={data.otherGraveSiteNote}
            InputLabelProps={{
                shrink: true,
            }}
            disabled={isDisabled}
        />

        <label className="checkboxLabel">{strings.cremationScheduling.form.attachedDocuments}</label>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            {attachedDocuments.map(document => (
                <div>
                    <FormControlLabel
                        key={document.id}
                        control={
                            <Checkbox
                                checked={attachedDocument.some(item => item.id === document.id)}
                                onChange={handleAttachedDocumentsCheckboxChange(document.name, document.id)}
                                disabled={isDisabled}
                            />
                        }
                        label={<span className="formControlLabel">{document.name}</span>}
                    />
                    {attachedDocument.some(item => item.id === document.id) && document.name === strings.cremationScheduling.contractForLeasedGraveSite && (
                        <div>
                            <FormControl component="fieldset">
                                {/* <FormLabel className="checkboxLabel"> {strings.cremationScheduling.form.contractFinalized} </FormLabel> */}
                                <RadioGroup name="contractFinalized" value={(data.contractFinalized == true || data.contractFinalized == "true") ? "true" : "false"} onChange={handleChange} className="radioGroup">
                                    <FormControlLabel value={"true"} disabled={isDisabled} control={<Radio />} label={<span className="formControlLabel">{strings.cremationScheduling.form.finalized}</span>} />
                                    <FormControlLabel value={"false"} disabled={isDisabled} control={<Radio />} label={<span className="formControlLabel">{strings.cremationScheduling.form.notFinalized}</span>} />
                                </RadioGroup>
                            </FormControl>
                        </div>
                    )}
                    {attachedDocument.some(item => item.id === document.id) && document.name === strings.cremationScheduling.proofOfDeceasedWishToBeCremated && (
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            {proofOfWishes.map(wish => (
                                <FormControlLabel
                                    key={wish.id}
                                    control={
                                        <Checkbox
                                            checked={proofOfWish && proofOfWish.id === wish.id}
                                            onChange={handleProofOfWishCheckboxChange(wish.name, wish.id, wish.value)}
                                            disabled={isDisabled}
                                        />
                                    }
                                    label={<span className="formControlLabel">{wish.name}</span>}
                                />
                            ))}
                        </div>  
                    )}
                </div>
            ))}
        </div>

        <div>
            <FormControl component="fieldset">
                <FormLabel className="checkboxLabel"> {strings.cremationScheduling.form.requestSubmitter} </FormLabel>
                <RadioGroup name="legalEntity" value={(data.legalEntity == true || data.legalEntity == "true") ? "true" : "false"} onChange={handleChange} className="radioGroup">
                    <FormControlLabel value={"true"} disabled={isDisabled} control={<Radio />} label={<span className="formControlLabel">{strings.cremationScheduling.form.legalEntity}</span>} />
                    <FormControlLabel value={"false"} disabled={isDisabled} control={<Radio />} label={<span className="formControlLabel">{strings.cremationScheduling.form.individual}</span>} />
                </RadioGroup>
            </FormControl>
        </div>

        {(data.legalEntity == "false" || !data.legalEntity) && 
            <React.Fragment>
                {((data.municipality && flag == "edit") || (flag == "add") || (data.transporter && flag == "view")) &&
                    <Autocomplete
                        id={"submitter"}
                        name={"submitter"}
                        options={submitters}
                        getOptionLabel={option => option.personalNumber}
                        onChange={onAutocompleteChange}
                        renderInput={params => (
                            <TextField
                                {...params}
                                variant="standard"
                                label={strings.cremationScheduling.form.identificationNumber}
                                margin="normal"
                                style={{ width: 275 }}
                                fullWidth
                                name={"submitter"}
                                onChange={handleTextInputChange}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        )}
                        disabled={isDisabled}
                        value={data.submitter}
                    />
                }
                {((!data.municipality && flag == "edit") || (!data.transporter && flag == "view")) &&
                    <Autocomplete
                        id={"submitter"}
                        name={"submitter"}
                        options={submitters}
                        getOptionLabel={option => option.personalNumber}
                        onChange={onAutocompleteChange}
                        renderInput={params => (
                            <TextField
                                {...params}
                                variant="standard"
                                label={strings.cremationScheduling.form.identificationNumber}
                                margin="normal"
                                style={{ width: 275 }}
                                fullWidth
                                name={"submitter"}
                                onChange={handleTextInputChange}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        )}
                        disabled={isDisabled}
                        value={data.submitter}
                    />
                }
                
                <TextField
                    label={strings.exhumationRequest.form.requestSubmitterNameSurname}
                    error={hasError(errors, 'nameSurnameSubmitter')}
                    helperText={getError(errors, 'nameSurnameSubmitter')}
                    fullWidth
                    name='nameSurnameSubmitter'
                    onChange={onChange}
                    margin="normal"
                    defaultValue={submitter.nameSurnameSubmitter}
                    value={submitter.nameSurnameSubmitter}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    disabled={true}
                />

                <TextField
                    label={strings.cremationScheduling.form.addressSubmitter}
                    error={hasError(errors, 'addressSubmitter')}
                    helperText={getError(errors, 'addressSubmitter')}
                    fullWidth
                    name='addressSubmitter'
                    onChange={onChange}
                    margin="normal"
                    defaultValue={submitter.addressSubmitter}
                    value={submitter.addressSubmitter}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    disabled={true}
                />

                <TextField
                    label={strings.cremationScheduling.form.municipalitySubmitter}
                    error={hasError(errors, 'municipalitySubmitter')}
                    helperText={getError(errors, 'municipalitySubmitter')}
                    fullWidth
                    name='municipalitySubmitter'
                    onChange={onChange}
                    margin="normal"
                    defaultValue={submitter.municipalitySubmitter}
                    value={submitter.municipalitySubmitter}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    disabled={true}
                />

                <TextField
                    label={strings.cremationScheduling.form.telephoneSubmitter}
                    error={hasError(errors, 'telephoneSubmitter')}
                    helperText={getError(errors, 'telephoneSubmitter')}
                    fullWidth
                    name='telephoneSubmitter'
                    onChange={onChange}
                    margin="normal"
                    defaultValue={submitter.telephoneSubmitter}
                    value={submitter.telephoneSubmitter}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    disabled={true}
                />

                <TextField
                    label={strings.cremationScheduling.form.IDCardOrPassportNumber}
                    error={hasError(errors, 'submitterIDCardOrPassportNumber')}
                    helperText={getError(errors, 'submitterIDCardOrPassportNumber')}
                    fullWidth
                    name='submitterIDCardOrPassportNumber'
                    onChange={onChange}
                    margin="normal"
                    defaultValue={data.submitterIDCardOrPassportNumber}
                    value={data.submitterIDCardOrPassportNumber}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    disabled={isDisabled}
                />

                <TextField
                    label={strings.cremationScheduling.form.IDCardOrPassportIssuer}
                    error={hasError(errors, 'submitterIDCardOrPassportIssuer')}
                    helperText={getError(errors, 'submitterIDCardOrPassportIssuer')}
                    fullWidth
                    name='submitterIDCardOrPassportIssuer'
                    onChange={onChange}
                    margin="normal"
                    defaultValue={data.submitterIDCardOrPassportIssuer}
                    value={data.submitterIDCardOrPassportIssuer}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    disabled={isDisabled}
                />

                <DatePickerControl
                    label={strings.cremationScheduling.form.IDCardOrPassportIssueDate}
                    error={getError(errors, 'submitterIDCardOrPassportIssueDate')}
                    hasError={hasError(errors, 'submitterIDCardOrPassportIssueDate')}
                    helperText={getError(errors, 'submitterIDCardOrPassportIssueDate')}
                    placeholder={strings.cremationScheduling.form.IDCardOrPassportIssueDate}
                    name='submitterIDCardOrPassportIssueDate'
                    onChange={onChange}
                    date={data.submitterIDCardOrPassportIssueDate ? new Date(data.submitterIDCardOrPassportIssueDate) : data.submitterIDCardOrPassportIssueDate}
                    isClearable={true}
                    dateFormat='dd/MM/yyyy'
                    disabled={isDisabled}
                />
            </React.Fragment>
        }

        {(data.legalEntity == "true" || data.legalEntity == true) &&
            <React.Fragment>
                <TextField
                    label={strings.cremationScheduling.form.nameOfLegalEntity}
                    error={hasError(errors, 'nameOfLegalEntity')}
                    helperText={getError(errors, 'nameOfLegalEntity')}
                    fullWidth
                    name='nameOfLegalEntity'
                    onChange={onChange}
                    margin="normal"
                    defaultValue={data.nameOfLegalEntity}
                    value={data.nameOfLegalEntity}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    disabled={isDisabled}
                />

                <TextField
                    label={strings.cremationScheduling.form.legalEntityHeadquarters}
                    error={hasError(errors, 'legalEntityHeadquarters')}
                    helperText={getError(errors, 'legalEntityHeadquarters')}
                    fullWidth
                    name='legalEntityHeadquarters'
                    onChange={onChange}
                    margin="normal"
                    defaultValue={data.legalEntityHeadquarters}
                    value={data.legalEntityHeadquarters}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    disabled={isDisabled}
                />

                <TextField
                    label={strings.company.form.taxNumber}
                    error={hasError(errors, 'legalEntityTaxNumber')}
                    helperText={getError(errors, 'legalEntityTaxNumber')}
                    fullWidth
                    name='legalEntityTaxNumber'
                    onChange={onChange}
                    margin="normal"
                    defaultValue={data.legalEntityTaxNumber}
                    value={data.legalEntityTaxNumber}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    disabled={isDisabled}
                />

                <TextField
                    label={strings.cremationScheduling.form.nameSurnameDriver}
                    error={hasError(errors, 'nameSurnameDriver')}
                    helperText={getError(errors, 'nameSurnameDriver')}
                    fullWidth
                    name='nameSurnameDriver'
                    onChange={onChange}
                    margin="normal"
                    defaultValue={data.nameSurnameDriver}
                    value={data.nameSurnameDriver}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    disabled={isDisabled}
                />

                <TextField
                    label={strings.cremationScheduling.form.IDCardOrPassportNumber}
                    error={hasError(errors, 'driverIDCardOrPassportNumber')}
                    helperText={getError(errors, 'driverIDCardOrPassportNumber')}
                    fullWidth
                    name='driverIDCardOrPassportNumber'
                    onChange={onChange}
                    margin="normal"
                    defaultValue={data.driverIDCardOrPassportNumber}
                    value={data.driverIDCardOrPassportNumber}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    disabled={isDisabled}
                />

                <TextField
                    label={strings.cremationScheduling.form.IDCardOrPassportIssuer}
                    error={hasError(errors, 'driverIDCardOrPassportIssuer')}
                    helperText={getError(errors, 'driverIDCardOrPassportIssuer')}
                    fullWidth
                    name='driverIDCardOrPassportIssuer'
                    onChange={onChange}
                    margin="normal"
                    defaultValue={data.driverIDCardOrPassportIssuer}
                    value={data.driverIDCardOrPassportIssuer}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    disabled={isDisabled}
                />

                <DatePickerControl
                    label={strings.cremationScheduling.form.IDCardOrPassportIssueDate}
                    error={getError(errors, 'driverIDCardOrPassportIssueDate')}
                    hasError={hasError(errors, 'driverIDCardOrPassportIssueDate')}
                    helperText={getError(errors, 'driverIDCardOrPassportIssueDate')}
                    placeholder={strings.cremationScheduling.form.IDCardOrPassportIssueDate}
                    name='driverIDCardOrPassportIssueDate'
                    onChange={onChange}
                    date={data.driverIDCardOrPassportIssueDate ? new Date(data.driverIDCardOrPassportIssueDate) : data.driverIDCardOrPassportIssueDate}
                    isClearable={true}
                    dateFormat='dd/MM/yyyy'
                    disabled={isDisabled}
                />

                <TextField
                    label={strings.cremationScheduling.form.telephoneSubmitter}
                    error={hasError(errors, 'driverTelephoneNumber')}
                    helperText={getError(errors, 'driverTelephoneNumber')}
                    fullWidth
                    name='driverTelephoneNumber'
                    onChange={onChange}
                    margin="normal"
                    defaultValue={data.driverTelephoneNumber}
                    value={data.driverTelephoneNumber}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    disabled={isDisabled}
                />

                <TextField
                    label={strings.cremationScheduling.form.contactPerson}
                    error={hasError(errors, 'legalEntityContactPerson')}
                    helperText={getError(errors, 'legalEntityContactPerson')}
                    fullWidth
                    name='legalEntityContactPerson'
                    onChange={onChange}
                    margin="normal"
                    defaultValue={data.legalEntityContactPerson}
                    value={data.legalEntityContactPerson}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    disabled={isDisabled}
                />
            </React.Fragment>
        }

        {
            !isDisabled &&
            <div className='submit-container'>
                <Button variant="contained" color="primary" disabled={disabled} onClick={onSubmit}>
                    {strings.cremationScheduling.form.ok}
                </Button>
                <Button variant="contained" color="secondary" onClick={onCancel}>
                    {strings.cremationScheduling.form.cancel}
                </Button>
            </div>
        }
    </form>
  )
};

export default CremationRequestForm;