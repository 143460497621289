import React from 'react';
import strings from "../../localization";
import { bindActionCreators } from "redux";
import { withSnackbar } from "notistack";
import { withRouter } from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import * as Actions from "../../actions/Actions";
import Page from '../../common/Page';
import RenewalEditedHeader from './RenewalEditedHeader';
import { getRenewalTypes } from '../../util/ReviewsUtil';
import update from 'immutability-helper';
import { getYears } from '../../util/DateUtil';
import { getAllGraveyards } from '../../services/graveyard/GraveyardService';
import { getGraveSitesWithSelectedGraveyard } from '../../services/graveSite/GraveSiteService';
import { getEditedRenewalFindTableAll } from '../../services/renewalEdited/RenewalEditedService';
import RenewalEditedList from './RenewalEditedList';
import PostalAddressCodeList from '../postalAddressCode/PostalAddressCodeList';
import CardGraveSiteList from '../reviews/reviewsRecords/CardGraveSiteList';
import RenewalList from '../renewal/RenewalList';
import IconButton from "@material-ui/core/IconButton";

class RenewalEditedInput extends Page {
    constructor(props) {
        super(props);

        const years = getYears()
        //years.unshift({ name: strings.years.all, value: -1 })

        this.state = {
            ...this.state,
            selectRenewalType: [],
            selectRenewalTypeKind: [],
            years: years,
            data: {
                selectedRenewalType: { id: -1 },
                selectedRenewalTypeKind: { id: -1, value: -1 },
                selectedYear: { value: -1 },
                selectedGraveyard: { id: -1 },
                selectedGraveSite: { id: -1 },

            },

        }

        this.state.sortBy = "renewalNumber,ASC";
        this.changeData = this.changeData.bind(this);
        this.find = this.find.bind(this);
        this.hideTableShowSearch = this.hideTableShowSearch.bind(this);
    }

    componentDidMount() {
        this.setState({
            companyId: this.props.auth.user.company.id,
            selectRenewalType: renewalSearch(),
            selectRenewalTypeKind: getRenewalTypes(),


        });

        getAllGraveyards().then(response => {
            this.setState({
                ...this.state,
                graveyards: response.data.entities
            })
        });
    }

    componentWillReceiveProps(nextProps) {
        strings.setLanguage(nextProps.auth.user.language);
        this.setState({
            selectRenewalType: renewalSearch(),
            selectRenewalTypeKind: getRenewalTypes(),
        });
    }

    changeData(event, data = 'data') {
        this.setState({
            [data]: update(this.state[data], { [event.target.name]: { $set: event.target.value } })
        }, () => {
            if (event.target.name == 'graveyard') {
                this.setState({
                    graveSites: [],
                    data: {
                        ...this.state.data,
                        graveSite: null,
                    }
                });
            }

        });
        this.writeLineObject(event);

        this.restartSelects(event.target.name);
    }

    restartSelects(eventName) {
        if (eventName == "selectedRenewalType") {
            this.setState({
                data: {
                    ...this.state.data,
                    selectedRenewalTypeKind: { id: -1, value: -1 },
                    selectedYear: { value: new Date().getFullYear() },
                    selectedGraveyard: { id: -1 },
                    selectedGraveSite: { id: -1 },
                }
            }, () => {
                this.setState({
                    obj: {
                        ...this.state.obj,
                        // renewalType: null,
                        renewalTypeKind: null,
                        year: null,
                        selectedGraveSite: null,
                        selectedGraveyard: null,
                        deceasedNameSurname: null,
                        nameSurnameSubmitter: null,
                    }
                })
            });

        }
    }

    getPageHeader() {
        return <h1>{strings.renewalList.pageTitle}</h1>;
    }



    writeLineObject(e) {
        if (e.target.name == "selectedRenewalType") {
            this.setState({
                obj: {
                    ...this.state.obj,
                    renewalType: e.target.value
                },
                data: {
                    ...this.state.data,
                    selectedRenewalType: e.target.value
                }
            })


        }

        if (e.target.name == "selectedRenewalTypeKind") {
            this.setState({
                obj: {
                    ...this.state.obj,
                    renewalTypeKind: e.target.value
                }
            })
        }

        if (e.target.name == "graveyard") {
            this.setState({
                data: {
                    ...this.state.data,
                    selectedGraveyard: e.target.value
                },
                obj: {
                    ...this.state.obj,
                    selectedGraveyard: e.target.value
                }
            })
        }

        if (e.target.name == "selectedYear") {
            this.setState({
                obj: {
                    ...this.state.obj,
                    year: e.target.value
                }
            })
        }

        if (e.target.name == "graveSite") {
            this.setState({
                obj: {
                    ...this.state.obj,
                    selectedGraveSite: e.target.value
                }
            })

        }
    }

    handleTextInputChange = (e) => {
        if (e.target.name === "graveSite") {
            let data = {
                searchParam: e.target.value,
                companyId: this.state.companyId,
                sort: "code,ASC",
                renewal: -1,
                graveyardId: this.state.data.selectedGraveyard.id,
                type: "-1",
                userId: 0
            };

            {
                this.state.companyId != undefined &&
                    getGraveSitesWithSelectedGraveyard(data).then(response => {
                        if (!response.ok) {
                            return;
                        }
                        this.setState({
                            graveSites: response.data.entities,
                        });
                    });
            }


        }

        if (e.target.name == "deceasedNameSurname") {
            this.setState({
                obj: {
                    ...this.state.obj,
                    deceasedNameSurname: e.target.value
                }
            })
        }

        if (e.target.name == "nameSurnameSubmitter") {
            this.setState({
                obj: {
                    ...this.state.obj,
                    nameSurnameSubmitter: e.target.value
                }
            })
        }
    }

    onAutocompleteChange = (event, values) => {
        let autocompleteName = event.target.id.split("-")[0];
        this.setState({
            data: {
                ...this.state.data, [autocompleteName]: values
            },
            obj: {
                ...this.state.obj,
                selectedGraveSite: values
            }
        });
    }

    keyPressBackspace(e) {
        if (e.target.value.length == 2 && e.keyCode !== 8) {
            e.target.value = e.target.value + '/';
        }

        if (e.target.value.length == 5 && e.keyCode !== 8) {
            e.target.value = e.target.value + '-';
        }

        if (e.keyCode !== 8) {
            return;
        }

        if (e.target.value.charAt(e.target.value.length - 2) == "-" || e.target.value.charAt(e.target.value.length - 2) == "/") {
            e.target.value = e.target.value.substring(0, e.target.value.length - 1);
        }
        else {
            e.target.value = e.target.value.substring(0, e.target.value.length);
        }
    }

    find() {
        let data = {
            page: 0,
            size: 100,
            graveyardId: this.state.obj.selectedGraveyard != null ? this.state.obj.selectedGraveyard.id : "",
            graveSiteId: this.state.obj.selectedGraveSite != null ? this.state.obj.selectedGraveSite.id : "",
            companyId: this.state.companyId,
            userId: this.props.auth.user.id,
            searchType: this.state.obj != undefined ? this.state.obj.renewalType.id : -1,
            selectedYear: this.state.data.selectedYear.value,
            selectedRenewalTypeKind: this.state.data.selectedRenewalTypeKind.id,
            submitterNameSurname: this.state.obj != undefined ? this.state.obj.nameSurnameSubmitter : "",
            deceasedNameSurname: this.state.obj != undefined ? this.state.obj.deceasedNameSurname : ""

        };
        getEditedRenewalFindTableAll(data).then(response => {
            this.setState({
                showTable: true,
                responseData: response.data.content,
            }, () => {
                this.setState({
                    tableData: this.state.responseData,
                    total: this.state.responseData.length,
                });
            });
        });

    }

    hideTableShowSearch() {
        this.setState({
            showTable: false
        })
    }

    render() {
        return (
            <div className='header'>
                {this.getPageHeader()}
                {!this.state.showTable &&
                    <div>
                        <RenewalEditedHeader
                            selectRenewalType={this.state.selectRenewalType}
                            selectRenewalTypeKind={this.state.selectRenewalTypeKind}
                            onChange={this.changeData}
                            selectedYear={this.state.data.selectedYear}
                            selectedRenewalType={this.state.obj != undefined ? this.state.obj.renewalType : ""}
                            selectedRenewalTypeKind={this.state.obj != undefined ? this.state.obj.renewalTypeKind : ""}
                            selectedGraveyard={this.state.data.selectedGraveyard}
                            selectedGraveSite={this.state.data.selectedGraveSite}
                            years={this.state.years}
                            graveyards={this.state.graveyards}
                            graveSites={this.state.graveSites}
                            autocompleteChange={this.onAutocompleteChange}
                            handleTextInputChange={this.handleTextInputChange}
                            onKeyDown={this.keyPressBackspace}
                            renewalType={this.state.obj != undefined ? this.state.obj.renewalType : ""}
                            find={this.find}
                            data={this.state.data}
                            obj={this.state.obj}
                            submitter={this.state.obj != undefined ? this.state.obj.nameSurnameSubmitter : ""}
                            deceased={this.state.obj != undefined ? this.state.obj.deceasedNameSurname : ""}
                        />

                        <RenewalList
                            data={this.state.responseData}
                            selectedRenewalType={this.state.obj != undefined ? this.state.obj.renewalType : ""}
                        />
                    </div>


                }

                {this.state.showTable &&
                    <div>
                        {/* {this.state.obj.renewalType.id == 3 && */}
                        <RenewalEditedList
                            data={this.state.responseData}
                            obj={this.state.obj}
                            selectedRenewalTypeKind={this.state.data.selectedRenewalTypeKind}
                            selectedYear={this.state.data.selectedYear}
                            hideTableShowSearch={this.state.showTable}
                            onEditCompleted={() => {
                                this.setState({
                                    showTable: false
                                })
                            }}
                        />
                        {/* } */}
                        <IconButton onClick={() => this.hideTableShowSearch()}>
                            {strings.review.buttons.back}
                        </IconButton>
                    </div>

                }
            </div>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        changeFullScreen: Actions.changeFullScreen
    }, dispatch);
}

function mapStateToProps({ menuReducers, authReducers, siteDataReducers }) {
    return { menu: menuReducers, auth: authReducers, siteData: siteDataReducers };
}

function renewalSearch() {
    return [
        { id: 0, value: null, name: strings.renewalTypes.all },
        { id: 1, value: strings.renewalTypes.submitterNameSurname, name: strings.renewalTypes.submitterNameSurname },
        { id: 2, value: strings.renewalTypes.deceasedNameSurname, name: strings.renewalTypes.deceasedNameSurname },
        { id: 3, value: strings.renewalTypes.graveSite, name: strings.renewalTypes.graveSite },
    ];
}

export default withSnackbar(withRouter(connect(mapStateToProps, mapDispatchToProps)(RenewalEditedInput)));
