import React from 'react'
import TablePage from "../../common/TablePage";
import { bindActionCreators } from "redux";
import * as Actions from "../../actions/Actions";
import { withRouter } from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import strings from "../../localization";
import { withSnackbar } from "notistack";
import { getPostalAddressCodes, deletePostalAddressCode } from '../../services/postalAddressCode/PostalAddressCodeService';
import AddPostalAddressCode from './AddPostalAddressCode'
import EditPostalAddressCode from './EditPostalAddressCode'
import ViewPostalAddressCode from './ViewPostalAddressCode'

class PostalAddressCodeList extends TablePage {

  tableDescription = [
    { key: 'place', label: strings.postalAddressCodeList.place, sort: 'place' },
    { key: 'street', label: strings.postalAddressCodeList.street, sort: 'street' },
    { key: 'number', label: strings.postalAddressCodeList.number, sort: 'number' },
    { key: 'pac', label: strings.postalAddressCodeList.pac, sort: 'pac' },
    { key: 'postalNumberCode', label: strings.postalAddressCodeList.postalNumber, sort: 'postalNumberCode' },
    { key: 'settlementCode', label: strings.postalAddressCodeList.settlementCode, sort: 'settlementCode' },
    { key: 'stateCode', label: strings.postalAddressCodeList.stateCode, sort: 'stateCode' },
  ];

  constructor(props) {
    super(props);

    this.state.sortBy = "place,ASC";

  }


  fetchData() {
    let data = {
      searchParam: this.state.searchData.search,
      page: this.state.searchData.page - 1,
      size: this.state.searchData.perPage,
      companyId: this.state.companyId,
      sort: this.state.sortBy,
      userId: this.props.auth.user.id
    };

    {
      this.state.companyId != undefined &&
        getPostalAddressCodes(data).then(response => {
          if (!response.ok) {
            return;
          }
          this.setState({
            tableData: response.data.entities,
            total: response.data.total,
          });
        });
    }
  }

  componentDidMount() {
    this.setState({
      companyId: this.props.auth.user.company.id
    }, () => { this.fetchData(); })
  }


  componentWillReceiveProps(nextProps) {
    strings.setLanguage(nextProps.auth.user.language);
    this.tableDescription = [
      { key: 'place', label: strings.postalAddressCodeList.place, sort: 'place' },
      { key: 'street', label: strings.postalAddressCodeList.street, sort: 'street' },
      { key: 'number', label: strings.postalAddressCodeList.number, sort: 'number' },
      { key: 'pac', label: strings.postalAddressCodeList.pac, sort: 'pac' },
      { key: 'postalNumberCode', label: strings.postalAddressCodeList.postalNumber, sort: 'postalNumberCode' },
      { key: 'settlementCode', label: strings.postalAddressCodeList.settlementCode, sort: 'settlementCode' },
      { key: 'stateCode', label: strings.postalAddressCodeList.stateCode, sort: 'stateCode' },
    ];
  }


  getPageHeader() {
    return <h1>{strings.postalAddressCodeList.pageTitle}</h1>;
  }

  renderAddContent() {
    return <AddPostalAddressCode
      cid={this.state.companyId}
      uemail={this.props.auth.user.email}
      onCancel={this.onCancel}
      onFinish={this.onFinish}
      flag={"add"}
    />
  }

  renderEditContent() {
    return <EditPostalAddressCode
      id={this.state.selectedItem.id}
      uemail={this.props.auth.user.email}
      cid={this.state.companyId}
      onCancel={this.onCancel}
      onFinish={this.onFinish}
      isDisabled={false}
      flag={"edit"}
    />
  }

  renderViewContent() {

    return <ViewPostalAddressCode
      id={this.state.selectedItem.id}
      isDisabled={true}
      onCancel={this.onCancel}
      onFinish={this.onFinish}
      flag={"view"}
    />
  }

  delete(item) {

    this.setState({
      lockTable: true
    });


    deletePostalAddressCode(item.id, this.props.auth.user.id).then(response => {

      if (response && !response.ok) {
        this.onFinish(null);
        return;
      }

      this.props.enqueueSnackbar(strings.postalAddressCodeList.postalAddressCodeDeleted, { variant: 'success' });

      this.onFinish(item);
      this.cancelDelete();

      this.setState({
        lockTable: false
      });
    });
  }

}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    changeFullScreen: Actions.changeFullScreen
  }, dispatch);
}

function mapStateToProps({ menuReducers, authReducers, siteDataReducers }) {
  return { menu: menuReducers, auth: authReducers, siteData: siteDataReducers };
}

export default withSnackbar(withRouter(connect(mapStateToProps, mapDispatchToProps)(PostalAddressCodeList)));