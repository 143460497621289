import React, { useState } from "react";
import strings from "../../../localization";
import { getError, hasError } from "../../../functions/Validation";
import { Button, TextField } from "@material-ui/core";
import DatePickerControl from "../../controls/DatePickerControl";
import Autocomplete from "@material-ui/lab/Autocomplete";

const RenewalForm = ({
  onSubmit,
  onCancel,
  onChange,
  errors,
  data,
  isDisabled,
  onAutocompleteChange,
  handleTextInputChange,
  submitter,
  submitters,
  flag,
  searchTypeFlag,
  expiryDate,
  graveyard,
  graveyards,
  graveSite,
  graveSites,
  disabledSubmitButton,
  newExpirationDate,
  onChangePaymentSlipAndDateField,
  onKeyDown,
  costs,
  cost,
}) => {
  const [startDate, setStartDate] = useState(new Date().getFullYear());
  
  return (
    <form id="user-form">
      {searchTypeFlag.id !== 2 && (
        <TextField
          label={strings.renewal.form.renewalNumber}
          error={hasError(errors, "renewalNumber")}
          helperText={getError(errors, "renewalNumber")}
          fullWidth
          name="renewalNumber"
          onChange={onChange}
          margin="normal"
          defaultValue={data.selectedRenewalTypeKind}
          value={data.renewalNumber}
          InputLabelProps={{
            shrink: true,
          }}
          disabled={true}
        />
      )}
      {searchTypeFlag.id == 2 && (
        <TextField
          label={strings.renewal.form.renewalNumber}
          error={hasError(errors, "renewalNumber")}
          helperText={getError(errors, "renewalNumber")}
          fullWidth
          name="renewalNumber"
          onChange={onChange}
          margin="normal"
          defaultValue={data.selectedRenewalTypeKind}
          value={data.renewalNumber}
          InputLabelProps={{
            shrink: true,
          }}
          disabled={true}
        />
      )}
      {/* <TextField
        label={strings.renewal.form.personalNumber}
        error={hasError(errors, "personalNumber")}
        helperText={getError(errors, "personalNumber")}
        fullWidth
        name="personalNumber"
        onChange={onChange}
        margin="normal"
        defaultValue={submitter ? submitter.personalNumber : ""}
        value={submitter ? submitter.personalNumber : ""}
        InputLabelProps={{
          shrink: true,
        }}
        disabled={true}
      /> */}

      {((data.submitter && flag == "edit") ||
        flag == "add" ||
        (data.submitter && flag == "view")) && (
        <Autocomplete
          id={"submitter"}
          name={"submitter"}
          options={submitters}
          getOptionLabel={(option) => option.personalNumber}
          onChange={onAutocompleteChange}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              label={strings.renewal.form.personalNumber}
              margin="normal"
              fullWidth
              name={"submitter"}
              onChange={handleTextInputChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
          disabled={isDisabled}
          value={submitter ? submitter : data.submitter}
        />
      )}

      {((!data.submitter && flag == "edit") || (!data.submitter && flag == "view")) && (
        <Autocomplete
          id={"submitter"}
          name={"submitter"}
          options={submitters}
          getOptionLabel={(option) => option.personalNumber}
          onChange={onAutocompleteChange}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              label={strings.renewal.form.personalNumber}
              margin="normal"
              fullWidth
              name={"submitter"}
              onChange={handleTextInputChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
          disabled={isDisabled}
          value={data.submitter}
        />
      )}

      {((data.submitter && flag == "edit") ||
        flag == "add" ||
        (data.submitter && flag == "view")) && (
        <TextField
          label={strings.renewal.form.submitterName}
          error={hasError(errors, "nameSurnameSubmitter")}
          helperText={getError(errors, "nameSurnameSubmitter")}
          fullWidth
          name="nameSurnameSubmitter"
          onChange={onChange}
          margin="normal"
          defaultValue={submitter ? submitter.nameSurnameSubmitter : ""}
          InputLabelProps={{
            shrink: true,
          }}
          disabled={true}
          value={submitter ? submitter.nameSurnameSubmitter : ""}
        />
      )}

      {((!data.submitter && flag == "edit") ||
        (!data.submitter && flag == "view")) && (
        <TextField
          label={strings.renewal.form.submitterName}
          error={hasError(errors, "nameSurnameSubmitter")}
          helperText={getError(errors, "nameSurnameSubmitter")}
          fullWidth
          name="nameSurnameSubmitter"
          onChange={onChange}
          margin="normal"
          defaultValue={
            submitter
              ? submitter.nameSurnameSubmitter
                ? submitter.nameSurnameSubmitter
                : ""
              : ""
          }
          InputLabelProps={{
            shrink: true,
          }}
          disabled={true}
          value={
            submitter
              ? submitter.nameSurnameSubmitter
                ? submitter.nameSurnameSubmitter
                : ""
              : ""
          }
        />
      )}

      <TextField
        label={strings.renewal.form.submitterAddress}
        error={hasError(errors, "addressSubmitter")}
        helperText={getError(errors, "addressSubmitter")}
        fullWidth
        name="addressSubmitter"
        onChange={onChange}
        margin="normal"
        defaultValue={submitter ? submitter.addressSubmitter : ""}
        value={submitter ? submitter.addressSubmitter : ""}
        InputLabelProps={{
          shrink: true,
        }}
        disabled={true}
      />
      <TextField
        label={strings.renewal.form.submitterMunicipality}
        error={hasError(errors, "municipalitySubmitter")}
        helperText={getError(errors, "municipalitySubmitter")}
        fullWidth
        name="municipalitySubmitter"
        onChange={onChange}
        margin="normal"
        defaultValue={submitter ? submitter.postalCode : ""}
        value={
          submitter
            ? submitter.postalCode
              ? submitter.postalCode
              : submitter.postalCodeImprovedZip
            : ""
        }
        InputLabelProps={{
          shrink: true,
        }}
        disabled={true}
      />
      <TextField
        label={strings.renewal.form.submitterTelephone}
        error={hasError(errors, "telephoneSubmitter")}
        helperText={getError(errors, "telephoneSubmitter")}
        fullWidth
        name="telephoneSubmitter"
        onChange={onChange}
        margin="normal"
        defaultValue={submitter ? submitter.telephone : ""}
        value={submitter ? submitter.telephone : ""}
        InputLabelProps={{
          shrink: true,
        }}
        disabled={true}
      />

      {/* {(data.expiryDateString != undefined && data.expiryDateString != null && data.expiryDateString != "") &&
                <TextField
                    label={strings.renewal.form.expiryDateString}
                    fullWidth
                    name='expiryDateString'
                    onChange={handleTextInputChange}
                    margin="normal"
                    value={data.expiryDateString}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    disabled={true}
                />
            } */}

      {((data.cost && flag == "edit") ||
        flag == "add" ||
        (data.cost && flag == "view")) && (
        <Autocomplete
          id={"cost"}
          name={"cost"}
          options={costs}
          getOptionLabel={(option) => option.personalNumber}
          onChange={onAutocompleteChange}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              label={strings.renewal.form.costPersonalNumber}
              margin="normal"
              fullWidth
              name={"cost"}
              onChange={handleTextInputChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
          disabled={isDisabled}
          value={data.cost}
        />
      )}

      {((!data.cost && flag == "edit") || (!data.cost && flag == "view")) && (
        <Autocomplete
          id={"cost"}
          name={"cost"}
          options={costs}
          getOptionLabel={(option) => option.personalNumber}
          onChange={onAutocompleteChange}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              label={strings.renewal.form.costPersonalNumber}
              margin="normal"
              fullWidth
              name={"cost"}
              onChange={handleTextInputChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
          disabled={isDisabled}
          value={data.cost}
        />
      )}

      <TextField
        label={strings.renewal.form.costNameSurname}
        error={hasError(errors, "personalNumber")}
        helperText={getError(errors, "personalNumber")}
        fullWidth
        name="personalNumber"
        onChange={onChange}
        margin="normal"
        defaultValue={cost ? cost.nameSurname : ""}
        value={cost ? cost.nameSurname : ""}
        InputLabelProps={{
          shrink: true,
        }}
        disabled={true}
      />
      <TextField
        label={strings.renewal.form.costStreet}
        error={hasError(errors, "personalNumber")}
        helperText={getError(errors, "personalNumber")}
        fullWidth
        name="personalNumber"
        onChange={onChange}
        margin="normal"
        defaultValue={cost ? cost.address : ""}
        value={cost ? cost.address : ""}
        InputLabelProps={{
          shrink: true,
        }}
        disabled={true}
      />
      <TextField
        label={strings.renewal.form.costCity}
        error={hasError(errors, "personalNumber")}
        helperText={getError(errors, "personalNumber")}
        fullWidth
        name="personalNumber"
        onChange={onChange}
        margin="normal"
        defaultValue={cost ? cost.municipality : ""}
        value={cost ? cost.municipality : ""}
        InputLabelProps={{
          shrink: true,
        }}
        disabled={true}
      />
      {flag == "add" && (
        <DatePickerControl
          label={strings.renewal.form.expiryDate}
          error={getError(errors, "expiryDate")}
          hasError={hasError(errors, "expiryDate")}
          helperText={getError(errors, "expiryDate")}
          placeholder={strings.renewal.form.expiryDate}
          name="expiryDate"
          onChange={onChange}
          date={expiryDate ? new Date(expiryDate) : expiryDate}
          isClearable={true}
          dateFormat="dd/MM/yyyy"
          disabled={true}
          showYearDropdown={true}
        />
      )}

      {(flag == "view" || flag == "edit") && (
        <DatePickerControl
          label={strings.renewal.form.expiryDate}
          error={getError(errors, "expiryDate")}
          hasError={hasError(errors, "expiryDate")}
          helperText={getError(errors, "expiryDate")}
          placeholder={strings.renewal.form.expiryDate}
          name="expiryDate"
          onChange={onChange}
          date={data.expiryDate ? new Date(data.expiryDate) : data.expiryDate}
          isClearable={true}
          dateFormat="dd/MM/yyyy"
          disabled={isDisabled}
          showYearDropdown={true}
        />
      )}
      <DatePickerControl
        label={strings.renewal.form.dateOfChange}
        error={getError(errors, "dateOfChange")}
        hasError={hasError(errors, "dateOfChange")}
        helperText={getError(errors, "dateOfChange")}
        placeholder={strings.renewal.form.dateOfChange}
        name="dateOfChange"
        onChange={onChange}
        date={
          data.dateOfChange ? new Date(data.dateOfChange) : data.dateOfChange
        }
        isClearable={true}
        dateFormat="dd/MM/yyyy"
        disabled={flag == "view" ? true : false}
        showYearDropdown={true}
      />

      <TextField
        label={strings.renewal.form.paymentNumber}
        error={getError(errors, "paymentSlipAndDate")}
        hasError={hasError(errors, "paymentSlipAndDate")}
        helperText={getError(errors, "paymentSlipAndDate")}
        fullWidth
        name="paymentSlipAndDate"
        onChange={onChangePaymentSlipAndDateField}
        margin="normal"
        defaultValue={data.paymentSlipAndDate ? data.paymentSlipAndDate : data.payment}
        value={data.paymentSlipAndDate ? data.paymentSlipAndDate : data.payment}
        InputLabelProps={{
          shrink: true,
        }}
        disabled={isDisabled}
        onKeyDown={onKeyDown}
      />

      {flag == "view" && data.amount % 1 == 0 && data.amount != null && (
        <TextField
          label={strings.renewal.form.amount}
          error={hasError(errors, "amount")}
          helperText={getError(errors, "amount")}
          fullWidth
          name="amount"
          onChange={onChange}
          margin="normal"
          defaultValue={data.amount}
          value={data.amount + ".00"}
          InputLabelProps={{
            shrink: true,
          }}
          disabled={isDisabled}
          placeholder="0.00"
        />
      )}

      {flag == "view" && data.amount % 1 != 0 && data.amount != null && (
        <TextField
          label={strings.renewal.form.amount}
          error={hasError(errors, "amount")}
          helperText={getError(errors, "amount")}
          fullWidth
          name="amount"
          onChange={onChange}
          margin="normal"
          defaultValue={data.amount}
          value={data.amount.toFixed(2)}
          InputLabelProps={{
            shrink: true,
          }}
          disabled={isDisabled}
          placeholder="0.00"
        />
      )}

      {flag == "view" && data.amount == null && (
        <TextField
          label={strings.renewal.form.amount}
          error={hasError(errors, "amount")}
          helperText={getError(errors, "amount")}
          fullWidth
          name="amount"
          onChange={onChange}
          margin="normal"
          defaultValue={data.amount}
          value={0 + ".00"}
          InputLabelProps={{
            shrink: true,
          }}
          disabled={isDisabled}
          placeholder="0.00"
        />
      )}

      {(flag == "add" || flag == "edit") && (
        <TextField
          label={strings.renewal.form.amount}
          error={hasError(errors, "amount")}
          helperText={getError(errors, "amount")}
          fullWidth
          name="amount"
          onChange={onChange}
          margin="normal"
          defaultValue={data.amount}
          value={data.amount}
          InputLabelProps={{
            shrink: true,
          }}
          disabled={isDisabled}
          placeholder="0.00"
        />
      )}

      {flag == "add" && (
        <TextField
          label={strings.renewal.form.graveyard}
          error={hasError(errors, "graveyard")}
          helperText={getError(errors, "graveyard")}
          fullWidth
          name="graveyard"
          onChange={onChange}
          margin="normal"
          defaultValue={graveyard ? graveyard.name : ""}
          value={data.graveyard ? data.graveyard.name : ""}
          InputLabelProps={{
            shrink: true,
          }}
          disabled={true}
        />
      )}

      {((data.graveyard && flag == "edit") ||
        (data.graveyard && flag == "view")) && (
        <Autocomplete
          id={"graveyard"}
          name={"graveyard"}
          options={graveyards}
          getOptionLabel={(option) => option.name}
          onChange={onAutocompleteChange}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              label={strings.reservation.form.graveyard}
              error={hasError(errors, "graveyard")}
              helperText={getError(errors, "graveyard")}
              margin="normal"
              fullWidth
              name={"graveyard"}
              onChange={handleTextInputChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
          disabled={isDisabled}
          value={data.graveyard}
        />
      )}

      {((!data.graveyard && flag == "edit") ||
        (!data.graveyard && flag == "view")) && (
        <Autocomplete
          id={"graveyard"}
          name={"graveyard"}
          options={graveyards}
          getOptionLabel={(option) => option.name}
          onChange={onAutocompleteChange}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              label={strings.reservation.form.graveyard}
              error={hasError(errors, "graveyard")}
              helperText={getError(errors, "graveyard")}
              margin="normal"
              fullWidth
              name={"graveyard"}
              onChange={handleTextInputChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
          disabled={isDisabled}
          value={data.graveyard}
        />
      )}

      {flag == "add" && (
        <TextField
          label={strings.renewal.form.graveSite}
          error={hasError(errors, "graveSite")}
          helperText={getError(errors, "graveSite")}
          fullWidth
          name="graveSite"
          onChange={onChange}
          margin="normal"
          defaultValue={graveSite ? graveSite.code : ""}
          value={data.graveSite ? data.graveSite.code : ""}
          InputLabelProps={{
            shrink: true,
          }}
          disabled={true}
        />
      )}

      {data.graveyard != undefined && data.graveyard != null && (flag == "edit" || flag == "view") && (
        <Autocomplete
          id={"graveSite"}
          name={"graveSite"}
          options={graveSites}
          getOptionLabel={(option) => option.code}
          onChange={onAutocompleteChange}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              label={strings.mainBookOfBuried.form.graveSite}
              error={hasError(errors, "graveSite")}
              helperText={getError(errors, "graveSite")}
              margin="normal"
              fullWidth
              name={"graveSite"}
              onChange={handleTextInputChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
          disabled={isDisabled}
          value={data.graveSite}
          disableClearable={true}
        />
      )}

      {(data.graveyard == undefined || data.graveyard == null) && (flag == "edit" || flag == "view") && (
        <Autocomplete
          id={"graveSite"}
          name={"graveSite"}
          options={graveSites}
          getOptionLabel={(option) => option.code}
          onChange={onAutocompleteChange}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              label={strings.mainBookOfBuried.form.graveSite}
              error={hasError(errors, "graveSite")}
              helperText={getError(errors, "graveSite")}
              margin="normal"
              fullWidth
              name={"graveSite"}
              onChange={handleTextInputChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
          disabled={true}
          value={null}
          disableClearable={true}
        />
      )}

      <TextField
        label={strings.reservation.form.tombstoneType}
        error={hasError(errors, "tombstoneType")}
        helperText={getError(errors, "tombstoneType")}
        fullWidth
        name="tombstoneType"
        onChange={onChange}
        margin="normal"
        defaultValue={data.tombstoneType}
        value={data.tombstoneType}
        InputLabelProps={{
          shrink: true,
        }}
        disabled={isDisabled}
      />

      <TextField
        label={strings.renewal.form.renewalYears}
        error={hasError(errors, "renewalYears")}
        helperText={getError(errors, "renewalYears")}
        fullWidth
        name="renewalYears"
        onChange={handleTextInputChange}
        margin="normal"
        defaultValue={data.renewalYears}
        value={data.renewalYears}
        InputLabelProps={{
          shrink: true,
        }}
        disabled={flag == "view" ? true : false}
      />

      {flag == "add" && (
        <DatePickerControl
          label={strings.renewal.form.newExpirationDate}
          error={getError(errors, "newExpirationDate")}
          hasError={hasError(errors, "newExpirationDate")}
          helperText={getError(errors, "newExpirationDate")}
          placeholder={strings.renewal.form.newExpirationDate}
          name="newExpirationDate"
          onChange={onChange}
          date={newExpirationDate}
          isClearable={true}
          dateFormat="dd/MM/yyyy"
          showYearDropdown={false}
        />
      )}

      {(flag == "view" || flag == "edit") && (
        <DatePickerControl
          label={strings.renewal.form.newExpirationDate}
          error={getError(errors, "newExpirationDate")}
          hasError={hasError(errors, "newExpirationDate")}
          helperText={getError(errors, "newExpirationDate")}
          placeholder={strings.renewal.form.newExpirationDate}
          name="newExpirationDate"
          onChange={onChange}
          date={
            data.newExpirationDate
              ? new Date(data.newExpirationDate)
              : data.newExpirationDate
          }
          isClearable={true}
          dateFormat="dd/MM/yyyy"
          //disabled={true}
          showYearDropdown={false}
        />
      )}

      <TextField
        multiline={true}
        rowsMax={5}
        label={strings.renewal.form.note}
        error={hasError(errors, "note")}
        helperText={getError(errors, "note")}
        fullWidth
        name="note"
        onChange={onChange}
        margin="normal"
        defaultValue={data.note}
        value={data.note}
        InputLabelProps={{
          shrink: true,
        }}
        disabled={isDisabled}
      />
      {!isDisabled && (
        <div className="submit-container">
          <Button variant="contained" color="primary" disabled={disabledSubmitButton} onClick={onSubmit}>
            {strings.renewal.form.ok}
          </Button>
          <Button variant="contained" color="secondary" disabled={disabledSubmitButton} onClick={onCancel}>
            {strings.renewal.form.cancel}
          </Button>
        </div>
      )}
    </form>
  );
};

export default RenewalForm;
