import React from 'react';
import strings from "../../../localization";
import { getError, hasError } from "../../../functions/Validation";
import { Button, TextField, FormLabel, FormGroup, FormControlLabel, Checkbox, Radio, FormControl, RadioGroup } from "@material-ui/core";
import DatePickerControl from '../../controls/DatePickerControl';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { stringToDate } from '../../../util/DateUtil';
import moment from 'moment';

const StoneCuttingWorksForm = ({
  onSubmit,
  onCancel,
  onChange,
  errors,
  data,
  isDisabled,
  citizen,
  stonecutters,
  mainBooksOfBuried,
  graveyards,
  graveSites,
  citizens,
  onAutocompleteChange,
  handleTextInputChange,
  flag,
  date,
  typedInGraveSite,
  keyPress,
  keyPressBackspace,
}) => {  
  return (
    <form id='user-form'>
      <TextField
        label={strings.stoneCuttingWorks.form.code + "*"}
        error={hasError(errors, 'code')}
        helperText={getError(errors, 'code')}
        fullWidth
        autoFocus
        name='code'
        onChange={onChange}
        margin="normal"
        defaultValue={date}
        value={data.code}
        InputLabelProps={{
          shrink: true,
        }}
        disabled={isDisabled}
      />
      {((data.stonecutter && flag == "edit") || (flag == "add") || (data.stonecutter && flag == "view")) &&
        <Autocomplete
          id={"stonecutter"}
          name={"stonecutter"}
          options={stonecutters}
          getOptionLabel={option => option.name}
          onChange={onAutocompleteChange}
          renderInput={params => (
            <TextField
              {...params}
              variant="standard"
              label={strings.stoneCuttingWorks.form.stonecutter}
              margin="normal"
              fullWidth
              name={"stonecutter"}
              onChange={handleTextInputChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
          disabled={isDisabled}
          value={data.stonecutter}
        />
      }
      {((!data.stonecutter && flag == "edit") || (!data.stonecutter && flag == "view")) &&
        <Autocomplete
          id={"stonecutter"}
          name={"stonecutter"}
          options={stonecutters}
          getOptionLabel={option => option.name}
          onChange={onAutocompleteChange}
          renderInput={params => (
            <TextField
              {...params}
              variant="standard"
              label={strings.stoneCuttingWorks.form.stonecutter}
              margin="normal"
              fullWidth
              name={"stonecutter"}
              onChange={handleTextInputChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
          disabled={isDisabled}
          value={data.stonecutter}
        />
      }
      <TextField
        label={strings.stoneCuttingWorks.form.jobType}
        error={hasError(errors, 'jobType')}
        helperText={getError(errors, 'jobType')}
        fullWidth
        name='jobType'
        onChange={onChange}
        margin="normal"
        defaultValue={data.jobType}
        value={data.jobType}
        InputLabelProps={{
          shrink: true,
        }}
        disabled={isDisabled}
      />
      {((data.mainBookOfBuried && flag == "edit") || (flag == "add") || (data.mainBookOfBuried && flag == "view")) &&
        <Autocomplete
          id={"mainBookOfBuried"}
          name={"mainBookOfBuried"}
          options={mainBooksOfBuried}
          getOptionLabel={option => option.mainBookOfBuries}
          onChange={onAutocompleteChange}
          renderInput={params => (
            <TextField
              {...params}
              variant="standard"
              label={strings.stoneCuttingWorks.form.mainBookOfBuried}
              margin="normal"
              fullWidth
              name={"mainBookOfBuried"}
              onChange={handleTextInputChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
          disabled={isDisabled}
          value={data.mainBookOfBuried}
        />
      }
      {((!data.mainBookOfBuried && flag == "edit") || (!data.mainBookOfBuried && flag == "view")) &&
        <Autocomplete
          id={"mainBookOfBuried"}
          name={"mainBookOfBuried"}
          options={mainBooksOfBuried}
          getOptionLabel={option => option.mainBookOfBuries}
          onChange={onAutocompleteChange}
          renderInput={params => (
            <TextField
              {...params}
              variant="standard"
              label={strings.stoneCuttingWorks.form.mainBookOfBuried}
              margin="normal"
              fullWidth
              name={"mainBookOfBuried"}
              onChange={handleTextInputChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
          disabled={isDisabled}
          value={data.mainBookOfBuried}
        />
      }
      {((data.graveyard && flag == "edit") || (data.graveyard && flag == "add") || (data.graveyard && flag == "view")) &&
        <Autocomplete
          id={"graveyard"}
          name={"graveyard"}
          options={graveyards}
          getOptionLabel={option => option.name}
          onChange={onAutocompleteChange}
          renderInput={params => (
            <TextField
              {...params}
              variant="standard"
              label={strings.stoneCuttingWorks.form.graveyard}
              margin="normal"
              fullWidth
              name={"graveyard"}
              onChange={handleTextInputChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
          disabled={isDisabled}
          value={data.graveyard}
        />
      }
      {((data.graveyard && flag == "edit") || (!data.graveyard && flag == "add") || (data.graveyard && flag == "view")) &&
        <Autocomplete
          id={"graveyard"}
          name={"graveyard"}
          options={graveyards}
          getOptionLabel={option => option.name}
          onChange={onAutocompleteChange}
          renderInput={params => (
            <TextField
              {...params}
              variant="standard"
              label={strings.stoneCuttingWorks.form.graveyard}
              margin="normal"
              fullWidth
              name={"graveyard"}
              onChange={handleTextInputChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
          disabled={isDisabled}
          value={data.graveyard}
        />
      }
      {((!data.graveyard && flag == "edit") || (!data.graveyard && flag == "view")) &&
        <Autocomplete
          id={"graveyard"}
          name={"graveyard"}
          options={graveyards}
          getOptionLabel={option => option.name}
          onChange={onAutocompleteChange}
          renderInput={params => (
            <TextField
              {...params}
              variant="standard"
              label={strings.stoneCuttingWorks.form.graveyard}
              margin="normal"
              fullWidth
              name={"graveyard"}
              onChange={handleTextInputChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
          disabled={isDisabled}
          value={data.graveyard}
        />
      }
      {((data.graveSite && flag == "edit") || (data.graveSite && flag == "add") || (data.graveSite && flag == "view")) && ((data.graveyard != undefined && data.graveyard != null)) &&
        <Autocomplete
          noOptionsText={(typedInGraveSite != undefined ? strings.mainBookOfBuried.form.create + typedInGraveSite : "")}
          onKeyPress={keyPress}
          id={"graveSite"}
          name={"graveSite"}
          options={graveSites}
          getOptionLabel={option => option.code}
          onChange={onAutocompleteChange}
          renderInput={params => (
            <TextField
              {...params}
              variant="standard"
              label={strings.stoneCuttingWorks.form.graveSite}
              error={hasError(errors, 'graveSite')}
              helperText={getError(errors, 'graveSite')}
              margin="normal"
              fullWidth
              name={"graveSite"}
              onChange={handleTextInputChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
          disabled={isDisabled}
          value={data.graveSite}
          disableClearable={true}
          onKeyDown={keyPressBackspace}
        />
      }
       {((data.graveSite && flag == "edit") || (!data.graveSite && flag == "add") || (data.graveSite && flag == "view")) && ((data.graveyard != undefined && data.graveyard != null)) &&
        <Autocomplete
          noOptionsText={(typedInGraveSite != undefined ? strings.mainBookOfBuried.form.create + typedInGraveSite : "")}
          onKeyPress={keyPress}
          id={"graveSite"}
          name={"graveSite"}
          options={graveSites}
          getOptionLabel={option => option.code}
          onChange={onAutocompleteChange}
          renderInput={params => (
            <TextField
              {...params}
              variant="standard"
              label={strings.stoneCuttingWorks.form.graveSite}
              error={hasError(errors, 'graveSite')}
              helperText={getError(errors, 'graveSite')}
              margin="normal"
              fullWidth
              name={"graveSite"}
              onChange={handleTextInputChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
          disabled={isDisabled}
          value={data.graveSite}
          disableClearable={true}
          onKeyDown={keyPressBackspace}
        />
      }
      {((!data.graveSite && flag == "edit") || (!data.graveSite && flag == "view") && (data.graveyard != undefined && data.graveyard != null)) &&
        <Autocomplete
          noOptionsText={(typedInGraveSite != undefined ? strings.mainBookOfBuried.form.create + typedInGraveSite : "")}
          onKeyPress={keyPress}
          id={"graveSite"}
          name={"graveSite"}
          options={graveSites}
          getOptionLabel={option => option.code}
          onChange={onAutocompleteChange}
          renderInput={params => (
            <TextField
              {...params}
              variant="standard"
              label={strings.stoneCuttingWorks.form.graveSite}
              error={hasError(errors, 'graveSite')}
              helperText={getError(errors, 'graveSite')}
              margin="normal"
              fullWidth
              name={"graveSite"}
              onChange={handleTextInputChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
          disabled={isDisabled}
          value={data.graveSite}
          disableClearable={true}
          onKeyDown={keyPressBackspace}
        />
      }

      {(data.graveyard == undefined || data.graveyard == null) &&
        <Autocomplete
          id={"graveSite"}
          name={"graveSite"}
          options={graveSites}
          getOptionLabel={option => option.code}
          onChange={onAutocompleteChange}
          renderInput={params => (
            <TextField
              {...params}
              variant="standard"
              label={strings.stoneCuttingWorks.form.graveSite}
              error={hasError(errors, 'graveSite')}
              helperText={getError(errors, 'graveSite')}
              margin="normal"
              fullWidth
              name={"graveSite"}
              onChange={handleTextInputChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
          disabled={true}
          value={null}
          disableClearable={true}
        />
      }
      <TextField
        label={strings.stoneCuttingWorks.form.tombstoneType}
        error={hasError(errors, 'tombstoneType')}
        helperText={getError(errors, 'tombstoneType')}
        fullWidth
        name='tombstoneType'
        onChange={onChange}
        margin="normal"
        defaultValue={data.tombstoneType}
        value={data.tombstoneType}
        InputLabelProps={{
          shrink: true,
        }}
        disabled={isDisabled}
      />
      <TextField
        label={strings.stoneCuttingWorks.form.permissionNumber}
        error={hasError(errors, 'permissionNumber')}
        helperText={getError(errors, 'permissionNumber')}
        fullWidth
        name='permissionNumber'
        onChange={onChange}
        margin="normal"
        defaultValue={data.permissionNumber}
        value={data.permissionNumber}
        InputLabelProps={{
          shrink: true,
        }}
        disabled={isDisabled}
      />
      {(data.permissionsStartDateString != undefined && data.permissionsStartDateString != null && data.permissionsStartDateString != "") &&
        <TextField
          label={strings.stoneCuttingWorks.form.permissionsStartDateString}
          fullWidth
          name='permissionsStartDateString'
          onChange={handleTextInputChange}
          margin="normal"
          value={data.permissionsStartDateString}
          InputLabelProps={{
            shrink: true,
          }}
          disabled={true}
        />
      }
      <DatePickerControl
        label={strings.stoneCuttingWorks.form.permissionStartDate}
        error={getError(errors, 'permissionStartDate')}
        hasError={hasError(errors, 'permissionStartDate')}
        helperText={getError(errors, 'permissionStartDate')}
        placeholder={strings.stoneCuttingWorks.form.permissionStartDate}
        name='permissionStartDate'
        onChange={onChange}
        //date={data.permissionStartDate ? new Date(data.permissionStartDate) : data.permissionStartDate}
        date={data.permissionStartDate ? stringToDate(data.permissionsStartDateString) > moment(data.permissionStartDate, 'YYYY-MM-DD') ? stringToDate(data.permissionsStartDateString).toDate() : new Date(data.permissionStartDate) : data.permissionStartDate}
        isClearable={true}
        dateFormat='dd/MM/yyyy'
        disabled={isDisabled}
      />
      {((data.citizen && flag == "edit") || (data.citizen && flag == "add") || (data.citizen && flag == "view")) &&
        <Autocomplete
          id={"citizen"}
          name={"citizen"}
          options={citizens}
          getOptionLabel={option => option.personalNumber}
          onChange={onAutocompleteChange}
          renderInput={params => (
            <TextField
              {...params}
              variant="standard"
              label={strings.stoneCuttingWorks.form.citizen}
              margin="normal"
              fullWidth
              name={"citizen"}
              onChange={handleTextInputChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
          disabled={isDisabled}
          value={data.citizen}
        />
      }
      {((data.citizen && flag == "edit") || (!data.citizen && flag == "add") || (data.citizen && flag == "view")) &&
        <Autocomplete
          id={"citizen"}
          name={"citizen"}
          options={citizens}
          getOptionLabel={option => option.personalNumber}
          onChange={onAutocompleteChange}
          renderInput={params => (
            <TextField
              {...params}
              variant="standard"
              label={strings.stoneCuttingWorks.form.citizen}
              margin="normal"
              fullWidth
              name={"citizen"}
              onChange={handleTextInputChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
          disabled={isDisabled}
          value={data.citizen}
        />
      }
      {((!data.citizen && flag == "edit") || (!data.citizen && flag == "view")) &&
        <Autocomplete
          id={"citizen"}
          name={"citizen"}
          options={citizens}
          getOptionLabel={option => option.personalNumber}
          onChange={onAutocompleteChange}
          renderInput={params => (
            <TextField
              {...params}
              variant="standard"
              label={strings.stoneCuttingWorks.form.citizen}
              margin="normal"
              fullWidth
              name={"citizen"}
              onChange={handleTextInputChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
          disabled={isDisabled}
          value={data.citizen}
        />
      }
      <TextField
        label={strings.stoneCuttingWorks.form.citizenNameSurname}
        error={hasError(errors, 'citizenNameSurname')}
        helperText={getError(errors, 'citizenNameSurname')}
        fullWidth
        name='citizenNameSurname'
        onChange={onChange}
        margin="normal"
        defaultValue={citizen.citizenNameSurname}
        value={citizen.citizenNameSurname}
        InputLabelProps={{
          shrink: true,
        }}
        disabled={true}
      />
      <TextField
        label={strings.stoneCuttingWorks.form.citizenAddress}
        error={hasError(errors, 'citizenAddress')}
        helperText={getError(errors, 'citizenAddress')}
        fullWidth
        name='citizenAddress'
        onChange={onChange}
        margin="normal"
        defaultValue={citizen.citizenAddress}
        value={citizen.citizenAddress}
        InputLabelProps={{
          shrink: true,
        }}
        disabled={true}
      />
      <TextField
        label={strings.stoneCuttingWorks.form.citizenSettlement}
        error={hasError(errors, 'citizenSettlement')}
        helperText={getError(errors, 'citizenSettlement')}
        fullWidth
        name='citizenSettlement'
        onChange={onChange}
        margin="normal"
        defaultValue={citizen.citizenSettlement}
        value={citizen.citizenSettlement}
        InputLabelProps={{
          shrink: true,
        }}
        disabled={true}
      />
      <TextField
        label={strings.stoneCuttingWorks.form.citizenTelephone}
        error={hasError(errors, 'citizenTelephone')}
        helperText={getError(errors, 'citizenTelephone')}
        fullWidth
        name='citizenTelephone'
        onChange={onChange}
        margin="normal"
        defaultValue={citizen.citizenTelephone}
        value={citizen.citizenTelephone}
        InputLabelProps={{
          shrink: true,
        }}
        disabled={true}
      />
      <TextField
        multiline={true}
        rowsMax={5}
        label={strings.stoneCuttingWorks.form.note}
        error={hasError(errors, 'note')}
        helperText={getError(errors, 'note')}
        fullWidth
        name='note'
        onChange={onChange}
        margin="normal"
        defaultValue={data.note}
        value={data.note}
        InputLabelProps={{
          shrink: true,
        }}
        disabled={isDisabled}
      />

      {
        !isDisabled &&
        <div className='submit-container'>
          <Button variant="contained" color="primary" onClick={onSubmit}>
            {strings.stonecutter.form.ok}
          </Button>
          <Button variant="contained" color="secondary" onClick={onCancel}>
            {strings.stonecutter.form.cancel}
          </Button>
        </div>
      }
    </form>
  )
};

export default StoneCuttingWorksForm;