import React from 'react'
import TablePage from "../../common/TablePage";
import { bindActionCreators } from "redux";
import * as Actions from "../../actions/Actions";
import { withRouter } from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import strings from "../../localization";
import { withSnackbar } from "notistack";
import { getGraveSites, getDeletedGraveSites, deleteGraveSite, restoreGraveSite } from '../../services/graveSite/GraveSiteService';
import AddGraveSite from './AddGraveSite';
import EditGraveSite from './EditGraveSite';
import ViewGraveSite from './ViewGraveSite';
import { getGraveSiteTypes, getGraveSiteRenewalTypes } from '../../util/ReviewsUtil';
import { getAllGraveyards } from '../../services/graveyard/GraveyardService';
import SelectControl from '../../components/controls/SelectControl';
import { Grid, TextField, Paper, Drawer, FormControlLabel, Switch } from '@material-ui/core';
import DrawerWrapper from '../../common/DrawerWrapper';
import PageState from '../../constants/PageState';
import update from 'immutability-helper';


class GraveSiteList extends TablePage {

  tableDescription = [
    { key: 'belongingGraveyardName', label: strings.graveSiteList.graveyardName, sort: 'belongingGraveyardName' },
    { key: 'code', label: strings.graveSiteList.graveSite, sort: 'code' },
    { key: 'expirationDate', label: strings.graveSiteList.expirationDate, transform: 'renderColumnDate', sort: 'expirationDate' },
    { key: 'dateUpdated', label: strings.graveSiteList.dateUpdated, transform: 'renderColumnDate', sort: 'dateUpdated' },
    { key: 'dateCreated', label: strings.graveSiteList.dateCreated, transform: 'renderColumnDate', sort: 'dateCreated' },
    { key: 'arrangedPlot', label: strings.graveSiteList.arrangedPlot, transform: 'renderColumnDeleted', sort: 'arrangedPlot' },
    { key: 'taken', label: strings.graveSiteList.used, transform: 'renderColumnDeleted', sort: 'taken' }
  ];

  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
      data: {
        graveyard: { id: -1 },
        graveSiteType: { value: -1 },
        graveSiteRenewalType: { value: -1 },
        removedItemsShow: false
      },
      filter: {
        graveSiteTypes: [],
        graveSiteRenewalTypes: []
      },
    };

    this.state.sortBy = "dateCreated,DESC";
    this.state.showDeleted = false;
    
  }

  fetchData() {
    let data = {
      searchParam: this.state.searchData.search,
      page: this.state.searchData.page - 1,
      size: this.state.searchData.perPage,
      companyId: this.state.companyId,
      sort: this.state.sortBy,
      type: this.state.data.graveSiteType.id != -1 ? this.state.data.graveSiteType.value : "-1",
      renewal: this.state.data.graveSiteRenewalType.id != -1 ? this.state.data.graveSiteRenewalType.value : -1,
      graveyardId: this.state.data.graveyard ? this.state.data.graveyard.id : -1,
      userId: this.props.auth.user.id
    };

    {
      (this.state.companyId != undefined && !this.state.showDeleted) &&
        getGraveSites(data).then(response => {
          if (!response.ok) {
            return;
          }
          this.setState({
            tableData: response.data.entities,
            total: response.data.total,
          });
        });
    }
    {
      (this.state.companyId != undefined && this.state.showDeleted) &&
      getDeletedGraveSites(data).then(response => {
          if (!response.ok) {
            return;
          }
          this.setState({
            tableData: response.data.entities,
            total: response.data.total,
          });
        });
    }

  }

  handleDeletedChange(){
    this.setState({
      showDeleted : !this.state.showDeleted
    },() =>{ this.fetchData(); })
    
  }

  usePermissions(){
    let data = {
      userId: this.props.auth.user.id,
      category: "Гробна места"
    }
    this.getPermissionsS(data);
  
  }

  componentDidMount() {
    this.usePermissions()
    this.setState({
      companyId: this.props.auth.user.company.id,
      filter: {
        graveSiteTypes: getGraveSiteTypes(),
        graveSiteRenewalTypes: getGraveSiteRenewalTypes()
      }
    }, () => {
      this.state.filter.graveSiteTypes.unshift({ value: -1, name: strings.graveSiteTypes.all });
      this.state.filter.graveSiteRenewalTypes.unshift({ value: -1, name: strings.graveSiteRenewalTypes.all });
      this.fetchData();
    })

    getAllGraveyards().then(response => {
      this.setState({
        filter: {
          ...this.state.filter,
          graveyards: response.data.entities
        }
      }, () => this.state.filter.graveyards.unshift({ id: -1, name: strings.years.all }));
    });
  }

  componentWillReceiveProps(nextProps) {
    strings.setLanguage(nextProps.auth.user.language);
    this.tableDescription = [
      { key: 'belongingGraveyardName', label: strings.graveSiteList.graveyardName, sort: 'belongingGraveyardName' },
      { key: 'code', label: strings.graveSiteList.graveSite, sort: 'code' },
      { key: 'expirationDate', label: strings.graveSiteList.expirationDate, transform: 'renderColumnDate', sort: 'expirationDate' },
      { key: 'dateUpdated', label: strings.graveSiteList.dateUpdated, transform: 'renderColumnDate', sort: 'dateUpdated' },
      { key: 'dateCreated', label: strings.graveSiteList.dateCreated, transform: 'renderColumnDate', sort: 'dateCreated' },
      { key: 'arrangedPlot', label: strings.graveSiteList.arrangedPlot, transform: 'renderColumnDeleted', sort: 'arrangedPlot' },
      { key: 'taken', label: strings.graveSiteList.used, transform: 'renderColumnDeleted', sort: 'taken' }
    ];
  }

  getPageHeader() {
    return <h1>{strings.graveSiteList.pageTitle}</h1>;
  }

  renderAddContent() {
    
    return <AddGraveSite cid={this.state.companyId} uemail={this.props.auth.user.email} onCancel={this.onCancel} onFinish={this.onFinish} flag={"add"} />
  }

  renderEditContent() {
    console.log("stejt", this.state)
    return <EditGraveSite
      id={this.state.selectedItem.id}
      uemail={this.props.auth.user.email}
      cid={this.state.companyId}
      onCancel={this.onCancel}
      onFinish={this.onFinish}
      isDisabled={false}
      flag={"edit"}
    />
  }

  renderViewContent() {

    return <ViewGraveSite id={this.state.selectedItem.id} isDisabled={true} onCancel={this.onCancel} onFinish={this.onFinish} flag={"view"} />
  }

  delete(item) {

    this.setState({
      lockTable: true
    });


    deleteGraveSite(item.id, this.props.auth.user.id).then(response => {

      if (response && !response.ok) {
        this.onFinish(null);
        return;
      }

      this.props.enqueueSnackbar(strings.companyList.graveSiteDeleted, { variant: 'success' });

      this.onFinish(item);
      this.cancelDelete();

      this.setState({
        lockTable: false
      });
    });
  }

  restore(item){
    
    restoreGraveSite(this.transformRequest(item)).then(response => {
      if (response && !response.ok) {
        this.onFinish(null);
        return;
      }
      this.setState({
        showDeleted : !this.state.showDeleted,
      },() =>{ this.fetchData(); })
    })
  }


  transformRequest(data) {
    return {
        ...data,
        userEmail: this.props.auth.user.email,
        companyId: this.state.companyId
    }
  }

  changeData(event, data = 'data') {
    this.setState({
      [data]: update(this.state[data], { [event.target.name]: { $set: event.target.value } })
    }, () => {
      this.fetchData();
    });
  }

  renderFilters() {
    return (
      <div className="renderFilters">

      {this.state.removedItemsShow &&
        <FormControlLabel
          control = {<Switch
            checked={this.state.showDeleted}
            onChange={this.handleDeletedChange}
            inputProps={{ 'aria-label': 'controlled' }}
            />}
            labelPlacement="start"
            label={!this.state.showDeleted ? "Prikazi obrisane" : "Prikazi neobrisane"}
        />}
        <SelectControl
          options={this.state.filter.graveSiteTypes}
          selected={this.state.data.graveSiteType}
          onChange={this.changeData}
          label={strings.graveSiteTypes.label}
          name={"graveSiteType"}
          nameKey={"name"}
          valueKey={"value"}
        />

        <SelectControl
          options={this.state.filter.graveSiteRenewalTypes}
          selected={this.state.data.graveSiteRenewalType}
          onChange={this.changeData}
          label={strings.graveSiteRenewalTypes.label}
          name={"graveSiteRenewalType"}
          nameKey={"name"}
          valueKey={"value"}
        />

        <SelectControl
          options={this.state.filter.graveyards}
          selected={this.state.data.graveyard}
          onChange={this.changeData}
          label={strings.mainBookOfBuriedList.graveyard}
          name={"graveyard"}
          nameKey={"name"}
          valueKey={"id"}
        />

      </div>
    );
  }

  render() {

    return (
      <Grid id='table-page'>
        {this.renderDialog(strings.table.confirmDelete, strings.delete.confirmation,
          this.cancelDelete, this.delete)}
        <div className='header'>
          {this.getPageHeader()}

          <div className='filter-controls'>
            {
              this.renderFilters()
            }
            {
              this.state.showSearch &&
              <TextField
                label={strings.table.search}
                type="search"
                name='search'
                value={this.state.searchData.search}
                onChange={this.searchChanged}
              />
            }

            {
              this.state.showAdd &&
              this.renderTableControls()
            }
          </div>
        </div>
        <Paper md={12}>
          {this.renderTable(this.state.tableData)}
        </Paper>

        <Drawer id='drawer' anchor='right' open={this.showDrawer()} onClose={() => this.setPageState(PageState.View)} ModalProps={{disableBackdropClick: true,}} >
          <DrawerWrapper onBack={() => this.setPageState(PageState.View)}>
            {this.renderDrawerContent()}
          </DrawerWrapper>
        </Drawer>
      </Grid>
    );
  }

}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    changeFullScreen: Actions.changeFullScreen
  }, dispatch);
}

function mapStateToProps({ menuReducers, authReducers, siteDataReducers }) {
  return { menu: menuReducers, auth: authReducers, siteData: siteDataReducers };
}

export default withSnackbar(withRouter(connect(mapStateToProps, mapDispatchToProps)(GraveSiteList)));