import {request} from "../../base/HTTP";
import HttpMethod from "../../constants/HttpMethod";
import ResponseTypes from "../../constants/ResponseTypes";

export async function getLeaseProcessingResults(data) {
    return await request('/api/leaseRenewal/processing', data);
}

export async function generateLeaseRenewalPdf(data) {
    return await request('/api/leaseRenewal/processing/generatePdf', data, HttpMethod.POST, { responseType: ResponseTypes.BLOB });
}

export async function getAllLeaseProcessingReports() {
    return await request('/api/leaseRenewal/processing/reports');
}

export async function createLeaseProcessingReport(data) {
    return await request('/api/leaseRenewal/processing/create-report', data, HttpMethod.POST);
}

export function loadLeaseProcessingReport(id) {
    return request("api/leaseRenewal/processing/load-report/" + id, {}, HttpMethod.GET);
}