import React from 'react'

import { bindActionCreators } from "redux";
import * as Actions from "../../actions/Actions";
import { withRouter } from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import strings from "../../localization";
import Page from "../../common/Page";
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Validators from "../../constants/ValidatorTypes";
import CreatePasswordForm from '../../components/forms/user/CreatePasswordForm';
import { createPassword } from '../../services/user/UserService';


class CreatePassword extends Page {

  validationList = {
    password: [{ type: Validators.REQUIRED }],
    passwordRepeat: [{ type: Validators.REQUIRED }],
    token: [{ type: Validators.REQUIRED }]
  };

  params = [{ name: 'registrationToken', default: '' }];

  constructor(props) {
    super(props);

    this.state = {
      data: {},
      errors: {},
    };

    this.props.changeFullScreen(true);

    this.keyPress = this.keyPress.bind(this);
  }

  componentDidMount() {
    if (this.props.auth.user) {
      this.props.history.push('/');
    }
  }

  keyPress(event) {

    if (event.key == 'Enter') {
      this.createPassword();
    }
  }

  passwordMach() {

    return this.state.data.password === this.state.data.passwordRepeat;
  }

  createPassword() {
    let data = this.state.data;
    data.token = this.state.searchData.registrationToken;

    if (!this.validate()) {
      return;
    }

    if (!this.passwordMach()) {
      this.setError('password', strings.registration.passwordDoNotMatch);
      return;
    }


    createPassword(data.token, data.password).then(response => {
      if (!response.ok) {
        this.setError("password", strings.validation.PasswordErrorMessage);
        return;
      }

      this.props.history.push('/login');
    });
  }

  render() {

    return (

      <div id='login'>
        <Grid item md={6}>
          <Paper className='paper'>
            <h1>{strings.createPassword.createPassword}</h1>
            <CreatePasswordForm
              onSubmit={() => this.createPassword()}
              onChange={this.changeData}
              keyPress={this.keyPress}
              data={this.state.data} errors={this.state.errors}
            />
          </Paper>
        </Grid>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    changeFullScreen: Actions.changeFullScreen,
    login: Actions.login
  }, dispatch);
}

function mapStateToProps({ menuReducers, authReducers }) {
  return { menu: menuReducers, auth: authReducers };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CreatePassword));