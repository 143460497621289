import { request } from "../../../base/HTTP";

export async function getCardGraveSiteAll(data) {
  return await request('/api/cardGraveSite/all', data);
}

export async function getDeceased(data){
  return await request('/api/cardGraveSite/deceased', data);
}

export async function getLease(data){
  return await request('/api/cardGraveSite/lease', data);
}

export async function getRenewal(data){
  return await request('/api/cardGraveSite/renewal', data);
}

export async function getExhumation(data){
  return await request('/api/cardGraveSite/exhumation', data);
}

export async function getDepth(data){
  return await request('/api/cardGraveSite/depth', data);
}