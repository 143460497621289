import React from 'react';
import strings from "../../../localization";
import { getError, hasError } from "../../../functions/Validation";
import { Button, TextField } from "@material-ui/core";

const DoctorForm = ({
  onSubmit,
  onCancel,
  onChange,
  errors,
  data,
  isDisabled
}) => {
  return (

    <form id='user-form'>
      {/* <TextField
        label={strings.doctor.form.code + "*"}
        error={hasError(errors, 'code')}
        helperText={getError(errors, 'code')}
        fullWidth
        autoFocus
        name='code'
        onChange={onChange}
        margin="normal"
        defaultValue={data.code}
        value={data.code}
        InputLabelProps={{
          shrink: true,
        }}
        disabled={isDisabled}
      /> */}
      <TextField
        label={strings.doctor.form.name + "*"}
        error={hasError(errors, 'name')}
        helperText={getError(errors, 'name')}
        fullWidth
        name='name'
        onChange={onChange}
        margin="normal"
        defaultValue={data.name}
        value={data.name}
        InputLabelProps={{
          shrink: true,
        }}
        disabled={isDisabled}
      />
      <TextField
        multiline={true}
        rowsMax={5}
        label={strings.doctor.form.address}
        error={hasError(errors, 'address')}
        helperText={getError(errors, 'address')}
        fullWidth
        name='address'
        onChange={onChange}
        margin="normal"
        defaultValue={data.address}
        value={data.address}
        InputLabelProps={{
          shrink: true,
        }}
        disabled={isDisabled}
      />

      {
        !isDisabled &&
        <div className='submit-container'>
          <Button variant="contained" color="primary" onClick={onSubmit}>
            {strings.doctor.form.ok}
          </Button>
          <Button variant="contained" color="secondary" onClick={onCancel}>
            {strings.doctor.form.cancel}
          </Button>
        </div>
      }
    </form>
  )
};

export default DoctorForm;