import React from 'react';
import strings from "../../../localization";
import { getError, hasError } from "../../../functions/Validation";
import { Button, TextField, FormLabel, FormControlLabel, Radio, FormControl, RadioGroup, Drawer } from "@material-ui/core";
import DatePickerControl from '../../controls/DatePickerControl';
import Autocomplete from '@material-ui/lab/Autocomplete';
import NumberFormat from 'react-number-format';
import { stringToDate } from '../../../util/DateUtil';
import moment from 'moment';

const ReservationForm = ({
  onSubmit,
  onCancel,
  onChange,
  errors,
  data,
  isDisabled,
  handleChange,
  onAutocompleteChange,
  handleTextInputChange,
  submitters,
  submitters2,
  submitter,
  submitter2,
  graveyards,
  graveSites,
  stonecutters,
  flag,
  date,
  flagAddNewSubmitter,
  flagAddNewSubmitter2,
  renderAddCitizen,
  flagMarginValid,
  flagMarginValid2,
  typedInGraveSite,
  keyPress,
  keyPressBackspace,
}) => {
  let renderAddCitizenButton = {
    marginLeft: "289px",
    marginTop: "-114px",
    paddingBottom: "-300px"
  }


  return (
    <form id='user-form'>
      <TextField
        label={strings.reservation.form.mainBookOfBuried + "*"}
        error={hasError(errors, 'mainBookOfBuriedString')}
        helperText={getError(errors, 'mainBookOfBuriedString')}
        fullWidth
        autoFocus
        name='mainBookOfBuriedString'
        onChange={onChange}
        margin="normal"
        defaultValue={date}
        value={data.mainBookOfBuriedString}
        InputLabelProps={{
          shrink: true,
        }}
        disabled={isDisabled}
      />


      {((data.submitter && flag == "edit") || (flag == "add") || (data.submitter && flag == "view")) &&
        <Autocomplete
          id={"submitter"}
          name={"submitter"}
          options={submitters}
          noOptionsText={""}
          getOptionLabel={option => option.personalNumber}
          onChange={onAutocompleteChange}
          renderInput={params => (
            <TextField
              {...params}
              variant="standard"
              label={strings.reservation.form.personalNumber}
              margin="normal"
              style={{ width: 275 }}
              name={"submitter"}
              onChange={handleTextInputChange}
              InputLabelProps={{
                shrink: true,
              }}
              error={hasError(errors, 'submitter')}
              helperText={getError(errors, 'submitter')}
            />
          )}
          error={hasError(errors, 'submitter')}
          helperText={getError(errors, 'submitter')}
          disabled={isDisabled}
          value={data.submitter}
        />
      }
      {((!data.submitter && flag == "edit") || (!data.submitter && flag == "view")) &&
        <Autocomplete
          id={"submitter"}
          name={"submitter"}
          options={submitters}
          getOptionLabel={option => option.personalNumber}
          onChange={onAutocompleteChange}
          renderInput={params => (
            <TextField
              {...params}
              variant="standard"
              label={strings.reservation.form.personalNumber}
              margin="normal"
              style={{ width: 275 }}
              name={"submitter"}
              onChange={handleTextInputChange}
              InputLabelProps={{
                shrink: true,
              }}
              error={hasError(errors, 'submitter')}
              helperText={getError(errors, 'submitter')}
            />
          )}
          error={hasError(errors, 'submitter')}
          helperText={getError(errors, 'submitter')}
          disabled={isDisabled}
          value={data.submitter}
        />
      }

      {flagAddNewSubmitter == true && flagMarginValid == true &&
        <div className='submit-container'>
          <Button variant="contained" color="secondary" onClick={renderAddCitizen} style={renderAddCitizenButton}>
            {strings.reservation.form.addNewSubmitter}
          </Button>

        </div>
      }

      {flagAddNewSubmitter == true && flagMarginValid == false &&
        <div className='submit-container'>
          <Button variant="contained" color="secondary" onClick={renderAddCitizen} style={renderAddCitizenButton}>
            {strings.reservation.form.addNewSubmitter}
          </Button>
          <div class="renderAddCitizenButtonNotValidPersonalNumber">
            {strings.citizen.messages.CITIZEN_PERSONAL_NUMBER_NOT_VALID}
          </div>
        </div>
      }

      <TextField
        label={strings.reservation.form.submitterName}
        error={hasError(errors, 'nameSurnameSubmitter')}
        helperText={getError(errors, 'nameSurnameSubmitter')}
        fullWidth
        name='nameSurnameSubmitter'
        onChange={onChange}
        margin="normal"
        defaultValue={submitter.nameSurnameSubmitter}
        value={submitter.nameSurnameSubmitter}
        InputLabelProps={{
          shrink: true,
        }}
        disabled={true}
      />
      <TextField
        label={strings.reservation.form.submitterAddress}
        error={hasError(errors, 'addressSubmitter')}
        helperText={getError(errors, 'addressSubmitter')}
        fullWidth
        name='addressSubmitter'
        onChange={onChange}
        margin="normal"
        defaultValue={submitter.addressSubmitter}
        value={submitter.addressSubmitter}
        InputLabelProps={{
          shrink: true,
        }}
        disabled={true}
      />
      <TextField
        label={strings.reservation.form.submitterMunicipality}
        error={hasError(errors, 'municipalitySubmitter')}
        helperText={getError(errors, 'municipalitySubmitter')}
        fullWidth
        name='municipalitySubmitter'
        onChange={onChange}
        margin="normal"
        defaultValue={submitter.municipalitySubmitter}
        value={submitter.municipalitySubmitter}
        InputLabelProps={{
          shrink: true,
        }}
        disabled={true}
      />
      <TextField
        label={strings.reservation.form.submitterTelephone}
        error={hasError(errors, 'telephoneSubmitter')}
        helperText={getError(errors, 'telephoneSubmitter')}
        fullWidth
        name='telephoneSubmitter'
        onChange={onChange}
        margin="normal"
        defaultValue={submitter.telephoneSubmitter}
        value={submitter.telephoneSubmitter}
        InputLabelProps={{
          shrink: true,
        }}
        disabled={true}
      />
      {((data.submitter2 && flag == "edit") || (flag == "add") || (data.submitter2 && flag == "view")) &&
        <Autocomplete
          id={"submitter2"}
          name={"submitter2"}
          options={submitters2}
          getOptionLabel={option => option.personalNumber}
          onChange={onAutocompleteChange}
          renderInput={params => (
            <TextField
              {...params}
              variant="standard"
              label={strings.reservation.form.personalNumber2}
              margin="normal"
              style={{ width: 275 }}
              name={"submitter2"}
              onChange={handleTextInputChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
          disabled={isDisabled}
          value={data.submitter2}
        />
      }
      {((!data.submitter2 && flag == "edit") || (!data.submitter2 && flag == "view")) &&
        <Autocomplete
          id={"submitter2"}
          name={"submitter2"}
          options={submitters2}
          getOptionLabel={option => option.personalNumber}
          onChange={onAutocompleteChange}
          renderInput={params => (
            <TextField
              {...params}
              variant="standard"
              label={strings.reservation.form.personalNumber2}
              margin="normal"
              style={{ width: 275 }}
              name={"submitter2"}
              onChange={handleTextInputChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
          disabled={isDisabled}
          value={data.submitter2}
        />
      }
      {flagAddNewSubmitter2 == true && flagMarginValid2 == true &&
        <div className='submit-container'>
          <Button variant="contained" color="secondary" onClick={renderAddCitizen} style={renderAddCitizenButton}>
            {strings.reservation.form.addNewSubmitter}
          </Button>

        </div>
      }

      {flagAddNewSubmitter2 == true && flagMarginValid2 == false &&
        <div className='submit-container'>
          <Button variant="contained" color="secondary" onClick={renderAddCitizen} style={renderAddCitizenButton}>
            {strings.reservation.form.addNewSubmitter}
          </Button>
          <div class="renderAddCitizenButtonNotValidPersonalNumber">
            {strings.citizen.messages.CITIZEN_PERSONAL_NUMBER_NOT_VALID}
          </div>
        </div>
      }

      <TextField
        label={strings.reservation.form.submitterName2}
        error={hasError(errors, 'nameSurnameSubmitter2')}
        helperText={getError(errors, 'nameSurnameSubmitter2')}
        fullWidth
        name='nameSurnameSubmitter2'
        onChange={onChange}
        margin="normal"
        defaultValue={submitter2.nameSurnameSubmitter2}
        value={submitter2.nameSurnameSubmitter2}
        InputLabelProps={{
          shrink: true,
        }}
        disabled={true}
      />
      <TextField
        label={strings.reservation.form.submitterAddress2}
        error={hasError(errors, 'addressSubmitter2')}
        helperText={getError(errors, 'addressSubmitter2')}
        fullWidth
        name='addressSubmitte2r'
        onChange={onChange}
        margin="normal"
        defaultValue={submitter2.addressSubmitter2}
        value={submitter2.addressSubmitter2}
        InputLabelProps={{
          shrink: true,
        }}
        disabled={true}
      />
      <TextField
        label={strings.reservation.form.submitterMunicipality2}
        error={hasError(errors, 'municipalitySubmitter2')}
        helperText={getError(errors, 'municipalitySubmitter2')}
        fullWidth
        name='municipalitySubmitter2'
        onChange={onChange}
        margin="normal"
        defaultValue={submitter2.municipalitySubmitter2}
        value={submitter2.municipalitySubmitter2}
        InputLabelProps={{
          shrink: true,
        }}
        disabled={true}
      />
      <TextField
        label={strings.reservation.form.submitterTelephone2}
        error={hasError(errors, 'telephoneSubmitter2')}
        helperText={getError(errors, 'telephoneSubmitter2')}
        fullWidth
        name='telephoneSubmitter2'
        onChange={onChange}
        margin="normal"
        defaultValue={submitter2.telephoneSubmitter2}
        value={submitter2.telephoneSubmitter2}
        InputLabelProps={{
          shrink: true,
        }}
        disabled={true}
      />
      <TextField
        label={strings.reservation.form.horizontal}
        error={hasError(errors, 'horizontal')}
        helperText={getError(errors, 'horizontal')}
        fullWidth
        name='horizontal'
        onChange={onChange}
        margin="normal"
        defaultValue={data.horizontal}
        value={data.horizontal}
        InputLabelProps={{
          shrink: true,
        }}
        disabled={isDisabled}
      />
      <TextField
        label={strings.reservation.form.vertical}
        error={hasError(errors, 'vertical')}
        helperText={getError(errors, 'vertical')}
        fullWidth
        name='vertical'
        onChange={onChange}
        margin="normal"
        defaultValue={data.vertical}
        value={data.vertical}
        InputLabelProps={{
          shrink: true,
        }}
        disabled={isDisabled}
      />
      <TextField
        label={strings.reservation.form.horizontalNumber}
        error={hasError(errors, 'horizontalNumber')}
        helperText={getError(errors, 'horizontalNumber')}
        fullWidth
        name='horizontalNumber'
        onChange={onChange}
        margin="normal"
        defaultValue={data.horizontalNumber}
        value={data.horizontalNumber}
        InputLabelProps={{
          shrink: true,
        }}
        disabled={isDisabled}
      />
      <TextField
        label={strings.reservation.form.verticalNumber}
        error={hasError(errors, 'verticalNumber')}
        helperText={getError(errors, 'verticalNumber')}
        fullWidth
        name='verticalNumber'
        onChange={onChange}
        margin="normal"
        defaultValue={data.verticalNumber}
        value={data.verticalNumber}
        InputLabelProps={{
          shrink: true,
        }}
        disabled={isDisabled}
      />
      {((data.graveyard && flag == "edit") || (flag == "add") || (data.graveyard && flag == "view")) &&
        <Autocomplete
          id={"graveyard"}
          name={"graveyard"}
          options={graveyards}
          getOptionLabel={option => option.name}
          onChange={onAutocompleteChange}
          renderInput={params => (
            <TextField
              {...params}
              variant="standard"
              label={strings.reservation.form.graveyard}
              error={hasError(errors, 'graveyard')}
              helperText={getError(errors, 'graveyard')}
              margin="normal"
              fullWidth
              name={"graveyard"}
              onChange={handleTextInputChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
          disabled={isDisabled}
          value={data.graveyard}
        />
      }
      {((!data.graveyard && flag == "edit") || (!data.graveyard && flag == "view")) &&
        <Autocomplete
          id={"graveyard"}
          name={"graveyard"}
          options={graveyards}
          getOptionLabel={option => option.name}
          onChange={onAutocompleteChange}
          renderInput={params => (
            <TextField
              {...params}
              variant="standard"
              label={strings.reservation.form.graveyard}
              error={hasError(errors, 'graveyard')}
              helperText={getError(errors, 'graveyard')}
              margin="normal"
              fullWidth
              name={"graveyard"}
              onChange={handleTextInputChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
          disabled={isDisabled}
          value={data.graveyard}
        />

      }
      {((data.graveSite && flag == "edit") || (flag == "add") || (data.graveSite && flag == "view")) && ((data.graveyard != undefined && data.graveyard != null)) &&
        <Autocomplete
          noOptionsText={(typedInGraveSite != undefined ? strings.mainBookOfBuried.form.create + typedInGraveSite : "")}
          onKeyPress={keyPress}
          id={"graveSite"}
          name={"graveSite"}
          options={graveSites}
          getOptionLabel={option => option.code}
          onChange={onAutocompleteChange}
          renderInput={params => (
            <TextField
              {...params}
              variant="standard"
              label={strings.reservation.form.graveSite}
              error={hasError(errors, 'graveSite')}
              helperText={getError(errors, 'graveSite')}
              margin="normal"
              fullWidth
              name={"graveSite"}
              onChange={handleTextInputChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
          disabled={isDisabled}
          value={data.graveSite}
          disableClearable={true}
          onKeyDown={keyPressBackspace}
        />
      }
      {((!data.graveSite && flag == "edit") || (!data.graveSite && flag == "view")) && (data.graveyard != undefined && data.graveyard != null) &&
        <Autocomplete
          noOptionsText={(typedInGraveSite != undefined ? strings.mainBookOfBuried.form.create + typedInGraveSite : "")}
          onKeyPress={keyPress}
          id={"graveSite"}
          name={"graveSite"}
          options={graveSites}
          getOptionLabel={option => option.code}
          onChange={onAutocompleteChange}
          renderInput={params => (
            <TextField
              {...params}
              variant="standard"
              label={strings.reservation.form.graveSite}
              error={hasError(errors, 'graveSite')}
              helperText={getError(errors, 'graveSite')}
              margin="normal"
              fullWidth
              name={"graveSite"}
              onChange={handleTextInputChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
          disabled={isDisabled}
          value={data.graveSite}
          disableClearable={true}
          onKeyDown={keyPressBackspace}
        />
      }

      {(data.graveyard == undefined || data.graveyard == null) &&
        <Autocomplete
          id={"graveSite"}
          name={"graveSite"}
          options={graveSites}
          getOptionLabel={option => option.code}
          onChange={onAutocompleteChange}
          renderInput={params => (
            <TextField
              {...params}
              variant="standard"
              label={strings.reservation.form.graveSite}
              error={hasError(errors, 'graveSite')}
              helperText={getError(errors, 'graveSite')}
              margin="normal"
              fullWidth
              name={"graveSite"}
              onChange={handleTextInputChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
          disabled={true}
          value={null}
          disableClearable={true}
        />
      }

      <FormControl component="fieldset">
        <FormLabel className="checkboxLabel"> {strings.reservation.form.deceasedInside} </FormLabel>
        <RadioGroup name="deceasedInside" value={(data.deceasedInside == true || data.deceasedInside == "true") ? "true" : "false"} onChange={handleChange} className="radioGroup">
          <FormControlLabel value={"true"} disabled={isDisabled} control={<Radio />} label={<span className="formControlLabel">{strings.reservation.form.yes}</span>} />
          <FormControlLabel value={"false"} disabled={isDisabled} control={<Radio />} label={<span className="formControlLabel">{strings.reservation.form.no}</span>} />
        </RadioGroup>
      </FormControl>
      <TextField
        label={strings.reservation.form.tombstoneType}
        error={hasError(errors, 'tombstoneType')}
        helperText={getError(errors, 'tombstoneType')}
        fullWidth
        name='tombstoneType'
        onChange={onChange}
        margin="normal"
        defaultValue={data.tombstoneType}
        value={data.tombstoneType}
        InputLabelProps={{
          shrink: true,
        }}
        disabled={isDisabled}
      />
      {(data.tombstoneFromDateString != undefined && data.tombstoneFromDateString != null && data.tombstoneFromDateString != "") &&
        <TextField
          label={strings.reservation.form.tombstoneFromDateString}
          fullWidth
          name='tombstoneFromDateString'
          onChange={handleTextInputChange}
          margin="normal"
          value={data.tombstoneFromDateString}
          InputLabelProps={{
            shrink: true,
          }}
          disabled={true}
        />
      }
      <DatePickerControl
        label={strings.reservation.form.tombstoneFromDate}
        error={getError(errors, 'tombstoneFromDate')}
        hasError={hasError(errors, 'tombstoneFromDate')}
        helperText={getError(errors, 'tombstoneFromDate')}
        placeholder={strings.reservation.form.tombstoneFromDate}
        name='tombstoneFromDate'
        onChange={onChange}
        date={data.tombstoneFromDate ? new Date(data.tombstoneFromDate) : data.tombstoneFromDate}
        isClearable={true}
        dateFormat='dd/MM/yyyy'
        disabled={isDisabled}
      />
      {((data.stonecutter && flag == "edit") || (flag == "add") || (data.stonecutter && flag == "view")) &&
        <Autocomplete
          id={"stonecutter"}
          name={"stonecutter"}
          options={stonecutters}
          getOptionLabel={option => option.name}
          onChange={onAutocompleteChange}
          renderInput={params => (
            <TextField
              {...params}
              variant="standard"
              label={strings.reservation.form.stonecutter}
              margin="normal"
              fullWidth
              name={"stonecutter"}
              onChange={handleTextInputChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
          disabled={isDisabled}
          value={data.stonecutter}
        />
      }
      {((!data.stonecutter && flag == "edit") || (!data.stonecutter && flag == "view")) &&
        <Autocomplete
          id={"stonecutter"}
          name={"stonecutter"}
          options={stonecutters}
          getOptionLabel={option => option.name}
          onChange={onAutocompleteChange}
          renderInput={params => (
            <TextField
              {...params}
              variant="standard"
              label={strings.reservation.form.stonecutter}
              margin="normal"
              fullWidth
              name={"stonecutter"}
              onChange={handleTextInputChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
          disabled={isDisabled}
          value={data.stonecutter}
        />
      }
      {(data.expiryDateString != undefined && data.expiryDateString != null && data.expiryDateString != "") &&
        <TextField
          label={strings.reservation.form.expiryDateString}
          fullWidth
          name='expiryDateString'
          onChange={handleTextInputChange}
          margin="normal"
          value={data.expiryDateString}
          InputLabelProps={{
            shrink: true,
          }}
          disabled={true}
        />
      }
      {(flag == 'add' || flag == 'view') &&
        <DatePickerControl
          label={strings.reservation.form.expiryDate}
          error={getError(errors, 'expiryDate')}
          hasError={hasError(errors, 'expiryDate')}
          helperText={getError(errors, 'expiryDate')}
          placeholder={strings.reservation.form.expiryDate}
          name='expiryDate'
          onChange={onChange}
          //date={data.expiryDate ? new Date(data.expiryDate) : data.expiryDate}
          date={data.expiryDate ? stringToDate(data.expiryDateString) > moment(data.expiryDate, 'YYYY-MM-DD') ? stringToDate(data.expiryDateString).toDate() : new Date(data.expiryDate) : data.expiryDate}
          isClearable={true}
          dateFormat='dd/MM/yyyy'
          disabled={isDisabled}
        />
      }
      {(flag == 'edit') &&
        <DatePickerControl
          label={strings.reservation.form.expiryDate}
          error={getError(errors, 'expiryDate')}
          hasError={hasError(errors, 'expiryDate')}
          helperText={getError(errors, 'expiryDate')}
          placeholder={strings.reservation.form.expiryDate}
          name='expiryDate'
          onChange={onChange}
          date={data.expiryDate ? new Date(data.expiryDate) : data.expiryDate}
          isClearable={true}
          dateFormat='dd/MM/yyyy'
          disabled={isDisabled}
        />
      }
      <TextField
        label={strings.reservation.form.contractNumber}
        error={hasError(errors, 'contractNumber')}
        helperText={getError(errors, 'contractNumber')}
        fullWidth
        name='contractNumber'
        onChange={onChange}
        margin="normal"
        defaultValue={data.contractNumber}
        value={data.contractNumber}
        InputLabelProps={{
          shrink: true,
        }}
        disabled={isDisabled}
      />
      {(data.contractDateString != undefined && data.contractDateString != null && data.contractDateString != "") &&
        <TextField
          label={strings.reservation.form.contractDateString}
          fullWidth
          name='contractDateString'
          onChange={handleTextInputChange}
          margin="normal"
          value={data.contractDateString}
          InputLabelProps={{
            shrink: true,
          }}
          disabled={true}
        />
      }
      <DatePickerControl
        label={strings.reservation.form.contractDate}
        error={getError(errors, 'contractDate')}
        hasError={hasError(errors, 'contractDate')}
        helperText={getError(errors, 'contractDate')}
        placeholder={strings.reservation.form.contractDate}
        name='contractDate'
        onChange={onChange}
        //date={data.contractDate ? new Date(data.contractDate) : data.contractDate}
        date={data.contractDate ? stringToDate(data.contractDateString) > moment(data.contractDate, 'YYYY-MM-DD') ? stringToDate(data.contractDateString).toDate() : new Date(data.contractDate) : data.contractDate}
        isClearable={true}
        dateFormat='dd/MM/yyyy'
        disabled={isDisabled}
      />
      
      <TextField
        label={strings.reservation.form.paymentNumber}
        error={hasError(errors, 'paymentNumber')}
        helperText={getError(errors, 'paymentNumber')}
        fullWidth
        name='paymentNumber'
        onChange={onChange}
        margin="normal"
        defaultValue={data.paymentNumber}
        value={data.paymentNumber}
        InputLabelProps={{
          shrink: true,
        }}
        disabled={isDisabled}
      />
      {(data.dateOfRentString != undefined && data.dateOfRentString != null && data.dateOfRentString != "") &&
        <TextField
          label={strings.reservation.form.dateOfRentString}
          fullWidth
          name='dateOfRentString'
          onChange={handleTextInputChange}
          margin="normal"
          value={data.dateOfRentString}
          InputLabelProps={{
            shrink: true,
          }}
          disabled={true}
        />
      }
      <DatePickerControl
        label={strings.reservation.form.dateOfRent + "*"}
        error={getError(errors, 'dateOfRent')}
        hasError={hasError(errors, 'dateOfRent')}
        helperText={getError(errors, 'dateOfRent')}
        placeholder={strings.reservation.form.dateOfRent}
        name='dateOfRent'
        onChange={onChange}
        //date={data.dateOfRent ? new Date(data.dateOfRent) : data.dateOfRent}
        date={data.dateOfRent ? stringToDate(data.dateOfRentString) > moment(data.dateOfRent, 'YYYY-MM-DD') ? stringToDate(data.dateOfRentString).toDate() : new Date(data.dateOfRent) : data.dateOfRent}
        isClearable={true}
        dateFormat='dd/MM/yyyy'
        disabled={isDisabled}
      />

{(data.dateOfCancellationString != undefined && data.dateOfCancellationString != null && data.dateOfCancellationString != "") &&
        <TextField
          label={strings.reservation.form.dateOfCancellation}
          fullWidth
          name='dateOfCancellationString'
          onChange={handleTextInputChange}
          margin="normal"
          value={data.dateOfCancellationString}
          InputLabelProps={{
            shrink: true,
          }}
          disabled={true}
        />
      }
      {(flag == "add" || flag == "edit" || flag == "view") &&
        <NumberFormat
          thousandSeparator={true} className="" inputmode="numeric" displayType="numeric"
          customInput={TextField}
          type="text"
          label={strings.reservation.form.amount}
          error={hasError(errors, 'amount')}
          helperText={getError(errors, 'amount')}
          fullWidth
          name='amount'
          onChange={onChange}
          margin="normal"
          defaultValue={data.amount}
          value={data.amount}
          InputLabelProps={{
            shrink: true,
          }}
          disabled={isDisabled}
          placeholder="0.00"
        />
      }

      <FormControl component="fieldset">
        <FormLabel className="checkboxLabel"> {strings.reservation.form.ban} </FormLabel>
        <RadioGroup name="ban" value={(data.z == true || data.z == "true") ? "true" : "false"} onChange={handleChange} className="radioGroup">
          <FormControlLabel value={"true"} disabled={isDisabled} control={<Radio />} label={<span className="formControlLabel">{strings.reservation.form.yes}</span>} />
          <FormControlLabel value={"false"} disabled={isDisabled} control={<Radio />} label={<span className="formControlLabel">{strings.reservation.form.no}</span>} />
        </RadioGroup>
      </FormControl>

      <TextField
        multiline={true}
        // rowsMax={5}
        label={strings.reservation.form.note}
        error={hasError(errors, 'note')}
        helperText={getError(errors, 'note')}
        fullWidth
        name='note'
        onChange={onChange}
        margin="normal"
        defaultValue={data.note}
        value={data.note}
        InputLabelProps={{
          shrink: true,
        }}
        disabled={isDisabled}
      />
      {
        !isDisabled &&
        <div className='submit-container'>
          <Button variant="contained" color="primary" onClick={onSubmit}>
            {strings.reservation.form.ok}
          </Button>
          <Button variant="contained" color="secondary" onClick={onCancel}>
            {strings.reservation.form.cancel}
          </Button>
        </div>
      }
    </form>
  )
};

export default ReservationForm;