import React from 'react';
import { bindActionCreators } from "redux";
import { withSnackbar } from "notistack";
import { withRouter } from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import * as Actions from "../../../../actions/Actions";
import Page from '../../../../common/Page';

class InheritedGraveSitesForCurrentYearPerMonthsList extends Page {

  render() {
    return (<h1>REZERVACIJE 3</h1>);
  }

}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    changeFullScreen: Actions.changeFullScreen
  }, dispatch);
}

function mapStateToProps({ menuReducers, authReducers, siteDataReducers }) {
  return { menu: menuReducers, auth: authReducers, siteData: siteDataReducers };
}

export default withSnackbar(withRouter(connect(mapStateToProps, mapDispatchToProps)(InheritedGraveSitesForCurrentYearPerMonthsList)));
