import React from 'react';
import strings from "../../../localization";
import { getError, hasError } from "../../../functions/Validation";
import { Button, TextField } from "@material-ui/core";

const FirmForm = ({
    onSubmit,
    onCancel,
    onChange,
    errors,
    data,
    isDisabled
}) => {
    return (

        <form id='user-form'>
            <TextField
                label={strings.firm.form.taxNumber + "*"}
                error={hasError(errors, 'taxNumber')}
                helperText={getError(errors, 'taxNumber')}
                fullWidth
                autoFocus
                name='taxNumber'
                onChange={onChange}
                margin="normal"
                defaultValue={data.taxNumber}
                value={data.taxNumber}
                InputLabelProps={{
                    shrink: true,
                }}
                disabled = {isDisabled}
            />
            <TextField
                label={strings.firm.form.name + "*"}
                error={hasError(errors, 'name')}
                helperText={getError(errors, 'name')}
                fullWidth
                name='name'
                onChange={onChange}
                margin="normal"
                defaultValue={data.name}
                value={data.name}
                InputLabelProps={{
                    shrink: true,
                }}
                disabled = {isDisabled}
            />
            <TextField
                label={strings.firm.form.headquarters + "*"}
                error={hasError(errors, 'headquarters')}
                helperText={getError(errors, 'headquarters')}
                fullWidth
                name='headquarters'
                onChange={onChange}
                margin="normal"
                defaultValue={data.headquarters}
                value={data.headquarters}
                InputLabelProps={{
                    shrink: true,
                }}
                disabled = {isDisabled}
            />
            <TextField
                label={strings.firm.form.street + "*"}
                error={hasError(errors, 'street')}
                helperText={getError(errors, 'street')}
                fullWidth
                name='street'
                onChange={onChange}
                margin="normal"
                defaultValue={data.street}
                value={data.street}
                InputLabelProps={{
                    shrink: true,
                }}
                disabled = {isDisabled}
            />
            <TextField
                label={strings.firm.form.streetNumber + "*"}
                error={hasError(errors, 'streetNumber')}
                helperText={getError(errors, 'streetNumber')}
                fullWidth
                name='streetNumber'
                onChange={onChange}
                margin="normal"
                defaultValue={data.streetNumber}
                value={data.streetNumber}
                InputLabelProps={{
                    shrink: true,
                }}
                disabled = {isDisabled}
            />
            <TextField
                label={strings.firm.form.contactPerson}
                error={hasError(errors, 'contactPerson')}
                helperText={getError(errors, 'contactPerson')}
                fullWidth
                name='contactPerson'
                onChange={onChange}
                margin="normal"
                defaultValue={data.contactPerson}
                value={data.contactPerson}
                InputLabelProps={{
                    shrink: true,
                }}
                disabled = {isDisabled}
            />
            <TextField
                label={strings.firm.form.telephone}
                error={hasError(errors, 'telephone')}
                helperText={getError(errors, 'telephone')}
                fullWidth
                name='telephone'
                onChange={onChange}
                margin="normal"
                defaultValue={data.telephone}
                value={data.telephone}
                InputLabelProps={{
                    shrink: true,
                }}
                disabled = {isDisabled}
            />
            
            {
                !isDisabled &&
                <div className='submit-container'>
                    <Button variant="contained" color="primary" onClick={onSubmit}>
                        {strings.firm.form.ok}
                    </Button>
                    <Button variant="contained" color="secondary" onClick={onCancel}>
                        {strings.firm.form.cancel}
                    </Button>
                </div>
            }
        </form>
    )};

export default FirmForm;