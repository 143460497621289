import React from 'react';
import strings from "../../../localization";
import { getError, hasError } from "../../../functions/Validation";
import { Button, TextField, FormLabel, FormControlLabel, Radio, FormControl, RadioGroup, Checkbox } from "@material-ui/core";
import DatePickerControl from '../../controls/DatePickerControl';

const ExhumationRequestForm = ({
  onSubmit,
  onCancel,
  onChange,
  errors,
  data,
  date,
  isDisabled,
  disabled,
  handleChange,
  graveSiteType,
  graveSiteTypes,
  handleGraveSiteTypeCheckboxChange,
}) => {
  return (
    <form id='user-form'>
        <TextField
            label={strings.exhumationRequest.form.exhumationRequestNumber + " (генерисано аутоматски)"}
            error={hasError(errors, 'exhumationRequestNumber')}
            helperText={getError(errors, 'exhumationRequestNumber')}
            fullWidth
            autoFocus
            name='exhumationRequestNumber'
            onChange={onChange}
            margin="normal"
            defaultValue={date}
            value={data.exhumationRequestNumber}
            InputLabelProps={{
                shrink: true,
            }}
            disabled={true}
        />
        <div>
            <FormControl component="fieldset">
                <FormLabel className="checkboxLabel"> {strings.exhumationRequest.form.gender} </FormLabel>
                <RadioGroup name="gender" value={(data.gender == true || data.gender == "true") ? "true" : "false"} onChange={handleChange} className="radioGroup">
                    <FormControlLabel value={"true"} disabled={isDisabled} control={<Radio />} label={<span className="formControlLabel">{strings.exhumationRequest.form.male}</span>} />
                    <FormControlLabel value={"false"} disabled={isDisabled} control={<Radio />} label={<span className="formControlLabel">{strings.exhumationRequest.form.female}</span>} />
                </RadioGroup>
            </FormControl>
        </div>

        <TextField
            label={strings.exhumationRequest.form.deceasedNameSurname}
            error={hasError(errors, 'deceasedNameSurname')}
            helperText={getError(errors, 'deceasedNameSurname')}
            fullWidth
            name='deceasedNameSurname'
            onChange={onChange}
            margin="normal"
            defaultValue={data.deceasedNameSurname}
            value={data.deceasedNameSurname}
            InputLabelProps={{
                shrink: true,
            }}
            disabled={isDisabled}
        />

        <TextField
            label={strings.exhumationRequest.form.placeOfRest}
            error={hasError(errors, 'placeOfRest')}
            helperText={getError(errors, 'placeOfRest')}
            fullWidth
            name='placeOfRest'
            onChange={onChange}
            margin="normal"
            defaultValue={data.placeOfRest}
            value={data.placeOfRest}
            InputLabelProps={{
                shrink: true,
            }}
            disabled={isDisabled}
        />

        <TextField
            label={strings.exhumationRequest.form.fromGraveyard}
            error={hasError(errors, 'fromGraveyard')}
            helperText={getError(errors, 'fromGraveyard')}
            fullWidth
            name='fromGraveyard'
            onChange={onChange}
            margin="normal"
            defaultValue={data.fromGraveyard}
            value={data.fromGraveyard}
            InputLabelProps={{
                shrink: true,
            }}
            disabled={isDisabled}
        />

        <TextField
            label={strings.exhumationRequest.form.fromGraveSite}
            error={hasError(errors, 'fromGraveSite')}
            helperText={getError(errors, 'fromGraveSite')}
            fullWidth
            name='fromGraveSite'
            onChange={onChange}
            margin="normal"
            defaultValue={data.fromGraveSite}
            value={data.fromGraveSite}
            InputLabelProps={{
                shrink: true,
            }}
            disabled={isDisabled}
        />

        <TextField
            label={strings.exhumationRequest.form.dateOfFuneralString}
            error={hasError(errors, 'dateOfFuneralString')}
            helperText={getError(errors, 'dateOfFuneralString')}
            fullWidth
            name='dateOfFuneralString'
            onChange={onChange}
            margin="normal"
            defaultValue={data.dateOfFuneralString}
            value={data.dateOfFuneralString}
            InputLabelProps={{
                shrink: true,
            }}
            disabled={isDisabled}
        />

        <TextField
            label={strings.exhumationRequest.form.field}
            error={hasError(errors, 'field')}
            helperText={getError(errors, 'field')}
            fullWidth
            name='field'
            onChange={onChange}
            margin="normal"
            defaultValue={data.field}
            value={data.field}
            InputLabelProps={{
                shrink: true,
            }}
            disabled={isDisabled}
        />

        <TextField
            label={strings.exhumationRequest.form.placeOfTakeover}
            error={hasError(errors, 'placeOfTakeover')}
            helperText={getError(errors, 'placeOfTakeover')}
            fullWidth
            name='placeOfTakeover'
            onChange={onChange}
            margin="normal"
            defaultValue={data.placeOfTakeover}
            value={data.placeOfTakeover}
            InputLabelProps={{
                shrink: true,
            }}
            disabled={isDisabled}
        />

        <TextField
            label={strings.exhumationRequest.form.toGraveyard}
            error={hasError(errors, 'toGraveyard')}
            helperText={getError(errors, 'toGraveyard')}
            fullWidth
            name='toGraveyard'
            onChange={onChange}
            margin="normal"
            defaultValue={data.toGraveyard}
            value={data.toGraveyard}
            InputLabelProps={{
                shrink: true,
            }}
            disabled={isDisabled}
        />

        <TextField
            label={strings.exhumationRequest.form.toGraveSite}
            error={hasError(errors, 'toGraveSite')}
            helperText={getError(errors, 'toGraveSite')}
            fullWidth
            name='toGraveSite'
            onChange={onChange}
            margin="normal"
            defaultValue={data.toGraveSite}
            value={data.toGraveSite}
            InputLabelProps={{
                shrink: true,
            }}
            disabled={isDisabled}
        />

        <label className="checkboxLabel">{strings.exhumationRequest.form.graveSiteType}</label>
        <div>
            {graveSiteTypes.map(type => (
                <div>
                    <FormControlLabel
                        key={type.id}
                        control={
                            <Checkbox
                                checked={graveSiteType && graveSiteType.id === type.id}
                                onChange={handleGraveSiteTypeCheckboxChange(type.name, type.id, type.value)}
                                disabled={isDisabled}
                            />
                        }
                        label={<span className="formControlLabel">{type.name}</span>}
                        style={{fontSize: '10px'}}
                    />
                    {(graveSiteType && graveSiteType.id === type.id && graveSiteType.name === strings.exhumationRequest.tomb) &&
                        <React.Fragment>
                            <TextField
                                label={strings.exhumationRequest.form.numberOfCasketsForTomb}
                                error={hasError(errors, 'numberOfCasketsForTomb')}
                                helperText={getError(errors, 'numberOfCasketsForTomb')}
                                fullWidth
                                name='numberOfCasketsForTomb'
                                onChange={onChange}
                                margin="normal"
                                defaultValue={data.numberOfCasketsForTomb}
                                value={data.numberOfCasketsForTomb}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                disabled={isDisabled}
                            />
                            <div>
                                <FormControl component="fieldset">
                                    <FormLabel className="checkboxLabel"> {strings.exhumationRequest.form.tombEntrance} </FormLabel>
                                    <RadioGroup name="tombEntrance" value={(data.tombEntrance == true || data.tombEntrance == "true") ? "true" : "false"} onChange={handleChange} className="radioGroup">
                                        <FormControlLabel value={"true"} disabled={isDisabled} control={<Radio />} label={<span className="formControlLabel">{strings.exhumationRequest.form.entranceFromTop}</span>} />
                                        <FormControlLabel value={"false"} disabled={isDisabled} control={<Radio />} label={<span className="formControlLabel">{strings.exhumationRequest.form.entranceFromSide}</span>} />
                                    </RadioGroup>
                                </FormControl>
                            </div>
                        </React.Fragment>
                    }
                    {(graveSiteType && graveSiteType.id === type.id && graveSiteType.name === strings.exhumationRequest.other) &&
                        <TextField
                            label={strings.exhumationRequest.form.graveSiteTypeOtherNotes}
                            error={hasError(errors, 'graveSiteTypeOtherNotes')}
                            helperText={getError(errors, 'graveSiteTypeOtherNotes')}
                            fullWidth
                            name='graveSiteTypeOtherNotes'
                            onChange={onChange}
                            margin="normal"
                            defaultValue={data.graveSiteTypeOtherNotes}
                            value={data.graveSiteTypeOtherNotes}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            disabled={isDisabled}
                        />
                    }              
                </div>
            ))}
        </div>

        <div>
            <FormControl component="fieldset">
                <FormLabel className="checkboxLabel"> {strings.exhumationRequest.form.hasTombstone} </FormLabel>
                <RadioGroup name="hasTombstone" value={(data.hasTombstone == true || data.hasTombstone == "true") ? "true" : "false"} onChange={handleChange} className="radioGroup">
                    <FormControlLabel value={"true"} disabled={isDisabled} control={<Radio />} label={<span className="formControlLabel">{strings.exhumationRequest.form.withTombstone}</span>} />
                    <FormControlLabel value={"false"} disabled={isDisabled} control={<Radio />} label={<span className="formControlLabel">{strings.exhumationRequest.form.noTombstone}</span>} />
                </RadioGroup>
            </FormControl>
        </div>

        <div>
            <FormControl component="fieldset">
                <FormLabel className="checkboxLabel"> {strings.exhumationRequest.form.hasDeceased} </FormLabel>
                <RadioGroup name="hasDeceased" value={(data.hasDeceased == true || data.hasDeceased == "true") ? "true" : "false"} onChange={handleChange} className="radioGroup">
                    <FormControlLabel value={"true"} disabled={isDisabled} control={<Radio />} label={<span className="formControlLabel">{strings.exhumationRequest.form.withDeceased}</span>} />
                    <FormControlLabel value={"false"} disabled={isDisabled} control={<Radio />} label={<span className="formControlLabel">{strings.exhumationRequest.form.noDeceased}</span>} />
                </RadioGroup>
            </FormControl>
        </div>

        {(data.hasDeceased == "true" || data.hasDeceased) &&
            <TextField
                label={strings.exhumationRequest.form.deceasedList}
                error={hasError(errors, 'deceasedList')}
                helperText={getError(errors, 'deceasedList')}
                fullWidth
                name='deceasedList'
                onChange={onChange}
                margin="normal"
                defaultValue={data.deceasedList}
                value={data.deceasedList}
                InputLabelProps={{
                shrink: true,
                }}
                disabled={isDisabled}
            />
        }

        <TextField
            label={strings.exhumationRequest.form.requestSubmitterNameSurname}
            error={hasError(errors, 'requestSubmitterNameSurname')}
            helperText={getError(errors, 'requestSubmitterNameSurname')}
            fullWidth
            name='requestSubmitterNameSurname'
            onChange={onChange}
            margin="normal"
            defaultValue={data.requestSubmitterNameSurname}
            value={data.requestSubmitterNameSurname}
            InputLabelProps={{
                shrink: true,
            }}
            disabled={isDisabled}
        />

        <TextField
            label={strings.exhumationRequest.form.requestSubmitterMunicipality}
            error={hasError(errors, 'requestSubmitterMunicipality')}
            helperText={getError(errors, 'requestSubmitterMunicipality')}
            fullWidth
            name='requestSubmitterMunicipality'
            onChange={onChange}
            margin="normal"
            defaultValue={data.requestSubmitterMunicipality}
            value={data.requestSubmitterMunicipality}
            InputLabelProps={{
                shrink: true,
            }}
            disabled={isDisabled}
        />

        <TextField
            label={strings.exhumationRequest.form.requestSubmitterAddress}
            error={hasError(errors, 'requestSubmitterAddress')}
            helperText={getError(errors, 'requestSubmitterAddress')}
            fullWidth
            name='requestSubmitterAddress'
            onChange={onChange}
            margin="normal"
            defaultValue={data.requestSubmitterAddress}
            value={data.requestSubmitterAddress}
            InputLabelProps={{
                shrink: true,
            }}
            disabled={isDisabled}
        />

        <TextField
            label={strings.exhumationRequest.form.requestSubmitterIDCardOrPassportNumber}
            error={hasError(errors, 'requestSubmitterIDCardOrPassportNumber')}
            helperText={getError(errors, 'requestSubmitterIDCardOrPassportNumber')}
            fullWidth
            name='requestSubmitterIDCardOrPassportNumber'
            onChange={onChange}
            margin="normal"
            defaultValue={data.requestSubmitterIDCardOrPassportNumber}
            value={data.requestSubmitterIDCardOrPassportNumber}
            InputLabelProps={{
                shrink: true,
            }}
            disabled={isDisabled}
        />

        <TextField
            label={strings.exhumationRequest.form.requestSubmitterIdentificationNumber}
            error={hasError(errors, 'requestSubmitterIdentificationNumber')}
            helperText={getError(errors, 'requestSubmitterIdentificationNumber')}
            fullWidth
            name='requestSubmitterIdentificationNumber'
            onChange={onChange}
            margin="normal"
            defaultValue={data.requestSubmitterIdentificationNumber}
            value={data.requestSubmitterIdentificationNumber}
            InputLabelProps={{
                shrink: true,
            }}
            disabled={isDisabled}
        />

        <TextField
            label={strings.exhumationRequest.form.requestSubmitterTelephone}
            error={hasError(errors, 'requestSubmitterTelephone')}
            helperText={getError(errors, 'requestSubmitterTelephone')}
            fullWidth
            name='requestSubmitterTelephone'
            onChange={onChange}
            margin="normal"
            defaultValue={data.requestSubmitterTelephone}
            value={data.requestSubmitterTelephone}
            InputLabelProps={{
                shrink: true,
            }}
            disabled={isDisabled}
        />

        <TextField
            label={strings.exhumationRequest.form.paymentNumber}
            error={hasError(errors, 'paymentNumber')}
            helperText={getError(errors, 'paymentNumber')}
            fullWidth
            name='paymentNumber'
            onChange={onChange}
            margin="normal"
            defaultValue={data.paymentNumber}
            value={data.paymentNumber}
            InputLabelProps={{
                shrink: true,
            }}
            disabled={isDisabled}
        />

        <DatePickerControl
            label={strings.exhumationRequest.form.paymentDate}
            error={getError(errors, 'paymentDate')}
            hasError={hasError(errors, 'paymentDate')}
            helperText={getError(errors, 'paymentDate')}
            placeholder={strings.exhumationRequest.form.paymentDate}
            name='paymentDate'
            onChange={onChange}
            date={data.paymentDate ? new Date(data.paymentDate) : data.paymentDate}
            isClearable={true}
            dateFormat='dd/MM/yyyy'
            disabled={isDisabled}
        />

        <DatePickerControl
            label={strings.exhumationRequest.form.dateTimeOfExhumation}
            error={getError(errors, 'dateTimeOfExhumation')}
            hasError={hasError(errors, 'dateTimeOfExhumation')}
            helperText={getError(errors, 'dateTimeOfExhumation')}
            placeholder={strings.exhumationRequest.form.dateTimeOfExhumation}
            name='dateTimeOfExhumation'
            onChange={onChange}
            date={data.dateTimeOfExhumation ? new Date(data.dateTimeOfExhumation) : data.dateTimeOfExhumation}
            isClearable={true}
            dateFormat='dd/MM/yyyy HH:mm'
            showTimeSelect={true}
            disabled={isDisabled}
        />

        <TextField
            label={strings.exhumationRequest.form.supervisorNameSurname}
            error={hasError(errors, 'supervisorNameSurname')}
            helperText={getError(errors, 'supervisorNameSurname')}
            fullWidth
            name='supervisorNameSurname'
            onChange={onChange}
            margin="normal"
            defaultValue={data.supervisorNameSurname}
            value={data.supervisorNameSurname}
            InputLabelProps={{
                shrink: true,
            }}
            disabled={isDisabled}
        />

        <TextField
            label={strings.exhumationRequest.form.specialNote}
            error={hasError(errors, 'specialNote')}
            helperText={getError(errors, 'specialNote')}
            fullWidth
            name='specialNote'
            onChange={onChange}
            margin="normal"
            defaultValue={data.specialNote}
            value={data.specialNote}
            InputLabelProps={{
                shrink: true,
            }}
            disabled={isDisabled}
        />

        {
            !isDisabled &&
            <div className='submit-container'>
                <Button variant="contained" color="primary" disabled={disabled} onClick={onSubmit}>
                    {strings.exhumationRequest.form.ok}
                </Button>
                <Button variant="contained" color="secondary" onClick={onCancel}>
                    {strings.exhumationRequest.form.cancel}
                </Button>
            </div>
        }
    </form>
  )
};

export default ExhumationRequestForm;