import React from 'react';
import strings from "../../../localization";
import { getError, hasError } from "../../../functions/Validation";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Button, TextField, FormControlLabel, Checkbox, FormControl, FormLabel, RadioGroup, Radio, Select, MenuItem , FormHelperText } from "@material-ui/core";
import DatePickerControl from '../../controls/DatePickerControl';

const FuneralDetailsForm = ({
  onSubmit,
  onCancel,
  onChange,
  errors,
  data,
  isDisabled,
  graveyards,
  graveSites,
  handleChange,
  onAutocompleteChange,
  handleTextInputChange,
  flag,
  typedInGraveSite,
  userInfo,
  transporters,
  keyPress,
  keyPressBackspace,
  handleFuneralCheckboxChange,
  handleFarewellTypeCheckboxChange,
  funeralType,
  funeralTypes,
  farewellType,
  farewellTypes,
  municipalitiesOfFuneral,
  farewellTransitMunicipalities,
  necessaryArrangement,
  necessaryArrangements,
  handleNecessaryArrangementCheckboxChange,
  additionalItem,
  additionalItems,
  handleAdditionalItemsCheckboxChange,
  musicTypes,
  selectedMusicType,
  handleFarewellMusicTypeChange,
  handleAppointmentTimeChange,
  selectedAppointment,
  appointmentTimesCityGraveyard,
  appointmentTimesExternalGraveyards,
  availableAppointments,
  availableAppointmentsExternalGraveyards,
  extraordinaryAppointments
}) => {
  return (
    <form id='user-form'>
      <label className="checkboxLabel">{strings.funeralScheduling.form.funeralType}</label>
      <div>
        {funeralTypes.map(type => (
          <FormControlLabel
            key={type.id}
            control={
              <Checkbox
                checked={funeralType && funeralType.id === type.id}
                onChange={handleFuneralCheckboxChange(type.name, type.id, type.value)}
                disabled={isDisabled}
              />
            }
            label={<span className="formControlLabel">{type.name}</span>}
            style={{fontSize: '10px'}}
          />
        ))}
      </div>

      <Autocomplete
        id={"municipalityOfFuneral"}
        name={"municipalityOfFuneral"}
        options={municipalitiesOfFuneral}
        getOptionLabel={option => option.city}
        onChange={onAutocompleteChange}
        renderInput={params => (
          <TextField
            {...params}
            variant="standard"
            label={strings.funeralScheduling.form.municipalityOfFuneral}
            error={hasError(errors, 'municipalityOfFuneral')}
            helperText={getError(errors, 'municipalityOfFuneral')}
            margin="normal"
            fullWidth
            name={"municipalityOfFuneral"}
            onChange={handleTextInputChange}
            InputLabelProps={{
              shrink: true,
            }}
          />
        )}
        disabled={isDisabled}
        value={data.municipalityOfFuneral}
      />

      <Autocomplete
        id={"graveyard"}
        name={"graveyard"}
        options={graveyards}
        getOptionLabel={option => option.name}
        onChange={onAutocompleteChange}
        renderInput={params => (
          <TextField
            {...params}
            variant="standard"
            label={strings.funeralScheduling.form.graveyard}
            error={hasError(errors, 'graveyard')}
            helperText={getError(errors, 'graveyard')}
            margin="normal"
            fullWidth
            name={"graveyard"}
            onChange={handleTextInputChange}
            InputLabelProps={{
              shrink: true,
            }}
          />
        )}
        disabled={isDisabled}
        value={data.graveyard}
      />
      
      {(data.graveyard != undefined && data.graveyard != null) &&
        <Autocomplete
          noOptionsText={(typedInGraveSite != undefined ? strings.funeralScheduling.form.create + typedInGraveSite : "")}
          onKeyPress={keyPress}
          id={"graveSite"}
          name={"graveSite"}
          options={graveSites}
          getOptionLabel={option => option.code}
          onChange={onAutocompleteChange}
          renderInput={params => (
            <TextField
              {...params}
              variant="standard"
              label={strings.funeralScheduling.form.graveSite}
              error={hasError(errors, 'graveSite')}
              helperText={getError(errors, 'graveSite')}
              margin="normal"
              fullWidth
              name={"graveSite"}
              onChange={handleTextInputChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
          disabled={isDisabled}
          value={data.graveSite}
          disableClearable={true}
          onKeyDown={keyPressBackspace}
        />
      }

      {(data.graveyard == undefined || data.graveyard == null) &&
        <Autocomplete
          id={"graveSite"}
          name={"graveSite"}
          options={graveSites}
          getOptionLabel={option => option.code}
          onChange={onAutocompleteChange}
          renderInput={params => (
            <TextField
              {...params}
              variant="standard"
              label={strings.funeralScheduling.form.graveSite}
              error={hasError(errors, 'graveSite')}
              helperText={getError(errors, 'graveSite')}
              margin="normal"
              fullWidth
              name={"graveSite"}
              onChange={handleTextInputChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
          disabled={true}
          value={null}
          disableClearable={true}
        />
      }

      <TextField
        label={strings.funeralScheduling.form.deceasedNameSurname}
        error={hasError(errors, 'deceasedNameSurname')}
        helperText={getError(errors, 'deceasedNameSurname')}
        fullWidth
        name='deceasedNameSurname'
        onChange={onChange}
        margin="normal"
        defaultValue={data.deceasedNameSurname}
        value={data.deceasedNameSurname}
        InputLabelProps={{
            shrink: true,
        }}
        disabled={isDisabled}
      />

      <FormControl component="fieldset">
        <FormLabel className="checkboxLabel"> {strings.funeralScheduling.form.exhumationRequired} </FormLabel>
        <RadioGroup name="exhumationRequired" value={(data.exhumationRequired == true || data.exhumationRequired == "true") ? "true" : "false"} onChange={handleChange} className="radioGroup">
          <FormControlLabel value={"true"} disabled={isDisabled} control={<Radio />} label={<span className="formControlLabel">{strings.funeralScheduling.form.yes}</span>} />
          <FormControlLabel value={"false"} disabled={isDisabled} control={<Radio />} label={<span className="formControlLabel">{strings.funeralScheduling.form.no}</span>} />
        </RadioGroup>
      </FormControl>

      {(data.exhumationRequired == "true" || data.exhumationRequired == true) &&
        <TextField
          label={strings.funeralScheduling.form.exhumationRequiredNote}
          error={hasError(errors, 'exhumationRequiredNote')}
          helperText={getError(errors, 'exhumationRequiredNote')}
          fullWidth
          name='exhumationRequiredNote'
          onChange={onChange}
          margin="normal"
          defaultValue={data.exhumationRequiredNote}
          value={data.exhumationRequiredNote}
          InputLabelProps={{
              shrink: true,
          }}
          disabled={isDisabled}
        />
      }

      <div>
        <FormControl component="fieldset">
          <FormLabel className="checkboxLabel"> {strings.funeralScheduling.form.usingChapel} </FormLabel>
          <RadioGroup name="usingChapel" value={(data.usingChapel == true || data.usingChapel == "true") ? "true" : "false"} onChange={handleChange} className="radioGroup">
            <FormControlLabel value={"true"} disabled={isDisabled} control={<Radio />} label={<span className="formControlLabel">{strings.funeralScheduling.form.yes}</span>} />
            <FormControlLabel value={"false"} disabled={isDisabled} control={<Radio />} label={<span className="formControlLabel">{strings.funeralScheduling.form.no}</span>} />
          </RadioGroup>
        </FormControl>
      </div>

      <label className="checkboxLabel">{strings.funeralScheduling.form.farewellType}</label>
      <div>
        {farewellTypes?.map(type => (
          <FormControlLabel
            key={type.id}
            control={
              <Checkbox
                checked={farewellType && farewellType.id === type.id}
                onChange={handleFarewellTypeCheckboxChange(type.name, type.id, type.value)}
                disabled={isDisabled}
              />
            }
            label={<span className="formControlLabel">{type.name}</span>}
            style={{fontSize: '10px'}}
          />
        ))}
      </div>

      {(farewellType && farewellType.value === "transit") &&
        <Autocomplete
          id={"farewellTransitMunicipality"}
          name={"farewellTransitMunicipality"}
          options={farewellTransitMunicipalities}
          getOptionLabel={option => option.city}
          onChange={onAutocompleteChange}
          renderInput={params => (
            <TextField
              {...params}
              variant="standard"
              label={strings.funeralScheduling.form.farewellTransitMunicipality}
              error={hasError(errors, 'farewellTransitMunicipality')}
              helperText={getError(errors, 'farewellTransitMunicipality')}
              margin="normal"
              fullWidth
              name={"farewellTransitMunicipality"}
              onChange={handleTextInputChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
          disabled={isDisabled}
          value={data.farewellTransitMunicipality}
        />
      }

      <div>
        <FormControl component="fieldset">
          <FormLabel className="checkboxLabel"> {strings.funeralScheduling.form.reservedGraveSite} </FormLabel>
          <RadioGroup name="reservedGraveSite" value={(data.reservedGraveSite == true || data.reservedGraveSite == "true") ? "true" : "false"} onChange={handleChange} className="radioGroup">
            <FormControlLabel value={"true"} disabled={isDisabled} control={<Radio />} label={<span className="formControlLabel">{strings.funeralScheduling.form.reserved}</span>} />
            <FormControlLabel value={"false"} disabled={isDisabled} control={<Radio />} label={<span className="formControlLabel">{strings.funeralScheduling.form.new}</span>} />
          </RadioGroup>
        </FormControl>
      </div>

      {(data.reservedGraveSite == "true" || data.reservedGraveSite == true) &&
        <DatePickerControl
          label={strings.funeralScheduling.form.reservedGraveSiteExpiryDate}
          error={getError(errors, 'reservedGraveSiteExpiryDate')}
          hasError={hasError(errors, 'reservedGraveSiteExpiryDate')}
          helperText={getError(errors, 'reservedGraveSiteExpiryDate')}
          placeholder={strings.funeralScheduling.form.reservedGraveSiteExpiryDate}
          name='reservedGraveSiteExpiryDate'
          onChange={onChange}
          date={data.reservedGraveSiteExpiryDate ? new Date(data.reservedGraveSiteExpiryDate) : data.reservedGraveSiteExpiryDate}
          isClearable={true}
          dateFormat='dd/MM/yyyy'
          showTimeSelect={true}
          disabled={isDisabled}
        />
      }

      <label className="checkboxLabel">{strings.funeralScheduling.form.necessaryArrangements}</label>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {necessaryArrangements.map(arrangement => (
          <FormControlLabel
            key={arrangement.id}
            control={
              <Checkbox
                checked={necessaryArrangement.some(item => item.id === arrangement.id)}
                onChange={handleNecessaryArrangementCheckboxChange(arrangement.name, arrangement.id)}
                disabled={isDisabled}
              />
            }
            label={<span className="formControlLabel">{arrangement.name}</span>}
          />
        ))}
      </div>

      <div>
        <FormControl component="fieldset">
          <FormLabel className="checkboxLabel"> {strings.funeralScheduling.form.solderATinCasket} </FormLabel>
          <RadioGroup name="solderATinCasket" value={(data.solderATinCasket == true || data.solderATinCasket == "true") ? "true" : "false"} onChange={handleChange} className="radioGroup">
            <FormControlLabel value={"true"} disabled={isDisabled} control={<Radio />} label={<span className="formControlLabel">{strings.funeralScheduling.form.yes}</span>} />
            <FormControlLabel value={"false"} disabled={isDisabled} control={<Radio />} label={<span className="formControlLabel">{strings.funeralScheduling.form.no}</span>} />
          </RadioGroup>
        </FormControl>
      </div>

      <div>
        <FormControl component="fieldset">
          <FormLabel className="checkboxLabel"> {strings.funeralScheduling.form.verticalReservation} </FormLabel>
          <RadioGroup name="verticalReservation" value={(data.verticalReservation == true || data.verticalReservation == "true") ? "true" : "false"} onChange={handleChange} className="radioGroup">
            <FormControlLabel value={"true"} disabled={isDisabled} control={<Radio />} label={<span className="formControlLabel">{strings.funeralScheduling.form.yes}</span>} />
            <FormControlLabel value={"false"} disabled={isDisabled} control={<Radio />} label={<span className="formControlLabel">{strings.funeralScheduling.form.no}</span>} />
          </RadioGroup>
        </FormControl>
      </div>

      <div>
        <FormControl component="fieldset">
          <FormLabel className="checkboxLabel"> {strings.funeralScheduling.form.horizontalReservation} </FormLabel>
          <RadioGroup name="horizontalReservation" value={(data.horizontalReservation == true || data.horizontalReservation == "true") ? "true" : "false"} onChange={handleChange} className="radioGroup">
            <FormControlLabel value={"true"} disabled={isDisabled} control={<Radio />} label={<span className="formControlLabel">{strings.funeralScheduling.form.yes}</span>} />
            <FormControlLabel value={"false"} disabled={isDisabled} control={<Radio />} label={<span className="formControlLabel">{strings.funeralScheduling.form.no}</span>} />
          </RadioGroup>
        </FormControl>
      </div>

      {(data.horizontalReservation == "true" || data.horizontalReservation == true) &&
        <TextField
          label={strings.funeralScheduling.form.reservationNumber}
          error={hasError(errors, 'reservationNumber')}
          helperText={getError(errors, 'reservationNumber')}
          fullWidth
          name='reservationNumber'
          onChange={onChange}
          margin="normal"
          defaultValue={data.reservationNumber}
          value={data.reservationNumber}
          InputLabelProps={{
              shrink: true,
          }}
          disabled={isDisabled}
        />
      }

      <label className="checkboxLabel">{strings.funeralScheduling.form.additionalItems}</label>
      <div>
        {additionalItems.map(item => (
          <FormControlLabel
            key={item.id}
            control={
              <Checkbox
                checked={additionalItem.some(itemFromList => itemFromList.id === item.id)}
                onChange={handleAdditionalItemsCheckboxChange(item.name, item.id)}
                disabled={isDisabled}
              />
            }
            label={<span className="formControlLabel">{item.name}</span>}
            style={{fontSize: '10px'}}
          />
        ))}
      </div>

      <FormControl component="fieldset">
        <FormLabel className="checkboxLabel"> {strings.funeralScheduling.form.farewellMusicType} </FormLabel>
        <Select
          id="selectedMusicType"
          value={selectedMusicType ? selectedMusicType.id : null}
          onChange={handleFarewellMusicTypeChange}
          disabled={isDisabled}
        >
          {musicTypes.map(musicType => (
            <MenuItem key={musicType.id} value={musicType.id}>{musicType.name}</MenuItem>
          ))}
        </Select>
      </FormControl>

      <div>
        <FormControl component="fieldset">
          <FormLabel className="checkboxLabel"> {strings.funeralScheduling.form.reservedHall} </FormLabel>
          <RadioGroup name="reservedHall" value={(data.reservedHall == true || data.reservedHall == "true") ? "true" : "false"} onChange={handleChange} className="radioGroup">
            <FormControlLabel value={"true"} disabled={isDisabled} control={<Radio />} label={<span className="formControlLabel">{strings.funeralScheduling.form.yes}</span>} />
            <FormControlLabel value={"false"} disabled={isDisabled} control={<Radio />} label={<span className="formControlLabel">{strings.funeralScheduling.form.no}</span>} />
          </RadioGroup>
        </FormControl>
      </div>

      {(data.reservedHall == "true" || data.reservedHall == true) &&
        <React.Fragment>
          <TextField
            label={strings.funeralScheduling.form.numberOfAttendees}
            error={hasError(errors, 'numberOfAttendees')}
            helperText={getError(errors, 'numberOfAttendees')}
            fullWidth
            name='numberOfAttendees'
            onChange={onChange}
            margin="normal"
            defaultValue={data.numberOfAttendees}
            value={data.numberOfAttendees}
            InputLabelProps={{
                shrink: true,
            }}
            disabled={isDisabled}
          />
          <TextField
            label={strings.funeralScheduling.form.menu}
            error={hasError(errors, 'menu')}
            helperText={getError(errors, 'menu')}
            fullWidth
            name='menu'
            onChange={onChange}
            margin="normal"
            defaultValue={data.menu}
            value={data.menu}
            InputLabelProps={{
                shrink: true,
            }}
            disabled={isDisabled}
          />
          <TextField
            label={strings.funeralScheduling.form.hallApplicationReceivedBy}
            error={hasError(errors, 'hallApplicationReceivedBy')}
            helperText={getError(errors, 'hallApplicationReceivedBy')}
            fullWidth
            name='hallApplicationReceivedBy'
            onChange={onChange}
            margin="normal"
            defaultValue={data.hallApplicationReceivedBy}
            value={data.hallApplicationReceivedBy}
            InputLabelProps={{
                shrink: true,
            }}
            disabled={isDisabled}
          />
        </React.Fragment>
      }

      {(transporters && ((data.transporter && flag == "edit") || (flag == "add") || (data.transporter && flag == "view"))) &&
        <Autocomplete
          id={"transporter"}
          name={"transporter"}
          options={transporters}
          getOptionLabel={option => option.name}
          onChange={onAutocompleteChange}
          renderInput={params => (
            <TextField
              {...params}
              variant="standard"
              label={strings.funeralScheduling.form.transporter}
              margin="normal"
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
          disabled={isDisabled}
          value={data.transporter}
        />
      }
      {(transporters && ((!data.transporter && flag == "edit") || (!data.transporter && flag == "view"))) &&
        <Autocomplete
          id={"transporter"}
          name={"transporter"}
          options={transporters}
          getOptionLabel={option => option.name}
          onChange={onAutocompleteChange}
          renderInput={params => (
            <TextField
              {...params}
              variant="standard"
              label={strings.funeralScheduling.form.transporter}
              margin="normal"
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
          disabled={isDisabled}
          value={data.transporter}
        />
      }

      <TextField
        label={strings.funeralScheduling.form.funeralEquipment}
        error={hasError(errors, 'funeralEquipment')}
        helperText={getError(errors, 'funeralEquipment')}
        fullWidth
        name='funeralEquipment'
        onChange={onChange}
        margin="normal"
        defaultValue={data.funeralEquipment}
        value={data.funeralEquipment}
        InputLabelProps={{
            shrink: true,
        }}
        disabled={isDisabled}
      />

      <label className="header-label"> {strings.funeralScheduling.form.informationAboutLeasedGraveSitesAndTombs} </label>
      <TextField
        label={strings.funeralScheduling.form.previouslyBuriedDeceasedAndYearsOfDeath}
        error={hasError(errors, 'previouslyBuriedDeceasedAndYearsOfDeath')}
        helperText={getError(errors, 'previouslyBuriedDeceasedAndYearsOfDeath')}
        fullWidth
        name='previouslyBuriedDeceasedAndYearsOfDeath'
        onChange={onChange}
        margin="normal"
        defaultValue={data.previouslyBuriedDeceasedAndYearsOfDeath}
        value={data.previouslyBuriedDeceasedAndYearsOfDeath}
        InputLabelProps={{
            shrink: true,
        }}
        disabled={isDisabled}
      />

      <TextField
        label={strings.funeralScheduling.form.nameSurnameUser}
        error={hasError(errors, 'nameSurnameUser')}
        helperText={getError(errors, 'nameSurnameUser')}
        fullWidth
        name='nameSurnameUser'
        onChange={onChange}
        margin="normal"
        defaultValue={userInfo.nameSurnameUser}
        value={userInfo.nameSurnameUser}
        InputLabelProps={{
            shrink: true,
        }}
        disabled={true}
      />

      <div>
        <FormControl component="fieldset">
          <FormLabel className="checkboxLabel"> {strings.funeralScheduling.form.graveSiteHasTombstone} </FormLabel>
          <RadioGroup name="graveSiteHasTombstone" value={(data.graveSiteHasTombstone == true || data.graveSiteHasTombstone == "true") ? "true" : "false"} onChange={handleChange} className="radioGroup">
            <FormControlLabel value={"true"} disabled={isDisabled} control={<Radio />} label={<span className="formControlLabel">{strings.funeralScheduling.form.yes}</span>} />
            <FormControlLabel value={"false"} disabled={isDisabled} control={<Radio />} label={<span className="formControlLabel">{strings.funeralScheduling.form.no}</span>} />
          </RadioGroup>
        </FormControl>
      </div>

      {(data.graveSiteHasTombstone == "true" || data.graveSiteHasTombstone == true) &&
        <TextField
          label={strings.funeralScheduling.form.tombstoneType}
          error={hasError(errors, 'tombstoneType')}
          helperText={getError(errors, 'tombstoneType')}
          fullWidth
          name='tombstoneType'
          onChange={onChange}
          margin="normal"
          defaultValue={data.tombstoneType}
          value={data.tombstoneType}
          InputLabelProps={{
              shrink: true,
          }}
          disabled={isDisabled}
        />
      }

      <TextField
        label={strings.funeralScheduling.form.specialTerms}
        error={hasError(errors, 'specialTerms')}
        helperText={getError(errors, 'specialTerms')}
        fullWidth
        name='specialTerms'
        onChange={onChange}
        margin="normal"
        defaultValue={data.specialTerms}
        value={data.specialTerms}
        InputLabelProps={{
            shrink: true,
        }}
        disabled={isDisabled}
      />

      <div>
        <FormControl component="fieldset">
          <FormLabel className="checkboxLabel"> {strings.funeralScheduling.form.announceThroughMedia} </FormLabel>
          <RadioGroup name="announceThroughMedia" value={(data.announceThroughMedia == true || data.announceThroughMedia == "true") ? "true" : "false"} onChange={handleChange} className="radioGroup">
            <FormControlLabel value={"true"} disabled={isDisabled} control={<Radio />} label={<span className="formControlLabel">{strings.funeralScheduling.form.yes}</span>} />
            <FormControlLabel value={"false"} disabled={isDisabled} control={<Radio />} label={<span className="formControlLabel">{strings.funeralScheduling.form.no}</span>} />
          </RadioGroup>
        </FormControl>
      </div>

      <TextField
        label={strings.funeralScheduling.form.note}
        error={hasError(errors, 'note')}
        helperText={getError(errors, 'note')}
        fullWidth
        name='note'
        onChange={onChange}
        margin="normal"
        defaultValue={data.note}
        value={data.note}
        InputLabelProps={{
            shrink: true,
        }}
        disabled={isDisabled}
      />

      <DatePickerControl
        label={strings.funeralScheduling.form.dateFuneral}
        error={getError(errors, 'dateTimeFuneral')}
        hasError={hasError(errors, 'dateTimeFuneral')}
        helperText={getError(errors, 'dateTimeFuneral')}
        placeholder={strings.funeralScheduling.form.dateTimeFuneral}
        name='dateTimeFuneral'
        onChange={onChange}
        date={data.dateTimeFuneral ? new Date(data.dateTimeFuneral) : data.dateTimeFuneral}
        isClearable={true}
        dateFormat='dd/MM/yyyy'
        disabled={isDisabled}
      />

      <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', gap: '1rem'}}>


      {flag === "view" && data?.dateTimeFuneral !== null && data.dateTimeFuneral && (
        <FormControl component="fieldset">
        <FormLabel className="checkboxLabel">{strings.funeralScheduling.form.timeFuneral}</FormLabel>
          <Select
              placeholder={strings.funeralScheduling.form.dateTimeFuneral}
              id="selectedAppointment"
              name="selectedAppointment"
              defaultValue={data.appointmentTime}
              value={data.appointmentTime}
              disabled={isDisabled}
          >
            <MenuItem key={data.appointmentId} value={data.appointmentTime}>{data.appointmentTime}</MenuItem>
          </Select>
        </FormControl>
      )}

      {(flag === "add" && data?.dateTimeFuneral && data?.dateTimeFuneral !== null) && (data?.graveyard && data.graveyard.id === 2 && (
      <FormControl component="fieldset">
          <FormLabel className="checkboxLabel">{strings.funeralScheduling.form.timeFuneral}</FormLabel>
          <Select
              placeholder={strings.funeralScheduling.form.dateTimeFuneral}
              id="selectedAppointment"
              name="selectedAppointment"
              defaultValue={data.appointmentTime}
              value={selectedAppointment || ""}            
              onChange={handleAppointmentTimeChange}
              disabled={isDisabled}
          >
            {(data.usingChapel == "true" && data.graveyard.id === 2) && availableAppointments && availableAppointments.map((item) => (
              <MenuItem key={item.id} value={item.value}>{item.value}</MenuItem>
            ))}

            {data.usingChapel == "false" && appointmentTimesCityGraveyard.map((item) => (
              <MenuItem key={item.id} value={item.value}>{item.value}</MenuItem>
            ))}
          </Select>
      </FormControl>
      ))}

      {(flag === "add" && data?.dateTimeFuneral && data?.dateTimeFuneral !== null) && (data?.graveyard && data.graveyard.id !== 2 && (
      <FormControl component="fieldset">
          <FormLabel className="checkboxLabel">{strings.funeralScheduling.form.timeFuneral}</FormLabel>
          <Select
              placeholder={strings.funeralScheduling.form.dateTimeFuneral}
              id="selectedAppointment"
              name="selectedAppointment"
              defaultValue={data.appointmentTime}
              value={selectedAppointment || ""}            
              onChange={handleAppointmentTimeChange}
              disabled={isDisabled}
          >
            {data.usingChapel === "true" && availableAppointmentsExternalGraveyards && availableAppointmentsExternalGraveyards.map((item) => (
              <MenuItem key={item.id} value={item.value}>{item.value}</MenuItem>
            ))}

            {data.usingChapel == "false" && appointmentTimesExternalGraveyards.map((item) => (
              <MenuItem key={item.id} value={item.value}>{item.value}</MenuItem>
            ))}
          </Select>
      </FormControl>
      ))}

      {(flag === "edit" && data?.dateTimeFuneral && data?.dateTimeFuneral !== null) && (data?.graveyard && data.graveyard.id === 2 && (
      <FormControl component="fieldset">
          <FormLabel className="checkboxLabel">{strings.funeralScheduling.form.timeFuneral}</FormLabel>
          <Select
              placeholder={strings.funeralScheduling.form.dateTimeFuneral}
              id="selectedAppointment"
              name="selectedAppointment"
              defaultValue={data.appointmentTime}
              value={selectedAppointment ? selectedAppointment : data.appointmentTime}            
              onChange={handleAppointmentTimeChange}
              disabled={isDisabled}
          >
            {data.usingChapel && availableAppointments && availableAppointments.map((item) => (
              <MenuItem key={item.id} value={item.value}>{item.value}</MenuItem>
            ))}

            {!data.usingChapel && appointmentTimesCityGraveyard.map((item) => (
              <MenuItem key={item.id} value={item.value}>{item.value}</MenuItem>
            ))}
          </Select>
      </FormControl>
      ))}

      {(flag === "edit" && data?.dateTimeFuneral && data?.dateTimeFuneral !== null) && (data?.graveyard && data.graveyard.id !== 2 && (
      <FormControl component="fieldset">
          <FormLabel className="checkboxLabel">{strings.funeralScheduling.form.timeFuneral}</FormLabel>
          <Select
              placeholder={strings.funeralScheduling.form.dateTimeFuneral}
              id="selectedAppointment"
              name="selectedAppointment"
              defaultValue={data.appointmentTime}
              value={selectedAppointment ? selectedAppointment : data.appointmentTime}            
              onChange={handleAppointmentTimeChange}
              disabled={isDisabled}
          >
            {data.usingChapel && data.dateTimeFuneral && availableAppointmentsExternalGraveyards && availableAppointmentsExternalGraveyards.map((item) => (
              <MenuItem key={item.id} value={item.value}>{item.value}</MenuItem>
            ))}

            {!data.usingChapel && data.dateTimeFuneral && appointmentTimesExternalGraveyards.map((item) => (
              <MenuItem key={item.id} value={item.value}>{item.value}</MenuItem>
            ))}
          </Select>
      </FormControl>
      ))}

    {/* extraordinary appointments */}

    {(flag === "add" && data?.dateTimeFuneral && data?.dateTimeFuneral !== null) && (data.graveyard && data.graveyard !== null &&(
      <FormControl component="fieldset">
          <FormLabel className="checkboxLabel">Ванредни термини</FormLabel>
          <Select
              placeholder={strings.funeralScheduling.form.dateTimeFuneral}
              id="selectedAppointment"
              name="selectedAppointment"
              defaultValue={data.appointmentTime}
              value={selectedAppointment ? selectedAppointment : data.appointmentTime}            
              onChange={handleAppointmentTimeChange}
              disabled={isDisabled}
          >
            {data.usingChapel && data.dateTimeFuneral && extraordinaryAppointments && extraordinaryAppointments.map((item) => (
              <MenuItem key={item.id} value={item.appointmentTime}>{item.appointmentTime}</MenuItem>
            ))}

            {!data.usingChapel && data.dateTimeFuneral && extraordinaryAppointments && extraordinaryAppointments.map((item) => (
              <MenuItem key={item.id} value={item.appointmentTime}>{item.appointmentTime}</MenuItem>
            ))}
          </Select>
      </FormControl>
    ))}

    {(flag === "edit" && data?.dateTimeFuneral && data?.dateTimeFuneral !== null) && (data.graveyard && data.graveyard !== null && (
      <FormControl component="fieldset">
          <FormLabel className="checkboxLabel">Ванредни термини</FormLabel>
          <Select
              placeholder={strings.funeralScheduling.form.dateTimeFuneral}
              id="selectedAppointment"
              name="selectedAppointment"
              defaultValue={data.appointmentTime}
              value={selectedAppointment ? selectedAppointment : data.appointmentTime}            
              onChange={handleAppointmentTimeChange}
              disabled={isDisabled}
          >
            {data.usingChapel && data.dateTimeFuneral && extraordinaryAppointments && extraordinaryAppointments.map((item) => (
              <MenuItem key={item.id} value={item.appointmentTime}>{item.appointmentTime}</MenuItem>
            ))}

            {!data.usingChapel && data.dateTimeFuneral && extraordinaryAppointments && extraordinaryAppointments.map((item) => (
              <MenuItem key={item.id} value={item.appointmentTime}>{item.appointmentTime}</MenuItem>
            ))}
          </Select>
      </FormControl>
    ))}

    </div>

      {!isDisabled &&
      <div className='submit-container' style={{ padding: '5px' }}>
        <Button variant="contained" color="primary" disabled={isDisabled} onClick={onSubmit}>
          {strings.funeralScheduling.form.ok}
        </Button>
        <Button variant="contained" color="secondary" onClick={onCancel}>
          {strings.funeralScheduling.form.cancel}
        </Button>
      </div>
      }
    </form>
  )
};

export default FuneralDetailsForm;