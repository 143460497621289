import React from 'react'
import { bindActionCreators } from "redux";
import * as Actions from "../../actions/Actions";
import { withRouter } from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import Grid from '@material-ui/core/Grid';
import { Paper } from "@material-ui/core";
import strings from "../../localization";
import Validators from "../../constants/ValidatorTypes";
import FormComponent from "../../common/FormComponent";
import { withSnackbar } from "notistack";
import { addFamousCitizen } from '../../services/famousCitizen/FamousCitizenService';
import FamousCitizenForm from '../../components/forms/famousCitizen/FamousCitizenForm';


class AddFamousCitizen extends FormComponent {

  validationList = {
    code: [{ type: Validators.REQUIRED }],
    description: [{ type: Validators.REQUIRED }],
  };

  constructor(props) {
    super(props);

    this.state = {
      data: props.data ? props.data : {},
      errors: {}
    };

    this.props.changeFullScreen(false);

    this.submit = this.submit.bind(this);

  }

  submit() {

    if (!this.validate()) {
      return;
    }

    this.showDrawerLoader();

    addFamousCitizen(this.transformRequest(this.state.data), this.props.cid).then(response => {
      if (!response.ok) {
        let messageKey = response.response.data.message;

        this.props.enqueueSnackbar(strings.famousCitizen.messages[messageKey], {
          variant: "error"
        });

        this.handleError(messageKey);

        return;
      }

      this.props.enqueueSnackbar(strings.addCompany.famousCitizenAdded, { variant: 'success' });
      this.props.onFinish(response.data.famousCitizen);

      this.hideDrawerLoader();
    });
  }

  transformRequest(data) {
    return {
      ...data,
      userEmail: this.props.uemail,
    }
  }

  handleError(message) {
    switch (message) {
      case "FAMOUS_CITIZEN_WITH_CODE_ALREADY_EXIST":
        this.setError("code", strings.famousCitizen.messages.FAMOUS_CITIZEN_WITH_CODE_ALREADY_EXIST);
        break;
      case "FAMOUS_CITIZEN_CODE_CANT_BE_NULL":
        this.setError("code", strings.famousCitizen.messages.FAMOUS_CITIZEN_CODE_CANT_BE_NULL);
        break;
      case "FAMOUS_CITIZEN_DESCRIPTION_CANT_BE_NULL":
        this.setError("description", strings.famousCitizen.messages.FAMOUS_CITIZEN_DESCRIPTION_CANT_BE_NULL);
        break;
    }
  }

  render() {
    return (
      <Grid id='page' item md={12}>

        <div className='header'>
          <h1>{strings.famousCitizen.pageTitle}</h1>
        </div>

        <Paper className='paper'>
          <FamousCitizenForm onChange={this.changeData} onSubmit={this.submit}
            data={this.state.data} errors={this.state.errors} onCancel={this.props.onCancel} />
        </Paper>

      </Grid>

    );
  }
}


function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    changeFullScreen: Actions.changeFullScreen
  }, dispatch);
}

function mapStateToProps({ menuReducers, siteDataReducers }) {
  return { menu: menuReducers, siteData: siteDataReducers };
}

export default withSnackbar(withRouter(connect(mapStateToProps, mapDispatchToProps)(AddFamousCitizen)));