import React from 'react';
import strings from '../../localization';
import TablePage from '../../common/TablePage';
import { withSnackbar } from 'notistack';
import * as Actions from "../../actions/Actions";
import { withRouter } from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import { bindActionCreators } from "redux";
import { Grid } from '@material-ui/core';
import SelectControl from '../../components/controls/SelectControl';
import IconButton from "@material-ui/core/IconButton";
import { generateExpiryByGraveFIeld } from '../../services/report/ReportService';
import { downloadReport, getGraveSiteTypes } from '../../util/ReviewsUtil';
import { getAllGraveyards } from '../../services/graveyard/GraveyardService';

class ExpiryByGraveFieldReview extends TablePage {

  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
      showAdd: false,
      showSerach: false,
      filter: {
        graveSiteTypes: []
      },
    };
  }

  componentDidMount() {
    this.setState({
      companyId: this.props.auth.user.company.id,
      filter: {
        graveSiteTypes: getGraveSiteTypes()
      }
    });

    getAllGraveyards().then(response => {
      this.setState({
        filter: {
          ...this.state.filter,
          graveyards: response.data.entities
        }
      });
    });
  }

  componentWillReceiveProps(nextProps) {
    strings.setLanguage(nextProps.auth.user.language);
  }

  getPageHeader() {
    return <h1>{strings.expiryByGraveFieldReview.pageTitle}</h1>
  }

  generateReport() {
    let data = {
      companyId: this.state.companyId,
      graveyardCode: this.state.data.graveyard.code,
      optionType: this.state.data.graveSiteType.value,
      year: new Date().getFullYear(),
      userId: this.props.auth.user.id
    };
    generateExpiryByGraveFIeld(data).then(response => {
      if (!response.ok) {
        return;
      }

      this.props.enqueueSnackbar(strings.mainBookOfBuriedList.PDF, { variant: 'success' });
      downloadReport(response, "Pregled isteka po parcelama");
    })
  }

  render() {
    return (
      <Grid id='table-page'>
        <div className='header'>
          {this.getPageHeader()}
        </div>
        <div className='review-select'>
          <React.Fragment>
            <SelectControl
              options={this.state.filter.graveyards}
              selected={this.state.data.graveyard}
              onChange={this.changeData}
              label={strings.expiryByGraveFieldReview.graveyard}
              placeholder={strings.selectControl.select}
              name={"graveyard"}
              nameKey={"name"}
              valueKey={"id"}
            />
            <SelectControl
              options={this.state.filter.graveSiteTypes}
              selected={this.state.data.graveSiteType}
              onChange={this.changeData}
              label={strings.expiryByGraveFieldReview.graveSiteTypes}
              placeholder={strings.selectControl.select}
              name={"graveSiteType"}
              nameKey={"name"}
              valueKey={"id"}
            />
          </React.Fragment>
        </div>
        <div className="report-button">
          <IconButton onClick={() => this.generateReport()} disabled={!this.state.data.graveyard || !this.state.data.graveSiteType}
          >
            {strings.expiryByGraveFieldReview.generateReport}
          </IconButton>
        </div>
      </Grid>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    changeFullScreen: Actions.changeFullScreen
  }, dispatch);
}

function mapStateToProps({ menuReducers, authReducers, siteDataReducers }) {
  return { menu: menuReducers, auth: authReducers, siteData: siteDataReducers };
}

export default withSnackbar(withRouter(connect(mapStateToProps, mapDispatchToProps)(ExpiryByGraveFieldReview)));
