import React from 'react'
import { bindActionCreators } from "redux";
import * as Actions from "../../actions/Actions";
import { withRouter } from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import Grid from '@material-ui/core/Grid';
import { Paper } from "@material-ui/core";
import strings from "../../localization";
import Validators from "../../constants/ValidatorTypes";
import FormComponent from "../../common/FormComponent";
import { withSnackbar } from "notistack";
import { addPermission } from '../../services/permissions/PermissionService';
import PermissionForm from '../../components/forms/permissions/PermissionsForm';

class AddPermission extends FormComponent{
    validationList = {
        category: [{ type: Validators.REQUIRED }],
        code: [{ type: Validators.REQUIRED }],
    };

    constructor(props) {
        super(props);

        this.state = {
            data: props.data ? props.data : {},
            errors: {}
        };

        this.props.changeFullScreen(false);

        this.submit = this.submit.bind(this);

    }

    submit(){
        
        if(!this.validate()){
            
            return;
        }

        this.showDrawerLoader();
        

        addPermission(this.state.data, this.props.cid).then(response =>{
            if(!response.ok){
                let messageKey = response.response.data.message;

                this.props.enqueueSnackbar(strings.state.messages[messageKey], {
                    variant: "error"
                });

                // this.handleError(messageKey);

                return;
            }
            this.props.enqueueSnackbar(strings.addCompany.stateAdded, { variant: 'success' });
            this.props.onFinish(response.data.state);

            this.hideDrawerLoader();
        
        })
    }

    render() {
        return (
            <Grid id='page' item md={12}>

            <div className='header'>
                <h1>{"Дозволе"}</h1>
            </div>

            <Paper className='paper'>
                <PermissionForm onCancel={this.props.onCancel} onChange={this.changeData} cid={this.state.companyId} onSubmit={this.submit} data={this.state.data} />
            </Paper>

        </Grid>
        );
    }

}
function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        changeFullScreen: Actions.changeFullScreen
    }, dispatch);
}

function mapStateToProps({ menuReducers, siteDataReducers }) {
    return { menu: menuReducers, siteData: siteDataReducers };
}

export default withSnackbar(withRouter(connect(mapStateToProps, mapDispatchToProps)(AddPermission)));