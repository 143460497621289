import {request} from "../../base/HTTP";
import HttpMethod from "../../constants/HttpMethod";

export async function getFirms(data) {
    return await request('/api/firm/search', data);
}

export async function addFirm(data, cid) {
    return await request('/api/firm/' + cid, data, HttpMethod.POST);
}

export function getFirmById(id) {
    return  request("api/firm/" + id , {}, HttpMethod.GET);
}

export async function editFirm(data) {
    return await request("api/firm/" + data.id, data, HttpMethod.PUT);
}

export async function deleteFirm(id, uid) {
    return await request('/api/firm/' + id + '/' + uid, {} , HttpMethod.DELETE);
}

export async function getDeletedFirms(data) {
    return await request('/api/firm/deleted', data)
}

export async function restoreFirm(data) {
    return await request("api/firm/restore/" + data.id, data, HttpMethod.PUT);
}