export const TOGGLE_LOADER = '[SITE_DATA] TOGGLE_LOADER';
export const FORCE_HIDE_LOADER = '[SITE_DATA] FORCE_HIDE_LOADER';
export const ACTIVE_LANGUAGE = '[SITE_DATA] ACTIVE_LANGUAGE';

export function showLoader() {

    return {
        type: TOGGLE_LOADER,
        loader: true,
        activeLanguage: 'rs'
    };
}

export function forceHideLoader() {
    return {
        type: TOGGLE_LOADER
    };
}

export function hideLoader() {
    return {
        type: TOGGLE_LOADER,
        loader: false
    };
}

export function setActiveLanguage(activeLanguage) {
    return {
        type: ACTIVE_LANGUAGE,
        activeLanguage: activeLanguage
    };
}
