import React from 'react';
import strings from "../../../localization";
import { getError, hasError } from "../../../functions/Validation";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Button, TextField } from "@material-ui/core";
import DatePickerControl from '../../controls/DatePickerControl';
import moment from 'moment';
import { stringToDate } from '../../../util/DateUtil';

const CremationMainBookOfCremationForm = ({
    onSubmit,
    onCancel,
    onChange,
    errors,
    data,
    isDisabled,
    graveyards,
    graveSites,
    urns,
    handleChange,
    onAutocompleteChange,
    handleTextInputChange,
    flag,
    typedInGraveSite,
    keyPress,
    keyPressBackspace
}) => {

    return (

        <form id='user-form'>
            <label className="header-label"> {strings.mainBookOfCremated.form.cremationCurrentPlaceOfRest} </label>
            {(data.dateTimeOfTakeInString != undefined && data.dateTimeOfTakeInString != null && data.dateTimeOfTakeInString != "") &&
                <TextField
                    label={strings.mainBookOfCremated.form.dateTimeOfTakeInString}
                    fullWidth
                    name='dateTimeOfTakeInString'
                    onChange={handleTextInputChange}
                    margin="normal"
                    value={data.dateTimeOfTakeInString}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    disabled={true}
                />
            }
            <DatePickerControl
                label={strings.mainBookOfCremated.form.dateTimeOfTakeIn}
                error={getError(errors, 'dateTimeOfTakeIn')}
                hasError={hasError(errors, 'dateTimeOfTakeIn')}
                helperText={getError(errors, 'dateTimeOfTakeIn')}
                placeholder={strings.mainBookOfCremated.form.dateTimeOfTakeIn}
                name='dateTimeOfTakeIn'
                onChange={onChange}
                date={data.dateTimeOfTakeIn ? new Date(data.dateTimeOfTakeIn) : data.dateTimeOfTakeIn}
                isClearable={true}
                dateFormat='dd/MM/yyyy HH:mm'
                showTimeSelect={true}
                disabled={isDisabled}
            />
            {(data.dateTimeSayGoodbyeString != undefined && data.dateTimeSayGoodbyeString != null && data.dateTimeSayGoodbyeString != "") &&
                <TextField
                    label={strings.mainBookOfCremated.form.dateTimeSayGoodbyeString}
                    fullWidth
                    name='dateTimeSayGoodbyeString'
                    onChange={handleTextInputChange}
                    margin="normal"
                    value={data.dateTimeSayGoodbyeString}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    disabled={true}
                />
            }
            <DatePickerControl
                label={strings.mainBookOfCremated.form.dateTimeSayGoodbye}
                error={getError(errors, 'dateTimeSayGoodbye')}
                hasError={hasError(errors, 'dateTimeSayGoodbye')}
                helperText={getError(errors, 'dateTimeSayGoodbye')}
                placeholder={strings.mainBookOfCremated.form.dateTimeSayGoodbye}
                name='dateTimeSayGoodbye'
                onChange={onChange}
                date={data.dateTimeSayGoodbye ? new Date(data.dateTimeSayGoodbye) : data.dateTimeSayGoodbye}
                isClearable={true}
                dateFormat='dd/MM/yyyy HH:mm'
                showTimeSelect={true}
                disabled={isDisabled}
            />
            {(data.dateTimeOfCremationString != undefined && data.dateTimeOfCremationString != null && data.dateTimeOfCremationString != "") &&
                <TextField
                    label={strings.mainBookOfCremated.form.dateTimeOfCremationString}
                    fullWidth
                    name='dateTimeOfCremationString'
                    onChange={handleTextInputChange}
                    margin="normal"
                    value={data.dateTimeOfCremationString}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    disabled={true}
                />
            }
            <DatePickerControl
                label={strings.mainBookOfCremated.form.dateTimeOfCremation}
                error={getError(errors, 'dateTimeOfCremation')}
                hasError={hasError(errors, 'dateTimeOfCremation')}
                helperText={getError(errors, 'dateTimeOfCremation')}
                placeholder={strings.mainBookOfCremated.form.dateTimeOfCremation}
                name='dateTimeOfCremation'
                onChange={onChange}
                date={data.dateTimeOfCremation ? new Date(data.dateTimeOfCremation) : data.dateTimeOfCremation}
                isClearable={true}
                dateFormat='dd/MM/yyyy HH:mm'
                showTimeSelect={true}
                disabled={isDisabled}
            />
            {(data.dateTimeUrnTakeoverString != undefined && data.dateTimeUrnTakeoverString != null && data.dateTimeUrnTakeoverString != "") &&
                <TextField
                    label={strings.mainBookOfCremated.form.dateTimeUrnTakeoverString}
                    fullWidth
                    name='dateTimeUrnTakeoverString'
                    onChange={handleTextInputChange}
                    margin="normal"
                    value={data.dateTimeUrnTakeoverString}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    disabled={true}
                />
            }
            <DatePickerControl
                label={strings.mainBookOfCremated.form.dateTimeUrnTakeover}
                error={getError(errors, 'dateTimeUrnTakeover')}
                hasError={hasError(errors, 'dateTimeUrnTakeover')}
                helperText={getError(errors, 'dateTimeUrnTakeover')}
                placeholder={strings.mainBookOfCremated.form.dateTimeUrnTakeover}
                name='dateTimeUrnTakeover'
                onChange={onChange}
                date={data.dateTimeUrnTakeover ? new Date(data.dateTimeUrnTakeover) : data.dateTimeUrnTakeover}
                isClearable={true}
                dateFormat='dd/MM/yyyy HH:mm'
                showTimeSelect={true}
                disabled={isDisabled}
            />
            <Autocomplete
                id={"urn"}
                name={"urn"}
                options={urns}
                getOptionLabel={option => option.name}
                onChange={onAutocompleteChange}
                renderInput={params => (
                    <TextField
                        {...params}
                        variant="standard"
                        label={strings.mainBookOfCremated.form.urn}
                        error={hasError(errors, 'urn')}
                        helperText={getError(errors, 'urn')}
                        margin="normal"
                        fullWidth
                        name={"urn"}
                        onChange={handleTextInputChange}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                )}
                disabled={isDisabled}
                value={data.urn}
            />
            <Autocomplete
                id={"graveyard"}
                name={"graveyard"}
                options={graveyards}
                getOptionLabel={option => option.name}
                onChange={onAutocompleteChange}
                renderInput={params => (
                    <TextField
                        {...params}
                        variant="standard"
                        label={strings.mainBookOfCremated.form.graveyard}
                        error={hasError(errors, 'graveyard')}
                        helperText={getError(errors, 'graveyard')}
                        margin="normal"
                        fullWidth
                        name={"graveyard"}
                        onChange={handleTextInputChange}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                )}
                disabled={isDisabled}
                value={data.graveyard}
            />
            {((data.graveSite && flag == "edit") || (flag == "add") || (data.graveSite && flag == "view")) && ((data.graveyard != undefined && data.graveyard != null)) &&
                <Autocomplete
                    noOptionsText={(typedInGraveSite != undefined ? strings.mainBookOfBuried.form.create + typedInGraveSite : "")}
                    onKeyPress={keyPress}
                    id={"graveSite"}
                    name={"graveSite"}
                    options={graveSites}
                    getOptionLabel={option => option.code}
                    onChange={onAutocompleteChange}
                    renderInput={params => (
                        <TextField
                            {...params}
                            variant="standard"
                            label={strings.mainBookOfCremated.form.graveSite}
                            error={hasError(errors, 'graveSite')}
                            helperText={getError(errors, 'graveSite')}
                            margin="normal"
                            fullWidth
                            name={"graveSite"}
                            onChange={handleTextInputChange}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    )}
                    disabled={isDisabled}
                    value={data.graveSite}
                    disableClearable={true}
                    onKeyDown={keyPressBackspace}
                />
            }
            {((!data.graveSite && flag == "edit") || (!data.graveSite && flag == "view") && (data.graveyard != undefined && data.graveyard != null)) &&
                <Autocomplete
                    noOptionsText={(typedInGraveSite != undefined ? strings.mainBookOfBuried.form.create + typedInGraveSite : "")}
                    onKeyPress={keyPress}
                    id={"graveSite"}
                    name={"graveSite"}
                    options={graveSites}
                    getOptionLabel={option => option.code}
                    onChange={onAutocompleteChange}
                    renderInput={params => (
                        <TextField
                            {...params}
                            variant="standard"
                            label={strings.mainBookOfCremated.form.graveSite}
                            error={hasError(errors, 'graveSite')}
                            helperText={getError(errors, 'graveSite')}
                            margin="normal"
                            fullWidth
                            name={"graveSite"}
                            onChange={handleTextInputChange}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    )}
                    disabled={isDisabled}
                    value={data.graveSite}
                    disableClearable={true}
                    onKeyDown={keyPressBackspace}
                />
            }

            {(data.graveyard == undefined || data.graveyard == null) &&
                <Autocomplete
                    id={"graveSite"}
                    name={"graveSite"}
                    options={graveSites}
                    getOptionLabel={option => option.code}
                    onChange={onAutocompleteChange}
                    renderInput={params => (
                        <TextField
                            {...params}
                            variant="standard"
                            label={strings.mainBookOfCremated.form.graveSite}
                            error={hasError(errors, 'graveSite')}
                            helperText={getError(errors, 'graveSite')}
                            margin="normal"
                            fullWidth
                            name={"graveSite"}
                            onChange={handleTextInputChange}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    )}
                    disabled={true}
                    value={null}
                    disableClearable={true}
                />
            }

            <TextField
                label={strings.mainBookOfBuried.form.paymentNumber}
                error={hasError(errors, 'paymentNumber')}
                helperText={getError(errors, 'paymentNumber')}
                fullWidth
                name='paymentNumber'
                onChange={onChange}
                margin="normal"
                defaultValue={data.paymentNumber}
                value={data.paymentNumber}
                InputLabelProps={{
                    shrink: true,
                }}
                disabled={isDisabled}
            />
            {(data.expiryDateString != undefined && data.expiryDateString != null && data.expiryDateString != "") &&
                <TextField
                    label={strings.mainBookOfBuried.form.expiryDateString}
                    fullWidth
                    name='expiryDateString'
                    onChange={handleTextInputChange}
                    margin="normal"
                    value={data.expiryDateString}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    disabled={true}
                />
            }
            <DatePickerControl
                label={strings.mainBookOfBuried.form.expiryDate}
                error={getError(errors, 'expiryDate')}
                hasError={hasError(errors, 'expiryDate')}
                helperText={getError(errors, 'expiryDate')}
                placeholder={strings.mainBookOfBuried.form.expiryDate}
                name='expiryDate'
                onChange={onChange}
                date={data.expiryDate ? new Date(data.expiryDate) : data.expiryDate}
                isClearable={true}
                dateFormat='dd/MM/yyyy'
                disabled={isDisabled}
            />
            {
                !isDisabled &&
                <div className='submit-container'>
                    <Button variant="contained" color="primary" onClick={onSubmit}>
                        {strings.settlement.form.ok}
                    </Button>
                    <Button variant="contained" color="secondary" onClick={onCancel}>
                        {strings.settlement.form.cancel}
                    </Button>
                </div>
            }
        </form>
    )
};

export default CremationMainBookOfCremationForm;