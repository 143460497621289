import FormComponent from "../../common/FormComponent";
import React from "react";
import { bindActionCreators } from "redux";
import * as Actions from "../../actions/Actions";
import { withRouter } from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import Grid from "@material-ui/core/Grid";
import { Paper } from "@material-ui/core";
import strings from "../../localization";
import { withSnackbar } from "notistack";
import { getMainBookOfBuriedById } from "../../services/mainBookOfBuried/MainBookOfBuriedService";
import { getGraveyardById } from "../../services/graveyard/GraveyardService";
import { getGraveSiteById } from "../../services/graveSite/GraveSiteService";
import { getStoneCuttingWorksById } from "../../services/stoneCuttingWorks/StoneCuttingWorksService";
import { getStonecutterById } from "../../services/stonecutter/StonecutterService";
import { getCitizenById } from "../../services/citizen/CitizenService";
import StoneCuttingWorksForm from "../../components/forms/stoneCuttingWorks/StoneCuttingWorksForm";

class ViewStoneCuttingWorks extends FormComponent {

  constructor(props) {
    super(props);

    this.state = {
      data: props.data ? props.data : {},
      errors: {},
      citizen: {}
    };

    this.props.changeFullScreen(false);
  }

  componentDidMount() {

    getStoneCuttingWorksById(this.props.id).then(response => {
        this.setState({
            data: response.data,
        }, () => {
            {this.state.data.mainBookOfBuriedId &&
                getMainBookOfBuriedById(this.state.data.mainBookOfBuriedId).then(response => {
                    this.setState({
                        data: {
                            ...this.state.data,
                            mainBookOfBuried: response.data
                        },
                    })
                });
            }
            {this.state.data.stonecutterId &&
                getStonecutterById(this.state.data.stonecutterId).then(response => {
                    this.setState({
                        data: {
                            ...this.state.data,
                            stonecutter: response.data
                        },
                    })
                });
            }
            {this.state.data.graveyardId &&
                getGraveyardById(this.state.data.graveyardId).then(response => {
                    this.setState({
                        data: {
                            ...this.state.data,
                            graveyard: response.data
                        },
                    })
                });
            }
            {this.state.data.graveSiteId &&
                getGraveSiteById(this.state.data.graveSiteId).then(response => {
                    this.setState({
                        data: {
                            ...this.state.data,
                            graveSite: response.data
                        },
                    })
                });
            }
            {this.state.data.citizenId &&
                getCitizenById(this.state.data.citizenId).then(response => {
                  
                    this.setState({
                        data: {
                            ...this.state.data,
                            citizen: response.data
                        },
                        citizen: {
                            citizenNameSurname: response.data.name + " " + response.data.surname,
                            citizenAddress: response.data.street + " " + response.data.streetNumber,
                            citizenSettlement: response.data.postalCodeImprovedZip,
                            citizenTelephone: response.data.telephone
                        }
                    })
                });
            }
        });
    });
  }

  render() {

    return (
      <Grid id="page" item md={12}>
        <div className="header">
          <h1>{strings.stoneCuttingWorks.viewPageTitle}</h1>
        </div>
        <Paper className='paper'>
            <StoneCuttingWorksForm
                onChange={this.changeData}
                onSubmit={this.submit}
                data={this.state.data} 
                errors={this.state.errors} 
                onCancel={this.props.onCancel} 
                isDisabled={this.props.isDisabled}
                onAutocompleteChange={this.onAutocompleteChange}
                handleTextInputChange={this.handleTextInputChange}
                citizen={this.state.citizen}
                citizens={this.state.citizens == undefined ? [] : this.state.citizens}
                stonecutters={this.state.stonecutters == undefined ? [] : this.state.stonecutters}
                mainBooksOfBuried={this.state.mainBooksOfBuried == undefined ? [] : this.state.mainBooksOfBuried}
                graveyards={this.state.graveyards == undefined ? [] : this.state.graveyards}
                graveSites={this.state.graveSites == undefined ? [] : this.state.graveSites}
                flag={this.props.flag}/>
            </Paper>
      </Grid>
    );
  }
}

function mapDispatchToProps(dispatch) {

  return bindActionCreators(
    {
      changeFullScreen: Actions.changeFullScreen
    },
    dispatch
  );
}

function mapStateToProps({ menuReducers, siteDataReducers }) {

  return { menu: menuReducers, siteData: siteDataReducers };
}

export default withSnackbar(
  withRouter(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(ViewStoneCuttingWorks)
  )
);
