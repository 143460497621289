const Validators = {
  REQUIRED: 0,
  MIN_LENGTH: 1,
  MAX_LENGTH: 2,
  EMAIL: 3,
  PASSWORD: 4,
  NOT_EMPTY_ARRAY: 5,
  SELECTED_OPTION: 6,
  IS_NUMBER: 7,
  IS_NEGATIVE_NUMBER: 8,
  IS_VALID_YEAR: 9
};

export default Validators;