import {request} from "../../base/HTTP";
import HttpMethod from "../../constants/HttpMethod";

export async function getUsers(data) {
    return await request('/api/admin/users/search', data);
}

export async function getUser(id) {
    return await request('/api/admin/users/' + id, {}, HttpMethod.GET);
}

export async function addUser(data) {
    return await request('/api/admin/users', data, HttpMethod.POST);
}

export async function editUser(data) {
    return await request("api/admin/users/" + data.id, data, HttpMethod.PUT);
}

export async function deleteUser(id, uid) {
    return await request('/api/admin/users/' + id + '/' + uid, {} , HttpMethod.DELETE);
}

export async function getAllUsers() {
    return await request("api/admin/users/all");
}
