import React from 'react'
import TablePage from "../../common/TablePage";
import { bindActionCreators } from "redux";
import * as Actions from "../../actions/Actions";
import { withRouter } from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import strings from "../../localization";
import { withSnackbar } from "notistack";
import { getExhumations, getDeletedExhumations, deleteExhumation, restoreExhumation } from '../../services/exhumation/ExhumationService';
import AddExhumation from './AddExhumation';
import EditExhumation from './EditExhumation';
import ViewExhumation from './ViewExhumation';


class ExhumationList extends TablePage {

  tableDescription = [
    { key: 'exhumation', label: strings.exhumationList.exhumation, sort: 'exhumation' },
    { key: 'mainBookOfBuriedMainBookOfBuries', label: strings.exhumationList.mainBookOfBuried, sort: 'mainBookOfBuriedMainBookOfBuries' },
    { key: 'deceasedNameSurname', label: strings.exhumationList.deceasedNameSurname, sort: 'deceasedNameSurname' },
    { key: 'graveyardName', label: strings.exhumationList.graveyardName, sort: 'graveyardName' },
    { key: 'graveSiteCode', label: strings.exhumationList.graveSiteCode, sort: 'graveSiteCode' },
    { key: 'nameSurnameCost', label: strings.exhumationList.nameSurnameCost, sort: 'nameSurnameCost' },
    { key: 'expiryDate', label: strings.exhumationList.expiryDate, transform: 'renderColumnDate', sort: 'expiryDate' },
    { key: 'mainBookOfBuriedDateTimeFuneral', label: strings.exhumationList.dateTimeFuneral, transform: 'renderColumnDate', sort: 'mainBookOfBuriedDateTimeFuneral' },
    { key: 'oldGraveyardName', label: strings.exhumationList.oldGraveyardCode, sort: 'oldGraveyardName' },
    { key: 'oldGraveSiteCode', label: strings.exhumationList.oldGraveSiteCode, sort: 'oldGraveSiteCode' },
    { key: 'mainBookOfBuriedUserGraveSiteName', label: strings.exhumationList.mainBookOfBuriedSubmitterName, sort: 'mainBookOfBuriedUserGraveSiteName' },
    { key: 'mainBookOfBuriedUserGraveSiteSurname', label: strings.exhumationList.mainBookOfBuriedSubmitterSurname, sort: 'mainBookOfBuriedUserGraveSiteSurname' },
    { key: 'hasNote', label: strings.exhumationList.isHasNote, transform: 'renderColumnDeleted', sort: 'hasNote' },

  ];

  constructor(props) {
    super(props);

    this.state.sortBy = "dateCreated,DESC";
    this.state.showDeleted = false;

  }

  fetchData() {
    let data = {
      searchParam: this.state.searchData.search,
      page: this.state.searchData.page - 1,
      size: this.state.searchData.perPage,
      companyId: this.state.companyId,
      sort: this.state.sortBy,
      userId: this.props.auth.user.id
    };

    {
      (this.state.companyId != undefined && !this.state.showDeleted) &&
        getExhumations(data).then(response => {
          if (!response.ok) {
            return;
          }
          this.setState({
            tableData: response.data.entities,
            total: response.data.total,
          });
        });
    }

    {
      (this.state.companyId != undefined && this.state.showDeleted) &&
      getDeletedExhumations(data).then(response => {
          if (!response.ok) {
            return;
          }
          this.setState({
            tableData: response.data.entities,
            total: response.data.total,
          });
        });
    }
  }

  handleDeletedChange(){
    this.setState({
      showDeleted : !this.state.showDeleted
    },() =>{ this.fetchData(); })
    
  }

  usePermissions(){
    let data = {
      userId: this.props.auth.user.id,
      category: "Ексхумације"
    }
    this.getPermissionsS(data);
    this.showAdd(this.props.showAdd);
    this.showRemove(this.props.remove);
    this.showEdit(this.props.showEdit);
    this.showView(this.props.showView);
    this.removedItemsShow(this.props.removedItemsShow);

  }

  componentDidMount() {
    this.usePermissions()
    this.setState({
      companyId: this.props.auth.user.company.id
    }, () => { this.fetchData(); })
  }

  componentWillReceiveProps(nextProps) {
    strings.setLanguage(nextProps.auth.user.language);
    
    this.tableDescription = [
      { key: 'exhumation', label: strings.exhumationList.exhumation, sort: 'exhumation' },
      { key: 'mainBookOfBuriedMainBookOfBuries', label: strings.exhumationList.mainBookOfBuried, sort: 'mainBookOfBuriedMainBookOfBuries' },
      { key: 'deceasedNameSurname', label: strings.exhumationList.deceasedNameSurname, sort: 'deceasedNameSurname' },
      { key: 'graveyardName', label: strings.exhumationList.graveyardName, sort: 'graveyardName' },
      { key: 'graveSiteCode', label: strings.exhumationList.graveSiteCode, sort: 'graveSiteCode' },
      { key: 'nameSurnameCost', label: strings.exhumationList.nameSurnameCost, sort: 'nameSurnameCost' },
      { key: 'expiryDate', label: strings.exhumationList.expiryDate, transform: 'renderColumnDate', sort: 'expiryDate' },
      { key: 'mainBookOfBuriedDateTimeFuneral', label: strings.exhumationList.dateTimeFuneral, transform: 'renderColumnDate', sort: 'mainBookOfBuriedDateTimeFuneral' },
      { key: 'oldGraveyardName', label: strings.exhumationList.oldGraveyardCode, sort: 'oldGraveyardName' },
      { key: 'oldGraveSiteCode', label: strings.exhumationList.oldGraveSiteCode, sort: 'oldGraveSiteCode' },
      { key: 'mainBookOfBuriedUserGraveSiteName', label: strings.exhumationList.mainBookOfBuriedSubmitterName, sort: 'mainBookOfBuriedUserGraveSiteName' },
      { key: 'mainBookOfBuriedUserGraveSiteSurname', label: strings.exhumationList.mainBookOfBuriedSubmitterSurname, sort: 'mainBookOfBuriedUserGraveSiteSurname' },
      { key: 'hasNote', label: strings.exhumationList.isHasNote, transform: 'renderColumnDeleted', sort: 'hasNote' },

    ];

  }

  getPageHeader() {
    return <h1>{strings.exhumationList.pageTitle}</h1>;
  }

  renderAddContent() {
    return <AddExhumation cid={this.state.companyId} uemail={this.props.auth.user.email} onCancel={this.onCancel} onFinish={this.onFinish} flag={"add"} />
  }

  renderEditContent() {
    return <EditExhumation
      id={this.state.selectedItem.id}
      uemail={this.props.auth.user.email}
      cid={this.state.companyId}
      onCancel={this.onCancel}
      onFinish={this.onFinish}
      isDisabled={false}
      flag={"edit"}
    />
  }

  renderViewContent() {
    return <ViewExhumation id={this.state.selectedItem.id} isDisabled={true} onCancel={this.onCancel} onFinish={this.onFinish} flag={"view"} />
  }

  delete(item) {

    this.setState({
      lockTable: true
    });


    deleteExhumation(item.id, this.props.auth.user.id).then(response => {

      if (response && !response.ok) {
        this.onFinish(null);
        return;
      }

      this.props.enqueueSnackbar(strings.companyList.exhumationDeleted, { variant: 'success' });

      this.onFinish(item);
      this.cancelDelete();

      this.setState({
        lockTable: false
      });
    });
  }

  transformRequest(data) {
    return {
        ...data,
        userEmail: this.props.auth.user.email,
        companyId: this.state.companyId
    }
}
  restore(item){
    
    restoreExhumation(this.transformRequest(item)).then(response => {
      if (response && !response.ok) {
        this.onFinish(null);
        return;
      }
      this.setState({
        showDeleted : !this.state.showDeleted,
      },() =>{ this.fetchData(); })
    })
  }

}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    changeFullScreen: Actions.changeFullScreen
  }, dispatch);
}

function mapStateToProps({ menuReducers, authReducers, siteDataReducers }) {
  return { menu: menuReducers, auth: authReducers, siteData: siteDataReducers };
}

export default withSnackbar(withRouter(connect(mapStateToProps, mapDispatchToProps)(ExhumationList)));