import React from 'react'
import TablePage from "../../../common/TablePage";
import { bindActionCreators } from "redux";
import * as Actions from "../../../actions/Actions";
import { withRouter } from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import strings from "../../../localization";
import { withSnackbar } from "notistack";
import { TableFooter, TableRow, TableBody, TableCell, TableHead, Menu, ListItemIcon, ListItemText } from "@material-ui/core";
import ViewMainBookOfBuried from '../../mainBookOfBuried/ViewMainBookOfBuried';
import ViewExhumation from '../../exhumation/ViewExhumation';
import ViewRenewal from '../../renewal/ViewRenewal';
import ViewReservation from '../../reservation/ViewReservation';
import ViewTombRent from '../../tombRent/ViewTombRent';
import ViewCassetteRent from '../../cassetteRent/ViewCassetteRent';
import IconButton from '@material-ui/core/IconButton';
import MoreVert from '@material-ui/icons/MoreVert';
import { MenuItem } from "@material-ui/core";
import EditIcon from '@material-ui/icons/Edit';
import { getDeceased, getLease, getRenewal, getExhumation, getDepth } from '../../../services/reviews/reviewsRecords/CardGraveSiteService';
import { getMainBookOfBuriedById } from '../../../services/mainBookOfBuried/MainBookOfBuriedService';
import { getCauseOfDeathById } from '../../../services/causeOfDeath/CauseOfDeathService';
import { getGraveSiteById } from '../../../services/graveSite/GraveSiteService';



class CardGraveSiteList extends TablePage {

  tableDescription = [
    { key: 'indicator', label: strings.cardGraveSiteList.indicator },
    { key: 'mainBookOfBuried', label: strings.cardGraveSiteList.mainBookOfBuried },
    { key: 'tombstone', label: strings.cardGraveSiteList.tombstone },
    { key: 'dateUpdated', label: strings.cardGraveSiteList.lastChange, transform: 'renderColumnDate' },
    { key: 'graveSiteExpiry', label: strings.cardGraveSiteList.expiryDate, transform: 'renderColumnDate' },
    { key: 'deceasedNameSurname', label: strings.cardGraveSiteList.deceased },
    { key: 'submitterNameSurname', label: strings.cardGraveSiteList.name },
    { key: 'submitterAddress', label: strings.cardGraveSiteList.street },
    { key: 'postalCode', label: strings.cardGraveSiteList.place },
    { key: 'previousGraveSite', label: strings.cardGraveSiteList.previousGraveSite },
    { key: 'hasNote', label: strings.cardGraveSiteList.comment, transform: 'renderColumnDeleted' },
  ];

  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
      showSearch: false,
      showAdd: false,
      responseData: -1,
      flagFirstTimeRender: false,
      graveyardCode: this.props.graveyardCode != null ? this.props.graveyardCode : "",
      graveSiteId: this.props.graveSiteId != null ? this.props.graveSiteId : "",
      userId: this.props.auth.user.id,
    };

  }

  fetchData() {
    if (this.state.flagFirstTimeRender) {
      let data = {
        page: 0,
        size: 100,
        graveyardCode: this.props.graveyardCode != null ? this.props.graveyardCode : "",
        graveSiteId: this.props.graveSiteId != null ? this.props.graveSiteId : "",
        companyId: this.state.companyId,
        userId: this.props.auth.user.id,
      };

      
      getDeceased(data).then(response => {
        this.setState({
          showTable: true,
          responseData: response.data.content,
        }, () => {
          this.setState({
            tableData: this.state.responseData,
            total: this.state.responseData.length,
          });
        });
      });
    }
    else {
      this.setState({
        tableData: this.props.data,
        total: this.props.data.length,
        flagFirstTimeRender: 1,
      });
    }
  }

  componentDidMount() {
    
    this.setState({
      companyId: this.props.auth.user.company.id,
      
    });
    
    getMainBookOfBuriedById(this.props.data[0] != null ? this.props.data[0].id : null).then(response =>{
      if(!response.ok){
        return
      }
      
      getCauseOfDeathById(response.data.causeOfDeathId).then(response =>{
        
        if(!response.ok){
          return
        }
        
        this.setState({
          infectious: response.data.infectious
        })
      })
    })

    getGraveSiteById(this.state.graveSiteId).then(response =>{
      if(!response.ok){
        return
      }

      this.setState({
        arrangedPlot: response.data.arrangedPlot,
        forbidden : response.data.z === "0" || response.data.z == null
      })
    })
    
  }

  // componentWillReceiveProps(nextProps) {
  //   strings.setLanguage(nextProps.auth.user.language);
  //   this.tableDescription = [
  //     { key: 'indicator', label: strings.cardGraveSiteList.indicator },
  //     { key: 'mainBookOfBuried', label: strings.cardGraveSiteList.mainBookOfBuried },
  //     { key: 'tombstone', label: strings.cardGraveSiteList.tombstone },
  //     { key: 'dateUpdated', label: strings.cardGraveSiteList.lastChange, transform: 'renderColumnDate' },
  //     { key: 'graveSiteExpiry', label: strings.cardGraveSiteList.expiryDate, transform: 'renderColumnDate' },
  //     { key: 'deceasedNameSurname', label: strings.cardGraveSiteList.deceased },
  //     { key: 'submitterNameSurname', label: strings.cardGraveSiteList.name },
  //     { key: 'submitterAddress', label: strings.cardGraveSiteList.street },
  //     { key: 'postalCode', label: strings.cardGraveSiteList.place },
  //     { key: 'previousGraveSite', label: strings.cardGraveSiteList.previousGraveSite },
  //     { key: 'hasNote', label: strings.cardGraveSiteList.comment, transform: 'renderColumnDeleted' },
  //   ];
  // }


  onDeceasedClick(){
    let data = {
      page: 0,
      size: 100,
      graveyardCode: this.state.graveyardCode,
      graveSiteId: this.state.graveSiteId,
      companyId: this.state.companyId,
      userId: this.state.userId,
    };
    getDeceased(data).then(response => {
      this.setState({
          showTable: true,
          responseData: response.data.content,
        }, () => {
          this.tableDescription = [
            { key: 'indicator', label: strings.cardGraveSiteList.indicator },
            { key: 'mainBookOfBuried', label: strings.cardGraveSiteList.mainBookOfBuried },
            { key: 'tombstone', label: strings.cardGraveSiteList.tombstone },
            { key: 'dateUpdated', label: strings.cardGraveSiteList.lastChange, transform: 'renderColumnDate' },
            { key: 'graveSiteExpiry', label: strings.cardGraveSiteList.expiryDate, transform: 'renderColumnDate' },
            { key: 'deceasedNameSurname', label: strings.cardGraveSiteList.deceased },
            { key: 'submitterNameSurname', label: strings.cardGraveSiteList.name },
            { key: 'submitterAddress', label: strings.cardGraveSiteList.street },
            { key: 'postalCode', label: strings.cardGraveSiteList.place },
            { key: 'previousGraveSite', label: strings.cardGraveSiteList.previousGraveSite },
            { key: 'hasNote', label: strings.cardGraveSiteList.comment, transform: 'renderColumnDeleted' },
          ];

          this.setState({
            tableData: this.state.responseData,
            total: this.state.responseData.length,
          });
        });
      });
  }
  
  onLeaseClick(){
    let data = {
      page: 0,
      size: 100,
      graveyardCode: this.state.graveyardCode,
      graveSiteId: this.state.graveSiteId,
      companyId: this.state.companyId,
      userId: this.state.userId,
    };
    getLease(data).then(response => {
      this.setState({
          showTable: true,
          responseData: response.data.content,
        }, () => {
          this.tableDescription = [
            { key: 'indicator', label: strings.cardGraveSiteList.indicator },
            { key: 'mainBookOfBuried', label: strings.cardGraveSiteList.contractNumber },
            { key: 'dateUpdated', label: strings.cardGraveSiteList.lastChange, transform: 'renderColumnDate' },
            { key: 'graveSiteExpiry', label: strings.cardGraveSiteList.expiryDate, transform: 'renderColumnDate' },
            { key: 'submitterNameSurname', label: strings.cardGraveSiteList.leaser },
            { key: 'submitterAddress', label: strings.cardGraveSiteList.street },
            { key: 'postalCode', label: strings.cardGraveSiteList.place },
            { key: 'hasNote', label: strings.cardGraveSiteList.comment, transform: 'renderColumnDeleted' },
          ];

          this.setState({
            tableData: this.state.responseData,
            total: this.state.responseData.length,
          });
        });
      });
  }
  onRenewalClick(){
    let data = {
      page: 0,
      size: 100,
      graveyardCode: this.state.graveyardCode,
      graveSiteId: this.state.graveSiteId,
      companyId: this.state.companyId,
      userId: this.state.userId,
    };
    getRenewal(data).then(response => {
      
      this.setState({
          showTable: true,
          responseData: response.data.content,
        }, () => {
          this.tableDescription = [
            { key: 'indicator', label: strings.cardGraveSiteList.indicator },
            { key: 'mainBookOfBuried', label: strings.cardGraveSiteList.contractNumber },
            { key: 'dateUpdated', label: strings.cardGraveSiteList.lastChange, transform: 'renderColumnDate' },
            { key: 'graveSiteExpiry', label: strings.cardGraveSiteList.expiryDate, transform: 'renderColumnDate' },
            { key: 'costNameSurname', label: strings.cardGraveSiteList.cost },
            { key: 'costAddress', label: strings.cardGraveSiteList.street },
            { key: 'costPostalCode', label: strings.cardGraveSiteList.place },
            { key: 'hasNote', label: strings.cardGraveSiteList.comment, transform: 'renderColumnDeleted' },
          ];

          this.setState({
            tableData: this.state.responseData,
            total: this.state.responseData.length,
          });
        });
      });
  }
  onExhumationClick(){
    let data = {
      page: 0,
      size: 100,
      graveyardCode: this.state.graveyardCode,
      graveSiteId: this.state.graveSiteId,
      companyId: this.state.companyId,
      userId: this.state.userId,
    };
    getExhumation(data).then(response => {
      this.setState({
          showTable: true,
          responseData: response.data.content,
        }, () => {
          this.tableDescription = [
            { key: 'indicator', label: strings.cardGraveSiteList.indicator },
            { key: 'mainBookOfBuried', label: strings.cardGraveSiteList.mainBookOfBuried },
            { key: 'tombstone', label: strings.cardGraveSiteList.tombstone },
            { key: 'dateUpdated', label: strings.cardGraveSiteList.lastChange, transform: 'renderColumnDate' },
            { key: 'graveSiteExpiry', label: strings.cardGraveSiteList.expiryDate, transform: 'renderColumnDate' },
            { key: 'deceasedNameSurname', label: strings.cardGraveSiteList.deceased },
            { key: 'submitterNameSurname', label: strings.cardGraveSiteList.name },
            { key: 'submitterAddress', label: strings.cardGraveSiteList.street },
            { key: 'postalCode', label: strings.cardGraveSiteList.place },
            { key: 'previousGraveSite', label: strings.cardGraveSiteList.previousGraveSite },
            { key: 'hasNote', label: strings.cardGraveSiteList.comment, transform: 'renderColumnDeleted' },
          ];

          this.setState({
            tableData: this.state.responseData,
            total: this.state.responseData.length,
          });
        });
      });
  }
  onDepthClick(){
    let data = {
      page: 0,
      size: 100,
      graveyardCode: this.state.graveyardCode,
      graveSiteId: this.state.graveSiteId,
      companyId: this.state.companyId,
      userId: this.state.userId,
    };
    getDepth(data).then(response => {
      this.setState({
          showTable: true,
          responseData: response.data.content,
        }, () => {
          this.tableDescription = [
            { key: 'indicator', label: strings.cardGraveSiteList.indicator },
            { key: 'mainBookOfBuried', label: strings.cardGraveSiteList.mainBookOfBuried },
            { key: 'tombstone', label: strings.cardGraveSiteList.tombstone },
            { key: 'dateUpdated', label: strings.cardGraveSiteList.lastChange, transform: 'renderColumnDate' },
            { key: 'graveSiteExpiry', label: strings.cardGraveSiteList.expiryDate, transform: 'renderColumnDate' },
            { key: 'deceasedNameSurname', label: strings.cardGraveSiteList.deceased },
            { key: 'submitterNameSurname', label: strings.cardGraveSiteList.name },
            { key: 'submitterAddress', label: strings.cardGraveSiteList.street },
            { key: 'postalCode', label: strings.cardGraveSiteList.place },
            { key: 'previousGraveSite', label: strings.cardGraveSiteList.previousGraveSite },
            { key: 'hasNote', label: strings.cardGraveSiteList.comment, transform: 'renderColumnDeleted' },
          ];

          this.setState({
            tableData: this.state.responseData,
            total: this.state.responseData.length,
          });
        });
      });
  }
  getPageHeader() {
    return <div>
      { this.state.infectious &&
      <div>
          <h1>ZARAZNA BOLEST</h1>
        </div>}
        { !this.state.forbidden &&
      <div>
          <h1>ZABRANA</h1>
        </div>}
        { this.state.arrangedPlot &&
      <div>
          <h1>UREDJENA</h1>
        </div>}
        {
      <div className='card-grave-site-buttons'>
        <button type="button" class="btn btn-light" onClick={() =>this.onDeceasedClick()}>Pokojnici</button>
        <button type="button" class="btn btn-light" onClick={() =>this.onLeaseClick()}>Zakup</button>
        <button type="button" class="btn btn-light" onClick={() =>this.onRenewalClick()}>Obnove</button>
        <button type="button" class="btn btn-light" onClick={() =>this.onExhumationClick()}>Ekshumacije</button>
      </div>}
    </div>;
  }

  renderViewContent() {
    if (this.state.selectedItem.indicator == "POK" || this.state.selectedItem.indicator == "KOR") {
      
      return <ViewMainBookOfBuried
        id={this.state.selectedItem.id}
        uemail={this.props.auth.user.email}
        cid={this.state.companyId}
        onCancel={this.onCancel}
        onFinish={this.onFinish}
        isDisabled={true}
        flag={"view"}
      />
    } else if (this.state.selectedItem.indicator.includes("EXH")) {
      

      return <ViewExhumation
        id={this.state.selectedItem.id}
        uemail={this.props.auth.user.email}
        cid={this.state.companyId}
        onCancel={this.onCancel}
        onFinish={this.onFinish}
        isDisabled={true}
        flag={"view"}
      />
    } else if (this.state.selectedItem.indicator == "OBN" ||
              this.state.selectedItem.indicator == "OBN STORNO") {
      

      return <ViewRenewal
        id={this.state.selectedItem.id}
        uemail={this.props.auth.user.email}
        cid={this.state.companyId}
        onCancel={this.onCancel}
        onFinish={this.onFinish}
        isDisabled={true}
        flag={"view"}
      />
    } else if (this.state.selectedItem.indicator == "REZ" ||
              this.state.selectedItem.indicator == "REZ STORNO" || 
              this.state.selectedItem.indicator == "REZ DUB" || 
              this.state.selectedItem.indicator == "REZ URE" || 
              this.state.selectedItem.indicator == "REZ DUB URE") {
      
      return <ViewReservation
        id={this.state.selectedItem.id}
        uemail={this.props.auth.user.email}
        cid={this.state.companyId}
        onCancel={this.onCancel}
        onFinish={this.onFinish}
        isDisabled={true}
        flag={"view"}
      />

    } else if (this.state.selectedItem.indicator == "GRO ZAKUP" ||
              this.state.selectedItem.indicator == "GRO ZAKUP STORNO") {
      

      return <ViewTombRent
        id={this.state.selectedItem.id}
        uemail={this.props.auth.user.email}
        cid={this.state.companyId}
        onCancel={this.onCancel}
        onFinish={this.onFinish}
        isDisabled={true}
        flag={"view"}
      />
    } else if (this.state.selectedItem.indicator == "KAS ZAKUP" ||
              this.state.selectedItem.indicator == "KAS ZAKUP STORNO") {
      

      return <ViewCassetteRent
        id={this.state.selectedItem.id}
        uemail={this.props.auth.user.email}
        cid={this.state.companyId}
        onCancel={this.onCancel}
        onFinish={this.onFinish}
        isDisabled={true}
        flag={"view"}
      />
    }

  }

  isRowSelected(item) {
    return this.state.selectedItem && item.indicator === this.state.selectedItem.indicator && item.id === this.state.selectedItem.id;
  }

  renderRowMenu(index, item) {

    let ariaOwns = 'action-menu-' + index;

    return (
      <TableCell>
        <IconButton
          size='small'
          aria-owns={this.state.anchorEl ? ariaOwns : undefined}
          aria-haspopup="true"
          onClick={(event) => this.handleMenuClick(event, ariaOwns)}
        >
          <MoreVert />
        </IconButton>
        {
          ariaOwns === this.state.ariaOwns &&
          <Menu
            id={ariaOwns}
            anchorEl={this.state.anchorEl}
            open={Boolean(this.state.anchorEl)}
            onClose={() => this.handleMenuClose()}
          >
            <MenuItem onClick={() => this.handleMenuView(item)}>
              <ListItemIcon>
                <EditIcon />
              </ListItemIcon>
              <ListItemText primary={strings.table.view} />
            </MenuItem>
          </Menu>
        }

      </TableCell>
    );
  }

  renderTableRow(data = []) {

    let result = [];

    for (let item of data) {

      let className = 'table-row-' + result.length % 2;

      if (this.isRowSelected(item)) {
        className += ' selected';
      }

      result.push(
        <TableRow key={'table-row-' + result.length} className={className} onClick={() => this.selectRow(item)}>
          {this.renderRowMenu(result.length, item)}
          {this.renderTableRowData(item)}
        </TableRow>
      )
    }

    return (
      <TableBody>
        {result}
      </TableBody>
    );
  }

  renderTableHeader() {

    let result = [];

    for (let item of this.tableDescription) {

      result.push(
        <TableCell key={'table-header-' + result.length}>
          <div className='container-horizontal'>
            <div className='container-horizontal-label'>
              {item.label}
            </div>
          </div>
        </TableCell>
      )
    }

    return (
      <TableHead className='table-header'>
        <TableRow>
          <TableCell>
            {strings.table.actions}
          </TableCell>
          {result}
        </TableRow>
      </TableHead>
    );
  }

  renderTableFooter() {

    return (
      <TableFooter>
        <TableRow>

        </TableRow>
      </TableFooter>
    );
  }

}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    changeFullScreen: Actions.changeFullScreen
  }, dispatch);
}

function mapStateToProps({ menuReducers, authReducers, siteDataReducers }) {
  return { menu: menuReducers, auth: authReducers, siteData: siteDataReducers };
}

export default withSnackbar(withRouter(connect(mapStateToProps, mapDispatchToProps)(CardGraveSiteList)));