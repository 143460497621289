import strings from "../../localization";
import React from "react";
import SelectControl from "../../components/controls/SelectControl";
import IconButton from "@material-ui/core/IconButton";

const RenewalProcessingReportHeader = ({
  reports,
  selectedReport,
  onChange,
  load,
}) => {
  return (
    <form style={{ display: 'flex', flexDirection: 'row', alignContent: 'flex-start', alignItems: 'flex-start' }}>
      <div style={{ width: 200, marginTop: 50 }}>
        <SelectControl
          options={reports}
          selected={selectedReport}
          onChange={onChange}
          label={strings.renewalList.processingReports}
          name={"selectedReport"}
          nameKey={"dateCreated"}
          valueKey={"id"}
        />
      </div>

      <div className="select-grave-site-button" style={{ marginLeft: 25, marginTop: 70 }}>
        <IconButton
          onClick={load}
          disabled={
            selectedReport == undefined || selectedReport.id == -1
          }
        >
          {strings.review.buttons.loadProcessingReport}
        </IconButton>
      </div>
    </form>
  );
};

export default RenewalProcessingReportHeader;
