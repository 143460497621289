import React from 'react';
import TablePage from "../../common/TablePage";
import { bindActionCreators } from "redux";
import * as Actions from "../../actions/Actions";
import { withRouter } from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import strings from "../../localization";
import { withSnackbar } from "notistack";
import { getAids, getDeletedAids, deleteAid, restoreAid } from '../../services/aid/AidService';
import AddAid from './AddAid';
import EditAid from './EditAid';
import ViewAid from './ViewAid';


class AidList extends TablePage {

  tableDescription = [
    { key: 'name', label: strings.aidList.name, sort: 'name' },
    { key: 'dateCreated', label: strings.aidList.dateCreated, transform: 'renderColumnDate', sort: 'dateCreated' },
  ];

  constructor(props) {
    super(props);

    this.state.sortBy = "name,ASC";
    this.state.showDeleted = false;
  }

  fetchData() {
    let data = {
      searchParam: this.state.searchData.search,
      page: this.state.searchData.page - 1,
      size: this.state.searchData.perPage,
      companyId: this.state.companyId,
      sort: this.state.sortBy,
      userId: this.props.auth.user.id
    }

    {
      (this.state.companyId != undefined && !this.state.showDeleted) &&
        getAids(data).then(response => {
          if (!response.ok) {
            return;
          }

          this.setState({
            tableData: response.data.entities,
            total: response.data.total,
          });
        });
    }

    {
      (this.state.companyId != undefined && this.state.showDeleted) &&
      getDeletedAids(data).then(response => {
          if (!response.ok) {
            return;
          }
          this.setState({
            tableData: response.data.entities,
            total: response.data.total,
          });
        });
    }
  }

  handleDeletedChange(){
    this.setState({
      showDeleted : !this.state.showDeleted
    },() =>{ this.fetchData(); })
    
  }

  usePermissions(){
    let data = {
      userId: this.props.auth.user.id,
      category: "Помагала"
    }
    this.getPermissionsS(data);
    this.showAdd(this.props.showAdd);
    this.showRemove(this.props.remove);
    this.showEdit(this.props.showEdit);
    this.showView(this.props.showView);
    this.removedItemsShow(this.props.removedItemsShow);

  }

  componentDidMount() {
    this.usePermissions()
    this.setState({
      companyId: this.props.auth.user.company.id
    }, () => { this.fetchData(); })
  }

  componentWillReceiveProps(nextProps) {
    strings.setLanguage(nextProps.auth.user.language);
    this.tableDescription = [
      { key: 'name', label: strings.aidList.name, sort: 'name' },
      { key: 'dateCreated', label: strings.aidList.dateCreated, transform: 'renderColumnDate', sort: 'dateCreated' },
    ];
  }

  getPageHeader() {

    return <h1>{strings.aidList.pageTitle}</h1>;
  }

  renderAddContent() {

    return <AddAid uemail={this.props.auth.user.email} cid={this.state.companyId} onCancel={this.onCancel} onFinish={this.onFinish} />
  }

  renderEditContent() {

    return <EditAid
      uemail={this.props.auth.user.email}
      cid={this.state.companyId}
      isDisabled={false}
      id={this.state.selectedItem.id}
      onCancel={this.onCancel}
      onFinish={this.onFinish} />
  }

  renderViewContent() {

    return <ViewAid id={this.state.selectedItem.id} cid={this.state.companyId} isDisabled={true} onCancel={this.onCancel} onFinish={this.onFinish} />
  }

  delete(item) {

    this.setState({
      lockTable: true
    });

    deleteAid(item.id, this.props.auth.user.id).then(response => {

      if (response && !response.ok) {
        this.onFinish(null);
        return;
      }

      this.props.enqueueSnackbar(strings.aidList.deleted, { variant: 'success' });

      this.onFinish(item);
      this.cancelDelete();

      this.setState({
        lockTable: false
      });
    });
  }

  transformRequest(data) {
    return {
        ...data,
        userEmail: this.props.auth.user.email,
        companyId: this.state.companyId
    }
}
  restore(item){
    
    restoreAid(this.transformRequest(item)).then(response => {
      if (response && !response.ok) {
        this.onFinish(null);
        return;
      }
      this.setState({
        showDeleted : !this.state.showDeleted,
      },() =>{ this.fetchData(); })
    })
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    changeFullScreen: Actions.changeFullScreen
  }, dispatch);
}

function mapStateToProps({ menuReducers, authReducers, siteDataReducers }) {
  return { menu: menuReducers, auth: authReducers, siteData: siteDataReducers };
}

export default withSnackbar(withRouter(connect(mapStateToProps, mapDispatchToProps)(AidList)));