import strings from "../../localization";
import React from "react";
import SelectControl from "../../components/controls/SelectControl";
import { TextField } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import DatePickerControl from "../../components/controls/DatePickerControl";

const LeaseProcessingHeader = ({
  selectRenewalTypeKind,
  onChange,
  selectedRenewalTypeKind,
  graveyards,
  selectedGraveyard,
  find,
  data,
}) => {
  return (
    <form style={{ display: 'flex', flexDirection: 'row', alignContent: 'flex-start', alignItems: 'flex-start' }}>
      <div style={{ width: 200 }}>
        <SelectControl
          options={selectRenewalTypeKind}
          selected={selectedRenewalTypeKind}
          onChange={onChange}
          label={strings.leaseRenewalProcessingList.leaseRenewalType}
          name={"selectedRenewalTypeKind"}
          nameKey={"name"}
          valueKey={"id"}
        />
      </div>

      <div style={{ width: 120, marginLeft: 25 }}>
        <DatePickerControl
          label={strings.renewalProcessing.periodFrom}
          placeholder={strings.renewalProcessing.periodFrom}
          name="renewalProcessingFromDate"
          onChange={onChange}
          date={
            data.renewalProcessingFromDate
              ? new Date(data.renewalProcessingFromDate)
              : data.renewalProcessingFromDate
          }
          isClearable={true}
          dateFormat="dd/MM/yyyy"
        />
      </div>

      <div style={{ width: 120, marginLeft: 25 }}>
        <DatePickerControl
          label={strings.renewalProcessing.periodTo}
          placeholder={strings.renewalProcessing.periodTo}
          name="renewalProcessingToDate"
          onChange={onChange}
          date={
            data.renewalProcessingToDate
              ? new Date(data.renewalProcessingToDate)
              : data.renewalProcessingToDate
          }
          isClearable={true}
          dateFormat="dd/MM/yyyy"
        />
      </div>

      <div style={{ width: 250, marginLeft: 25 }}>
        <SelectControl
          options={graveyards}
          selected={selectedGraveyard}
          onChange={onChange}
          label={strings.renewalList.graveyard}
          name={"graveyard"}
          nameKey={"name"}
          valueKey={"id"}
        />
      </div>

      <div style={{ width: 50, marginLeft: 25 }}>
        <TextField
          label={strings.renewalProcessing.field}
          fullWidth
          name="field"
          onChange={onChange}
          margin="normal"
          defaultValue={data.field}
          value={data.field}
          inputProps={{ maxLength: 2 }}
          InputLabelProps={{
            shrink: true,
          }}
          disabled={false}
        />
      </div>

      <div style={{ width: 50, marginLeft: 25 }}>
        <TextField
          label={strings.renewalProcessing.plot}
          fullWidth
          name="plot"
          onChange={onChange}
          margin="normal"
          defaultValue={data.plot}
          value={data.plot}
          inputProps={{ maxLength: 2 }}
          InputLabelProps={{
            shrink: true,
          }}
          disabled={false}
        />
      </div>

      <div style={{ width: 165, marginLeft: 25 }}>
        <TextField
          label={strings.renewalProcessing.graveSiteNumber}
          fullWidth
          name="graveSiteNumber"
          onChange={onChange}
          margin="normal"
          defaultValue={data.graveSiteNumber}
          value={data.graveSiteNumber}
          InputLabelProps={{
            shrink: true,
          }}
          disabled={false}
        />
      </div>

      <div className="select-grave-site-button" style={{ marginLeft: 25 }}>
        <IconButton
          onClick={find}
          disabled={
            selectedRenewalTypeKind == undefined ||
            !data.renewalProcessingFromDate 
              ? true
              : false
          }
        >
          {strings.review.buttons.find}
        </IconButton>
      </div>
    </form>
  );
};

export default LeaseProcessingHeader;
