import strings from '../localization';

export function getMainBookOfBuriedSelect() {

  return [
    { id: 1, value: strings.reviewMainBookOfBuried.deceasedNameSurnameSubmitter, name: strings.reviewMainBookOfBuried.deceasedNameSurnameSubmitter },
    { id: 2, value: strings.reviewMainBookOfBuried.deceasedNameSurnameDeceased, name: strings.reviewMainBookOfBuried.deceasedNameSurnameDeceased },
    { id: 3, value: strings.reviewMainBookOfBuried.doctorTransporter, name: strings.reviewMainBookOfBuried.doctorTransporter },
    { id: 4, value: strings.reviewMainBookOfBuried.graveField, name: strings.reviewMainBookOfBuried.graveField },
  ];
}

export function downloadReport(response, value) {

  var file = new Blob([response.data], { type: 'application/pdf' });
  const link = document.createElement('a');
  link.href = URL.createObjectURL(file);
  link.download = value + ".pdf";
  link.click();
}

export function getRenewalSearches() {

  return [
    { id: 1, value: strings.renewalTypes.submitterNameSurname, name: strings.renewalTypes.submitterNameSurname },
    { id: 2, value: strings.renewalTypes.deceasedNameSurname, name: strings.renewalTypes.deceasedNameSurname },
    { id: 3, value: strings.renewalTypes.graveSite, name: strings.renewalTypes.graveSite },
  ];
}

export function getRenewalTypes() {

  return [
    { id: 1, value: "01", name: strings.renewalTypes.grave },
    { id: 2, value: "02", name: strings.renewalTypes.reservation },
    { id: 3, value: "03", name: strings.renewalTypes.tombrent },
    { id: 4, value: "04", name: strings.renewalTypes.cassetterent },
    { id: 5, value: "05", name: strings.renewalTypes.arrangedPlot },
    { id: 8, value: "08", name: strings.renewalTypes.childGrave },
  ];
}

export function getLeaseTypes() {

  return [
    { id: 3, value: "03", name: strings.renewalTypes.tombrent },
    { id: 4, value: "04", name: strings.renewalTypes.cassetterent },
    { id: 5, value: "05", name: strings.renewalTypes.arrangedPlot },
  ];
}

export function getReservationTypes() {

  return [
    { id: 1, value: "01", name: strings.reservationTypes.canceled },
    { id: 2, value: "02", name: strings.reservationTypes.unRenewed },
  ];
}

export function getGraveSiteTypes() {

  return [
    { id: 1, value: "r", name: strings.graveSiteTypes.raka },
    { id: 2, value: "g", name: strings.graveSiteTypes.tomb },
    { id: 3, value: "k", name: strings.graveSiteTypes.cassette },
  ];
}

export function getGraveSiteRenewalTypes() {

  return [
    { id: 1, value: 0, name: strings.graveSiteRenewalTypes.false },
    { id: 2, value: 1, name: strings.graveSiteRenewalTypes.true },
  ];
}

export function getFuneralTypes() {
  return [
    { id: 1, value: "funeral", name: strings.funeralScheduling.funeral },
    { id: 2, value: "urn_storage", name: strings.funeralScheduling.urnStorage },
    { id: 3, value: "farewell", name: strings.funeralScheduling.farewell },
    { id: 4, value: "cremation", name: strings.funeralScheduling.cremation },
  ];
}

export function getNecessaryArrangements() {
  return [
    { id: 1, value: strings.funeralScheduling.removeAndReturnGravePlate, name: strings.funeralScheduling.removeAndReturnGravePlate },
    { id: 2, value: strings.funeralScheduling.dismantleTombstone, name: strings.funeralScheduling.dismantleTombstone },
    { id: 3, value: strings.funeralScheduling.breakTheExtentOfTheGraveSite, name: strings.funeralScheduling.breakTheExtentOfTheGraveSite },
    { id: 4, value: strings.funeralScheduling.hireServicesForArrangingAndMaintainingGreenSurfaces, name: strings.funeralScheduling.hireServicesForArrangingAndMaintainingGreenSurfaces },
  ];
}

export function getAdditionalItems() {
  return [
    { id: 1, value: strings.funeralScheduling.microphone, name: strings.funeralScheduling.microphone },
    { id: 2, value: strings.funeralScheduling.deathbed, name: strings.funeralScheduling.deathbed },
    { id: 3, value: strings.funeralScheduling.pillowsForDecorations, name: strings.funeralScheduling.pillowsForDecorations },
    { id: 4, value: strings.funeralScheduling.tableForAttendees, name: strings.funeralScheduling.tableForAttendees },
  ];
}

export function getFarewellMusicTypes() {
  return [
    { id: 1, value: "bell", name: strings.funeralScheduling.bell },
    { id: 2, value: "silent", name: strings.funeralScheduling.silent },
    { id: 3, value: "beethoven", name: strings.funeralScheduling.beethoven },
    { id: 4, value: "lenin", name: strings.funeralScheduling.lenin },
    { id: 5, value: "schopen", name: strings.funeralScheduling.schopen },
    { id: 6, value: "chorus_of_the_jews", name: strings.funeralScheduling.chorusOfTheJews },
    { id: 7, value: "tambourine_players", name: strings.funeralScheduling.tambourinePlayers },
    { id: 8, value: "quiet_night", name: strings.funeralScheduling.quietNight },
    { id: 9, value: "cd", name: strings.funeralScheduling.cd },
    { id: 10, value: "music_live", name: strings.funeralScheduling.music_live },
  ];
}

export function getFarewellMusicType(type) {
  switch (type) {
    case "bell":
      return strings.funeralScheduling.bell;
    case "silent":
      return strings.funeralScheduling.silent;
    case "beethoven":
      return strings.funeralScheduling.beethoven;
    case "lenin":
      return strings.funeralScheduling.lenin;
    case "schopen":
      return strings.funeralScheduling.schopen;
    case "chorus_of_the_jews":
      return strings.funeralScheduling.chorusOfTheJews;
    case "tambourine_players":
      return strings.funeralScheduling.tambourinePlayers;
    case "quiet_night":
      return strings.funeralScheduling.quietNight;
    case "cd":
      return strings.funeralScheduling.cd;
    case "music_live":
      return strings.funeralScheduling.music_live;
  }
}

export function getUrnStorageTypes() {
  return [
    { id: 1, value: "columbarium", name: strings.cremationScheduling.columbarium },
    { id: 2, value: "rosarium", name: strings.cremationScheduling.rosarium },
    { id: 3, value: "garden_of_memories", name: strings.cremationScheduling.gardenOfMemories },
    { id: 4, value: "osarium", name: strings.cremationScheduling.osarium },
    { id: 5, value: "other", name: strings.cremationScheduling.other },
  ];
}

export function getFarewellTypes() {
  return [
    { id: 1, value: "transit", name: strings.funeralScheduling.form.farewellTransit },
    { id: 2, value: "cremation", name: strings.funeralScheduling.form.farewellCremation },
  ];
}

export function getAttachedDocumentTypes() {
  return [
    { id: 1, value: strings.cremationScheduling.deathCertificate, name: strings.cremationScheduling.deathCertificate },
    { id: 2, value: strings.cremationScheduling.extractFromRegistryBookOfDead, name: strings.cremationScheduling.extractFromRegistryBookOfDead },
    { id: 3, value: strings.cremationScheduling.invoiceFromPurchasedFuneralEquipment, name: strings.cremationScheduling.invoiceFromPurchasedFuneralEquipment },
    { id: 4, value: strings.cremationScheduling.authorizationPowerOfAttorneyForLegalEntity, name: strings.cremationScheduling.authorizationPowerOfAttorneyForLegalEntity },
    { id: 5, value: strings.cremationScheduling.approvalFromTheAuthority, name: strings.cremationScheduling.approvalFromTheAuthority },
    { id: 6, value: strings.cremationScheduling.contractForLeasedGraveSite, name: strings.cremationScheduling.contractForLeasedGraveSite },
    { id: 7, value: strings.cremationScheduling.proofOfDeceasedWishToBeCremated, name: strings.cremationScheduling.proofOfDeceasedWishToBeCremated },
  ];
}

export function getProofOfWishTypes() {
  return [
    { id: 1, value: "will", name: strings.cremationScheduling.will },
    { id: 2, value: "crematists_association", name: strings.cremationScheduling.crematistsAssociation },
    { id: 3, value: "lifetime_support_agreement", name: strings.cremationScheduling.lifetimeSupportAgreement },
    { id: 4, value: "certified_statement_of_the_deceased", name: strings.cremationScheduling.certifiedStatementOfTheDeceased },
    { id: 5, value: "certified_declaration_of_relatives", name: strings.cremationScheduling.certifiedDeclarationOfRelatives },
  ];
}

export function getLetterTypes() {
  return [
    { id: 1, value: "latin", name: strings.equipmentOrder.latin },
    { id: 2, value: "cyrillic", name: strings.equipmentOrder.cyrillic },
  ];
}

export function getLetters() {
  return [
    { id: 1, value: "metal", name: strings.equipmentOrder.metalLetters },
    { id: 2, value: "plastic", name: strings.equipmentOrder.plasticLetters },
  ];
}

export function getTombstones() {
  return [
    { id: 1, value: "cross", name: strings.equipmentOrder.cross },
    { id: 2, value: "pyramid", name: strings.equipmentOrder.pyramid },
    { id: 3, value: "reformation_board", name: strings.equipmentOrder.reformationBoard },
  ];
}

export function getDeceasedPreparations() {
  return [
    { id: 1, value: strings.equipmentOrder.washDeceased, name: strings.equipmentOrder.washDeceased },
    { id: 2, value: strings.equipmentOrder.shaveDeceased, name: strings.equipmentOrder.shaveDeceased },
    { id: 3, value: strings.equipmentOrder.dressDeceased, name: strings.equipmentOrder.dressDeceased },
  ];
}

export function getTransportOptions() {
  return [
    { id: 1, value: "within_city_limits_up_to_30_km", name: strings.equipmentOrder.withinCityLimits },
    { id: 2, value: "outside_city_limits_within_state_by_km", name: strings.equipmentOrder.outsideCityLimitsWithinState },
    { id: 3, value: "outside_city_limits_outside_state_by_km", name: strings.equipmentOrder.outsideCityLimitsOutsideState },
  ];
}

export function getExhumationGraveSiteTypes() {
  return [
    { id: 1, value: "grave", name: strings.exhumationRequest.grave },
    { id: 2, value: "tomb", name: strings.exhumationRequest.tomb },
    { id: 3, value: "cassette", name: strings.exhumationRequest.cassette },
    { id: 4, value: "columbarium", name: strings.exhumationRequest.columbarium },
    { id: 5, value: "rosarium", name: strings.exhumationRequest.rosarium },
    { id: 6, value: "garden_of_memories", name: strings.exhumationRequest.gardenOfMemories },
    { id: 7, value: "other", name: strings.exhumationRequest.other },
  ];
}

export function getAppointmentTimesCityGraveyard() {
  return [
    { id: 1, value: "09:45" },
    { id: 2, value: "10:30" },
    { id: 3, value: "11:15" },
    { id: 4, value: "12:00" },
    { id: 5, value: "12:45" },
    { id: 6, value: "13:30" },
    { id: 7, value: "14:15" },
    { id: 8, value: "15:00" },
  ]
}

export function getAppointmentTimesExternalGraveyards() {
  return [
    { id: 1, value: "11:00" },
    { id: 2, value: "13:00" },
    { id: 3, value: "15:00" },
  ]
}