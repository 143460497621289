import React from 'react'
import { bindActionCreators } from "redux";
import * as Actions from "../../actions/Actions";
import { withRouter } from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import Grid from '@material-ui/core/Grid';
import { Paper } from "@material-ui/core";
import strings from "../../localization";
import Validators from "../../constants/ValidatorTypes";
import FormComponent from "../../common/FormComponent";
import UserForm from "../../components/forms/user/UserForm";
import { withSnackbar } from "notistack";
import { getAllCompanies, getCompanyById } from '../../services/company/CompanyService';
import update from 'immutability-helper';
import { addAdmin } from '../../services/superadmin/SuperadminService';
import { addUser } from '../../services/admin/AdminService';

class AddUser extends FormComponent {

    validationList = {
        email: [{ type: Validators.EMAIL }],
        name: [{ type: Validators.REQUIRED }],
        surname: [{ type: Validators.REQUIRED }],
        phoneNumber: [{ type: Validators.REQUIRED }, { type: Validators.IS_NUMBER }],
    };

    constructor(props) {
        super(props);

        this.state = {
            data: props.data ? props.data : {},
            errors: {},
            companies: null,
        };

        this.props.changeFullScreen(false);

        this.submit = this.submit.bind(this);
        this.handleError = this.handleError.bind(this);

    }

    fetchData() {
        getAllCompanies().then(response => {
            if (!response.ok) {
                return;
            }
            this.setState({
                companies: response.data.entities,
            });
        });
        
        {this.props.cid != 0 &&
            getCompanyById(this.props.cid).then(response => {
                if (!response.ok) {
                    return;
                }
                this.setState({
                    company: response.data,
                });
            });   
        }
    }

    componentDidMount() {
        this.fetchData();
    }

    changeData(event, data = 'data') {
        this.setState({
            [data]: update(this.state[data], { [event.target.name]: { $set: event.target.value } })
        }, () => { this.fetchData();  });
    }

    submit() {

        if (!this.validate()) {
            return;
        }

        this.showDrawerLoader();
        if(this.props.cid != 0){    
            addUser(this.transformRequestWithCompany(this.state.data)).then(response => {

                if (!response.ok) {
                    let messageKey = response.response.data.message;
    
                    this.props.enqueueSnackbar(strings.user.messages[messageKey], {
                        variant: "error"
                    });
    
                    this.handleError(messageKey);
    
                    return;
                }
    
                this.props.enqueueSnackbar(strings.addUser.userAdded, { variant: 'success' });
                this.props.onFinish(response.data.user);
    
                this.hideDrawerLoader();
            });
        }else {
            addAdmin(this.transformRequestWithoutCompany(this.state.data)).then(response => {

                if (!response.ok) {
                    let messageKey = response.response.data.message;
    
                    this.props.enqueueSnackbar(strings.user.messages[messageKey], {
                        variant: "error"
                    });
    
                    this.handleError(messageKey);
    
                    return;
                }
    
                this.props.enqueueSnackbar(strings.addUser.userAdded, { variant: 'success' });
                this.props.onFinish(response.data.user);
    
                this.hideDrawerLoader();
            });
        }
    }

    transformRequestWithCompany(data){
        let company = this.state.company;

        return {
            ...data,
            company,
            actionUserEmail: this.props.uemail,
        };   
    }

    transformRequestWithoutCompany(data){

        return {
            ...data,
            actionUserEmail: this.props.uemail,
        };   
    }

    handleError(message) {

        switch (message) {
            case "USER_WITH_EMAIL_ALREADY_EXIST":
                this.setError("email", strings.user.messages.USER_WITH_EMAIL_ALREADY_EXIST);
                break;
            case "USER_COMPANY_CANT_BE_NULL":
                this.setError("company", strings.user.messages.USER_COMPANY_CANT_BE_NULL);
                break;
            case "USER_EMAIL_CANT_BE_NULL":
                this.setError("email", strings.user.messages.USER_EMAIL_CANT_BE_NULL);
                break;
            case "USER_NAME_CANT_BE_NULL":
                this.setError("name", strings.user.messages.USER_NAME_CANT_BE_NULL);
                break;
            case "USER_SURNAME_CANT_BE_NULL":
                this.setError("surname", strings.user.messages.USER_SURNAME_CANT_BE_NULL);
                break;
            case "USER_PHONE_CANT_BE_NULL":
                this.setError("phoneNumber", strings.user.messages.USER_PHONE_CANT_BE_NULL);
                break;
        }
    }

    render() {
        return (
            <Grid id='page' item md={12}>

                <div className='header'>
                    <h1>{strings.addUser.pageTitle}</h1>
                </div>

                <Paper className='paper'>
                    <UserForm 
                        onChange={this.changeData} 
                        onSubmit={this.submit}
                        data={this.state.data}
                        companies={this.state.companies} 
                        errors={this.state.errors} 
                        onCancel={this.props.onCancel} 
                        role={this.props.role}
                    />
                </Paper>

            </Grid>

        );
    }
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        changeFullScreen: Actions.changeFullScreen
    }, dispatch);
}

function mapStateToProps({ menuReducers, siteDataReducers }) {
    return { menu: menuReducers, siteData: siteDataReducers };
}

export default withSnackbar(withRouter(connect(mapStateToProps, mapDispatchToProps)(AddUser)));