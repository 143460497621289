import {request} from "../../base/HTTP";
import HttpMethod from "../../constants/HttpMethod";

export async function getAllCompanies() {
    return await request("api/company/all");
}

export async function getCompanies(data) {
    return await request('/api/company/search', data);
}

export async function addCompany(data) {
    return await request('/api/company/', data, HttpMethod.POST);
}

export function getCompanyById(id) {
    return  request("api/company/" + id , {}, HttpMethod.GET);
}

export async function editCompany(data) {
    return await request("api/company/" + data.id, data, HttpMethod.PUT);
}

export async function deleteCompany(id, uid) {
    return await request('/api/company/' + id + '/' + uid, {} , HttpMethod.DELETE);
}