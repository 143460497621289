import {request} from "../../base/HTTP";
import HttpMethod from "../../constants/HttpMethod";

export async function getRolePermissions(data) {
    return await request('/api/role_permission/search', data);
}

export async function addRolePermissions(data, cid) {
    return await request('/api/role_permission/' + cid, data, HttpMethod.POST);
}

export async function deleteRolePermission(id, uid){
    return await request('/api/role_permission/' + id + '/' + uid, {} , HttpMethod.DELETE);
}