let CONFIG = {
   // baseUrlAPI: 'http://localhost:8080/api/',
   // baseUrl: 'http://localhost:8080/',
  baseUrlAPI: 'https://api-app.lisje.com/api/',
  baseUrl: 'https://api-app.lisje.com/',
  tokenKey: '1stKgorpnoj8mFIsI3sg',
  refreshTokenKey: 'ZJmD63XQFsK6cHUWwN5T',
  clientId: 'client_id',
  clientSecret: 'client_secret',
  facebookAppId: 'FACEBOOK_APP_ID',
  facebookAppSecret: 'FACEBOOK_APP_SECRET',
  googleClientId: 'GOOGLE_CLIENT_ID',
  googleClientSecret: 'GOOGLE_CLIENT_SECRET',
  linkedInClientId: 'LINKEDIN_CLIENT_ID',
  linkedInCliendSecret: 'LINKEDIN_CLIENT_SECRET',
  rowsPerPage: [5, 10, 25],
  rolesAllowed: ['ADMIN', 'SUPERADMIN', 'USER']
};

export default CONFIG;
