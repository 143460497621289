import React from 'react';
import strings from "../../../localization";
import { getError, hasError } from "../../../functions/Validation";
import { Button, TextField } from "@material-ui/core";
import DatePickerControl from '../../controls/DatePickerControl';
import { stringToDate } from '../../../util/DateUtil';
import moment from 'moment';

const GraveyardForm = ({
    onSubmit,
    onCancel,
    onChange,
    errors,
    data,
    isDisabled
}) => {
    return (

        <form id='user-form'>
            <TextField
                label={strings.graveyard.form.code + "*"}
                error={hasError(errors, 'code')}
                helperText={getError(errors, 'code')}
                fullWidth
                autoFocus
                name='code'
                onChange={onChange}
                margin="normal"
                defaultValue={data.code}
                value={data.code}
                InputLabelProps={{
                    shrink: true,
                }}
                disabled={isDisabled}
            />
            <TextField
                label={strings.graveyard.form.name + "*"}
                error={hasError(errors, 'name')}
                helperText={getError(errors, 'name')}
                fullWidth
                name='name'
                onChange={onChange}
                margin="normal"
                defaultValue={data.name}
                value={data.name}
                InputLabelProps={{
                    shrink: true,
                }}
                disabled={isDisabled}
            />
            <TextField
                label={strings.graveyard.form.address + "*"}
                error={hasError(errors, 'address')}
                helperText={getError(errors, 'address')}
                fullWidth
                name='address'
                onChange={onChange}
                margin="normal"
                defaultValue={data.address}
                value={data.address}
                InputLabelProps={{
                    shrink: true,
                }}
                disabled={isDisabled}
            />
            <TextField
                label={strings.graveyard.form.place + "*"}
                error={hasError(errors, 'place')}
                helperText={getError(errors, 'place')}
                fullWidth
                name='place'
                onChange={onChange}
                margin="normal"
                defaultValue={data.place}
                value={data.place}
                InputLabelProps={{
                    shrink: true,
                }}
                disabled={isDisabled}
            />
            <TextField
                label={strings.graveyard.form.surface + "*"}
                error={hasError(errors, 'surface')}
                helperText={getError(errors, 'surface')}
                fullWidth
                name='surface'
                onChange={onChange}
                margin="normal"
                defaultValue={data.surface}
                value={data.surface}
                InputLabelProps={{
                    shrink: true,
                }}
                disabled={isDisabled}
            />
            <TextField
                label={strings.graveyard.form.status + "*"}
                error={hasError(errors, 'status')}
                helperText={getError(errors, 'status')}
                fullWidth
                name='status'
                onChange={onChange}
                margin="normal"
                defaultValue={data.status}
                value={data.status}
                InputLabelProps={{
                    shrink: true,
                }}
                disabled={isDisabled}
            />
            <TextField
                label={strings.graveyard.form.denomination + "*"}
                error={hasError(errors, 'denomination')}
                helperText={getError(errors, 'denomination')}
                fullWidth
                name='denomination'
                onChange={onChange}
                margin="normal"
                defaultValue={data.denomination}
                value={data.denomination}
                InputLabelProps={{
                    shrink: true,
                }}
                disabled={isDisabled}
            />
            <TextField
                label={strings.graveyard.form.yearOfEstablishment + "*"}
                error={hasError(errors, 'yearOfEstablishment')}
                helperText={getError(errors, 'yearOfEstablishment')}
                fullWidth
                name='yearOfEstablishment'
                onChange={onChange}
                margin="normal"
                defaultValue={data.yearOfEstablishment}
                value={data.yearOfEstablishment}
                InputLabelProps={{
                    shrink: true,
                }}
                disabled={isDisabled}
            />
            {(data.takeoverDateString != undefined && data.takeoverDateString != null && data.takeoverDateString != "") &&
                <TextField
                    label={strings.graveyard.form.takeoverDateString}
                    fullWidth
                    name='takeoverDateString'
                    onChange={onChange}
                    margin="normal"
                    value={data.takeoverDateString}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    disabled={true}
                />
            }
            <DatePickerControl
                label={strings.graveyard.form.takeoverDate + "*"}
                error={getError(errors, 'takeoverDate')}
                hasError={hasError(errors, 'takeoverDate')}
                helperText={getError(errors, 'takeoverDate')}
                placeholder={strings.graveyard.form.takeoverDate}
                name='takeoverDate'
                onChange={onChange}
                //date={data.takeoverDate ? new Date(data.takeoverDate) : data.takeoverDate}
                date={data.takeoverDate ? stringToDate(data.takeoverDateString) > moment(data.takeoverDate, 'YYYY-MM-DD') ? stringToDate(data.takeoverDateString).toDate() : new Date(data.takeoverDate) : data.takeoverDate}
                isClearable={true}
                dateFormat='dd/MM/yyyy'
                disabled={isDisabled}
            />

            {
                !isDisabled &&
                <div className='submit-container'>
                    <Button variant="contained" color="primary" onClick={onSubmit}>
                        {strings.graveyard.form.ok}
                    </Button>
                    <Button variant="contained" color="secondary" onClick={onCancel}>
                        {strings.graveyard.form.cancel}
                    </Button>
                </div>
            }
        </form>
    )
};

export default GraveyardForm;