import FormComponent from "../../common/FormComponent";
import React from "react";
import { bindActionCreators } from "redux";
import * as Actions from "../../actions/Actions";
import { withRouter } from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import { withSnackbar } from "notistack";
import Validators from "../../constants/ValidatorTypes";
import Grid from '@material-ui/core/Grid';
import strings from "../../localization";
import { addPostalCodeImproved, getPostalCodeImprovedById } from "../../services/postalCodeImproved/PostalCodeImprovedService";
import PostalCodeImprovedForm from "../../components/forms/postalCodeImproved/PostalCodeImprovedForm";
import { Paper } from "@material-ui/core";
import { getStates } from "../../services/state/StateService";


class AddPostalCodeImproved extends FormComponent {

    validationList = {
        state: [{ type: Validators.REQUIRED }],
        zip: [{ type: Validators.REQUIRED }],
        city: [{ type: Validators.REQUIRED }],
    };

    constructor(props) {
        super(props);

        this.state = {
            data: props.data ? props.data : {},
            errors: {}
        };

        this.state.sortBy = "name,ASC";

        this.props.changeFullScreen(false);

        this.submit = this.submit.bind(this)

    }

    onAutocompleteChange = (event, values) => {
        let autocompleteName = event.target.id.split("-")[0];
        this.setState({
            data: {
                ...this.state.data, [autocompleteName]: values
            }
        });


    }

    handleTextInputChange = (e) => {
        if (e.target.name === "state" && e.target.value.trim().length >= 2) {
            let data = {
                searchParam: e.target.value,
                companyId: this.props.cid,
                sort: this.state.sortBy,

                userId: 0
            };
            {
                this.props.cid != undefined &&
                    getStates(data).then(response => {
                        if (!response.ok) {
                            return;
                        }
                        this.setState({
                            states: response.data.entities,
                        });
                    });
            }

        }
    }

    submit() {

        if (!this.validate()) {
            this.props.enqueueSnackbar(strings.error.requiredFields, { variant: 'error' });
            return;
        }

        this.showDrawerLoader();

        addPostalCodeImproved(this.transformRequest(this.state.data), this.props.cid).then(response => {
            if (!response.ok) {
                let messageKey = response.response.data.message;

                this.props.enqueueSnackbar(strings.postalCodeImproved.messages[messageKey], {
                    variant: "error"
                });

                this.handleError(messageKey);

                return;
            }

            this.props.enqueueSnackbar(strings.addCompany.postalCodeAdded, { variant: 'success' });
            this.props.onFinish(response.data.state);

            this.hideDrawerLoader();
        });
    }

    transformRequest(data) {
        return {
            ...data,
            userEmail: this.props.uemail,
            stateId: this.state.data.state ? this.state.data.state.id : null,
        }
    }

    handleError(message) {
        switch (message) {
            case "POSTAL_CODE_IMPROVED_ZIP_CANT_BE_NULL":
                this.setError("zip", strings.postalCodeImproved.messages.POSTAL_CODE_IMPROVED_ZIP_CANT_BE_NULL);
                break;
            case "POSTAL_CODE_IMPROVED_CITY_CANT_BE_NULL":
                this.setError("city", strings.postalCodeImproved.messages.POSTAL_CODE_IMPROVED_CITY_CANT_BE_NULL);
                break;
            case "POSTAL_CODE_IMPROVED_STATE_CANT_BE_NULL":
                this.setError("stateName", strings.postalCodeImproved.messages.POSTAL_CODE_IMPROVED_STATE_CANT_BE_NULL);
                break;

        }
    }

    render() {
        return (
            <Grid id='page' item md={12}>

                <div className='header'>
                    <h1>{strings.postalCode.pageTitle}</h1>
                </div>
                <Paper className='paper'>
                    <PostalCodeImprovedForm
                        onChange={this.changeData}
                        onSubmit={this.submit}
                        data={this.state.data}
                        errors={this.state.errors}
                        onCancel={this.props.onCancel}
                        states={this.state.states == undefined ? [] : this.state.states}
                        onAutocompleteChange={this.onAutocompleteChange}
                        handleTextInputChange={this.handleTextInputChange}
                        flag={this.props.flag} />
                </Paper>
            </Grid>

        );
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        changeFullScreen: Actions.changeFullScreen
    }, dispatch);
}

function mapStateToProps({ menuReducers, siteDataReducers }) {
    return { menu: menuReducers, siteData: siteDataReducers };
}

export default withSnackbar(withRouter(connect(mapStateToProps, mapDispatchToProps)(AddPostalCodeImproved)));