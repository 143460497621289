import { request } from "../../base/HTTP";
import HttpMethod from "../../constants/HttpMethod";

export async function getAllDeceasedBrought() {
  return await request("api/deceased/all");
}

export async function getDeceasedBrought(data) {
  return await request("api/deceased/search", data);
}

export function getDeceasedBroughtById(id) {
  return request("api/deceased/" + id, {}, HttpMethod.GET);
}

export async function addDeceasedBrought(data, cid) {
  return await request("/api/deceased/" + cid, data, HttpMethod.POST);
}

export async function editDeceasedBrought(data) {
  return await request("api/deceased/" + data.id, data, HttpMethod.PUT);
}

export async function deleteDeceasedBrought(id, uid) {
  return await request("/api/deceased/" + id + "/" + uid, {}, HttpMethod.DELETE);
}

export async function getDeletedDeceasedBrought(data) {
  return await request("/api/deceased/deleted", data)
}

export async function restoreDeceasedBrought(data) {
  return await request("api/deceased/restore/" + data.id, data, HttpMethod.PUT);
}