import React from "react";

import { bindActionCreators } from "redux";
import * as Actions from "../../actions/Actions";
import { withRouter } from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import strings from "../../localization";
import Page from "../../common/Page";

import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import LoginForm from "../../components/forms/user/LoginForm";
import Validators from "../../constants/ValidatorTypes";
import { login } from "../../base/OAuth";
import { getPermissionNavigation } from "../../services/permissionAdministration/PermissionAdministrationService";
import { ThreeSixtyOutlined } from "@material-ui/icons";

class Login extends Page {
  validationList = {
    email: [{ type: Validators.EMAIL }],
    password: [{ type: Validators.REQUIRED }]
  };

  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
      data: {},
      errors: {},
      redirectUrl: props.location.state ? props.location.state.redirectUrl : "/",
      showPassword: false
    };

    this.props.changeFullScreen(true);

    this.keyPress = this.keyPress.bind(this);
    this.showPasswordButton = this.showPasswordButton.bind(this);
  }

  componentDidMount() {
    
    if (this.props.auth.user) {
      this.props.history.push("/");
    }

  }

  showPasswordButton(){
    this.setState({
      showPassword: !this.state.showPassword
    })
  }

 

  keyPress(event) {
    if (event.key == "Enter") {
      this.login();
    }
  }

  getPermissions(){
    
    getPermissionNavigation().then(response =>{
      
      if(!response.ok){
        return;
      }
      this.props.setPermissions(response.data.entities)

    })
  }

  login() {
    if (!this.validate()) {
      return;
    }



    login(this.state.data.email, this.state.data.password).then(response => {
      if (!response.ok) {
        this.setError("email", strings.login.wrongCredentials);
        this.setError("password", strings.login.wrongCredentials);
        return;
      }

      this.getPermissions();
      this.props.login(response.data);

      this.props.history.push({
        pathname: this.state.redirectUrl
      });
    }, () => {
      

    });
  }

  render() {
    return (
      <div id="login">
        <Grid item md={6}>
          <Paper className="paper">
            <h1>{strings.login.login}</h1>

            <LoginForm
              onSubmit={() => this.login()}
              onChange={this.changeData}
              keyPress={this.keyPress}
              data={this.state.data}
              errors={this.state.errors}
              showPassword={this.state.showPassword}
              showPasswordButton={this.showPasswordButton}
            />
          </Paper>
        </Grid>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      changeFullScreen: Actions.changeFullScreen,
      login: Actions.login,
      setPermissions: Actions.setPermissions
    },
    dispatch
  );
}

function mapStateToProps({ menuReducers, authReducers }) {
  return { menu: menuReducers, auth: authReducers };
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Login)
);
