import React from 'react'
import TablePage from "../../common/TablePage";
import { bindActionCreators } from "redux";
import * as Actions from "../../actions/Actions";
import { withRouter } from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import strings from "../../localization";
import { withSnackbar } from "notistack";
import { deleteCompany, getCompanies } from '../../services/company/CompanyService';
import AddCompany from './AddCompany';
import EditCompany from './EditCompany';
import ViewCompany from './ViewCompany';


class CompanyList extends TablePage {

    tableDescription = [
        { key: 'email', label: strings.companyList.email, sort: 'email' },
        { key: 'name', label: strings.companyList.name, sort: 'name' },
        { key: 'registrationNumber', label: strings.companyList.registrationNumber, sort: 'registrationNumber' },
        { key: 'city', label: strings.companyList.city, sort: 'city' },
        { key: 'state', label: strings.companyList.state, sort: 'state' },
        { key: 'dateCreated', label: strings.companyList.dateCreated, transform: 'renderColumnDate', sort: 'dateCreated' },
    ];

    constructor(props) {
        super(props);

        this.state.sortBy = "name,ASC";
    }

    fetchData() {
        let data = {
            searchParam: this.state.searchData.search,
            page: this.state.searchData.page - 1,
            size: this.state.searchData.perPage,
            sort: this.state.sortBy
        }

        getCompanies(data).then(response => {
            if (!response.ok) {
                return;
            }

            this.setState({
                tableData: response.data.entities,
                total: response.data.total,
            });
        });
    }

    componentDidMount() {

        this.fetchData();
    }

    componentWillReceiveProps(nextProps) {
        strings.setLanguage(nextProps.auth.user.language);
        this.tableDescription = [
            { key: 'email', label: strings.companyList.email, sort: 'email' },
            { key: 'name', label: strings.companyList.name, sort: 'name' },
            { key: 'registrationNumber', label: strings.companyList.registrationNumber, sort: 'registrationNumber' },
            { key: 'city', label: strings.companyList.city, sort: 'city' },
            { key: 'state', label: strings.companyList.state, sort: 'state' },
            { key: 'dateCreated', label: strings.companyList.dateCreated, transform: 'renderColumnDate', sort: 'dateCreated' },
        ];
    }

    getPageHeader() {

        return <h1>{strings.companyList.pageTitle}</h1>;
    }

    renderAddContent() {

        return <AddCompany uemail={this.props.auth.user.email} onCancel={this.onCancel} onFinish={this.onFinish} />
    }

    renderEditContent() {

        return <EditCompany uemail={this.props.auth.user.email} isDisabled={false} id={this.state.selectedItem.id} onCancel={this.onCancel} onFinish={this.onFinish} />
    }

    renderViewContent() {

        return <ViewCompany id={this.state.selectedItem.id} isDisabled={true} onCancel={this.onCancel} onFinish={this.onFinish} />
    }

    delete(item) {

        this.setState({
            lockTable: true
        });

        deleteCompany(item.id, this.props.auth.user.id).then(response => {

            if (response && !response.ok) {
                this.onFinish(null);
                return;
            }

            this.props.enqueueSnackbar(strings.companyList.companyDelete, { variant: 'success' });

            this.onFinish(item);
            this.cancelDelete();

            this.setState({
                lockTable: false
            });
        });
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        changeFullScreen: Actions.changeFullScreen
    }, dispatch);
}

function mapStateToProps({ menuReducers, authReducers, siteDataReducers }) {
    return { menu: menuReducers, auth: authReducers, siteData: siteDataReducers };
}

export default withSnackbar(withRouter(connect(mapStateToProps, mapDispatchToProps)(CompanyList)));