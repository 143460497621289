import React from 'react'
import TablePage from "../../common/TablePage";
import { bindActionCreators } from "redux";
import * as Actions from "../../actions/Actions";
import { withRouter } from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import strings from "../../localization";
import { withSnackbar } from "notistack";
import { deleteRolePermission, getRolePermissions } from "../../services/rolePermission/RolePermissionsService"
import AddRolePermissions from './AddRolePermissions';

class RolePermissionsList extends TablePage {

    tableDescription = [
        { key: 'roleName', label: strings.rolePermission.role, sort: 'roleName' },
        { key: 'permissionCategory', label: strings.rolePermission.category, sort: 'permissionCategory' },
        { key: 'permissionCode', label: strings.rolePermission.code, sort: 'permissionCategory' },
        { key: 'dateCreated', label: strings.stateList.dateCreated, transform: 'renderColumnDate', sort: 'dateCreated' },
      ];

      constructor(props) {
        super(props);
    
        this.state.showDeleted = false;
        // this.state.sortBy = "ASC";
        
      }

      usePermissions(){
        let data = {
          userId: this.props.auth.user.id,
          category: "Дозволе рола"
        }
        this.getPermissionsS(data);
      
      }

      componentWillReceiveProps(nextProps){
        strings.setLanguage(nextProps.auth.user.language);
        this.tableDescription = [
          { key: 'roleName', label: strings.rolePermission.role, sort: 'roleName' },
          { key: 'permissionCategory', label: strings.rolePermission.category, sort: 'permissionCategory' },
          { key: 'permissionCode', label: strings.rolePermission.code, sort: 'permissionCategory' },
          { key: 'dateCreated', label: strings.stateList.dateCreated, transform: 'renderColumnDate', sort: 'dateCreated' },
        ];
      }
    
      componentDidMount() {
        
        this.usePermissions()
        this.setState({
          companyId: this.props.auth.user.company.id
        }, () => { this.fetchData(); })
      }
      fetchData() {
        let data = {
            searchParam: this.state.searchData.search,
            page: this.state.searchData.page - 1,
            size: this.state.searchData.perPage,
            companyId: this.state.companyId,
            // sort: this.state.sortBy,
            userId: this.props.auth.user.id
        };

        getRolePermissions(data).then(response => {
            if (!response.ok) {
              return;
            }
            
            this.setState({
              tableData: response.data.entities,
              total: response.data.total,
            });
          });
    }

    delete(item) {

      this.setState({
        lockTable: true
      });
  
  
      deleteRolePermission(item.id, this.props.auth.user.id).then(response => {
  
        if (response && !response.ok) {
          this.onFinish(null);
          return;
        }
  
        this.props.enqueueSnackbar(strings.rolePermission.rolePermissionDeleted, { variant: 'success' });
  
        this.onFinish(item);
        this.cancelDelete();
  
        this.setState({
          lockTable: false
        });
      });
    }

    renderAddContent() {
        return <AddRolePermissions cid={this.state.companyId} uemail={this.props.auth.user.email} onCancel={this.onCancel} onFinish={this.onFinish} />
      }

      getPageHeader() {
        return <h1>{strings.rolePermission.pageTitle}</h1>;
      }
    
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
      changeFullScreen: Actions.changeFullScreen
    }, dispatch);
  }
  
  function mapStateToProps({ menuReducers, authReducers, siteDataReducers }) {
    return { menu: menuReducers, auth: authReducers, siteData: siteDataReducers };
  }
  
  export default withSnackbar(withRouter(connect(mapStateToProps, mapDispatchToProps)(RolePermissionsList)));