import {request} from "../../base/HTTP";
import HttpMethod from "../../constants/HttpMethod";

export async function getAllTransporters() {
    return await request('/api/transporter/all');
}

export async function getTransporters(data) {
    return await request('/api/transporter/search', data);
}

export async function addTransporter(data, cid) {
    return await request('/api/transporter/' + cid, data, HttpMethod.POST);
}

export function getTransporterById(id) {
    return  request("api/transporter/" + id , {}, HttpMethod.GET);
}

export async function editTransporter(data) {
    return await request("api/transporter/" + data.id, data, HttpMethod.PUT);
}

export async function deleteTransporter(id, uid) {
    return await request('/api/transporter/' + id + '/' + uid, {} , HttpMethod.DELETE);
}

export async function getDeletedTransporters(data){
    return await request('/api/transporter/deleted', data)
}

export async function restoreTransporter(data) {
    return await request("api/transporter/restore/" + data.id, data, HttpMethod.PUT);
}