import {request} from "../../base/HTTP";
import HttpMethod from "../../constants/HttpMethod";

export async function getCitizens(data) {
    return await request('/api/citizen/search', data);
}

export async function addCitizen(data, cid) {
    return await request('/api/citizen/' + cid, data, HttpMethod.POST);
}

export function getCitizenById(id) {
    return  request("api/citizen/" + id , {}, HttpMethod.GET);
}

export function getLeaserByGraveSiteId(graveSiteId) {
    return  request("api/citizen/leaser/grave-site/" + graveSiteId, {}, HttpMethod.GET);
}

export async function editCitizen(data) {
    return await request("api/citizen/" + data.id, data, HttpMethod.PUT);
}

export async function deleteCitizen(id, uid) {
    return await request('/api/citizen/' + id + '/' + uid, {} , HttpMethod.DELETE);
}

export function getCitizenByPersonalNumber(personalNumber) {
    return  request("api/citizen/mainBook/" + personalNumber , {}, HttpMethod.GET);
}

export async function getDeletedCitizens(data) {
    return await request('/api/citizen/deleted', data);
}

export async function restoreCitizen(data) {
    return await request("api/citizen/restore/" + data.id, data, HttpMethod.PUT);
}