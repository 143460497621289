import FormComponent from "../../common/FormComponent";
import React from "react";
import { bindActionCreators } from "redux";
import * as Actions from "../../actions/Actions";
import { withRouter } from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import Grid from "@material-ui/core/Grid";
import strings from "../../localization";
import { withSnackbar } from "notistack";
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import { getEquipmentOrderById } from "../../services/equipmentOrder/EquipmentOrderService";
import { getDeceasedPreparations, getLetterTypes, getLetters, getTombstones, getTransportOptions } from "../../util/ReviewsUtil";
import DeceasedDetailsEquipmentOrderForm from "../../components/forms/equipmentOrder/DeceasedDetailsEquipmentOrderForm";
import EquipmentOrderForm from "../../components/forms/equipmentOrder/EquipmentOrderForm";
import { getTypefaceById } from "../../services/typeface/TypefaceService";

function TabContainer(props) {
  return (
    <Typography className="tabContainer" component="div">
      {props.children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

class ViewEquipmentOrder extends FormComponent {

  constructor(props) {
    super(props);

    this.state = {
      data: props.data ? props.data : {},
      errors: {},
      value: 0,
      dateFlag: "",
      dateOfBirthWhenValidIDNumber: [],
      letterType: [],
      letterTypes: [],
      tombstone: [],
      tombstones: {},
      letter: [],
      letters: {},
      deceasedPreparation: [],
      deceasedPreparations: {},
      transportOption: [],
      transportOptions: {},
    };

    this.props.changeFullScreen(false);
  }

  componentDidMount() {
    getEquipmentOrderById(this.props.id).then(response => {
      this.setState({
        data: response.data,
        letterTypes: getLetterTypes(),
        tombstones: getTombstones(),
        letters: getLetters(),
        deceasedPreparations: getDeceasedPreparations(),
        transportOptions: getTransportOptions(),
      }, () => {

        this.setState({
          letterType: this.state.letterTypes.find(type => type.value === this.state.data.letterType),
          tombstone: this.state.tombstones.find(type => type.value === this.state.data.tombstone),
          letter: this.state.letters.find(type => type.value === this.state.data.letters),
          transportOption: this.state.transportOptions.find(type => type.value === this.state.data.transportOption),
        });

        this.populateDeceasedPreparations();

        this.getTypeface();
      });
    });
  }

  getTypeface() {
    {
      this.state.data.typefaceId &&
        getTypefaceById(this.state.data.typefaceId).then(response => {
          this.setState({
            data: {
              ...this.state.data,
              typeface: response.data
            },
          });
      });
    }
  }

  populateDeceasedPreparations() {
    const filteredDeceasedPreparations = this.state.deceasedPreparations.filter(preparation => {
      switch (preparation.value) {
        case strings.equipmentOrder.washDeceased:
          return this.state.data.washDeceased ? preparation : null;
        case strings.equipmentOrder.shaveDeceased:
          return this.state.data.shaveDeceased ? preparation : null;
        case strings.equipmentOrder.dressDeceased:
          return this.state.data.dressDeceased ? preparation : null;
        default:
          return null;
      }
    });

    const selectedDeceasedPreparations = filteredDeceasedPreparations.filter(item => item !== null);

    this.setState({
      deceasedPreparation: selectedDeceasedPreparations
    });
  }

  handleChangeTab = (event, value) => {
    this.setState({
      value: value
    });
  };

handleDeceasedPreparationsCheckboxChange = (type, id) => (event) => {
  const checked = event.target.checked;
  const exists = this.state.deceasedPreparation.some(item => item.id === id);

  if (checked && !exists) {
      this.setState(prevState => ({
          deceasedPreparation: [...prevState.deceasedPreparation, { id, value: type, name: type }]
      }));
  } else {
    this.setState(prevState => ({
        deceasedPreparation: prevState.deceasedPreparation.filter(item => item.id !== id)
    }));
  }
}

handleLetterTypeCheckboxChange = (type, id, value) => (event) => {
    const checked = event.target.checked;
    if (checked) {
        this.setState({
            letterType: { id, value: value, name: type}
        });
    }
};

handleTransportOptionsCheckboxChange = (type, id, value) => (event) => {
    const checked = event.target.checked;
    if (checked) {
        this.setState({
            transportOption: { id, value: value, name: type}
        });
    }
};

handleTombstonesCheckboxChange = (type, id, value) => (event) => {
    const checked = event.target.checked;
    if (checked) {
        this.setState({
            tombstone: { id, value: value, name: type}
        });
    }
};

handleLettersCheckboxChange = (type, id, value) => (event) => {
    const checked = event.target.checked;
    if (checked) {
        this.setState({
            letter: { id, value: value, name: type}
        });
    }
};

  render() {
    return (
      <Grid id='page' item md={12}>

        <div className='header'>
          <h1>{strings.equipmentOrder.viewPageTitle}</h1>
        </div>
        <div className="tabsDiv">
          <AppBar position="static">
            <Tabs value={this.state.value} className="tabs" onChange={this.handleChangeTab}>
              <Tab label={strings.equipmentOrder.deceased} className="twoTabs" />
              <Tab label={strings.equipmentOrder.equipmentDetails} className="twoTabs" />
            </Tabs>
          </AppBar>
          {this.state.value === 0 && <TabContainer>

            <DeceasedDetailsEquipmentOrderForm
              onChange={this.changeData}
              onSubmit={this.submit}
              data={this.state.data}
              errors={this.state.errors}
              onCancel={this.props.onCancel}
              handleChange={this.handleChange}
              flag={this.props.flag}
              date={(new Date().getFullYear())}
              disabled={this.state.disabled}
              letterType={this.state.letterType}
              letterTypes={this.state.letterTypes}
              handleLetterTypeCheckboxChange={this.handleLetterTypeCheckboxChange}
              isDisabled={this.props.isDisabled}
            />

          </TabContainer>}
          {this.state.value === 1 && <TabContainer>

            <EquipmentOrderForm
              onChange={this.changeData}
              onSubmit={this.submit}
              data={this.state.data}
              flag={this.props.flag}
              errors={this.state.errors}
              onCancel={this.props.onCancel}
              handleChange={this.handleChange}
              disabled={this.state.disabled}
              tombstone={this.state.tombstone}
              tombstones={this.state.tombstones}
              handleTombstonesCheckboxChange={this.handleTombstonesCheckboxChange}
              letter={this.state.letter}
              letters={this.state.letters}
              handleLettersCheckboxChange={this.handleLettersCheckboxChange}
              deceasedPreparation={this.state.deceasedPreparation}
              deceasedPreparations={this.state.deceasedPreparations}
              handleDeceasedPreparationsCheckboxChange={this.handleDeceasedPreparationsCheckboxChange}
              transportOption={this.state.transportOption}
              transportOptions={this.state.transportOptions}
              handleTransportOptionsCheckboxChange={this.handleTransportOptionsCheckboxChange}
              isDisabled={this.props.isDisabled}
            />

          </TabContainer>}
        </div>

      </Grid>
    );
  }
}

function mapDispatchToProps(dispatch) {

  return bindActionCreators(
    {
      changeFullScreen: Actions.changeFullScreen
    },
    dispatch
  );
}

function mapStateToProps({ menuReducers, siteDataReducers }) {

  return { menu: menuReducers, siteData: siteDataReducers };
}

export default withSnackbar(
  withRouter(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(ViewEquipmentOrder)
  )
);
