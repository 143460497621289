import { request } from "../../base/HTTP";
import HttpMethod from "../../constants/HttpMethod";

export async function getPostalAddressCodesImproved(data) {
    return await request('/api/postalAddressCodeImproved/search', data);
}

export async function getAllPostalAddressCodesImproved() {
    return await request('/api/postalAddressCodeImproved/all');
}

export async function addPostalAdressCodeImproved(data, cid) {
    return await request('/api/postalAddressCodeImproved/' + cid, data, HttpMethod.POST);
}

export function getPostalAddressCodeImprovedById(id) {
    return request('/api/postalAddressCodeImproved/' + id, {}, HttpMethod.GET);
}

export async function editPostalAddressCodeImproved(data) {
    return await request('/api/postalAddressCodeImproved/' + data.id, data, HttpMethod.PUT);
}

export async function deletePostalAddressCodeImproved(id, uid) {
    return await request('/api/postalAddressCodeImproved/' + id + '/' + uid, {}, HttpMethod.DELETE);
}

export async function getCitiesAndZipsWithSelectedState(data) {
    return await request('/api/postalAddressCodeImproved/search/selectedState', data);
}

export async function getPostalAddressCodesWithParams(data){
    return await request('/api/postalAddressCodeImproved/search/pac', data)
}

export async function getDeletedPostalAddressCodesImproved(data){
    return await request('api/postalAddressCodeImproved/deleted', data)
}

export async function restorePostalAddressCodeImproved(data) {
    return await request('/api/postalAddressCodeImproved/restore/' + data.id, data, HttpMethod.PUT);
}
