import {request} from "../../base/HTTP";
import HttpMethod from "../../constants/HttpMethod";

export async function getAllReasonBringingDeceassed() {
    return await request("api/reason/all");
}

export async function getReasonBringingDeceassed(data) {
    return await request('/api/reason/search', data);
}

export async function addReasonBringingDeceassed(data, cid) {
    return await request('/api/reason/' + cid, data, HttpMethod.POST);
}

export function getReasonBringingDeceassedById(id) {
    return  request("api/reason/" + id , {}, HttpMethod.GET);
}

export async function editReasonBringingDeceassed(data) {
    return await request("api/reason/" + data.id, data, HttpMethod.PUT);
}

export async function deleteReasonBringingDeceassed(id, uid) {
    return await request('/api/reason/' + id + '/' + uid, {} , HttpMethod.DELETE);
}

export async function getDeletedReasonBringingDeceassed(data){
    return await request('/api/reason/deleted', data)
}

export async function restoreReasonBringingDeceased(data) {
    return await request("api/reason/restore/" + data.id, data, HttpMethod.PUT);
}