import React from 'react';
import strings from "../../../localization";
import { bindActionCreators } from "redux";
import { withSnackbar } from "notistack";
import { withRouter } from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import * as Actions from "../../../actions/Actions";
import Page from '../../../common/Page';
import SelectControl from '../../../components/controls/SelectControl';
import IconButton from "@material-ui/core/IconButton";
import { getCardGraveSiteAll, getDeceased } from '../../../services/reviews/reviewsRecords/CardGraveSiteService';
import { getAllGraveyards } from '../../../services/graveyard/GraveyardService';
import { getGraveNotTakenSites, getGraveSiteById, getGraveSites, getGraveSitesWithSelectedGraveyard } from '../../../services/graveSite/GraveSiteService';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField } from '@material-ui/core';
import CardGraveSiteList from './CardGraveSiteList';
import CardGraveSiteHeader from './CardGraveSiteHeader';
import { getMainBookOfBuriedById } from '../../../services/mainBookOfBuried/MainBookOfBuriedService';
import { getFamousCitizenById } from '../../../services/famousCitizen/FamousCitizenService';
import update from 'immutability-helper';


class CardGraveSiteInputForm extends Page {

  constructor(props) {
    super(props);


    this.state = {
      ...this.state,
      data: {
        graveyard: { value: "none" },
      },
      graveSite: {},
      graveyards: [],
      graveSites: [],
      responseData: [],
      deceasedFamousCitizenId: -1,
      deceasedFamousCitizenCode: -1,
      contractExpiryDate: null
    }

    this.keyPress = this.keyPress.bind(this);
  }

  getGraveyards() {
    getAllGraveyards().then(response => {
      let graveyardCodes = [];
      for (let obj of response.data.entities) {
        graveyardCodes.push({
          name: obj.code + " - " + obj.name,
          value: obj.name,
          code: obj.code,
          id: obj.id
        });
      }

      this.setState({ graveyards: graveyardCodes });
    });
  }

  componentDidMount() {
    this.setState({
      companyId: this.props.auth.user.company.id,
    }, () => {
      this.getGraveyards();
    });
  }

  keyPress(e) {
    if (e.key == "Enter") {
      e.preventDefault();
      let data = {
        graveyardCode: this.state.data.graveyard != null ? this.state.data.graveyard.code : "",
        graveSiteId: this.state.data.graveSite != null ? this.state.data.graveSite.id : "",
        companyId: this.state.companyId,
        userId: this.props.auth.user.id,
        page: 0,
        size: 100,
      };
      this.sendGraveyardIdAndGraveSiteId(data);
    }
  }

  keyPressBackspace(e) {
    if (e.target.value.length == 2 && e.keyCode !== 8) {
      e.target.value = e.target.value + '/';
    }

    if (e.target.value.length == 5 && e.keyCode !== 8) {
      e.target.value = e.target.value + '-';
    }

    if (e.keyCode !== 8) {
      return;
    }

    if (e.target.value.charAt(e.target.value.length - 2) == "-" || e.target.value.charAt(e.target.value.length - 2) == "/") {
      e.target.value = e.target.value.substring(0, e.target.value.length - 1);
    }
    else {
      e.target.value = e.target.value.substring(0, e.target.value.length);
    }
  }
  componentWillReceiveProps(nextProps) {
    strings.setLanguage(nextProps.auth.user.language);
    {
      this.state.showTable &&
        getGraveSiteById(this.state.data.graveSite.id).then(response => {
          if (!response.ok) {
            return
          }

          this.setState({
            graveSite: response.data
          });
        });
    }
  }

  /** 
   * Handles autocomplete type input change event
   * When user typed in characters it searches for results to fill dropdown 
   */
  handleTextInputChange = (e) => {
    if (e.target.name === "graveSite") {
      let data = {
        searchParam: e.target.value,
        companyId: this.state.companyId,
        sort: "code,ASC",
        renewal: -1,
        graveyardId: this.state.data.graveyard.id,
        type: "-1",
        userId: 0
      };

      {
        this.state.companyId != undefined &&
          getGraveSitesWithSelectedGraveyard(data).then(response => {
            if (!response.ok) {
              return;
            }
            this.setState({
              graveSites: response.data.entities,
            });
          });
      }
    }
  }

  onAutocompleteChange = (event, values) => {
    let autocompleteName = event.target.id.split("-")[0];
    this.setState({
      data: {
        ...this.state.data, [autocompleteName]: values
      }
    });
  }

  renderText() {
    return (
      <div className='card-grave-site-header'>
        <h1> {strings.review.reviewsRecords.cardGraveSite}</h1>
      </div>
    );
  }

  changeData(event, data = 'data') {
    this.setState({
      [data]: update(this.state[data], { [event.target.name]: { $set: event.target.value } })
    }, () => {
      if (event.target.name == 'graveyard') {
        this.setState({
          graveSites: [],
          data: {
            ...this.state.data,
            graveSite: null,
          }
        });
      }
    });

  }

  renderFilter() {
    return (
      <div className='card-grave-site-select'>
        <SelectControl
          options={this.state.graveyards}
          selected={this.state.data.graveyard}
          onChange={this.changeData}
          label={strings.review.tables.funerals.selectedGraveyard}
          name={"graveyard"}
          nameKey={"name"}
          valueKey={"value"}
          codeKey={"code"}
          placeholder={strings.cardGraveSiteList.select}
        />

        { (this.state.data.graveyard != undefined && this.state.data.graveyard != null && this.state.data.graveyard.value != "none") &&
          <Autocomplete
            id={"graveSite"}
            name={"graveSite"}
            options={this.state.graveSites}
            getOptionLabel={option => option.code}
            onChange={this.onAutocompleteChange}
            renderInput={params => (
              <TextField
                {...params}
                variant="standard"
                label={strings.mainBookOfBuried.form.graveSite}
                margin="normal"
                fullWidth
                name={"graveSite"}
                onChange={this.handleTextInputChange}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
            disableClearable={true}
            disabled={false}
            value={this.state.data.graveSite}
            onKeyDown={this.keyPressBackspace}
            onKeyPress={this.keyPress}
          />
        }

        {(this.state.data.graveyard == undefined || this.state.data.graveyard == null || this.state.data.graveyard.value == "none") &&
          <Autocomplete
            id={"graveSite"}
            name={"graveSite"}
            options={this.state.graveSites}
            getOptionLabel={option => option.code}
            onChange={this.onAutocompleteChange}
            renderInput={params => (
              <TextField
                {...params}
                variant="standard"
                label={strings.mainBookOfBuried.form.graveSite}
                margin="normal"
                fullWidth
                name={"graveSite"}
                onChange={this.handleTextInputChange}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
            disableClearable={true}
            disabled={true}
            value={null}

          />
        }

      </div>
    );
  }

  renderButton() {
    let data = {
      graveyardCode: this.state.data.graveyard != null ? this.state.data.graveyard.code : "",
      graveSiteId: this.state.data.graveSite != null ? this.state.data.graveSite.id : "",
      companyId: this.state.companyId,
      userId: this.props.auth.user.id,
      page: 0,
      size: 100,
    };

    return (
      <div className="select-grave-site-button">
        <IconButton onClick={() => this.sendGraveyardIdAndGraveSiteId(data)} disabled={this.state.data.graveyard.value == 'none'}>
          {strings.review.buttons.find}
        </IconButton>
      </div>

    );
  }

  sendGraveyardIdAndGraveSiteId(data) {
    
    getDeceased(data).then(response => {
      if (!response.ok) {

        return;
      }

      this.setState({
        showTable: true,
        responseData: response.data.content,
        contractExpiryDate: response.data.content[0]?.contractExpiryDate

      }, () => {
        let i;
        let id = -1;
        for (i = 0; i < response.data.content.length; i++) {
          if (response.data.content[i].indicator === "pok") {
            id = response.data.content[i].id;
            break;
          }
        }
        {
          id != -1 &&
            getMainBookOfBuriedById(id).then(response => {
              this.setState({
                mainBookOfBuriedId: id,
                deceasedFamousCitizenId: response.data.famousCitizenId ? response.data.famousCitizenId : -1
              }, () => {
                {
                  this.state.deceasedFamousCitizenId != -1 &&
                    getFamousCitizenById(this.state.deceasedFamousCitizenId).then(response => {
                      if (!response.ok) {
                        return;
                      }
                      this.setState({
                        deceasedFamousCitizenCode: response.data.code
                      });
                    });
                }
             
              });
            });
        }
        this.setState({
          deceasedFamousCitizenCode: ""
        })
      })
    });
  }

  hideTableShowSearch() {
    this.setState({
      showTable: false
    })
  }

  render() {
    return (
      <div>
        {!this.state.showTable &&
          this.renderText()
        }
        {!this.state.showTable &&
          this.renderFilter()
        }
        {!this.state.showTable &&
          this.renderButton()
        }
        {this.state.showTable &&
          <div>
            <CardGraveSiteHeader
              graveyardName={this.state.data.graveyard.name}
              graveSiteCode={this.state.data.graveSite.code}
              contractExpiryDate={this.state.contractExpiryDate}
            />
            <CardGraveSiteList data={this.state.responseData} graveyardCode={this.state.data.graveyard.code} graveSiteId={this.state.data.graveSite.id}/>
            <IconButton onClick={() => this.hideTableShowSearch()}>
              {strings.review.buttons.back}
            </IconButton>
          </div>
        }

      </div>
    );
  }

}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    changeFullScreen: Actions.changeFullScreen
  }, dispatch);
}

function mapStateToProps({ menuReducers, authReducers, siteDataReducers }) {
  return { menu: menuReducers, auth: authReducers, siteData: siteDataReducers };
}

export default withSnackbar(withRouter(connect(mapStateToProps, mapDispatchToProps)(CardGraveSiteInputForm)));
