import { request } from "../../base/HTTP";
import HttpMethod from "../../constants/HttpMethod";
import ResponseTypes from "../../constants/ResponseTypes";

export async function getEditedRenewalFindTableAll(data) {
    return await request('/api/editedRenewalFindTable/all', data);
}

export async function getEditedRenewalFindTableProcessing(data) {
    return await request('/api/editedRenewalFindTable/processing', data);
}

export async function addEditedRenewal(data, cid) {
    return await request('/api/editedRenewal/' + cid, data, HttpMethod.POST);
}

export async function editEditedRenewal(data) {
    return await request("api/editedRenewal/" + data.id, data, HttpMethod.PUT);
}


export async function getEditedRenewals(data) {
    return await request('/api/editedRenewal/search', data);
}

export async function getEditedRenewalsForProcessing(data) {
    return await request('/api/editedRenewal/processing', data);
}

export async function getAllProcessingReports() {
    return await request('/api/editedRenewal/processing/reports');
}

export async function createProcessingReport(data) {
    return await request('/api/editedRenewal/processing/create-report', data, HttpMethod.POST);
}

export function loadProcessingReport(id) {
    return request("api/editedRenewal/processing/load-report/" + id, {}, HttpMethod.GET);
}

export async function generateRenewalProcessingPdf(data) {
    return await request('/api/editedRenewal/processing/generatePdf', data, HttpMethod.POST, { responseType: ResponseTypes.BLOB });
}

export async function generateRenewalProcessingPdfWithoutDiscount(data) {
    return await request('/api/editedRenewal/processing/generatePdfWithoutDiscount', data, HttpMethod.POST, { responseType: ResponseTypes.BLOB });
}

export function getEditedRenewalById(id) {
    return request("api/editedRenewal/" + id, {}, HttpMethod.GET);
}

export async function deleteEditedRenewal(id, uid) {
    return await request('/api/editedRenewal/' + id + '/' + uid, {}, HttpMethod.DELETE);
}

export async function stornoEditedRenewal(id, uid) {
    return await request('/api/editedRenewal/cancel/' + id + '/' + uid, {}, HttpMethod.PUT);
}

export async function undoStornoEditedRenewal(id, uid) {
    return await request('/api/editedRenewal/undoCancel/' + id + '/' + uid, {}, HttpMethod.PUT);
}

export async function getDeletedRenewal(data){
    return await request('/api/editedRenewal/deleted', data);
}

export async function restoreRenewal(data){
    return await request('/api/editedRenewal/restore/'+data.id, data, HttpMethod.PUT)
}