import strings from '../../localization';
import React from 'react';
import SelectControl from '../../components/controls/SelectControl';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField } from '@material-ui/core';
import { getError, hasError } from "../../functions/Validation";
import IconButton from "@material-ui/core/IconButton";

const RenewalEditedHeader = ({
    selectRenewalType,
    selectRenewalTypeKind,
    onChange,
    years,
    selectedYear,
    selectedRenewalType,
    selectedRenewalTypeKind,
    graveyards,
    graveSites,
    selectedGraveyard,
    autocompleteChange,
    handleTextInputChange,
    onKeyDown,
    renewalType,
    find,
    data,
    submitter,
    deceased,
    obj,
}) => {

    return (
        <form>
            <div style={{ width: 200 }}>
                <SelectControl
                    options={selectRenewalType}
                    selected={selectedRenewalType}
                    onChange={onChange}
                    label={strings.renewalType}
                    name={"selectedRenewalType"}
                    nameKey={"name"}
                    valueKey={"id"}
                />
            </div>

            {renewalType.id == 3 &&


                <React.Fragment>
                    <div style={{ width: 200, marginLeft: 225, marginTop: -68 }}>
                        <SelectControl
                            options={years}
                            selected={selectedYear}
                            onChange={onChange}
                            label={strings.renewalList.year}
                            name={"selectedYear"}
                            nameKey={"name"}
                            valueKey={"value"}
                        />
                    </div>

                    <div style={{ width: 200, marginLeft: 450, marginTop: -68 }}>
                        <SelectControl
                            options={selectRenewalTypeKind}
                            selected={selectedRenewalTypeKind}
                            onChange={onChange}
                            label={strings.renewalList.renewalType}
                            name={"selectedRenewalTypeKind"}
                            nameKey={"name"}
                            valueKey={"id"}
                        />
                    </div>

                    <div style={{ width: 200, marginLeft: 675, marginTop: -68 }}>
                        <SelectControl
                            options={graveyards}
                            selected={selectedGraveyard}
                            onChange={onChange}
                            label={strings.renewalList.graveyard}
                            name={"graveyard"}
                            nameKey={"name"}
                            valueKey={"id"}
                        />
                    </div>

                    <div style={{ width: 200, marginLeft: 900, marginTop: -68 }} >
                        {(selectedGraveyard != undefined && selectedGraveyard != null && selectedGraveyard.id != -1) &&
                            <Autocomplete
                                id={"graveSite"}
                                name={"graveSite"}
                                options={graveSites}
                                getOptionLabel={option => option.code}
                                onChange={autocompleteChange}
                                renderInput={params => (
                                    <TextField
                                        {...params}
                                        variant="standard"
                                        label={strings.mainBookOfBuried.form.graveSite}
                                        margin="normal"
                                        fullWidth
                                        name={"graveSite"}
                                        onChange={handleTextInputChange}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                )}
                                disableClearable={true}
                                disabled={false}
                                //value={selectedGraveSite}
                                onKeyDown={onKeyDown}
                            //onKeyPress={this.keyPress}
                            />
                        }

                        {(selectedGraveyard == undefined || selectedGraveyard == null || selectedGraveyard.id == -1) &&
                            <Autocomplete
                                id={"graveSite"}
                                name={"graveSite"}
                                options={graveSites}
                                getOptionLabel={option => option.code}
                                onChange={autocompleteChange}
                                renderInput={params => (
                                    <TextField
                                        {...params}
                                        variant="standard"
                                        label={strings.mainBookOfBuried.form.graveSite}
                                        margin="normal"
                                        fullWidth
                                        name={"graveSite"}
                                        onChange={handleTextInputChange}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                )}
                                disableClearable={true}
                                disabled={true}
                                value={null}

                            />
                        }
                    </div>
                </React.Fragment>

            }

            {renewalType.id == 2 &&

                <React.Fragment>
                    <div style={{ width: 200, marginLeft: 225, marginTop: -68 }}>
                        <SelectControl
                            options={years}
                            selected={selectedYear}
                            onChange={onChange}
                            label={strings.renewalList.year}
                            name={"selectedYear"}
                            nameKey={"name"}
                            valueKey={"value"}
                        />
                    </div>

                    <div style={{ width: 200, marginLeft: 450, marginTop: -68 }}>
                        <TextField
                            label={strings.mainBookOfBuried.form.deceasedNameSurname}
                            //error={hasError(errors, 'deceasedNameSurname')}
                            //helperText={getError(errors, 'deceasedNameSurname')}
                            name='deceasedNameSurname'
                            onChange={handleTextInputChange}
                            margin="normal"
                            defaultValue={deceased == undefined ? "" : obj.deceasedNameSurname}
                            value={deceased == undefined ? "" : obj.deceasedNameSurname}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        //disabled={isDisabled}
                        />


                    </div>

                </React.Fragment>
            }

            {renewalType.id == 1 &&

                <React.Fragment>
                    <div style={{ width: 200, marginLeft: 225, marginTop: -68 }}>
                        <SelectControl
                            options={years}
                            selected={selectedYear}
                            onChange={onChange}
                            label={strings.renewalList.year}
                            name={"selectedYear"}
                            nameKey={"name"}
                            valueKey={"value"}
                        />
                    </div>

                    <div style={{ width: 200, marginLeft: 450, marginTop: -68 }}>
                        <SelectControl
                            options={selectRenewalTypeKind}
                            selected={selectedRenewalTypeKind}
                            onChange={onChange}
                            label={strings.renewalList.renewalType}
                            name={"selectedRenewalTypeKind"}
                            nameKey={"name"}
                            valueKey={"id"}
                        />
                    </div>

                    <div style={{ width: 200, marginLeft: 675, marginTop: -68 }}>
                        <TextField
                            label={strings.renewal.form.submitterName}
                            //error={hasError(errors, 'nameSurnameSubmitter')}
                            //helperText={getError(errors, 'nameSurnameSubmitter')}
                            name='nameSurnameSubmitter'
                            onChange={handleTextInputChange}
                            margin="normal"
                            defaultValue={submitter == undefined ? "" : obj.nameSurnameSubmitter}
                            value={submitter == undefined ? "" : obj.nameSurnameSubmitter}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        //disabled={isDisabled}
                        />
                    </div>



                </React.Fragment>
            }

            {renewalType.id == 3 &&
                <div className="select-grave-site-button">
                    <IconButton
                        onClick={find}
                        disabled={
                            (renewalType.id == undefined ||
                                renewalType.id == 0 ||
                                selectedYear.value == -1 ||
                                selectedRenewalTypeKind == undefined ||
                                selectedGraveyard.id == -1 ||
                                !data.graveSite) ? true : false}
                    >
                        {strings.review.buttons.find}
                    </IconButton>
                </div>
            }

            {renewalType.id == 2 &&
                <div className="select-grave-site-button">
                    <IconButton
                        onClick={find}
                        disabled={
                            (renewalType.id == undefined ||
                                renewalType.id == 0 ||
                                selectedYear.value == -1

                            ) ? true : false}
                    >
                        {strings.review.buttons.find}
                    </IconButton>
                </div>
            }

            {renewalType.id == 1 &&
                <div className="select-grave-site-button">
                    <IconButton
                        onClick={find}
                        disabled={
                            (renewalType.id == undefined ||
                                renewalType.id == 0 ||
                                selectedYear.value == -1 ||
                                selectedRenewalTypeKind == undefined

                            ) ? true : false}
                    >
                        {strings.review.buttons.find}
                    </IconButton>
                </div>
            }




        </form>


    )

};

export default RenewalEditedHeader;