import {request} from "../../base/HTTP";
import HttpMethod from "../../constants/HttpMethod";

export async function getExhumations(data) {
    return await request('/api/exhumation/search', data);
}

export async function addExhumation(data, cid) {
    return await request('/api/exhumation/' + cid, data, HttpMethod.POST);
}

export function getExhumationById(id) {
    return  request("api/exhumation/" + id , {}, HttpMethod.GET);
}

export async function editExhumation(data) {
    return await request("api/exhumation/" + data.id, data, HttpMethod.PUT);
}

export async function deleteExhumation(id, uid) {
    return await request('/api/exhumation/' + id + '/' + uid, {} , HttpMethod.DELETE);
}

export async function getDeletedExhumations(data){
    return await request('/api/exhumation/deleted', data)
}

export async function restoreExhumation(data) {
    return await request("api/exhumation/restore/" + data.id, data, HttpMethod.PUT);
}