import FormComponent from "../../common/FormComponent";
import React from "react";
import { bindActionCreators } from "redux";
import * as Actions from "../../actions/Actions";
import { withRouter } from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import Grid from "@material-ui/core/Grid";
import { Paper } from "@material-ui/core";
import strings from "../../localization";
import { withSnackbar } from "notistack";
import { getMainBookOfBuriedById, getAutocompleteObjectsById } from '../../services/mainBookOfBuried/MainBookOfBuriedService';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import DeathReportMainBookOfBuriedForm from '../../components/forms/mainBookOfBuried/DeathReportMainBookOfBuriedForm';
import DeceasedMainBookOfBuriedForm from '../../components/forms/mainBookOfBuried/DeceasedMainBookOfBuriedForm';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import { getAllTransporters } from "../../services/transporter/TransporterService";
import FuneralMainBookOfBuriedForm from "../../components/forms/mainBookOfBuried/FuneralMainBookOfBuriedForm";
import { validatePersonalNumber, getDateFromIdentificationNumber } from "../../functions/Validation";
import { dateToString, dateToString2, stringToDate, stringToDate2, stringToDate3 } from "../../util/DateUtil";
import { getPostalCodeImprovedById } from "../../services/postalCodeImproved/PostalCodeImprovedService"
import moment from "moment"

function TabContainer(props) {
  return (
    <Typography className="tabContainer" component="div">
      {props.children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

class ViewMainBookOfBuried extends FormComponent {

  constructor(props) {
    super(props);

    this.state = {
      data: props.data ? props.data : {},
      errors: {},
      value: 0,
      dateFlag: "",
      dateOfBirthWhenValidIDNumber: [],
    };

    this.props.changeFullScreen(false);
  }

  componentDidMount() {

    
    getMainBookOfBuriedById(this.props.id).then(response => {
      
      getPostalCodeImprovedById(response.data.municipalityOfBirthId ? response.data.municipalityOfBirthId : "20999").then(response =>{
        
        this.setState({
          data:{
            ...this.state.data,
           stateOfBirth: response.data.stateName
          }
        })
      })
      this.setState({
        data: response.data,
      }, () => {
        this.setState({
          data: {
            ...this.state.data,
            dateTimeFuneralString: response.data.dateTimeFuneralString,
            dateTimeFuneral: (response.data.dateTimeFuneralString && response.data.dateTimeFuneral == null) ? (stringToDate2(response.data.dateTimeFuneralString)._d == 'Invalid Date' ? (stringToDate(response.data.dateTimeFuneralString)._d == 'Invalid Date' ? null : stringToDate(response.data.dateTimeFuneralString)._d) : stringToDate2(response.data.dateTimeFuneralString)._d) : response.data.dateTimeFuneral,
            dateTimeOfDeathString: response.data.dateTimeOfDeathString,
            dateTimeOfDeath: (response.data.dateTimeOfDeathString && response.data.dateTimeOfDeath == null) ? (stringToDate2(response.data.dateTimeOfDeathString)._d == 'Invalid Date' ? (stringToDate(response.data.dateTimeOfDeathString)._d == 'Invalid Date' ? null : stringToDate(response.data.dateTimeOfDeathString)._d) : stringToDate2(response.data.dateTimeOfDeathString)._d) : response.data.dateTimeOfDeath,
            takeoverDateTimeString: response.data.takeoverDateTimeString,
            takeoverDateTime: (response.data.takeoverDateTimeString && response.data.takeoverDateTime == null) ? (stringToDate2(response.data.takeoverDateTimeString)._d == 'Invalid Date' ? (stringToDate(response.data.takeoverDateTimeString)._d == 'Invalid Date' ? null : stringToDate(response.data.takeoverDateTimeString)._d) : stringToDate2(response.data.takeoverDateTimeString)._d) : response.data.takeoverDateTime,
            dateTimeFuneral2String: response.data.dateTimeFuneral2String,
            dateTimeFuneral2: (response.data.dateTimeFuneral2String && response.data.dateTimeFuneral2 == null) ? (stringToDate2(response.data.dateTimeFuneral2String)._d == 'Invalid Date' ? (stringToDate(response.data.dateTimeFuneral2String)._d == 'Invalid Date' ? null : stringToDate(response.data.dateTimeFuneral2String)._d) : stringToDate2(response.data.dateTimeFuneral2String)._d) : response.data.dateTimeFuneral2,
            dateTimeFuneral3String: response.data.dateTimeFuneral3String,
            dateTimeFuneral3: (response.data.dateTimeFuneral3String && response.data.dateTimeFuneral3 == null) ? (stringToDate2(response.data.dateTimeFuneral3String)._d == 'Invalid Date' ? (stringToDate(response.data.dateTimeFuneral3String)._d == 'Invalid Date' ? null : stringToDate(response.data.dateTimeFuneral3String)._d) : stringToDate2(response.data.dateTimeFuneral3String)._d) : response.data.dateTimeFuneral3,
            dateTimeFuneral4String: response.data.dateTimeFuneral4String,
            dateTimeFuneral4: (response.data.dateTimeFuneral4String && response.data.dateTimeFuneral4 == null) ? (stringToDate2(response.data.dateTimeFuneral4String)._d == 'Invalid Date' ? (stringToDate(response.data.dateTimeFuneral4String)._d == 'Invalid Date' ? null : stringToDate(response.data.dateTimeFuneral4String)._d) : stringToDate2(response.data.dateTimeFuneral4String)._d) : response.data.dateTimeFuneral4,
            expiryDate: (stringToDate(response.data.expiryDateString) > moment(response.data.expiryDate, 'YYYY-MM-DD')) ? stringToDate(response.data.expiryDateString) : response.data.expiryDate,
          }
        });
        this.checkIfValidatePersonalNumber(this.state.data.identificationNumber);
        getAutocompleteObjectsById(this.state.data).then(response => {
          this.setState({
            data: {
              ...this.state.data,
              transporter: response.data.transporter,
              causeOfDeath: response.data.causeOfDeath,
              submitter: response.data.submitter,
              cost: response.data.cost,
              userGraveSite: response.data.userGraveSite,
              firmCost: response.data.firmCost,
              firmPays: this.state.data.firmPays,
              stoneCutter: response.data.stoneCutter,
              municipality: response.data.municipality,
              state: response.data.state,
              occupation: response.data.occupation,
              municipalityOfBirth: response.data.municipalityOfBirth,
              doctor: response.data.doctor,
              famousCitizen: response.data.famousCitizen,
              graveyard: response.data.graveyard,
              graveSite: response.data.graveSite,
              graveyard2: response.data.graveyard2,
              graveSite2: response.data.graveSite2,
              graveyard3: response.data.graveyard3,
              graveSite3: response.data.graveSite3,
              graveyard4: response.data.graveyard4,
              graveSite4: response.data.graveSite4,
            },
            submitter: {
              nameSurnameSubmitter: response.data.submitter ? response.data.submitter.name + " " + response.data.submitter.surname : null,
              addressSubmitter: response.data.submitter ? response.data.submitter.street + " " + response.data.submitter.streetNumber : "",
              municipalitySubmitter: response.data.submitter ? response.data.submitter.postalCodeImprovedZip : "",
              telephoneSubmitter: response.data.submitter ? response.data.submitter.telephone : "",
            },
            costInfo: {
              nameSurnameCosts: response.data.cost ? response.data.cost.name + " " + response.data.cost.surname : "",
              addressCosts: response.data.cost ? response.data.cost.street + " " + response.data.cost.streetNumber : "",
              municipalityCosts: response.data.cost ? response.data.cost.postalCodeImprovedZip : "",
              telephoneCosts: response.data.cost ? response.data.cost.telephone : "",
            },
            userInfo: {
              nameSurnameUser: response.data.userGraveSite ? response.data.userGraveSite.name + " " + response.data.userGraveSite.surname : "",
              addressUser: response.data.userGraveSite ? response.data.userGraveSite.street + " " + response.data.userGraveSite.streetNumber : "",
              municipalityUser: response.data.userGraveSite ? response.data.userGraveSite.postalCodeImprovedZip : "",
              telephoneUser: response.data.userGraveSite ? response.data.userGraveSite.telephone : "",
            },
            firmInfo: {
              taxNumberCost: response.data.firmCost ? response.data.firmCost.taxNumber : "",
            },
          })
        });
      });
    });

    getAllTransporters().then(response => {
      this.setState({
        transporters: response.data.entities,
      });
    });
  }

  checkIfValidatePersonalNumber(identificationNumber) {
    let dateOfBirth = "";
    let dateOfBirthArray = [];

    if (validatePersonalNumber(identificationNumber)) {
      dateOfBirth = getDateFromIdentificationNumber(identificationNumber);
      dateOfBirthArray = dateOfBirth.split('/');
      this.setState({
        dateFlag: "validIDNumber",
        dateOfBirthWhenValidIDNumber: dateOfBirthArray,
      });
    }
    else {
      this.setState({
        dateFlag: "invalidIDNumber"
      });
    }
  }

  handleChangeTab = (event, value) => {
    this.setState({
      value: value
    });
  };

  render() {
  
    return (
      <Grid id="page" item md={12}>
        <div className="header">
          <h1>{strings.mainBookOfBuried.viewPageTitle}</h1>
        </div>

        <div className="tabsDiv">
          <AppBar position="static">
            <Tabs value={this.state.value} className="tabs" onChange={this.handleChangeTab}>
              <Tab label={strings.mainBookOfBuried.deceased} className="threeTabs" />
              <Tab label={strings.mainBookOfBuried.deathReport} className="threeTabs" />
              <Tab label={strings.mainBookOfBuried.funeral} className="threeTabs" />
            </Tabs>
          </AppBar>
          {this.state.value === 0 && <TabContainer>

            <DeceasedMainBookOfBuriedForm
              onChange={this.changeData}
              onSubmit={this.submit}
              data={this.state.data}
              errors={this.state.errors}
              onCancel={this.props.onCancel}
              isDisabled={this.props.isDisabled}
              handleChange={this.handleChange}
              onAutocompleteChange={this.onAutocompleteChange}
              handleTextInputChange={this.handleTextInputChange}
              transporters={this.state.transporters}
              municipalities={this.state.settlements == undefined ? [] : this.state.settlements}
              states={this.state.states == undefined ? [] : this.state.states}
              stateOfBirth = {this.state.stateOfBirth == undefined ? [] : this.state.stateOfBirth}
              occupations={this.state.occupations == undefined ? [] : this.state.occupations}
              causesOfDeath={this.state.causesOfDeath == undefined ? [] : this.state.causesOfDeath}
              doctors={this.state.doctors == undefined ? [] : this.state.doctors}
              famousCitizens={this.state.famousCitizens == undefined ? [] : this.state.famousCitizens}
              flag={this.props.flag}
              dateFlag={this.state.dateFlag}
              dateOfBirthWhenValidIDNumber={this.state.dateOfBirthWhenValidIDNumber}
            />

          </TabContainer>}
          {this.state.value === 1 && <TabContainer>

            <DeathReportMainBookOfBuriedForm
              onChange={this.changeData}
              onSubmit={this.submit}
              data={this.state.data}
              errors={this.state.errors}
              onCancel={this.props.onCancel}
              isDisabled={this.props.isDisabled}
              handleChange={this.handleChange}
              onAutocompleteChange={this.onAutocompleteChange}
              handleTextInputChange={this.handleTextInputChange}
              submitters={this.state.submitters == undefined ? [] : this.state.submitters}
              submitter={this.state.submitter}
              identificationSubmitterEqualsIdentificationCosts={this.identificationSubmitterEqualsIdentificationCosts}
              costs={this.state.costs == undefined ? [] : this.state.costs}
              cost={this.state.cost}
              identificationCostsEqualsIdentificationUser={this.identificationCostsEqualsIdentificationUser}
              users={this.state.users == undefined ? [] : this.state.users}
              userGraveSite={this.state.userGraveSite}
              identificationSubmitterEqualsIdentificationUser={this.identificationSubmitterEqualsIdentificationUser}
              costSameAsSubmitter={this.state.costSameAsSubmitter}
              userSameAsCost={this.state.userSameAsCost}
              userSameAsSubmitter={this.state.userSameAsSubmitter}
              costInfo={this.state.costInfo}
              userInfo={this.state.userInfo}
              flag={this.props.flag}
              firms={this.state.firms == undefined ? [] : this.state.firms}
              firmCost={this.state.firmCost}
              firmInfo={this.state.firmInfo}
            />

          </TabContainer>}
          {this.state.value === 2 && <TabContainer>

            <FuneralMainBookOfBuriedForm
              onChange={this.changeData}
              onSubmit={this.submit}
              data={this.state.data}
              errors={this.state.errors}
              isDisabled={this.props.isDisabled}
              onCancel={this.props.onCancel}
              handleChange={this.handleChange}
              onAutocompleteChange={this.onAutocompleteChange}
              handleTextInputChange={this.handleTextInputChange}
              graveyards={this.state.graveyards == undefined ? [] : this.state.graveyards}
              graveSites={this.state.graveSites == undefined ? [] : this.state.graveSites}
              stoneCutters={this.state.stoneCutters == undefined ? [] : this.state.stoneCutters}
              flag={this.props.flag}
            />


          </TabContainer>}
        </div>

      </Grid>
    );
  }
}

function mapDispatchToProps(dispatch) {

  return bindActionCreators(
    {
      changeFullScreen: Actions.changeFullScreen
    },
    dispatch
  );
}

function mapStateToProps({ menuReducers, siteDataReducers }) {

  return { menu: menuReducers, siteData: siteDataReducers };
}

export default withSnackbar(
  withRouter(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(ViewMainBookOfBuried)
  )
);
