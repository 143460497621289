import FormComponent from "../../common/FormComponent";
import React from "react";
import { Grid, Paper } from "@material-ui/core";
import { withSnackbar } from "notistack";
import { bindActionCreators } from "redux";
import * as Actions from "../../actions/Actions";
import { withRouter } from "react-router-dom";
import strings from "../../localization";
import connect from "react-redux/es/connect/connect";
import UserRoleForm from "../../components/forms/userRole/UserRoleForm";
import { editUserRole, getUserRoleById, getUserRoles } from "../../services/userRoles/UserRoleService";
import { getRoleByName, getRoles } from '../../services/roles/RolesService';
import { getUserBySearchParam } from '../../services/user/UserService';

class EditUserRole extends FormComponent{

    validationList = {
        
    };
    constructor(props){
        super(props);

        this.state= {
            data: props.data ? props.data : {},
            errors:{}
        }
        this.props.changeFullScreen(false);
        this.submit = this.submit.bind(this);
    }

    componentDidMount(){
    
        getUserRoleById(this.props.id).then(response => {
            
            this.setState({
                data: response.data,
            });
        });
    }

    transformRequest(data) {
        return {
            ...data,
            userEmail: this.props.uemail,
            companyId: this.props.cid,
            userId: this.state.data.user ? this.state.data.user.id : this.state.data.userId,
            roleId: this.state.data.role ? this.state.data.role.id : this.state.data.roleId
        }
    }


    onAutocompleteChange = (event, values) => {
        let autocompleteName = event.target.id.split("-")[0];
        this.setState({
            data: {
                ...this.state.data, [autocompleteName]: values
            }
        });
    }

    handleTextInputChange = (e) =>{
        
        if(e.target.name === "role" && e.target.value.trim().length >= 2){
           
            let data = {
                searchParam: e.target.value,
                companyId: this.props.cid,
                sort: "name,ASC",
                userId: 0,
            };
            {
                
                this.props.cid != undefined &&
                getRoles(data).then(response => {
                    
                    if (!response.ok) {

                        return;
                    }

                    this.setState({
                        roles: response.data.entities,
                    });
                });
            }
        }
        if(e.target.name === "user" && e.target.value.trim().length >= 2){
            let data = {
                searchParam: e.target.value,
                companyId: this.props.cid,
                sort: "name,ASC",
                userId: 0,
            };
            {
                this.props.cid != undefined &&
                getUserBySearchParam(data).then(response => {
                    
                    if (!response.ok) {

                        return;
                    }

                    this.setState({
                        users: response.data.entities,
                    });
                });
            }
        }
    }

    submit() {
        
        // if(!this.validate){

        //     return;
        // }
        this.showDrawerLoader();
        
        editUserRole(this.transformRequest(this.state.data)).then(response => {
            if(!response.ok){
                let messageKey = response.response.data.message;

                this.props.enqueueSnackbar(strings.urn.messages[messageKey], {
                    variant: "error"
                });

                this.handleError(messageKey);

                return;
            }
            this.props.enqueueSnackbar(strings.userRole.userRoleEdited, { variant: 'success' });
            this.props.onFinish(response.data.state);
            
            this.hideDrawerLoader();
        })
    }
    

    render(){
        return(
            <Grid id="page" item md={12}>
                 <div className="header">
                    <h1>{'Роле корисника - Измени'}</h1>
                </div>
                <Paper className="paper">
                    <UserRoleForm
                        onAutocompleteChange={this.onAutocompleteChange}
                        onChange={this.onChange}
                        onSubmit={this.submit}
                        handleTextInputChange={this.handleTextInputChange}
                        data={this.state.data}
                        errors={this.state.errors}
                        onCancel={this.props.onCancel}
                        flag={"edit"}
                        roles={this.state.roles == undefined ? [] : this.state.roles}
                        users={this.state.users == undefined ? [] : this.state.users} 
                    />
                </Paper>
            </Grid>
        );
    }

}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        changeFullScreen: Actions.changeFullScreen
    }, dispatch);
}

function mapStateToProps({ menuReducers, siteDataReducers }) {
    return { menu: menuReducers, siteData: siteDataReducers };
}

export default withSnackbar(withRouter(connect(mapStateToProps,mapDispatchToProps)(EditUserRole)));