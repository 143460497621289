import React from 'react';
import strings from '../../../localization';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import {getError, hasError} from "../../../functions/Validation";
import { withRouter, Link } from "react-router-dom";

const LoginForm = ({
    onSubmit,
    onChange,
    errors,
    data,
    keyPress,
    showPassword,
    showPasswordButton
}) => (
    <form id="login-form" onSubmit={ onSubmit } action = "#">
        <TextField
            label={ strings.login.email }
            error={ hasError(errors, 'email') }
            helperText={ getError(errors, 'email') }
            fullWidth
            autoFocus
            name='email'
            onChange={ onChange }
            onKeyPress={ keyPress }
            margin="normal"
            defaultValue={ data.email }
        />
        <div>
        <TextField
            label={ strings.login.password }
            error={ hasError(errors, 'password') }
            helperText={ getError(errors, 'password') }
            fullWidth
            name='password'
            type={showPassword ? 'text' : 'password'}
            onChange={ onChange }
            onKeyPress={ keyPress }
            margin="normal"
            defaultValue={ data.password }
            InputProps={{endAdornment: <Button variant="contained" color="transparent" onClick={ showPasswordButton }>{strings.login.showPassword}</Button>}}
           
        />
        
        </div>
        <div className='submit-container'>
            <Button variant="contained" color="primary" onClick={ onSubmit }>
                { strings.login.login }
            </Button>
            <Link to="/resetPasswordRequest">
                <Button>{strings.login.resetPassword}</Button>
            </Link>
        </div>
    </form>
);

export default LoginForm;