import {request} from "../../base/HTTP";
import HttpMethod from "../../constants/HttpMethod";

export async function getSettlements(data) {
    return await request('/api/settlement/search', data);
}

export async function addSettlement(data, cid) {
    return await request('/api/settlement/' + cid, data, HttpMethod.POST);
}

export function getSettlementById(id) {
    return  request("api/settlement/" + id , {}, HttpMethod.GET);
}

export async function editSettlement(data) {
    return await request("api/settlement/" + data.id, data, HttpMethod.PUT);
}

export async function deleteSettlement(id, uid) {
    return await request('/api/settlement/' + id + '/' + uid, {} , HttpMethod.DELETE);
}