import React from 'react'
import { bindActionCreators } from "redux";
import * as Actions from "../../actions/Actions";
import { withRouter } from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import Grid from '@material-ui/core/Grid';
import { Paper } from "@material-ui/core";
import strings from "../../localization";
import Validators from "../../constants/ValidatorTypes";
import FormComponent from "../../common/FormComponent";
import { withSnackbar } from "notistack";
import { addGraveSite } from '../../services/graveSite/GraveSiteService';
import GraveSiteForm from '../../components/forms/graveSite/GraveSiteForm';
import { getAllGraveyards } from '../../services/graveyard/GraveyardService';
import Loader from "../../components/Loader";


class AddGraveSite extends FormComponent {

  validationList = {
    belongingGraveyard: [{ type: Validators.REQUIRED }],
    placeNumberFrom: [{ type: Validators.REQUIRED }, { type: Validators.IS_NUMBER }]
  };

  constructor(props) {
    super(props);

    this.state = {
      data: props.data ? props.data : {},
      data: {
        ...this.state.data,
        renewal: "false",
        addMoreThanOne: "false",
      },
      errors: {},
      showLoader: false
    };

    this.props.changeFullScreen(false);

    this.submit = this.submit.bind(this);

  }

  componentDidMount() {

    getAllGraveyards().then(response => {
      this.setState({
        graveyards: response.data.entities,
      });
    });
  }

  submit() {

    let placeNumberFrom = this.state.data.placeNumberFrom;
    let placeNumberTo = this.state.data.addMoreThanOne == "true" ? this.state.data.placeNumberTo : null;

    if (!this.validate()) {
      this.props.enqueueSnackbar(strings.error.requiredFields, { variant: 'error' });
      return;
    }

    if (!this.placeNumberFromToValidation(placeNumberFrom, placeNumberTo)) {
      return;
    }

    if (this.state.data.addMoreThanOne == "true") {
      this.showDrawerLoader();
    }

    addGraveSite(this.transformRequest(this.state.data), this.props.cid).then(response => {
      if (!response.ok) {
        this.hideDrawerLoader();
        let messageKey = response.response.data.message;

        this.props.enqueueSnackbar(strings.graveSite.messages[messageKey], {
          variant: "error"
        });

        this.handleError(messageKey);

        return;
      }

      this.props.enqueueSnackbar(strings.addCompany.graveSiteAdded, { variant: 'success' });
      this.props.onFinish(response.data.graveSite);

      this.hideDrawerLoader();
    });
  }

  transformRequest(data) {
    let graveField = this.state.data.graveField ? this.state.data.graveField : "";
    let gravePlot = this.state.data.gravePlot ? this.state.data.gravePlot : "";
    let placeNumberFrom = this.state.data.placeNumberFrom ? this.state.data.placeNumberFrom : "";
    let labelGraveSite = this.state.data.labelGraveSite ? this.state.data.labelGraveSite : "";
    let typeGraveSite = this.state.data.typeGraveSite ? this.state.data.typeGraveSite : "";

    return {
      ...data,
      userEmail: this.props.uemail,
      code: graveField + "/" + gravePlot + "-" + placeNumberFrom
        + labelGraveSite +" "+ typeGraveSite,
      placeNumberTo: this.state.data.addMoreThanOne == "true" ? this.state.data.placeNumberTo : null,
      belongingGraveyardId: this.state.data.belongingGraveyard.id,
      graveSiteNumber: data.graveSiteNumber ? data.graveSiteNumber : (data.size ? data.size : null)
    }
  }

  placeNumberFromToValidation(placeNumberFrom, placeNumberTo) {
    if (placeNumberFrom.length != 3) {
      this.setError("placeNumberFrom", strings.graveSite.errorMinLength);
      return false;
    }

    if (placeNumberTo) {
      if (placeNumberTo.length != 3) {
        this.setError("placeNumberTo", strings.graveSite.errorMinLength);
        return false;
      }

      if (!isNaN(parseInt(placeNumberTo))) {
        let integerNumberFrom = parseInt(placeNumberFrom)
        let integerNumberTo = parseInt(placeNumberTo)

        if (integerNumberFrom >= integerNumberTo) {
          this.setError("placeNumberFrom", strings.graveSite.errorToLessThanFrom);
          return false;
        }
      }
      else {
        this.setError("placeNumberTo", strings.graveSite.errorInvalidFormat);
        return false;
      }
    }

    return true;
  }

  /* Handlers */

  handleError(message) {
    switch (message) {
      case "GRAVE_SITE_GRAVEYARD_CANT_BE_NULL":
        this.setError("belongingGraveyard", strings.graveSite.messages.GRAVE_SITE_GRAVEYARD_CANT_BE_NULL);
        break;
      case "GRAVE_SITE_WITH_GRAVEYARD_NAME_AND_GRAVE_SITE_ALREADY_EXIST":
        this.setError("belongingGraveyard", strings.graveSite.messages.GRAVE_SITE_WITH_GRAVEYARD_NAME_AND_GRAVE_SITE_ALREADY_EXIST);
        break;
    }
  }

  handleChange = (event) => {
    this.setState({
      data: {
        ...this.state.data, [event.target.name]: event.target.value
      }
    })
  };
  onAutocompleteChange = (event, values) => {
    let autocompleteName = event.target.id.split("-")[0];
    this.setState({
      data: {
        ...this.state.data, [autocompleteName]: values
      }
    });
  }

  render() {
    return (
      <Grid id='page' item md={12}>

        <div className='header'>
          <h1>{strings.graveSite.pageTitle}</h1>
        </div>

        {
          this.state.showLoader &&
          <Loader />
        }

        <Paper className='paper'>
          <GraveSiteForm
            onChange={this.changeData}
            onSubmit={this.submit}
            data={this.state.data}
            errors={this.state.errors}
            onCancel={this.props.onCancel}
            handleChange={this.handleChange}
            belongingGraveyards={this.state.graveyards == undefined ? [] : this.state.graveyards}
            onAutocompleteChange={this.onAutocompleteChange}
            flag={this.props.flag}
          />
        </Paper>

      </Grid>

    );
  }
}


function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    changeFullScreen: Actions.changeFullScreen
  }, dispatch);
}

function mapStateToProps({ menuReducers, siteDataReducers }) {
  return { menu: menuReducers, siteData: siteDataReducers };
}

export default withSnackbar(withRouter(connect(mapStateToProps, mapDispatchToProps)(AddGraveSite)));