import {request} from "../../base/HTTP";
import HttpMethod from "../../constants/HttpMethod";

export async function getAppointments(data) {
    return await request('/api/appointments/all', data);
}

export async function getAppointmentsByDate(data) {
    return await request('/api/appointments/by-date', data);
}

export async function addAppointment(data, cid) {
    return await request('/api/appointments/' + cid, data, HttpMethod.POST);
}

export function getAppointmentById(id) {
    return request("api/appointments/" + id , {}, HttpMethod.GET);
}

export async function editAppointment(data) {
    return await request("api/appointments/" + data.id, data, HttpMethod.PUT);
}

export async function deleteAppointment(id, uid) {
    return await request('/api/appointments/' + id + '/' + uid, {} , HttpMethod.DELETE);
}

export function bookAppointmentById(data, uid) {
    return request('api/appointments/book-appointment/' + data.id + '/' + uid, {}, HttpMethod.PUT);
}

export function cancelAppointmentById(data, uid) {
    return request('api/appointments/cancel-appointment/' + data.id + '/' + uid, {}, HttpMethod.PUT);
}

export async function getAppointmentsByDateAndNotTaken(data) {
    return await request('/api/appointments/by-date-and-not-taken', data);
}

export async function getAppointmentTimesByFuneralDetails(data) {
    return await request('/api/appointments/appointment-times', data);
}