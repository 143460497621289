import { validate, isFormValid } from '../functions/Validation';
import BaseComponent from "../common/BaseComponent";
import update from 'immutability-helper';

class FormComponent extends BaseComponent {

    validationList = {};

    constructor(props) {
        super(props);

        this.state = {
            data: {},
            errors: {},
            showLoader: false
        };

        this.changeData = this.changeData.bind(this);
        this.changeCheckBox = this.changeCheckBox.bind(this);
        this.validate = this.validate.bind(this);
        this.unsetError = this.unsetError.bind(this);
    }

    changeData(event, data = 'data') {
        const name = event.target && event.target.name;
        const value = event.target? event.target.value : null;

        if (!name) {
            return;
        }

        this.setState(prevState => ({
            [data]: update(prevState[data], { [name]: { $set: value } }),
            errors: {
                ...prevState.errors,
                [name]: null,
            }
        }));
    }

    changeCheckBox(event) {

        const field = event.target.name;
        const data = this.state.data;
        data[field] = !data[field];

        this.setState({
            data
        });
    }

    isNumeric(n) {
        return !isNaN(parseFloat(n)) && isFinite(n);
    }

    isFloat(n) {
        return !isNaN(parseFloat(n));
    }

    isInt(n) {
        return isFinite(n)
    }

    isLength(n, length) {
        return n.length == length;
    }

    validate() {
        let errors = validate(this.state.data, this.validationList);

        this.setState({ errors });

        return isFormValid(errors);
    }

    setError(key, value) {
        this.setState({
            errors: update(this.state.errors, { [key]: { $set: [{ message: value }] } })
        });
    }

    unsetError(key) {
        let errors = this.state.errors;
        delete errors[key];
        this.setState({ errors: errors })
    }

    showDrawerLoader() {
        this.setState({
            showLoader: true
        });
    }

    hideDrawerLoader() {
        this.setState({
            showLoader: false
        });
    }
}

export default FormComponent;