import React from 'react';
import FormComponent from "../../common/FormComponent";
import Validators from "../../constants/ValidatorTypes";
import { addTombstone } from "../../services/tombstone/TombstoneService";
import TombstoneForm from "../../components/forms/tombstone/TombstoneForm";
import strings from "../../localization";
import Grid from '@material-ui/core/Grid';
import { Paper } from "@material-ui/core";
import { bindActionCreators } from 'redux';
import connect from "react-redux/es/connect/connect";
import * as Actions from '../../actions/Actions';
import { withSnackbar } from 'notistack';
import { withRouter } from "react-router-dom";

class AddTombstone extends FormComponent {
  validationList = {
    name: [{ type: Validators.REQUIRED }]
  };

  constructor(props) {
    super(props);

    this.state = {
      data: props.data ? props.data : {},
      errors: {}
    };

    this.props.changeFullScreen(false);
    this.submit = this.submit.bind(this);

  }

  submit() {
    if (!this.validate()) {
      return;
    }

    this.showDrawerLoader();

    addTombstone(this.transformRequest(this.state.data), this.props.cid).then(response => {
      if (!response.ok) {
        let messageKey = response.response.data.message;

        this.props.enqueueSnackbar(strings.tombstone.messages[messageKey], { variant: "error" });

        this.handleError(messageKey);

        return;
      }
      this.props.enqueueSnackbar(strings.addTombstone.added, { variant: 'success' });
      this.props.onFinish(response.data);
      this.hideDrawerLoader();
    });
  }

  transformRequest(data) {
    return {
      ...data,
      userEmail: this.props.uemail,
      companyId: this.props.cid
    }
  }

  handleError(message) {
    switch (message) {
      case "TOMBSTONE_WITH_NAME_ALREADY_EXIST":
        this.setError("name", strings.tombstone.messages.TOMBSTONE_WITH_NAME_ALREADY_EXIST);
        break;
      case "TOMBSTONE_NOT_EXIST":
        this.setError("name", strings.tombstone.messages.TOMBSTONE_NOT_EXIST);
        break;
      case "TOMBSTONE_NAME_CANT_BE_NULL":
        this.setError("name", strings.tombstone.messages.TOMBSTONE_NAME_CANT_BE_NULL);
        break;
    }
  }

  render() {

    return (
      <Grid id='page' item md={12}>

        <div className='header'>
          <h1>{strings.addTombstone.pageTitle}</h1>
        </div>

        <Paper className='paper'>
          <TombstoneForm
            onChange={this.changeData}
            onSubmit={this.submit}
            data={this.state.data}
            errors={this.state.errors}
            onCancel={this.props.onCancel} />
        </Paper>

      </Grid>
    );
  }

}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    changeFullScreen: Actions.changeFullScreen
  }, dispatch);
}

function mapStateToProps({ menuReducers, siteDataReducers }) {
  return { menu: menuReducers, siteData: siteDataReducers };
}

export default withSnackbar(withRouter(connect(mapStateToProps, mapDispatchToProps)(AddTombstone)));
