import React from 'react';
import strings from "../../../localization";
import { getError, hasError } from "../../../functions/Validation";
import { Button, TextField } from "@material-ui/core";

const SettlementForm = ({
    onSubmit,
    onCancel,
    onChange,
    errors,
    data,
    isDisabled
}) => {
    return (

        <form id='user-form'>
            <TextField
                label={strings.settlement.form.code + "*"}
                error={hasError(errors, 'code')}
                helperText={getError(errors, 'code')}
                fullWidth
                autoFocus
                name='code'
                onChange={onChange}
                margin="normal"
                defaultValue={data.code}
                value={data.code}
                InputLabelProps={{
                    shrink: true,
                }}
                disabled = {isDisabled}
            />
            <TextField
                label={strings.settlement.form.name + "*"}
                error={hasError(errors, 'name')}
                helperText={getError(errors, 'name')}
                fullWidth
                name='name'
                onChange={onChange}
                margin="normal"
                defaultValue={data.name}
                value={data.name}
                InputLabelProps={{
                    shrink: true,
                }}
                disabled = {isDisabled}
            />
            <TextField
                label={strings.settlement.form.municipalityCode + "*"}
                error={hasError(errors, 'municipalityCode')}
                helperText={getError(errors, 'municipalityCode')}
                fullWidth
                name='municipalityCode'
                onChange={onChange}
                margin="normal"
                defaultValue={data.municipalityCode}
                value={data.municipalityCode}
                InputLabelProps={{
                    shrink: true,
                }}
                disabled = {isDisabled}
            />
            <TextField
                label={strings.settlement.form.municipalityName + "*"}
                error={hasError(errors, 'municipalityName')}
                helperText={getError(errors, 'municipalityName')}
                fullWidth
                name='municipalityName'
                onChange={onChange}
                margin="normal"
                defaultValue={data.municipalityName}
                value={data.municipalityName}
                InputLabelProps={{
                    shrink: true,
                }}
                disabled = {isDisabled}
            />
            
            {
                !isDisabled &&
                <div className='submit-container'>
                    <Button variant="contained" color="primary" onClick={onSubmit}>
                        {strings.settlement.form.ok}
                    </Button>
                    <Button variant="contained" color="secondary" onClick={onCancel}>
                        {strings.settlement.form.cancel}
                    </Button>
                </div>
            }
        </form>
    )};

export default SettlementForm;