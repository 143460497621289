import React from 'react'
import { bindActionCreators } from "redux";
import * as Actions from "../../actions/Actions";
import { withRouter } from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import Grid from '@material-ui/core/Grid';
import { Paper } from "@material-ui/core";
import strings from "../../localization";
import Validators from "../../constants/ValidatorTypes";
import FormComponent from "../../common/FormComponent";
import { withSnackbar } from "notistack";
import { addFirm } from '../../services/firm/FirmService';
import FirmForm from '../../components/forms/firm/FirmForm';


class AddFirm extends FormComponent {

    validationList = {
        taxNumber: [{ type: Validators.REQUIRED }],
        name: [{ type: Validators.REQUIRED }],
        headquarters: [{ type: Validators.REQUIRED }],
        street: [{ type: Validators.REQUIRED }],
        streetNumber: [{ type: Validators.REQUIRED }],
    };

    constructor(props) {
        super(props);

        this.state = {
            data: props.data ? props.data : {},
            errors: {}
        };

        this.props.changeFullScreen(false);

        this.submit = this.submit.bind(this);

    }

    submit() {
        if (!this.validate()) {
            return;
        }

        this.showDrawerLoader();

        addFirm(this.transformRequest(this.state.data), this.props.cid).then(response => {
            if (!response.ok) {
                let messageKey = response.response.data.message;

                this.props.enqueueSnackbar(strings.firm.messages[messageKey], {
                    variant: "error"
                });

                this.handleError(messageKey);

                return;
            }

            this.props.enqueueSnackbar(strings.addCompany.firmAdded, { variant: 'success' });
            this.props.onFinish(response.data.firm);

            this.hideDrawerLoader();
        });
    }

    transformRequest(data) {
        return {
            ...data,
            userEmail: this.props.uemail,
        }
    }

    handleError(message) {
        switch (message) {
            case "FIRM_WITH_TAX_NUMBER_ALREADY_EXIST":
                this.setError("taxNumber", strings.firm.messages.FIRM_WITH_TAX_NUMBER_ALREADY_EXIST);
                break;
            case "FIRM_TAX_NUMBER_CANT_BE_NULL":
                this.setError("taxNumber", strings.firm.messages.FIRM_TAX_NUMBER_CANT_BE_NULL);
                break;
            case "FIRM_NAME_CANT_BE_NULL":
                this.setError("name", strings.firm.messages.FIRM_NAME_CANT_BE_NULL);
                break;
            case "FIRM_HEADQUARTERS_CANT_BE_NULL":
                this.setError("headquarter", strings.firm.messages.FIRM_HEADQUARTERS_CANT_BE_NULL);
                break;
            case "FIRM_STREET_CANT_BE_NULL":
                this.setError("street", strings.firm.messages.FIRM_STREET_CANT_BE_NULL);
                break;
            case "FIRM_STREET_NUMBER_CANT_BE_NULL":
                this.setError("streetNumber", strings.firm.messages.FIRM_STREET_NUMBER_CANT_BE_NULL);
                break;
        }
    }

    render() {
        return (
            <Grid id='page' item md={12}>

                <div className='header'>
                    <h1>{strings.firm.pageTitle}</h1>
                </div>

                <Paper className='paper'>
                    <FirmForm onChange={this.changeData} onSubmit={this.submit}
                        data={this.state.data} errors={this.state.errors} onCancel={this.props.onCancel} />
                </Paper>

            </Grid>

        );
    }
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        changeFullScreen: Actions.changeFullScreen
    }, dispatch);
}

function mapStateToProps({ menuReducers, siteDataReducers }) {
    return { menu: menuReducers, siteData: siteDataReducers };
}

export default withSnackbar(withRouter(connect(mapStateToProps, mapDispatchToProps)(AddFirm)));