import { withSnackbar } from 'notistack';
import React from 'react';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import connect from 'react-redux/es/connect/connect';
import TablePage from '../../common/TablePage';
import * as Actions from "../../actions/Actions";
import strings from '../../localization';
import { TableFooter, TableRow, TableBody, TableCell, TableHead, Menu, ListItemIcon, ListItemText } from "@material-ui/core";
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import VisibilityIcon from '@material-ui/icons/Visibility';
import MoreVert from '@material-ui/icons/MoreVert';
import { MenuItem } from "@material-ui/core";
import { getEditedRenewalsForProcessing } from '../../services/renewalEdited/RenewalEditedService';
import EditRenewal from '../renewal/EditRenewal';
import ViewRenewal from '../renewal/ViewRenewal';
import { dateToStringDatePickerFormat3 } from '../../util/DateUtil';


class RenewalProcessingResult extends TablePage {
    tableDescription = [
        { key: 'renewalNumber', label: strings.renewalList.renewalNumber, sort: 'renewalNumber' },
        { key: 'graveyard', label: strings.renewalList.graveyard, sort: 'graveyard' },
        { key: 'graveSiteCode', label: strings.renewalList.graveSiteCode, sort: 'graveSiteCode' },
        { key: 'submitterName', label: strings.renewalList.submitterName, sort: 'submitterName' },
        { key: 'submitterSurname', label: strings.renewalList.submitterSurname, sort: 'submitterSurname' },
        { key: 'dateOfChange', label: strings.renewalList.dateOfChange, transform: 'renderColumnDate', sort: 'dateOfChange' },
        { key: 'expiryDate', label: strings.renewalList.expiryDate, transform: 'renderColumnDate', sort: 'expiryDate' },
        { key: 'canceled', label: strings.renewalList.canceled, transform: 'renderColumnDeleted', sort: 'canceled' },
        { key: 'dateOfCancellation', label: strings.renewalList.dateOfCancellation, transform: 'renderColumnDateTime', sort: 'dateOfCancellation' },
        { key: 'dateCreated', label: strings.renewalList.dateCreated, transform: 'renderColumnDate', sort: 'dateCreated' },
    ];

    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            data: {
                ...this.state.data,
                selectedRenewalType: { id: -1 },
            },
            showSearch: false,
            showAdd: false,
            responseData: [],
            flagFirstTimeRender: false,
        };

        this.state.sortBy = "dateCreated,DESC";

    }

    fetchData() {

        if (this.state.flagFirstTimeRender) {
            let data = {
                page: 0,
                size: 5000,
                graveyardId: this.state.obj.selectedGraveyard != null ? this.state.obj.selectedGraveyard.id : -1,
                field: this.state.data.field != null ? this.state.data.field : "",
                plot: this.state.data.plot !=null ? this.state.data.plot : "",
                graveSiteNumber: this.state.data.graveSiteNumber != null ? this.state.data.graveSiteNumber : "",
                fromDate: dateToStringDatePickerFormat3(this.state.data.renewalProcessingFromDate),
                toDate: dateToStringDatePickerFormat3(this.state.data.renewalProcessingToDate),
                companyId: this.state.companyId,
                userId: this.props.auth.user.id,
            };

            getEditedRenewalsForProcessing(data).then(response => {
                this.setState({
                    showTable: true,
                    responseData: response.data.entities,
                }, () => {
                    this.setState({
                        tableData: this.state.responseData,
                        total: this.state.responseData.length,
                    });

                });
            });

        }
        else {
            this.setState({
                tableData: this.props.data,
                total: this.props.data.length,
                flagFirstTimeRender: 1,
            });
        }

    }

    usePermissions(){
        let data = {
          userId: this.props.auth.user.id,
          category: "Обрада"
        }
        this.getPermissionsS(data);
        this.showAdd(this.props.showAdd);
        this.showRemove(this.props.remove);
        this.showEdit(this.props.showEdit);
        this.showView(this.props.showView);
        this.removedItemsShow(this.props.removedItemsShow);
    
      }
    
      componentDidMount() {
        this.usePermissions()
        this.setState({
            companyId: this.props.auth.user.company.id,
        });

    }

    componentWillReceiveProps(nextProps) {
        strings.setLanguage(nextProps.auth.user.language);
        this.tableDescription = [
            { key: 'renewalNumber', label: strings.renewalList.renewalNumber, sort: 'renewalNumber' },
            { key: 'graveyard', label: strings.renewalList.graveyard, sort: 'graveyard' },
            { key: 'graveSiteCode', label: strings.renewalList.graveSiteCode, sort: 'graveSiteCode' },
            { key: 'submitterName', label: strings.renewalList.submitterName, sort: 'submitterName' },
            { key: 'submitterSurname', label: strings.renewalList.submitterSurname, sort: 'submitterSurname' },
            { key: 'dateOfChange', label: strings.renewalList.dateOfChange, transform: 'renderColumnDate', sort: 'dateOfChange' },
            { key: 'expiryDate', label: strings.renewalList.expiryDate, transform: 'renderColumnDate', sort: 'expiryDate' },
            { key: 'canceled', label: strings.renewalList.canceled, transform: 'renderColumnDeleted', sort: 'canceled' },
            { key: 'dateOfCancellation', label: strings.renewalList.dateOfCancellation, transform: 'renderColumnDateTime', sort: 'dateOfCancellation' },
            { key: 'dateCreated', label: strings.renewalList.dateCreated, transform: 'renderColumnDate', sort: 'dateCreated' },
        ]

        if (this.props.data !== nextProps.data) {
            this.setState({
                responseData: nextProps.data,
                tableData: nextProps.data,
            });
        }
    }

    getPageHeader() {
        return <h2>{strings.renewalList.renewalsForProcessingList}</h2>;
    }

    renderTableControls() {
        
    }

    onFinish(data, fetch) {
        this.props.onEditCompleted(data);
    }

    renderEditContent() {
        return <EditRenewal
          id={this.state.selectedItem.id}
          uemail={this.props.auth.user.email}
          cid={this.state.companyId}
          onCancel={this.onCancel}
          onFinish={this.onFinish}
          isDisabled={false}
          flag={"edit"}
          searchTypeFlag={this.state.data.selectedRenewalType}
          modifiedData={true}
          data={this.state.selectedItem}
        />
      }
    
      renderViewContent() {
        return <ViewRenewal
          id={this.state.selectedItem.id}
          isDisabled={true}
          onCancel={this.onCancel}
          onFinish={this.onFinish}
          flag={"view"}
          searchTypeFlag={this.props.selectedRenewalType}
          modifiedData={true}
          data={this.state.selectedItem}
        />
      }

    isRowSelected(item) {
        return this.state.selectedItem && item.indicator === this.state.selectedItem.indicator && item.id === this.state.selectedItem.id;
    }

    renderRowMenu(index, item) {

        let ariaOwns = 'action-menu-' + index;

        return (
            <TableCell>
                <IconButton
                    size='small'
                    aria-owns={this.state.anchorEl ? ariaOwns : undefined}
                    aria-haspopup="true"
                    onClick={(event) => this.handleMenuClick(event, ariaOwns)}
                >
                    <MoreVert />
                </IconButton>
                {
                    ariaOwns === this.state.ariaOwns &&
                    <Menu
                        id={ariaOwns}
                        anchorEl={this.state.anchorEl}
                        open={Boolean(this.state.anchorEl)}
                        onClose={() => this.handleMenuClose()}
                    >
                        <MenuItem onClick={() => this.handleMenuView(item)}>
                            <ListItemIcon>
                                <VisibilityIcon />
                            </ListItemIcon>
                            <ListItemText primary={strings.table.view} />
                        </MenuItem>
                        <MenuItem onClick={() => this.handleMenuEdit(item)}>
                            <ListItemIcon>
                                <EditIcon />
                            </ListItemIcon>
                            <ListItemText primary={strings.table.edit} />
                        </MenuItem>
                        <MenuItem onClick={() =>{ this.props.handleRemoveItemFromProcessingList(item); this.handleMenuClose(); }}>
                            <ListItemIcon>
                                <DeleteIcon />
                            </ListItemIcon>
                            <ListItemText primary={strings.table.delete} />
                        </MenuItem>
                    </Menu>
                }
            </TableCell>
        );
    }



    renderTableRow(data = []) {

        let result = [];

        for (let item of data) {

            let className = 'table-row-' + result.length % 2;

            if (this.isRowSelected(item)) {
                className += ' selected';
            }

            const today = new Date();
            const dateUpdated = new Date(item.dateEdited ? item.dateEdited : item.dateUpdated);
            const sevenDaysAgo = new Date();
            sevenDaysAgo.setDate(today.getDate() - 7);

            if (dateUpdated >= sevenDaysAgo && dateUpdated <= today) {
                className = 'backgroundColorEditedProcess';
            }

            result.push(
                <TableRow key={'table-row-' + result.length} className={className} onClick={() => this.selectRow(item)}>
                    {this.renderRowMenu(result.length, item)}
                    {this.renderTableRowData(item)}
                </TableRow>
            )
        }

        return (
            <TableBody>
                {result}
            </TableBody>
        );
    }

    renderTableHeader() {

        let result = [];

        for (let item of this.tableDescription) {

            result.push(
                <TableCell key={'table-header-' + result.length}>
                    <div className='container-horizontal'>
                        <div className='container-horizontal-label'>
                            {item.label}
                        </div>
                    </div>
                </TableCell>
            )
        }

        return (
            <TableHead className='table-header'>
                <TableRow>
                    <TableCell>
                        {strings.table.actions}
                    </TableCell>
                    {result}
                </TableRow>
            </TableHead>
        );
    }

    renderTableFooter() {

        return (
            <TableFooter>
                <TableRow>

                </TableRow>
            </TableFooter>
        );
    }


}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        changeFullScreen: Actions.changeFullScreen
    }, dispatch);
}

function mapStateToProps({ menuReducers, authReducers, siteDataReducers }) {
    return { menu: menuReducers, auth: authReducers, siteData: siteDataReducers, };
}

export default withSnackbar(withRouter(connect(mapStateToProps, mapDispatchToProps)(RenewalProcessingResult)));
