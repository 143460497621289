import React from 'react';
import strings from "../../../localization";
import { getError, hasError } from "../../../functions/Validation";
import { Button, TextField, FormLabel, FormControlLabel, Radio, FormControl, RadioGroup } from "@material-ui/core";
import DatePickerControl from '../../controls/DatePickerControl';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { stringToDate } from '../../../util/DateUtil';
import moment from 'moment';

const GrassForm = ({
  onSubmit,
  onCancel,
  onChange,
  errors,
  data,
  isDisabled,
  handleChange,
  onAutocompleteChange,
  handleTextInputChange,
  mainBooksOfBuried,
  submitters,
  submitter,
  graveyards,
  graveSites,
  flag,
  currentYear,
  typedInGraveSite,
  keyPress,
  keyPressBackspace,
}) => {
  return (
    <form id='user-form'>
      <TextField
        label={strings.grass.form.grassNumber + "*"}
        error={hasError(errors, 'grassNumber')}
        helperText={getError(errors, 'grassNumber')}
        fullWidth
        name='grassNumber'
        onChange={onChange}
        margin="normal"
        autoFocus
        defaultValue={currentYear}
        value={data.grassNumber}
        InputLabelProps={{
          shrink: true,
        }}
        disabled={isDisabled}
      />
      {((data.graveyard && flag == "edit") || (flag == "add") || (data.graveyard && flag == "view")) &&
        <Autocomplete
          id={"graveyard"}
          name={"graveyard"}
          options={graveyards}
          getOptionLabel={option => option.name}
          onChange={onAutocompleteChange}
          renderInput={params => (
            <TextField
              {...params}
              variant="standard"
              label={strings.tombRent.form.graveyard}
              error={hasError(errors, 'graveyard')}
              helperText={getError(errors, 'graveyard')}
              margin="normal"
              fullWidth
              name={"graveyard"}
              onChange={handleTextInputChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
          disabled={isDisabled}
          value={data.graveyard}
        />
      }
      {((!data.graveyard && flag == "edit") || (!data.graveyard && flag == "view")) &&
        <Autocomplete
          id={"graveyard"}
          name={"graveyard"}
          options={graveyards}
          getOptionLabel={option => option.name}
          onChange={onAutocompleteChange}
          renderInput={params => (
            <TextField
              {...params}
              variant="standard"
              label={strings.tombRent.form.graveyard}
              error={hasError(errors, 'graveyard')}
              helperText={getError(errors, 'graveyard')}
              margin="normal"
              fullWidth
              name={"graveyard"}
              onChange={handleTextInputChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
          disabled={isDisabled}
          value={data.graveyard}
        />
      }
      {((data.graveSite && flag == "edit") || (flag == "add") || (data.graveSite && flag == "view")) && ((data.graveyard != undefined && data.graveyard != null)) &&
        <Autocomplete
          noOptionsText={(typedInGraveSite != undefined ? strings.mainBookOfBuried.form.create + typedInGraveSite : "")}
          onKeyPress={keyPress}
          id={"graveSite"}
          name={"graveSite"}
          options={graveSites}
          getOptionLabel={option => option.code}
          onChange={onAutocompleteChange}
          renderInput={params => (
            <TextField
              {...params}
              variant="standard"
              label={strings.tombRent.form.graveSite}
              error={hasError(errors, 'graveSite')}
              helperText={getError(errors, 'graveSite')}
              margin="normal"
              fullWidth
              name={"graveSite"}
              onChange={handleTextInputChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
          disabled={isDisabled}
          value={data.graveSite}
          disableClearable={true}
          onKeyDown={keyPressBackspace}
        />
      }
      {((!data.graveSite && flag == "edit") || (!data.graveSite && flag == "view") && (data.graveyard != undefined && data.graveyard != null)) &&
        <Autocomplete
          noOptionsText={(typedInGraveSite != undefined ? strings.mainBookOfBuried.form.create + typedInGraveSite : "")}
          onKeyPress={keyPress}
          id={"graveSite"}
          name={"graveSite"}
          options={graveSites}
          getOptionLabel={option => option.code}
          onChange={onAutocompleteChange}
          renderInput={params => (
            <TextField
              {...params}
              variant="standard"
              label={strings.tombRent.form.graveSite}
              error={hasError(errors, 'graveSite')}
              helperText={getError(errors, 'graveSite')}
              margin="normal"
              fullWidth
              name={"graveSite"}
              onChange={handleTextInputChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
          disabled={isDisabled}
          value={data.graveSite}
          disableClearable={true}
          onKeyDown={keyPressBackspace}
        />
      }

      {(data.graveyard == undefined || data.graveyard == null) &&
        <Autocomplete
          id={"graveSite"}
          name={"graveSite"}
          options={graveSites}
          getOptionLabel={option => option.code}
          onChange={onAutocompleteChange}
          renderInput={params => (
            <TextField
              {...params}
              variant="standard"
              label={strings.grass.form.graveSite}
              error={hasError(errors, 'graveSite')}
              helperText={getError(errors, 'graveSite')}
              margin="normal"
              fullWidth
              name={"graveSite"}
              onChange={handleTextInputChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
          disabled={true}
          value={null}
          disableClearable={true}
        />
      }

      {((data.mainBookOfBuried && flag == "edit") || (flag == "add") || (data.mainBookOfBuried && flag == "view")) &&
        <Autocomplete
          id={"mainBookOfBuried"}
          name={"mainBookOfBuried"}
          options={mainBooksOfBuried}
          getOptionLabel={option => option.deceasedNameSurname + " - " + strings.exhumation.form.mainBookOfBuried + ": " + option.mainBookOfBuries}
          onChange={onAutocompleteChange}
          renderInput={params => (
            <TextField
              {...params}
              variant="standard"
              label={strings.tombRent.form.mainBookOfBuried}
              error={hasError(errors, 'mainBookOfBuried')}
              helperText={getError(errors, 'mainBookOfBuried')}
              margin="normal"
              fullWidth
              name={"mainBookOfBuried"}
              onChange={handleTextInputChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
          disabled={isDisabled}
          value={data.mainBookOfBuried}
        />
      }
      {((!data.mainBookOfBuried && flag == "edit") || (!data.mainBookOfBuried && flag == "view")) &&
        <Autocomplete
          id={"mainBookOfBuried"}
          name={"mainBookOfBuried"}
          options={mainBooksOfBuried}
          getOptionLabel={option => option.deceasedNameSurname + " - " + strings.exhumation.form.mainBookOfBuried + ": " + option.mainBookOfBuries}
          onChange={onAutocompleteChange}
          renderInput={params => (
            <TextField
              {...params}
              variant="standard"
              label={strings.tombRent.form.mainBookOfBuried}
              error={hasError(errors, 'mainBookOfBuried')}
              helperText={getError(errors, 'mainBookOfBuried')}
              margin="normal"
              fullWidth
              name={"mainBookOfBuried"}
              onChange={handleTextInputChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
          disabled={isDisabled}
          value={data.mainBookOfBuried}
        />
      }
      <FormControl component="fieldset">
        <FormLabel className="checkboxLabel"> {strings.grass.form.deceasedInside} </FormLabel>
        <RadioGroup name="deceasedInside" value={(data.deceasedInside == true || data.deceasedInside == "true") ? "true" : "false"} onChange={handleChange} className="radioGroup">
          <FormControlLabel value={"true"} disabled={isDisabled} control={<Radio />} label={<span className="formControlLabel">{strings.grass.form.yes}</span>} />
          <FormControlLabel value={"false"} disabled={isDisabled} control={<Radio />} label={<span className="formControlLabel">{strings.grass.form.no}</span>} />
        </RadioGroup>
      </FormControl>

      {(flag == "view" && data.amount % 1 == 0 && data.amount != null) &&
        <TextField
          label={strings.grass.form.amount}
          error={hasError(errors, 'amount')}
          helperText={getError(errors, 'amount')}
          fullWidth
          name='amount'
          onChange={onChange}
          margin="normal"
          defaultValue={data.amount}
          value={data.amount + ".00"}
          InputLabelProps={{
            shrink: true,
          }}
          disabled={isDisabled}
          placeholder="0.00"
        />
      }

      {(flag == "view" && data.amount % 1 != 0 && data.amount != null) &&
        <TextField
          label={strings.grass.form.amount}
          error={hasError(errors, 'amount')}
          helperText={getError(errors, 'amount')}
          fullWidth
          name='amount'
          onChange={onChange}
          margin="normal"
          defaultValue={data.amount}
          value={data.amount.toFixed(2)}
          InputLabelProps={{
            shrink: true,
          }}
          disabled={isDisabled}
          placeholder="0.00"
        />
      }

      {flag == "view" && data.amount == null &&
        <TextField
          label={strings.grass.form.amount}
          error={hasError(errors, 'amount')}
          helperText={getError(errors, 'amount')}
          fullWidth
          name='amount'
          onChange={onChange}
          margin="normal"
          defaultValue={data.amount}
          value={0 + ".00"}
          InputLabelProps={{
            shrink: true,
          }}
          disabled={isDisabled}
          placeholder="0.00"
        />
      }

      {(flag == "add" || flag == "edit") &&
        <TextField
          label={strings.grass.form.amount}
          error={hasError(errors, 'amount')}
          helperText={getError(errors, 'amount')}
          fullWidth
          name='amount'
          onChange={onChange}
          margin="normal"
          defaultValue={data.amount}
          value={data.amount}
          InputLabelProps={{
            shrink: true,
          }}
          disabled={isDisabled}
          placeholder="0.00"
        />
      }
      {(data.expiryDateString != undefined && data.expiryDateString != null && data.expiryDateString != "") &&
        <TextField
          label={strings.grass.form.expiryDateString}
          fullWidth
          name='expiryDateString'
          onChange={handleTextInputChange}
          margin="normal"
          value={data.expiryDateString}
          InputLabelProps={{
            shrink: true,
          }}
          disabled={true}
        />
      }
      <DatePickerControl
        label={strings.grass.form.expiryDate}
        error={getError(errors, 'expiryDate')}
        hasError={hasError(errors, 'expiryDate')}
        helperText={getError(errors, 'expiryDate')}
        placeholder={strings.grass.form.expiryDate}
        name='expiryDate'
        onChange={onChange}
        //date={data.expiryDate ? new Date(data.expiryDate) : data.expiryDate}
        date={data.expiryDate ? stringToDate(data.expiryDateString) > moment(data.expiryDate, 'YYYY-MM-DD') ? stringToDate(data.expiryDateString).toDate() : new Date(data.expiryDate) : data.expiryDate}
        isClearable={true}
        dateFormat='dd/MM/yyyy'
        disabled={isDisabled}
      />
      {(data.paymentDateString != undefined && data.paymentDateString != null && data.paymentDateString != "") &&
        <TextField
          label={strings.grass.form.paymentDateString}
          fullWidth
          name='paymentDateString'
          onChange={handleTextInputChange}
          margin="normal"
          value={data.paymentDateString}
          InputLabelProps={{
            shrink: true,
          }}
          disabled={true}
        />
      }
      <DatePickerControl
        label={strings.grass.form.paymentDate}
        error={getError(errors, 'paymentDate')}
        hasError={hasError(errors, 'paymentDate')}
        helperText={getError(errors, 'paymentDate')}
        placeholder={strings.grass.form.paymentDate}
        name='paymentDate'
        onChange={onChange}
        //date={data.paymentDate ? new Date(data.paymentDate) : data.paymentDate}
        date={data.paymentDate ? stringToDate(data.paymentDateString) > moment(data.paymentDate, 'YYYY-MM-DD') ? stringToDate(data.paymentDateString).toDate() : new Date(data.paymentDate) : data.paymentDate}
        isClearable={true}
        dateFormat='dd/MM/yyyy'
        disabled={isDisabled}
      />
      {((data.submitter && flag == "edit") || (flag == "add") || (data.submitter && flag == "view")) &&
        <Autocomplete
          id={"submitter"}
          name={"submitter"}
          options={submitters}
          getOptionLabel={option => option.personalNumber}
          onChange={onAutocompleteChange}
          renderInput={params => (
            <TextField
              {...params}
              variant="standard"
              label={strings.tombRent.form.personalNumber}
              margin="normal"
              fullWidth
              name={"submitter"}
              onChange={handleTextInputChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
          disabled={isDisabled}
          value={data.submitter}
        />
      }
      {((!data.submitter && flag == "edit") || (!data.submitter && flag == "view")) &&
        <Autocomplete
          id={"submitter"}
          name={"submitter"}
          options={submitters}
          getOptionLabel={option => option.personalNumber}
          onChange={onAutocompleteChange}
          renderInput={params => (
            <TextField
              {...params}
              variant="standard"
              label={strings.tombRent.form.personalNumber}
              margin="normal"
              fullWidth
              name={"submitter"}
              onChange={handleTextInputChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
          disabled={isDisabled}
          value={data.submitter}
        />
      }
      <TextField
        label={strings.tombRent.form.submitterName}
        error={hasError(errors, 'nameSurnameSubmitter')}
        helperText={getError(errors, 'nameSurnameSubmitter')}
        fullWidth
        name='nameSurnameSubmitter'
        onChange={onChange}
        margin="normal"
        defaultValue={submitter.nameSurnameSubmitter}
        value={submitter.nameSurnameSubmitter}
        InputLabelProps={{
          shrink: true,
        }}
        disabled={true}
      />
      <TextField
        label={strings.tombRent.form.submitterAddress}
        error={hasError(errors, 'addressSubmitter')}
        helperText={getError(errors, 'addressSubmitter')}
        fullWidth
        name='addressSubmitter'
        onChange={onChange}
        margin="normal"
        defaultValue={submitter.addressSubmitter}
        value={submitter.addressSubmitter}
        InputLabelProps={{
          shrink: true,
        }}
        disabled={true}
      />
      <TextField
        label={strings.tombRent.form.submitterMunicipality}
        error={hasError(errors, 'municipalitySubmitter')}
        helperText={getError(errors, 'municipalitySubmitter')}
        fullWidth
        name='municipalitySubmitter'
        onChange={onChange}
        margin="normal"
        defaultValue={submitter.municipalitySubmitter}
        value={submitter.municipalitySubmitter}
        InputLabelProps={{
          shrink: true,
        }}
        disabled={true}
      />
      <TextField
        label={strings.tombRent.form.submitterTelephone}
        error={hasError(errors, 'telephoneSubmitter')}
        helperText={getError(errors, 'telephoneSubmitter')}
        fullWidth
        name='telephoneSubmitter'
        onChange={onChange}
        margin="normal"
        defaultValue={submitter.telephoneSubmitter}
        value={submitter.telephoneSubmitter}
        InputLabelProps={{
          shrink: true,
        }}
        disabled={true}
      />
      {
        !isDisabled &&
        <div className='submit-container'>
          <Button variant="contained" color="primary" onClick={onSubmit}>
            {strings.grass.form.ok}
          </Button>
          <Button variant="contained" color="secondary" onClick={onCancel}>
            {strings.grass.form.cancel}
          </Button>
        </div>
      }
    </form>
  )
};

export default GrassForm;