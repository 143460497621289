import { request } from "../../base/HTTP";
import HttpMethod from "../../constants/HttpMethod";

export async function getPostalAddressCodes(data) {
  return await request('/api/postalAddressCode/search', data);
}

export async function getAllPostalAddressCodes() {
  return await request("api/postalAddressCode/all");
}

export async function addPostalAddressCode(data, cid) {
  return await request('/api/postalAddressCode/' + cid, data, HttpMethod.POST);
}

export function getPostalAddressCodeById(id) {
  return request("api/postalAddressCode/" + id, {}, HttpMethod.GET);
}

export async function editPostalAddressCode(data) {
  return await request("api/postalAddressCode/" + data.id, data, HttpMethod.PUT);
}

export async function deletePostalAddressCode(id, uid) {
  return await request('/api/postalAddressCode/' + id + '/' + uid, {}, HttpMethod.DELETE);
}