import { request } from "../../base/HTTP";
import HttpMethod from "../../constants/HttpMethod";

export async function getAllCrates() {
  return await request("/api/crate/all");
}

export async function getCrates(data) {
  return await request("/api/crate/search", data);
}

export function getCrateById(id) {
  return request("/api/crate/" + id, {}, HttpMethod.GET);
}

export async function addCrate(data, cid) {
  return await request("/api/crate/" + cid, data, HttpMethod.POST);
}

export async function editCrate(data) {
  return await request("/api/crate/" + data.id, data, HttpMethod.PUT);
}

export async function deleteCrate(id, uid) {
  return await request("/api/crate/" + id + '/' + uid, {}, HttpMethod.DELETE);
}

export async function getDeletedCrates(data){
  return await request('/api/crate/deleted', data)
}

export async function restoreCrate(data) {
  return await request("/api/crate/restore/" + data.id, data, HttpMethod.PUT);
}