import {request} from "../../base/HTTP";
import HttpMethod from "../../constants/HttpMethod";

export async function getMainBooksOfCremated(data) {
    return await request('/api/mainBookOfCremated/search', data);
}

export async function addMainBookOfCremated(data, cid) {
    return await request('/api/mainBookOfCremated/' + cid, data, HttpMethod.POST);
}

export function getMainBookOfCrematedById(id) {
    return  request("api/mainBookOfCremated/" + id , {}, HttpMethod.GET);
}

export async function editMainBookOfCremated(data) {
    return await request("api/mainBookOfCremated/" + data.id, data, HttpMethod.PUT);
}

export async function deleteMainBookOfCremated(id, uid) {
    return await request('/api/mainBookOfCremated/' + id + '/' + uid, {} , HttpMethod.DELETE);
}

export function getAutocompleteObjectsById(data) {
    return  request("api/mainBookOfCremated/objects/" + data.id , data, HttpMethod.PUT);
}

export async function getDeletedMainBooksOfCremated(data){
    return await request('/api/mainBookOfCremated/deleted', data)
}

export async function restoreMainBookOfCremated(data) {
    return await request("api/mainBookOfCremated/restore/" + data.id, data, HttpMethod.PUT);
}