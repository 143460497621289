import FormComponent from "../../common/FormComponent";
import React from "react";
import { bindActionCreators } from "redux";
import * as Actions from "../../actions/Actions";
import { withRouter } from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import Grid from "@material-ui/core/Grid";
import { Paper } from "@material-ui/core";
import strings from "../../localization";
import Validators from "../../constants/ValidatorTypes";
import { withSnackbar } from "notistack";
import { validateTypedInGraveSite } from "../../functions/Validation";
import { getMainBooksOfBuried, getMainBookOfBuriedById } from "../../services/mainBookOfBuried/MainBookOfBuriedService";
import { getGraveyards, getGraveyardById } from "../../services/graveyard/GraveyardService";
import { getGraveSites, getGraveSiteById, getGraveNotTakenSites, getGraveSitesWithSelectedGraveyard, addGraveSite } from "../../services/graveSite/GraveSiteService";
import StoneCuttingWorksForm from "../../components/forms/stoneCuttingWorks/StoneCuttingWorksForm";
import { getStoneCuttingWorksById, editStoneCuttingWorks } from "../../services/stoneCuttingWorks/StoneCuttingWorksService";
import { getStonecutterById, getStonecutters } from "../../services/stonecutter/StonecutterService";
import { getCitizenById, getCitizens, getCitizenByPersonalNumber } from "../../services/citizen/CitizenService";

class EditStoneCuttingWorks extends FormComponent {

  validationList = {
    code: [{ type: Validators.REQUIRED }, { type: Validators.IS_NUMBER }],
  };

  constructor(props) {
    super(props);

    this.state = {
      data: props.data ? props.data : {},
      errors: {},
      citizen: {},
      sortBy: "name,ASC"
    };

    this.props.changeFullScreen(false);
    this.submit = this.submit.bind(this);
    this.keyPress = this.keyPress.bind(this);
  }

  componentDidMount() {
    getStoneCuttingWorksById(this.props.id).then(response => {
      this.setState({
        data: response.data,
      }, () => {
        {
          this.state.data.mainBookOfBuriedId &&
            getMainBookOfBuriedById(this.state.data.mainBookOfBuriedId).then(response => {
              this.setState({
                data: {
                  ...this.state.data,
                  mainBookOfBuried: response.data
                },
              })
            });
        }
        {
          this.state.data.stonecutterId &&
            getStonecutterById(this.state.data.stonecutterId).then(response => {
              this.setState({
                data: {
                  ...this.state.data,
                  stonecutter: response.data
                },
              })
            });
        }
        {
          this.state.data.graveyardId &&
            getGraveyardById(this.state.data.graveyardId).then(response => {
              this.setState({
                data: {
                  ...this.state.data,
                  graveyard: response.data
                },
              })
            });
        }
        {
          this.state.data.graveSiteId &&
            getGraveSiteById(this.state.data.graveSiteId).then(response => {
              this.setState({
                data: {
                  ...this.state.data,
                  graveSite: response.data
                },
              })
            });
        }
        {
          this.state.data.citizenId &&
            getCitizenById(this.state.data.citizenId).then(response => {
              this.setState({
                data: {
                  ...this.state.data,
                  citizen: response.data
                },
                citizen: {
                  citizenNameSurname: response.data.name + " " + response.data.surname,
                  citizenAddress: response.data.street + " " + response.data.streetNumber,
                  citizenSettlement: response.data.postalCode,
                  citizenTelephone: response.data.telephone
                }
              })
            });
        }
      });
    });
  }

  keyPress(event) {
    if (event.key == "Enter") {
      event.preventDefault();

      if (!validateTypedInGraveSite(this.state.typedInGraveSite)) {
        this.props.enqueueSnackbar(strings.graveSite.messages.GRAVE_SITE_INPUT_NOT_VALID, {
          variant: "error"
        });
        return;
      }

      addGraveSite(this.transformRequestGraveSite(), this.props.cid).then(response => {
        if (!response.ok) {

          let messageKey = response.response.data.message;

          this.props.enqueueSnackbar(strings.graveSite.messages[messageKey], {
            variant: "error"
          });

          this.handleError(messageKey);

          return;
        }

        this.setState({
          data: {
            ...this.state.data,
            graveSite: response.data,
            graveSiteCode: response.data.code,
            graveSiteId: response.data.id
          }
        });

        this.props.enqueueSnackbar(strings.addCompany.graveSiteAdded, { variant: 'success' });

      });
    }
  }

  keyPressBackspace(e) {
    if (e.target.value.length == 2 && e.keyCode !== 8) {
      e.target.value = e.target.value + '/';
    }

    if (e.target.value.length == 5 && e.keyCode !== 8) {
      e.target.value = e.target.value + '-';
    }

    if (e.keyCode !== 8) {
      return;
    }

    if (e.target.value.charAt(e.target.value.length - 2) == "-" || e.target.value.charAt(e.target.value.length - 2) == "/") {
      e.target.value = e.target.value.substring(0, e.target.value.length - 1);
    }
    else {
      e.target.value = e.target.value.substring(0, e.target.value.length);
    }
  }

  transformRequestGraveSite() {
    return {
      userEmail: this.props.uemail,
      code: this.state.typedInGraveSite,
      belongingGraveyardId: this.state.data.graveyard.id
    }
  }


  submit() {

    if (!this.validate()) {
      this.props.enqueueSnackbar(strings.error.requiredFields, { variant: 'error' });
      return;
    }

    if (this.state.data.code.length != 8) {
      this.setError("code", strings.stoneCuttingWorks.editForm.errorLengthForEdit);
      this.props.enqueueSnackbar(strings.error.requiredFields, { variant: 'error' });
      return;
    }

    this.showDrawerLoader();

    editStoneCuttingWorks(this.transformRequest(this.state.data)).then(response => {
      if (!response.ok) {
        let messageKey = response.response.data.message;

        this.props.enqueueSnackbar(strings.stoneCuttingWorks.messages[messageKey], {
          variant: "error"
        });

        this.handleError(messageKey);

        return;
      }

      this.props.enqueueSnackbar(strings.addCompany.stoneCuttingWorkEdited, { variant: 'success' });
      this.props.onFinish(response.data.stoneCuttingWorks);

      this.hideDrawerLoader();
    });
  }

  transformRequest(data) {
    return {
      ...data,
      userEmail: this.props.uemail,
      companyId: this.props.cid,
      stonecutterId: this.state.data.stonecutter ? this.state.data.stonecutter.id : null,
      stonecutterName: this.state.data.stoncutter ? this.state.data.stonecutter.name : null,
      mainBookOfBuriedId: this.state.data.mainBookOfBuried ? this.state.data.mainBookOfBuried.id : null,
      graveyardId: this.state.data.graveyard ? this.state.data.graveyard.id : null,
      graveSiteId: this.state.data.graveSite ? this.state.data.graveSite.id : null,
      citizenId: this.state.data.citizen ? this.state.data.citizen.id : null
    }
  }

  /* Handlers */

  handleError(message) {
    switch (message) {
      case "STONE_CUTTING_WORK_WITH_CODE_ALREADY_EXIST":
        this.setError("code", strings.stoneCuttingWorks.messages.STONE_CUTTING_WORK_WITH_CODE_ALREADY_EXIST);
        break;
      case "STONE_CUTTING_WORK_CODE_CANT_BE_NULL":
        this.setError("code", strings.stoneCuttingWorks.messages.STONE_CUTTING_WORK_CODE_CANT_BE_NULL);
        break;
    }
  }



  /** 
   * Handles autocomplete change event
   * Triggers when user selects something from dropdown menu
   * to fill info (disabled) fields 
   */
  onAutocompleteChange = (event, values) => {
    let autocompleteName = event.target.id.split("-")[0];
    this.setState({
      data: {
        ...this.state.data, [autocompleteName]: values
      }
    });

    if (autocompleteName === "citizen" && values != null) {
      getCitizenByPersonalNumber(values.personalNumber).then(response => {
        this.setState({
          citizen: {
            citizenNameSurname: response.data.nameSurname,
            citizenAddress: response.data.address,
            citizenSettlement: response.data.municipality,
            citizenTelephone: response.data.telephone
          }
        });
      });
    }
  }

  /** 
   * Handles autocomplete type input change event
   * When user typed in 2+ characters it searches for results to fill dropdown 
   */
  handleTextInputChange = (e) => {
    if (e.target.name === "stonecutter" && e.target.value.trim().length >= 2) {
      let data = {
        searchParam: e.target.value,
        companyId: this.props.cid,
        sort: this.state.sortBy,
        userId: 0
      };

      {
        this.props.cid != undefined &&
          getStonecutters(data).then(response => {
            if (!response.ok) {
              return;
            }
            this.setState({
              stonecutters: response.data.entities,
            });
          });
      }
    } else if (e.target.name === "mainBookOfBuried" && e.target.value.trim().length >= 2) {
      let data = {
        searchParam: e.target.value,
        companyId: this.props.cid,
        sort: "mainBookOfBuries,ASC",
        year: -1,
        month: -1,
        generate: false,
        graveyard: -1,
        userId: 0
      };

      {
        this.props.cid != undefined &&
          getMainBooksOfBuried(data).then(response => {
            if (!response.ok) {
              return;
            }
            this.setState({
              mainBooksOfBuried: response.data.entities,
            });
          });
      }
    } else if (e.target.name === "graveyard" && e.target.value.trim().length >= 2) {
      let data = {
        searchParam: e.target.value,
        companyId: this.props.cid,
        sort: this.state.sortBy,
        userId: 0
      };

      {
        this.props.cid != undefined &&
          getGraveyards(data).then(response => {
            if (!response.ok) {
              return;
            }
            this.setState({
              graveyards: response.data.entities,
            });
          });
      }

      this.setState({
        graveSites: null,
        data: {
          ...this.state.data,
          graveSite: null
        }
      });

    } else if (e.target.name === "graveSite" && e.target.value.trim().length >= 2) {
      let data = {
        searchParam: e.target.value,
        companyId: this.props.cid,
        sort: "code,ASC",
        userId: 0,
        graveyardId: this.state.data.graveyard.id
      };

      this.setState({
        typedInGraveSite: e.target.value
      });

      {
        this.props.cid != undefined &&
          getGraveSitesWithSelectedGraveyard(data).then(response => {
            if (!response.ok) {
              return;
            }
            this.setState({
              graveSites: response.data.entities,
            });
          });
      }
    } else if (e.target.name === "citizen" && e.target.value.trim().length >= 2) {
      let data = {
        searchParam: e.target.value,
        companyId: this.props.cid,
        sort: this.state.sortBy,
        userId: 0
      };

      {
        this.props.cid != undefined &&
          getCitizens(data).then(response => {
            if (!response.ok) {
              return;
            }
            this.setState({
              citizens: response.data.entities,
            });
          });
      }
    }
  }

  render() {
    return (
      <Grid id="page" item md={12}>
        <div className="header">
          <h1>{strings.stoneCuttingWorks.editPageTitle}</h1>
        </div>

        <Paper className="paper">
          <StoneCuttingWorksForm
            onChange={this.changeData}
            onSubmit={this.submit}
            data={this.state.data}
            errors={this.state.errors}
            onCancel={this.props.onCancel}
            onAutocompleteChange={this.onAutocompleteChange}
            handleTextInputChange={this.handleTextInputChange}
            citizen={this.state.citizen}
            citizens={this.state.citizens == undefined ? [] : this.state.citizens}
            stonecutters={this.state.stonecutters == undefined ? [] : this.state.stonecutters}
            mainBooksOfBuried={this.state.mainBooksOfBuried == undefined ? [] : this.state.mainBooksOfBuried}
            graveyards={this.state.graveyards == undefined ? [] : this.state.graveyards}
            graveSites={this.state.graveSites == undefined ? [] : this.state.graveSites}
            flag={this.props.flag}
            typedInGraveSite={this.state.typedInGraveSite}
            keyPress={this.keyPress}
            keyPressBackspace={this.keyPressBackspace}
          />
        </Paper>

      </Grid>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      changeFullScreen: Actions.changeFullScreen
    },
    dispatch
  );
}

function mapStateToProps({ menuReducers, siteDataReducers }) {
  return { menu: menuReducers, siteData: siteDataReducers };
}

export default withSnackbar(
  withRouter(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(EditStoneCuttingWorks)
  )
);
