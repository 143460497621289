import {request} from "../../base/HTTP";
import HttpMethod from "../../constants/HttpMethod";

export async function getUserRoles(data) {
    return await request('/api/user_role/search', data);
}

export async function addUserRole(data, cid) {
    return await request('/api/user_role/' + cid, data, HttpMethod.POST);
}


export async function deleteUserRole(id, uid) {
    return await request('/api/user_role/' + id + '/' + uid, {} , HttpMethod.DELETE);
}

export async function editUserRole(data){
    return await request('/api/user_role/' + data.id, data, HttpMethod.PUT);
}

export async function getUserRoleById(id) {
    return await request("api/user_role/" + id , {}, HttpMethod.GET);
}
