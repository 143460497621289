import React from 'react'
import TablePage from "../../common/TablePage";
import { bindActionCreators } from "redux";
import * as Actions from "../../actions/Actions";
import { withRouter } from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import strings from "../../localization";
import { withSnackbar } from "notistack";
import { deleteMainBookOfBuried } from '../../services/mainBookOfBuried/MainBookOfBuriedService';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { Drawer, TextField, TableRow, TableBody, FormControlLabel, Switch } from "@material-ui/core";
import PageState from '../../constants/PageState';
import DrawerWrapper from '../../common/DrawerWrapper';
import update from 'immutability-helper';
import { getMainBookOfBuriedSelect } from '../../util/ReviewsUtil';
import { deleteScheduledCremation, generateScheduledCremationRequestPdf, getDeletedScheduledCremations, getScheduledCremations, restoreScheduledCremation } from '../../services/scheduledCremation/ScheduledCremationService';
import { getError, hasError, validatePersonalNumber } from '../../functions/Validation';
import { addCitizen } from '../../services/citizen/CitizenService';
import { getStates } from '../../services/state/StateService';
import CitizenForm from '../../components/forms/citizen/CitizenForm';
import { getPostalCodesImproved } from '../../services/postalCodeImproved/PostalCodeImprovedService';
import { getPostalAddressCodesWithParams } from '../../services/postalAddressCodeImproved/PostalAddressCodeServiceImproved';
import DatePickerControl from '../../components/controls/DatePickerControl';
import { dateToString2 } from '../../util/DateUtil';
import AddScheduledCremation from './AddScheduledCremation';
import ViewScheduledCremation from './ViewScheduledCremation';
import EditScheduledCremation from './EditScheduledCremation';

import IconButton from "@material-ui/core/IconButton";
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';

class ScheduledCremationList extends TablePage {

  tableDescription = [
    { key: 'funeralNumber', label: strings.cremationScheduling.funeralNumber, sort: 'funeralNumber' },
    { key: 'deceasedNameSurname', label: strings.cremationScheduling.deceasedNameSurname, sort: 'deceasedNameSurname' },
    { key: 'graveyardName', label: strings.cremationScheduling.graveyard, sort: 'graveyardCode' },
    { key: 'graveSiteCode', label: strings.cremationScheduling.graveSite, sort: 'graveSiteCode' },
    { key: 'responsiblePersonNameSurname', label: strings.cremationScheduling.responsiblePerson },
    { key: 'responsiblePersonEditedByNameSurname', label: strings.cremationScheduling.responsiblePersonЕditedBy },
    { key: 'dateTimeFuneral', label: strings.cremationScheduling.dateTimeFuneral, transform: 'renderColumnDateTime', sort: 'dateTimeFuneral' },
    { key: 'dateCreated', label: strings.cremationScheduling.dateCreated, transform: 'renderColumnDate', sort: 'dateCreated' },
  ];

  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
      selectMainBookOfBuried: [],
      showCitizen: false,
      checked: false,
      data: {
        selectedMainBookOfBuriedReview: { id: -1 },
        year: { value: -1 },
        month: { id: -1 },
        graveyard: { id: -1 },
      },
      errors: {},
      companyId: this.props.auth.user.company.id,
    };

    this.state.sortBy = "dateCreated,DESC";

    this.changeData = this.changeData.bind(this);
    this.changeDataCitizen = this.changeDataCitizen.bind(this);
    this.renderAddCitizen = this.renderAddCitizen.bind(this);
    this.changeStateShowCitizen = this.changeStateShowCitizen.bind(this);
    this.submit = this.submit.bind(this);
    this.changeTypedInPersonalNumber = this.changeTypedInPersonalNumber.bind(this);
    this.state.showDeleted = false;
  }


  fetchData() {
    let data = {
      searchParam: this.state.searchData.search,
      page: this.state.searchData.page - 1,
      size: this.state.searchData.perPage,
      companyId: this.props.auth.user.company.id,
      filterDate: this.state.data.filterDate ? dateToString2(this.state.data.filterDate) : "",
      sort: this.state.sortBy,
      userId: this.props.auth.user.id,
    };

    {
      !this.state.showDeleted && 
      getScheduledCremations(data).then(response => {
        if (!response.ok) {
          return;
        }
        this.setState({
          tableData: response.data.entities,
          total: response.data.total,
        });
      }); 
    }

    {
      this.state.showDeleted && 
      getDeletedScheduledCremations(data).then(response => {
        if (!response.ok) {
          return;
        }
        this.setState({
          tableData: response.data.entities,
          total: response.data.total,
        });
      }); 
    }
  }

  handleDeletedChange(){
    this.setState({
      showDeleted : !this.state.showDeleted
    },() =>{ this.fetchData(); })
  }

  usePermissions(){
    let data = {
      userId: this.props.auth.user.id,
      category: "Главна књига сахрањених"
    }
    this.getPermissionsS(data);
    this.removedItemsShow(this.props.removedItemsShow);

  }

  componentDidMount() {
    this.usePermissions()
    this.setState({
      companyId: this.props.auth.user.company.id,
      selectMainBookOfBuried: getMainBookOfBuriedSelect()
    }, () => {
      this.fetchData();
      this.state.selectMainBookOfBuried.unshift({ id: -1, name: strings.reviewMainBookOfBuried.all });
    });
  }

  componentWillReceiveProps(nextProps) {
    strings.setLanguage(nextProps.auth.user.language);
    this.tableDescription = [
      { key: 'funeralNumber', label: strings.cremationScheduling.funeralNumber, sort: 'funeralNumber' },
      { key: 'deceasedNameSurname', label: strings.cremationScheduling.deceasedNameSurname, sort: 'deceasedNameSurname' },
      { key: 'graveyardName', label: strings.cremationScheduling.graveyard, sort: 'graveyardCode' },
      { key: 'graveSiteCode', label: strings.cremationScheduling.graveSite, sort: 'graveSiteCode' },
      { key: 'responsiblePersonNameSurname', label: strings.cremationScheduling.responsiblePerson, sort: 'responsiblePersonNameSurname' },
      { key: 'responsiblePersonEditedByNameSurname', label: strings.cremationScheduling.responsiblePersonЕditedBy },
      { key: 'dateTimeFuneral', label: strings.cremationScheduling.dateTimeFuneral, transform: 'renderColumnDateTime', sort: 'dateTimeFuneral' },
      { key: 'dateCreated', label: strings.cremationScheduling.dateCreated, transform: 'renderColumnDate', sort: 'dateCreated' },
    ];
  }

  getPageHeader() {
    return <h1>{strings.scheduledCremationList.pageTitle}</h1>;
  }

  renderAddContent() {
    return <AddScheduledCremation
      cid={this.state.companyId}
      uid={this.props.auth.user.id}
      uemail={this.props.auth.user.email}
      onCancel={this.onCancel}
      onFinish={this.onFinish}
      flag={"add"}
      flagAddCitizen={this.renderAddCitizen}
      handler={this.changeTypedInPersonalNumber}
      responsiblePersonId={this.props.auth.user.id}
      responsiblePersonEditedById={this.props.auth.user.id}
    />
  }

  renderEditContent() {
    return <EditScheduledCremation
      id={this.state.selectedItem.id}
      uemail={this.props.auth.user.email}
      cid={this.state.companyId}
      onCancel={this.onCancel}
      onFinish={this.onFinish}
      isDisabled={false}
      flag={"edit"}
      flagAddCitizen={this.renderAddCitizen}
      handler={this.changeTypedInPersonalNumber}
      responsiblePersonId={this.props.auth.user.id}
      responsiblePersonEditedById={this.props.auth.user.id}
    />
  }

  renderViewContent() {
    return <ViewScheduledCremation
      id={this.state.selectedItem.id}
      isDisabled={true}
      onCancel={this.onCancel}
      onFinish={this.onFinish}
      flag={"view"}
    />
  }

  delete(item) {

    this.setState({
      lockTable: true
    });


    deleteScheduledCremation(item.id, this.props.auth.user.id).then(response => {

      if (response && !response.ok) {
        this.onFinish(null);
        return;
      }

      this.props.enqueueSnackbar(strings.companyList.scheduledCremationDeleted, { variant: 'success' });

      this.onFinish(item);
      this.cancelDelete();

      this.setState({
        lockTable: false
      });
    });
  }

  restore(item){
    
    restoreScheduledCremation(this.transformRequest(item)).then(response => {
      if (response && !response.ok) {
        this.onFinish(null);
        return;
      }
      this.setState({
        showDeleted : !this.state.showDeleted,
      }, () => { this.fetchData(); })
    })
  }

  transformRequest(data) {
    return {
      ...data,
      userEmail: this.props.auth.user.email,
      companyId: this.state.companyId
    }
  }

  changeData(event, data = 'data') {
    this.setState({
      [data]: update(this.state[data], { [event.target.name]: { $set: event.target.value } })
    }, () => {
      this.fetchData();
    });
  }

  renderCitizenForm() {
    return <Grid id='page' item md={12}>

      <div className='header'>
        <h1>{strings.citizen.pageTitle}</h1>
      </div>

      <Paper className='paper'>
        <CitizenForm
          onChange={this.changeDataCitizen}
          onSubmit={this.submit}
          data={this.state.data}
          errors={this.state.errors}
          onCancel={this.changeStateShowCitizen}
          handleChange={this.handleChange}
          onAutocompleteChange={this.onAutocompleteChange}
          handleTextInputChange={this.handleTextInputChange}
          onChangePersonalNumberField={this.onChangePersonalNumberField}
          flag={"add"}
          states={this.state.stateCodes == undefined ? [] : this.state.stateCodes}
          citiesAndZips={this.state.zip == undefined ? [] : this.state.zip}
        />
      </Paper>

    </Grid>
  }

  changeStateShowCitizen() {
    this.setState({
      ...this.state,
      showCitizen: false,
      data: {
        personalNumber: "",
        name: "",
        postalCode: "",
        street: "",
        streetNumber: "",
        surname: "",
        telephone: "",
      },
      errors: {},
    });
  }

  changeDataCitizen(event, data = 'data') {
    this.setState({
      [data]: update(this.state[data], { [event.target.name]: { $set: event.target.value } })
    }, () => {
      this.fetchData();
    });
  }

  getPucs () {
    let d = {
      street: this.state.data.street ? this.state.data.street : "", 
      companyId: this.state.companyId,
      sort: "pac,ASC",
      userId: 0,
      stateId: this.state.data.state ? this.state.data.state.id : 0,
      postalCodeImprovedId: this.state.data.postalCodeImproved ? this.state.data.postalCodeImproved.id : 0,
      streetNumber: this.state.data.streetNumber ? this.state.data.streetNumber : "",
    }   
      d.companyId != undefined &&
        getPostalAddressCodesWithParams(d).then(response =>{
        if(!response.ok){
          return;
        }
         this.setState({
          data: {
            ...this.state.data,
            pac: response.data.pac,

          }
          
        })
      });
  }

  handleTextInputChange = (e) => {
    if (e.target.name === "state" && e.target.value.trim().length >= 2) {
      let data = {
        searchParam: e.target.value,
        companyId: this.props.auth.user.company.id,
        sort: "name,ASC",
        userId: 0
      };

      {
        data.companyId != undefined &&
          getStates(data).then(response => {
            if (!response.ok) {
              return;
            }
            this.setState({
              stateCodes: response.data.entities,
            });
          });
      }
    }
    else if (e.target.name === "postalCodeImproved" && e.target.value.trim().length >= 2) {
      let data = {
        searchParam: e.target.value,
        companyId: this.props.auth.user.company.id,
        sort: 'city',
        userId: 0
      };

      {
        data.companyId != undefined &&
        getPostalCodesImproved(data).then(response => {
          if (!response.ok) {
            return;
          }
          this.setState({
            zip: response.data.entities,
          }, () => {
            this.getPucs()
          });
        });
      }
    }
    else if (e.target.name === "street") {
      this.setState({
        data: {
          ...this.state.data,
          street: e.target.value
        }
      }, () => {
        this.getPucs()
      });
    }
    else if (e.target.name === "streetNumber") {
      this.setState({
        data: {
          ...this.state.data,
          streetNumber: e.target.value
        }
      }, () => {
        this.getPucs()
      });
    }
  }

  onAutocompleteChange = (event, values) => {
    let autocompleteName = event.target.id.split("-")[0];
    this.setState({
      data: {
        ...this.state.data, [autocompleteName]: values
      }
    }, () => {
      this.getPucs()
    });
  }

  onChangePersonalNumberField = (event) => {
    let validPersonalNumber = validatePersonalNumber(event.target.value)

    if (!validPersonalNumber) {
      this.setError("personalNumber", strings.citizen.messages.CITIZEN_PERSONAL_NUMBER_NOT_VALID);
    }
    else {
      this.unsetError("personalNumber");
    }
    this.changeDataCitizen(event);
  }

  changeTypedInPersonalNumber(someValue) {
    this.setState({
      data: {
        personalNumber: someValue,
      },
    });
  }

  handleChange = event => {
    this.setState({ checked: event.target.checked }, () => {
      let validPersonalNumber = validatePersonalNumber(this.state.data.personalNumber)

      if (!validPersonalNumber) {
        this.setError("personalNumber", strings.citizen.messages.CITIZEN_PERSONAL_NUMBER_NOT_VALID);
      }
      else {
        this.unsetError("personalNumber");
      }
    })
  }

  submit() {
    if (!this.validate()) {
      return;
    }

    this.showDrawerLoader();
    
    addCitizen(this.transformRequestCitizen(this.state.data), this.state.companyId).then(response => {
      if (!response.ok) {
        let messageKey = response.response.data.message;
        this.props.enqueueSnackbar(strings.citizen.messages[messageKey], {
          variant: "error"
        });

        this.handleError(messageKey);

        return;
      }

      this.props.enqueueSnackbar(strings.addCompany.citizenAdded, { variant: 'success' });

      this.hideDrawerLoader();
      this.changeStateShowCitizen();
    });
  }

  transformRequestCitizen(data) {
    return {
      ...data,
      deceased: this.state.checked,
      userEmail: this.props.auth.user.email,
      stateCode: this.state.data.state ? this.state.data.state.id : null ,
      postalCodeImprovedId: this.state.data.postalCodeImproved ? this.state.data.postalCodeImproved.id : null
    }
  }

  renderAddCitizen() {
    this.setState({
      showCitizen: true,
    });
  }

  renderFilters() {
    return (
      <div className="renderFilters">
        <DatePickerControl
          label={strings.cremationScheduling.filterDate}
          error={getError(this.state.errors, 'filterDate')}
          hasError={hasError(this.state.errors, 'filterDate')}
          helperText={getError(this.state.errors, 'filterDate')}
          placeholder={strings.cremationScheduling.filterDate}
          name='filterDate'
          onChange={this.changeData}
          date={this.state.data.filterDate ? new Date(this.state.data.filterDate) : this.state.data.filterDate}
          isClearable={true}
          dateFormat='dd/MM/yyyy'
          disabled={false}
        />
      </div>
    );
  }

  handleGeneratePdf() {
    this.setState({
        loading: true,
    });

    let data = {
      scheduledCremations: this.state.tableData,
    }

    generateScheduledCremationRequestPdf(data).then(response => {
        const blob = new Blob([response.data], { type: 'application/pdf' });

        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        
        link.download = `Zahtev-za-kremiranje.pdf`;

        document.body.appendChild(link);

        link.click();

        document.body.removeChild(link);

        this.setState({
            loading: false,
        });
    });
  }

  renderTableRow(data = []) {

    let result = [];
    let expiryDate;
    let currentDate = new Date();
    let expiryDateIncremented;

    for (let item of data) {

      expiryDate = item.expiryDate != null ? new Date(item.expiryDate) : "";
      expiryDateIncremented = expiryDate;

      let className = 'table-row-' + result.length % 2;

      if (this.isRowSelected(item)) {
        className += ' selected';
      }

      if (item.dateTimeFuneral != null) {
        const now = new Date();
        const dateTimeFuneralCompleted = new Date(item.dateTimeFuneral);
        dateTimeFuneralCompleted.setHours(dateTimeFuneralCompleted.getHours() + 1);

        if (dateTimeFuneralCompleted <= now) {
          className = 'backgroundColorFuneralCompleted';
        }
      }

      result.push(
        <TableRow key={'table-row-' + result.length} className={className} onClick={() => this.selectRow(item)}>
          {this.renderRowMenu(result.length, item)}
          {this.renderTableRowData(item)}
        </TableRow>
      )
    }

    return (
      <TableBody>
        {result}
      </TableBody>
    );

  }

  render() {

    return (
      <Grid id='table-page'>
        {
          this.state.showCitizen &&
          <Drawer id='drawer' anchor='left' open={this.showDrawer()} onClose={() => this.changeStateShowCitizen()} ModalProps={{disableBackdropClick: true,}} >
            <DrawerWrapper onBack={() => this.changeStateShowCitizen()}>
              {this.renderCitizenForm()}
            </DrawerWrapper>
          </Drawer>
        }

        {this.renderDialog(strings.table.confirmDelete, strings.delete.confirmation,
          this.cancelDelete, this.delete)}
        <div className='header'>
          {this.getPageHeader()}

          <div>
            {this.state.removedItemsShow &&
              <FormControlLabel
                control = {<Switch
                checked={this.state.showDeleted}
                onChange={this.handleDeletedChange}
                inputProps={{ 'aria-label': 'controlled' }}
                />}
                labelPlacement="start"
                label={!this.state.showDeleted ? "Прикажи обрисане" : "Прикажи необрисане"}
              />
            }
          </div> 

          <div className='filter-controls'>
            {
              this.renderFilters()
            }
            {
              this.state.showSearch &&
              <TextField
                label={strings.table.search}
                type="search"
                name='search'
                value={this.state.searchData.search}
                onChange={this.searchChanged}
              />
            }

            {
              this.state.showAdd &&
              this.renderTableControls()
            }
          </div>
        </div>
        <Paper md={12}>
          {this.renderTable(this.state.tableData)}
        </Paper>

        <Drawer id='drawer' anchor='right' open={this.showDrawer()} onClose={() => this.setPageState(PageState.View)} ModalProps={{disableBackdropClick: true,}} >
          <DrawerWrapper onBack={() => this.setPageState(PageState.View)}>
            {this.renderDrawerContent()}
          </DrawerWrapper>
        </Drawer>

        <div style={{ display: 'flex', justifyContent: 'end' }}>
          <IconButton disabled={this.state.tableData.length <= 0} onClick={() => this.handleGeneratePdf()}>
            {this.state.loading 
                ? (<CircularProgress size={24} color="inherit" />) 
                : (<InsertDriveFileIcon />)
            }
            <Typography variant="button">{strings.review.buttons.generatePDF}</Typography>
          </IconButton>
        </div>
      </Grid>
    );
  }

}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    changeFullScreen: Actions.changeFullScreen
  }, dispatch);
}

function mapStateToProps({ menuReducers, authReducers, siteDataReducers }) {
  return { menu: menuReducers, auth: authReducers, siteData: siteDataReducers };
}

export default withSnackbar(withRouter(connect(mapStateToProps, mapDispatchToProps)(ScheduledCremationList)));