import FormComponent from "../../common/FormComponent";
import React from "react";
import { bindActionCreators } from "redux";
import * as Actions from "../../actions/Actions";
import { withRouter } from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import Grid from "@material-ui/core/Grid";
import Paper from '@material-ui/core/Paper';
import strings from "../../localization";
import { withSnackbar } from "notistack";

import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import ExhumationRequestForm from "../../components/forms/exhumationRequest/ExhumationRequestForm";
import { getExhumationRequestById } from "../../services/exhumationRequest/ExhumationRequestService";
import { getExhumationGraveSiteTypes } from "../../util/ReviewsUtil";

function TabContainer(props) {
  return (
    <Typography className="tabContainer" component="div">
      {props.children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

class ViewExhumationRequest extends FormComponent {

  constructor(props) {
    super(props);

    this.state = {
      data: props.data ? props.data : {},
      errors: {},
      value: 0,
      dateFlag: "",
      dateOfBirthWhenValidIDNumber: [],
      graveSiteType: [],
      graveSiteTypes: [],
    };

    this.props.changeFullScreen(false);
  }

  componentDidMount() {
    getExhumationRequestById(this.props.id).then(response => {
      this.setState({
        data: response.data,
        graveSiteTypes: getExhumationGraveSiteTypes(),
      }, () => {

        this.setState({
          graveSiteType: this.state.graveSiteTypes.find(type => type.value === this.state.data.graveSiteType),
        });
      });
    });
  }

  handleChangeTab = (event, value) => {
    this.setState({
      value: value
    });
  };

handleGraveSiteTypeCheckboxChange = (type, id, value) => (event) => {
    const checked = event.target.checked;
    if (checked) {
        this.setState({
            graveSiteType: { id, value: value, name: type}
        });
    }
  };

  render() {
    return (
      <Grid id='page' item md={12}>

        <div className='header'>
            <h1>{strings.exhumationRequest.viewPageTitle}</h1>
        </div>

        <Paper className='paper'>
            <ExhumationRequestForm
                onChange={this.changeData}
                onSubmit={this.submit}
                data={this.state.data}
                errors={this.state.errors}
                onCancel={this.props.onCancel}
                handleChange={this.handleChange}
                flag={this.props.flag}
                date={(new Date().getFullYear())}
                disabled={this.state.disabled}
                graveSiteType={this.state.graveSiteType}
                graveSiteTypes={this.state.graveSiteTypes}
                handleGraveSiteTypeCheckboxChange={this.handleGraveSiteTypeCheckboxChange}
                isDisabled={this.props.isDisabled}
            />
        </Paper>
      </Grid>
    );
  }
}

function mapDispatchToProps(dispatch) {

  return bindActionCreators(
    {
      changeFullScreen: Actions.changeFullScreen
    },
    dispatch
  );
}

function mapStateToProps({ menuReducers, siteDataReducers }) {

  return { menu: menuReducers, siteData: siteDataReducers };
}

export default withSnackbar(
  withRouter(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(ViewExhumationRequest)
  )
);
