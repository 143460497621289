import { request } from "../../base/HTTP";
import HttpMethod from "../../constants/HttpMethod";

export async function getAllAids() {
    return await request("api/aid/all");
}

export async function getAids(data) {
    return await request('/api/aid/search', data);
}

export function getAidById(id) {
    return request("api/aid/" + id, {}, HttpMethod.GET);
}

export async function addAid(data, cid) {
    return await request('/api/aid/' + cid, data, HttpMethod.POST);
}

export async function editAid(data) {
    return await request("api/aid/" + data.id, data, HttpMethod.PUT);
}

export async function deleteAid(id, uid) {
    return await request('/api/aid/' + id + '/' + uid, {}, HttpMethod.DELETE);
}

export async function getDeletedAids(data){
    return await request('/api/aid/deleted', data)
}

export async function restoreAid(data) {
    return await request("api/aid/restore/" + data.id, data, HttpMethod.PUT);
}