import React from 'react';
import FormComponent from '../../common/FormComponent';
import Validators from '../../constants/ValidatorTypes';
import { addCrate } from '../../services/crate/CrateService';
import strings from '../../localization';
import * as Actions from '../../actions/Actions';
import Grid from '@material-ui/core/Grid';
import { Paper } from '@material-ui/core';
import CrateForm from '../../components/forms/crate/CrateForm';
import { withSnackbar } from 'notistack';
import { withRouter } from "react-router-dom";
import connect from 'react-redux/es/connect/connect';
import { bindActionCreators } from 'redux';

class AddCrate extends FormComponent {
  validationList = {
    name: [{ type: Validators.REQUIRED }]
  };

  constructor(props) {
    super(props);

    this.state = {
      data: props.data ? props.data : {},
      errors: {}
    };

    this.props.changeFullScreen(false);
    this.submit = this.submit.bind(this);
  }

  submit() {
    if (!this.validate()) {
      return;
    }

    this.showDrawerLoader();

    addCrate(this.transformRequest(this.state.data), this.props.cid).then(response => {
      if (!response.ok) {
        let messageKey = response.response.data.message;
        this.props.enqueueSnackbar(strings.crate.messages[messageKey], { variant: 'error' });
        this.handleError(messageKey);
        return;
      }

      this.props.enqueueSnackbar(strings.addCrate.added, { variant: 'success' });
      this.props.onFinish(response.data);
      this.hideDrawerLoader();
    });
  }

  transformRequest(data) {
    return {
      ...data,
      userEmail: this.props.uemail,
      companyId: this.props.cid
    }
  }

  handleError(message) {
    switch (message) {
      case "CRATE_NAME_CANT_BE_NULL":
        this.setError("name", strings.crate.messages.CRATE_NAME_CANT_BE_NULL);
        break;

      case "CRATE_NOT_EXIST":
        this.setError("name", strings.crate.messages.CRATE_NOT_EXIST);
        break;

      case "CRATE_WITH_NAME_ALREADY_EXIST":
        this.setError("name", strings.crate.messages.CRATE_WITH_NAME_ALREADY_EXIST);
        break;
    }
  }

  render() {

    return (
      <Grid id='page' item md={12}>
        <div className='header'>
          <h1>{strings.addCrate.pageTitle}</h1>
        </div>

        <Paper className='paper'>
          <CrateForm
            onChange={this.changeData}
            onSubmit={this.submit}
            onCancel={this.props.onCancel}
            data={this.state.data}
            errors={this.state.errors}
          />
        </Paper>
      </Grid>
    );
  }

}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    changeFullScreen: Actions.changeFullScreen
  }, dispatch);
}

function mapStateToProps({ menuReducers, siteDataReducers }) {
  return { menu: menuReducers, siteData: siteDataReducers };
}

export default withSnackbar(withRouter(connect(mapStateToProps, mapDispatchToProps)(AddCrate)));
