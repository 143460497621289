import React from 'react'
import { bindActionCreators } from "redux";
import * as Actions from "../../actions/Actions";
import { withRouter } from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import Grid from '@material-ui/core/Grid';
import { Paper } from "@material-ui/core";
import strings from "../../localization";
import FormComponent from "../../common/FormComponent";
import { withSnackbar } from "notistack";
import { getCitizenByPersonalNumber, getCitizens, getCitizenById, getLeaserByGraveSiteId } from '../../services/citizen/CitizenService';
import { getGraveyards, getGraveyardById } from '../../services/graveyard/GraveyardService';
import { getGraveSiteById, getGraveNotTakenSites, getGraveSitesWithSelectedGraveyard, editGraveSite } from '../../services/graveSite/GraveSiteService';
import { getRenewalById, editRenewal } from '../../services/renewal/RenewalService';
import RenewalForm from '../../components/forms/renewal/RenewalForm';
import Validators from '../../constants/ValidatorTypes';
import { editEditedRenewal, getEditedRenewalById } from '../../services/renewalEdited/RenewalEditedService';
import RenewalEditedForm from '../../components/forms/renewalEdited/RenewalEditedForm';
import { validateDateFromRenewalPaymentSlipAndDate } from '../../functions/Validation';
import { isNumeric, isInt } from "../../util/DataValidation";

class EditRenewal extends FormComponent {

  validationList = {
    amount: [{ type: Validators.REQUIRED }, { type: Validators.IS_NEGATIVE_NUMBER }],
  };

  constructor(props) {
    super(props);

    this.state = {
      data: props.data ? props.data : {},
      errors: {},
      submitter: {},
    };

    this.props.changeFullScreen(false);

    this.submit = this.submit.bind(this);
    this.transformModifiedData = this.transformModifiedData.bind(this);

  }

  componentDidMount() {
    if (this.props.modifiedData) {
      {
        this.state.data.submitterId &&
          getCitizenById(this.state.data.submitterId).then(response => {
            this.setState({
              data: {
                ...this.state.data,
                submitter: response.data
              },
              submitter: {
                nameSurnameSubmitter: response.data.name + " " + response.data.surname,
                addressSubmitter: response.data.street + " " + response.data.streetNumber,
                postalCode: response.data.postalCodeImprovedZip,
                telephone: response.data.telephone,
                personalNumber: response.data.personalNumber,
              },
            });
          });
      }
      {
        this.state.data.costId &&
          getCitizenById(this.state.data.costId).then(response =>{
            this.setState({
              data:{ 
                ...this.state.data,
                cost: response.data
              },
              cost: {
                nameSurname: response.data.name + " " + response.data.surname,
                address: response.data.street + " " + response.data.streetNumber,
                municipality: response.data.postalCodeImprovedZip,
              }
            });
          });
      }
      {
        this.state.data.graveyardId &&
          getGraveyardById(this.state.data.graveyardId).then(response => {
            this.setState({
              data: {
                ...this.state.data,
                graveyard: response.data
              },
            });
          });
      }
      {
        this.state.data.graveSiteId &&
          getGraveSiteById(this.state.data.graveSiteId).then(response => {
            this.setState({
              data: {
                ...this.state.data,
                graveSite: response.data,
              },
            });
          })
      }
    } else {
      getEditedRenewalById(this.props.id).then(response => {
        this.setState({
          data: response.data
        }, () => {
  
          {
            this.state.data.submitterId &&
              getCitizenById(this.state.data.submitterId).then(response => {
                this.setState({
                  data: {
                    ...this.state.data,
                    submitter: response.data
                  },
                  submitter: {
                    nameSurnameSubmitter: response.data.name + " " + response.data.surname,
                    addressSubmitter: response.data.street + " " + response.data.streetNumber,
                    postalCode: response.data.postalCodeImprovedZip,
                    telephone: response.data.telephone,
                    personalNumber: response.data.personalNumber,
                  },
                });
              });
          }
          {
            this.state.data.costId &&
              getCitizenById(this.state.data.costId).then(response =>{
                this.setState({
                  data:{ 
                    ...this.state.data,
                    cost: response.data
                  },
                  cost: {
                    nameSurname: response.data.name + " " + response.data.surname,
                    address: response.data.street + " " + response.data.streetNumber,
                    municipality: response.data.postalCodeImprovedZip,
                  }
                });
              });
          }
          {
            this.state.data.graveyardId &&
              getGraveyardById(this.state.data.graveyardId).then(response => {
                this.setState({
                  data: {
                    ...this.state.data,
                    graveyard: response.data
                  },
                });
              });
          }
          {
            this.state.data.graveSiteId &&
              getGraveSiteById(this.state.data.graveSiteId).then(response => {
                this.setState({
                  data: {
                    ...this.state.data,
                    graveSite: response.data,
                  },
                });
              })
          }
        });
      });
    }
  }

  submit() {
    if (!this.validate()) {
      this.props.enqueueSnackbar(strings.renewal.validationЕrror, { variant: 'error' });
      return;
    }

    this.showDrawerLoader();

    if (this.props.modifiedData) {
      this.props.enqueueSnackbar(strings.addCompany.renewalEdited, { variant: 'success' });

      this.props.onFinish(this.transformModifiedData(this.state.data));

      this.hideDrawerLoader();
      return;
    }

    editGraveSite(this.transformRequestEditGraveSite(this.state.data.graveSite)).then(() => {
      editEditedRenewal(this.transformRequest(this.state.data), this.props.cid).then(response => {
        if (!response.ok) {
          let messageKey = response.response.data.message;
  
          this.props.enqueueSnackbar(strings.renewal.messages[messageKey], {
            variant: "error"
          });
  
          this.handleError(messageKey);
  
          return;
        }
  
        this.props.enqueueSnackbar(strings.addCompany.renewalEdited, { variant: 'success' });
        this.props.onFinish(response.data);
  
        this.hideDrawerLoader();
      });
    });
  }

  transformRequest(data) {
    return {
      ...data,
      userEmail: this.props.uemail,
      companyId: this.props.cid,
      submitterId: this.state.data.submitter ? this.state.data.submitter.id : null,
      graveyardId: this.state.data.graveyard ? this.state.data.graveyard.id : null,
      graveSiteId: this.state.data.graveSite ? this.state.data.graveSite.id : null,
      graveyard: this.state.data.graveyard ? this.state.data.graveyard.name : null,
      graveSite: this.state.data.graveSite ? this.state.data.graveSite.code : null,
      expiryDate: this.state.data.newExpirationDate ? this.state.data.newExpirationDate : (this.state.data.expiryDate ? this.state.data.expiryDate : this.state.data.graveSite.expirationDate),
    }
  }

  transformRequestEditGraveSite(graveSite) {
    return {
      ...graveSite,
      userEmail: this.props.uemail,
      companyId: this.props.cid,
      identificationNumber: this.state.submitter.personalNumber, 
      expirationDate: this.state.data.newExpirationDate ? this.state.data.newExpirationDate : this.state.data.graveSite.expirationDate,
    }
  }

  transformModifiedData(data) {
    return {
      ...data,
      cost: null,
      submitterId: this.state.data.submitter ? this.state.data.submitter.id : null,
      submitterName: this.state.data.submitter ? this.state.data.submitter.name : null,
      submitterString: this.state.data.submitter ? this.state.data.submitter.personalNumber : null,
      submitterSurname: this.state.data.submitter ? this.state.data.submitter.surname : null,
      graveyard: this.state.data.graveyard ? this.state.data.graveyard.name : null,
      graveSite: null,
      dateEdited: new Date(),
    }
  }

  handleError(message) {
    switch (message) {
      case "RENEWAL_DATE_TIME_OF_DEATH_CANT_BE_NULL":
        this.setError("paymentNumber", strings.reservation.messages.RENEWAL_DATE_TIME_OF_DEATH_CANT_BE_NULL);
        break;
    }
  }

  handleChange = (event) => {
    this.setState({
      data: {
        ...this.state.data, [event.target.name]: event.target.value
      }
    })
  };

  /** 
   * Handles autocomplete change event
   * Triggers when user selects something from dropdown menu
   * to fill info (disabled) fields 
   */
  onAutocompleteChange = (event, values) => {
    let autocompleteName = event.target.id.split("-")[0];
    this.setState({
      data: {
        ...this.state.data, [autocompleteName]: values
      }
    });

    if (autocompleteName === "submitter" && values != null) {
      getCitizenByPersonalNumber(values.personalNumber).then(response => {
        this.setState({
          submitter: {
            personalNumber: response.data.personalNumber,
            nameSurnameSubmitter: response.data.nameSurname,
            addressSubmitter: response.data.address,
            postalCode: response.data.municipality,
            telephone: response.data.telephone
          }
        });
      });
    }

    if (autocompleteName === "graveSite" && values != null) {
      this.setState({
        data: {
          ...this.state.data,
          graveSite: values,
          expiryDate: values.expirationDate,
          expiryDateString: values.expirationDateString
        }
      }, () => {
        if (values.identificationNumber != null) {
          getCitizenByPersonalNumber(values.identificationNumber).then(response => {
            this.setState({
              submitter: {
                personalNumber: response.data.personalNumber,
                nameSurnameSubmitter: response.data.nameSurname,
                addressSubmitter: response.data.address,
                postalCode: response.data.municipality,
                telephone: response.data.telephone
            }});
          });
        } else {
          getLeaserByGraveSiteId(values.id).then(response => {
            this.setState({
              submitter: {
                personalNumber: response.data.personalNumber ? response.data.personalNumber : "",
                nameSurnameSubmitter: response.data.name && response.data.surname ? response.data.name + " " + response.data.surname : "",
                addressSubmitter: response.data.fullAddress ? response.data.fullAddress : "",
                postalCode: response.data.postalCodeImprovedZip ? response.data.postalCodeImprovedZip : "",
                telephone: response.data.telephone ? response.data.telephone : ""
            }});
          });
        }
      });
    }else if(autocompleteName === "cost" && values != null){
      getCitizenByPersonalNumber(values.personalNumber).then(response =>{
          this.setState({
              data: {
                ...this.state.data,
                costId: response.data.id,
              },
              cost: {
                  personalNumber: response.data.personalNumber,
                  nameSurname: response.data.nameSurname,
                  address: response.data.address,
                  municipality: response.data.municipality,
              }
          })
      })
    }
  }

  /** 
   * Handles autocomplete type input change event
   * When user typed in 2+ characters it searches for results to fill dropdown 
   */
  handleTextInputChange = (e) => {
    if (e.target.name === "submitter" && e.target.value.trim().length >= 2) {
      let data = {
        searchParam: e.target.value,
        companyId: this.props.cid,
        sort: "personalNumber,ASC",
        userId: 0
      };

      {
        this.props.cid != undefined &&
          getCitizens(data).then(response => {
            if (!response.ok) {
              return;
            }
            this.setState({
              submitters: response.data.entities,
            });
          });
      }
    } else if (e.target.name === "graveyard" && e.target.value.trim().length >= 2) {
      let data = {
        searchParam: e.target.value,
        companyId: this.props.cid,
        sort: "name,ASC",
        userId: 0
      };

      {
        this.props.cid != undefined &&
          getGraveyards(data).then(response => {
            if (!response.ok) {
              return;
            }
            this.setState({
              graveyards: response.data.entities,
            });
          });
      }
      this.setState({
        submitter: null,
        cost: null,
        graveSites: null,
        data: {
          ...this.state.data,
          expiryDate: null,
          graveSite: null,
          newExpirationDate: null,
          renewalYears: 0,
        }
      });
    } else if (e.target.name === "graveSite" && e.target.value.trim().length >= 2) {
      let data = {
        searchParam: e.target.value,
        companyId: this.props.cid,
        sort: "code,ASC",
        userId: 0,
        graveyardId: this.state.data.graveyard.id
      };

      {
        this.props.cid != undefined &&
          getGraveSitesWithSelectedGraveyard(data).then(response => {
            if (!response.ok) {
              return;
            }
            this.setState({
              graveSites: response.data.entities,
            });
          });
      }
      this.setState({
        submitter: null,
        cost: null,
        data: {
          ...this.state.data,
          expiryDate: null,
          newExpirationDate: null,
          renewalYears: 0,
        }
      });
    } else if (e.target.name == "renewalYears") {

      var expiryDateYear = (new Date(this.state.data.expiryDate)).getFullYear()
      var expiryDateMonth = (new Date(this.state.data.expiryDate)).getMonth()
      var expiryDateDay = (new Date(this.state.data.expiryDate)).getDate()

      if (e.target.value.length != "") {
        var renewalYears = (this.isNumeric(e.target.value) && parseInt(e.target.value) < 1000) ? e.target.value : 0
        var newExpirationDateYear = expiryDateYear + parseInt(renewalYears)
      }
      else {
        var renewalYears = ""
        var defaultValueForRenewalYears = 0
        var newExpirationDateYear = expiryDateYear + parseInt(defaultValueForRenewalYears)
      }

      var newExpirationDate = new Date(newExpirationDateYear, expiryDateMonth, expiryDateDay)

      this.setState({
        data: {
          ...this.state.data,
          newExpirationDate: newExpirationDate,
          renewalYears: renewalYears
        }
      });

    } else if (e.target.name === "cost" && e.target.value.trim().length >= 2) {
      let data = {
          searchParam: e.target.value,
          companyId: this.props.cid,
          sort: "id,ASC",
          userId: 0
      };

      {
          this.props.cid != undefined &&
              getCitizens(data).then(response => {
                  if (!response.ok) {
                      return;
                  }
                  this.setState({
                      costs: response.data.entities,
                  });
                  
              });
      }
    } 
  }

  validateCashRegisterNumber(paymentSlipAndDate) {
    let validCashRegisterNumberArray = ["03", "11", "28", "52", "70", "91", "92", "93"]
    if (!validCashRegisterNumberArray.includes(paymentSlipAndDate.substr(0, 2))) {
      return false;
    }
    return true;
  }

  keyPressBackspace = (e) => {
    if (e.target.value.length == 2 && e.keyCode !== 8) {
      e.target.value = e.target.value + ' ';
    }

    if (e.target.value.length == 8 && e.keyCode !== 8) {
      e.target.value = e.target.value + '/';
    }

    if (e.target.value.length == 11 && e.keyCode !== 8) {
      e.target.value = e.target.value + '.';
    }

    if (e.target.value.length == 14 && e.keyCode !== 8) {
      e.target.value = e.target.value + '.';
    }

    if (e.keyCode !== 8) {
      return;
    }

  }

  onChangePaymentSlipAndDateField = (e) => {

    let validDate = validateDateFromRenewalPaymentSlipAndDate(e.target.value)
    let year = e.target.value.substr(15, 4)

    let validCashRegistryNumber = this.validateCashRegisterNumber(e.target.value)

    if (!validCashRegistryNumber) {
      this.setError("paymentSlipAndDate", "Blagajna ne valja!");
      // this.setState({
      //     blagajnaFlag: "invalidNumber"
      // })
    }
    else if (!validDate || !isNumeric(year)) {
      this.setError("paymentSlipAndDate", "Datum ne valja!");
    }
    else {
      this.unsetError("paymentSlipAndDate")
    }

    if (e.target.value.length == 20) {
      return;
    }

    this.changeData(e);
  }

  render() {
    return (
      <Grid id='page' item md={12}>

        <div className='header'>
          <h1>{strings.renewal.editPageTitle}</h1>
        </div>

        <Paper className='paper'>
          <RenewalEditedForm
            onChange={this.changeData}
            onSubmit={this.submit}
            data={this.state.data}
            errors={this.state.errors}
            onCancel={this.props.onCancel}
            isDisabled={this.props.isDisabled}
            handleChange={this.handleChange}
            onAutocompleteChange={this.onAutocompleteChange}
            handleTextInputChange={this.handleTextInputChange}
            submitters={this.state.submitters == undefined ? [] : this.state.submitters}
            submitter={this.state.submitter}
            graveyards={this.state.graveyards == undefined ? [] : this.state.graveyards}
            graveSites={this.state.graveSites == undefined ? [] : this.state.graveSites}
            costs={this.state.costs == undefined ? [] : this.state.costs}
            cost={this.state.cost == undefined ? [] : this.state.cost}
            flag={this.props.flag}
            searchTypeFlag={this.props.searchTypeFlag}
            graveyard={this.state.data.graveyard == undefined ? "" : this.state.data.graveyard.name}
            graveSite={this.state.data.graveSite == undefined ? "" : this.state.data.graveSite.code}
            onChangePaymentSlipAndDateField={this.onChangePaymentSlipAndDateField}
            onKeyDown={this.keyPressBackspace}
          />
        </Paper>

      </Grid>
    );
  }
}


function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    changeFullScreen: Actions.changeFullScreen
  }, dispatch);
}

function mapStateToProps({ menuReducers, siteDataReducers }) {
  return { menu: menuReducers, siteData: siteDataReducers };
}

export default withSnackbar(withRouter(connect(mapStateToProps, mapDispatchToProps)(EditRenewal)));