import React from 'react'
import { bindActionCreators } from "redux";
import * as Actions from "../../actions/Actions";
import { withRouter } from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import Grid from '@material-ui/core/Grid';
import { Paper } from "@material-ui/core";
import strings from "../../localization";
import FormComponent from "../../common/FormComponent";
import { withSnackbar } from "notistack";
import Validators from '../../constants/ValidatorTypes';
import { editPostalAddressCodeImproved, getCitiesAndZipsWithSelectedState, getPostalAddressCodeImprovedById } from '../../services/postalAddressCodeImproved/PostalAddressCodeServiceImproved';
import { getPostalCodeImprovedById } from '../../services/postalCodeImproved/PostalCodeImprovedService';
import { getStateById, getStates } from '../../services/state/StateService';
import PostalAddressCodeImprovedForm from '../../components/forms/postalAddressCodeImproved/PostalAddressCodeImprovedForm';

class EditPostalAddressCodeImproved extends FormComponent {

    validationList = {

        state: [{ type: Validators.REQUIRED }],
        pac: [{ type: Validators.REQUIRED }],
        street: [{ type: Validators.REQUIRED }],
        number: [{ type: Validators.REQUIRED }],
        postalCodeImprovedCityZip: [{ type: Validators.REQUIRED }]
    };

    constructor(props) {
        super(props);
        this.state = {
            data: props.data ? props.data : {},
            errors: {},
        }

        this.state.sortBy = "name,ASC";

        this.props.changeFullScreen(false);
        this.submit = this.submit.bind(this);
    }

    componentDidMount() {

        getPostalAddressCodeImprovedById(this.props.id).then(response => {
            this.setState({
                data: response.data
            }, () => {
                getPostalCodeImprovedById(this.state.data.postalCodeImprovedId).then(response => {
                    this.setState({
                        data: {
                            ...this.state.data,
                            postalCodeImproved: response.data,
                            postalCodeImprovedCityZip: response.data
                        },
                    });
                });
                getStateById(this.state.data.stateId).then(response => {
                    this.setState({
                        data: {
                            ...this.state.data,
                            state: response.data
                        },
                    });
                });
            });
        });
    }

    submit() {
        if (!this.validate()) {
            this.props.enqueueSnackbar(strings.error.requiredFields, { variant: 'error' });
            return;
        }

        this.showDrawerLoader();

        editPostalAddressCodeImproved(this.transformRequest(this.state.data), this.props.cid).then(response => {
            if (!response.ok) {

                let messageKey = response.response.data.message;

                this.props.enqueueSnackbar(strings.postalAddressCodeImproved.messages[messageKey], {
                    variant: "error"
                });

                this.handleError(messageKey);
                return;
            }

            this.props.enqueueSnackbar(strings.addCompany.postalAddressCodeEdited, { variant: 'success' });
            this.props.onFinish(response.data.postalAddressCodeImproved);

            this.hideDrawerLoader();
        });
    }

    transformRequest(data) {
        return {
            ...data,
            userEmail: this.props.uemail,
            companyId: this.props.cid,
            stateId: this.state.data.state ? this.state.data.state.id : null,
            postalCodeImprovedId: this.state.data.postalCodeImprovedCityZip ? this.state.data.postalCodeImprovedCityZip.id : null,

        }
    }

    handleError(message) {
        switch (message) {
            case "POSTAL_ADDRESS_CODE_IMPROVED_CITY_AND_ZIP_CANT_BE_NULL":
                this.setError("cityAndZip", strings.postalAddressCodeImproved.messages.POSTAL_ADDRESS_CODE_IMPROVED_CITY_AND_ZIP_CANT_BE_NULL);
                break;
            case "POSTAL_ADDRESS_CODE_IMPROVED_STREET_CANT_BE_NULL":
                this.setError("street", strings.postalAddressCodeImproved.messages.POSTAL_ADDRESS_CODE_IMPROVED_STREET_CANT_BE_NULL);
                break;
            case "POSTAL_ADDRESS_CODE_IMPROVED_NUMBER_CANT_BE_NULL":
                this.setError("number", strings.postalAddressCodeImproved.messages.POSTAL_ADDRESS_CODE_IMPROVED_NUMBER_CANT_BE_NULL);
                break;
            case "POSTAL_ADDRESS_CODE_IMPROVED_PAC_CANT_BE_NULL":
                this.setError("pac", strings.postalAddressCodeImproved.messages.POSTAL_ADDRESS_CODE_IMPROVED_PAC_CANT_BE_NULL);
                break;
            case "POSTAL_ADDRESS_CODE_IMPROVED_STATE_CANT_BE_NULL":
                this.setError("state", strings.postalAddressCodeImproved.messages.POSTAL_ADDRESS_CODE_IMPROVED_STATE_CANT_BE_NULL);
                break;
        }
    }

    onAutocompleteChange = (event, values) => {
        let autocompleteName = event.target.id.split("-")[0];
        this.setState({
            data: {
                ...this.state.data, [autocompleteName]: values
            }
        });
    }

    handleTextInputChange = (e) => {

        if (e.target.name === "state" && e.target.value.trim().length >= 2) {
            let data = {
                searchParam: e.target.value,
                companyId: this.props.cid,
                sort: this.state.sortBy,
                userId: 0
            };
            {

                this.props.cid != undefined &&
                    getStates(data).then(response => {
                        if (!response.ok) {

                            return;
                        }

                        this.setState({
                            states: response.data.entities,
                        });
                    });
            }
        } else if (e.target.name === "postalCodeImprovedCityZip") {
            let data = {
                searchParam: e.target.value,
                companyId: this.props.cid,
                sort: "city,ASC",
                userId: 0,
                stateId: this.state.data.state.id
            };

            {
                this.props.cid != undefined &&
                    getCitiesAndZipsWithSelectedState(data).then(response => {
                        if (!response.ok) {
                            return;
                        }
                        this.setState({
                            citiesAndZips: response.data.entities,
                        });
                    });
            }
        }

    }

    render() {
        return (
            <Grid id='page' item md={12}>
                <div className='header'>
                    <h1>{strings.postalAddressCodeImproved.addPageTitle}</h1>
                </div>
                <Paper className='paper'>
                    <PostalAddressCodeImprovedForm
                        onChange={this.changeData}
                        onSubmit={this.submit}
                        data={this.state.data}
                        errors={this.state.errors}
                        onCancel={this.props.onCancel}
                        isDisabled={this.props.isDIsabled}
                        onAutocompleteChange={this.onAutocompleteChange}
                        handleChange={this.handleChange}
                        handleTextInputChange={this.handleTextInputChange}
                        flag={this.props.flag}
                        states={this.state.states == undefined ? [] : this.state.states}
                        citiesAndZips={this.state.citiesAndZips == undefined ? [] : this.state.citiesAndZips}

                    />
                </Paper>
            </Grid>
        );
    }




















}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        changeFullScreen: Actions.changeFullScreen
    }, dispatch);
}

function mapStateToProps({ menuReducers, siteDataReducers }) {
    return { menu: menuReducers, siteData: siteDataReducers };
}

export default withSnackbar(withRouter(connect(mapStateToProps, mapDispatchToProps)(EditPostalAddressCodeImproved)));