import FormComponent from "../../common/FormComponent";
import React from "react";
import { bindActionCreators } from "redux";
import * as Actions from "../../actions/Actions";
import { withRouter } from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import Grid from "@material-ui/core/Grid";
import { Paper } from "@material-ui/core";
import strings from "../../localization";
import Validators from "../../constants/ValidatorTypes";
import { withSnackbar } from "notistack";
import { getAllCompanies } from "../../services/company/CompanyService";
import UserForm from "../../components/forms/user/UserForm";
import { getUser, editUser } from "../../services/admin/AdminService";

class EditUser extends FormComponent {

  validationList = {
    email: [{ type: Validators.EMAIL }],
    name: [{ type: Validators.REQUIRED }],
    surname: [{ type: Validators.REQUIRED }],
    phoneNumber: [{ type: Validators.REQUIRED }, { type: Validators.IS_NUMBER }],
  };

  constructor(props) {
    super(props);

    this.state = {
      data: props.data ? props.data : {},
      errors: {},
      companies: null,
    };

    this.props.changeFullScreen(false);
    this.submit = this.submit.bind(this);
  }

  fetchData() {
    getUser(this.props.id).then(response => {
      this.setState({
        data: response.data
      });
    });

    getAllCompanies().then(response => {
      if (!response.ok) {
        return;
      }
      this.setState({
        companies: response.data.entities,
      });
    });
  }

  componentDidMount() {
    this.fetchData();
  }

  submit() {
    if (!this.validate()) {
      return;
    }

    this.showDrawerLoader();

    editUser(this.transformRequest(this.state.data)).then(response => {
      if (!response.ok) {
        let messageKey = response.response.data.message;

        this.props.enqueueSnackbar(strings.user.messages[messageKey], {
          variant: "error"
        });

        this.handleError(messageKey);

        return;
      }

      this.props.enqueueSnackbar(strings.user.editForm.edited, { variant: 'success' });
      this.props.onFinish(response.data.user);

      this.hideDrawerLoader();
    });

  }

  transformRequest(data) {
    return {
        ...data,
        actionUserEmail: this.props.uemail,
        companyId: this.props.cid
    }
}

  handleError(message) {

    switch (message) {
      case "USER_WITH_EMAIL_ALREADY_EXIST":
          this.setError("email", strings.user.messages.USER_WITH_EMAIL_ALREADY_EXIST);
          break;
      case "USER_COMPANY_CANT_BE_NULL":
          this.setError("company", strings.user.messages.USER_COMPANY_CANT_BE_NULL);
          break;
      case "USER_EMAIL_CANT_BE_NULL":
          this.setError("email", strings.user.messages.USER_EMAIL_CANT_BE_NULL);
          break;
      case "USER_NAME_CANT_BE_NULL":
          this.setError("name", strings.user.messages.USER_NAME_CANT_BE_NULL);
          break;
      case "USER_SURNAME_CANT_BE_NULL":
          this.setError("surname", strings.user.messages.USER_SURNAME_CANT_BE_NULL);
          break;
      case "USER_PHONE_CANT_BE_NULL":
          this.setError("phoneNumber", strings.user.messages.USER_PHONE_CANT_BE_NULL);
          break;
  }
  }

  render() {
    return (
      <Grid id="page" item md={12}>
        <div className="header">
          <h1>{strings.user.pageTitleEdit}</h1>
        </div>

        <Paper className="paper">
          <UserForm
            onChange={this.changeData}
            onSubmit={this.submit}
            data={this.state.data}
            companies={this.state.companies}
            errors={this.state.errors}
            onCancel={this.props.onCancel}
            role={this.props.role}
          />
        </Paper>

      </Grid>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      changeFullScreen: Actions.changeFullScreen
    },
    dispatch
  );
}

function mapStateToProps({ menuReducers, siteDataReducers }) {
  return { menu: menuReducers, siteData: siteDataReducers };
}

export default withSnackbar(
  withRouter(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(EditUser)
  )
);
