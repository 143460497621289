import React from 'react'
import CONFIG from "../config";

import strings from "../localization";
import Page from "./Page";

import MoreVert from '@material-ui/icons/MoreVert';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import VisibilityIcon from '@material-ui/icons/Visibility';
import AddIcon from '@material-ui/icons/Add';
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';
import UndoIcon from '@material-ui/icons/Undo';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import {
  Button,
  Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Drawer, LinearProgress,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell, TableFooter,
  TableHead, TablePagination,
  TableRow, TextField,
  Switch, FormControlLabel,
  FormGroup,
  
} from "@material-ui/core";
import { dateToString, dateTimeToString } from "../util/DateUtil";
import PageState from "../constants/PageState";
import DrawerWrapper from "./DrawerWrapper";
import SortDirection from '../constants/SortDirection';
import { ThreeSixtyOutlined } from '@material-ui/icons';

class TablePage extends Page {

  tableDescription = [];

  params = [
    { name: 'search', defaultValue: '' },
    { name: 'page', defaultValue: 1 },
    { name: 'perPage', defaultValue: 30 }
  ];

  deletedField = 'deleted';

  constructor(props) {
    super(props);

    this.state = {

      anchorEl: null,
      lockTable: false,
      ariaOwns: '',
      showConfirmDialog: false,
      itemForDelete: null,
      data: {},
      tableData: [],
      total: 0,
      selectedItem: undefined,
      pageState: PageState.View,
      showSearch: true,
      sortDirection: undefined,
      sortPropertie: undefined,
      showDeleted: false,
      showView: false,
      showAdd: false,
      remove: false,
      showEdit: false,
      showRestore: false,
      removedItemsShow: true,
    };

    this.props.changeFullScreen(false);

    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
    this.searchChanged = this.searchChanged.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.onFinish = this.onFinish.bind(this);
    this.cancelDelete = this.cancelDelete.bind(this);
    this.delete = this.delete.bind(this);
    this.handleDeletedChange = this.handleDeletedChange.bind(this);
    this.selectItem = this.selectItem.bind(this);
  }

  /** HANDLERS **/

  handleSortBy(label, order) {
    let description = this.tableDescription.find((desc) => desc.label === label);
    this.state.sortBy = description.sort + ',' + order;
    this.state.sortDirection = order;
    this.state.sortPropertie = description.sort;
    this.fetchData();
  }

  onCancel() {
    this.setPageState(PageState.View);
  }

  onFinish(item, fetch = true) {

    this.setState({
      selectedItem: item,
      pageState: PageState.View
    });

    if (!fetch) {
      return;
    }

    setTimeout(() => {
      this.fetchData()
    }, 1000);
  }

  handleMenuClick(event, ariaOwns) {

    this.setState({
      anchorEl: event.currentTarget,
      ariaOwns: ariaOwns
    });
  }

  handleDeletedChange(){
    this.setState({showDeleted : !this.state.showDeleted})
    
  }

  handleMenuClose() {
    this.setState({
      anchorEl: null,
      ariaOwns: ''
    });
  }

  handleChangePage(event, page) {

    this.state.searchData.page = page + 1;
    this.fetchData();
  }

  handleChangeRowsPerPage(event) {

    this.state.searchData.perPage = event.target.value;
    this.fetchData();
  }

  handleMenuEdit(item) {

    this.setState({
      selectedItem: item,
    });

    this.handleMenuClose();

    this.setPageState(PageState.Edit);
  }

  handleMenuAdd(item) {

    this.setState({
      selectedItem: item,
    });

    this.handleMenuClose();

    this.setPageState(PageState.Add);
  }

  showView(showView){
    this.setState({
      showView : showView
    })
  }

  showAdd(showAdd){
    this.setState({
      showAdd: false
    })
  }

  showRemove(remove){
    this.setState({
      remove: remove
    })
  }

  showEdit(showEdit){
    this.setState({
      showEdit : false
    })
  }
  showRestore(showRestore){
    this.setState({
      showRestore: showRestore
    })
  }
  removedItemsShow(removedItemsShow){
    this.setState({
      removedItemsShow: removedItemsShow
    })
  }
  handleMenuView(item) {

    this.setState({
      selectedItem: item,
    });

    this.handleMenuClose();

    this.setPageState(PageState.See);
  }

  handleMenuDelete(item) {

    this.setState({
      showConfirmDialog: true,
      selectedItem: item,
      anchorEl: null
    });
  }

  delete(item) {

  }

  handleRestore(item) {

    this.setState({
      selectedItem: item,
      anchorEl: null,
      
    }, () => {
      this.restore(item);
    });
  }

  restore(item) {
    
  }

  cancelDelete() {
    this.setState(prevState => ({
      ...prevState,
      showConfirmDialog: false,
      selectedItem: null,
    }));
  }

  searchChanged(event) {

    if (event.target.value.includes('"')) {
      event.target.value = event.target.value.replace(event.target.value.slice(-1), "''");
    }

    if (event.target.value.includes("\\")) {
      event.target.value = event.target.value.replace(event.target.value.slice(-1), "");
    }

    let searchData = this.state.searchData;

    searchData[event.target.name] = event.target.value;

    this.setState({
      searchData: searchData
    });

    this.fetchData();
  }

  selectRow(item) {

    this.setState({
      selectedItem: item
    });
  }

  selectItem(item) {
    this.setState(prevState => ({
      ...prevState,
      showConfirmDialog: true,
      selectedItem: item,
    }));
  }

  isRowSelected(item) {
    return this.state.selectedItem && item.id === this.state.selectedItem.id;
  }

  showDrawer() {

    return this.state.pageState !== PageState.View
  }

  setPageState(state) {

    this.setState({
      pageState: state
    });
  }

  

  /** RENDER TABLE  **/

  renderRowMenu(index, item) {

    let ariaOwns = 'action-menu-' + index;
    return (
      <TableCell>
        <IconButton
          size='small'
          aria-owns={this.state.anchorEl ? ariaOwns : undefined}
          aria-haspopup="true"
          onClick={(event) => this.handleMenuClick(event, ariaOwns)}
        >
          <MoreVert />
          
        </IconButton>
        {
          ariaOwns === this.state.ariaOwns &&
          <Menu
            id={ariaOwns}
            anchorEl={this.state.anchorEl}
            open={Boolean(this.state.anchorEl)}
            onClose={() => this.handleMenuClose()}
          >
            {
              (!this.state.showDeleted && this.state.showView) &&
            <MenuItem onClick={() => this.handleMenuView(item)}>
              <ListItemIcon>
                <VisibilityIcon />
              </ListItemIcon>
              <ListItemText primary={strings.table.view} />
            </MenuItem>
            }
           {
             (!this.state.showDeleted && this.state.showEdit ) &&
            <MenuItem onClick={() => this.handleMenuEdit(item)}>
              <ListItemIcon>
                <EditIcon />
              </ListItemIcon>
              <ListItemText primary={strings.table.edit} />
            </MenuItem>
            }
            {
              (!this.state.showDeleted && this.state.remove) &&
              <MenuItem onClick={() => this.handleMenuDelete(item)}>
                <ListItemIcon>
                  <DeleteIcon />
                </ListItemIcon>
                <ListItemText primary={strings.table.delete} />
              </MenuItem>
            }
            {
              (this.state.showDeleted ) &&
              <MenuItem onClick={() => this.handleRestore(item)}>
                <ListItemIcon>
                  <UndoIcon />
                </ListItemIcon>
                <ListItemText primary={strings.table.undo} />
              </MenuItem>
            }

          </Menu>
        }

      </TableCell>
    );
  }

  renderTableRowData(item) {

    let result = [];

    for (let description of this.tableDescription) {

      result.push(
        <TableCell key={'table-data-' + description.key + '-' + result.length}>
          {
            description.transform !== undefined &&
            this[description.transform](item[description.key])
          }
          {
            description.transform === undefined &&
            item[description.key]
          }
        </TableCell>
      );
    }

    return result;
  }

  renderTableRow(data = []) {

    let result = [];
    let expiryDate;
    let currentDate = new Date();
    let expiryDateIncremented;

    for (let item of data) {

      expiryDate = item.graveSiteExpiryDate != null ? new Date(item.graveSiteExpiryDate) : "";
      expiryDateIncremented = expiryDate;

      let className = 'table-row-' + result.length % 2;

      if (this.isRowSelected(item)) {
        className += ' selected';
      }

      if (expiryDate != "") {
        expiryDateIncremented.setDate(expiryDate.getDate() + 1);
        if (expiryDateIncremented.getTime() < currentDate.getTime()) {
          className = 'backgroundColorExpired';
        }
      }

      if (item.canceled) {
        className = 'backgroundColorCancel';
      }

      result.push(
        <TableRow key={'table-row-' + result.length} className={className} onClick={() => this.selectRow(item)}>
          {this.renderRowMenu(result.length, item)}
          {this.renderTableRowData(item)}
        </TableRow>
      )
    }

    return (
      <TableBody>
        {result}
      </TableBody>
    );
  }

  renderTableHeader() {

    let result = [];

    for (let item of this.tableDescription) {

      result.push(
        <TableCell key={'table-header-' + result.length}>
          <div class='container-horizontal'>
            <div class='container-horizontal-label'>
              {item.label}
            </div>
            <div class='container-horizontal-icons'>
              {this.state.sortPropertie !== item.sort ? (
                <div class='container-vertical'>
                  <img src={'images/button.png'} id='table-page-image' onClick={() => this.handleSortBy(item.label, SortDirection.ASC)} />
                  <img src={'images/multimedia-option.png'} id='table-page-image2' onClick={() => this.handleSortBy(item.label, SortDirection.DESC)} />
                </div>
              ) : (
                  <div>
                    {this.state.sortDirection === SortDirection.ASC && this.state.sortPropertie === item.sort ? (
                      <img src={'images/multimedia-option.png'} id='table-page-image2' onClick={() => this.handleSortBy(item.label, SortDirection.DESC)} />
                    ) : (
                        <img src={'images/button.png'} id='table-page-image' onClick={() => this.handleSortBy(item.label, SortDirection.ASC)} />
                      )}
                  </div>
                )}
            </div>
          </div>
        </TableCell>
      )
    }

    return (
      <TableHead className='table-header'>
        <TableRow>
          <TableCell>
            {strings.table.actions}
          </TableCell>
          {result}
        </TableRow>
      </TableHead>
    );
  }
  getPaginationLabel() {
    return strings.table.footer;
  }

  renderTableFooter() {

    return (
      <TableFooter>
        <TableRow>
          <TablePagination count={this.state.total} rowsPerPageOptions={CONFIG.rowsPerPage}
            colSpan={this.tableDescription.length + 1} page={this.state.searchData.page - 1} rowsPerPage={this.state.searchData.perPage}
            onChangePage={this.handleChangePage} onChangeRowsPerPage={this.handleChangeRowsPerPage}
            getItemAriaLabel={this.getPaginationLabel}
            SelectProps={{
              native: true,
            }} labelRowsPerPage={strings.table.footer}
          />
        </TableRow>
      </TableFooter>
    );
  }

  /** RENDER TRANSFORMS */

  renderColumnDeleted(value) {

    return value ? <DoneIcon /> : <CloseIcon />;
  }

  renderColumnDate(value) {
    return dateToString(value);
  }

  renderColumnDateTime(value) {
    return dateTimeToString(value);
  }

  renderTable(data) {

    return <Table>

      {
        this.state.lockTable &&
        <div className='lock-table'>
          <LinearProgress />
        </div>
      }

      {this.renderTableHeader()}
      {this.renderTableRow(data)}
      {this.renderTableFooter()}
    </Table>
  }

  renderAddContent() {
    return ''
  }

  renderEditContent(item) {
    return ''
  }

  renderViewContent(item) {
    return ''
  }

  renderDrawerContent() {

    switch (this.state.pageState) {

      case PageState.Add: return this.renderAddContent();
      case PageState.Edit: return this.renderEditContent(this.state.selectedItem);
      case PageState.See: return this.renderViewContent(this.state.selectedItem);

      default: return '';
    }

  }

  /** RENDER DIALOG **/

  renderDialog(title, text, cancel, deleteFunction) {
    return (
      <Dialog open={this.state.showConfirmDialog}
        onClose={() => cancel()}
        aria-labelledby='draggable-dialog-title'
      >
        <DialogTitle id='draggable-dialog-title'>
          {title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {text}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => cancel()} color='primary' variant="contained">
            {strings.table.no}
          </Button>
          <Button onClick={() => deleteFunction(this.state.selectedItem)} color='secondary' variant="contained">
            {strings.table.yes}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  getPageHeader() {
    return <h1>Title</h1>;
  }

  renderTableControls() {
    return [
      
      <IconButton onClick={() => this.setPageState(PageState.Add)}>
        <AddIcon />
      </IconButton>
    
    ]
  }

  render() {
    return (
      <Grid id='table-page'>
        {this.renderDialog(strings.table.confirmDelete, strings.delete.confirmation,
          this.cancelDelete, this.delete)}
        <div className='header'>
          {this.getPageHeader()}
          <div className='show-deleted'>
            
          { this.state.removedItemsShow &&
            <FormControlLabel
            control = {<Switch
              checked={this.state.showDeleted}
              onChange={this.handleDeletedChange}
              inputProps={{ 'aria-label': 'controlled' }}
              />}
              labelPlacement="start"
              label={!this.state.showDeleted ? "Prikazi obrisane" : "Prikazi neobrisane"}
          />
          }
            </div>
          
          <div className='filter-controls'>

    
            {
              this.state.showSearch &&
              <TextField
                label={strings.table.search}
                type="search"
                name='search'
                value={this.state.searchData.search}
                onChange={this.searchChanged}
              />
            }

            {
                this.state.showAdd &&
              this.renderTableControls()
            }
          </div>
        </div>
        <Paper md={12}>
          {this.renderTable(this.state.tableData)}
        </Paper>

        <Drawer id='drawer' anchor='right' open={this.showDrawer()} onClose={() => this.setPageState(PageState.View)} ModalProps={{disableBackdropClick: true,}} >
          <DrawerWrapper onBack={() => this.setPageState(PageState.View)}>
            {this.renderDrawerContent()}
          </DrawerWrapper>
        </Drawer>
      </Grid>
    );
  }
}

export default TablePage;