import React from 'react';
import strings from "../../../../localization";
import { getError, hasError } from "../../../../functions/Validation";
import { Button, TextField, FormLabel, FormGroup, FormControlLabel, Checkbox, Radio, FormControl, RadioGroup } from "@material-ui/core";
import DatePickerControl from '../../../controls/DatePickerControl';
import Autocomplete from '@material-ui/lab/Autocomplete';

const DeceasedTakeawayRecordForm = ({
  onSubmit,
  onCancel,
  onChange,
  errors,
  data,
  isDisabled,
  handleChange,
  onAutocompleteChange,
  handleTextInputChange,
  transporterTs,
  flag
}) => {
  return (

    <form id='user-form'>
      <TextField
        label={strings.deceasedReception.form.deceasedNameSurname + "*"}
        error={hasError(errors, 'deceasedNameSurname')}
        helperText={getError(errors, 'deceasedNameSurname')}
        fullWidth
        name='deceasedNameSurname'
        onChange={onChange}
        margin="normal"
        autoFocus
        defaultValue={data.deceasedNameSurname}
        value={data.deceasedNameSurname}
        InputLabelProps={{
          shrink: true,
        }}
        disabled={isDisabled}
      />
      <TextField
        label={strings.deceasedReception.form.principalT}
        error={hasError(errors, 'principalT')}
        helperText={getError(errors, 'principalT')}
        fullWidth
        name='principalT'
        onChange={onChange}
        margin="normal"
        defaultValue={data.principalT}
        value={data.principalT}
        InputLabelProps={{
          shrink: true,
        }}
        disabled={isDisabled}
      />
      <DatePickerControl
        label={strings.deceasedReception.form.takeawayDateTime}
        error={getError(errors, 'dateOfTakeover')}
        hasError={hasError(errors, 'dateOfTakeover')}
        helperText={getError(errors, 'dateOfTakeover')}
        placeholder={strings.deceasedReception.form.takeawayDateTime}
        name='dateOfTakeover'
        onChange={onChange}
        date={data.dateOfTakeover ? new Date(data.dateOfTakeover) : data.dateOfTakeover}
        isClearable={true}
        dateFormat='dd/MM/yyyy'
        disabled={isDisabled}
      />
      <TextField
        label={strings.deceasedReception.form.reasonOfTaking}
        error={hasError(errors, 'reasonOfTaking')}
        helperText={getError(errors, 'reasonOfTaking')}
        fullWidth
        name='reasonOfTaking'
        onChange={onChange}
        margin="normal"
        defaultValue={data.reasonOfTaking}
        value={data.reasonOfTaking}
        InputLabelProps={{
          shrink: true,
        }}
        disabled={isDisabled}
      />
      {/* <TextField
                label={strings.deceasedReception.form.transporterT}
                error={hasError(errors, 'transporterT')}
                helperText={getError(errors, 'transporterT')}
                fullWidth
                name='transporterT'
                onChange={onChange}
                margin="normal"
                defaultValue={data.transporterT}
                value={data.transporterT}
                InputLabelProps={{
                    shrink: true,
                }}
                disabled={isDisabled}
            /> */}

      {(transporterTs && ((data.transporterT && flag == "edit") || (flag == "add") || (data.transporterT && flag == "view"))) &&
        <Autocomplete
          id={"transporterT"}
          name={"transporterT"}
          options={transporterTs}
          getOptionLabel={option => option.name}
          onChange={onAutocompleteChange}
          renderInput={params => (
            <TextField
              {...params}
              variant="standard"
              label={strings.deceasedReception.form.transporterT}
              margin="normal"
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
          disabled={isDisabled}
          value={data.transporterT}
        />
      }

      {(transporterTs && ((!data.transporterT && flag == "edit") || (!data.transporterT && flag == "view"))) &&
        <Autocomplete
          id={"transporterT"}
          name={"transporterT"}
          options={transporterTs}
          getOptionLabel={option => option.name}
          onChange={onAutocompleteChange}
          renderInput={params => (
            <TextField
              {...params}
              variant="standard"
              label={strings.deceasedReception.form.transporterT}
              margin="normal"
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
          disabled={isDisabled}
          value={data.transporterT}
        />
      }


      <TextField
        label={strings.deceasedReception.form.registrationPlateT}
        error={hasError(errors, 'registrationPlateT')}
        helperText={getError(errors, 'registrationPlateT')}
        fullWidth
        name='registrationPlateT'
        onChange={onChange}
        margin="normal"
        defaultValue={data.registrationPlateT}
        value={data.registrationPlateT}
        InputLabelProps={{
          shrink: true,
        }}
        disabled={isDisabled}
      />
      <TextField
        label={strings.deceasedReception.form.approvedBy}
        error={hasError(errors, 'approvedBy')}
        helperText={getError(errors, 'approvedBy')}
        fullWidth
        name='approvedBy'
        onChange={onChange}
        margin="normal"
        defaultValue={data.approvedBy}
        value={data.approvedBy}
        InputLabelProps={{
          shrink: true,
        }}
        disabled={isDisabled}
      />
      <TextField
        label={strings.deceasedReception.form.deceasedTookOverByT}
        error={hasError(errors, 'deceasedTookOverByT')}
        helperText={getError(errors, 'deceasedTookOverByT')}
        fullWidth
        name='deceasedTookOverByT'
        onChange={onChange}
        margin="normal"
        defaultValue={data.deceasedTookOverByT}
        value={data.deceasedTookOverByT}
        InputLabelProps={{
          shrink: true,
        }}
        disabled={isDisabled}
      />
      <TextField
        multiline={true}
        rowsMax={5}
        label={strings.deceasedReception.form.note}
        error={hasError(errors, 'note')}
        helperText={getError(errors, 'note')}
        fullWidth
        name='note'
        onChange={onChange}
        margin="normal"
        defaultValue={data.note}
        value={data.note}
        InputLabelProps={{
          shrink: true,
        }}
        disabled={isDisabled}
      />
      {
        !isDisabled &&
        <div className='submit-container'>
          <Button variant="contained" color="primary" onClick={onSubmit}>
            {strings.deceasedReception.form.ok}
          </Button>
          <Button variant="contained" color="secondary" onClick={onCancel}>
            {strings.deceasedReception.form.cancel}
          </Button>
        </div>
      }
    </form>
  )
};

export default DeceasedTakeawayRecordForm;