import React, { useState } from 'react';
import strings from "../../../localization";
import { getError, hasError } from "../../../functions/Validation";
import { Button, TextField, FormLabel, FormControlLabel, Radio, FormControl, RadioGroup } from "@material-ui/core";
import DatePickerControl from '../../controls/DatePickerControl';
import Autocomplete from '@material-ui/lab/Autocomplete';
import DatePicker from "react-datepicker";

const RenewalForm = ({
  onSubmit,
  onCancel,
  onChange,
  errors,
  data,
  isDisabled,
  onAutocompleteChange,
  handleTextInputChange,
  submitter,
  submitters,
  graveyards,
  graveSites,
  flag,
  searchTypeFlag
}) => {
  const [startDate, setStartDate] = useState(new Date());
  return (
    <form id='user-form'>

      {((data.submitter && flag == "edit") || (flag == "add") || (data.submitter && flag == "view")) && searchTypeFlag.id != 2 &&
        <Autocomplete
          id={"submitter"}
          name={"submitter"}
          options={submitters}
          getOptionLabel={option => option.personalNumber}
          onChange={onAutocompleteChange}
          renderInput={params => (
            <TextField
              {...params}
              variant="standard"
              label={strings.renewal.form.personalNumber}
              margin="normal"
              fullWidth
              name={"submitter"}
              onChange={handleTextInputChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
          disabled={isDisabled}
          value={data.submitter}
        />
      }
      {((!data.submitter && flag == "edit") || (!data.submitter && flag == "view")) && searchTypeFlag.id != 2 &&
        <Autocomplete
          id={"submitter"}
          name={"submitter"}
          options={submitters}
          getOptionLabel={option => option.personalNumber}
          onChange={onAutocompleteChange}
          renderInput={params => (
            <TextField
              {...params}
              variant="standard"
              label={strings.renewal.form.personalNumber}
              margin="normal"
              fullWidth
              name={"submitter"}
              onChange={handleTextInputChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
          disabled={isDisabled}
          value={data.submitter}
        />
      }

      { searchTypeFlag.id != 2 &&
        <TextField
          label={strings.renewal.form.submitterName}
          error={hasError(errors, 'nameSurnameSubmitter')}
          helperText={getError(errors, 'nameSurnameSubmitter')}
          fullWidth
          name='nameSurnameSubmitter'
          onChange={onChange}
          margin="normal"
          defaultValue={submitter.nameSurnameSubmitter}
          value={submitter.nameSurnameSubmitter}
          InputLabelProps={{
            shrink: true,
          }}
          disabled={true}
        />
      }

      {(((data.submitter && flag == "edit") || (flag == "add") || (data.submitter && flag == "view")) && searchTypeFlag.id == 2) &&
        <Autocomplete
          id={"submitter"}
          name={"submitter"}
          options={submitters}
          getOptionLabel={option => option.deceasedPersonalNumber}
          onChange={onAutocompleteChange}
          renderInput={params => (
            <TextField
              {...params}
              variant="standard"
              label={strings.renewal.form.deceasedPersonalNumber}
              margin="normal"
              fullWidth
              name={"submitter"}
              onChange={handleTextInputChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
          disabled={isDisabled}
          value={data.submitter}
        />
      }
      {(((!data.submitter && flag == "edit") || (!data.submitter && flag == "view")) && searchTypeFlag.id == 2) &&
        <Autocomplete
          id={"submitter"}
          name={"submitter"}
          options={submitters}
          getOptionLabel={option => option.deceasedPersonalNumber}
          onChange={onAutocompleteChange}
          renderInput={params => (
            <TextField
              {...params}
              variant="standard"
              label={strings.renewal.form.deceasedPersonalNumber}
              margin="normal"
              fullWidth
              name={"submitter"}
              onChange={handleTextInputChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
          disabled={isDisabled}
          value={data.submitter}
        />
      }

      { searchTypeFlag.id == 2 &&
        <TextField
          label={strings.renewal.form.deceasedName}
          error={hasError(errors, 'nameSurnameSubmitter')}
          helperText={getError(errors, 'nameSurnameSubmitter')}
          fullWidth
          name='nameSurnameSubmitter'
          onChange={onChange}
          margin="normal"
          defaultValue={submitter.nameSurnameSubmitter}
          value={submitter.nameSurnameSubmitter}
          InputLabelProps={{
            shrink: true,
          }}
          disabled={true}
        />
      }


      <TextField
        label={strings.renewal.form.submitterAddress}
        error={hasError(errors, 'addressSubmitter')}
        helperText={getError(errors, 'addressSubmitter')}
        fullWidth
        name='addressSubmitter'
        onChange={onChange}
        margin="normal"
        defaultValue={submitter.addressSubmitter}
        value={submitter.addressSubmitter}
        InputLabelProps={{
          shrink: true,
        }}
        disabled={true}
      />
      <TextField
        label={strings.renewal.form.submitterMunicipality}
        error={hasError(errors, 'municipalitySubmitter')}
        helperText={getError(errors, 'municipalitySubmitter')}
        fullWidth
        name='municipalitySubmitter'
        onChange={onChange}
        margin="normal"
        defaultValue={submitter.municipalitySubmitter}
        value={submitter.municipalitySubmitter}
        InputLabelProps={{
          shrink: true,
        }}
        disabled={true}
      />
      <TextField
        label={strings.renewal.form.submitterTelephone}
        error={hasError(errors, 'telephoneSubmitter')}
        helperText={getError(errors, 'telephoneSubmitter')}
        fullWidth
        name='telephoneSubmitter'
        onChange={onChange}
        margin="normal"
        defaultValue={submitter.telephoneSubmitter}
        value={submitter.telephoneSubmitter}
        InputLabelProps={{
          shrink: true,
        }}
        disabled={true}
      />
      <TextField
        label={strings.renewal.form.paymentNumber}
        error={hasError(errors, 'payment')}
        helperText={getError(errors, 'payment')}
        fullWidth
        name='payment'
        onChange={onChange}
        margin="normal"
        defaultValue={data.payment}
        value={data.payment}
        InputLabelProps={{
          shrink: true,
        }}
        disabled={isDisabled}
      />

      { (flag == "view" && data.amount % 1 == 0 && data.amount != null) &&
        <TextField
          label={strings.renewal.form.amount}
          error={hasError(errors, 'amount')}
          helperText={getError(errors, 'amount')}
          fullWidth
          name='amount'
          onChange={onChange}
          margin="normal"
          defaultValue={data.amount}
          value={data.amount + ".00"}
          InputLabelProps={{
            shrink: true,
          }}
          disabled={isDisabled}
          placeholder="0.00"
        />
      }

      { (flag == "view" && data.amount % 1 != 0 && data.amount != null) &&
        <TextField
          label={strings.renewal.form.amount}
          error={hasError(errors, 'amount')}
          helperText={getError(errors, 'amount')}
          fullWidth
          name='amount'
          onChange={onChange}
          margin="normal"
          defaultValue={data.amount}
          value={data.amount.toFixed(2)}
          InputLabelProps={{
            shrink: true,
          }}
          disabled={isDisabled}
          placeholder="0.00"
        />
      }

      { flag == "view" && data.amount == null &&
        <TextField
          label={strings.renewal.form.amount}
          error={hasError(errors, 'amount')}
          helperText={getError(errors, 'amount')}
          fullWidth
          name='amount'
          onChange={onChange}
          margin="normal"
          defaultValue={data.amount}
          value={0 + ".00"}
          InputLabelProps={{
            shrink: true,
          }}
          disabled={isDisabled}
          placeholder="0.00"
        />
      }

      { (flag == "add" || flag == "edit") &&
        <TextField
          label={strings.renewal.form.amount}
          error={hasError(errors, 'amount')}
          helperText={getError(errors, 'amount')}
          fullWidth
          name='amount'
          onChange={onChange}
          margin="normal"
          defaultValue={data.amount}
          value={data.amount}
          InputLabelProps={{
            shrink: true,
          }}
          disabled={isDisabled}
          placeholder="0.00"
        />
      }

      {((data.graveyard && flag == "edit") || (flag == "add") || (data.graveyard && flag == "view")) &&
        <Autocomplete
          id={"graveyard"}
          name={"graveyard"}
          options={graveyards}
          getOptionLabel={option => option.name}
          onChange={onAutocompleteChange}
          renderInput={params => (
            <TextField
              {...params}
              variant="standard"
              label={strings.renewal.form.graveyard}
              error={hasError(errors, 'graveyard')}
              helperText={getError(errors, 'graveyard')}
              margin="normal"
              fullWidth
              name={"graveyard"}
              onChange={handleTextInputChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
          disabled={isDisabled}
          value={data.graveyard}
        />
      }
      {((!data.graveyard && flag == "edit") || (!data.graveyard && flag == "view")) &&
        <Autocomplete
          id={"graveyard"}
          name={"graveyard"}
          options={graveyards}
          getOptionLabel={option => option.name}
          onChange={onAutocompleteChange}
          renderInput={params => (
            <TextField
              {...params}
              variant="standard"
              label={strings.renewal.form.graveyard}
              error={hasError(errors, 'graveyard')}
              helperText={getError(errors, 'graveyard')}
              margin="normal"
              fullWidth
              name={"graveyard"}
              onChange={handleTextInputChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
          disabled={isDisabled}
          value={data.graveyard}
        />
      }
      <TextField
        label={strings.reservation.form.tombstoneType}
        error={hasError(errors, 'tombstoneType')}
        helperText={getError(errors, 'tombstoneType')}
        fullWidth
        name='tombstoneType'
        onChange={onChange}
        margin="normal"
        defaultValue={data.tombstoneType}
        value={data.tombstoneType}
        InputLabelProps={{
          shrink: true,
        }}
        disabled={isDisabled}
      />
      {((data.graveSite && flag == "edit") || (flag == "add") || (data.graveSite && flag == "view")) &&
        <Autocomplete
          id={"graveSite"}
          name={"graveSite"}
          options={graveSites}
          getOptionLabel={option => option.code}
          onChange={onAutocompleteChange}
          renderInput={params => (
            <TextField
              {...params}
              variant="standard"
              label={strings.renewal.form.graveSite}
              margin="normal"
              fullWidth
              name={"graveSite"}
              onChange={handleTextInputChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
          disabled={isDisabled}
          value={data.graveSite}
        />
      }
      {((!data.graveSite && flag == "edit") || (!data.graveSite && flag == "view")) &&
        <Autocomplete
          id={"graveSite"}
          name={"graveSite"}
          options={graveSites}
          getOptionLabel={option => option.code}
          onChange={onAutocompleteChange}
          renderInput={params => (
            <TextField
              {...params}
              variant="standard"
              label={strings.renewal.form.graveSite}
              margin="normal"
              fullWidth
              name={"graveSite"}
              onChange={handleTextInputChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
          disabled={isDisabled}
          value={data.graveSite}
        />
      }
      <DatePickerControl
        label={strings.renewal.form.expiryDate}
        error={getError(errors, 'expiryDate')}
        hasError={hasError(errors, 'expiryDate')}
        helperText={getError(errors, 'expiryDate')}
        placeholder={strings.renewal.form.expiryDate}
        name='expiryDate'
        onChange={onChange}
        date={data.expiryDate ? new Date(data.expiryDate) : data.expiryDate}
        isClearable={true}
        dateFormat='dd/MM/yyyy'
        disabled={isDisabled}
        showYearDropdown={true}
      />
      <TextField
        multiline={true}
        rowsMax={5}
        label={strings.renewal.form.note}
        error={hasError(errors, 'note')}
        helperText={getError(errors, 'note')}
        fullWidth
        name='note'
        onChange={onChange}
        margin="normal"
        defaultValue={data.note}
        value={data.note}
        InputLabelProps={{
          shrink: true,
        }}
        disabled={isDisabled}
      />
      {
        !isDisabled &&
        <div className='submit-container'>
          <Button variant="contained" color="primary" onClick={onSubmit}>
            {strings.renewal.form.ok}
          </Button>
          <Button variant="contained" color="secondary" onClick={onCancel}>
            {strings.renewal.form.cancel}
          </Button>
        </div>
      }
    </form>
  )
};

export default RenewalForm;