import React from 'react'
import TablePage from "../../common/TablePage";
import { bindActionCreators } from "redux";
import * as Actions from "../../actions/Actions";
import { withRouter } from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import strings from "../../localization";
import { withSnackbar } from "notistack";
import { getTransporters, deleteTransporter, getDeletedTransporters, restoreTransporter } from '../../services/transporter/TransporterService';
import AddTranspoprter from './AddTranspoprter';
import EditTransporter from './EditTransporter';
import ViewTransporter from './ViewTransporter';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { Drawer, TextField, FormControlLabel, Switch } from "@material-ui/core";
import PageState from '../../constants/PageState';
import DrawerWrapper from '../../common/DrawerWrapper';
import SelectControl from '../../components/controls/SelectControl';
import IconButton from "@material-ui/core/IconButton";
import { getMonths, getYears } from '../../util/DateUtil';
import { generateTransporterByTimesReview } from '../../services/report/ReportService';
import { downloadReport } from '../../util/ReviewsUtil';


class TransporterList extends TablePage {

  tableDescription = [
    // { key: 'code', label: strings.transporterList.code, sort: 'code' },
    { key: 'shortenedName', label: strings.transporterList.shortenedName, sort: 'shortenedName' },
    { key: 'name', label: strings.transporterList.name, sort: 'name' },
    { key: 'dateCreated', label: strings.transporterList.dateCreated, transform: 'renderColumnDate', sort: 'dateCreated' },
  ];


  constructor(props) {
    super(props);

    const months = getMonths();
    months.unshift({ id: -1, name: strings.months.all })

    const years = getYears();
    years.unshift({ value: -1, name: strings.years.all })

    this.state = {
      ...this.state,
      data: {
        yearFrom: { value: -1 },
        yearTo: { value: -1 },
        monthTo: { id: -1 },
        monthFrom: { id: -1 },
      },
      filter: {
        months: months,
        years: years,
      },
      reportFlag: true
    };

    this.state.sortBy = "name,ASC";
    this.state.showDeleted = false;

  }

  fetchData() {
    let data = {
      searchParam: this.state.searchData.search,
      page: this.state.searchData.page - 1,
      size: this.state.searchData.perPage,
      companyId: this.state.companyId,
      sort: this.state.sortBy,
      userId: this.props.auth.user.id
    };

    {
      (this.state.companyId != undefined && !this.state.showDeleted) &&
        getTransporters(data).then(response => {
          if (!response.ok) {
            return;
          }
          this.setState({
            tableData: response.data.entities,
            total: response.data.total,
          });
        });
    }

    {
      (this.state.companyId != undefined && this.state.showDeleted) &&
        getDeletedTransporters(data).then(response => {
          if (!response.ok) {
            return;
          }
          this.setState({
            tableData: response.data.entities,
            total: response.data.total,
          });
        });
    }

  }

  handleDeletedChange(){
    this.setState({
      showDeleted : !this.state.showDeleted
    },() =>{ this.fetchData(); })
    
  }
  usePermissions(){
    let data = {
      userId: this.props.auth.user.id,
      category: "Транспортери"
    }
    this.getPermissionsS(data);
    this.showAdd(this.props.showAdd);
    this.showRemove(this.props.remove);
    this.showEdit(this.props.showEdit);
    this.showView(this.props.showView);
    this.removedItemsShow(this.props.removedItemsShow);

  }

  componentDidMount() {
    this.usePermissions()
    this.setState({
      companyId: this.props.auth.user.company.id
    }, () => { this.fetchData(); })
  }

  componentWillReceiveProps(nextProps) {
    strings.setLanguage(nextProps.auth.user.language);
    this.tableDescription = [
      // { key: 'code', label: strings.transporterList.code, sort: 'code' },
      { key: 'shortenedName', label: strings.transporterList.shortenedName, sort: 'shortenedName' },
      { key: 'name', label: strings.transporterList.name, sort: 'name' },
      { key: 'dateCreated', label: strings.transporterList.dateCreated, transform: 'renderColumnDate', sort: 'dateCreated' },
    ];
  }

  getPageHeader() {
    return <h1>{strings.transporterList.pageTitle}</h1>;
  }

  renderAddContent() {
    return <AddTranspoprter cid={this.state.companyId} uemail={this.props.auth.user.email} onCancel={this.onCancel} onFinish={this.onFinish} />
  }

  renderEditContent() {
    return <EditTransporter
      id={this.state.selectedItem.id}
      uemail={this.props.auth.user.email}
      cid={this.state.companyId}
      onCancel={this.onCancel}
      onFinish={this.onFinish}
      isDisabled={false}
    />
  }

  renderViewContent() {

    return <ViewTransporter id={this.state.selectedItem.id} isDisabled={true} onCancel={this.onCancel} onFinish={this.onFinish} />
  }

  delete(item) {

    this.setState({
      lockTable: true
    });


    deleteTransporter(item.id, this.props.auth.user.id).then(response => {

      if (response && !response.ok) {
        this.onFinish(null);
        return;
      }

      this.props.enqueueSnackbar(strings.companyList.transporterDeleted, { variant: 'success' });

      this.onFinish(item);
      this.cancelDelete();

      this.setState({
        lockTable: false
      });
    });
  }

  restore(item){
    
    restoreTransporter(this.transformRequest(item)).then(response => {
      if (response && !response.ok) {
        this.onFinish(null);
        return;
      }
      this.setState({
        showDeleted : !this.state.showDeleted,
      },() =>{ this.fetchData(); })
    })
  }


  transformRequest(data) {
    return {
        ...data,
        userEmail: this.props.auth.user.email,
        companyId: this.state.companyId
    }
  }

  generateReport(data) {

    generateTransporterByTimesReview(data).then(response => {
      if (!response.ok) {
        return;
      }

      this.props.enqueueSnackbar(strings.mainBookOfBuriedList.PDF, { variant: 'success' });
      downloadReport(response, "Транспортери по времену доношења");
    })
  }

  renderButtons() {
    let data = {
      companyId: this.state.companyId,
      searchParam: this.state.searchData ? this.state.searchData.search : "",
      monthFrom: this.state.data.monthFrom ? this.state.data.monthFrom.id : -1,
      yearFrom: this.state.data.yearFrom ? this.state.data.yearFrom.value : -1,
      monthTo: this.state.data.monthTo ? this.state.data.monthTo.id : -1,
      yearTo: this.state.data.yearTo ? this.state.data.yearTo.value : -1,
    };
    return (
      <div className="report-button">
        <IconButton onClick={() => this.generateReport(data)} disabled={this.state.data.monthFrom.id == -1 || this.state.data.yearFrom.value == -1 || this.state.data.monthTo.id == -1 || this.state.data.yearTo.value == -1}
        >
          {strings.transporter.buttons.generateReport}
        </IconButton>
      </div>
    );
  }

  renderFilters() {
    return (
      <div className="renderFilters">
        <React.Fragment>
        <FormControlLabel
            control = {<Switch
              checked={this.state.showDeleted}
              onChange={this.handleDeletedChange}
              inputProps={{ 'aria-label': 'controlled' }}
              />}
              labelPlacement="start"
              label={!this.state.showDeleted ? "Prikazi obrisane" : "Prikazi neobrisane"}
          />
          <SelectControl
            options={this.state.filter.years}
            selected={this.state.data.yearFrom}
            onChange={this.changeData}
            label={strings.transporterList.yearFrom}
            name={"yearFrom"}
            nameKey={"name"}
            valueKey={"value"}
          />

          <SelectControl
            options={this.state.filter.months}
            selected={this.state.data.monthFrom}
            onChange={this.changeData}
            label={strings.transporterList.monthFrom}
            name={"monthFrom"}
            nameKey={"name"}
            valueKey={"id"}
          />

          <SelectControl
            options={this.state.filter.years}
            selected={this.state.data.yearTo}
            onChange={this.changeData}
            label={strings.transporterList.yearTo}
            name={"yearTo"}
            nameKey={"name"}
            valueKey={"value"}
          />

          <SelectControl
            options={this.state.filter.months}
            selected={this.state.data.monthTo}
            onChange={this.changeData}
            label={strings.transporterList.monthTo}
            name={"monthTo"}
            nameKey={"name"}
            valueKey={"id"}
          />
        </React.Fragment>
      </div>
    );
  }

  render() {

    return (
      <Grid id='table-page'>
        {this.renderDialog(strings.table.confirmDelete, strings.delete.confirmation,
          this.cancelDelete, this.delete)}
        <div className='header'>
          {this.getPageHeader()}

          <div className='filter-controls'>
            {
              this.renderButtons()
            }
            {
              this.renderFilters()
            }
            {
              this.state.showSearch &&
              <TextField
                label={strings.table.search}
                type="search"
                name='search'
                value={this.state.searchData.search}
                onChange={this.searchChanged}
              />
            }

            {
              this.state.showAdd &&
              this.renderTableControls()
            }
          </div>
        </div>
        <Paper md={12}>
          {this.renderTable(this.state.tableData)}
        </Paper>

        <Drawer id='drawer' anchor='right' open={this.showDrawer()} onClose={() => this.setPageState(PageState.View)} ModalProps={{disableBackdropClick: true,}} >
          <DrawerWrapper onBack={() => this.setPageState(PageState.View)}>
            {this.renderDrawerContent()}
          </DrawerWrapper>
        </Drawer>
      </Grid>
    );
  }

}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    changeFullScreen: Actions.changeFullScreen
  }, dispatch);
}

function mapStateToProps({ menuReducers, authReducers, siteDataReducers }) {
  return { menu: menuReducers, auth: authReducers, siteData: siteDataReducers };
}

export default withSnackbar(withRouter(connect(mapStateToProps, mapDispatchToProps)(TransporterList)));