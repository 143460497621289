import React from 'react';
import strings from "../../../../localization";
import { getError, hasError } from "../../../../functions/Validation";
import { Button, TextField, FormLabel, FormGroup, FormControlLabel, Checkbox, Radio, FormControl, RadioGroup } from "@material-ui/core";
import DatePickerControl from '../../../controls/DatePickerControl';
import Autocomplete from '@material-ui/lab/Autocomplete';

const UrnTakeoverRecordForm = ({
  onSubmit,
  onCancel,
  onChange,
  errors,
  data,
  isDisabled,
  handleChange,
  onAutocompleteChange,
  handleTextInputChange,
  transporters,
  reasonsOfBrought,
  storages,
  tombstones,
  letters,
  languages,
  letterTypes,
  flag
}) => {

  return (

    <form id='user-form'>
      <TextField
        label={strings.deceasedReception.form.identificationNumber}
        error={hasError(errors, 'identificationNumber')}
        helperText={getError(errors, 'identificationNumber')}
        fullWidth
        autoFocus
        name='identificationNumber'
        onChange={onChange}
        margin="normal"
        defaultValue={data.identificationNumber}
        value={data.identificationNumber}
        InputLabelProps={{
          shrink: true,
        }}
        disabled={isDisabled}
      />
      <TextField
        label={strings.deceasedReception.form.equippingOrder}
        error={hasError(errors, 'equippingOrder')}
        helperText={getError(errors, 'equippingOrder')}
        fullWidth
        name='equippingOrder'
        onChange={onChange}
        margin="normal"
        defaultValue={data.equippingOrder}
        value={data.equippingOrder}
        InputLabelProps={{
          shrink: true,
        }}
        disabled={isDisabled}
      />
      <TextField
        label={strings.deceasedReception.form.deceasedNameSurname + "*"}
        error={hasError(errors, 'deceasedNameSurname')}
        helperText={getError(errors, 'deceasedNameSurname')}
        fullWidth
        name='deceasedNameSurname'
        onChange={onChange}
        margin="normal"
        defaultValue={data.deceasedNameSurname}
        value={data.deceasedNameSurname}
        InputLabelProps={{
          shrink: true,
        }}
        disabled={isDisabled}
      />
      <DatePickerControl
        label={strings.deceasedReception.form.dateOfDeath}
        error={getError(errors, 'dateOfDeath')}
        hasError={hasError(errors, 'dateOfDeath')}
        helperText={getError(errors, 'dateOfDeath')}
        placeholder={strings.deceasedReception.form.dateOfDeath}
        name='dateOfDeath'
        onChange={onChange}
        date={data.dateOfDeath ? new Date(data.dateOfDeath) : data.dateOfDeath}
        isClearable={true}
        dateFormat='dd/MM/yyyy'
        disabled={isDisabled}
      />
      <TextField
        label={strings.deceasedReception.form.placeOfDeath}
        error={hasError(errors, 'placeOfDeath')}
        helperText={getError(errors, 'placeOfDeath')}
        fullWidth
        name='placeOfDeath'
        onChange={onChange}
        margin="normal"
        defaultValue={data.placeOfDeath}
        value={data.placeOfDeath}
        InputLabelProps={{
          shrink: true,
        }}
        disabled={isDisabled}
      />
      <DatePickerControl
        label={strings.deceasedReception.form.deliveryDateTime}
        error={getError(errors, 'deliveryDateTime')}
        hasError={hasError(errors, 'deliveryDateTime')}
        helperText={getError(errors, 'deliveryDateTime')}
        placeholder={strings.deceasedReception.form.deliveryDateTime}
        name='deliveryDateTime'
        onChange={onChange}
        date={data.deliveryDateTime ? new Date(data.deliveryDateTime) : data.deliveryDateTime}
        isClearable={true}
        dateFormat='dd/MM/yyyy HH:mm'
        showTimeSelect={true}
        disabled={isDisabled}
      />

      {(transporters && ((data.transporter && flag == "edit") || (flag == "add") || (data.transporter && flag == "view"))) &&
        <Autocomplete
          id={"transporter"}
          name={"transporter"}
          options={transporters}
          getOptionLabel={option => option.name}
          onChange={onAutocompleteChange}
          renderInput={params => (
            <TextField
              {...params}
              variant="standard"
              label={strings.deceasedReception.form.transporter}
              margin="normal"
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
          disabled={isDisabled}
          value={data.transporter}
        />
      }

      {(transporters && ((!data.transporter && flag == "edit") || (!data.transporter && flag == "view"))) &&
        <Autocomplete
          id={"transporter"}
          name={"transporter"}
          options={transporters}
          getOptionLabel={option => option.name}
          onChange={onAutocompleteChange}
          renderInput={params => (
            <TextField
              {...params}
              variant="standard"
              label={strings.deceasedReception.form.transporter}
              margin="normal"
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
          disabled={isDisabled}
          value={data.transporter}
        />
      }

      <TextField
        label={strings.deceasedReception.form.driverNameSurname}
        error={hasError(errors, 'driverNameSurname')}
        helperText={getError(errors, 'driverNameSurname')}
        fullWidth
        name='driverNameSurname'
        onChange={onChange}
        margin="normal"
        defaultValue={data.driverNameSurname}
        value={data.driverNameSurname}
        InputLabelProps={{
          shrink: true,
        }}
        disabled={isDisabled}
      />
      <TextField
        label={strings.deceasedReception.form.registrationPlates}
        error={hasError(errors, 'registrationPlates')}
        helperText={getError(errors, 'registrationPlates')}
        fullWidth
        name='registrationPlates'
        onChange={onChange}
        margin="normal"
        defaultValue={data.registrationPlates}
        value={data.registrationPlates}
        InputLabelProps={{
          shrink: true,
        }}
        disabled={isDisabled}
      />

      {(reasonsOfBrought && ((data.reasonOfBrought && flag == "edit") || (flag == "add") || (data.reasonOfBrought && flag == "view"))) &&
        <Autocomplete
          id={"reasonOfBrought"}
          name={"reasonOfBrought"}
          options={reasonsOfBrought}
          getOptionLabel={option => option.name}
          onChange={onAutocompleteChange}
          renderInput={params => (
            <TextField
              {...params}
              variant="standard"
              label={strings.deceasedReception.form.urnReasonOfBrought}
              margin="normal"
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
          disabled={isDisabled}
          value={data.reasonOfBrought}
        />
      }
      {(reasonsOfBrought && ((!data.reasonOfBrought && flag == "edit") || (!data.reasonOfBrought && flag == "view"))) &&
        <Autocomplete
          id={"reasonOfBrought"}
          name={"reasonOfBrought"}
          options={reasonsOfBrought}
          getOptionLabel={option => option.name}
          onChange={onAutocompleteChange}
          renderInput={params => (
            <TextField
              {...params}
              variant="standard"
              label={strings.deceasedReception.form.urnReasonOfBrought}
              margin="normal"
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
          disabled={isDisabled}
          value={data.reasonOfBrought}
        />
      }


      <FormControl component="fieldset">
        <FormLabel className="checkboxLabel"> {strings.deceasedReception.form.reportedFuneral} </FormLabel>
        <RadioGroup name="reportedFuneral" value={(data.reportedFuneral == true || data.reportedFuneral == "true") ? "true" : "false"} onChange={handleChange} className="radioGroup">
          <FormControlLabel value={"true"} disabled={isDisabled} control={<Radio />} label={<span className="formControlLabel">{strings.deceasedReception.form.reported}</span>} />
          <FormControlLabel value={"false"} disabled={isDisabled} control={<Radio />} label={<span className="formControlLabel">{strings.deceasedReception.form.notReported}</span>} />
        </RadioGroup>
      </FormControl>

      {(storages && ((data.storage && flag == "edit") || (flag == "add") || (data.storage && flag == "view"))) &&
        <Autocomplete
          id={"storage"}
          name={"storage"}
          options={storages}
          getOptionLabel={option => option.name}
          onChange={onAutocompleteChange}
          renderInput={params => (
            <TextField
              {...params}
              variant="standard"
              label={strings.deceasedReception.form.urnStorage}
              margin="normal"
              fullWidth
              InputLabelProps={{
                shrink: true
              }}
            />
          )}
          disabled={isDisabled}
          value={data.storage}
        />
      }

      {(storages && ((!data.storage && flag == "edit") || (!data.storage && flag == "view"))) &&
        <Autocomplete
          id={"storage"}
          name={"storage"}
          options={storages}
          getOptionLabel={option => option.name}
          onChange={onAutocompleteChange}
          renderInput={params => (
            <TextField
              {...params}
              variant="standard"
              label={strings.deceasedReception.form.urnStorage}
              margin="normal"
              fullWidth
              InputLabelProps={{
                shrink: true
              }}
            />
          )}
          disabled={isDisabled}
          value={data.storage}
        />
      }

      <TextField
        label={strings.deceasedReception.form.boxNumber}
        error={hasError(errors, 'boxNumber')}
        helperText={getError(errors, 'boxNumber')}
        fullWidth
        name='boxNumber'
        onChange={onChange}
        margin="normal"
        defaultValue={data.boxNumber}
        value={data.boxNumber}
        InputLabelProps={{
          shrink: true,
        }}
        disabled={isDisabled}
      />
      <FormControl component="fieldset">
        <FormLabel className="checkboxLabel"> {strings.deceasedReception.form.urnLaidInStorage} </FormLabel>
        <RadioGroup name="urnLaidInStorage" value={(data.urnLaidInStorage == true || data.urnLaidInStorage == "true") ? "true" : "false"} onChange={handleChange} className="radioGroup">
          <FormControlLabel value={"true"} disabled={isDisabled} control={<Radio />} label={<span className="formControlLabel">{strings.deceasedReception.form.yes}</span>} />
          <FormControlLabel value={"false"} disabled={isDisabled} control={<Radio />} label={<span className="formControlLabel">{strings.deceasedReception.form.no}</span>} />
        </RadioGroup>
      </FormControl>

      {(tombstones && ((data.tombstone && flag == "edit") || (flag == "add") || (data.tombstone && flag == "view"))) &&
        <Autocomplete
          id={"tombstone"}
          name={"tombstone"}
          options={tombstones}
          getOptionLabel={option => option.name}
          onChange={onAutocompleteChange}
          renderInput={params => (
            <TextField
              {...params}
              variant="standard"
              label={strings.deceasedReception.form.tombstones}
              margin="normal"
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
          disabled={isDisabled}
          value={data.tombstone}
        />
      }
      {(tombstones && ((!data.tombstone && flag == "edit") || (!data.tombstone && flag == "view"))) &&
        <Autocomplete
          id={"tombstone"}
          name={"tombstone"}
          options={tombstones}
          getOptionLabel={option => option.name}
          onChange={onAutocompleteChange}
          renderInput={params => (
            <TextField
              {...params}
              variant="standard"
              label={strings.deceasedReception.form.tombstones}
              margin="normal"
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
          disabled={isDisabled}
          value={data.tombstone}
        />
      }

      {(letters && ((data.letter && flag == "edit") || (flag == "add") || (data.letter && flag == "view"))) &&
        <Autocomplete
          id={"letter"}
          name={"letter"}
          options={letters}
          getOptionLabel={option => option.name}
          onChange={onAutocompleteChange}
          renderInput={params => (
            <TextField
              {...params}
              variant="standard"
              label={strings.deceasedReception.form.urnLetter}
              margin="normal"
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
          disabled={isDisabled}
          value={data.letter}
        />
      }
      {(letters && ((!data.letter && flag == "edit") || (!data.letter && flag == "view"))) &&
        <Autocomplete
          id={"letter"}
          name={"letter"}
          options={letters}
          getOptionLabel={option => option.name}
          onChange={onAutocompleteChange}
          renderInput={params => (
            <TextField
              {...params}
              variant="standard"
              label={strings.deceasedReception.form.urnLetter}
              margin="normal"
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
          disabled={isDisabled}
          value={data.letter}
        />
      }

      {(languages && ((data.language && flag == "edit") || (flag == "add") || (data.language && flag == "view"))) &&
        <Autocomplete
          id={"language"}
          name={"language"}
          options={languages}
          getOptionLabel={option => option.name}
          onChange={onAutocompleteChange}
          renderInput={params => (
            <TextField
              {...params}
              variant="standard"
              label={strings.deceasedReception.form.language}
              margin="normal"
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
          disabled={isDisabled}
          value={data.language}
        />
      }
      {(languages && ((!data.language && flag == "edit") || (!data.language && flag == "view"))) &&
        <Autocomplete
          id={"language"}
          name={"language"}
          options={languages}
          getOptionLabel={option => option.name}
          onChange={onAutocompleteChange}
          renderInput={params => (
            <TextField
              {...params}
              variant="standard"
              label={strings.deceasedReception.form.language}
              margin="normal"
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
          disabled={isDisabled}
          value={data.language}
        />
      }

      {/* 
            <TextField
                label={strings.deceasedReception.form.letterType}
                error={hasError(errors, 'letterType')}
                helperText={getError(errors, 'letterType')}
                fullWidth
                name='letterType'
                onChange={onChange}
                margin="normal"
                defaultValue={data.letterType}
                value={data.letterType}
                InputLabelProps={{
                    shrink: true,
                }}
                disabled={isDisabled}
            /> */}

      {(letterTypes && ((data.letterType && flag == "edit") || (flag == "add") || (data.letterType && flag == "view"))) &&
        <Autocomplete
          id={"letterType"}
          name={"letterType"}
          options={letterTypes}
          getOptionLabel={option => option.name}
          onChange={onAutocompleteChange}
          renderInput={params => (
            <TextField
              {...params}
              variant="standard"
              label={strings.deceasedReception.form.letterType}
              margin="normal"
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
          disabled={isDisabled}
          value={data.letterType}
        />
      }
      {(letterTypes && ((!data.letterType && flag == "edit") || (!data.letterType && flag == "view"))) &&
        <Autocomplete
          id={"letterType"}
          name={"letterType"}
          options={letterTypes}
          getOptionLabel={option => option.name}
          onChange={onAutocompleteChange}
          renderInput={params => (
            <TextField
              {...params}
              variant="standard"
              label={strings.deceasedReception.form.letterType}
              margin="normal"
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
          disabled={isDisabled}
          value={data.letterType}
        />
      }

      <TextField
        label={strings.deceasedReception.form.urnDamages}
        error={hasError(errors, 'damages')}
        helperText={getError(errors, 'damages')}
        fullWidth
        name='damages'
        onChange={onChange}
        margin="normal"
        defaultValue={data.damages}
        value={data.damages}
        InputLabelProps={{
          shrink: true,
        }}
        disabled={isDisabled}
      />
      <TextField
        label={strings.deceasedReception.form.numberOrders}
        error={hasError(errors, 'numberOrders')}
        helperText={getError(errors, 'numberOrders')}
        fullWidth
        name='numberOrders'
        onChange={onChange}
        margin="normal"
        defaultValue={data.numberOrders}
        value={data.numberOrders}
        InputLabelProps={{
          shrink: true,
        }}
        disabled={isDisabled}
      />
      <TextField
        label={strings.deceasedReception.form.numberRings}
        error={hasError(errors, 'numberRings')}
        helperText={getError(errors, 'numberRings')}
        fullWidth
        name='numberRings'
        onChange={onChange}
        margin="normal"
        defaultValue={data.numberRings}
        value={data.numberRings}
        InputLabelProps={{
          shrink: true,
        }}
        disabled={isDisabled}
      />
      <TextField
        label={strings.deceasedReception.form.numberJewelry}
        error={hasError(errors, 'numberJewelry')}
        helperText={getError(errors, 'numberJewelry')}
        fullWidth
        name='numberJewelry'
        onChange={onChange}
        margin="normal"
        defaultValue={data.numberJewelry}
        value={data.numberJewelry}
        InputLabelProps={{
          shrink: true,
        }}
        disabled={isDisabled}
      />
      <DatePickerControl
        label={strings.deceasedReception.form.dateOfTakeover}
        error={getError(errors, 'dateOfTakeover')}
        hasError={hasError(errors, 'dateOfTakeover')}
        helperText={getError(errors, 'dateOfTakeover')}
        placeholder={strings.deceasedReception.form.dateOfTakeover}
        name='dateOfTakeover'
        onChange={onChange}
        date={data.dateOfTakeover ? new Date(data.dateOfTakeover) : data.dateOfTakeover}
        isClearable={true}
        dateFormat='dd/MM/yyyy'
        disabled={isDisabled}
      />
      <TextField
        label={strings.deceasedReception.form.urnTookOverBy}
        error={hasError(errors, 'deceasedTookOverBy')}
        helperText={getError(errors, 'deceasedTookOverBy')}
        fullWidth
        name='deceasedTookOverBy'
        onChange={onChange}
        margin="normal"
        defaultValue={data.deceasedTookOverBy}
        value={data.deceasedTookOverBy}
        InputLabelProps={{
          shrink: true,
        }}
        disabled={isDisabled}
      />
      <TextField
        label={strings.deceasedReception.form.urnBroughtBy}
        error={hasError(errors, 'deceasedBroughtBy')}
        helperText={getError(errors, 'deceasedBroughtBy')}
        fullWidth
        name='deceasedBroughtBy'
        onChange={onChange}
        margin="normal"
        defaultValue={data.deceasedBroughtBy}
        value={data.deceasedBroughtBy}
        InputLabelProps={{
          shrink: true,
        }}
        disabled={isDisabled}
      />
      <TextField
        multiline={true}
        rowsMax={5}
        label={strings.deceasedReception.form.note}
        error={hasError(errors, 'note')}
        helperText={getError(errors, 'note')}
        fullWidth
        name='note'
        onChange={onChange}
        margin="normal"
        defaultValue={data.note}
        value={data.note}
        InputLabelProps={{
          shrink: true,
        }}
        disabled={isDisabled}
      />

      {
        !isDisabled &&
        <div className='submit-container'>
          <Button variant="contained" color="primary" onClick={onSubmit}>
            {strings.deceasedReception.form.ok}
          </Button>
          <Button variant="contained" color="secondary" onClick={onCancel}>
            {strings.deceasedReception.form.cancel}
          </Button>
        </div>
      }
    </form>
  )
};

export default UrnTakeoverRecordForm;