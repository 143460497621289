import React from 'react';
import strings from "../../../../localization";
import { getError, hasError } from "../../../../functions/Validation";
import { Button, TextField, FormLabel, FormGroup, FormControlLabel, Checkbox, Radio, FormControl, RadioGroup } from "@material-ui/core";
import DatePickerControl from '../../../controls/DatePickerControl';
import Autocomplete from '@material-ui/lab/Autocomplete';

const EquipmentTakeoverRecordForm = ({
  onSubmit,
  onCancel,
  onChange,
  errors,
  data,
  isDisabled,
  handleChange,
  onAutocompleteChange,
  handleTextInputChange,
  deceasedBroughtIns,
  coffinTypes,
  tombstones,
  letters,
  languages,
  letterTypes,
  flag
}) => {

  return (

    <form id='user-form'>

      {(deceasedBroughtIns && ((data.deceasedBroughtIn && flag == "edit") || (flag == "add") || (data.deceasedBroughtIn && flag == "view"))) &&

        <Autocomplete
          id={"deceasedBroughtIn"}
          name={"deceasedBroughtIn"}
          options={deceasedBroughtIns}
          getOptionLabel={option => option.name}
          onChange={onAutocompleteChange}
          renderInput={params => (
            <TextField
              {...params}
              variant="standard"
              label={strings.deceasedReception.form.deceasedBroughtIn}
              margin="normal"
              fullWidth
              InputLabelProps={{
                shrink: true
              }}
            />
          )}
          disabled={isDisabled}
          value={data.deceasedBroughtIn}
        />
      }

      {(deceasedBroughtIns && ((!data.deceasedBroughtIn && flag == "edit") || (!data.deceasedBroughtIn && flag == "view"))) &&

        <Autocomplete
          id={"deceasedBroughtIn"}
          name={"deceasedBroughtIn"}
          options={deceasedBroughtIns}
          getOptionLabel={option => option.name}
          onChange={onAutocompleteChange}
          renderInput={params => (
            <TextField
              {...params}
              variant="standard"
              label={strings.deceasedReception.form.deceasedBroughtIn}
              margin="normal"
              fullWidth
              InputLabelProps={{
                shrink: true
              }}
            />
          )}
          disabled={isDisabled}
          value={data.deceasedBroughtIn}
        />
      }

      <FormControl component="fieldset">
        <FormLabel className="checkboxLabel"> {strings.deceasedReception.form.coffinBroughtAfter} </FormLabel>
        <RadioGroup name="coffinBroughtAfter" value={(data.coffinBroughtAfter == true || data.coffinBroughtAfter == "true") ? "true" : "false"} onChange={handleChange} className="radioGroup">
          <FormControlLabel value={"true"} disabled={isDisabled} control={<Radio />} label={<span className="formControlLabel">{strings.deceasedReception.form.yes}</span>} />
          <FormControlLabel value={"false"} disabled={isDisabled} control={<Radio />} label={<span className="formControlLabel">{strings.deceasedReception.form.no}</span>} />
        </RadioGroup>
      </FormControl>
      <DatePickerControl
        label={strings.deceasedReception.form.dateTimeBroughtAfter}
        error={getError(errors, 'dateTimeBroughtAfter')}
        hasError={hasError(errors, 'dateTimeBroughtAfter')}
        helperText={getError(errors, 'dateTimeBroughtAfter')}
        placeholder={strings.deceasedReception.form.dateTimeBroughtAfter}
        name='dateTimeBroughtAfter'
        onChange={onChange}
        date={data.dateTimeBroughtAfter ? new Date(data.dateTimeBroughtAfter) : data.dateTimeBroughtAfter}
        isClearable={true}
        dateFormat='dd/MM/yyyy HH:mm'
        showTimeSelect={true}
        disabled={isDisabled}
      />
      <FormControl component="fieldset">
        <FormLabel className="checkboxLabel"> {strings.deceasedReception.form.standardCoffin} </FormLabel>
        <RadioGroup name="standardCoffin" value={(data.standardCoffin == true || data.standardCoffin == "true") ? "true" : "false"} onChange={handleChange} className="radioGroup">
          <FormControlLabel value={"true"} disabled={isDisabled} control={<Radio />} label={<span className="formControlLabel">{strings.deceasedReception.form.yes}</span>} />
          <FormControlLabel value={"false"} disabled={isDisabled} control={<Radio />} label={<span className="formControlLabel">{strings.deceasedReception.form.no}</span>} />
        </RadioGroup>
      </FormControl>

      {(coffinTypes && ((data.coffinType && flag == "edit") || (flag == "add") || (data.coffinType && flag == "view"))) &&
        <Autocomplete
          id={"coffinType"}
          name={"coffinType"}
          options={coffinTypes}
          getOptionLabel={option => option.name}
          onChange={onAutocompleteChange}
          renderInput={params => (
            <TextField
              {...params}
              variant="standard"
              label={strings.deceasedReception.form.coffinType}
              margin="normal"
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
          disabled={isDisabled}
          value={data.coffinType}
        />
      }
      {(coffinTypes && ((!data.coffinType && flag == "edit") || (!data.coffinType && flag == "view"))) &&
        <Autocomplete
          id={"coffinType"}
          name={"coffinType"}
          options={coffinTypes}
          getOptionLabel={option => option.name}
          onChange={onAutocompleteChange}
          renderInput={params => (
            <TextField
              {...params}
              variant="standard"
              label={strings.deceasedReception.form.coffinType}
              margin="normal"
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
          disabled={isDisabled}
          value={data.coffinType}
        />
      }

      <TextField
        multiline={true}
        rowsMax={5}
        label={strings.deceasedReception.form.coffinNote}
        error={hasError(errors, 'coffinNote')}
        helperText={getError(errors, 'coffinNote')}
        fullWidth
        name='coffinNote'
        onChange={onChange}
        margin="normal"
        defaultValue={data.coffinNote}
        value={data.coffinNote}
        InputLabelProps={{
          shrink: true,
        }}
        disabled={isDisabled}
      />

      {(tombstones && ((data.tombstone && flag == "edit") || (flag == "add") || (data.tombstone && flag == "view"))) &&
        <Autocomplete
          id={"tombstone"}
          name={"tombstone"}
          options={tombstones}
          getOptionLabel={option => option.name}
          onChange={onAutocompleteChange}
          renderInput={params => (
            <TextField
              {...params}
              variant="standard"
              label={strings.deceasedReception.form.tombstones}
              margin="normal"
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
          disabled={isDisabled}
          value={data.tombstone}
        />
      }
      {(tombstones && ((!data.tombstone && flag == "edit") || (!data.tombstone && flag == "view"))) &&
        <Autocomplete
          id={"tombstone"}
          name={"tombstone"}
          options={tombstones}
          getOptionLabel={option => option.name}
          onChange={onAutocompleteChange}
          renderInput={params => (
            <TextField
              {...params}
              variant="standard"
              label={strings.deceasedReception.form.tombstones}
              margin="normal"
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
          disabled={isDisabled}
          value={data.tombstone}
        />
      }

      {(letters && ((data.letter && flag == "edit") || (flag == "add") || (data.letter && flag == "view"))) &&
        <Autocomplete
          id={"letter"}
          name={"letter"}
          options={letters}
          getOptionLabel={option => option.name}
          onChange={onAutocompleteChange}
          renderInput={params => (
            <TextField
              {...params}
              variant="standard"
              label={strings.deceasedReception.form.letter}
              margin="normal"
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
          disabled={isDisabled}
          value={data.letter}
        />
      }
      {(letters && ((!data.letter && flag == "edit") || (!data.letter && flag == "view"))) &&
        <Autocomplete
          id={"letter"}
          name={"letter"}
          options={letters}
          getOptionLabel={option => option.name}
          onChange={onAutocompleteChange}
          renderInput={params => (
            <TextField
              {...params}
              variant="standard"
              label={strings.deceasedReception.form.letter}
              margin="normal"
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
          disabled={isDisabled}
          value={data.letter}
        />
      }

      {(languages && ((data.language && flag == "edit") || (flag == "add") || (data.language && flag == "view"))) &&
        <Autocomplete
          id={"language"}
          name={"language"}
          options={languages}
          getOptionLabel={option => option.name}
          onChange={onAutocompleteChange}
          renderInput={params => (
            <TextField
              {...params}
              variant="standard"
              label={strings.deceasedReception.form.language}
              margin="normal"
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
          disabled={isDisabled}
          value={data.language}
        />
      }
      {(languages && ((!data.language && flag == "edit") || (!data.language && flag == "view"))) &&
        <Autocomplete
          id={"language"}
          name={"language"}
          options={languages}
          getOptionLabel={option => option.name}
          onChange={onAutocompleteChange}
          renderInput={params => (
            <TextField
              {...params}
              variant="standard"
              label={strings.deceasedReception.form.language}
              margin="normal"
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
          disabled={isDisabled}
          value={data.language}
        />
      }

      {(letterTypes && ((data.letterType && flag == "edit") || (flag == "add") || (data.letterType && flag == "view"))) &&
        <Autocomplete
          id={"letterType"}
          name={"letterType"}
          options={letterTypes}
          getOptionLabel={option => option.name}
          onChange={onAutocompleteChange}
          renderInput={params => (
            <TextField
              {...params}
              variant="standard"
              label={strings.deceasedReception.form.letterType}
              margin="normal"
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
          disabled={isDisabled}
          value={data.letterType}
        />
      }
      {(letterTypes && ((!data.letterType && flag == "edit") || (!data.letterType && flag == "view"))) &&
        <Autocomplete
          id={"letterType"}
          name={"letterType"}
          options={letterTypes}
          getOptionLabel={option => option.name}
          onChange={onAutocompleteChange}
          renderInput={params => (
            <TextField
              {...params}
              variant="standard"
              label={strings.deceasedReception.form.letterType}
              margin="normal"
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
          disabled={isDisabled}
          value={data.letterType}
        />
      }


      <TextField
        label={strings.deceasedReception.form.damages}
        error={hasError(errors, 'damages')}
        helperText={getError(errors, 'damages')}
        fullWidth
        name='damages'
        onChange={onChange}
        margin="normal"
        defaultValue={data.damages}
        value={data.damages}
        InputLabelProps={{
          shrink: true,
        }}
        disabled={isDisabled}
      />
      <TextField
        label={strings.deceasedReception.form.numberArtificialWreaths}
        error={hasError(errors, 'numberArtificialWreaths')}
        helperText={getError(errors, 'numberArtificialWreaths')}
        fullWidth
        name='numberArtificialWreaths'
        onChange={onChange}
        margin="normal"
        defaultValue={data.numberArtificialWreaths}
        value={data.numberArtificialWreaths}
        InputLabelProps={{
          shrink: true,
        }}
        disabled={isDisabled}
      />
      <TextField
        label={strings.deceasedReception.form.numberNaturalWreaths}
        error={hasError(errors, 'numberNaturalWreaths')}
        helperText={getError(errors, 'numberNaturalWreaths')}
        fullWidth
        name='numberNaturalWreaths'
        onChange={onChange}
        margin="normal"
        defaultValue={data.numberNaturalWreaths}
        value={data.numberNaturalWreaths}
        InputLabelProps={{
          shrink: true,
        }}
        disabled={isDisabled}
      />
      <TextField
        label={strings.deceasedReception.form.numberFlowersBouquet}
        error={hasError(errors, 'numberFlowersBouquet')}
        helperText={getError(errors, 'numberFlowersBouquet')}
        fullWidth
        name='numberFlowersBouquet'
        onChange={onChange}
        margin="normal"
        defaultValue={data.numberFlowersBouquet}
        value={data.numberFlowersBouquet}
        InputLabelProps={{
          shrink: true,
        }}
        disabled={isDisabled}
      />
      <TextField
        label={strings.deceasedReception.form.numberArrangements}
        error={hasError(errors, 'numberArrangements')}
        helperText={getError(errors, 'numberArrangements')}
        fullWidth
        name='numberArrangements'
        onChange={onChange}
        margin="normal"
        defaultValue={data.numberArrangements}
        value={data.numberArrangements}
        InputLabelProps={{
          shrink: true,
        }}
        disabled={isDisabled}
      />
      <TextField
        label={strings.deceasedReception.form.numberOrders}
        error={hasError(errors, 'numberOrders')}
        helperText={getError(errors, 'numberOrders')}
        fullWidth
        name='numberOrders'
        onChange={onChange}
        margin="normal"
        defaultValue={data.numberOrders}
        value={data.numberOrders}
        InputLabelProps={{
          shrink: true,
        }}
        disabled={isDisabled}
      />
      <TextField
        label={strings.deceasedReception.form.numberRings}
        error={hasError(errors, 'numberRings')}
        helperText={getError(errors, 'numberRings')}
        fullWidth
        name='numberRings'
        onChange={onChange}
        margin="normal"
        defaultValue={data.numberRings}
        value={data.numberRings}
        InputLabelProps={{
          shrink: true,
        }}
        disabled={isDisabled}
      />
      <TextField
        label={strings.deceasedReception.form.numberJewelry}
        error={hasError(errors, 'numberJewelry')}
        helperText={getError(errors, 'numberJewelry')}
        fullWidth
        name='numberJewelry'
        onChange={onChange}
        margin="normal"
        defaultValue={data.numberJewelry}
        value={data.numberJewelry}
        InputLabelProps={{
          shrink: true,
        }}
        disabled={isDisabled}
      />
      <DatePickerControl
        label={strings.deceasedReception.form.dateOfTakeover}
        error={getError(errors, 'dateOfTakeover')}
        hasError={hasError(errors, 'dateOfTakeover')}
        helperText={getError(errors, 'dateOfTakeover')}
        placeholder={strings.deceasedReception.form.dateOfTakeover}
        name='dateOfTakeover'
        onChange={onChange}
        date={data.dateOfTakeover ? new Date(data.dateOfTakeover) : data.dateOfTakeover}
        isClearable={true}
        dateFormat='dd/MM/yyyy'
        disabled={isDisabled}
      />
      <TextField
        label={strings.deceasedReception.form.deceasedTookOverBy}
        error={hasError(errors, 'deceasedTookOverBy')}
        helperText={getError(errors, 'deceasedTookOverBy')}
        fullWidth
        name='deceasedTookOverBy'
        onChange={onChange}
        margin="normal"
        defaultValue={data.deceasedTookOverBy}
        value={data.deceasedTookOverBy}
        InputLabelProps={{
          shrink: true,
        }}
        disabled={isDisabled}
      />
      <TextField
        label={strings.deceasedReception.form.deceasedBroughtBy}
        error={hasError(errors, 'deceasedBroughtBy')}
        helperText={getError(errors, 'deceasedBroughtBy')}
        fullWidth
        name='deceasedBroughtBy'
        onChange={onChange}
        margin="normal"
        defaultValue={data.deceasedBroughtBy}
        value={data.deceasedBroughtBy}
        InputLabelProps={{
          shrink: true,
        }}
        disabled={isDisabled}
      />
      <TextField
        multiline={true}
        rowsMax={5}
        label={strings.deceasedReception.form.note}
        error={hasError(errors, 'note')}
        helperText={getError(errors, 'note')}
        fullWidth
        name='note'
        onChange={onChange}
        margin="normal"
        defaultValue={data.note}
        value={data.note}
        InputLabelProps={{
          shrink: true,
        }}
        disabled={isDisabled}
      />

      {
        !isDisabled &&
        <div className='submit-container'>
          <Button variant="contained" color="primary" onClick={onSubmit}>
            {strings.deceasedReception.form.ok}
          </Button>
          <Button variant="contained" color="secondary" onClick={onCancel}>
            {strings.deceasedReception.form.cancel}
          </Button>
        </div>
      }
    </form>
  )
};

export default EquipmentTakeoverRecordForm;