import React from 'react'
import TablePage from "../../common/TablePage";
import { bindActionCreators } from "redux";
import * as Actions from "../../actions/Actions";
import { withRouter } from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import strings from "../../localization";
import { withSnackbar } from "notistack";
import { getMainBooksOfBuried, getDeletedMainBooksOfBuried, deleteMainBookOfBuried,restoreMainBookOfBuried } from '../../services/mainBookOfBuried/MainBookOfBuriedService';
import AddMainBookOfBuried from './AddMainBookOfBuried';
import EditMainBookOfBuried from './EditMainBookOfBuried';
import ViewMainBookOfBuried from './ViewMainBookOfBuried';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { Drawer, TextField, TableRow, TableBody, Table, LinearProgress, FormControlLabel, Switch } from "@material-ui/core";
import PageState from '../../constants/PageState';
import DrawerWrapper from '../../common/DrawerWrapper';
import SelectControl from '../../components/controls/SelectControl';
import IconButton from "@material-ui/core/IconButton";
import update from 'immutability-helper';
import { getMainBookOfBuriedSelect, downloadReport } from '../../util/ReviewsUtil';
import { generateMainBookOfBuriedReview } from '../../services/report/ReportService';
import { getMonths, getYears } from '../../util/DateUtil';
import { getAllGraveyards } from '../../services/graveyard/GraveyardService';
import { getFamousCitizenById, getFamousCitizens } from '../../services/famousCitizen/FamousCitizenService';
import { isFormValid, validatePersonalNumber } from '../../functions/Validation';
import { addCitizen } from '../../services/citizen/CitizenService';
import Validators from '../../constants/ValidatorTypes';
import { addPostalAddressCode, getAllPostalAddressCodes } from '../../services/postalAddressCode/PostalAddressCodeService';
import PostalAddressCodeForm from '../../components/forms/postalAddressCode/PostalAddressCodeForm';
import { getPostalCodes } from '../../services/postalCode/PostalCodeService';
import { getSettlements } from '../../services/settlement/SettlementService';
import { getStates } from '../../services/state/StateService';
import CitizenForm from '../../components/forms/citizen/CitizenForm';
import { getPostalCodesImproved } from '../../services/postalCodeImproved/PostalCodeImprovedService';
import { getPostalAddressCodesWithParams } from '../../services/postalAddressCodeImproved/PostalAddressCodeServiceImproved';

class MainBookOfBuriedList extends TablePage {

  tableDescription = [
    { key: 'mainBookOfBuries', label: strings.mainBookOfBuriedList.mainBookOfBuries, sort: 'mainBookOfBuries' },
    { key: 'deceasedNameSurname', label: strings.mainBookOfBuriedList.deceasedNameSurname, sort: 'deceasedNameSurname' },
    { key: 'graveyardName', label: strings.mainBookOfBuriedList.graveyard, sort: 'graveyardCode' },
    { key: 'graveSiteCode', label: strings.mainBookOfBuriedList.graveSite, sort: 'graveSiteCode' },
    { key: 'dateCreated', label: strings.mainBookOfBuriedList.dateCreated, transform: 'renderColumnDate', sort: 'dateCreated' },

  ];


  constructor(props) {
    super(props);

    const months = getMonths();
    months.unshift({ id: -1, name: strings.months.all })

    const years = getYears();
    years.unshift({ value: -1, name: strings.years.all })

    this.state = {
      ...this.state,
      selectMainBookOfBuried: [],
      showCitizen: false,
      checked: false,
      data: {
        selectedMainBookOfBuriedReview: { id: -1 },
        year: { value: -1 },
        month: { id: -1 },
        graveyard: { id: -1 },
      },
      filter: {
        months: months,
        years: years,
      },
      errors: {},
    };

    this.state.sortBy = "dateCreated,DESC";
    this.restartSelects = this.restartSelects.bind(this);

    this.changeData = this.changeData.bind(this);
    this.changeDataCitizen = this.changeDataCitizen.bind(this);
    this.renderAddCitizen = this.renderAddCitizen.bind(this);
    this.changeStateShowCitizen = this.changeStateShowCitizen.bind(this);
    this.submit = this.submit.bind(this);
    this.changeTypedInPersonalNumber = this.changeTypedInPersonalNumber.bind(this);

    this.state.showDeleted = false;
  }


  fetchData() {
    let data = {
      searchParam: this.state.searchData.search,
      page: this.state.searchData.page - 1,
      size: this.state.searchData.perPage,
      companyId: this.state.companyId,
      sort: this.state.sortBy,
      month: this.state.data.month ? this.state.data.month.id : "-1",
      year: this.state.data.year ? this.state.data.year.value : "-1",
      generate: false,
      graveyard: this.state.data.graveyard ? this.state.data.graveyard.id : -1,
      userId: this.props.auth.user.id
    };


    {
      (this.state.companyId != undefined && !this.state.showDeleted) &&
        getMainBooksOfBuried(data).then(response => {
          if (!response.ok) {
            return;
          }
          this.setState({
            tableData: response.data.entities,
            total: response.data.total,
          });
        });
    }

    {
      (this.state.companyId != undefined && this.state.showDeleted) &&
      getDeletedMainBooksOfBuried(data).then(response => {
         
        if (!response.ok) {
            return;
          }
          
          this.setState({
            tableData: response.data.entities,
            total: response.data.total,
          });
        });
    }
  }

  handleDeletedChange(){
    this.setState({
      showDeleted : !this.state.showDeleted
    },() =>{ this.fetchData(); })
    
  }

  usePermissions(){
    let data = {
      userId: this.props.auth.user.id,
      category: "Главна књига сахрањених"
    }
    this.getPermissionsS(data);
    this.removedItemsShow(this.props.removedItemsShow);

  }

  componentDidMount() {
    this.usePermissions()
    this.setState({
      companyId: this.props.auth.user.company.id,
      selectMainBookOfBuried: getMainBookOfBuriedSelect()
    }, () => {
      this.state.selectMainBookOfBuried.unshift({ id: -1, name: strings.reviewMainBookOfBuried.all });
    });

    getAllGraveyards().then(response => {
      this.setState({
        filter: {
          ...this.state.filter,
          graveyards: response.data.entities
        }
      }, () => this.state.filter.graveyards.unshift({ id: -1, name: strings.years.all }));
    });
  }

  componentWillReceiveProps(nextProps) {
    strings.setLanguage(nextProps.auth.user.language);
    this.tableDescription = [
      { key: 'mainBookOfBuries', label: strings.mainBookOfBuriedList.mainBookOfBuries, sort: 'mainBookOfBuries' },
      { key: 'deceasedNameSurname', label: strings.mainBookOfBuriedList.deceasedNameSurname, sort: 'deceasedNameSurname' },
      { key: 'graveyardName', label: strings.mainBookOfBuriedList.graveyard, sort: 'graveyardCode' },
      { key: 'graveSiteCode', label: strings.mainBookOfBuriedList.graveSite, sort: 'graveSiteCode' },
      { key: 'dateCreated', label: strings.mainBookOfBuriedList.dateCreated, transform: 'renderColumnDate', sort: 'dateCreated' },

    ];
    this.setState({
      selectMainBookOfBuried: getMainBookOfBuriedSelect()
    }, () => {
      if (this.state.selectMainBookOfBuried[0].id != -1) {
        this.state.selectMainBookOfBuried.unshift({ id: -1, name: strings.reviewMainBookOfBuried.all });
      }
      this.fetchData();
    });
  }

  getPageHeader() {
    return <h1>{strings.mainBookOfBuriedList.pageTitle}</h1>;
  }

  renderAddContent() {
    return <AddMainBookOfBuried
      cid={this.state.companyId}
      uemail={this.props.auth.user.email}
      onCancel={this.onCancel}
      onFinish={this.onFinish}
      flag={"add"}
      flagAddCitizen={this.renderAddCitizen}
      handler={this.changeTypedInPersonalNumber}
    />
  }

  renderEditContent() {
    return <EditMainBookOfBuried
      id={this.state.selectedItem.id}
      uemail={this.props.auth.user.email}
      cid={this.state.companyId}
      onCancel={this.onCancel}
      onFinish={this.onFinish}
      isDisabled={false}
      flag={"edit"}
      flagAddCitizen={this.renderAddCitizen}
      handler={this.changeTypedInPersonalNumber}
    />
  }

  renderViewContent() {

    return <ViewMainBookOfBuried
      id={this.state.selectedItem.id}
      isDisabled={true}
      onCancel={this.onCancel}
      onFinish={this.onFinish}
      flag={"view"}
    />
  }

  delete(item) {

    this.setState({
      lockTable: true
    });


    deleteMainBookOfBuried(item.id, this.props.auth.user.id).then(response => {

      if (response && !response.ok) {
        this.onFinish(null);
        return;
      }

      this.props.enqueueSnackbar(strings.companyList.mainBookOfBuriedDeleted, { variant: 'success' });

      this.onFinish(item);
      this.cancelDelete();

      this.setState({
        lockTable: false
      });
    });
  }

  restore(item){
    
    restoreMainBookOfBuried(this.transformRequest(item)).then(response => {
      if (response && !response.ok) {
        this.onFinish(null);
        return;
      }
      this.setState({
        showDeleted : !this.state.showDeleted,
      },() =>{ this.fetchData(); })
    })
  }


  transformRequest(data) {
    return {
        ...data,
        userEmail: this.props.auth.user.email,
        companyId: this.state.companyId
    }
  }


  generateMonthlyReport(data, value) {
    generateMainBookOfBuriedReview(data).then(response => {
      if (!response.ok) {
        return;
      }

      this.props.enqueueSnackbar(strings.mainBookOfBuriedList.PDF, { variant: 'success' });
      downloadReport(response, value);
    })
  }

  changeData(event, data = 'data') {
    this.setState({
      [data]: update(this.state[data], { [event.target.name]: { $set: event.target.value } })
    }, () => {
      if (this.state.data.selectedMainBookOfBuriedReview?.id === 1) {
        this.restartSelects(event.target.name);
        this.tableDescription = [
          { key: 'mainBookOfBuries', label: strings.mainBookOfBuriedList.mainBookOfBuries, sort: 'mainBookOfBuries' },
          { key: 'deceasedNameSurname', label: strings.mainBookOfBuriedList.deceasedNameSurname, sort: 'deceasedNameSurname' },
          { key: 'graveyardName', label: strings.mainBookOfBuriedList.graveyard, sort: 'graveyardCode' },
          { key: 'graveSiteCode', label: strings.mainBookOfBuriedList.graveSite, sort: 'graveSiteCode' },
          { key: 'dateCreated', label: strings.mainBookOfBuriedList.dateCreated, transform: 'renderColumnDate', sort: 'dateCreated' },
        ];
      } else if (this.state.data.selectedMainBookOfBuriedReview?.id === 2) {
        this.restartSelects(event.target.name);
        this.tableDescription = [
          { key: 'mainBookOfBuries', label: strings.mainBookOfBuriedList.mainBookOfBuries, sort: 'mainBookOfBuries' },
          { key: 'deceasedNameSurname', label: strings.mainBookOfBuriedList.deceasedNameSurname, sort: 'deceasedNameSurname' },
          { key: 'graveyardName', label: strings.mainBookOfBuriedList.graveyard, sort: 'graveyardCode' },
          { key: 'graveSiteCode', label: strings.mainBookOfBuriedList.graveSite, sort: 'graveSiteCode' },
          { key: 'dateCreated', label: strings.mainBookOfBuriedList.dateCreated, transform: 'renderColumnDate', sort: 'dateCreated' },
        ];
      } else if (this.state.data.selectedMainBookOfBuriedReview?.id === 3) {
        this.restartSelects(event.target.name);
        this.tableDescription = [
          { key: 'mainBookOfBuries', label: strings.mainBookOfBuriedList.mainBookOfBuries, sort: 'mainBookOfBuries' },
          { key: 'deceasedNameSurname', label: strings.mainBookOfBuriedList.deceasedNameSurname, sort: 'deceasedNameSurname' },
          { key: 'graveyardName', label: strings.mainBookOfBuriedList.graveyard, sort: 'graveyardCode' },
          { key: 'graveSiteCode', label: strings.mainBookOfBuriedList.graveSite, sort: 'graveSiteCode' },
          { key: 'dateCreated', label: strings.mainBookOfBuriedList.dateCreated, transform: 'renderColumnDate', sort: 'dateCreated' },
        ];
      } else if (this.state.data.selectedMainBookOfBuriedReview?.id === 4) {
        this.restartSelects(event.target.name);
        this.tableDescription = [
          { key: 'mainBookOfBuries', label: strings.mainBookOfBuriedList.mainBookOfBuries, sort: 'mainBookOfBuries' },
          { key: 'deceasedNameSurname', label: strings.mainBookOfBuriedList.deceasedNameSurname, sort: 'deceasedNameSurname' },
          { key: 'graveyardName', label: strings.mainBookOfBuriedList.graveyard, sort: 'graveyardCode' },
          { key: 'graveSiteCode', label: strings.mainBookOfBuriedList.graveSite, sort: 'graveSiteCode' },
          { key: 'dateCreated', label: strings.mainBookOfBuriedList.dateCreated, transform: 'renderColumnDate', sort: 'dateCreated' },
        ];
      } else {
        this.restartSelects(event.target.name);
        this.tableDescription = [
          { key: 'mainBookOfBuries', label: strings.mainBookOfBuriedList.mainBookOfBuries, sort: 'mainBookOfBuries' },
          { key: 'deceasedNameSurname', label: strings.mainBookOfBuriedList.deceasedNameSurname, sort: 'deceasedNameSurname' },
          { key: 'graveyardName', label: strings.mainBookOfBuriedList.graveyard, sort: 'graveyardCode' },
          { key: 'graveSiteCode', label: strings.mainBookOfBuriedList.graveSite, sort: 'graveSiteCode' },
          { key: 'dateCreated', label: strings.mainBookOfBuriedList.dateCreated, transform: 'renderColumnDate', sort: 'dateCreated' },
        ];
      }

      this.fetchData();
    });
  }

  restartSelects(eventName) {
    if (eventName == "selectedMainBookOfBuriedReview") {
      this.setState({
        data: {
          ...this.state.data,
          year: this.state.filter.years[0],
          month: this.state.filter.months[0],
          graveyard: { id: -1 },
        }
      }, () => this.fetchData())
    }
  }

  renderButtons() {
    let data = {
      companyId: this.state.companyId,
      selectedMainBookOfBuriedReview: this.state.data.selectedMainBookOfBuriedReview ? this.state.data.selectedMainBookOfBuriedReview.id : "",
      searchParam: this.state.searchData ? this.state.searchData.search : "",
      month: this.state.data.month ? this.state.data.month.id : "",
      year: this.state.data.year ? this.state.data.year.value : "",
      sort: this.state.sortBy,
      graveyardId: this.state.data.graveyard ? this.state.data.graveyard.id : "",
    };

    return (
      <div className="report-button">
        <IconButton onClick={() => this.generateMonthlyReport(data, this.state.data.selectedMainBookOfBuriedReview.value)} disabled={this.state.data.selectedMainBookOfBuriedReview != undefined ? (this.state.data.selectedMainBookOfBuriedReview.id == -1) : true}
        >
          {strings.mainBookOfBuried.buttons.generateReport}
        </IconButton>
      </div>
    );
  }

  renderCitizenForm() {
    return <Grid id='page' item md={12}>

      <div className='header'>
        <h1>{strings.citizen.pageTitle}</h1>
      </div>

      <Paper className='paper'>
        <CitizenForm
          onChange={this.changeDataCitizen}
          onSubmit={this.submit}
          data={this.state.data}
          errors={this.state.errors}
          onCancel={this.changeStateShowCitizen}
          handleChange={this.handleChange}
          onAutocompleteChange={this.onAutocompleteChange}
          handleTextInputChange={this.handleTextInputChange}
          onChangePersonalNumberField={this.onChangePersonalNumberField}
          flag={"add"}
          states={this.state.stateCodes == undefined ? [] : this.state.stateCodes}
          citiesAndZips={this.state.zip == undefined ? [] : this.state.zip}
        />
      </Paper>

    </Grid>
  }

  changeStateShowCitizen() {
    this.setState({
      ...this.state,
      showCitizen: false,
      data: {
        personalNumber: "",
        name: "",
        postalCode: "",
        street: "",
        streetNumber: "",
        surname: "",
        telephone: "",
      },
      errors: {},
    });
  }

  changeDataCitizen(event, data = 'data') {
    this.setState({
      [data]: update(this.state[data], { [event.target.name]: { $set: event.target.value } })
    }, () => {
      this.fetchData();
    });
  }

  getPucs () {
    let d = {
      street: this.state.data.street ? this.state.data.street : "", 
      companyId: this.state.companyId,
      sort: "pac,ASC",
      userId: 0,
      stateId: this.state.data.state ? this.state.data.state.id : 0,
      postalCodeImprovedId: this.state.data.postalCodeImproved ? this.state.data.postalCodeImproved.id : 0,
      streetNumber: this.state.data.streetNumber ? this.state.data.streetNumber : "",
    }   
      d.companyId != undefined &&
        getPostalAddressCodesWithParams(d).then(response =>{
        if(!response.ok){
          return;
        }
         this.setState({
          data: {
            ...this.state.data,
            pac: response.data.pac,

          }
          
        })
      });
  }

  handleTextInputChange = (e) => {
    if (e.target.name === "state" && e.target.value.trim().length >= 2) {
      let data = {
        searchParam: e.target.value,
        companyId: this.props.auth.user.company.id,
        sort: "name,ASC",
        userId: 0
      };

      {
        data.companyId != undefined &&
          getStates(data).then(response => {
            if (!response.ok) {
              return;
            }
            this.setState({
              stateCodes: response.data.entities,
            });
          });
      }
    }
    else if (e.target.name === "postalCodeImproved" && e.target.value.trim().length >= 2) {
      let data = {
        searchParam: e.target.value,
        companyId: this.props.auth.user.company.id,
        sort: 'city',
        userId: 0
      };

      {
        data.companyId != undefined &&
        getPostalCodesImproved(data).then(response => {
          if (!response.ok) {
            return;
          }
          this.setState({
            zip: response.data.entities,
          }, () => {
            this.getPucs()
          });
        });
      }
    }
    else if (e.target.name === "street") {
      this.setState({
        data: {
          ...this.state.data,
          street: e.target.value
        }
      }, () => {
        this.getPucs()
      });
    }
    else if (e.target.name === "streetNumber") {
      this.setState({
        data: {
          ...this.state.data,
          streetNumber: e.target.value
        }
      }, () => {
        this.getPucs()
      });
    }
  }

  onAutocompleteChange = (event, values) => {
    let autocompleteName = event.target.id.split("-")[0];
    this.setState({
      data: {
        ...this.state.data, [autocompleteName]: values
      }
    }, () => {
      this.getPucs()
    });
  }

  onChangePersonalNumberField = (event) => {
    let validPersonalNumber = validatePersonalNumber(event.target.value)

    if (!validPersonalNumber) {
      this.setError("personalNumber", strings.citizen.messages.CITIZEN_PERSONAL_NUMBER_NOT_VALID);
    }
    else {
      this.unsetError("personalNumber");
    }
    this.changeDataCitizen(event);
  }

  changeTypedInPersonalNumber(someValue) {
    this.setState({
      data: {
        personalNumber: someValue,
      },
    });
  }

  handleChange = event => {
    this.setState({ checked: event.target.checked }, () => {
      let validPersonalNumber = validatePersonalNumber(this.state.data.personalNumber)

      if (!validPersonalNumber) {
        this.setError("personalNumber", strings.citizen.messages.CITIZEN_PERSONAL_NUMBER_NOT_VALID);
      }
      else {
        this.unsetError("personalNumber");
      }
    })
  }

  submit() {
    if (!this.validate()) {
      return;
    }

    this.showDrawerLoader();
    
    addCitizen(this.transformRequestCitizen(this.state.data), this.state.companyId).then(response => {
      if (!response.ok) {
        let messageKey = response.response.data.message;
        this.props.enqueueSnackbar(strings.citizen.messages[messageKey], {
          variant: "error"
        });

        this.handleError(messageKey);

        return;
      }

      this.props.enqueueSnackbar(strings.addCompany.citizenAdded, { variant: 'success' });

      this.hideDrawerLoader();
      this.changeStateShowCitizen();
    });
  }

  transformRequestCitizen(data) {
    
    return {
      ...data,
      deceased: this.state.checked,
      userEmail: this.props.auth.user.email,
      stateCode: this.state.data.state ? this.state.data.state.id : null ,
      postalCodeImprovedId: this.state.data.postalCodeImproved ? this.state.data.postalCodeImproved.id : null
    }
  }

  renderAddCitizen() {
    this.setState({
      showCitizen: true,
    });
  }

  renderFilters() {
    return (
      <div className="renderFilters">

        { this.state.removedItemsShow &&
        <FormControlLabel
            control = {<Switch
              checked={this.state.showDeleted}
              onChange={this.handleDeletedChange}
              inputProps={{ 'aria-label': 'controlled' }}
              />}
              labelPlacement="start"
              label={!this.state.showDeleted ? "Prikazi obrisane" : "Prikazi neobrisane"}
          />}
        <SelectControl
          options={this.state.selectMainBookOfBuried}
          selected={this.state.data.selectedMainBookOfBuriedReview}
          onChange={this.changeData}
          label={strings.reviewEvidentation}
          name={"selectedMainBookOfBuriedReview"}
          nameKey={"name"}
          valueKey={"id"}
        />
        {this.state.data.selectedMainBookOfBuriedReview?.id === 3 &&
          <React.Fragment>
            <SelectControl
              options={this.state.filter.years}
              selected={this.state.data.year}
              onChange={this.changeData}
              label={strings.mainBookOfBuriedList.year}
              name={"year"}
              nameKey={"name"}
              valueKey={"value"}
            />

            <SelectControl
              options={this.state.filter.months}
              selected={this.state.data.month}
              onChange={this.changeData}
              label={strings.mainBookOfBuriedList.month}
              name={"month"}
              nameKey={"name"}
              valueKey={"id"}
            />
          </React.Fragment>
        }
        {this.state.data.selectedMainBookOfBuriedReview?.id === 4 &&
          <SelectControl
            options={this.state.filter.graveyards}
            selected={this.state.data.graveyard}
            onChange={this.changeData}
            label={strings.mainBookOfBuriedList.graveyard}
            name={"graveyard"}
            nameKey={"name"}
            valueKey={"id"}
          />
        }
      </div>
    );
  }

  renderTableRow(data = []) {

    let result = [];
    let expiryDate;
    let currentDate = new Date();
    let expiryDateIncremented;

    for (let item of data) {

      expiryDate = item.expiryDate != null ? new Date(item.expiryDate) : "";
      expiryDateIncremented = expiryDate;

      let className = 'table-row-' + result.length % 2;

      if (this.isRowSelected(item)) {
        className += ' selected';
      }

      if ((item.famousCitizenCode == "001" || item.famousCitizenCode == "002") && item.famousCitizenCode != null) {
        className = 'backgroundColorFamousCitizen';
      }

      result.push(
        <TableRow key={'table-row-' + result.length} className={className} onClick={() => this.selectRow(item)}>
          {this.renderRowMenu(result.length, item)}
          {this.renderTableRowData(item)}
        </TableRow>
      )
    }

    return (
      <TableBody>
        {result}
      </TableBody>
    );

  }

  render() {

    return (
      <Grid id='table-page'>
        {
          this.state.showCitizen &&
          <Drawer id='drawer' anchor='left' open={this.showDrawer()} onClose={() => this.changeStateShowCitizen()} ModalProps={{disableBackdropClick: true,}} >
            <DrawerWrapper onBack={() => this.changeStateShowCitizen()}>
              {this.renderCitizenForm()}
            </DrawerWrapper>
          </Drawer>
        }

        {this.renderDialog(strings.table.confirmDelete, strings.delete.confirmation,
          this.cancelDelete, this.delete)}
        <div className='header'>
          {this.getPageHeader()}

          <div className='filter-controls'>
            {
              this.renderButtons()
            }
            {
              this.renderFilters()
            }
            {
              this.state.showSearch &&
              <TextField
                label={strings.table.search}
                type="search"
                name='search'
                value={this.state.searchData.search}
                onChange={this.searchChanged}
              />
            }

            {
              this.state.showAdd &&
              this.renderTableControls()
            }
          </div>
        </div>
        <Paper md={12}>
          {this.renderTable(this.state.tableData)}
        </Paper>

        <Drawer id='drawer' anchor='right' open={this.showDrawer()} onClose={() => this.setPageState(PageState.View)} ModalProps={{disableBackdropClick: true,}} >
          <DrawerWrapper onBack={() => this.setPageState(PageState.View)}>
            {this.renderDrawerContent()}
          </DrawerWrapper>
        </Drawer>
      </Grid>
    );
  }

}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    changeFullScreen: Actions.changeFullScreen
  }, dispatch);
}

function mapStateToProps({ menuReducers, authReducers, siteDataReducers }) {
  return { menu: menuReducers, auth: authReducers, siteData: siteDataReducers };
}

export default withSnackbar(withRouter(connect(mapStateToProps, mapDispatchToProps)(MainBookOfBuriedList)));