import FormComponent from "../../common/FormComponent";
import React from "react";
import { bindActionCreators } from "redux";
import * as Actions from "../../actions/Actions";
import { withRouter } from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import Grid from "@material-ui/core/Grid";
import { Paper } from "@material-ui/core";
import strings from "../../localization";
import Validators from "../../constants/ValidatorTypes";
import { withSnackbar } from "notistack";
import CompanyForm from "../../components/forms/company/CompanyForm";
import { getCompanyById, editCompany } from "../../services/company/CompanyService";

class EditCompany extends FormComponent {
    validationList = {
        email: [{ type: Validators.EMAIL }],
        name: [{ type: Validators.REQUIRED }],
        registrationNumber: [{ type: Validators.REQUIRED }],
      };
    
      constructor(props) {
        super(props);
    
        this.state = {
          data: props.data ? props.data : {},
          errors: {}
        };
    
        this.props.changeFullScreen(false);
        this.submit = this.submit.bind(this);
      }
    
      componentDidMount(){

        getCompanyById(this.props.id).then(response => {
            this.setState({
                data: response.data
            });
        });
      }
    
      submit() {

        if (!this.validate()) {
          return;
        }
    
        this.showDrawerLoader();

        editCompany(this.transformRequest(this.state.data)).then(response => {
            if (!response.ok) {
              let messageKey = response.response.data.message;
        
              this.props.enqueueSnackbar(strings.company.messages[messageKey], {
                  variant: "error"
              });

              this.handleError(messageKey);

              return;
            }
      
            this.props.enqueueSnackbar(strings.company.editForm.edited, { variant: 'success' });
            this.props.onFinish(response.data.company);

            this.hideDrawerLoader();
          });
    
      }

      transformRequest(data) {
        return {
            ...data,
            actionUserEmail: this.props.uemail,
        }
    }

      handleError(message) {

        switch (message) {
          case "COMPANY_WITH_NAME_ALREADY_EXIST":
              this.setError("name", strings.company.messages.COMPANY_WITH_NAME_ALREADY_EXIST);
              break;
          case "COMPANY_WITH_EMAIL_ALREADY_EXIST":
              this.setError("email", strings.company.messages.COMPANY_WITH_EMAIL_ALREADY_EXIST);
              break;
          case "COMPANY_WITH_REGISTRATION_NUMBER_ALREADY_EXIST":
              this.setError("registrationNumber", strings.company.messages.COMPANY_WITH_REGISTRATION_NUMBER_ALREADY_EXIST);
              break;
      }
    }
    
      render() {

        return (
          <Grid id="page" item md={12}>
            <div className="header">
              <h1>{strings.company.editPageTitle}</h1>
            </div>
            
              <Paper className="paper">
                <CompanyForm
                  onChange={this.changeData}
                  onSubmit={this.submit}
                  data={this.state.data}
                  errors={this.state.errors}
                  onCancel={this.props.onCancel}
                  isDisabled={this.props.isDisabled}
                />
              </Paper>
            
          </Grid>
        );
      }
    }
    
    function mapDispatchToProps(dispatch) {

      return bindActionCreators(
        {
          changeFullScreen: Actions.changeFullScreen
        },
        dispatch
      );
    }
    
    function mapStateToProps({ menuReducers, siteDataReducers }) {

      return { menu: menuReducers, siteData: siteDataReducers };
    }

    export default withSnackbar(
        withRouter(
          connect(
            mapStateToProps,
            mapDispatchToProps
          )(EditCompany)
        )
      );
