import { withSnackbar } from "notistack";
import React from "react";
import { bindActionCreators } from "redux";
import * as Actions from "../../actions/Actions";
import { withRouter } from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import FormComponent from "../../common/FormComponent";
import PostalAddressCodeImprovedForm from "../../components/forms/postalAddressCodeImproved/PostalAddressCodeImprovedForm";
import { getPostalAddressCodeImprovedById } from "../../services/postalAddressCodeImproved/PostalAddressCodeServiceImproved";
import { getPostalCodeImprovedById } from "../../services/postalCodeImproved/PostalCodeImprovedService";
import { getStateById } from "../../services/state/StateService";
import Grid from "@material-ui/core/Grid";
import { Paper } from "@material-ui/core";
import strings from "../../localization";


class ViewPostalCodeImproved extends FormComponent {

    constructor(props) {
        super(props);

        this.state = {
            data: props.data ? props.data : {},
            errors: {},
        }

        this.props.changeFullScreen(false);
    }

    componentDidMount() {

        getPostalAddressCodeImprovedById(this.props.id).then(response => {
            this.setState({
                data: response.data
            }, () => {
                getPostalCodeImprovedById(this.state.data.postalCodeImprovedId).then(response => {
                    this.setState({
                        data: {
                            ...this.state.data,
                            postalCodeImproved: response.data,
                            postalCodeImprovedCityZip: response.data
                        },
                    });
                });
                getStateById(this.state.data.stateId).then(response => {
                    this.setState({
                        data: {
                            ...this.state.data,
                            state: response.data
                        },
                    });
                });
            });
        });
    }

    render() {

        return (
            <Grid id="page" item md={12}>
                <div className="header">
                    <h1>{strings.reservation.viewPageTitle}</h1>
                </div>

                <Paper className='paper'>
                    <PostalAddressCodeImprovedForm
                        onChange={this.changeData}
                        onSubmit={this.submit}
                        data={this.state.data}
                        errors={this.state.errors}
                        onCancel={this.props.onCancel}
                        isDisabled={this.props.isDisabled}
                        handleChange={this.handleChange}
                        onAutocompleteChange={this.onAutocompleteChange}
                        handleTextInputChange={this.handleTextInputChange}
                        states={this.state.states == undefined ? [] : this.state.states}
                        citiesAndZips={this.state.citiesAndZips == undefined ? [] : this.state.citiesAndZips}
                        flag={this.props.flag} />
                </Paper>

            </Grid>
        );
    }





}

function mapDispatchToProps(dispatch) {

    return bindActionCreators(
        {
            changeFullScreen: Actions.changeFullScreen
        },
        dispatch
    );
}

function mapStateToProps({ menuReducers, siteDataReducers }) {

    return { menu: menuReducers, siteData: siteDataReducers };
}

export default withSnackbar(withRouter(connect(mapStateToProps, mapDispatchToProps)(ViewPostalCodeImproved)));