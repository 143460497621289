import React, { Component } from 'react'
import { Link, withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import * as Actions from "../actions/Actions";

import connect from "react-redux/es/connect/connect";
import IconButton from '@material-ui/core/IconButton';

import MoreVert from '@material-ui/icons/MoreVert';
import MenuIcon from '@material-ui/icons/Menu';
import PersonIcon from '@material-ui/icons/Person';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import ListItem from "@material-ui/core/ListItem";

import MenuState from "../constants/MenuState";
import { ListItemIcon, ListItemText, Menu, MenuItem } from "@material-ui/core";
import strings from "../localization";
import { lock, logout, getUserFromLocalStorage } from "../base/OAuth";
import { changeLanguage } from '../services/user/UserService';


class Header extends Component {

    constructor(props) {
        super(props);

        this.state = {
            anchorEl: null,
            anchorElLang: null,
            language: 'rs',
        }

    }

    /** HANDLERS **/

    handleMenuClick(event) {
        this.setState({ anchorEl: event.currentTarget });
    }

    handleMenuClickLang(event) {
        this.setState({ anchorElLang: event.currentTarget });
    }

    handleMenuClose() {
        this.setState({ anchorEl: null });
    }

    handleMenuCloseLang() {
        this.setState({ anchorElLang: null });
    }

    logout() {
        logout();
        this.props.logout();
        this.props.history.push('/');
    }

    lock() {
        lock();
        this.props.history.push('/');
    }

    profile() {
        this.handleMenuClose();
    }

    changeToSerbian(){
        this.props.setActiveLanguage('rs');
        this.props.user.language = 'rs';
        changeLanguage(this.props.user.email, 'rs').then(response => {
            this.handleMenuCloseLang();
        });
    }

    changeToEnglish(){
        this.props.setActiveLanguage('en');
        this.props.user.language = 'en';
        changeLanguage(this.props.user.email, 'en').then(response => {
            this.handleMenuCloseLang();
        });
    }

    isCurrentPath(path) {

        return this.props.history.location.pathname == path;
    }

    getHeaderClass() {

        if (this.props.menu.state === MenuState.SHORT) {
            return 'short';
        }
        else {
            return '';
        }
    }

    render() {
        return (
            <div id='header' className={this.getHeaderClass()}>

                <div className='left'>

                    {
                        this.props.menu.state === MenuState.FULL &&
                        <IconButton size='small' onClick={() => this.props.changeMenuState(MenuState.SHORT)}>
                            <MoreVert />
                        </IconButton>
                    }

                    {
                        this.props.menu.state === MenuState.SHORT &&
                        <IconButton size='small' onClick={() => this.props.changeMenuState(MenuState.FULL)}>
                            <MenuIcon />
                        </IconButton>
                    }
                </div>
             
                <div className='language-select'>
                        <IconButton
                            size='small'
                            aria-owns={this.state.anchorElLang ? 'person-menu' : undefined}
                            aria-haspopup="true"
                            onClick={(event) => this.handleMenuClickLang(event)}
                        >
                            {this.props.user == null ? this.props.user == {} : this.props.user.language == 'rs' && <img src={'images/serbia.png'} />}
                            {this.props.user == null ? this.props.user == {} : this.props.user.language == 'en' && <img src={'images/united-kingdom.png'} />}
                        </IconButton>
                        <Menu
                            id='person-menu'
                            anchorEl={this.state.anchorElLang}
                            open={Boolean(this.state.anchorElLang)}
                            onClose={() => this.handleMenuCloseLang()}
                        >
                            <MenuItem onClick={ () => this.changeToSerbian() }>
                                <ListItemIcon>
                                <img src={'images/serbia.png'} />
                                </ListItemIcon>
                                <ListItemText primary={ strings.header.serbian }/>
                            </MenuItem>
                        <MenuItem onClick={ () => this.changeToEnglish() }>
                            <ListItemIcon>
                            <img src={'images/united-kingdom.png'} />
                            </ListItemIcon>
                            <ListItemText primary={ strings.header.english }/>
                        </MenuItem>
                        </Menu>
                    </div>

                {
                    getUserFromLocalStorage() != null &&
                    <div className='right'>
                        <IconButton
                            size='small'
                            aria-owns={this.state.anchorEl ? 'person-menu' : undefined}
                            aria-haspopup="true"
                            onClick={(event) => this.handleMenuClick(event)}
                        >
                            <PersonIcon />
                        </IconButton>
                        <Menu
                            id='person-menu'
                            anchorEl={this.state.anchorEl}
                            open={Boolean(this.state.anchorEl)}
                            onClose={() => this.handleMenuClose()}
                        >
                            <MenuItem onClick={() => this.profile()}>
                                <Link
                                    to={"/profile"}
                                >
                                    <ListItem>
                                        <ListItemIcon>
                                            <img src={'images/user.png'} />
                                        </ListItemIcon>

                                        <ListItemText
                                            primary={strings.profile.profile}
                                        />
                                    </ListItem>
                                </Link>
                            </MenuItem>
                            <MenuItem onClick={() => this.logout()}>
                                <Link
                                    to={"/"}
                                >
                                    <ListItem>
                                        <ListItemIcon>
                                            <ExitToAppIcon />
                                        </ListItemIcon>

                                        <ListItemText
                                            primary={strings.header.logout}
                                        />
                                    </ListItem>
                                </Link>
                            </MenuItem>
                        </Menu>
                    </div>
                }
                {getUserFromLocalStorage() === null &&
                    <Link
                        to={"/login"}
                        className={
                            this.isCurrentPath("/login")
                                ? "navigation-link active"
                                : "navigation-link"
                        }
                    >
                        <ListItem className="navigation-item">
                            <ListItemText
                                primary={strings.profile.login}
                                className="navigation-text"
                            />
                        </ListItem>
                    </Link>
                }

            </div>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        changeMenuState: Actions.changeMenuState,
        logout: Actions.logout,
        setActiveLanguage: Actions.setActiveLanguage
    }, dispatch);
}

function mapStateToProps({ menuReducers, authReducers, siteDataReducers }) {
    return { menu: menuReducers, user: authReducers.user, siteData: siteDataReducers };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));