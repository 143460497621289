import React from 'react';
import strings from "../../../localization";
import { getError, hasError } from "../../../functions/Validation";
import { Button, TextField } from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';

const PostalAddresCodeform = ({
  onSubmit,
  onCancel,
  onChange,
  onAutocompleteChange,
  handleTextInputChange,
  data,
  errors,
  isDisabled,
  postalNumbers,
  settlementCodes,
  stateCodes,
  flag,
}) => {

  return (
    <form id='user-form'>


      {((data.state && flag == "edit") || (flag == "add") || (data.state && flag == "view")) &&
        <Autocomplete
          id={"state"}
          name={"state"}
          options={stateCodes}
          getOptionLabel={option => option.code}
          onChange={onAutocompleteChange}
          renderInput={params => (
            <TextField
              {...params}
              variant="standard"
              label={strings.postalAddressCode.form.stateCode + "*"}
              error={hasError(errors, 'state')}
              helperText={getError(errors, 'state')}
              margin="normal"
              fullWidth
              name={"state"}
              onChange={handleTextInputChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
          disabled={isDisabled}
          value={data.state}
        />
      }
      {((!data.state && flag == "edit") || (!data.state && flag == "view")) &&
        <Autocomplete
          id={"state"}
          name={"state"}
          options={stateCodes}
          getOptionLabel={option => option.code}
          onChange={onAutocompleteChange}
          renderInput={params => (
            <TextField
              {...params}
              variant="standard"
              label={strings.postalAddressCode.form.stateCode + "*"}
              error={hasError(errors, 'state')}
              helperText={getError(errors, 'state')}
              margin="normal"
              fullWidth
              name={"state"}
              onChange={handleTextInputChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
          disabled={isDisabled}
          value={data.state}
        />
      }

      {((data.settlement && flag == "edit") || (flag == "add") || (data.settlement && flag == "view")) &&
        <Autocomplete
          id={"settlement"}
          name={"settlement"}
          options={settlementCodes}
          getOptionLabel={option => option.code}
          onChange={onAutocompleteChange}
          renderInput={params => (
            <TextField
              {...params}
              variant="standard"
              label={strings.postalAddressCode.form.settlementCode + "*"}
              error={hasError(errors, 'settlement')}
              helperText={getError(errors, 'settlement')}
              margin="normal"
              fullWidth
              name={"settlement"}
              onChange={handleTextInputChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
          disabled={isDisabled}
          value={data.settlement}
        />
      }
      {((!data.settlement && flag == "edit") || (!data.settlement && flag == "view")) &&
        <Autocomplete
          id={"settlement"}
          name={"settlement"}
          options={settlementCodes}
          getOptionLabel={option => option.code}
          onChange={onAutocompleteChange}
          renderInput={params => (
            <TextField
              {...params}
              variant="standard"
              label={strings.postalAddressCode.form.settlementCode + "*"}
              error={hasError(errors, 'settlement')}
              helperText={getError(errors, 'settlement')}
              margin="normal"
              fullWidth
              name={"settlement"}
              onChange={handleTextInputChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
          disabled={isDisabled}
          value={data.settlement}
        />
      }

      <TextField
        label={strings.postalAddressCode.form.place + '*'}
        error={hasError(errors, 'place')}
        helperText={getError(errors, 'place')}
        fullWidth
        autoFocus
        name='place'
        onChange={onChange}
        margin="normal"
        defaultValue={data.place}
        value={data.place}
        InputLabelProps={{
          shrink: true,
        }}
        disabled={isDisabled}
      />

      <TextField
        label={strings.postalAddressCode.form.street + '*'}
        error={hasError(errors, 'street')}
        helperText={getError(errors, 'street')}
        fullWidth
        name='street'
        onChange={onChange}
        margin="normal"
        defaultValue={data.street}
        value={data.street}
        InputLabelProps={{
          shrink: true,
        }}
        disabled={isDisabled}
      />

      <TextField
        label={strings.postalAddressCode.form.number + '*'}
        error={hasError(errors, 'number')}
        helperText={getError(errors, 'number')}
        fullWidth
        name='number'
        onChange={onChange}
        margin="normal"
        defaultValue={data.number}
        value={data.number}
        InputLabelProps={{
          shrink: true,
        }}
        disabled={isDisabled}
      />

      {((data.postalNumber && flag == "edit") || (flag == "add") || (data.postalNumber && flag == "view")) &&
        <Autocomplete
          id={"postalNumber"}
          name={"postalNumber"}
          options={postalNumbers}
          getOptionLabel={option => option.code}
          onChange={onAutocompleteChange}
          renderInput={params => (
            <TextField
              {...params}
              variant="standard"
              label={strings.postalAddressCode.form.postalNumber + "*"}
              error={hasError(errors, 'postalNumber')}
              helperText={getError(errors, 'postalNumber')}
              margin="normal"
              fullWidth
              name={"postalNumber"}
              onChange={handleTextInputChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
          disabled={isDisabled}
          value={data.postalNumber}

        />
      }
      {((!data.postalNumber && flag == "edit") || (!data.postalNumber && flag == "view")) &&
        <Autocomplete
          id={"postalNumber"}
          name={"postalNumber"}
          options={postalNumbers}
          getOptionLabel={option => option.code}
          onChange={onAutocompleteChange}
          renderInput={params => (
            <TextField
              {...params}
              variant="standard"
              label={strings.postalAddressCode.form.postalNumber + "*"}
              error={hasError(errors, 'postalNumber')}
              helperText={getError(errors, 'postalNumber')}
              margin="normal"
              fullWidth
              name={"postalNumber"}
              onChange={handleTextInputChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
          disabled={isDisabled}
          value={data.postalNumber}
        />
      }

      <TextField
        label={strings.postalAddressCode.form.pac + '*'}
        error={hasError(errors, 'postalAddressCode')}
        helperText={getError(errors, 'postalAddressCode')}
        fullWidth
        name='postalAddressCode'
        onChange={onChange}
        margin="normal"
        defaultValue={data.postalAddressCode}
        value={data.postalAddressCode}
        InputLabelProps={{
          shrink: true,
        }}
        disabled={isDisabled}
      />







      {
        !isDisabled &&
        <div className='submit-container'>
          <Button variant='contained' color='primary' onClick={onSubmit}>
            {strings.postalAddressCode.form.ok}
          </Button>
          <Button variant='contained' color='secondary' onClick={onCancel}>
            {strings.postalAddressCode.form.cancel}
          </Button>
        </div>
      }

    </form>
  )
};

export default PostalAddresCodeform;
