import {request} from "../../base/HTTP";
import HttpMethod from "../../constants/HttpMethod";

export async function getReservations(data) {
    return await request('/api/reservation/search', data);
}

export async function addReservation(data, cid) {
    return await request('/api/reservation/' + cid, data, HttpMethod.POST);
}

export function getReservationById(id) {
    return  request("api/reservation/" + id , {}, HttpMethod.GET);
}

export async function editReservation(data) {
    return await request("api/reservation/" + data.id, data, HttpMethod.PUT);
}

export async function deleteReservation(id, uid) {
    return await request('/api/reservation/' + id + '/' + uid, {} , HttpMethod.DELETE);
}

export async function stornoReservation(id, uid) {
    return await request('/api/reservation/cancel/' + id + '/' + uid, {} , HttpMethod.PUT);
}

export async function undoStornoReservation(id, uid) {
    return await request('/api/reservation/undoCancel/' + id + '/' + uid, {} , HttpMethod.PUT);
}

export async function getDeletedOReservations(data) {
    return await request('/api/reservation/deleted', data);
}

export async function restoreReservation(data) {
    return await request("api/reservation/restore/" + data.id, data, HttpMethod.PUT);
}