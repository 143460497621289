import React from "react";
import { bindActionCreators } from "redux";
import * as Actions from "../../actions/Actions";
import { withRouter } from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import Grid from "@material-ui/core/Grid";
import { Paper } from "@material-ui/core";
import strings from "../../localization";
import Validators from "../../constants/ValidatorTypes";
import FormComponent from "../../common/FormComponent";
import { withSnackbar } from "notistack";
import ProfileForm from "../../components/forms/user/ProfileForm";
import { editUser, getCurrentUser } from "../../services/user/UserService";

class EditProfile extends FormComponent {
  validationList = {
    email: [{ type: Validators.EMAIL }],
    name: [{ type: Validators.REQUIRED }],
    surname: [{ type: Validators.REQUIRED }],
    phoneNumber: [{ type: Validators.IS_NUMBER }]
  };

  constructor(props) {
    super(props);

    this.state = {
      data: props.data ? props.data : {},
      errors: {}
    };

    this.props.changeFullScreen(false);
    this.submit = this.submit.bind(this);
  }

  componentDidMount(){
    getCurrentUser().then(response => {
      this.setState({
        data: response.data
      });
    });
  }

  submit() {
    if (!this.validate()) {
      return;
    }

    this.showDrawerLoader();

    editUser(this.state.data).then(response => {
      if (!response.ok) {
        if (response.response.status === 400) {
          let messageKey = response.response.data;

          this.props.enqueueSnackbar(strings.user.messages[messageKey], {
            variant: "error"
          });

          switch (messageKey) {
            case "USER_WITH_EMAIL_ALREADY_EXIST":
              this.setError("email", strings.user.messages.USER_WITH_EMAIL_ALREADY_EXIST);
              break;
          }
        } else {
          this.props.enqueueSnackbar(strings.user.editForm.errorEditing, {
            variant: "error"
          });
        }

        return;
      }

      this.props.enqueueSnackbar(strings.user.editForm.edited, { variant: 'success' });

      this.hideDrawerLoader();
    });

  }

  render() {
    return (
      <Grid id="page" item md={12}>
        <div className="header">
          <h1>{strings.profile.pageTitle}</h1>
        </div>
        
          <Paper className="paper">
            <ProfileForm
              onChange={this.changeData}
              onSubmit={this.submit}
              data={this.state.data}
              errors={this.state.errors}
              onCancel={this.props.onCancel}
            />
          </Paper>
        
      </Grid>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      changeFullScreen: Actions.changeFullScreen
    },
    dispatch
  );
}

function mapStateToProps({ menuReducers, siteDataReducers }) {
  return { menu: menuReducers, siteData: siteDataReducers };
}

export default withSnackbar(
  withRouter(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(EditProfile)
  )
);
