import { request } from "../../base/HTTP";
import HttpMethod from "../../constants/HttpMethod";


export async function generateMainBookOfBuriedReview(data) {
  return await request('/api/report/mainbookofburied', data, HttpMethod.GET, { responseType: 'blob' });
}

export async function generateTransporterByTimesReview(data) {
  return await request('/api/report/transporter', data, HttpMethod.GET, { responseType: 'blob' });
}

export async function generateFuneralAndUrnPerMonth(data) {
  return await request('/api/report/funeralAndUrnPerMonthForSelectedYear', data, HttpMethod.GET, { responseType: 'blob' });
}

export async function generateFuneralsForGraveFieldsAndSelectedGraveyard(data) {
  return await request('/api/report/funeralsForGraveFieldsAndSelectedGraveyard', data, HttpMethod.GET, { responseType: 'blob' });
}

export async function generateFuneralsForAllGraveyards(data) {
  return await request('api/report/funeralsForAllGraveyards', data, HttpMethod.GET, { responseType: 'blob' });
}

export async function generateFuneralsForGraveFieldsAndGraveyardsPerMonthForSelectedYear(data) {
  return await request('api/report/funeralsForGraveFieldsAndGraveyardsPerMonthForSelectedYear', data, HttpMethod.GET, { responseType: 'blob' });
}

export async function generateFuneralsForYearsOfBurialAndSelectedGraveyards(data) {
  return await request('api/report/funeralsForYearsOfBurialAndSelectedGraveyards', data, HttpMethod.GET, { responseType: 'blob' });
}

export async function generateReservationReview(data) {
  return await request('/api/report/reservation', data, HttpMethod.GET, { responseType: 'blob' });
}

export async function generateReviserReview(data) {
  return await request('/api/report/reviser', data, HttpMethod.GET, { responseType: 'blob' });
}

export async function generateExpectedIncomeReview(data) {
  return await request('/api/report/expectedIncome', data, HttpMethod.GET, { responseType: 'blob' });
}

export async function generateExpiryByGraveFIeld(data) {
  return await request('/api/report/expiryByGraveField', data, HttpMethod.GET, { responseType: 'blob' });
}



export async function generateSoldGraveSitesForSelectedYearAndGraveyard(data) {
  return await request('/api/report/reservationsSoldGraveSitesForSelectedYearAndGraveyard', data, HttpMethod.GET, { responseType: 'blob' });
}

export async function generateSoldGraveSitesForSelectedYearAndGraveyardAndPlot(data) {
  return await request('/api/report/reservationsSoldGraveSitesForSelectedYearAndGraveyardAndPlot', data, HttpMethod.GET, { responseType: 'blob' });
}

export async function generateInheritedGraveSitesForCurrentYearPerMonths(data) {
  return await request('/api/report/reservationsInheritedGraveSitesForCurrentYearPerMonths', data, HttpMethod.GET, { responseType: 'blob' });
}

export async function generateInheritedGraveSitesForCurrentYearAndSelectedPlotPerMonths(data) {
  return await request('/api/report/reservationsInheritedGraveSitesForCurrentYearAndSelectedPlotPerMonths', data, HttpMethod.GET, { responseType: 'blob' });
}

