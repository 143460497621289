import React from 'react'
import { bindActionCreators } from "redux";
import * as Actions from "../../actions/Actions";
import { withRouter } from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import Grid from '@material-ui/core/Grid';
import { Paper } from "@material-ui/core";
import strings from "../../localization";
import FormComponent from "../../common/FormComponent";
import { withSnackbar } from "notistack";
import { getCitizenByPersonalNumber, getCitizens, getCitizenById } from '../../services/citizen/CitizenService';
import { getGraveyards, getGraveyardById } from '../../services/graveyard/GraveyardService';
import { getGraveSiteById, getGraveSitesWithSelectedGraveyard } from '../../services/graveSite/GraveSiteService';
import { getRenewalById, editRenewal } from '../../services/renewal/RenewalService';
import RenewalEditedForm from '../../components/forms/renewalEdited/RenewalEditedForm';
import Validators from '../../constants/ValidatorTypes';
import { addEditedRenewal } from '../../services/renewalEdited/RenewalEditedService';
import { validateDateFromRenewalPaymentSlipAndDate } from '../../functions/Validation';
import { isNumeric, isInt } from "../../util/DataValidation";
import { getMainBookOfBuriedById } from "../../services/mainBookOfBuried/MainBookOfBuriedService";

class AddRenewalEdited extends FormComponent {

    validationList = {
        amount: [{ type: Validators.REQUIRED }, { type: Validators.IS_NEGATIVE_NUMBER }],
    };

    constructor(props) {
        super(props);

        this.state = {
            data: props ? props : {},
            errors: {},
            submitter: props.data[this.props.elementIndex].submitter,
            disabledSubmitButton: false,
        };

        this.props.changeFullScreen(false);

        this.submit = this.submit.bind(this);


    }
    componentDidMount() {

        var startDate = new Date().getFullYear();
        if (this.props.searchTypeFlag == 2) {
            getMainBookOfBuriedById(this.state.data.id).then(response => {

                this.setState({
                    data: {
                        ...this.state.data,
                        renewalNumber: response.data.mainBookOfBuries ? startDate + '' + response.data.mainBookOfBuries.toString()[4] + '' + response.data.mainBookOfBuries.toString()[5] : "00"
                    }
                });
            })
        } else {
            this.setState({
                data: {
                    ...this.state.data,
                    renewalNumber: this.props.selectedYear.value + this.props.selectedRenewalTypeKind.value,
                    data: this.state.data.data[this.state.data.elementIndex]
                }
            }, () => {
                getGraveyardById(this.state.data.data.graveyardId).then(responseGraveyard => {
                    getGraveSiteById(this.state.data.data.graveSiteId).then(responseGraveSite => {
                        this.setState({
                            data: {
                                ...this.state.data,
                                graveSite: responseGraveSite.data,
                                graveyard: responseGraveyard.data
                            },
                            expiryDate: responseGraveSite.data.expirationDate ? responseGraveSite.data.expirationDate : this.state.expiryDate
                        });
                    });
                });

                {
                    this.state.submitter != null && this.state.submitter.personalNumber != null &&
                    getCitizenByPersonalNumber(this.state.submitter.personalNumber).then(response => {
                        this.setState({
                            submitter: {
                                ...response.data,
                                personalNumber: response.data.personalNumber,
                                nameSurnameSubmitter: response.data.nameSurname,
                                addressSubmitter: response.data.address,
                                postalCode: response.data.municipality,
                                telephoneSubmitter: response.data.telephone
                            }
                        });
                    });
                }
            });
        }
    }

    submit() {
        this.setState({
            disabledSubmitButton: true
        }, () => {
            if (!this.validate()) {
                this.props.enqueueSnackbar(strings.renewal.validationЕrror, { variant: 'error' });
                this.setState({
                    disabledSubmitButton: false
                });
                return;
            }
            
            this.showDrawerLoader();
    
            addEditedRenewal(this.transformRequestIntoData(this.props), this.props.cid).then(response => {
                if (!response.ok) {
                    let messageKey = response.response.data.message;
    
                    this.props.enqueueSnackbar(strings.renewal.messages[messageKey], {
                        variant: "error"
                    });
    
                    this.handleError(messageKey);
                    return;
                }
    
                this.props.enqueueSnackbar(strings.addCompany.renewalEdited, { variant: 'success' });
                this.props.onFinish(response.data);
    
                this.hideDrawerLoader();
            });
        });
    }

    handleError(message) {
        switch (message) {
            case "EDITED_RENEWAL_SUBMITTER_PERSONAL_NUMBER_CANT_BE_NULL":
                this.setError("personalNumber", "EDITED_RENEWAL_SUBMITTER_PERSONAL_NUMBER_CANT_BE_NULL");
                break;
            case "EDITED_RENEWAL_WITH_RENEWAL_NUMBER_ALREADY_EXISTS":
                this.setError("renewalNumber", "EDITED_RENEWAL_WITH_RENEWAL_NUMBER_ALREADY_EXISTS");
                break;
        }
    }

    transformRequestIntoData(props) {
       
        this.setState({
            dataForSending: {
                renewalNumber: props.selectedYear.value + props.selectedRenewalTypeKind.value,
                personalNumber: this.state.submitter.personalNumber,
                submitterId: this.state.submitter.id,
                nameSurnameSubmitter: this.state.submitter.name + " " + this.state.submitter.surname,
                addressSubmitter: this.state.submitter.street + " " + this.state.submitter.streetNumber,
                municipalitySubmitter: this.state.submitter.postalCode,
                telephoneSubmitter: this.state.submitter.telephone,
                expiryDate: props.data[this.props.elementIndex].expiryDate ? new Date(props.data[this.props.elementIndex].expiryDate) : null,
                dateOfChange: this.state.data.dateOfChange,
                paymentSlipAndDate: this.state.data.paymentSlipAndDate,
                amount: this.state.data.amount,
                graveyard: props.data[this.props.elementIndex].graveyard,
                graveyardId: props.data[this.props.elementIndex].graveyardId,
                graveSite: props.data[this.props.elementIndex].graveSite,
                graveSiteId: props.data[this.props.elementIndex].graveSiteId,
                tombstoneType: this.state.data.tombstoneType,
                renewalYears: this.state.data.renewalYears,
                newExpirationDate: this.state.data.newExpirationDate,
                note: this.state.data.note,
                costId: this.state.cost== undefined ? null : this.state.cost.id,
                costString: this.state.cost== undefined ? null : this.state.cost.personalNumber
            }
        });

        return {
            renewalNumber: props.selectedYear.value + props.selectedRenewalTypeKind.value,
            personalNumber: this.state.submitter.personalNumber,
            submitterId: this.state.submitter.id,
            nameSurnameSubmitter: this.state.submitter.name + " " + this.state.submitter.surname,
            addressSubmitter: this.state.submitter.street + " " + this.state.submitter.streetNumber,
            municipalitySubmitter: this.state.submitter.postalCode,
            telephoneSubmitter: this.state.submitter.telephone,
            expiryDate: props.data[this.props.elementIndex].expiryDate ? new Date(props.data[this.props.elementIndex].expiryDate) : null,
            dateOfChange: this.state.data.dateOfChange,
            paymentSlipAndDate: this.state.data.paymentSlipAndDate,
            amount: this.state.data.amount,
            graveyard: props.data[this.props.elementIndex].graveyard,
            graveyardId: props.data[this.props.elementIndex].graveyardId,
            graveSite: props.data[this.props.elementIndex].graveSite,
            graveSiteId: props.data[this.props.elementIndex].graveSiteId,
            tombstoneType: this.state.data.tombstoneType,
            renewalYears: this.state.data.renewalYears,
            newExpirationDate: this.state.data.newExpirationDate,
            note: this.state.data.note,
            userEmail: this.props.uemail,
            costId: this.state.cost== undefined ? null : this.state.cost.id,
            costString: this.state.cost== undefined ? null : this.state.cost.personalNumber
        }
    }

    transformRequest(data) {
        return {
            ...data,
            userEmail: this.props.uemail,
        }
    }


    handleChange = (event) => {
        this.setState({
            data: {
                ...this.state.data, [event.target.name]: event.target.value
            }
        })
    };

    /** 
     * Handles autocomplete change event
     * Triggers when user selects something from dropdown menu
     * to fill info (disabled) fields 
     */
    onAutocompleteChange = (event, values) => {
        let autocompleteName = event.target.id.split("-")[0];
        this.setState({
            data: {
                ...this.state.data, [autocompleteName]: values
            }
        });

        if (autocompleteName === "submitter" && values != null) {
            getCitizenByPersonalNumber(values.personalNumber).then(response => {
                this.setState({
                    submitter: {
                        id: response.data.id,
                        personalNumber: response.data.personalNumber,
                        nameSurnameSubmitter: response.data.nameSurname,
                        addressSubmitter: response.data.address,
                        postalCode: response.data.municipality,
                        telephoneSubmitter: response.data.telephone
                    }
                });
            });
        }else if(autocompleteName === "cost" && values != null){
            getCitizenByPersonalNumber(values.personalNumber).then(response =>{
                this.setState({
                    cost: {
                        id: response.data.id,
                        personalNumber: response.data.personalNumber,
                        nameSurname: response.data.nameSurname,
                        address: response.data.address,
                        municipality: response.data.municipality,
                    }
                })
            })
        }
    }

    /** 
     * Handles autocomplete type input change event
     * When user typed in 2+ characters it searches for results to fill dropdown 
     */
    handleTextInputChange = (e) => {
        if (e.target.name === "cost" && e.target.value.trim().length >= 2) {
            let data = {
                searchParam: e.target.value,
                companyId: this.props.cid,
                sort: "id,ASC",
                userId: 0
            };

            {
                this.props.cid != undefined &&
                    getCitizens(data).then(response => {
                        if (!response.ok) {
                            return;
                        }
                        this.setState({
                            costs: response.data.entities,
                        });
                        
                    });
            }
        } else if (e.target.name === "graveyard" && e.target.value.trim().length >= 2) {
            let data = {
                searchParam: e.target.value,
                companyId: this.props.cid,
                sort: "name,ASC",
                userId: 0
            };

            {
                this.props.cid != undefined &&
                    getGraveyards(data).then(response => {
                        if (!response.ok) {
                            return;
                        }
                        this.setState({
                            graveyards: response.data.entities,
                        });
                    });
            }
        } else if (e.target.name === "graveSite" && e.target.value.trim().length >= 2) {
            let data = {
                searchParam: e.target.value,
                companyId: this.props.cid,
                sort: "code,ASC",
                userId: 0
            };

            {
                this.props.cid != undefined &&
                    getGraveSitesWithSelectedGraveyard(data).then(response => {
                        if (!response.ok) {
                            return;
                        }
                        this.setState({
                            graveSites: response.data.entities,
                        });
                    });
            }
        } else if (e.target.name == "renewalYears") {
            
            var expiryDateYear = (new Date(this.props.data[this.props.elementIndex].expiryDate)).getFullYear()
            var expiryDateMonth = (new Date(this.props.data[this.props.elementIndex].expiryDate)).getMonth()
            var expiryDateDay = (new Date(this.props.data[this.props.elementIndex].expiryDate)).getDate()

            if (e.target.value.length != "") {
                var renewalYears = (this.isNumeric(e.target.value) && parseInt(e.target.value) < 1000) ? e.target.value : 0
                var newExpirationDateYear = expiryDateYear + parseInt(renewalYears)
            }
            else {
                var renewalYears = ""
                var defaultValueForRenewalYears = 0
                var newExpirationDateYear = expiryDateYear + parseInt(defaultValueForRenewalYears)
            }

            var newExpirationDate = new Date(newExpirationDateYear, expiryDateMonth, expiryDateDay)

            this.setState({
                data: {
                    ...this.state.data,
                    newExpirationDate: newExpirationDate,
                    renewalYears: renewalYears
                }
            });
        } else if (e.target.name === "submitter" && e.target.value.trim().length >= 2) {
            let data = {
                searchParam: e.target.value,
                companyId: this.props.cid,
                sort: "id,ASC",
                userId: 0
            };

            {
                this.props.cid != undefined &&
                    getCitizens(data).then(response => {
                        if (!response.ok) {
                            return;
                        }
                        this.setState({
                            submitters: response.data.entities,
                        });
                        
                    });
            }
        }

    }

    validateCashRegisterNumber(paymentSlipAndDate) {
        let validCashRegisterNumberArray = ["03", "11", "28", "52", "70", "91", "92", "93"]
        if (!validCashRegisterNumberArray.includes(paymentSlipAndDate.substr(0, 2))) {
            return false;
        }
        return true;
    }

    keyPressBackspace = (e) => {
        if (e.target.value.length == 2 && e.keyCode !== 8) {
            e.target.value = e.target.value + ' ';
        }

        if (e.target.value.length == 8 && e.keyCode !== 8) {
            e.target.value = e.target.value + '/';
        }

        if (e.target.value.length == 11 && e.keyCode !== 8) {
            e.target.value = e.target.value + '.';
        }

        if (e.target.value.length == 14 && e.keyCode !== 8) {
            e.target.value = e.target.value + '.';
        }

        if (e.keyCode !== 8) {
            return;
        }

    }

    onChangePaymentSlipAndDateField = (e) => {

        let validDate = validateDateFromRenewalPaymentSlipAndDate(e.target.value)
        let year = e.target.value.substr(15, 4)

        let validCashRegistryNumber = this.validateCashRegisterNumber(e.target.value)

        if (!validCashRegistryNumber) {
            this.setError("paymentSlipAndDate", "Blagajna ne valja!");
            // this.setState({
            //     blagajnaFlag: "invalidNumber"
            // })
        }
        else if (!validDate || !isNumeric(year)) {
            this.setError("paymentSlipAndDate", "Datum ne valja!");
        }
        else {
            this.unsetError("paymentSlipAndDate")
        }

        if (e.target.value.length == 20) {
            return;
        }

        this.changeData(e);
    }

    render() {
        
        return (
            <Grid id='page' item md={12}>

                <div className='header'>
                    <h1>{strings.renewal.addPageTitle}</h1>
                </div>

                <Paper className='paper'>
                    <RenewalEditedForm
                        onChange={this.changeData}
                        onSubmit={this.submit}
                        data={this.state.data}
                        errors={this.state.errors}
                        onCancel={this.props.onCancel}
                        handleChange={this.handleChange}
                        isDisabled={this.props.isDisabled}
                        onAutocompleteChange={this.onAutocompleteChange}
                        handleTextInputChange={this.handleTextInputChange}
                        submitters={this.state.submitters == undefined ? [] : this.state.submitters}
                        submitter={this.state.submitter}
                        graveyards={this.state.graveyards == undefined ? [] : this.state.graveyards}
                        graveSites={this.state.graveSites == undefined ? [] : this.state.graveSites}
                        flag={this.props.flag}
                        searchTypeFlag={this.props.searchTypeFlag}
                        // expiryDate={this.props.data[this.props.elementIndex].expiryDate}
                        expiryDate={this.state.expiryDate}
                        // graveyard={this.props.data[this.props.elementIndex].graveyard}
                        // graveSite={this.props.data[this.props.elementIndex].graveSite}
                        graveyard={this.state.data.graveyard}
                        graveSite={this.state.data.graveSite}
                        date={this.props.selectedYear.value}
                        selectedRenewalTypeKind={this.props.selectedRenewalTypeKind.value}
                        newExpirationDate={this.state.data.newExpirationDate}
                        onChangePaymentSlipAndDateField={this.onChangePaymentSlipAndDateField}
                        onKeyDown={this.keyPressBackspace}
                        costs={this.state.costs == undefined ? [] : this.state.costs}
                        cost={this.state.cost == undefined ? [] : this.state.cost}
                        disabledSubmitButton={this.state.disabledSubmitButton}
                    />
                </Paper>

            </Grid>
        );
    }
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        changeFullScreen: Actions.changeFullScreen,
    }, dispatch);
}

function mapStateToProps({ menuReducers, siteDataReducers }) {
    return { menu: menuReducers, siteData: siteDataReducers };
}

export default withSnackbar(withRouter(connect(mapStateToProps, mapDispatchToProps)(AddRenewalEdited)));