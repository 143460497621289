import {request} from "../../base/HTTP";
import HttpMethod from "../../constants/HttpMethod";

export async function getGrass(data) {
    return await request('/api/grass/search', data);
}

export async function addGrass(data, cid) {
    return await request('/api/grass/' + cid, data, HttpMethod.POST);
}

export function getGrassById(id) {
    return  request("api/grass/" + id , {}, HttpMethod.GET);
}

export async function editGrass(data) {
    return await request("api/grass/" + data.id, data, HttpMethod.PUT);
}

export async function deleteGrass(id, uid) {
    return await request('/api/grass/' + id + '/' + uid, {} , HttpMethod.DELETE);
}

export async function stornoGrass(id, uid) {
    return await request('/api/grass/cancel/' + id + '/' + uid, {} , HttpMethod.PUT);
}

export async function undoStornoGrass(id, uid) {
    return await request('/api/grass/undoCancel/' + id + '/' + uid, {} , HttpMethod.PUT);
}