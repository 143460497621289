import { request } from "../../base/HTTP";
import HttpMethod from "../../constants/HttpMethod";

export async function getPostalCodesImproved(data) {
    return await request('/api/postalCodeImproved/search', data);
}

export async function addPostalCodeImproved(data, cid) {
    return await request('/api/postalCodeImproved/' + cid, data, HttpMethod.POST);
}

export function getPostalCodeImprovedById(id) {
    return request("api/postalCodeImproved/" + id, {}, HttpMethod.GET);
}

export async function editPostalCodeImproved(data) {
    return await request("api/postalCodeImproved/" + data.id, data, HttpMethod.PUT);
}

export async function deletePostalCodeImproved(id, uid) {
    return await request('/api/postalCodeImproved/' + id + '/' + uid, {}, HttpMethod.DELETE);
}

export async function getDeletedPostalCodesImproved(data) {
    return await request('/api/postalCodeImproved/deleted', data)
}

export async function restorePostalCodeImproved(data) {
    return await request("api/postalCodeImproved/restore/" + data.id, data, HttpMethod.PUT);
}