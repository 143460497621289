import React from 'react'
import { bindActionCreators } from "redux";
import * as Actions from "../../../actions/Actions";
import { withRouter } from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import Grid from '@material-ui/core/Grid';
import { Paper } from "@material-ui/core";
import strings from "../../../localization";
import Validators from "../../../constants/ValidatorTypes";
import FormComponent from "../../../common/FormComponent";
import { withSnackbar } from "notistack";
import { addDeceasedReception } from '../../../services/deceasedReception/DeceasedReceptionService';
import DeceasedTakeawayRecordForm from '../../../components/forms/deceasedReception/deceasedTakeawayRecord/DeceasedTakeawayRecordForm';
import { getAllTransporters } from '../../../services/transporter/TransporterService';


class AddDeceasedTakeawayRecord extends FormComponent {

  validationList = {
    deceasedNameSurname: [{ type: Validators.REQUIRED }],
  };

  constructor(props) {
    super(props);

    this.state = {
      data: props.data ? props.data : {},
      errors: {},
    };

    this.state.sortBy = "name,ASC";

    this.props.changeFullScreen(false);

    this.submit = this.submit.bind(this);
  }

  componentDidMount() {
    getAllTransporters().then(response => {
      this.setState({
        transporterTs: response.data.entities,
      });
    });

  }

  submit() {

    if (!this.validate()) {
      this.props.enqueueSnackbar(strings.error.requiredFields, { variant: 'error' });
      return;
    }

    this.showDrawerLoader();

    addDeceasedReception(this.transformRequest(this.state.data), this.props.cid).then(response => {
      if (!response.ok) {
        let messageKey = response.response.data.message;

        this.props.enqueueSnackbar(strings.deceasedReception.messages[messageKey], {
          variant: "error"
        });

        this.handleError(messageKey);

        return;
      }

      this.props.enqueueSnackbar(strings.addCompany.deceaseTakeawayRecordAdded, { variant: 'success' });
      this.props.onFinish(response.data.deceasedTakeawayRecord);

      this.hideDrawerLoader();
    });
  }

  transformRequest(data) {
    return {
      ...data,
      deceasedReceptionType: 3,
      userEmail: this.props.uemail,
      transporterTId: this.state.data.transporterT ? this.state.data.transporterT.id : null,
    }
  }

  handleError(message) {
    switch (message) {

      case "DECEASED_RECEPTION_WITH_NAME_SURNAME_CANT_BE_NULL":
        this.setError("deceasedNameSurname", strings.deceasedReception.messages.DECEASED_RECEPTION_WITH_NAME_SURNAME_CANT_BE_NULL);
        break;
    }
  }

  handleChangeTab = (event, value) => {
    this.setState({
      value: value
    });
  };

  handleChange = (event) => {
    this.setState({
      data: {
        ...this.state.data, [event.target.name]: event.target.value
      }
    })
  };

  onAutocompleteChange = (event, values) => {
    let autocompleteName = event.target.id.split("-")[0];
    this.setState({
      data: {
        ...this.state.data, [autocompleteName]: values
      }
    });
  }


  render() {

    return (
      <Grid id='page' item md={12}>

        <div className='header'>
          <h1>{strings.deceasedTakeawayRecord.pageTitle}</h1>
        </div>
        <Paper className='paper'>
          <DeceasedTakeawayRecordForm
            onChange={this.changeData}
            onSubmit={this.submit}
            data={this.state.data}
            errors={this.state.errors}
            onCancel={this.props.onCancel}
            handleChange={this.handleChange}
            onAutocompleteChange={this.onAutocompleteChange}
            handleTextInputChange={this.handleTextInputChange}
            transporterTs={this.state.transporterTs}
            flag={this.props.flag}
          />
        </Paper>

      </Grid>

    );
  }
}


function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    changeFullScreen: Actions.changeFullScreen
  }, dispatch);
}

function mapStateToProps({ menuReducers, siteDataReducers }) {
  return { menu: menuReducers, siteData: siteDataReducers };
}

export default (withSnackbar(withRouter(connect(mapStateToProps, mapDispatchToProps)(AddDeceasedTakeawayRecord))));