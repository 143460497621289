import React from 'react';
import strings from "../../../localization";
import {getError, hasError} from "../../../functions/Validation";
import {Button, TextField} from "@material-ui/core";
import DatePickerControl from '../../controls/DatePickerControl';
import SelectControl from '../../controls/SelectControl';

const UserForm = ({
                      onSubmit,
                      onCancel,
                      onChange,
                      errors,
                      data,
                      companies,
                      role,
                      isDisabled
                  }) => (

    <form id='user-form'>
        <TextField
            label={ strings.user.form.name + "*" }
            error={ hasError(errors, 'name') }
            helperText={ getError(errors, 'name') }
            fullWidth
            autoFocus
            name='name'
            onChange={ onChange }
            margin="normal"
            disabled = {isDisabled}
            defaultValue={ data.name }
            value={ data.name }
            InputLabelProps={{
                shrink: true,
            }}
        />
        <TextField
            label={ strings.user.form.surname + "*" }
            error={ hasError(errors, 'surname') }
            helperText={ getError(errors, 'surname') }
            fullWidth
            name='surname'
            onChange={ onChange }
            margin="normal"
            disabled = { isDisabled }
            defaultValue={ data.surname }
            value={ data.surname }
            InputLabelProps={{
                shrink: true,
            }}
        />
        <TextField
            label={ strings.user.form.email + "*" }
            error={ hasError(errors, 'email') }
            helperText={ getError(errors, 'email') }
            fullWidth
            name='email'
            onChange={ onChange }
            margin="normal"
            disabled = { isDisabled }
            defaultValue={ data.email }
            value={ data.email }
            InputLabelProps={{
                shrink: true,
            }}
        />
        <TextField
            label={ strings.user.form.phoneNumber + "*" }
            error={ hasError(errors, 'phoneNumber') }
            helperText={ getError(errors, 'phoneNumber') }
            fullWidth
            name='phoneNumber'
            onChange={ onChange }
            margin="normal"
            disabled = { isDisabled }
            defaultValue={ data.phoneNumber }
            value={ data.phoneNumber }
            InputLabelProps={{
                shrink: true,
            }}
        />
        <TextField
            label={ strings.user.form.address }
            error={ hasError(errors, 'address') }
            helperText={ getError(errors, 'address') }
            fullWidth
            name='address'
            onChange={ onChange }
            margin="normal"
            disabled = { isDisabled }
            defaultValue={ data.address }
            value={ data.address }
            InputLabelProps={{
                shrink: true,
            }}
        />
        <DatePickerControl
            label={ strings.user.form.birthDate }
            placeholder={ strings.user.form.selectBirthDate }
            name='birthDate'
            onChange={ onChange }
            date={ data.birthDate ? new Date(data.birthDate) : data.birthDate }
            isClearable={ true }
            dateFormat='dd/MM/yyyy'
            disabled = { isDisabled }
        />
        { role === "SUPERADMIN" &&
            <SelectControl
                options={companies}
                selected={data.company}
                error={getError(errors, 'company')}
                hasError={hasError(errors, 'company')}
                onChange={onChange}
                label={strings.user.form.company + "*"}
                name={"company"}
                nameKey={"name"}
                valueKey={"name"}
                isDisabled = { isDisabled }
            />
        }
        {
            !isDisabled &&
            <div className='submit-container'>
                <Button variant="contained" color="primary" onClick={ onSubmit }>
                    { strings.userForm.ok }
                </Button>
                <Button variant="contained" color="secondary" onClick={ onCancel }>
                    { strings.userForm.cancel }
                </Button>
            </div>
        }
    </form>
);

export default UserForm;