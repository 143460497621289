import React from 'react'
import { bindActionCreators } from "redux";
import * as Actions from "../../actions/Actions";
import { withRouter } from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import Grid from '@material-ui/core/Grid';
import { Paper } from "@material-ui/core";
import strings from "../../localization";
import Validators from "../../constants/ValidatorTypes";
import FormComponent from "../../common/FormComponent";
import { withSnackbar } from "notistack";
import { addGraveyard } from '../../services/graveyard/GraveyardService';
import GraveyardForm from '../../components/forms/graveyard/GraveyardForm';


class AddGraveyard extends FormComponent {

  validationList = {
    code: [{ type: Validators.REQUIRED }],
    name: [{ type: Validators.REQUIRED }],
    address: [{ type: Validators.REQUIRED }],
    place: [{ type: Validators.REQUIRED }],
    surface: [{ type: Validators.REQUIRED, type: Validators.IS_NEGATIVE_NUMBER }],
    status: [{ type: Validators.REQUIRED }],
    denomination: [{ type: Validators.REQUIRED }],
    yearOfEstablishment: [{ type: Validators.REQUIRED, type: Validators.IS_NEGATIVE_NUMBER }],
    takeoverDate: [{ type: Validators.REQUIRED }],
  };

  constructor(props) {
    super(props);

    this.state = {
      data: props.data ? props.data : {},
      errors: {}
    };

    this.props.changeFullScreen(false);

    this.submit = this.submit.bind(this);

  }

  submit() {

    if (!this.validate()) {
      return;
    }

    this.showDrawerLoader();

    addGraveyard(this.transformRequest(this.state.data), this.props.cid).then(response => {
      if (!response.ok) {
        let messageKey = response.response.data.message;

        this.props.enqueueSnackbar(strings.graveyard.messages[messageKey], {
          variant: "error"
        });

        this.handleError(messageKey);

        return;
      }

      this.props.enqueueSnackbar(strings.addCompany.graveyardAdded, { variant: 'success' });
      this.props.onFinish(response.data.graveyard);

      this.hideDrawerLoader();
    });
  }

  transformRequest(data) {
    return {
      ...data,
      userEmail: this.props.uemail,
    }
  }

  handleError(message) {
    switch (message) {
      case "GRAVEYARD_WITH_CODE_ALREADY_EXIST":
        this.setError("code", strings.graveyard.messages.GRAVEYARD_WITH_CODE_ALREADY_EXIST);
        break;
      case "GRAVEYARD_CODE_CANT_BE_NULL":
        this.setError("code", strings.graveyard.messages.GRAVEYARD_CODE_CANT_BE_NULL);
        break;
      case "GRAVEYARD_NAME_CANT_BE_NULL":
        this.setError("name", strings.graveyard.messages.GRAVEYARD_NAME_CANT_BE_NULL);
        break;
      case "GRAVEYARD_ADDRESS_CANT_BE_NULL":
        this.setError("address", strings.graveyard.messages.GRAVEYARD_ADDRESS_CANT_BE_NULL);
        break;
      case "GRAVEYARD_PLACE_CANT_BE_NULL":
        this.setError("place", strings.graveyard.messages.GRAVEYARD_PLACE_CANT_BE_NULL);
        break;
      case "GRAVEYARD_SURFACE_CANT_BE_NULL":
        this.setError("surface", strings.graveyard.messages.GRAVEYARD_SURFACE_CANT_BE_NULL);
        break;
      case "GRAVEYARD_STATUS_CANT_BE_NULL":
        this.setError("status", strings.graveyard.messages.GRAVEYARD_STATUS_CANT_BE_NULL);
        break;
      case "GRAVEYARD_DENOMINATION_CANT_BE_NULL":
        this.setError("denomination", strings.graveyard.messages.GRAVEYARD_DENOMINATION_CANT_BE_NULL);
        break;
      case "GRAVEYARD_YEAR_OF_ESTABLISHMENT_CANT_BE_NULL":
        this.setError("yearOfEstablishment", strings.graveyard.messages.GRAVEYARD_YEAR_OF_ESTABLISHMENT_CANT_BE_NULL);
        break;
      case "GRAVEYARD_TAKEOVER_DATE_CANT_BE_NULL":
        this.setError("takeoverDate", strings.graveyard.messages.GRAVEYARD_TAKEOVER_DATE_CANT_BE_NULL);
        break;
    }
  }

  render() {
    return (
      <Grid id='page' item md={12}>

        <div className='header'>
          <h1>{strings.graveyard.pageTitle}</h1>
        </div>

        <Paper className='paper'>
          <GraveyardForm onChange={this.changeData} onSubmit={this.submit}
            data={this.state.data} errors={this.state.errors} onCancel={this.props.onCancel} />
        </Paper>

      </Grid>

    );
  }
}


function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    changeFullScreen: Actions.changeFullScreen
  }, dispatch);
}

function mapStateToProps({ menuReducers, siteDataReducers }) {
  return { menu: menuReducers, siteData: siteDataReducers };
}

export default withSnackbar(withRouter(connect(mapStateToProps, mapDispatchToProps)(AddGraveyard)));