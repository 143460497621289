import React from 'react';
import strings from "../../../localization";
import { getError, hasError } from "../../../functions/Validation";
import { Button, TextField } from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Flag } from '@material-ui/icons';
import { add } from 'date-fns';

const UserRoleForm = ({
    onSubmit,
    onCancel,
    onAutocompleteChange,
    handleTextInputChange,
    errors,
    data,
    isDisabled,
    roles,
    users,
    flag
}) =>{
    
    return(
        <form id='user-form'>
            {flag == "add" &&
                <Autocomplete
                id={"role"}
                name={"role"}
                options={roles}
                getOptionLabel={option => option.name}
                onChange={onAutocompleteChange}
                renderInput={params => (
                    <TextField
                        {...params}
                        variant="standard"
                        label={strings.userRole.role + "*"}
                        error={hasError(errors, 'role')}
                        helperText={getError(errors, 'role')}
                        margin="normal"
                        fullWidth
                        name={"role"}
                        onChange={handleTextInputChange}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                )}
                disabled={isDisabled}
            />
            }
            { (data.roleName && flag == "edit") &&
             <Autocomplete
                id={"role"}
                name={"role"}
                options={roles}
                getOptionLabel={option => (option.name ? option.name : data.roleName)}
                onChange={onAutocompleteChange}
                renderInput={params => (
                    <TextField
                        {...params}
                        variant="standard"
                        label={strings.userRole.role + "*"}
                        error={hasError(errors, 'role')}
                        helperText={getError(errors, 'role')}
                        margin="normal"
                        fullWidth
                        name={"role"}
                        onChange={handleTextInputChange}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                )}
                disabled={isDisabled}
                defaultValue={data.roleName}
            />
            }  
            {flag == "add" &&
            <Autocomplete
                id={"user"}
                name={"user"}
                options={users}
                getOptionLabel={option => option.name + " " + option.surname}
                onChange={onAutocompleteChange}
                renderInput={params => (
                    <TextField
                        {...params}
                        variant="standard"
                        label={strings.userRole.user + "*"}
                        error={hasError(errors, 'user')}
                        helperText={getError(errors, 'user')}
                        margin="normal"
                        fullWidth
                        name={"user"}
                        onChange={handleTextInputChange}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                )}
                disabled={isDisabled}  
            />
            }
            {(data.userName && flag == "edit") &&
                <Autocomplete
                    id={"user"}
                    name={"user"}
                    options={users}
                    getOptionLabel={option => (option.name ? option.name + " " + option.surname : data.userName + " " + data.userSurname)}
                    onChange={onAutocompleteChange}
                    renderInput={params => (
                        <TextField
                            {...params}
                            variant="standard"
                            label={strings.userRole.user + "*"}
                            error={hasError(errors, 'user')}
                            helperText={getError(errors, 'user')}
                            margin="normal"
                            fullWidth
                            name={"user"}
                            onChange={handleTextInputChange}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    )}
                    disabled={isDisabled}
                    defaultValue={data.userName + " " + data.userSurname}
                />}
{
                !isDisabled &&
                <div className='submit-container'>
                    <Button variant="contained" color="primary" onClick={onSubmit}>
                        {strings.state.form.ok}
                    </Button>
                    <Button variant="contained" color="secondary" onClick={onCancel}>
                        {strings.state.form.cancel}
                    </Button>
                </div>
            }
        </form>
    )
};
export default UserRoleForm;