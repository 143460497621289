import React from 'react';
import strings from '../../localization';
import TablePage from '../../common/TablePage';
import { withSnackbar } from 'notistack';
import * as Actions from "../../actions/Actions";
import { withRouter } from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import { bindActionCreators } from "redux";
import { Grid } from '@material-ui/core';
import SelectControl from '../../components/controls/SelectControl';
import { getMonths, getYears } from '../../util/DateUtil';
import IconButton from "@material-ui/core/IconButton";
import { generateReviserReview } from '../../services/report/ReportService';
import { downloadReport } from '../../util/ReviewsUtil';

class ReviserReviewList extends TablePage {

  constructor(props) {
    super(props);

    const months = getMonths();
    months.unshift({ id: -1, name: strings.months.all })

    const years = getYears();
    years.unshift({ value: -1, name: strings.years.all })

    this.state = {
      ...this.state,
      showAdd: false,
      showSerach: false,
      data: {
        year: { value: -1 },
        month: { id: -1 },
      },
      filter: {
        months: months,
        years: years,
      },
    };
  }

  componentDidMount() {
    this.setState({
      companyId: this.props.auth.user.company.id
    })
  }

  componentWillReceiveProps(nextProps) {
    strings.setLanguage(nextProps.auth.user.language);
  }

  getPageHeader() {
    return <h1>{strings.reviserReview.pageTitle}</h1>
  }

  generateReport() {
    let data = {
      companyId: this.state.companyId,
      month: this.state.data.month ? this.state.data.month.id : "",
      year: this.state.data.year ? this.state.data.year.value : "",
      userId: this.props.auth.user.id
    };
    generateReviserReview(data).then(response => {
      if (!response.ok) {
        return;
      }

      this.props.enqueueSnackbar(strings.mainBookOfBuriedList.PDF, { variant: 'success' });
      downloadReport(response, "Dugovanja zakljucno sa " + data.month + "." + data.year);
    })
  }

  render() {
    return (
      <Grid id='table-page'>
        <div className='header'>
          {this.getPageHeader()}
        </div>
        <div className='review-select'>
          <React.Fragment >
            <SelectControl
              options={this.state.filter.years}
              selected={this.state.data.year}
              onChange={this.changeData}
              label={strings.mainBookOfBuriedList.year}
              name={"year"}
              nameKey={"name"}
              valueKey={"value"}
            />

            <SelectControl
              options={this.state.filter.months}
              selected={this.state.data.month}
              onChange={this.changeData}
              label={strings.mainBookOfBuriedList.month}
              name={"month"}
              nameKey={"name"}
              valueKey={"id"}
            />
          </React.Fragment>
        </div>
        <div className="report-button">
          <IconButton onClick={() => this.generateReport()} disabled={this.state.data.month.id == -1 || this.state.data.year.value == -1}
          >
            {strings.reviserReview.generateReport}
          </IconButton>
        </div>

      </Grid>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    changeFullScreen: Actions.changeFullScreen
  }, dispatch);
}

function mapStateToProps({ menuReducers, authReducers, siteDataReducers }) {
  return { menu: menuReducers, auth: authReducers, siteData: siteDataReducers };
}

export default withSnackbar(withRouter(connect(mapStateToProps, mapDispatchToProps)(ReviserReviewList)));
