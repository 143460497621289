import React from 'react';
import strings from "../../../localization";
import { getError, hasError } from "../../../functions/Validation";
import { Button, TextField } from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';

const RolePermissionForm = ({
    onSubmit,
    onCancel,
    onAutocompleteChange,
    handleTextInputChange,
    errors,
    data1,
    isDisabled,
    roles,
    permissions
}) =>{
    
    return(
        <form id='user-form'>
            <Autocomplete
                    id={"role"}
                    name={"role"}
                    options={roles}
                    getOptionLabel={option => option.name}
                    onChange={onAutocompleteChange}
                    renderInput={params => (
                        <TextField
                            {...params}
                            variant="standard"
                            label={strings.rolePermission.role + "*"}
                            error={hasError(errors, 'role')}
                            helperText={getError(errors, 'role')}
                            margin="normal"
                            fullWidth
                            name={"role"}
                            onChange={handleTextInputChange}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    )}
                    disabled={isDisabled}
                    value={data1.state}
                />
                <Autocomplete
                    id={"permission"}
                    name={"permission"}
                    options={permissions}
                    getOptionLabel={option => option.category + "- " + option.code }
                    onChange={onAutocompleteChange}
                    renderInput={params => (
                        <TextField
                            {...params}
                            variant="standard"
                            label={strings.rolePermission.permission + "*"}
                            error={hasError(errors, 'permission')}
                            helperText={getError(errors, 'permission')}
                            margin="normal"
                            fullWidth
                            name={"permission"}
                            onChange={handleTextInputChange}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    )}
                    disabled={isDisabled}
                    value={data1.state}
                />

{
                !isDisabled &&
                <div className='submit-container'>
                    <Button variant="contained" color="primary" onClick={onSubmit}>
                        {strings.state.form.ok}
                    </Button>
                    <Button variant="contained" color="secondary" onClick={onCancel}>
                        {strings.state.form.cancel}
                    </Button>
                </div>
            }
        </form>
    )
};
export default RolePermissionForm;