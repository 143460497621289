import React from 'react'
import TablePage from "../../common/TablePage";
import { bindActionCreators } from "redux";
import * as Actions from "../../actions/Actions";
import { withRouter } from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import strings from "../../localization";
import { withSnackbar } from "notistack";
import { TableCell } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Block';
import IconButton from '@material-ui/core/IconButton';
import MoreVert from '@material-ui/icons/MoreVert';
import { ListItemIcon, ListItemText, Menu, MenuItem } from "@material-ui/core";
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import VisibilityIcon from '@material-ui/icons/Visibility';
import UndoCancelIcon from '@material-ui/icons/Replay';
import { getGrass, deleteGrass, stornoGrass, undoStornoGrass } from '../../services/grass/GrassService';
import AddGrass from './AddGrass';
import EditGrass from './EditGrass';
import ViewGrass from './ViewGrass';


class GrassList extends TablePage {

  tableDescription = [
    { key: 'grassNumber', label: strings.grassList.grassNumber, sort: 'grassNumber' },
    { key: 'canceled', label: strings.grassList.canceled, transform: 'renderColumnDeleted', sort: 'canceled' },
    { key: 'dateOfCancellation', label: strings.grassList.dateOfCancellation, transform: 'renderColumnDateTime', sort: 'dateOfCancellation' },
    { key: 'dateCreated', label: strings.grassList.dateCreated, transform: 'renderColumnDate', sort: 'dateCreated' },
  ];


  constructor(props) {

    super(props);

    this.state.sortBy = "dateCreated,DESC";

  }

  fetchData() {

    let data = {
      searchParam: this.state.searchData.search,
      page: this.state.searchData.page - 1,
      size: this.state.searchData.perPage,
      companyId: this.state.companyId,
      sort: this.state.sortBy,
      userId: this.props.auth.user.id
    };

    {
      this.state.companyId != undefined &&
        getGrass(data).then(response => {
          if (!response.ok) {
            return;
          }
          this.setState({
            tableData: response.data.entities,
            total: response.data.total,
          });
        });
    }
  }

  usePermissions(){
    let data = {
      userId: this.props.auth.user.id,
      category: "Трава"
    }
    this.getPermissionsS(data);
    this.showAdd(this.props.showAdd);
    this.showRemove(this.props.remove);
    this.showEdit(this.props.showEdit);
    this.showView(this.props.showView);
    this.removedItemsShow(this.props.removedItemsShow);

  }

  componentDidMount() {
    this.usePermissions()

    this.setState({
      companyId: this.props.auth.user.company.id
    }, () => { this.fetchData(); })
  }

  componentWillReceiveProps(nextProps) {

    strings.setLanguage(nextProps.auth.user.language);
    this.tableDescription = [
      { key: 'grassNumber', label: strings.grassList.grassNumber, sort: 'grassNumber' },
      { key: 'canceled', label: strings.grassList.canceled, transform: 'renderColumnDeleted', sort: 'canceled' },
      { key: 'dateOfCancellation', label: strings.grassList.dateOfCancellation, transform: 'renderColumnDateTime', sort: 'dateOfCancellation' },
      { key: 'dateCreated', label: strings.grassList.dateCreated, transform: 'renderColumnDate', sort: 'dateCreated' },
    ];
  }

  handleMenuStorno(item) {

    stornoGrass(item.id, this.props.auth.user.id).then(response => {
      if (!response.ok) {
        let messageKey = response.response.data.message;

        this.props.enqueueSnackbar(strings.grass.messages[messageKey], {
          variant: "error"
        });

        return;
      }

      this.props.enqueueSnackbar(strings.addCompany.grassCanceled, { variant: 'success' });
      this.handleMenuClose();
      this.onFinish(item);
    });
  }

  handleMenuUndoStorno(item) {

    undoStornoGrass(item.id, this.props.auth.user.id).then(response => {
      if (!response.ok) {
        let messageKey = response.response.data.message;

        this.props.enqueueSnackbar(strings.grass.messages[messageKey], {
          variant: "error"
        });

        return;
      }

      this.props.enqueueSnackbar(strings.addCompany.undoGrassCanceled, { variant: 'success' });
      this.handleMenuClose();
      this.onFinish(item);
    });
  }

  delete(item) {

    this.setState({
      lockTable: true
    });


    deleteGrass(item.id, this.props.auth.user.id).then(response => {

      if (response && !response.ok) {
        this.onFinish(null);
        return;
      }

      this.props.enqueueSnackbar(strings.companyList.grassDeleted, { variant: 'success' });

      this.onFinish(item);
      this.cancelDelete();

      this.setState({
        lockTable: false
      });
    });
  }

  getPageHeader() {

    return <h1>{strings.grassList.pageTitle}</h1>;
  }

  renderAddContent() {

    return <AddGrass cid={this.state.companyId} isDisabled={false} uemail={this.props.auth.user.email} onCancel={this.onCancel} onFinish={this.onFinish} flag={"add"} />
  }

  renderEditContent() {

    return <EditGrass
      id={this.state.selectedItem.id}
      uemail={this.props.auth.user.email}
      cid={this.state.companyId}
      onCancel={this.onCancel}
      onFinish={this.onFinish}
      isDisabled={false}
      flag={"edit"}
    />
  }

  renderViewContent() {

    return <ViewGrass id={this.state.selectedItem.id} isDisabled={true} onCancel={this.onCancel} onFinish={this.onFinish} flag={"view"} />
  }

  renderRowMenu(index, item) {

    let ariaOwns = 'action-menu-' + index;
    return (
      <TableCell>
        <IconButton
          size='small'
          aria-owns={this.state.anchorEl ? ariaOwns : undefined}
          aria-haspopup="true"
          onClick={(event) => this.handleMenuClick(event, ariaOwns)}
        >
          <MoreVert />
        </IconButton>
        {
          ariaOwns === this.state.ariaOwns &&
          <Menu
            id={ariaOwns}
            anchorEl={this.state.anchorEl}
            open={Boolean(this.state.anchorEl)}
            onClose={() => this.handleMenuClose()}
          >
            <MenuItem onClick={() => this.handleMenuView(item)}>
              <ListItemIcon>
                <VisibilityIcon />
              </ListItemIcon>
              <ListItemText primary={strings.table.view} />
            </MenuItem>
            <MenuItem onClick={() => this.handleMenuEdit(item)}>
              <ListItemIcon>
                <EditIcon />
              </ListItemIcon>
              <ListItemText primary={strings.table.edit} />
            </MenuItem>
            {
              !item[this.deletedField] &&
              <MenuItem onClick={() => this.handleMenuDelete(item)}>
                <ListItemIcon>
                  <DeleteIcon />
                </ListItemIcon>
                <ListItemText primary={strings.table.delete} />
              </MenuItem>
            }
            {
              !item.canceled &&
              <MenuItem onClick={() => this.handleMenuStorno(item)}>
                <ListItemIcon>
                  <CancelIcon />
                </ListItemIcon>
                <ListItemText primary={strings.table.cancel} />
              </MenuItem>
            }
            {
              item.canceled &&
              <MenuItem onClick={() => this.handleMenuUndoStorno(item)}>
                <ListItemIcon>
                  <UndoCancelIcon />
                </ListItemIcon>
                <ListItemText primary={strings.table.undoCancel} />
              </MenuItem>
            }

          </Menu>
        }
      </TableCell>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    changeFullScreen: Actions.changeFullScreen
  }, dispatch);
}

function mapStateToProps({ menuReducers, authReducers, siteDataReducers }) {
  return { menu: menuReducers, auth: authReducers, siteData: siteDataReducers };
}

export default withSnackbar(withRouter(connect(mapStateToProps, mapDispatchToProps)(GrassList)));