import React from 'react'
import { bindActionCreators } from "redux";
import * as Actions from "../../../actions/Actions";
import { withRouter } from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import Grid from '@material-ui/core/Grid';
import { Paper } from "@material-ui/core";
import strings from "../../../localization";
import Validators from "../../../constants/ValidatorTypes";
import FormComponent from "../../../common/FormComponent";
import { withSnackbar } from "notistack";
import { addDeceasedReception } from '../../../services/deceasedReception/DeceasedReceptionService';
import UrnTakeoverRecordForm from '../../../components/forms/deceasedReception/urnTakeoverRecord/UrnTakeoverRecordForm';
import { getAllTransporters } from '../../../services/transporter/TransporterService';
import { getAllReasonBringingDeceassed } from '../../../services/reasonBringingDeceased/ReasonBringingDeceasedService';
import { getAllStoragePlaces } from '../../../services/storagePlace/StoragePlaceService';
import { getAllTombstones } from '../../../services/tombstone/TombstoneService';
import { getAllLetters } from '../../../services/letter/LetterService';
import { getAllLanguages } from '../../../services/language/LanguageService';
import { getAllTypefaces } from '../../../services/typeface/TypefaceService';


class AddUrnTakeoverRecord extends FormComponent {

  validationList = {
    identificationNumber: [{ type: Validators.REQUIRED }],
    deceasedNameSurname: [{ type: Validators.REQUIRED }],
  };

  constructor(props) {
    super(props);

    this.state = {
      data: props.data ? props.data : {},
      errors: {},
    };

    this.state.sortBy = "name,ASC";

    this.props.changeFullScreen(false);

    this.submit = this.submit.bind(this);
  }

  componentDidMount() {
    getAllTransporters().then(response => {
      this.setState({
        transporters: response.data.entities,
      });
    });

    getAllReasonBringingDeceassed().then(response => {
      this.setState({
        reasonsOfBrought: response.data.entities,
      });
    });

    getAllStoragePlaces().then(response => {
      this.setState({
        storages: response.data.entities
      });
    });

    getAllTombstones().then(response => {
      this.setState({
        tombstones: response.data.entities
      })
    });

    getAllLetters().then(response => {
      this.setState({
        letters: response.data.entities
      });
    })

    getAllLanguages().then(response => {
      this.setState({
        languages: response.data.entities
      });
    });

    getAllTypefaces().then(response => {
      this.setState({
        letterTypes: response.data.entities
      });
    });

  }


  submit() {

    if (!this.validate()) {
      this.props.enqueueSnackbar(strings.error.requiredFields, { variant: 'error' });
      return;
    }

    this.showDrawerLoader();

    addDeceasedReception(this.transformRequest(this.state.data), this.props.cid).then(response => {
      if (!response.ok) {
        let messageKey = response.response.data.message;

        this.props.enqueueSnackbar(strings.deceasedReception.messages[messageKey], {
          variant: "error"
        });

        this.handleError(messageKey);

        return;
      }

      this.props.enqueueSnackbar(strings.addCompany.urnTakeoverRecordAdded, { variant: 'success' });
      this.props.onFinish(response.data.urnTakeoverRecord);

      this.hideDrawerLoader();
    });
  }

  transformRequest(data) {

    return {
      ...data,
      deceasedReceptionType: 2,
      userEmail: this.props.uemail,
      transporterId: this.state.data.transporter ? this.state.data.transporter.id : null,
      reasonOfBroughtId: this.state.data.reasonOfBrought ? this.state.data.reasonOfBrought.id : null,
      storageId: this.state.data.storage ? this.state.data.storage.id : null,
      tombstoneId: this.state.data.tombstone ? this.state.data.tombstone.id : null,
      letterId: this.state.data.letter ? this.state.data.letter.id : null,
      languageId: this.state.data.language ? this.state.data.language.id : null,
      letterTypeId: this.state.data.letterType ? this.state.data.letterType.id : null,
    }
  }

  handleError(message) {
    switch (message) {
      case "DECEASED_RECEPTION_WITH_IDENTIFICATION_NUMBER_ALREADY_EXIST":
        this.setError("identificationNumber", strings.deceasedReception.messages.DECEASED_RECEPTION_WITH_IDENTIFICATION_NUMBER_ALREADY_EXIST);
        break;
      case "DECEASED_RECEPTION_WITH_IDENTIFICATION_NUMBER_CANT_BE_NULL":
        this.setError("identificationNumber", strings.deceasedReception.messages.DECEASED_RECEPTION_WITH_IDENTIFICATION_NUMBER_CANT_BE_NULL);
        break;
      case "DECEASED_RECEPTION_WITH_NAME_SURNAME_CANT_BE_NULL":
        this.setError("deceasedNameSurname", strings.deceasedReception.messages.DECEASED_RECEPTION_WITH_NAME_SURNAME_CANT_BE_NULL);
        break;
    }
  }

  handleChangeTab = (event, value) => {
    this.setState({
      value: value
    });
  };

  handleChange = (event) => {
    this.setState({
      data: {
        ...this.state.data, [event.target.name]: event.target.value
      }
    })
  };

  /*
  * Handles autocomplete change event
  * Triggers when user selects something from dropdown menu
  * to fill info (disabled) fields
  */
  onAutocompleteChange = (event, values) => {
    let autocompleteName = event.target.id.split("-")[0];
    this.setState({
      data: {
        ...this.state.data, [autocompleteName]: values
      }
    });
  }

  render() {

    return (
      <Grid id='page' item md={12}>

        <div className='header'>
          <h1>{strings.urnTakeoverRecord.pageTitle}</h1>
        </div>

        <Paper className='paper'>
          <UrnTakeoverRecordForm
            onChange={this.changeData}
            onSubmit={this.submit}
            data={this.state.data}
            errors={this.state.errors}
            onCancel={this.props.onCancel}
            handleChange={this.handleChange}
            onAutocompleteChange={this.onAutocompleteChange}
            handleTextInputChange={this.handleTextInputChange}
            transporters={this.state.transporters}
            reasonsOfBrought={this.state.reasonsOfBrought}
            storages={this.state.storages}
            tombstones={this.state.tombstones}
            letters={this.state.letters}
            languages={this.state.languages}
            letterTypes={this.state.letterTypes}
            flag={this.props.flag}
          />
        </Paper>

      </Grid>

    );
  }
}


function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    changeFullScreen: Actions.changeFullScreen
  }, dispatch);
}

function mapStateToProps({ menuReducers, siteDataReducers }) {
  return { menu: menuReducers, siteData: siteDataReducers };
}

export default (withSnackbar(withRouter(connect(mapStateToProps, mapDispatchToProps)(AddUrnTakeoverRecord))));