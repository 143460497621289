import React from 'react';
import strings from "../../../localization";
import { getError, hasError } from "../../../functions/Validation";
import { Button, TextField, FormLabel, FormControlLabel, Radio, FormControl, RadioGroup } from "@material-ui/core";
import DatePickerControl from '../../controls/DatePickerControl';
import Autocomplete from '@material-ui/lab/Autocomplete';

const DeceasedCremationSchedulingForm = ({
  onSubmit,
  onCancel,
  onChange,
  errors,
  data,
  isDisabled,
  handleChange,
  onAutocompleteChange,
  handleTextInputChange,
  municipalities,
  states,
  occupations,
  causesOfDeath,
  doctors,
  flag,
  onChangePersonalNumberField,
  date,
  disabled,
  scheduledFunerals,
}) => {
  return (
    <form id='user-form'>

      {(flag == "add") &&
      <Autocomplete
          id={"scheduledFuneral"}
          name={"scheduledFuneral"}
          options={scheduledFunerals}
          getOptionLabel={option => option.funeralNumber}
          onChange={onAutocompleteChange}
          renderInput={params => (
              <TextField
                  {...params}
                  variant="standard"
                  label={strings.funeralScheduling.form.funeralNumber}
                  margin="normal"
                  style={{ width: 275 }}
                  fullWidth
                  name={"scheduledFuneral"}
                  onChange={handleTextInputChange}
                  InputLabelProps={{
                      shrink: true,
                  }}
              />
          )}
          disabled={isDisabled}
          value={data.scheduledFuneral}
      />
      }

      <TextField
        label={strings.cremationScheduling.form.cremationNumber + " (генерисано аутоматски)"}
        error={hasError(errors, 'funeralNumber')}
        helperText={getError(errors, 'funeralNumber')}
        fullWidth
        autoFocus
        name='funeralNumber'
        onChange={onChange}
        margin="normal"
        defaultValue={date}
        value={data.funeralNumber}
        InputLabelProps={{
          shrink: true,
        }}
        disabled={true}
      />

      <TextField
        label={strings.cremationScheduling.form.identificationNumber}
        error={hasError(errors, 'identificationNumber')}
        helperText={getError(errors, 'identificationNumber')}
        fullWidth
        name='identificationNumber'
        onChange={onChangePersonalNumberField}
        margin="normal"
        defaultValue={data?.identificationNumber}
        value={data?.identificationNumber}
        InputLabelProps={{
          shrink: true,
        }}
        disabled={isDisabled}
      />

      <TextField
        label={strings.cremationScheduling.form.deceasedNameSurname + "*"}
        error={hasError(errors, 'deceasedNameSurname')}
        helperText={getError(errors, 'deceasedNameSurname')}
        fullWidth
        name='deceasedNameSurname'
        onChange={onChange}
        margin="normal"
        defaultValue={data?.deceasedNameSurname}
        value={data?.deceasedNameSurname}
        InputLabelProps={{
          shrink: true,
        }}
        disabled={isDisabled}
      />

      <TextField
        label={strings.cremationScheduling.form.fathersName}
        error={hasError(errors, 'fathersName')}
        helperText={getError(errors, 'fathersName')}
        fullWidth
        name='fathersName'
        onChange={onChange}
        margin="normal"
        defaultValue={data?.fathersName}
        value={data?.fathersName}
        InputLabelProps={{
          shrink: true,
        }}
        disabled={isDisabled}
      />

      <FormControl component="fieldset">
        <FormLabel className="checkboxLabel"> {strings.cremationScheduling.form.gender} </FormLabel>
        <RadioGroup name="gender" value={(data.gender == true || data.gender == "true") ? "true" : "false"} onChange={handleChange} className="radioGroup">
          <FormControlLabel value={"true"} disabled={isDisabled} control={<Radio />} label={<span className="formControlLabel">{strings.cremationScheduling.form.male}</span>} />
          <FormControlLabel value={"false"} disabled={isDisabled} control={<Radio />} label={<span className="formControlLabel">{strings.cremationScheduling.form.female}</span>} />
        </RadioGroup>
      </FormControl>

      {((data.occupation && flag == "edit") || (flag == "add") || (data.occupation && flag == "view")) &&
        <Autocomplete
          id={"occupation"}
          name={"occupation"}
          options={occupations}
          getOptionLabel={option => option.name}
          onChange={onAutocompleteChange}
          renderInput={params => (
            <TextField
              {...params}
              variant="standard"
              label={strings.cremationScheduling.form.occupation}
              margin="normal"
              fullWidth
              name={"occupation"}
              onChange={handleTextInputChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
          disabled={isDisabled}
          value={data?.occupation}
        />
      }
      {((!data.occupation && flag == "edit") || (!data.occupation && flag == "view")) &&
        <Autocomplete
          id={"occupation"}
          name={"occupation"}
          options={occupations}
          getOptionLabel={option => option.name}
          onChange={onAutocompleteChange}
          renderInput={params => (
            <TextField
              {...params}
              variant="standard"
              label={strings.cremationScheduling.form.occupation}
              margin="normal"
              fullWidth
              name={"occupation"}
              onChange={handleTextInputChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
          disabled={isDisabled}
          value={data.occupation}
        />
      }

      <DatePickerControl
        label={strings.cremationScheduling.form.dateOfBirth}
        error={getError(errors, 'dateOfBirth')}
        hasError={hasError(errors, 'dateOfBirth')}
        helperText={getError(errors, 'dateOfBirth')}
        placeholder={strings.cremationScheduling.form.dateOfBirth}
        name='dateOfBirth'
        onChange={onChange}
        date={data?.dateOfBirth ? new Date(data?.dateOfBirth) : data?.dateOfBirth}
        isClearable={true}
        dateFormat='dd/MM/yyyy'
        disabled={isDisabled}
      />
      
      {((data.municipalityOfBirth && flag == "edit") || (flag == "add") || data.municipalityOfBirth && (flag == "view")) &&
        <Autocomplete
          id={"municipalityOfBirth"}
          name={"municipalityOfBirth"}
          options={municipalities}
          getOptionLabel={option => `${option.city} ${option.zip}`}
          onChange={onAutocompleteChange}
          renderInput={params => (
            <TextField
              {...params}
              variant="standard"
              label={strings.cremationScheduling.form.municipalityOfBirth}
              margin="normal"
              fullWidth
              name={"municipalityOfBirth"}
              onChange={handleTextInputChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
          disabled={isDisabled}
          value={data.municipalityOfBirth}
        />
      }
      {((!data.municipalityOfBirth && flag == "edit") || (!data.municipalityOfBirth && (flag == "view")))&&
        <Autocomplete
          id={"municipalityOfBirth"}
          name={"municipalityOfBirth"}
          options={municipalities}
          getOptionLabel={option => `${option.city} ${option.zip}`}
          onChange={onAutocompleteChange}
          renderInput={params => (
            <TextField
              {...params}
              variant="standard"
              label={strings.cremationScheduling.form.municipalityOfBirth}
              margin="normal"
              fullWidth
              name={"municipalityOfBirth"}
              onChange={handleTextInputChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
          disabled={isDisabled}
          value={data.municipalityOfBirth}
        />
      }

      <TextField
        label={strings.cremationScheduling.form.lastAddressOfResidence}
        error={hasError(errors, 'lastAddressOfResidence')}
        helperText={getError(errors, 'lastAddressOfResidence')}
        fullWidth
        name='lastAddressOfResidence'
        onChange={onChange}
        margin="normal"
        defaultValue={data?.lastAddressOfResidence}
        value={data?.lastAddressOfResidence}
        InputLabelProps={{
          shrink: true,
        }}
        disabled={isDisabled}
      />

      <FormControl component="fieldset">
        <FormLabel className="checkboxLabel"> {strings.cremationScheduling.form.permanentResidence} </FormLabel>
        <RadioGroup name="permanentResidence" value={(data.permanentResidence == true || data.permanentResidence == "true") ? "true" : "false"} onChange={handleChange} className="radioGroup">
          <FormControlLabel value={"true"} disabled={isDisabled} control={<Radio />} label={<span className="formControlLabel">{strings.cremationScheduling.form.permanent}</span>} />
          <FormControlLabel value={"false"} disabled={isDisabled} control={<Radio />} label={<span className="formControlLabel">{strings.cremationScheduling.form.temporary}</span>} />
        </RadioGroup>
      </FormControl>

      {((data.stateOfResidence && flag == "edit") || (flag == "add") || (data.stateOfResidence && flag == "view")) &&
        <Autocomplete
          id={"stateOfResidence"}
          name={"stateOfResidence"}
          options={states}
          getOptionLabel={option => option.name}
          onChange={onAutocompleteChange}
          renderInput={params => (
            <TextField
              {...params}
              variant="standard"
              label={strings.cremationScheduling.form.stateOfResidence}
              margin="normal"
              fullWidth
              name={"stateOfResidence"}
              onChange={handleTextInputChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
          disabled={isDisabled}
          value={data.stateOfResidence}
        />
      }
      {((!data.stateOfResidence && flag == "edit") || (!data.stateOfResidence && flag == "view")) &&
        <Autocomplete
          id={"stateOfResidence"}
          name={"stateOfResidence"}
          options={states}
          getOptionLabel={option => option.name}
          onChange={onAutocompleteChange}
          renderInput={params => (
            <TextField
              {...params}
              variant="standard"
              label={strings.cremationScheduling.form.stateOfResidence}
              margin="normal"
              fullWidth
              name={"stateOfResidence"}
              onChange={handleTextInputChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
          disabled={isDisabled}
          value={data.stateOfResidence}
        />
      }

      {((data.municipalityOfResidence && flag == "edit") || (flag == "add") || (data.municipalityOfResidence && flag == "view")) &&
        <Autocomplete
          id={"municipalityOfResidence"}
          name={"municipalityOfResidence"}
          options={municipalities}
          getOptionLabel={option => `${option.city} ${option.zip}`}
          onChange={onAutocompleteChange}
          renderInput={params => (
            <TextField
              {...params}
              variant="standard"
              label={strings.cremationScheduling.form.municipalityOfResidence}
              margin="normal"
              fullWidth
              name={"municipalityOfResidence"}
              onChange={handleTextInputChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
          disabled={isDisabled}
          value={data.municipalityOfResidence}
        />
      }
      {((!data.municipalityOfResidence && flag == "edit") || (!data.municipalityOfResidence && flag == "view")) &&
        <Autocomplete
          id={"municipalityOfResidence"}
          name={"municipalityOfResidence"}
          options={municipalities}
          getOptionLabel={option => `${option.city} ${option.zip}`}
          onChange={onAutocompleteChange}
          renderInput={params => (
            <TextField
              {...params}
              variant="standard"
              label={strings.cremationScheduling.form.municipalityOfResidence}
              margin="normal"
              fullWidth
              name={"municipalityOfResidence"}
              onChange={handleTextInputChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
          disabled={isDisabled}
          value={data.municipalityOfResidence}
        />
      }

      <DatePickerControl
        label={strings.cremationScheduling.form.dateTimeOfDeath}
        error={getError(errors, 'dateTimeOfDeath')}
        hasError={hasError(errors, 'dateTimeOfDeath')}
        helperText={getError(errors, 'dateTimeOfDeath')}
        placeholder={strings.cremationScheduling.form.dateTimeOfDeath}
        name='dateTimeOfDeath'
        onChange={onChange}
        date={data.dateTimeOfDeath ? new Date(data.dateTimeOfDeath) : data?.dateTimeOfDeath}
        isClearable={true}
        dateFormat='dd/MM/yyyy HH:mm'
        showTimeSelect={true}
        disabled={isDisabled}
      />

      {((data.municipalityOfDeath && flag == "edit") || (flag == "add") || (data.municipalityOfDeath && flag == "view")) &&
        <Autocomplete
          id={"municipalityOfDeath"}
          name={"municipalityOfDeath"}
          options={municipalities}
          getOptionLabel={option => `${option.city} ${option.zip}`}
          onChange={onAutocompleteChange}
          renderInput={params => (
            <TextField
              {...params}
              variant="standard"
              label={strings.cremationScheduling.form.municipalityOfDeath}
              margin="normal"
              fullWidth
              name={"municipalityOfDeath"}
              onChange={handleTextInputChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
          disabled={isDisabled}
          value={data.municipalityOfDeath}
        />
      }
      {((!data.municipalityOfDeath && flag == "edit") || (!data.municipalityOfDeath && flag == "view")) &&
        <Autocomplete
          id={"municipalityOfDeath"}
          name={"municipalityOfDeath"}
          options={municipalities}
          getOptionLabel={option => `${option.city} ${option.zip}`}
          onChange={onAutocompleteChange}
          renderInput={params => (
            <TextField
              {...params}
              variant="standard"
              label={strings.cremationScheduling.form.municipalityOfDeath}
              margin="normal"
              fullWidth
              name={"municipalityOfDeath"}
              onChange={handleTextInputChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
          disabled={isDisabled}
          value={data.municipalityOfDeath}
        />
      }

      <FormControl component="fieldset">
        <FormLabel className="checkboxLabel"> {strings.cremationScheduling.form.autopsy} </FormLabel>
        <RadioGroup name="autopsy" value={(data.autopsy == true || data.autopsy == "true") ? "true" : "false"} onChange={handleChange} className="radioGroup">
          <FormControlLabel value={"true"} disabled={isDisabled} control={<Radio />} label={<span className="formControlLabel">{strings.cremationScheduling.form.yes}</span>} />
          <FormControlLabel value={"false"} disabled={isDisabled} control={<Radio />} label={<span className="formControlLabel">{strings.cremationScheduling.form.no}</span>} />
        </RadioGroup>
      </FormControl>

      {((data.causeOfDeath && flag == "edit") || (flag == "add") || (data.causeOfDeath && flag == "view")) &&
        <Autocomplete
          id={"causeOfDeath"}
          name={"causeOfDeath"}
          options={causesOfDeath}
          getOptionLabel={option => option.name}
          onChange={onAutocompleteChange}
          renderInput={params => (
            <TextField
              {...params}
              variant="standard"
              label={strings.cremationScheduling.form.causeOfDeath}
              margin="normal"
              fullWidth
              name={"causeOfDeath"}
              onChange={handleTextInputChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
          disabled={isDisabled}
          value={data.causeOfDeath}
        />
      }
      {((!data.causeOfDeath && flag == "edit") || (!data.causeOfDeath && flag == "view")) &&
        <Autocomplete
          id={"causeOfDeath"}
          name={"causeOfDeath"}
          options={causesOfDeath}
          getOptionLabel={option => option.name}
          onChange={onAutocompleteChange}
          renderInput={params => (
            <TextField
              {...params}
              variant="standard"
              label={strings.cremationScheduling.form.causeOfDeath}
              margin="normal"
              fullWidth
              name={"causeOfDeath"}
              onChange={handleTextInputChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
          disabled={isDisabled}
          value={data.causeOfDeath}
        />
      }

      <TextField
        label={strings.cremationScheduling.form.causeOfDeathCode}
        error={hasError(errors, 'causeOfDeathCode')}
        helperText={getError(errors, 'causeOfDeathCode')}
        fullWidth
        name='causeOfDeathCode'
        onChange={onChange}
        margin="normal"
        defaultValue={data.causeOfDeathCode}
        value={data.causeOfDeathCode}
        InputLabelProps={{
          shrink: true,
        }}
        disabled={isDisabled}
      />

      {((data.doctor && flag == "edit") || (flag == "add") || (data.doctor && flag == "view")) &&
        <Autocomplete
          id={"doctor"}
          name={"doctor"}
          options={doctors}
          getOptionLabel={option => option.name}
          onChange={onAutocompleteChange}
          renderInput={params => (
            <TextField
              {...params}
              variant="standard"
              label={strings.cremationScheduling.form.doctor}
              margin="normal"
              fullWidth
              name={"doctor"}
              onChange={handleTextInputChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
          disabled={isDisabled}
          value={data.doctor}
        />
      }
      {((!data.doctor && flag == "edit") || (!data.doctor && flag == "view")) &&
        <Autocomplete
          id={"doctor"}
          name={"doctor"}
          options={doctors}
          getOptionLabel={option => option.name}
          onChange={onAutocompleteChange}
          renderInput={params => (
            <TextField
              {...params}
              variant="standard"
              label={strings.cremationScheduling.form.doctor}
              margin="normal"
              fullWidth
              name={"doctor"}
              onChange={handleTextInputChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
          disabled={isDisabled}
          value={data.doctor}
        />
      }

      <TextField
        label={strings.cremationScheduling.form.weight}
        error={hasError(errors, 'weight')}
        helperText={getError(errors, 'weight')}
        fullWidth
        name='weight'
        onChange={onChange}
        margin="normal"
        defaultValue={data?.weight}
        value={data?.weight}
        InputLabelProps={{
          shrink: true,
        }}
        disabled={isDisabled}
      />

      <TextField
        label={strings.cremationScheduling.form.height}
        error={hasError(errors, 'height')}
        helperText={getError(errors, 'height')}
        fullWidth
        name='height'
        onChange={onChange}
        margin="normal"
        defaultValue={data?.height}
        value={data?.height}
        InputLabelProps={{
          shrink: true,
        }}
        disabled={isDisabled}
      />

      {
        !isDisabled &&
        <div className='submit-container'>
          <Button variant="contained" color="primary" disabled={disabled} onClick={onSubmit}>
            {strings.cremationScheduling.form.ok}
          </Button>
          <Button variant="contained" color="secondary" onClick={onCancel}>
            {strings.cremationScheduling.form.cancel}
          </Button>
        </div>
      }
    </form>
  )
};

export default DeceasedCremationSchedulingForm;