import React from 'react';
import strings from "../../localization";
import { bindActionCreators } from "redux";
import { withSnackbar } from "notistack";
import { withRouter } from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import * as Actions from "../../actions/Actions";
import Page from '../../common/Page';
import { getYears } from '../../util/DateUtil';
import SelectControl from '../../components/controls/SelectControl';
import IconButton from "@material-ui/core/IconButton";
import { generateFuneralAndUrnPerMonth } from '../../services/report/ReportService';
import { downloadReport } from '../../util/ReviewsUtil';

class FuneralGraveyardAndUrnList extends Page {

  constructor(props) {
    super(props);

    const years = getYears();

    this.state = {
      ...this.state,
      data: {
        year: { value: "none" },
      },
      filter: {
        years: years,
      },
    }
  }

  fetchData() {
    let data = {
      year: this.state.data.year ? this.state.data.year.value : "",
      generate: false,
      searchParam: this.state.searchData.search,
      page: this.state.searchData.page - 1,
      size: this.state.searchData.perPage,
      companyId: this.state.companyId,
      sort: this.state.sortBy,
    };
  }

  componentDidMount() {
    this.setState({
      companyId: this.props.auth.user.company.id,
    }, () => {
      this.fetchData();
    });
  }

  componentWillReceiveProps(nextProps) {
    strings.setLanguage(nextProps.auth.user.language);
  }

  renderText() {
    let styles = {
      marginLeft: '20px',
    };
    return (
      <div style={styles}>
        <h1> {strings.review.tables.funerals.funeralsForSelectedYear}</h1>
      </div>
    );
  }


  renderFilter() {
    let styles = {
      marginLeft: '20px',
      width: '200px',
    };

    return (

      <div style={styles}>
        <SelectControl
          options={this.state.filter.years}
          selected={this.state.data.year}
          onChange={this.changeData}
          label={strings.mainBookOfBuriedList.year}
          name={"year"}
          nameKey={"name"}
          valueKey={"value"}
        />
      </div>

    );
  }



  renderButton() {
    let data = {
      year: this.state.data.year ? this.state.data.year.value : "",
      companyId: this.state.companyId,
      userId: this.props.auth.user.id
    };

    let styles = {
      marginLeft: '20px',
    };

    return (
      <div className="report-button" style={styles}>
        <IconButton onClick={() => this.generateFuneralAndUrnPerMonth(data, strings.review.tables.funerals.funeralsListPDFName)} disabled={this.state.data.year.value == 'none'}>
          {strings.review.buttons.generateReport}
        </IconButton>
      </div>

    );
  }

  generateFuneralAndUrnPerMonth(data, value) {

    generateFuneralAndUrnPerMonth(data).then(response => {
      if (!response.ok) {
        return;
      }

      this.props.enqueueSnackbar(strings.review.tables.funerals.funeralsListPDF, { variant: 'success' });
      downloadReport(response, value);
    })

  }

  render() {
    return (
      <div>
        {
          this.renderText()
        }
        {
          this.renderFilter()
        }
        {
          this.renderButton()
        }
      </div>
    );
  }


}


function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    changeFullScreen: Actions.changeFullScreen
  }, dispatch);
}

function mapStateToProps({ menuReducers, authReducers, siteDataReducers }) {
  return { menu: menuReducers, auth: authReducers, siteData: siteDataReducers };
}

export default withSnackbar(withRouter(connect(mapStateToProps, mapDispatchToProps)(FuneralGraveyardAndUrnList)));
