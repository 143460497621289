import React from 'react';
import strings from "../../../localization";
import { getError, hasError } from "../../../functions/Validation";
import { Button, TextField,FormLabel, FormControlLabel, Radio, FormControl, RadioGroup  } from "@material-ui/core";

const CauseOfDeathForm = ({
    onSubmit,
    onCancel,
    onChange,
    errors,
    data,
    isDisabled,
    handleChange
}) => {
    return (

        <form id='user-form'>
            {/* <TextField
                label={strings.causeOfDeath.form.code + "*"}
                error={hasError(errors, 'code')}
                helperText={getError(errors, 'code')}
                fullWidth
                autoFocus
                name='code'
                onChange={onChange}
                margin="normal"
                defaultValue={data.code}
                value={data.code}
                InputLabelProps={{
                    shrink: true,
                }}
                disabled = {isDisabled}
            /> */}
            <TextField
                label={strings.causeOfDeath.form.name + "*"}
                error={hasError(errors, 'name')}
                helperText={getError(errors, 'name')}
                fullWidth
                name='name'
                onChange={onChange}
                margin="normal"
                defaultValue={data.name}
                value={data.name}
                InputLabelProps={{
                    shrink: true,
                }}
                disabled={isDisabled}
            />
            <FormControl component="fieldset">
                <FormLabel className="checkboxLabel"> {strings.causeOfDeath.form.infectious} </FormLabel>
                <RadioGroup name="infectious" value={(data.infectious == true || data.infectious == "true") ? "true" : "false"} onChange={handleChange} className="radioGroup">
                <FormControlLabel value={"true"} disabled={isDisabled} control={<Radio />} label={<span className="formControlLabel">{strings.deceasedReception.form.yes}</span>} />
                <FormControlLabel value={"false"} disabled={isDisabled} control={<Radio />} label={<span className="formControlLabel">{strings.deceasedReception.form.no}</span>} />
                </RadioGroup>
            </FormControl>

            {
                !isDisabled &&
                <div className='submit-container'>
                    <Button variant="contained" color="primary" onClick={onSubmit}>
                        {strings.causeOfDeath.form.ok}
                    </Button>
                    <Button variant="contained" color="secondary" onClick={onCancel}>
                        {strings.causeOfDeath.form.cancel}
                    </Button>
                </div>
            }
        </form>
    )
};

export default CauseOfDeathForm;