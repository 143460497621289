import React from 'react';
import strings from "../../../localization";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { getError, hasError } from "../../../functions/Validation";
import { Button, TextField, Checkbox, FormControlLabel, FormLabel, FormGroup } from "@material-ui/core";

const CitizenForm = ({
  onSubmit,
  onCancel,
  onChange,
  errors,
  data,
  disabledCity,
  isDisabled,
  handleChange,
  checked,
  onChangePersonalNumberField,
  flag,
  handleTextInputChange,
  states,
  onAutocompleteChange,
  citiesAndZips,

  
}) => {


  let renderAddCitizenButton = {
    marginLeft: "289px",
    marginTop: "-114px",
    paddingBottom: "-300px"
  }

  return (

    <form id='user-form'>
      <TextField
        label={strings.citizen.form.personalNumber + "*"}
        error={hasError(errors, 'personalNumber')}
        helperText={getError(errors, 'personalNumber')}
        fullWidth
        autoFocus
        name='personalNumber'
        onChange={onChangePersonalNumberField}
        margin="normal"
        defaultValue={data.personalNumber}
        value={data.personalNumber}
        InputLabelProps={{
          shrink: true,
        }}
        disabled={isDisabled}
      />
      <TextField
        label={strings.citizen.form.name + "*"}
        error={hasError(errors, 'name')}
        helperText={getError(errors, 'name')}
        fullWidth
        name='name'
        onChange={onChange}
        margin="normal"
        defaultValue={data.name}
        value={data.name}
        InputLabelProps={{
          shrink: true,
        }}
        disabled={isDisabled}
      />
      <TextField
        label={strings.citizen.form.surname + "*"}
        error={hasError(errors, 'surname')}
        helperText={getError(errors, 'surname')}
        fullWidth
        name='surname'
        onChange={onChange}
        margin="normal"
        defaultValue={data.surname}
        value={data.surname}
        InputLabelProps={{
          shrink: true,
        }}
        disabled={isDisabled}
      />
      {((flag == "add") || (flag == "edit"  && data.state) || (flag == "view" && data.state)) &&
          <Autocomplete
              id={"state"}
              name={"state"}
              options={states}
              getOptionLabel={option => option.name}
              onChange={onAutocompleteChange}
              renderInput={params => (
                  <TextField
                      {...params}
                      variant="standard"
                      label={strings.postalCodeImproved.form.state + "*"}
                      error={hasError(errors, 'state')}
                      helperText={getError(errors, 'state')}
                      margin="normal"
                      fullWidth
                      name={"state"}
                      onChange={handleTextInputChange}
                      InputLabelProps={{
                          shrink: true,
                      }}
                  />
              )}
              disabled={isDisabled}
              value={data.state}
              
          />
      }
      {((flag == "edit"  && !data.state) || (flag == "view" && !data.state)) &&
          <Autocomplete
              id={"state"}
              name={"state"}
              options={states}
              getOptionLabel={option => option.name}
              onChange={onAutocompleteChange}
              renderInput={params => (
                  <TextField
                      {...params}
                      variant="standard"
                      label={strings.postalCodeImproved.form.state + "*"}
                      error={hasError(errors, 'state')}
                      helperText={getError(errors, 'state')}
                      margin="normal"
                      fullWidth
                      name={"state"}
                      onChange={handleTextInputChange}
                      InputLabelProps={{
                          shrink: true,
                      }}
                  />
              )}
              disabled={isDisabled}
              value={data.state}
              
          />
      }
      {((flag == "add") || (flag == "edit" && data.postalCodeImproved) || (flag == "view" && data.postalCodeImproved)) &&
          <Autocomplete
              id={"postalCodeImproved"}
              name={"postalCodeImproved"}
              options={citiesAndZips}
              getOptionLabel={option => option.zip + "-" + option.city}
              onChange={onAutocompleteChange}
              renderInput={params => (
                  <TextField
                      {...params}
                      variant="standard"
                      label={strings.postalAddressCodeImprovedList.cityAndZip + '*'}
                      error={hasError(errors, 'postalCodeImproved')}
                      helperText={getError(errors, 'postalCodeImproved')}
                      margin="normal"
                      fullWidth
                      name={"postalCodeImproved"}
                      onChange={handleTextInputChange}
                      InputLabelProps={{
                          shrink: true,
                      }}
                  />
              )}
              disabled={disabledCity}
              value={data.postalCodeImproved}

          />
      }
      {((flag == "edit" && !data.postalCodeImproved) || (flag == "view" && !data.postalCodeImproved)) &&
          <Autocomplete
              id={"postalCodeImproved"}
              name={"postalCodeImproved"}
              options={citiesAndZips}
              getOptionLabel={option => option.zip + "-" + option.city}
              onChange={onAutocompleteChange}
              renderInput={params => (
                  <TextField
                      {...params}
                      variant="standard"
                      label={strings.postalAddressCodeImprovedList.cityAndZip + '*'}
                      error={hasError(errors, 'postalCodeImproved')}
                      helperText={getError(errors, 'postalCodeImproved')}
                      margin="normal"
                      fullWidth
                      name={"postalCodeImproved"}
                      onChange={handleTextInputChange}
                      InputLabelProps={{
                          shrink: true,
                      }}
                  />
              )}
              disabled={disabledCity}
              value={data.postalCodeImproved}

          />
      }

      {(data.postalCodeImprovedCityZip == undefined ||  data.postalCodeImprovedCityZip == null) &&
          <TextField
                label={strings.citizen.form.street + "*"}
                error={hasError(errors, 'street')}
                helperText={getError(errors, 'street')}
                fullWidth
                name='street'
                onChange={handleTextInputChange}
                margin="normal"
                defaultValue={data.street}
                value={data.street}
                InputLabelProps={{
                  shrink: true,
                }}
                disabled={isDisabled}
              />
        }
        {(data.postalCodeImprovedCityZip != undefined ||  data.postalCodeImprovedCityZip != null) &&
          <TextField
                label={strings.citizen.form.street + "*"}
                error={hasError(errors, 'street')}
                helperText={getError(errors, 'street')}
                fullWidth
                name='street'
                onChange={handleTextInputChange}
                margin="normal"
                defaultValue={data.street}
                value={data.street}
                InputLabelProps={{
                  shrink: true,
                }}
                disabled={isDisabled}
              />
        }
        {(data.street == undefined ||  data.street == null) &&
          <TextField
            label={strings.citizen.form.streetNumber + "*"}
            error={hasError(errors, 'streetNumber')}
            helperText={getError(errors, 'streetNumber')}
            fullWidth
            name='streetNumber'
            onChange={handleTextInputChange}
            margin="normal"
            defaultValue={data.streetNumber}
            value={data.streetNumber}
            InputLabelProps={{
              shrink: true,
            }}
            disabled={isDisabled}
          />
      }
       {(data.street != undefined ||  data.street != null) &&
          <TextField
            label={strings.citizen.form.streetNumber + "*"}
            error={hasError(errors, 'streetNumber')}
            helperText={getError(errors, 'streetNumber')}
            fullWidth
            name='streetNumber'
            onChange={handleTextInputChange}
            margin="normal"
            defaultValue={data.streetNumber}
            value={data.streetNumber}
            InputLabelProps={{
              shrink: true,
            }}
            disabled={isDisabled}
          />
      }
      {(data.pac != undefined ||  data.pac != null ) &&
          <TextField
                label={"PAC" }
                error={hasError(errors, 'pac')}
                helperText={getError(errors, 'pac')}
                fullWidth
                name='pac'
                onChange={handleTextInputChange}
                margin="normal"
                defaultValue={data.pac}
                value={data.pac}
                InputLabelProps={{
                  shrink: true,
                }}
                disabled={true}
              />
        }
      <TextField
        label={strings.citizen.form.telephone}
        error={hasError(errors, 'telephone')}
        helperText={getError(errors, 'telephone')}
        fullWidth
        name='telephone'
        onChange={onChange}
        margin="normal"
        defaultValue={data.telephone}
        value={data.telephone}
        InputLabelProps={{
          shrink: true,
        }}
        disabled={isDisabled}
      />

      {flag != "add" &&
        <TextField
          label={strings.citizen.form.oldAddresses}
          name='oldAddresses'
          onChange={onChange}
          margin="normal"
          fullWidth
          value={data.oldAddresses}
          defaultValue={data.oldAddresses}
          disabled={true}
          multiline={true}
          rowsMax={5}
          InputLabelProps={{
            shrink: true,
          }}
        />
      }

      <FormLabel component="legend" className="checkboxLabel">{strings.citizen.form.deceased}</FormLabel>
      <FormGroup>
        <FormControlLabel
          control={<Checkbox checked={checked} onChange={handleChange} name="deceased" />}
          disabled={isDisabled}
        />
      </FormGroup>

      <TextField
        multiline={true}
        label={strings.mainBookOfBuried.form.note}
        error={hasError(errors, 'note')}
        helperText={getError(errors, 'note')}
        fullWidth
        name='note'
        onChange={onChange}
        margin="normal"
        defaultValue={data.note}
        value={data.note}
        InputLabelProps={{
          shrink: true,
        }}
        disabled={isDisabled}
        rowsMax={5}
      />

      {
        !isDisabled &&
        <div className='submit-container'>
          <Button variant="contained" color="primary" onClick={onSubmit}>
            {strings.citizen.form.ok}
          </Button>
          <Button variant="contained" color="secondary" onClick={onCancel}>
            {strings.citizen.form.cancel}
          </Button>
        </div>
      }
    </form>
  )
};

export default CitizenForm;